(function() {
    "use strict";

    angular.module("cpir").filter("entryCopyrightTypeFilter", () => {
        const oaTypeStringMap = {
            ccby: "CCBY",
            ccbyncnd: "CCBY-NC-ND",
            ccbync: "CCBY-NC",
            ccbysa: "CCBY-SA",
            ccbyncsa: "CCBY-NC-SA",
            ccbynd: "CCBY-ND",
        };

        function getOaTypeString(type) {
            return oaTypeStringMap[type];
        }

        return entry => {
            if (!entry) return entry;
            if (
                entry.openAccessCopyright &&
                entry.openAccessCopyright.purchased
            ) {
                return getOaTypeString(entry.openAccessCopyright.type);
            } else if (
                entry.copyrightType &&
                entry.copyrightType !== "open-access"
            ) {
                return entry.copyrightType;
            } else if (
                entry.openAccessCopyright &&
                entry.openAccessCopyright.signed
            ) {
                let typeString = getOaTypeString(
                    entry.openAccessCopyright.type
                );
                return typeString + "-PENDING";
            } else {
                return "";
            }
        };
    });
})();
