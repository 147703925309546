(function () {
    "use strict";

    const MAX_RETRIES = 8; // Number of retransmissions allowed
    const FILE_INFLIGHT_LIMIT = 4; // Number of files to upload at the same time

    angular.module("cpir").provider(
        "UploadService",
        class {
            constructor() {}
            $get(Upload, $q, WebSocketService, SocketStreamService) {
                /**
                 * Opens a websocket client for uploading files
                 * @param action {('manifest'|'files')}} The action with which to initialize the webSocket.
                 * Choose 'manifest' for batch upload manifest & 'files' for all other uploads.
                 * @returns {Promise}
                 */
                function getFileUploadWebSocketClient(action) {
                    return new Promise((resolve, reject) => {
                        WebSocketService.openSocket((client) => {
                            /*
                            Validate the action choice
                             */
                            if (action !== "files" && action !== "manifest") {
                                reject(
                                    new Error("Unsupported action: " + action),
                                );
                            }

                            // mark this as a file type websocket
                            client.fileTypeSocket = true;

                            // attach a streaming wrapper
                            client.socketStream =
                                SocketStreamService.getSocketStream(client);

                            /*
                            Set variables for file upload control
                            This is used by both manifest and regular uploads
                             */
                            client.fileUploadReadyAsync = new Promise(
                                (innerResolve) => {
                                    client.once("file.ready", () => {
                                        client.fileUploadReady = true;
                                        innerResolve(client);
                                        resolve(client);
                                    });
                                },
                            );

                            /*
                            Start the socket protocol
                             */
                            if (action === "manifest") {
                                /*
                                Set variables for manifest upload control
                                 */
                                client.manifestRequired = true;
                                client.manifestUploadReadyAsync = new Promise(
                                    (innerResolve) => {
                                        client.emit("upload.manifest", () => {
                                            client.manifestUploadReady = true;
                                            innerResolve(client);
                                            resolve(client);
                                        });
                                    },
                                );
                            } else {
                                // the listener was previously defined above
                                client.emit("upload.files");
                            }
                        });
                    });
                }

                /**
                 * Writes a file over Socket.IO
                 * @param [webSocketClientAsync] {Promise<*>} An existing websocket connection to re-use.
                 * The websocket must be ready for file writes.
                 * A websocket connection will be created if none is provided
                 * @param pid {string}
                 * @param [eid]
                 * @param [videoId]
                 * @param file {File}
                 * @param fileType {string} CPIR file type
                 * @param [videoMetadata] Optional video metadata for videos
                 * @param onProgress {Function} Callback when upload progress is updated
                 * @return {{processingAsync: Promise<any>, promise: Promise<any>}}
                 */
                function uploadFile({
                    webSocketClientAsync,
                    pid,
                    eid,
                    videoId,
                    file,
                    fileType,
                    videoMetadata,
                    onProgress,
                }) {
                    /*
                    Convert the file metadata to the standard form for CPIR uploads
                     */
                    const fileMetadata = {
                        uploadType: fileType,
                        originalFilename: file.name,
                        size: file.size,
                        type: _.isEmpty(file.type)
                            ? file.alternateType
                            : file.type,
                    };

                    /*
                    Helper promise to track when server side processing (saving to file repo, s3, etc) are complete.
                     */
                    let resolveProcessingAsync;
                    const processingAsync = new Promise((resolve) => {
                        resolveProcessingAsync = resolve;
                    });

                    /*
                    Helper promise to cancel an upload in progress.
                     */
                    let resolveCancelAsync;
                    const cancelAsync = new Promise((resolve) => {
                        resolveCancelAsync = resolve;
                    });

                    /*
                    If a file upload socket is not open create one
                     */
                    const uploadCompleteAsync = (
                        webSocketClientAsync
                            ? webSocketClientAsync
                            : getFileUploadWebSocketClient("files")
                    ).then((webSocketClient) => {
                        resolveCancelAsync(
                            webSocketClient.socketStream.cancel.bind(
                                webSocketClient.socketStream,
                            ),
                        );
                        return new Promise((resolve, reject) => {
                            const setWebsocketListeners = (client) => {
                                client.on("error", (err) => {
                                    reject(err);
                                });
                                /*
                                Handle any errors from the client
                                 */
                                client.on("upload-error", (err) => {
                                    reject(new Error(`Upload Error: ${err}`));
                                });

                                client.on("disconnect", (reason, details) => {
                                    console.error(
                                        `Socket disconnected: ${reason} - ${JSON.stringify(
                                            details,
                                            null,
                                            2,
                                        )}`,
                                    );
                                });

                                client.on("connect_error", (err) => {
                                    reject(err);
                                });

                                /*
                                Handle server-side processing complete
                                 */
                                client.on("processing-complete", (data) => {
                                    client.disconnect();
                                    resolveProcessingAsync(data);
                                });
                            };
                            setWebsocketListeners(webSocketClient);

                            webSocketClient.socketStream
                                .emit(
                                    "file.upload",
                                    file,
                                    {
                                        pid,
                                        eid,
                                        videoId,
                                        fileMetadata,
                                        videoMetadata,
                                    },
                                    onProgress,
                                    setWebsocketListeners,
                                    () => getFileUploadWebSocketClient("files"),
                                    (ids) => {
                                        const { fid, vid, videoId } = ids;
                                        console.log(
                                            `File upload completed. New IDs: fid ${fid}, vid ${vid}, videoId ${videoId}`,
                                        );
                                        resolve(ids);
                                    },
                                )
                                .catch((err) => reject(err));
                        });
                    });

                    return {
                        promise: uploadCompleteAsync,
                        processingAsync,
                        cancelAsync,
                    };
                }

                /**
                 * Uploads videos files
                 * @param pid {string}
                 * @param [eid] {string}
                 * @param video {File}
                 * @param videoDetails - video metadata for standalone videos
                 * @param onProgress - callback when upload progress is updated
                 * @return {{promise: Promise<*>}}
                 */
                function uploadVideo({
                    pid,
                    eid,
                    video,
                    videoDetails,
                    onProgress,
                }) {
                    return uploadFile({
                        pid,
                        eid,
                        fileType: "video",
                        file: video,
                        videoMetadata: videoDetails,
                        onProgress,
                    });
                }

                /**
                 * Uploads transcript files
                 * @param pid
                 * @param eid
                 * @param videoId
                 * @param transcript
                 * @param onProgress - callback when upload progress is updated
                 * @return {{promise: Promise<*>}}
                 */
                function uploadTranscript({
                    pid,
                    eid,
                    videoId,
                    transcript,
                    onProgress,
                }) {
                    return uploadFile({
                        pid,
                        eid,
                        videoId,
                        fileType: "transcript",
                        file: transcript,
                        onProgress,
                    });
                }

                function uploadFilesForEntry({
                    pid,
                    eid,
                    article,
                    source,
                    extras,
                }) {}

                // manifest will create entries & update toc Entries. then upload articles and extras as usual?
                // can update the protocol to handle the file uploads in the same way as other uploads.
                function batchUpload({ pid, manifest, articles, extras }) {}

                async function handleManifestUpload() {
                    throw new Error("TODO - manifest upload");
                }

                const batchUploadSocketHandler = (options) => {
                    let deferred = options.deferred || $q.defer();

                    WebSocketService.openSocket((client) => {
                        const socketStream =
                            SocketStreamService.getSocketStream(client);

                        // start a promise for the final return result
                        const fileUploadDeferred = $q.defer();

                        // check inputs
                        if (!options.pid) {
                            deferred.reject(
                                "A pid is required for batch uploads",
                            );
                            return;
                        }
                        if (!options.manifest) {
                            deferred.reject(
                                "A manifest file is required for batch uploads",
                            );
                            return;
                        }
                        options.articles = options.articles || [];
                        options.extras = options.extras || [];

                        // helper function for using the socket-io.stream module
                        let sendFile = (msg, file, extraData) => {
                            socketStream.emit(msg, file, {
                                ...extraData,
                                fileMetadata: getFileMetaData(file),
                            });
                        };

                        // helper function for creating file metadata
                        let getFileMetaData = (file) => {
                            return {
                                originalFilename: file.name,
                                size: file.size,
                                type: file.type,
                                retries: file.retries,
                                tocFileType: file.tocFileType,
                            };
                        };

                        // initialize in-flight counter
                        let inFlight = options.inFlight || 0;

                        // get the list of articles to send, add the article type
                        // don't use the spread operator to create a new object.  It doesn't seem to work for file metadata
                        const articleFileQueue =
                            options.articleFileQueue ||
                            options.articles.map((article) => {
                                article.tocFileType = "article";
                                return article;
                            }) ||
                            [];

                        // get the list of extras to send, add the extra type
                        // don't use the spread operator to create a new object.  It doesn't seem to work for file metadata
                        const extraFileQueue =
                            options.extraFileQueue ||
                            options.extras.map((extra) => {
                                extra.tocFileType = "extra";
                                return extra;
                            }) ||
                            [];

                        // combine both queues
                        const fileQueue =
                            articleFileQueue.concat(extraFileQueue);

                        // article file map for getting file metadata for handling errors and re-tries
                        const articleFileMap =
                            options.articleFileMap ||
                            articleFileQueue.reduce((fileMap, file) => {
                                fileMap[file.name] = file;
                                return fileMap;
                            }, {});

                        // extra file map for getting file metadata for handling errors and re-tries
                        const extraFileMap =
                            options.extraFileMap ||
                            extraFileQueue.reduce((fileMap, file) => {
                                fileMap[file.name] = file;
                                return fileMap;
                            }, {});

                        // get total size of all files to be uploaded
                        const totalSize = fileQueue.reduce((size, file) => {
                            size += file.size;
                            return size;
                        }, options.manifest.size);

                        // more initialization and settings
                        let completedSize = 0;

                        // final promise result handler
                        fileUploadDeferred.promise
                            .then(() => {
                                console.log(
                                    "File uploads finished, sending disconnect signal...",
                                );
                                client.emit("finish");
                                deferred.resolve("finished");
                            })
                            .catch((err) => {
                                console.error(err);
                                deferred.reject(err);
                                client.disconnect();
                            });

                        // file handler portion of the client
                        client.on("files.ready", (previousData) => {
                            // set new state
                            options.sentManifest = true;
                            inFlight--;
                            console.log("files.ready :: ", previousData);
                            const previousFileMetadata =
                                previousData.fileMetadata;
                            completedSize += previousFileMetadata.size;

                            // update progress ticker
                            let progress = parseInt(
                                (completedSize / totalSize) * 100,
                            );
                            console.log(`Upload progress: ${progress}%`);
                            if (
                                options.progress &&
                                typeof options.progress === "function"
                            ) {
                                options.progress(progress);
                            }

                            // only send files if there are actually files
                            if (fileQueue.length) {
                                // keep uploading until the files in the queue are gone
                                while (
                                    inFlight < FILE_INFLIGHT_LIMIT &&
                                    fileQueue.length
                                ) {
                                    // show current fileQueue
                                    console.log(
                                        "file queue length: ",
                                        fileQueue.length,
                                    );

                                    // get a file and send it
                                    let file = fileQueue.pop();
                                    file.retries = 0;
                                    sendFile("upload.file", file);
                                    inFlight++;
                                    console.log("In flight: ", inFlight);
                                }
                            } else {
                                if (inFlight <= 0) {
                                    fileUploadDeferred.resolve();
                                }
                            }
                            // TODO generate session divider for updated session dividers
                            // .then(closeResult => {
                            //     let entryIds = closeResult.toc.entries.filter(
                            //         e => e.class === 'SD' && e.hasPdf).map(e => e.id);
                            //     return this.ItextService.batchGenerateSessionDividers(
                            //         this.pid, entryIds)
                            //         .then(
                            //             () => console.log('regenerated session dividers'));
                        });

                        // manifest handler
                        client.on("manifest.ready", () => {
                            inFlight++;
                            console.log("sending manifest...");
                            sendFile("manifest.file", options.manifest, {
                                pid: options.pid,
                                supplementalMode: options.supplementalMode,
                            });
                        });

                        // Protocol error-handler. Will attempt to re-send files a configured amount of times
                        client.on("toc.import.error", (msg, inputData) => {
                            console.error(
                                "import protocol error:",
                                msg,
                                inputData,
                            );

                            // if we failed to send the initial manifest file, abort upload
                            if (!options.sentManifest) {
                                let m = `failure on initial manifest upload: ${msg}`;
                                console.log(m);
                                deferred.reject(m);
                                client.disconnect();
                            }
                            // else check if we are within the retry limit
                            else if (
                                typeof inputData.retries === "number" &&
                                inputData.retries < MAX_RETRIES - 1
                            ) {
                                // get the failed file metadata and re-send
                                const fileType = inputData.tocFileType;
                                const file = (
                                    fileType === "article"
                                        ? articleFileMap
                                        : extraFileMap
                                )[inputData.originalFilename];
                                file.retries++;
                                console.log("retrying file: ", file);
                                sendFile("upload.file", file);
                            }
                            // max retried reach for the file, skip this file
                            else {
                                inFlight--;
                                console.log(
                                    `The following file has failed ${
                                        inputData.retries + 1
                                    } times:`,
                                    inputData,
                                );

                                // check if it was the last file
                                if (!fileQueue.length && inFlight <= 0) {
                                    console.log(
                                        "upload finished, sending disconnect signal...",
                                    );
                                    client.emit("finish");
                                    deferred.resolve("finished");
                                }
                            }
                        });

                        // new data handler
                        client.on("new-data", () => {
                            options.onNewData && options.onNewData();
                            console.log("new data");
                        });

                        // general socket error handler
                        client.on("error", (msg) => {
                            console.error("socket io error: ", msg);
                            deferred.reject(msg);
                        });

                        // Send start signal
                        client.emit("toc.import");
                    });

                    return deferred.promise;
                };

                return {
                    upload: (options) => {
                        if (!options.entryId)
                            return $q.reject(
                                new Error(
                                    "TOC Entry Id is required for uploads",
                                ),
                            );
                        if (!options.pid)
                            return $q.reject(
                                new Error(
                                    "Proceeding Id (pid) is required for uploads",
                                ),
                            );

                        return Upload.upload({
                            url: `/files/${options.pid}/entry/${options.entryId}/insert`,
                            data: {
                                paper: options.paper,
                                source: options.source,
                                extras: options.extras,
                            },
                        })
                            .then((result) => result.data)
                            .catch((err) => {
                                console.log(err);
                                throw err;
                            });
                    },
                    batchUpload: (options) => batchUploadSocketHandler(options),
                    uploadLogo: (pid, logoFile) => {
                        return Upload.upload({
                            url: `/toc/${pid}/logo`,
                            data: {
                                file: logoFile,
                            },
                        })
                            .then((result) => result.data)
                            .catch((err) => {
                                console.log(err);
                                throw err;
                            });
                    },
                    uploadWebpubBanner: (pid, bannerFile) => {
                        return Upload.upload({
                            url: `/toc/${pid}/webpub-banner`,
                            data: {
                                file: bannerFile,
                            },
                        })
                            .then((result) => result.data)
                            .catch((err) => {
                                console.log(err);
                                throw err;
                            });
                    },
                    uploadComplianceFiles: (pid, complianceFiles) => {
                        return Upload.upload({
                            url: `/toc/${pid}/compliance-files`,
                            data: {
                                complianceFiles,
                            },
                        })
                            .then((result) => result.data)
                            .catch((err) => {
                                console.log(err);
                                throw err;
                            });
                    },
                    uploadVideo,
                    uploadTranscript,
                };
            }
        },
    );
})();
