(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceConfSubmissionPanel", {
        bindings: {
            proceeding: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/compliance-conf-submission-panel/compliance-conf-submission-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                ProceedingStatusService,
                NotificationService,
                UploadService,
                WebSocketService,
                $scope
            ) {
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.UploadService = UploadService;
                this.WebSocketService = WebSocketService;
                this.$scope = $scope;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
                this.compliance = this.proceeding.compliance.submission;
            }

            isReadonly() {
                // Only allow changes when the proceeding is in compliance submitted
                // or compliance rejected
                return !(
                    this.ProceedingStatusService.isComplianceSubmitted(
                        this.proceeding
                    ) ||
                    this.ProceedingStatusService.isComplianceRejected(
                        this.proceeding
                    )
                );
            }

            updateCompliance(compliance, isValid, complianceFilesToUpload) {
                this.compliance = compliance;
                this.complianceFilesToUpload = complianceFilesToUpload;
            }

            uploadComplianceFiles() {
                console.log(
                    "complianceFilesToUpload: ",
                    this.complianceFilesToUpload
                );
                if (
                    this.complianceFilesToUpload &&
                    this.complianceFilesToUpload.length > 0
                ) {
                    this.isLoading = true;
                    return this.UploadService.uploadComplianceFiles(
                        this.pid,
                        this.complianceFilesToUpload
                    );
                } else {
                    return Promise.resolve(null);
                }
            }

            uploadAndSaveMetadata() {
                return this.uploadComplianceFiles().then(() => {
                    // reformat the files
                    this.compliance.files =
                        (this.compliance.files &&
                            this.compliance.files.map(
                                ({ name, size, type, mimeType }) => ({
                                    name,
                                    size,
                                    mimeType: type || mimeType
                                })
                            )) ||
                        undefined;

                    return this.compliance;
                });
            }

            saveConfiguration() {
                this.proceeding.compliance.submission = this.compliance;
                return this.uploadAndSaveMetadata()
                    .then(() =>
                        this.onSave({
                            props: {
                                compliance: this.compliance,
                                hideNotification: true
                            }
                        })
                    )
                    .then(() => {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "success",
                            "The compliance submission was saved successfully."
                        );
                        return this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        console.error(err);
                        this.isLoading = false;
                        this.NotificationService.send(
                            "danger",
                            "There was an error saving the compliance: " +
                                err.name +
                                " " +
                                err.message
                        );
                        return this.WebSocketService.safeApply(this.$scope);
                    });
            }
        }
    });
})();
