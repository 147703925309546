(function() {
    "use strict";

    angular.module("cpir").component("cpirProceedingConfEmailPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/proceeding-conf-email-panel/proceeding-conf-email-panel.component.html",
        controller: class {
            constructor($stateParams) {
                this.$stateParams = $stateParams;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.activeTemplateIndex = 0;
            }

            $onChanges(changes) {
                if (
                    changes.configuration &&
                    changes.configuration.currentValue &&
                    !changes.configuration.previousValue
                ) {
                    this.templates =
                        this.configuration &&
                        this.configuration.settings.email.emailTemplates.filter(
                            t => t.isGlobal === false
                        );
                    this.activeTemplate = this.templates[0];
                }
            }

            activateTemplate(templateIndex) {
                this.activeTemplateIndex = templateIndex;
                this.activeTemplate = this.templates[templateIndex];
            }

            saveChanges() {
                this.onSave({
                    props: { configuration: this.configuration }
                });
            }
        }
    });
})();
