(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkPreparationInformationController",

        class {
            constructor(
                pid,
                entryId,
                $state,
                $stateParams,
                ProceedingService,
                AKService,
                TocEntriesApiService,
                TOCValuesService,
                authService,
                moment,
                $uibModal
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.TOCValuesService = TOCValuesService;
                this.AKService = AKService;
                this.authService = authService;
                this.moment = moment;
                this.$uibModal = $uibModal;
            }

            $onInit() {
                this.AKService.refreshProceeding();
                const { email } = this.authService.getProfile();

                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;
                });
            }

            isStateValid() {
                return this.pid;
            }

            isAuthorKitDisabled() {
                if (!this.proceeding) return true;
                return this.proceeding.configuration.settings.ak.isDisabled;
            }

            goToNextStep() {
                if (this.entryId) {
                    this.AKService.goToNextStep({
                        entryId: this.entryId
                    });
                } else {
                    let entryTemplate = angular.copy(
                        this.TOCValuesService.authorkitTemplate
                    );
                    let currentTime = this.moment().format(
                        "dddd, MMMM DD YYYY, h:mm:ss a"
                    );
                    entryTemplate.title = `[Ak started by author: ${currentTime}]`;
                    entryTemplate.pid = this.pid;
                    this.TocEntriesApiService.insertEntries(entryTemplate)
                        .then(result => {
                            console.log("prep info result: ", result);
                            this.TocEntriesApiService.broadcastEntries(
                                this.pid
                            );
                            this.AKService.goToNextStep({
                                entryId: result.eid
                            });
                        })
                        .catch(err => console.error(err));
                }
            }
        }
    );
})();
