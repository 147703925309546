(function () {
    "use strict";

    angular.module("cpir").provider(
        "FileService",
        class {
            $get(
                $window,
                $q,
                apiService,
                $httpParamSerializer,
                WebSocketService,
                $http,
                store,
                IndexedDBService,
            ) {
                let firstItem = apiService.firstItem;
                let items = apiService.items;
                const { from, concat, EMPTY, throwError } = rxjs;
                const { catchError, tap, finalize } = rxjs.operators;

                //TODO associate existing files with their submitter email.
                //TODO add submitter email to file metadata

                function getFilesMetadata({ pid, eid, fileType, active, all }) {
                    let path = "files";
                    if (!_.isNil(eid)) {
                        path += `/tocEntry/${eid}`;
                    } else if (!_.isNil(pid)) {
                        path += `/proceeding/${pid}`;
                    } else {
                        throw new Error("Must specify a pid or eid");
                    }
                    if (!_.isNil(fileType) || !_.isNil(active) || all) {
                        path += "?";
                        let firstParam = true;
                        if (!_.isNil(active)) {
                            path += `active=${!!active}`;
                            firstParam = false;
                        }
                        if (!_.isNil(fileType)) {
                            if (!firstParam) {
                                path += "&";
                            }
                            path += `fileType=${fileType}`;
                            firstParam = false;
                        }
                        if (all) {
                            if (!firstParam) {
                                path += "&";
                            }
                            path += `all=true`;
                            firstParam = false;
                        }
                    }
                    return apiService.get(path).then(items);
                }

                function getFilesMetadataForConference(
                    pid,
                    fileType,
                    active,
                    all = false,
                ) {
                    return getFilesMetadata({ pid, fileType, active, all });
                }

                function getFilesMetadataForConferenceWithCache$(
                    pid,
                    fileType,
                    active,
                ) {
                    const hasIndexedDBSupport = IndexedDBService.isSupported();
                    if (!hasIndexedDBSupport) {
                        console.warn(
                            "IndexedDB is not supported. Falling back to API only.",
                        );
                    }

                    const indexedDBObservable = hasIndexedDBSupport
                        ? from(
                              IndexedDBService.getFilesForProceeding(
                                  pid,
                                  fileType,
                                  active,
                              ),
                          ).pipe(
                              catchError((err) => {
                                  console.error(
                                      "Error accessing IndexedDB",
                                      err,
                                  );
                                  return EMPTY; // If there's an error or IndexedDB is empty, emit nothing from this stream
                              }),
                          )
                        : EMPTY; // If no IndexedDB support, emit nothing

                    const apiObservable = from(
                        getFilesMetadataForConference(
                            pid,
                            fileType,
                            active,
                            true,
                        ),
                    ).pipe(
                        tap((files) => {
                            if (hasIndexedDBSupport) {
                                IndexedDBService.updateFiles(files);
                            }
                        }),
                        catchError((err) => {
                            console.error(
                                "Error fetching files metadata from API",
                                err,
                            );
                            return throwError(
                                () =>
                                    new Error(
                                        "Failed to fetch files metadata from API",
                                    ),
                            );
                        }),
                    );

                    return concat(indexedDBObservable, apiObservable).pipe(
                        finalize(() =>
                            console.log("Completed fetching files metadata"),
                        ),
                    );
                }

                function getFilesMetadataFoEntry(eid, fileType, active) {
                    return getFilesMetadata({ eid, fileType, active });
                }

                function getArticlesMetadataForEntry(eid, active) {
                    return getFilesMetadata({
                        eid,
                        fileType: "article",
                        active,
                    });
                }

                function getActiveArticleMetadataForEntry(eid) {
                    return getArticlesMetadataForEntry(eid, true).then((r) =>
                        _.last(r),
                    );
                }

                function getSourceMetadataForEntry(eid) {
                    return getFilesMetadata({
                        eid,
                        fileType: "source",
                    }).then((r) => _.last(r));
                }

                function getExtrasMetadataForEntry(eid) {
                    return getFilesMetadata({ eid, fileType: "extra" });
                }

                function getStampMetadataForEntry(eid) {
                    return getFilesMetadata({
                        eid,
                        fileType: "stamp",
                    }).then((r) => _.last(r));
                }

                function getOpenAccessMetadataForEntry(eid) {
                    return getFilesMetadata({
                        eid,
                        fileType: "oa-ccby",
                    }).then((r) => _.last(r));
                }

                function getFile(vid) {
                    const idToken = store.get("id_token");
                    $window.open(`/files/${vid}?id_token=${idToken}`, "_blank");
                }

                /**
                 * Helper to download arbitrary temporary files
                 * @param fileName
                 * @param contentType
                 * @param accessToken
                 * @param downloadName
                 */
                function getTempFile(
                    fileName,
                    contentType,
                    accessToken,
                    downloadName,
                ) {
                    // check params
                    if (!fileName) {
                        throw new Error("Missing File Name");
                    }
                    if (!contentType) {
                        throw new Error("Missing Content Type");
                    }
                    if (!accessToken) {
                        throw new Error("Missing Access Token");
                    }

                    /*
                    Set the query string
                     */
                    let queryString = `?fileName=${encodeURIComponent(
                        fileName,
                    )}&contentType=${encodeURIComponent(
                        contentType,
                    )}&accessToken=${encodeURIComponent(accessToken)}`;
                    if (downloadName) {
                        queryString += `&${encodeURIComponent(downloadName)}`;
                    }

                    // open the download in a new tab
                    $window.open(`/files/get-file${queryString}`);
                }

                function getFilesMetadataForFid(fid, active) {
                    let path = `files/fid/${fid}`;

                    if (!_.isEmpty(active)) {
                        path += `?active=${!!active}`;
                    }

                    return apiService.get(path).then(items);
                }

                function getPublicationAgreement(pid, props) {
                    props = props || {};
                    if (props.openInCurrentWindow) {
                        $window.open(
                            `/files/${pid}/file/publication-agreement`,
                            "_top",
                        );
                    } else {
                        $window.open(
                            `/files/${pid}/file/publication-agreement`,
                        );
                    }
                }

                function getLogo(pid) {
                    $window.open(`toc/${pid}/logo`, "_blank");
                }

                function storeLogo(pid, file) {
                    if (!pid)
                        throw new Error("A pid is required for logo uploads");
                    if (!file)
                        throw new Error(
                            "A logo files is required for logo uploads",
                        );
                    return Upload.upload({
                        url: `/toc/${pid}/logo`,
                        data: {
                            file: file,
                        },
                    })
                        .then((result) => result.data)
                        .catch((err) => {
                            console.log(err);
                            throw err.data;
                        });
                }

                function exportToc(
                    pid,
                    exportType,
                    includeExtras,
                    keepRawFileName,
                    fields,
                    supplementFolderStructure,
                    supplementNaming,
                    includeStampFiles,
                    includeOriginalFiles,
                    articleFileNaming,
                    generateManifest,
                ) {
                    let deferred = $q.defer();
                    WebSocketService.openSocket((client) => {
                        client.on("error", (err) => {
                            console.error(err);
                            client.disconnect();
                        });
                        client.on("export-timeout", () => {
                            console.error("export timeout");
                            client.timeout = true;
                            client.disconnect();
                        });
                        client.on("export-failed", (err) => {
                            console.error("export failed: ", err);
                            //throw new Error(err);
                            client.disconnect();
                        });
                        client.on("download-finished", () => {
                            console.log("download complete");
                            client.disconnect();
                        });

                        client.emit("toc.export", {
                            pid,
                            exportType,
                            includeExtras,
                            keepRawFileName,
                            fields,
                            supplementFolderStructure,
                            supplementNaming,
                            includeStampFiles,
                            includeOriginalFiles,
                            articleFileNaming,
                            generateManifest
                        });

                        deferred.resolve(client);
                    });
                    return deferred.promise;
                }

                function downloadTocExport(key, year, acronym, type, pid) {
                    $window.open(
                        `/toc/export?${$httpParamSerializer({
                            key: key,
                            year: year,
                            acronym: acronym,
                            type: type,
                            pid: pid,
                        })}`,
                        "_blank",
                    );
                }

                function exists(file) {
                    return $http
                        .get(`/files/exists/${file.vid}`)
                        .then((r) => r.data);
                }

                function activateFileVersion(file) {
                    const { fid, vid, type } = file;
                    return apiService
                        .put(
                            `files/activate/file/${fid}/type/${type}/version/${vid}`,
                        )
                        .then((result) => result.data);
                }

                function removeFileVersions(files) {
                    return apiService
                        .put("files/remove/version", files)
                        .then((result) => result.data)
                        .then((r) => {
                            console.log(r);
                            return r;
                        });
                }

                function getComplianceFileForProceeding(pid, fileName) {
                    $window.open(
                        `toc/${pid}/compliance-files/${encodeURIComponent(
                            fileName,
                        )}`,
                        "_blank",
                    );
                }

                function removeComplianceFileForProceeding(
                    proceeding,
                    fileName,
                ) {
                    if (
                        !proceeding ||
                        !proceeding.compliance ||
                        !proceeding.compliance.files
                    ) {
                        return Promise.resolve(false);
                    }
                    return $http
                        .delete(
                            `toc/${
                                proceeding.pid
                            }/compliance-files/${encodeURIComponent(fileName)}`,
                        )
                        .then((result) => result.data);
                }

                function getStampFilesMetadataForConference(
                    pid,
                    fileType,
                    active,
                ) {
                    return getFilesMetadata({ pid, fileType: "stamp", active });
                }

                return {
                    getFilesMetadataForConference,
                    getFilesMetadataForConferenceWithCache$,
                    getFilesMetadataFoEntry,
                    getArticlesMetadataForEntry,
                    getActiveArticleMetadataForEntry,
                    getSourceMetadataForEntry,
                    getExtrasMetadataForEntry,
                    getStampMetadataForEntry,
                    getOpenAccessMetadataForEntry,
                    getFilesMetadataForFid,
                    getLogo,
                    getComplianceFileForProceeding,
                    removeComplianceFileForProceeding,
                    storeLogo,
                    getFile,
                    getTempFile,
                    getPublicationAgreement,
                    exportToc,
                    downloadTocExport,
                    exists,
                    activateFileVersion,
                    removeFileVersions,
                    getStampFilesMetadataForConference,
                };
            }
        },
    );
})();
