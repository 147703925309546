(function() {
    "use strict";

    angular
        .module("cpir")
        .controller(
            "DashboardOrganizerQuoteController",
            DashboardOrganizerQuoteController
        );
    function DashboardOrganizerQuoteController(
        authService,
        $http,
        $q,
        $state,
        $window,
        $timeout,
        moment,
        EmailService,
        InternationalDataService,
        DeepLinkService,
        pid,
        ProceedingService,
        apiQuotes,
        NotificationService,
        ProceedingStatusService,
        ContactService,
        OrganizerQuoteService,
        ProceedingRoleService,
        PureDateService
    ) {
        let $ctrl = this;
        $ctrl.brand = authService.getBrand();
        $ctrl.enabled = false;
        $ctrl.redirectError = { err: false };

        _loadContent(() => {
            if (!$ctrl.proceeding.publicationAgreement) return;

            // load the conference signatory
            let cids = $ctrl.proceeding.contacts.map(c => c.cid);

            //check if user is editor or organizer
            if (!ProceedingRoleService.isEditorOrOrganizer($ctrl.proceeding))
                $state.go("dashboard-editor.home");

            $ctrl.quoteSections = $ctrl.proceeding.publicationAgreement;
            $ctrl.paSections = $ctrl.proceeding.publicationAgreement;

            //build publication agreement terms sections
            let contractTerms = $ctrl.quoteSections.filter(
                section => $ctrl.isTextSection(section) && section.enabled
            );
            $ctrl.quoteSections = $ctrl.quoteSections.filter(
                section => !$ctrl.isTextSection(section)
            );
            $ctrl.quoteSections = [
                {
                    name: "Publication Agreement Terms",
                    content: contractTerms,
                    type: "contract",
                    enabled: true
                }
            ].concat($ctrl.quoteSections);

            //build pricing sections
            $ctrl.pricingContent = $ctrl.quoteSections.find(section =>
                $ctrl.isPricingInformation(section)
            ).content;
            $ctrl.acceptInstructions = $ctrl.quoteSections.find(section =>
                $ctrl.isAcceptInstructions(section)
            ).content.text;
            $ctrl.signatureInformation = $ctrl.quoteSections.find(section =>
                $ctrl.isSignature(section)
            ).content.text;
            $ctrl.quoteSections = $ctrl.quoteSections.filter(
                section =>
                    !$ctrl.isAcceptInstructions(section) &&
                    !$ctrl.isSignature(section) &&
                    !$ctrl.isPricingInformation(section) &&
                    section.enabled
            );

            //build publication agreement sections
            $ctrl.paSections = $ctrl.paSections.filter(
                section =>
                    !$ctrl.isAcceptInstructions(section) &&
                    !$ctrl.isSignature(section) &&
                    section.enabled
            );

            $ctrl.quoteSections = $ctrl.quoteSections.concat([
                { name: "Quote", type: "accept quote" }
            ]);

            ContactService.list(cids)
                .then(contacts => {
                    $ctrl.contacts = contacts;
                    $ctrl.signatory = contacts.find(
                        contact => contact.isSignatory
                    );

                    const templateProceeding = angular.copy($ctrl.proceeding);
                    const templateSignatory = angular.copy($ctrl.signatory);
                    const { start, end } = templateProceeding.dates;
                    templateProceeding.dates.start = PureDateService.format(
                        start,
                        "D MMMM YYYY"
                    );
                    templateProceeding.dates.end = PureDateService.format(
                        end,
                        "D MMMM YYYY"
                    );
                    templateProceeding.location.province = InternationalDataService.expandProvince(
                        templateProceeding.location.province
                    );
                    templateProceeding.location.country = InternationalDataService.expandCountry(
                        templateProceeding.location.country
                    );
                    templateSignatory.address.province = InternationalDataService.expandProvince(
                        templateSignatory.address.province
                    );
                    templateSignatory.address.country = InternationalDataService.expandCountry(
                        templateSignatory.address.country
                    );

                    $ctrl.quoteData = {
                        proceeding: templateProceeding,
                        signatory: templateSignatory,
                        organizerName: `${$ctrl.contacts[0].givenName} ${$ctrl.contacts[0].surname}`
                    };
                    OrganizerQuoteService.compilePaSections(
                        $ctrl.paSections,
                        $ctrl.quoteData
                    );
                })
                .catch(err => {
                    console.error(err);
                    throw err;
                });

            $ctrl.activateSection(0);
        });

        function _loadContent(callback) {
            //load conference
            ProceedingService.get(pid)
                .then(proceeding => {
                    $ctrl.proceeding = proceeding;
                    delete $ctrl.proceeding._id;
                    callback();
                })
                .catch(err => {
                    console.log("Error getting proceedings: ", err);
                });

            //load quotes
            apiQuotes
                .byPid(pid)
                .then(quote => {
                    $ctrl.quote = quote;
                    $ctrl.enabled =
                        $ctrl.quote.enabled && $ctrl.quote.approveDate;
                })
                .catch(err => {
                    console.log("Error getting quote: ", err);
                });
        }
        $ctrl.loadContent = _loadContent;

        /* Active Section State */
        $ctrl.activateSection = activeSectionIndex => {
            $ctrl.activeSectionIndex = activeSectionIndex;
            $timeout(
                () =>
                    ($ctrl.activeSection =
                        $ctrl.quoteSections[activeSectionIndex]),
                120
            );
        };

        $ctrl.activateNextSection = () => {
            if ($ctrl.activeSectionIndex + 1 < $ctrl.quoteSections.length)
                $ctrl.activateSection($ctrl.activeSectionIndex + 1);
        };

        $ctrl.activatePreviousSection = () => {
            if ($ctrl.activeSectionIndex - 1 >= 0)
                $ctrl.activateSection($ctrl.activeSectionIndex - 1);
        };

        $ctrl.isTextSection = section => section.type === "text";
        $ctrl.isSignature = section => section.type === "signature";
        $ctrl.isAcceptInstructions = section =>
            section && section.type === "accept";
        $ctrl.isPricingInformation = section =>
            section ? section.type === "quotes" : false;
        $ctrl.isProductionSchedule = section =>
            section ? section.type === "production schedule" : false;
        $ctrl.isConferenceInformation = section =>
            section ? section.type === "conference" : false;
        $ctrl.isContactInformation = section =>
            section ? section.type === "contact" : false;
        $ctrl.isContractSections = section =>
            section && section.type === "contract";
        $ctrl.isAcceptQuote = section =>
            section && section.type === "accept quote";

        $ctrl.acceptQuote = (selectedQuote, notes, product, index) => {
            selectedQuote.accepted = true;
            selectedQuote.acceptDate = new Date();

            selectedQuote.response = {
                action: "accept",
                responseDate: selectedQuote.acceptDate,
                notes: notes
            };

            ProceedingStatusService.setQuoteAccepted($ctrl.proceeding, false);

            let promises = [];
            promises.push(apiQuotes.update($ctrl.quote.qid, $ctrl.quote));
            promises.push(
                ProceedingService.update($ctrl.proceeding.pid, $ctrl.proceeding)
            );
            $q.all(promises)
                .then(() => $http.post("/dashboard/triggerRefresh", {})) // need to trigger the update here before we leave to docusign
                .then(() => {
                    // NotificationService.send('success', 'The quote has been successfully accepted.');

                    // console.log('accepted quote: ', selectedQuote);
                    let returnUrl = `${
                        window.location.href.split("#")[0]
                    }${$state.href("dashboard-editor.home", {
                        pid: $ctrl.proceeding.pid
                    })}`;
                    // console.log('returnUrl: ', returnUrl);
                    // console.log('signatory: ',$ctrl.signatory);

                    const templateProceeding = angular.copy($ctrl.proceeding);
                    const templateSignatory = angular.copy($ctrl.signatory);
                    templateProceeding.productionSchedule.forEach(s => {
                        s.date = moment(s.date).format("D MMMM YYYY");
                    });
                    const { start, end } = templateProceeding.dates;
                    templateProceeding.dates.start = PureDateService.format(
                        start,
                        "D MMMM YYYY"
                    );
                    templateProceeding.dates.end = PureDateService.format(
                        end,
                        "D MMMM YYYY"
                    );
                    templateProceeding.location.province = InternationalDataService.expandProvince(
                        $ctrl.proceeding.location.province
                    );
                    templateProceeding.location.country = InternationalDataService.expandCountry(
                        $ctrl.proceeding.location.country
                    );

                    templateSignatory.address.province = InternationalDataService.expandProvince(
                        templateSignatory.address.province
                    );
                    templateSignatory.address.country = InternationalDataService.expandCountry(
                        templateSignatory.address.country
                    );

                    $http
                        .post("/docusign", {
                            brand: $ctrl.brand,
                            returnUrl: returnUrl,
                            proceeding: templateProceeding,
                            acceptedQuote: selectedQuote,
                            sections: OrganizerQuoteService.compilePaSections(
                                $ctrl.paSections,
                                $ctrl.quoteData
                            ),
                            signatureInfo: $ctrl.signatureInformation,
                            signatory: templateSignatory,
                            product: product
                        })
                        .then(
                            successResult => {
                                console.log(successResult);
                                $window.location.href = successResult.data.url;
                                return successResult;
                            },
                            errorResult => {
                                NotificationService.send(
                                    "danger",
                                    "There was an error while redirecting you to DocuSign."
                                );
                                NotificationService.send(
                                    "danger",
                                    "Please contact your editor."
                                );
                                console.log(errorResult);
                                $ctrl.redirectError.err = errorResult;
                                $state.go("dashboard-editor.home");
                            }
                        );

                    EmailService.sendToOrganizers(
                        $ctrl.proceeding,
                        "quote-accepted",
                        {
                            data: {
                                proceeding: $ctrl.proceeding,
                                paLink: DeepLinkService.getPADeepLink(
                                    $ctrl.proceeding.pid
                                )
                            }
                        }
                    );
                });
        };

        $ctrl.reviseQuote = (selectedQuote, notes, index) => {
            let responseDate = new Date();

            selectedQuote.response = {
                action: "revise",
                responseDate: responseDate,
                notes: notes
            };

            ProceedingStatusService.setQuoteRevisionRequested(
                $ctrl.proceeding,
                false
            );

            let promises = [];
            promises.push(apiQuotes.update($ctrl.quote.qid, $ctrl.quote));
            promises.push(
                ProceedingService.update($ctrl.proceeding.pid, $ctrl.proceeding)
            );
            $q.all(promises)
                .then(() => {
                    NotificationService.send(
                        "success",
                        "Thank you! Your response has been submitted."
                    );
                    EmailService.sendToEditors(
                        $ctrl.proceeding,
                        "editor-quote-revise",
                        {
                            data: {
                                proceeding: $ctrl.proceeding,
                                message: notes,
                                reviseLink: DeepLinkService.getReviseQuoteDeepLink(
                                    $ctrl.proceeding.pid,
                                    index
                                )
                            }
                        }
                    );
                    $state.go("dashboard-editor.home");
                })
                .catch(err => console.log(err));
        };

        $ctrl.rejectQuote = notes => {
            let responseDate = new Date();

            ProceedingStatusService.setQuoteDeclined($ctrl.proceeding, false);

            $ctrl.quote.cart.forEach(quote => {
                quote.response = {
                    action: "reject",
                    responseDate: responseDate,
                    notes: notes
                };
            });

            let promises = [];
            promises.push(apiQuotes.update($ctrl.quote.qid, $ctrl.quote));
            promises.push(
                ProceedingService.update($ctrl.proceeding.pid, $ctrl.proceeding)
            );
            $q.all(promises)
                .then(() => {
                    EmailService.sendToOrganizers(
                        $ctrl.proceeding,
                        "quote-rejected",
                        {
                            data: {
                                proceeding: $ctrl.proceeding,
                                quoteLink: DeepLinkService.getQuoteDeepLink(
                                    $ctrl.proceeding.pid
                                )
                            }
                        }
                    );
                    EmailService.sendToEditors(
                        $ctrl.proceeding,
                        "editor-quote-rejected",
                        {
                            data: {
                                proceeding: $ctrl.proceeding,
                                message: notes,
                                reviseLink: DeepLinkService.getReviseQuoteDeepLink(
                                    $ctrl.proceeding.pid
                                )
                            }
                        }
                    );
                    NotificationService.send(
                        "success",
                        "Your response has been submitted."
                    );
                    $state.go("dashboard-editor.home");
                })
                .catch(err => console.log(err));
        };
    }
})();
