(function () {
    "use strict";

    class TOCViewModeService {
        constructor($q, $state) {
            this.$q = $q;
            this.$state = $state;

            // Initialize Dexie database with a new store
            this.db = new Dexie("cpirTOCViewModeSettings");

            // Define database schema
            this.db.version(1).stores({
                viewModeSettings: "pid,isFastMode",
            });

            // Default is standard mode
            this.defaultSetting = false;

            // Session storage for current view mode during session
            this.sessionViewModes = new Map();
        }

        /**
         * Get persistent view mode setting for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @returns {Promise} Promise resolving to view mode setting (true for Fast Mode, false for Standard)
         */
        async getViewMode(pid) {
            try {
                const result = await this.db.viewModeSettings.get(pid);
                return result ? result.isFastMode : this.defaultSetting;
            } catch (error) {
                console.error("Error getting view mode setting:", error);
                return this.defaultSetting;
            }
        }

        /**
         * Save persistent view mode setting for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @param {boolean} isFastMode - Whether Fast Mode is enabled
         * @returns {Promise} Promise resolving when save is complete
         */
        async saveViewMode(pid, isFastMode) {
            try {
                await this.db.viewModeSettings.put({
                    pid,
                    isFastMode,
                });
                return isFastMode;
            } catch (error) {
                console.error("Error saving view mode setting:", error);
                throw error;
            }
        }

        /**
         * Delete persistent view mode setting for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @returns {Promise} Promise resolving when delete is complete
         */
        async deleteViewMode(pid) {
            try {
                await this.db.viewModeSettings.delete(pid);
            } catch (error) {
                console.error("Error deleting view mode setting:", error);
                throw error;
            }
        }

        /**
         * Get default view mode setting
         * @returns {boolean} Default view mode setting (false = standard mode)
         */
        getDefaultViewMode() {
            return this.defaultSetting;
        }

        /**
         * Get session view mode for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @returns {Promise<boolean>} Promise resolving to session view mode (true for Fast Mode, false for Standard)
         */
        async getSessionViewMode(pid) {
            // If no session setting exists, fall back to the persistent setting
            if (!this.sessionViewModes.has(pid)) {
                // Initialize from persistent storage asynchronously
                const isFastMode = await this.getViewMode(pid);
                return this.saveSessionViewMode(pid, isFastMode);
            } else {
                return this.sessionViewModes.get(pid);
            }
        }

        /**
         * Save session view mode setting for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @param {boolean} isFastMode - Whether Fast Mode is enabled
         */
        saveSessionViewMode(pid, isFastMode) {
            this.sessionViewModes.set(pid, isFastMode);
            return isFastMode;
        }

        /**
         * Clear session view mode for a specific proceeding
         * @param {string} pid - Proceeding ID
         */
        clearSessionViewMode(pid) {
            this.sessionViewModes.delete(pid);
        }

        /**
         * Navigate to the Table of Contents with the appropriate persistent mode
         * @param {string} pid - Proceeding ID
         * @returns {Promise} Promise resolving when navigation is complete
         */
        async navigateToTableOfContents(pid) {
            const isFastMode = await this.getViewMode(pid);
            // Set the session view mode to match the persistent mode
            this.saveSessionViewMode(pid, isFastMode);

            if (isFastMode) {
                this.$state.go("dashboard-editor.table-of-contents-fast", {
                    pid,
                });
            } else {
                this.$state.go("dashboard-editor.table-of-contents", {
                    pid,
                });
            }
        }

        /**
         * Navigate to the Table of Contents using current session mode
         * @param {string} pid - Proceeding ID
         */
        async navigateToTableOfContentsWithSessionMode(pid) {
            const isFastMode = await this.getSessionViewMode(pid);

            if (isFastMode) {
                this.$state.go("dashboard-editor.table-of-contents-fast", {
                    pid,
                });
            } else {
                this.$state.go("dashboard-editor.table-of-contents", {
                    pid,
                });
            }
        }

        /**
         * Set the session view mode based on current state
         * @param {string} pid - Proceeding ID
         * @param {string} stateName - Current state name
         * @returns {boolean} The current session view mode
         */
        setSessionViewModeFromState(pid, stateName) {
            const isFastMode =
                stateName === "dashboard-editor.table-of-contents-fast";
            return this.saveSessionViewMode(pid, isFastMode);
        }

        /**
         * Set the persistent view mode based on current state and save it
         * @param {string} pid - Proceeding ID
         * @param {string} stateName - Current state name
         * @returns {Promise} Promise resolving to the saved mode
         */
        setViewModeFromState(pid, stateName) {
            const isFastMode =
                stateName === "dashboard-editor.table-of-contents-fast";
            // Update both persistent and session storage
            this.saveSessionViewMode(pid, isFastMode);
            return this.saveViewMode(pid, isFastMode);
        }
    }

    // Register the service
    angular
        .module("cpir")
        .service("TOCViewModeService", ["$q", "$state", TOCViewModeService]);
})();
