(function() {
    "use strict";

    angular.module("cpir").provider(
        "AccessCheckService",
        class {
            $get(ProceedingStatusService, ProceedingRoleService) {
                let role = ProceedingRoleService;
                let status = ProceedingStatusService;

                //p means proceeding object

                function continueInquiry(p) {
                    if (!p.isWorkshop) {
                        if (role.isEditor(p)) {
                            return true;
                        } else if (role.isOrganizer(p)) {
                            return status.isInquiryStarted(p);
                        }
                    }
                    return false;
                }
                function quoteSummary(p) {
                    if (!p.isWorkshop) {
                        return (
                            role.isOrganizer(p) && !status.isInquiryStarted(p)
                        );
                    }
                    return false;
                }
                function authorKit(p) {
                    if (role.isEditor(p) || role.isVendor(p)) {
                        return status.isAtLeastAkSetup(p);
                    } else if (role.isOrganizer(p)) {
                        return status.isAtLeastAkPending(p);
                    } else if (role.isAuthor(p)) {
                        return status.isAkActive(p); // only when ak active
                    }
                }
                function production(p) {
                    if (role.isEditor(p) || role.isVendor(p)) {
                        return status.isAtLeastAkSetup(p);
                    } else if (role.isOrganizer(p)) {
                        return status.isAtLeastAkActive(p);
                    } else return false;
                }
                function configuration(p) {
                    return role.isEditor(p);
                }
                function selectQuote(p) {
                    if (!p.isWorkshop) {
                        if (role.isEditorOrOrganizer(p)) {
                            return status.isAtLeastQuoted(p);
                        }
                    }
                    return false;
                }
                function publicationAgreement(p) {
                    if (!p.isWorkshop) {
                        if (
                            role.isEditorOrOrganizer(p) ||
                            role.isSignatory(p)
                        ) {
                            return status.isAtLeastAccepted(p);
                        }
                    }
                    return false;
                }
                function complianceReview(p) {
                    if (p.isSponsored) {
                        return false;
                    }
                    if (!p.isWorkshop) {
                        return (
                            role.isOrganizer(p) &&
                            status.isAtLeastComplianceReview(p) &&
                            !p.isSponsored
                        );
                    }
                    return false;
                }

                function reviseQuote(p) {
                    if (!p.isWorkshop) {
                        if (role.isEditor(p)) {
                            return (
                                status.isAtLeastQuoted(p) &&
                                !status.isAtLeastAccepted(p) &&
                                !status.isQuoted(p)
                            ); // revised or rejected status only
                        }
                    }
                    return false;
                }

                function videos(p) {
                    const videoSubmission =
                        (p.configuration.settings.ak &&
                            p.configuration.settings.ak.videoSubmission) ||
                        {};
                    const videoSubmissionsEnabled = videoSubmission.enabled;
                    if (
                        videoSubmissionsEnabled &&
                        (role.isEditor(p) ||
                            role.isVendor(p) ||
                            role.isOrganizer(p))
                    ) {
                        return status.isAtLeastAkSetup(p);
                    } else if (role.isOrganizer(p)) {
                        return status.isAtLeastAkActive(p);
                    } else return false;
                }

                return {
                    continueInquiry,
                    quoteSummary,
                    authorKit,
                    production,
                    configuration,
                    selectQuote,
                    publicationAgreement,
                    complianceReview,
                    reviseQuote,
                    videos
                };
            }
        }
    );
})();
