(function() {
    "use strict";

    angular.module("cpir").filter("entryAuthorsHtmlFilter", () => {
        return entry => {
            if (!entry) return entry;
            if (!entry.authors) return entry;

            if (entry.class === "SB") {
                let authorHtml = "";
                entry.authors.forEach(a => {
                    let authorName = `${a.givenName} ${a.surname}<br/>`;
                    authorHtml += authorName;
                });
                return authorHtml;
            } else {
                return "";
            }
        };
    });
})();
