(function() {
    "use strict";

    angular
        .module("cpir")
        .filter(
            "proceedingOrderByFilter",
            (
                orderByFilter,
                proceedingOrderBySubmissionDateFilterFilter,
                proceedingOrderBySubmittedDateFilterFilter,
                proceedingOrderByPressDateFilterFilter
            ) => {
                return (proceedings, selectedOrder) => {
                    switch (selectedOrder) {
                        case "acronym":
                            return orderByFilter(proceedings, "acronym", false);
                        case "acronym_desc":
                            return orderByFilter(proceedings, "acronym", true);
                        case "created_date_asc":
                            return orderByFilter(
                                proceedings,
                                "createDate",
                                false
                            );
                        case "created_date_desc":
                            return orderByFilter(
                                proceedings,
                                "createDate",
                                true
                            );
                        case "submitted_date_asc":
                            return proceedingOrderBySubmittedDateFilterFilter(
                                proceedings
                            );
                        case "submitted_date_desc":
                            return proceedingOrderBySubmittedDateFilterFilter(
                                proceedings,
                                true
                            );
                        case "submission_date_asc":
                            return proceedingOrderBySubmissionDateFilterFilter(
                                proceedings
                            );
                        case "submission_date_desc":
                            return proceedingOrderBySubmissionDateFilterFilter(
                                proceedings,
                                true
                            );
                        case "press_date_asc":
                            return proceedingOrderByPressDateFilterFilter(
                                proceedings
                            );
                        case "press_date_desc":
                            return proceedingOrderByPressDateFilterFilter(
                                proceedings,
                                true
                            );
                        case "best_match":
                            //Sort the filtered proceedings by the search score
                            return proceedings.sort(
                                (
                                    { decorations: { score: a } },
                                    { decorations: { score: b } }
                                ) => b - a
                            );
                    }
                };
            }
        );
})();
