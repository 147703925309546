(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCAddEntryController",
        class {
            constructor(
                pid,
                $state,
                $stateParams,
                TOCValuesService,
                TocEntriesApiService,
                NotificationService,
                TOCViewModeService,
            ) {
                this.pid = pid;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.TOCValuesService = TOCValuesService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.NotificationService = NotificationService;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                this.entry = angular.copy(this.TOCValuesService.recordTemplate);
                this.entry.pid = this.pid;
            }

            saveChanges() {
                if (!this.entry.enablePromoDates) {
                    this.entry.promoDates = null;
                }
                // force submitter email to be lowercase (for author kit user lookup)
                this.entry.submitterEmail =
                    this.entry.submitterEmail &&
                    this.entry.submitterEmail.toLowerCase().trim();

                // remove empty authors
                this.entry.authors = this.entry.authors.filter(
                    (author) =>
                        author.givenName ||
                        author.surname ||
                        author.affiliation ||
                        author.email ||
                        author.orcid,
                );

                this.saveEntry()
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The entry was created successfully.",
                        );
                        this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                            this.pid,
                        );
                    })
                    .catch((e) => {
                        console.log(e);
                        this.NotificationService.send(
                            "warning",
                            "The entry could not be created.",
                        );
                    });
            }

            saveEntry() {
                return this.TocEntriesApiService.insertEntries(this.entry);
            }

            updateEntry(entry) {
                this.entry = entry;
            }

            editAuthors() {
                this.saveEntry()
                    .then((result) => {
                        console.log("save entry result: ", result);
                        this.$state.go(
                            "dashboard-editor.table-of-contents-edit-entry-authors",
                            {
                                entryId: result.eid,
                                pid: this.pid,
                            },
                        );
                    })
                    .catch((e) => console.log(e));
            }

            editFiles() {
                this.saveEntry()
                    .then((result) => {
                        console.log("save entry result: ", result);
                        this.$state.go(
                            "dashboard-editor.table-of-contents-edit-entry-files",
                            {
                                entryId: result.eid,
                                pid: this.pid,
                            },
                        );
                    })
                    .catch((e) => console.log(e));
            }

            classChanged() {
                switch (this.tocRecord.class) {
                    case "SB":
                        this.tocRecord.type = "AP";
                        break;
                    case "SD":
                        this.tocRecord.type = "SD";
                        break;
                }
            }

            returnToToc() {
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }
        },
    );
})();
