(function() {
    "use strict";

    angular.module("cpir").provider(
        "DefaultSettingsService",
        class {
            $get($location, authService) {
                let createDeepLink = params => {
                    let brand = authService.getBrand();
                    let protocol = $location.protocol();
                    let host = $location.host();
                    let port = $location.port();

                    let urlParams = "";

                    params.forEach((param, index) => {
                        if (index > 0) urlParams += "&";
                        urlParams += param.name + "=" + param.value;
                    });

                    return this.port !== 443 && this.port !== 80
                        ? `${protocol}://${host}:${port}/${brand.name}#!/auth/login?${urlParams}`
                        : `${protocol}://${host}/${brand.name}#!/auth/login?${urlParams}`;
                };

                return {
                    createDeepLink,
                    dateFormat,
                    dateTimeFormat,
                    tinymceOptions,
                    getEditorSignature
                };
            }
        }
    );

    let getEditorSignature = proceeding => {
        console.log(proceeding.editor);
        let html = "";
        if (proceeding.editor.name)
            html = html + proceeding.editor.name + "<br/>";
        if (proceeding.editor.title)
            html = html + proceeding.editor.title + "<br/>";
        if (proceeding.editor.division)
            html = html + proceeding.editor.division + "<br/>";
        if (proceeding.editor.company)
            html = html + proceeding.editor.company + "<br/>";
        if (proceeding.editor.email)
            html = html + proceeding.editor.email + "<br/>";
        if (proceeding.editor.phone)
            html = html + proceeding.editor.phone + "<br/>";
        if (proceeding.editor.fax)
            html = html + proceeding.editor.fax + "<br/>";
        return html;
    };

    let tinymceOptions = {
        menubar: false,
        plugins: [
            "lists",
            "noneditable",
            "code",
            "hr",
            "link",
            "table",
            "textcolor"
        ],
        toolbar:
            "undo redo | styleselect | bold italic | alignleft aligncenter | numlist bullist | forecolor backcolor | link | table | code",
        noneditable_regexp: [/\[\[([^\]\]]*)]]/g], // matches "[[placeholder]]"
        branding: false,
        height: "350",
        skin: "lightgray",
        theme: "modern"
    };

    let dateFormat = "dd MMMM y";

    let dateTimeFormat = "dd MMM y h:mm a";
})();
