(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfApprovalPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/ak-conf-approval-panel/ak-conf-approval-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            saveConfiguration() {
                this.onSave({
                    props: { configuration: this.configuration }
                });
            }
        }
    });
})();
