(function() {
    "use strict";

    angular.module("cpir").component("icxCorrectionsPanel", {
        bindings: {
            proceeding: "<",
            icxConferenceDetails: "<",
            originalConferenceDetails: "<",
            checkingIcx: "<",
            applyIcx: "&",
            onChangeConferenceDetailSource: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/icx-corrections-panel/icx-corrections-panel.component.html",
        controller: class {
            constructor($scope, $uibModal) {
                this.$scope = $scope;
                this.$uibModal = $uibModal;
            }

            $onInit() {
                this.compareDetails = false;
                this.showIcxConferenceData = true;
                this.conferenceDataFromIcx=false;
                this.conferenceDetails = this.icxConferenceDetails;
                this.changeConferenceData('icx');
            }

            $onChanges(changes) {
                if(changes.applyIcx === true)
                    this.changeConferenceData('icx');
                else if(changes.applyIcx === false)
                    this.changeConferenceData('cps');

                if(changes.icxConferenceDetails) {
                    let corrValArray = _.values(this.icxConferenceDetails);

                   this.checkNoIcxAvaialbleArray =  corrValArray.filter(function(detail) {
                        if(detail.value.content !== "")
                            return detail;
                    });

                    this.isAnyIcxDataAvailable = this.checkNoIcxAvaialbleArray.length > 0?true:false;

                    this.comparisonDetails = corrValArray.filter(function(detail) {
                            if(detail.value.isMatching === false)
                                return detail;
                        }
                    );
                }

                if(changes.originalConferenceDetails) {
                    let corrValArray = _.values(this.originalConferenceDetails);

                    this.originalDetails = corrValArray.filter(function(detail) {
                            if(detail.value.isMatching === false)
                                return detail;
                        }
                    );
                }

                this.comparisonList = _.orderBy(this.comparisonDetails.concat(this.originalDetails), ['key'],['asc']).filter(function(detail) {
                        if(detail.value.isMatching === false)
                            return detail;
                    }
                );

            }

            changeConferenceData(type) {
                if(type === "icx") {
                    this.showIcxConferenceData = true;
                    this.conferenceDataFromCps = false;
                    this.conferenceDetails = this.icxConferenceDetails;
                    this.conferenceDataType = "icx";
                    this.applyIcx = true;
                } else {
                    this.showIcxConferenceData = false;
                    this.conferenceDataFromIcx=false;
                    this.conferenceDetails = this.originalConferenceDetails;
                    this.conferenceDataType = "cps";
                    this.applyIcx = false;
                }
                this.onChangeConferenceDetailSource({ "applyIcx": this.applyIcx});
            }


        }
    });
})();
