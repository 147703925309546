(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkCopyrightReleaseController",

        class {
            constructor(
                pid,
                entryId,
                standalone,
                $state,
                $stateParams,
                $uibModal,
                EnvironmentService,
                NotificationService,
                ProceedingService,
                ProceedingRoleService,
                AKService,
                TocEntriesApiService,
                authService,
                DeepLinkService,
                CopyrightService,
                Cpir2Service
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.isStandalone = !!standalone;
                this.TocEntriesApiService = TocEntriesApiService;
                this.EnvironmentService = EnvironmentService;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.AKService = AKService;
                this.defaultTitleStub = "[ak started by author";
                this.authService = authService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.$uibModal = $uibModal;
                this.DeepLinkService = DeepLinkService;
                this.CopyrightService = CopyrightService;
                this.Cpir2Service = Cpir2Service;
            }

            isUnset(field) {
                return (
                    _.isNil(field) || // null or undefined
                    (_.isString(field) && _.trim(field).length < 1) // empty string
                );
            }

            isCopyrightDetailsEnabled() {
                return (
                    this.entryIsLoaded &&
                    (this.isCopyrightIee() || this.isOpenAccessEnabled()) &&
                    !this.didPurchaseOpenAccessCopyright &&
                    !this.didSubmitIeeeCopyright
                );
            }

            isOpenAccessEnabled() {
                return (
                    (this.proceeding &&
                        this.entry &&
                        this.proceeding.configuration.settings.ak.copyright
                            .isOpenAccessEnabled &&
                        !this.didPurchaseOpenAccessCopyright) ||
                    false
                );
            }

            isCopyrightIee() {
                return (
                    this.proceeding &&
                    this.proceeding.configuration.settings.ak.copyright.type ===
                        "ieee"
                );
            }

            canSubmit() {
                return !this.isCopyrightDetailsEnabled() && !this.isStandalone;
            }

            $onInit() {
                this.entryIsLoaded = false;
                this.submitterEmailReadOnly = true;
                this.copyright = {};
                this.copyrightForm = {};
                this.AKService.setActive(this.pid, this.entryId);

                this.currentDate = new Date();
                this.isProduction = this.EnvironmentService.isProduction();

                this.proceeding = this.AKService.getProceeding();
                this.proceedingAsync = this.ProceedingService.get(
                    this.$stateParams.pid
                ).then(proceeding => {
                    this.proceeding = proceeding;
                    if (this.ProceedingRoleService.isEditor(this.proceeding)) {
                        this.submitterEmailReadOnly = false;
                    }
                    return proceeding;
                });

                this.TocEntriesApiService.getEntry(this.entryId).then(entry => {
                    this.entry = entry;

                    if (
                        this.entry.openAccessCopyright &&
                        this.entry.openAccessCopyright.purchased
                    ) {
                        this.didPurchaseOpenAccessCopyright = true;
                    }

                    this.proceedingAsync
                        .then(proceeding =>
                            this.CopyrightService.getEntryCopyright(
                                proceeding,
                                this.entry
                            )
                        )
                        .then(copyrightType => {
                            if (copyrightType === "IEEE") {
                                this.didSubmitIeeeCopyright = true;
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });

                    this.copyright.submitterName = this.entry.submitterName;
                    this.copyright.submitterEmail = this.entry.submitterEmail;
                    this.copyright.authors = this.getAuthors();

                    // load title only if it's not the default one
                    if (
                        !_.toLower(this.entry.title).includes(
                            this.defaultTitleStub
                        )
                    ) {
                        this.copyright.title = this.entry.title;
                    }

                    let needToUpdateEntry = false;
                    // set the SubmitterEmail if not already set
                    if (this.isUnset(this.entry.submitterEmail)) {
                        console.log("Auto-filling submitter email");
                        this.entry.submitterEmail = this.authService.getProfile().email;
                        this.copyright.submitterEmail = this.entry.submitterEmail;
                        needToUpdateEntry = true;
                    }

                    // set articleId if not already set
                    if (this.isUnset(this.entry.articleId)) {
                        console.log(
                            "Article Id missing for entry. Creating a new one..."
                        );
                        this.entry.articleId = Date.now();
                        needToUpdateEntry = true;
                    }

                    // apply updates if needed
                    if (needToUpdateEntry) {
                        this.updateEntry()
                            .then(() => {
                                console.log("Entry updated");
                                this.entryIsLoaded = true;
                            })
                            .catch(err => {
                                console.log("Error updating entry: ", err);
                                this.entryIsLoaded = true;
                            });
                    } else {
                        this.entryIsLoaded = true;
                    }
                });

                if (this.isProduction) {
                    this.copyrightUrl =
                        "https://ecopyright.ieee.org/ECTT/IntroPage.jsp";
                } else {
                    this.copyrightUrl =
                        "https://ecopyrightqa-beta.ieee.org/ECTT/IntroPage.jsp";
                }
            }

            isLastStep() {
                return this.AKService.isLastStep();
            }

            isNextEnabled() {
                return this.proceeding;
            }

            updateEntry() {
                // copy form data to entry
                this.entry.submitterEmail = this.copyright.submitterEmail;
                this.entry.title = this.copyright.title;

                // normalize email
                this.entry.submitterEmail =
                    this.entry.submitterEmail &&
                    this.entry.submitterEmail.toLowerCase().trim();
                return this.TocEntriesApiService.updateEntries(this.entry).then(
                    () => {
                        return this.TocEntriesApiService.broadcastEntries(
                            this.pid
                        );
                    }
                );
            }

            goToPreviousStep() {
                if (this.isStandalone) {
                    this.Cpir2Service.redirectToAuthorDashboard(
                        this.pid
                    ).catch(err =>
                        console.error(
                            "Error redirection to author dashboard: ",
                            err
                        )
                    );
                } else {
                    this.AKService.goToPreviousStep();
                }
            }

            goToNextStep() {
                this.AKService.goToNextStep();
            }

            getAuthors() {
                return this.AKService.getAuthorNames(this.entry);
            }

            getReturnUrl() {
                return this.AKService.getReturnUrl(this.pid, this.entryId);
            }

            goToOaRelease() {
                this.updateEntry().then(() => {
                    this.AKService.goToOaRelease(this.$stateParams);
                });
            }
        }
    );
})();
