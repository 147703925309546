(function () {
    "use strict";

    angular.module("cpir").component("summaryModal", {
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/summary-modal/summary-modal.component.html",
        controller: SummaryModalController,
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
        },
    });

    SummaryModalController.$inject = [
        "$timeout",
        "Chart",
        "TOCValuesService",
        "$uibModal",
    ];

    function SummaryModalController(
        $timeout,
        Chart,
        TOCValuesService,
        $uibModal,
    ) {
        var $ctrl = this;

        // Lifecycle hooks
        $ctrl.$onInit = onInit;

        // Public methods
        $ctrl.cancel = cancel;
        $ctrl.getSummaryData = getSummaryData;
        $ctrl.getStatusData = getStatusData;
        $ctrl.getFailedQACount = getFailedQACount;
        $ctrl.getNoShowCount = getNoShowCount;
        $ctrl.showNoShowEntryList = showNoShowEntryList;
        $ctrl.showQuoteMedia = showQuoteMedia;

        // Private variables
        var entries;
        var proceeding;
        var articles;
        var stampsByEntry;
        var statusChart;
        var statusMap = {};

        // Implementation
        function onInit() {
            entries = $ctrl.resolve.entries || [];
            proceeding = $ctrl.resolve.proceeding || {};
            articles = $ctrl.resolve.articles || {};
            stampsByEntry = $ctrl.resolve.stampsByEntry || {};
            $ctrl.proceeding = proceeding;
            $ctrl.articles = articles;

            // Create mapping for production statuses
            statusMap = TOCValuesService.productionStatusOptions.reduce(
                function (map, option) {
                    map[option.value] = option.name;
                    return map;
                },
                {},
            );

            // Initialize chart after DOM is ready
            $timeout(function () {
                initializeStatusChart();
            }, 100);
        }

        function cancel() {
            $ctrl.dismiss();
        }

        function showQuoteMedia() {
            $uibModal.open({
                component: "cpirTocQuoteMediaModal",
                size: "lg",
            });
        }

        function getSummaryData() {
            var totalCount = entries.length;
            var articleCount = 0;
            var totalPageCount = 0;
            var stampedCount = 0;
            var doiCount = 0;
            var papersCount = 0;

            entries.forEach(function (entry) {
                // Count papers - papers are entries with class=SB and type=AP
                if (entry.class === "SB" && entry.type === "AP") {
                    papersCount++;
                }

                // Count articles for backward compatibility
                if (
                    entry.entryType === "ARTICLE" ||
                    (entry.class === "SB" && entry.type === "AP")
                ) {
                    articleCount++;
                }

                // Count pages
                if (entry.pages) {
                    totalPageCount += entry.pages;
                }

                // Check for DOI
                if (entry.doi) {
                    doiCount++;
                }
            });

            // Count stamps from stampsByEntry object (stamp files)
            stampedCount = Object.keys(stampsByEntry).length;

            return {
                totalCount: totalCount,
                articleCount: articleCount,
                papersCount: papersCount,
                totalPageCount: totalPageCount,
                stampedCount: stampedCount,
                doiCount: doiCount,
            };
        }

        function getFailedQACount() {
            return entries.filter(function (entry) {
                return entry.productionStatus === "failed_qa";
            }).length;
        }

        function getNoShowCount() {
            return entries.filter(function (entry) {
                return entry.noShow === true || entry.noShow === "true";
            }).length;
        }

        function showNoShowEntryList() {
            // Filter entries with noShow flag
            var noShowEntries = entries.filter(function (entry) {
                return entry.noShow === true || entry.noShow === "true";
            });

            // Open a modal to display the no-show entries
            $uibModal.open({
                component: "noShowPanel",
                size: "lg",
                resolve: {
                    entries: function () {
                        return noShowEntries;
                    },
                    isSummaryPanel: function () {
                        return true;
                    },
                },
            });
        }

        function getStatusData() {
            var statusCounts = {};
            var statusLabels = [];
            var statusData = [];
            var statusColors = [
                "#4CAF50", // Green
                "#2196F3", // Blue
                "#FFC107", // Amber
                "#FF5722", // Deep Orange
                "#9C27B0", // Purple
                "#607D8B", // Blue Grey
                "#795548", // Brown
                "#009688", // Teal
                "#E91E63", // Pink
                "#3F51B5", // Indigo
            ];

            // Count production statuses
            entries.forEach(function (entry) {
                var status = entry.productionStatus || "undefined";
                var displayStatus = statusMap[status] || "Unknown";

                if (!statusCounts[status]) {
                    statusCounts[status] = {
                        count: 0,
                        displayName: displayStatus,
                    };
                }
                statusCounts[status].count++;
            });

            // Convert to arrays for chart
            Object.keys(statusCounts).forEach(function (status, index) {
                statusLabels.push(statusCounts[status].displayName);
                statusData.push(statusCounts[status].count);
            });

            return {
                labels: statusLabels,
                data: statusData,
                colors: statusColors.slice(0, statusLabels.length),
            };
        }

        function initializeStatusChart() {
            var ctx = document.getElementById("statusChart");
            if (!ctx) return;

            var chartData = getStatusData();

            if (statusChart) {
                statusChart.destroy();
            }

            statusChart = new Chart(ctx, {
                type: "doughnut",
                data: {
                    labels: chartData.labels,
                    datasets: [
                        {
                            data: chartData.data,
                            backgroundColor: chartData.colors,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                            },
                            label: function (tooltipItem, data) {
                                var dataset =
                                    data.datasets[tooltipItem.datasetIndex];
                                var total = dataset.data.reduce(
                                    function (previousValue, currentValue) {
                                        return previousValue + currentValue;
                                    },
                                );
                                var currentValue =
                                    dataset.data[tooltipItem.index];
                                var percentage = Math.round(
                                    (currentValue / total) * 100,
                                );
                                return (
                                    currentValue +
                                    " entries (" +
                                    percentage +
                                    "%)"
                                );
                            },
                        },
                    },
                },
            });
        }
    }
})();
