(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCEditEntryAuthorsController",
        class {
            constructor(
                pid,
                entryId,
                $state,
                ProceedingService,
                NotificationService,
                TocEntriesApiService,
                AKService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$state = $state;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.AKService = AKService;
                this.isAuthorFormValid = false;
            }

            $onInit() {
                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;
                });

                this.TocEntriesApiService.getEntry(this.entryId).then(entry => {
                    this.entry = entry;
                    if (this.entry.authors && this.entry.authors.length) {
                        this.authors = this.entry.authors;
                    } else {
                        let initialAuthor = {
                            givenName: "",
                            surName: ""
                        };
                        this.authors = [initialAuthor];
                    }
                    this.activeAuthorIndex = 0;
                    this.activeAuthor = this.authors[0];
                });

                this.isAuthorFormValid = false;
            }

            canSaveAuthor() {
                return this.isAuthorFormValid;
            }

            isAuthorFormValidFunc(author, valid) {
                this.isAuthorFormValid = valid;
            }

            addAuthor() {
                this.authors.push({
                    firstName: "",
                    lastName: ""
                });

                this.activateAuthor(this.authors.length - 1);
            }

            removeActiveAuthor() {
                this.authors.splice(this.activeAuthorIndex, 1);

                if (this.activeAuthorIndex === 0) this.activateAuthor(0);
                else this.activateAuthor(this.activeAuthorIndex - 1);
            }

            activateAuthor(authorIndex) {
                this.activeAuthorIndex = authorIndex;
                this.activeAuthor = this.authors[authorIndex];
            }

            updateActiveAuthor(author) {
                this.activeAuthor = author;
            }

            returnToEditEntry() {
                this.$state.go(
                    "dashboard-editor.table-of-contents-edit-entry",
                    {
                        pid: this.pid,
                        entryId: this.entryId
                    }
                );
            }

            cancel() {
                this.returnToEditEntry();
            }

            saveChanges() {
                this.entry.authors = this.authors;
                this.TocEntriesApiService.updateEntries(this.entry).then(() => {
                    this.NotificationService.send(
                        "success",
                        "The authors were successfully saved."
                    );
                    this.returnToEditEntry();
                });
            }
        }
    );
})();
