(function() {
    "use strict";

    angular.module("cpir").provider(
        "UuidService",
        class {
            $get($http) {
                function catchError(err) {
                    console.log(err);
                    throw err.data;
                }

                const getUuid = () =>
                    $http
                        .get(`uuid`)
                        .catch(catchError)
                        .then(response => response.data);

                const getB62UUID = () =>
                    $http
                        .get("uuid/b62")
                        .catch(catchError)
                        .then(response => response.data);

                return {
                    getUuid,
                    getB62UUID
                };
            }
        }
    );
})();
