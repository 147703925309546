(function() {
    "use strict";

    angular.module("cpir").component("cpirConfirmationModal", {
        bindings: {
            size: "@",
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/_shared/components/confirm-modal/confirm-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.message = this.resolve.message
                    ? this.resolve.message
                    : "Are you sure you want to continue?";

                this.title = this.resolve.title
                    ? this.resolve.title
                    : "Caution";

                this.confirmButtonDisabled = this.resolve.confirmButtonDisabled
                    ? this.resolve.confirmButtonDisabled
                    : false;

                this.confirmButtonText = this.resolve.confirmButtonText
                    ? this.resolve.confirmButtonText
                    : "Continue";

                this.cancelButtonDisabled = this.resolve.cancelButtonDisabled
                    ? this.resolve.cancelButtonDisabled
                    : false;

                this.cancelButtonText = this.resolve.cancelButtonText
                    ? this.resolve.cancelButtonText
                    : "Cancel and Go Back";
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            confirmButtonClicked() {
                this.close();
            }
        }
    });
})();
