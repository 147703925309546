(function() {
    "use strict";

    angular.module("cpir").filter("emptyValueTDBFilter", () => {
        return value => {
            if (value) return value;
            return "TBD";
        };
    });
})();
