(function() {
    angular.module("cpir").component("cpirComplianceContactSwitcher", {
        bindings: {
            contacts: "<",
            activeContactIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/contacts-panel/components/contact-switcher/contact-switcher.component.html",
        controller: class {
            buttonClicked($index) {
                this.onSwitch({ contactIndex: $index });
            }
        }
    });
})();
