(function() {
    angular.module("cpir").component("cpirCifSummaryContactsSwitcher", {
        bindings: {
            contacts: "<",
            activeContactIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/contacts/components/contact-switcher/contact-switcher.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            buttonClicked($index) {
                this.onSwitch({ contactIndex: $index });
            }
        }
    });
})();
