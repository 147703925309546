(function() {
    "use strict";

    angular.module("cpir").provider(
        "TosService",
        class {
            $get($q, $http, store, moment) {
                function getData(result) {
                    return result.data;
                }

                function catchError(err) {
                    console.log(err);
                    throw err.data;
                }

                const didAccept = email => {
                    if (!email)
                        return $q.reject(
                            new Error("Email address is required")
                        );

                    const previousResult = store.get(`tos.accepted.${email}`);
                    if (
                        previousResult &&
                        previousResult.accepted &&
                        moment().isBefore(previousResult.expireAt)
                    ) {
                        return $q.when(previousResult);
                    } else {
                        return $http
                            .get(
                                `/tos/did-accept?email=${encodeURIComponent(
                                    email
                                )}`
                            )
                            .catch(catchError)
                            .then(getData)
                            .then(result => {
                                store.set(`tos.accepted.${email}`, {
                                    accepted: result,
                                    expireAt: moment().add(1, "day")
                                });
                                return result;
                            });
                    }
                };

                const recordAcceptance = email => {
                    if (!email) {
                        return $q.reject(
                            new Error("Email address is required")
                        );
                    }
                    return $http
                        .post(`/tos/accept`, { email: email })
                        .catch(catchError)
                        .then(getData);
                };

                return {
                    didAccept,
                    recordAcceptance
                };
            }
        }
    );
})();
