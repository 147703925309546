(function() {
    "use strict";

    angular.module("cpir").component("cpirCifContactsAddContactButton", {
        bindings: {
            addContact: "&",
            isDisabled: "<"
        },
        templateUrl:
            "application/cpir/cif/contacts/components/add-contact-button/add-contact-button.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            buttonClicked() {
                if (this.isDisabled === false) {
                    this.addContact({ name: "", age: 4 });
                }
            }
        }
    });
})();
