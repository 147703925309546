(function() {
    "use strict";

    /*
    Since the current db stores dates as strings, they must
    be replaced at some point to use dates for components that
    will not convert it themselves. This service will scan an
    object for valid date strings and convert them to dates.
     */

    angular.module("cpir").provider(
        "DateFixerService",
        class {
            constructor() {}
            $get($filter, moment) {
                return {
                    fixDates: function dateFixer(input) {
                        // Reject and return non-objects
                        if (typeof input !== "object") return input;

                        // Iterate over key in object
                        for (let key in input) {
                            // Check that key belongs to object and not
                            // prototype
                            if (!input.hasOwnProperty(key)) continue;

                            // Get the value of the key and try to convert
                            // it into a date
                            let value = input[key];

                            let possibleDate = moment(
                                value,
                                moment.ISO_8601,
                                true
                            );

                            // Check if possible date is valid string and date
                            if (
                                typeof value === "string" &&
                                possibleDate.isValid()
                            ) {
                                // Convert to date object
                                input[key] = new Date(possibleDate);
                            } else if (typeof value === "object") {
                                // If value is an object, recurse into
                                // the object to find date strings
                                dateFixer(value);
                            }
                        }
                    },
                    changeToString: function changeToString(input, format) {
                        // Reject and return non-objects
                        if (typeof input !== "object") return input;

                        // Iterate over key in object
                        for (let key in input) {
                            // Check that key belongs to object and not
                            // prototype
                            if (!input.hasOwnProperty(key)) continue;

                            // Get the value of the key and try to convert
                            // it into a date
                            let value = input[key];

                            let possibleDate = moment(
                                value,
                                moment.ISO_8601,
                                true
                            );

                            // Check if possible date is valid string and date
                            if (
                                typeof value === "string" &&
                                possibleDate.isValid()
                            ) {
                                // Convert to date object
                                input[key] = new Date(input[key]);

                                input[key] = $filter("date")(
                                    input[key],
                                    format
                                );
                            } else if (typeof value === "object") {
                                // If value is an object, recurse into
                                // the object to find date strings
                                changeToString(value);
                            }
                        }
                    }
                };
            }
        }
    );
})();
