(function() {
    "use strict";

    angular.module("cpir").provider(
        "CsdlService",
        class {
            $get($http) {
                function catchError(err) {
                    console.log(err);
                    throw err.data;
                }

                const exportToCsdl = (pid, includeExtras) => {
                    const queryString = includeExtras ? `?includeExtras=1` : "";
                    return $http
                        .post(`/export/csdl/${pid}${queryString}`)
                        .catch(catchError);
                };

                const isConferenceInCsdl = (proceeding) => {
                    console.log("inside csdl aervice -------------- ");
                    return $http
                        .get(`/isConferenceInCsdl/${proceeding.acronym.toLowerCase()}/${proceeding.year}/${proceeding.toc.isContinuousPub}/
                ${proceeding.toc.continuousPubStatus}`)
                        .catch((e) => {
                            return true;
                        })
                        .then(result => {
                            if(result.data === "not found")
                                return false;
                            else
                                return true;
                        });

                };

                const checkDoiDuplicate = (doi, acronym, year) => {
                    doi = encodeURIComponent(doi);
                    acronym = encodeURIComponent(acronym);
                    return $http
                        .get(`/checkDoiDuplicate/${doi}/${acronym}/${year}`)
                        .catch((e) => {
                            return true;
                            })
                        .then(result => {
                            if(result.data === "not found")
                                return false;
                            else
                                return true;
                            });

                };

                const checkConferenceDetailsInICX = (conferenceNumber, acronym, year) => {
                    console.log("inside checkConferenceDetailsInICX");
                    return $http
                        .get(`/getConferenceDetailsFromICX/${conferenceNumber}/${acronym}/${year}`)
                        .catch((e) => {
                            console.log(e);
                            return false;
                        })
                        .then(result => {
                            if(!result || result.data === "not found")
                                return false;
                            else {
                                return result;
                            }
                        });
                };

                return {
                    exportToCsdl,
                    checkDoiDuplicate,
                    isConferenceInCsdl,
                    checkConferenceDetailsInICX
                };

            }
        }
    );
})();
