(function() {
    "use strict";

    angular.module("cpir").component("cpirTocQuoteMediaModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/quote-media-modal/quote-media-modal.component.html",
        controller: class {
            constructor($stateParams, apiQuotes) {
                this.$stateParams = $stateParams;
                this.apiQuotes = apiQuotes;
            }

            $onInit() {
                this.loading = true;

                this.apiQuotes
                    .byPid(this.$stateParams.pid)
                    .then(quote => {
                        this.loading = false;
                        this.quote = quote;
                        this.acceptedQuote = quote.cart.find(c => c.accepted);
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                    });
            }

            closeButtonClicked() {
                this.dismiss();
            }

            isAcceptedQuoteFound() {
                return this.acceptedQuote;
            }
        }
    });
})();
