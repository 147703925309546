(function() {
    "use strict";

    angular.module("cpir").component("cpirCifConferenceConferencePanel", {
        bindings: {
            proceeding: "<",
            showSaveButton: "<",
            onUpdate: "&",
            onSave: "&",
            isParentConfTab: "<",
            enableConferenceTitleCheck: "<"
        },
        templateUrl:
            "application/cpir/cif/conference/components/conference-panel/conference-panel.component.html",
        controller: class {
            constructor(
                $state,
                $scope,
                InternationalDataService,
                authService,
                ProceedingRoleService,
                moment,
                PureDateService
            ) {
                this.$state = $state;
                this.authService = authService;
                this.$scope = $scope;
                this.ProceedingRoleService = ProceedingRoleService;
                this.InternationalDataService = InternationalDataService;
                this.PureDateService = PureDateService;
                this.moment = moment;
                this.datesModel = {};
            }

            $onInit() {
                this.proceedingForm = {};

                this.brand = this.authService.getBrand();

                this.countryOptions = this.InternationalDataService.getCountries();

                if (this.proceeding) {
                    this.proceedingChanged();
                } else {
                    this.proceeding = {};
                    this.proceeding.brand = this.brand;
                    this.proceeding.location = {};
                    this.proceeding.location.country = "US";
                    this.proceeding.hasWorkshops = false;
                    this.proceeding.isIEEESponsored = false;
                    this.proceeding.hasSeparatePublicationsInXplore = false;
                }

                if (this.brand.isPubHub) {
                    this.proceeding.isSponsored = true;
                }

                if(this.enableConferenceTitleCheck)
                    this.errorPopoverTitle =  "Field Validation";
                else
                    this.errorPopoverTitle = "Missing Fields";

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];

                this.$scope.$watch(
                    () => this.proceedingForm.$valid,
                    () => this.proceedingChanged()
                );
            }

            $onChanges() {
                this.proceedingChanged();
            }

            getButtonLabel() {
                if (this.isParentConfTab) {
                    return "Save Parent Conference";
                } else
                    return "Save";
            }

            isEditor() {
                if (!this.proceeding) return false;
                return this.ProceedingRoleService.isEditor(this.proceeding);
            }

            inflateDates() {
                if (this.proceeding && this.proceeding.dates) {
                    let { start, end, delivery } = this.datesModel;
                    const {
                        start: pureStart,
                        end: pureEnd,
                        delivery: pureDelivery
                    } = this.proceeding.dates;

                    this.datesModel.start =
                        !start && pureStart
                            ? this.PureDateService.inflate(pureStart)
                            : start;
                    this.datesModel.end =
                        !end && pureEnd
                            ? this.PureDateService.inflate(pureEnd)
                            : end;
                    this.datesModel.delivery =
                        !delivery && pureDelivery
                            ? this.PureDateService.inflate(pureDelivery)
                            : delivery;
                }
            }

            updateDates() {
                this.inflateDates();
                const { start, end, delivery } = this.datesModel;
                this.proceeding.dates = {
                    start:
                        (start && this.PureDateService.purify(start)) ||
                        undefined,
                    end: (end && this.PureDateService.purify(end)) || undefined,
                    delivery:
                        (delivery && this.PureDateService.purify(delivery)) ||
                        undefined
                };
            }

            proceedingChanged() {
                if (this.proceeding) {
                    this.updateDates();
                    this.onUpdate({
                        proceeding: this.proceeding,
                        isValid: this.isProceedingValid()
                    });
                }
            }

            isProceedingValid() {
                if (this.proceedingForm) {
                    return this.proceedingForm.$valid;
                } else {
                    return false;
                }
            }

            startDateChanged() {
                this.proceedingChanged();
                this.proceeding.year = this.PureDateService.getYear(
                    this.proceeding.dates.start
                );
            }

            updateProvince(province) {
                this.proceeding.location.province = province;
                this.proceedingChanged();
            }

            isSponsoredChanged() {
                if (this.proceeding.sponsoringAgency) {
                    delete this.proceeding.sponsoringAgency;
                }
                this.proceedingChanged();
            }

            isSponsoringAgencyRequired() {
                return this.proceeding.isSponsored === true;
            }

            saveProceeding() {
                this.onSave({ proceeding: this.proceeding });
            }

        }
    });
})();
