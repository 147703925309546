(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("suffixvalidation", function($q, $timeout) {
            return {
                require: "ngModel",

                link: function(scope, element, attrs, ngModel) {
                    ngModel.$parsers.unshift(function(value) {
                        const field = ngModel.$name;

                        let arr = [
                            "JR",
                            "SR",
                            "II",
                            "III",
                            "IV",
                            "V",
                            "VI",
                            "VII",
                            "VIII",
                            "IX",
                            "X",
                            "JUNIOR",
                            "SENIOR",
                            "JÚNIOR",
                            "SEÑOR"
                        ];

                        if (value !== "") {
                            if (field === "surname") {
                                const valueUpper = value.toUpperCase().trim();
                                /**
                                 * Check field for invalid suffix
                                 */
                                let hasInvalidSuffix = false;

                                for (let i = 0; i < arr.length; i++) {
                                    if (
                                        valueUpper.endsWith(" " + arr[i]) ||
                                        valueUpper.endsWith(
                                            " " + arr[i] + "."
                                        ) ||
                                        valueUpper.endsWith("-" + arr[i]) ||
                                        valueUpper.endsWith(
                                            "-" + arr[i] + "."
                                        ) ||
                                        valueUpper === arr[i] + "."
                                    ) {
                                        hasInvalidSuffix = true;
                                    }
                                }
                                if (hasInvalidSuffix) {
                                    ngModel.$setValidity("suffixcheck", false);
                                    return value;
                                } else {
                                    ngModel.$setValidity("suffixcheck", true);
                                    return value;
                                }
                            }

                            // });
                        }

                        // });
                    });
                }
            };
        });
})();
