(function() {
    "use strict";

    angular.module("cpir").provider(
        "AKConfigService",
        class {
            constructor() {}
            $get(apiService) {
                let firstItem = apiService.firstItem;
                return {
                    getConfig: () =>
                        apiService
                            .get("author-kit")
                            .then(result => firstItem(result))
                };
            }
        }
    );
})();
