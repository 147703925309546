(function() {
    "use strict";

    angular.module("cpir").filter("proceedingSubmissionDate", [
        "PureDateService",
        PureDateService => {
            return function(proceeding) {
                if (!proceeding) return null;
                if (!proceeding.productionSchedule) return null;

                let submissionDueDate = null;

                proceeding.productionSchedule.forEach(schedule => {
                    if (schedule.id === "submissionsDue") {
                        submissionDueDate = PureDateService.inflate(
                            schedule.date
                        ).toISOString();
                    }
                });

                return submissionDueDate;
            };
        }
    ]);
})();
