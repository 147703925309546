(function() {
    "use strict";

    angular.module("cpir").component("cpirBatchSetPromoDatesModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/batch-set-promo-dates-modal/batch-set-promo-dates-modal.component.html",
        controller: class {
            constructor(NotificationService, PureDateService) {
                this.NotificationService = NotificationService;
                this.PureDateService = PureDateService;
            }

            $onInit() {
                this.entries = this.resolve.entries;
            }

            setDates() {
                this.entries.forEach(entry => {
                    entry.enablePromoDates = true;
                    entry.promoDates = entry.promoDates || {};
                    if (this.startDate)
                        entry.promoDates.start = this.PureDateService.purify(
                            this.startDate
                        );
                    if (this.endDate)
                        entry.promoDates.end = this.PureDateService.purify(
                            this.endDate
                        );
                });
            }

            clearDates() {
                this.entries.forEach(entry => {
                    entry.enablePromoDates = false;
                    entry.promoDates = null;
                });
            }

            saveButtonClicked() {
                if (!this.startDate && !this.endDate) {
                    this.NotificationService.send(
                        "danger",
                        "At least one date is required"
                    );
                    return;
                }
                this.setDates();
                this.close({ $value: "set" });
            }

            clearButtonClicked() {
                this.clearDates();
                this.close({ $value: "clear" });
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
