(function() {
    angular.module("cpir").component("cpirDashboardOrganizerConfirmQuote", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/organizer/quote/components/confirm-quote/confirm-quote.component.html",

        controller: [
            "$scope",
            "$filter",
            function($scope, $filter) {
                let $ctrl = this;

                $ctrl.$onInit = () => {
                    $ctrl.quote = $ctrl.resolve.quote;
                    $ctrl.state = "pending";
                    $ctrl.action = $ctrl.resolve.action;
                    $ctrl.product = $ctrl.resolve.productObj.product;
                    $ctrl.product.acceptedPrice = $filter("currency")(
                        $ctrl.quote.computedPrices.totalPrice.toFixed(2)
                    );
                    $ctrl.isBook = $ctrl.resolve.productObj.isBook;
                    $ctrl.getBookDescription =
                        $ctrl.resolve.productObj.getBookDescription;
                    $ctrl.isDigital = $ctrl.resolve.productObj.isDigital;
                    $ctrl.getDigitalDescription =
                        $ctrl.resolve.productObj.getDigitalDescription;
                    $ctrl.isPapers = $ctrl.resolve.productObj.isPapers;
                    $ctrl.getPaperDescription =
                        $ctrl.resolve.productObj.getPaperDescription;
                    $ctrl.index = $ctrl.resolve.index;
                };

                $ctrl.confirmStyle = function() {
                    return {
                        opacity: $ctrl.confirmDisabled() ? 0.65 : 1.0,
                        cursor: !$ctrl.confirmDisabled()
                            ? "default"
                            : "pointer",
                        "box-shadow": !$ctrl.confirmDisabled()
                            ? "none"
                            : "inherit"
                    };
                };

                $ctrl.confirmDisabled = function() {
                    return (
                        ($ctrl.action.name === "revise" ||
                            $ctrl.action.name === "reject") &&
                        !$ctrl.notes
                    );
                };

                $ctrl.confirm = function() {
                    if ($ctrl.action.name === "accept") {
                        $ctrl.state = "submitted";
                        $ctrl.action.action({
                            selectedQuote: $ctrl.quote,
                            notes: $ctrl.notes,
                            product: $ctrl.product,
                            index: $ctrl.index
                        });
                    } else if ($ctrl.action.name === "revise") {
                        if (!$ctrl.notes) {
                            return;
                        }
                        $ctrl.action.action({
                            selectedQuote: $ctrl.quote,
                            notes: $ctrl.notes,
                            index: $ctrl.index
                        });
                        $ctrl.close({ $value: $ctrl.action.name });
                    } else {
                        //reject
                        if (!$ctrl.notes) {
                            return;
                        }
                        $ctrl.action.action({
                            notes: $ctrl.notes
                        });
                        $ctrl.close({ $value: $ctrl.action.name });
                    }
                };

                $ctrl.cancel = function() {
                    $ctrl.dismiss({ $value: "Cancel" });
                };

                $scope.$watch(
                    () => $ctrl.resolve.redirectError.err,
                    err => {
                        if (err) {
                            console.log("closing modal due to error");
                            $ctrl.state = "pending";
                            $ctrl.dismiss({ $value: "redirect error" });
                        }
                    }
                );

                $scope.$on("modal.closing", ev => {
                    if ($ctrl.state === "submitted") {
                        console.log("blocked modal close");
                        ev.preventDefault();
                    }
                });
            }
        ]
    });
})();
