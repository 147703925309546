(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkAuthorsController",

        class AkAuthorsController {
            constructor(
                pid,
                entryId,
                $scope,
                $state,
                ProceedingService,
                $stateParams,
                NotificationService,
                TocEntriesApiService,
                AKService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$scope = $scope;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.ProceedingService = ProceedingService;
                this.AKService = AKService;
            }

            $onInit() {
                this.AKService.setActive(this.pid, this.entryId);

                this.proceeding = this.AKService.getProceeding();
                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                    }
                );

                this.isActiveAuthorValid = false;

                this.TocEntriesApiService.getEntry(this.entryId)
                    .then(entry => {
                        this.entry = entry;
                        if (
                            this.entry.authors &&
                            this.entry.authors.length >= 1
                        ) {
                            this.authors = this.entry.authors;
                        } else {
                            let initialAuthor = {
                                givenName: "",
                                surName: ""
                            };
                            this.authors = [initialAuthor];
                        }
                        this.activeAuthorIndex = 0;
                        this.activeAuthor = this.authors[0];
                    })
                    .catch(e => console.error(e));
            }

            addAuthor() {
                this.authors.push({
                    firstName: "",
                    lastName: ""
                });

                this.activateAuthor(this.authors.length - 1);
                this.$scope.$emit("AUTHOR_ADDED");
            }

            removeActiveAuthor() {
                this.authors.splice(this.activeAuthorIndex, 1);

                if (this.activeAuthorIndex === 0) this.activateAuthor(0);
                else this.activateAuthor(this.activeAuthorIndex - 1);
            }

            activateAuthor(authorIndex) {
                this.activeAuthorIndex = authorIndex;
                this.activeAuthor = this.authors[authorIndex];
            }

            updateActiveAuthor(updatedAuthor, isValid) {
                this.isActiveAuthorValid = isValid;
                this.activeAuthor = updatedAuthor;
            }

            saveAuthors() {
                this.entry.authors = this.authors;
                this.TocEntriesApiService.updateEntries(this.entry).then(() => {
                    this.TocEntriesApiService.broadcastEntries(this.pid);
                    this.NotificationService.send(
                        "success",
                        "The authors were sucessfully saved."
                    );
                    this.AKService.goToNextStep();
                });
            }

            isStateValid() {
                return this.isActiveAuthorValid;
            }

            goToPreviousStep() {
                this.AKService.goToPreviousStep();
            }

            goToNextStep() {
                this.AKService.goToNextStep();
            }

            canGoToNextStep() {
                return this.AKService.isApprovalMode() || this.isStateValid();
            }
        }
    );
})();
