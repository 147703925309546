(function () {
    "use strict";

    angular.module("cpir").component("cpirTocEntryPanel", {
        bindings: {
            entry: "<",
            pdfUrl: "<",
            onEditFiles: "&",
            onUpdate: "&",
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/toc-entry-panel/toc-entry-panel.component.html",
        controller: class {
            constructor(
                $state,
                $stateParams,
                $scope,
                TOCValuesService,
                PureDateService,
                IThenticateService,
                WebSocketService,
            ) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$scope = $scope;
                this.TOCValuesService = TOCValuesService;
                this.PureDateService = PureDateService;
                this.promoDatesModel = {};
                this.IThenticateService = IThenticateService;
                this.safeApply = WebSocketService.safeApply;
            }

            $onInit() {
                this.minlength = "2";
                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false },
                ];
                this.pid = this.$stateParams.pid;
                this.entryId = this.$stateParams.entryId;
                this.changeRequested = this.$stateParams.changeRequested;

                this.productionStatusOptions =
                    this.TOCValuesService.productionStatusOptions.filter(
                        (option) => option.value !== "Undefined",
                    );

                this.copyrightTypes = this.TOCValuesService.copyrightTypes;

                this.pdfExpressOptions =
                    this.TOCValuesService.pdfExpressOptions;

                this.classOptions = this.TOCValuesService.classOptions;

                this.typeOptions = this.TOCValuesService.typeOptions;
            }

            $onChanges({ entry }) {
                if (entry && entry.currentValue) {
                    this.updatePromoDates();

                    // Initialize authors array if not present
                    if (!this.entry.authors || !this.entry.authors.length) {
                        this.entry.authors = [
                            {
                                givenName: "",
                                surname: "",
                                affiliation: "",
                                email: "",
                                orcid: "",
                            },
                        ];
                    }

                    // Ensure all authors have consistent field names
                    this.entry.authors = this.entry.authors.map((author) => ({
                        givenName: author.givenName || "",
                        surname: author.surname || "",
                        affiliation: author.affiliation || "",
                        email: author.email || "",
                        orcid: author.orcid || "",
                    }));

                    // Initialize activeAuthorIndex
                    this.activeAuthorIndex = 0;

                    // load the similarity score
                    const {
                        currentValue: { iThenticateId },
                    } = entry;
                    if (iThenticateId) {
                        this.similarity = "loading...";
                        this.IThenticateService.getDocumentStatus(
                            this.pid,
                            iThenticateId,
                        ).then(
                            ({
                                documents: [{ is_pending, percent_match }],
                            }) => {
                                if (is_pending === "0") {
                                    this.showSimilarityLink = true;
                                    this.similarity = `${percent_match}%`;
                                } else {
                                    this.similarity = "processing";
                                }
                            },
                        );
                    }
                }
            }

            /**
             * Get array of author indices for ng-options
             * @returns {number[]} Array of indices
             */
            getAuthorIndices() {
                return (
                    (this.entry &&
                        this.entry.authors.map((_, index) => index)) ||
                    []
                );
            }

            /**
             * Get display name for author in dropdown
             * @param {Object} author The author object
             * @returns {string} The display name
             */
            getAuthorDisplayName(author) {
                if (!author.givenName && !author.surname) {
                    return "Enter Author Details";
                }
                return `${author.givenName} ${author.surname}`.trim();
            }

            /**
             * Condition for change request styling
             * @return {boolean|boolean}
             */
            shouldEmphasizeChangeRequest() {
                // always emphasize if using the deep link
                if (this.changeRequested) {
                    return true;
                }
                // emphasize if the status is set to "change-request"
                return (
                    !_.isNil(this.entry) &&
                    this.entry.productionStatus === "change-request"
                );
            }

            editFiles() {
                this.onEditFiles();
            }

            classChanged() {
                switch (this.entry.class) {
                    case "SB":
                        this.entry.type = "AP";
                        break;
                    case "SD":
                        this.entry.type = "SD";
                        break;
                    case "VD":
                        this.entry.type = "VD";
                        break;
                    case "FM":
                        this.entry.type = "FM_COPYRIGHT_PAGE";
                        break;
                    case "BM":
                        this.entry.type = "BM_AUTHOR_INDEX";
                        break;
                }
                this.modelChanged();
            }

            modelChanged() {

                // Don't filter out empty authors during form editing to avoid UI disruption
                console.log(
                    "entry.authors",
                    JSON.stringify(this.entry.authors),
                );

                if(this.entry.authors.length > 0) {
                    if (this.entryForm.givenName.$viewValue === "" || this.entryForm.givenName.$error.required)
                        this.givenNameError = "Please enter a given name.";
                    else if (this.entryForm.givenName.$error.authorname)
                        this.givenNameError = "Given Name: Only the following character types may be used:  English, Latin letters, apostrophe ('), periods (.), hyphen (-), spaces<";
                    else if (this.entryForm.givenName.$error.prefixcheck)
                        this.givenNameError = "Given Name: Please exclude prefixes";
                    else
                        this.givenNameError = "";

                    if (this.entryForm.surname.$viewValue === "" || this.entryForm.surname.$error.required)
                        this.surnameError = "Please enter a surname.";
                    else if (this.entryForm.surname.$error.authorname)
                        this.surnameError = "Surname: Only the following character types may be used:  English, Latin letters, apostrophe ('), periods (.), hyphen (-), spaces<";
                    else if (this.entryForm.surname.$error.suffixcheck)
                        this.surnameError = "Surname: Please exclude prefixes";
                    else
                        this.surnameError = "";

                    if (this.entryForm.affiliation.$viewValue === "" ) {
                        this.affiliationError = "Please enter an affiliation.";
                    } else {
                       if(this.entryForm.affiliation.$viewValue.length === 1) {
                            this.affiliationError = "Affiliation needs to be more than one letter";
                        }
                        else if(this.entryForm.affiliation.$viewValue.length > 511) {
                            this.affiliationError = "Affiliation length exceeds limit 511 characters";
                        } else
                            this.affiliationError = "";
                    }


                    if (this.entryForm.orcid.$error.minlength)
                        this.orcidError = "The ORCID consists of 16 digits in four groups (each group has 4 digits) with a hyphen between the groups. ";
                    else if (this.entryForm.orcid.$error.maxlength)
                        this.orcidError = "The ORCID consists of 16 digits in four groups (each group has 4 digits) with a hyphen between the groups. ";
                    else if (this.entryForm.orcid.$error.authorOrcId)
                        this.orcidError = "The ORCID consists of 16 digits in four groups (each group has 4 digits) with a hyphen between the groups. The last digit of the fourth group may be an X";
                    else
                        this.orcidError = "";

                    if (this.entryForm.email.$invalid)
                        this.emailError = "Please enter a valid email.";
                    else
                        this.emailError = "";

                    this.safeApply(this.$scope);
                }




                this.onUpdate({ entry: this.entry });
            }

            inflatePromoDates() {
                if (this.entry && this.entry.promoDates) {
                    const { start, end } = this.promoDatesModel;
                    const { start: pureStart, end: pureEnd } =
                        this.entry.promoDates;
                    this.promoDatesModel = {
                        start:
                            !start && pureStart
                                ? this.PureDateService.inflate(pureStart)
                                : start,
                        end:
                            !end && pureEnd
                                ? this.PureDateService.inflate(pureEnd)
                                : end,
                    };
                }
            }

            updatePromoDates() {
                if (this.entry) {
                    this.inflatePromoDates();
                    const { start, end } = this.promoDatesModel;
                    this.entry.promoDates = {
                        start:
                            (start && this.PureDateService.purify(start)) ||
                            undefined,
                        end:
                            (end && this.PureDateService.purify(end)) ||
                            undefined,
                    };
                }
            }

            promoDateChanged() {
                this.updatePromoDates();
                this.modelChanged();
            }

            async openSimilarityReport() {
                const { iThenticateId } = this.entry;
                const url =
                    await this.IThenticateService.getSimilarityReportLink(
                        this.pid,
                        iThenticateId,
                    );
                if (url) {
                    window.open(url, "_blank");
                }
            }

            // Author management methods
            addAuthor() {
                this.entry.authors.push({
                    givenName: "",
                    surname: "",
                    affiliation: "",
                    email: "",
                    orcid: "",
                });

                this.givenNameError = "";
                this.surnameError = "";
                this.affiliationError = "";
                this.orcidError = "";
                this.emailError = "";
                if(this.entry.authors.length > 1) {
                    this.entryForm.givenName.$setPristine();
                    this.entryForm.surname.$setPristine();
                    this.entryForm.affiliation.$setPristine();
                    this.entryForm.orcid.$setPristine();
                    this.entryForm.$setPristine();
                    this.entryForm.$setValidity();
                    this.entryForm.$setUntouched();
                }
                this.activeAuthorIndex = this.entry.authors.length - 1;
                this.modelChanged();
            }

            removeAuthor() {
                if (this.entry.authors.length > 1) {
                    this.entry.authors.splice(this.activeAuthorIndex, 1);
                    if (this.activeAuthorIndex >= this.entry.authors.length) {
                        this.activeAuthorIndex = this.entry.authors.length - 1;
                    }
                    this.modelChanged();
                }
            }

            authorSelected() {
                this.givenNameError = null;
                this.surnameError = null;
                this.affiliationError = null;
                this.orcidError = null;
                this.emailError = null;
                this.entryForm.givenName.$setPristine();
                this.entryForm.surname.$setPristine();
                this.entryForm.affiliation.$setPristine();
                this.entryForm.orcid.$setPristine();
                this.entryForm.$setPristine();
                this.entryForm.$setValidity();
                this.entryForm.$setUntouched();


                this.modelChanged();
            }

            moveAuthorUp() {
                if (this.activeAuthorIndex > 0) {
                    // Swap current author with the one above
                    const temp = this.entry.authors[this.activeAuthorIndex - 1];
                    this.entry.authors[this.activeAuthorIndex - 1] =
                        this.entry.authors[this.activeAuthorIndex];
                    this.entry.authors[this.activeAuthorIndex] = temp;

                    // Update active index to follow the moved author
                    this.activeAuthorIndex--;

                    this.modelChanged();
                }
            }

            moveAuthorDown() {
                if (this.activeAuthorIndex < this.entry.authors.length - 1) {
                    // Swap current author with the one below
                    const temp = this.entry.authors[this.activeAuthorIndex + 1];
                    this.entry.authors[this.activeAuthorIndex + 1] =
                        this.entry.authors[this.activeAuthorIndex];
                    this.entry.authors[this.activeAuthorIndex] = temp;

                    // Update active index to follow the moved author
                    this.activeAuthorIndex++;

                    this.modelChanged();
                }
            }
        },
    });
})();
