// TODO Deprecated (230906): Webpub downloads are now handled in the webpub modal
// remove the controller, template, and route if no longer needthis.pid = pid;ed
(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCDownloadsController",

        class {
            constructor(
                pid,
                TocEntriesApiService,
                ProceedingService,
                WebpubService,
                $rootScope,
                $scope,
                $state,
                $timeout,
                $filter,
                WebSocketService,
                FileService,
                EmailService,
                NotificationService,
                UuidService,
                TOCViewModeService,
            ) {
                this.pid = pid;
                this.TocEntriesApiService = TocEntriesApiService;
                this.ProceedingService = ProceedingService;
                this.WebpubService = WebpubService;
                this.$scope = $scope;
                this.$rootScope = $rootScope;
                this.$state = $state;
                this.$timeout = $timeout;
                this.$filter = $filter;
                this.WebsocketService = WebSocketService;
                this.FileService = FileService;
                this.EmailService = EmailService;
                this._ = _;
                this.NotificationService = NotificationService;
                this.UuidService = UuidService;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                this.missingPageNum = false;
                this.paperIdMissing = false;
                let entriesResolve;
                this.entriesAsync = new Promise((resolve) => {
                    entriesResolve = resolve;
                });
                let entriesCacheResolve;
                let entriesFromCache;
                const entriesFromCacheAsync = new Promise((resolve) => {
                    entriesCacheResolve = resolve;
                });

                // get entry data
                this.TocEntriesApiService.getEntriesWithCache$(
                    this.pid,
                ).subscribe({
                    next: (entries) => {
                        this.$timeout(() => {
                            entriesFromCache = entries;
                            entriesCacheResolve(entries);
                            this.entries = entries;
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log("Entries fetching completed");
                            entriesResolve(entriesFromCache);
                        });
                    },
                });

                this.exportInfo = {};
                this.exportCompleted = true;
                this.keepRawFileName = true;
                this.includeExtras = false;
                this.manifestColumns = {
                    class: "Class",
                    type: "Type",
                    sequence: "Sequence",
                    productionStatus: "Status",
                    copyrightType: "Copyright Type",
                    copyrightLine: "Copyright Line",
                    articleId: "Article Id (ecopyright id)",
                    pdfExpress: "Pdf Express",
                    //paperId: 'Paper Id',
                    title: "Title",
                    submitterName: "Submitter Name",
                    submitterEmail: "Submitter Email",
                    abstract: "Abstract",
                    authors: "Authors",
                    keywords: "Keywords",
                    doi: "DOI",
                    chair: "Chair",
                    pageNumber: "Page Number",
                    pagePadding: "Page Padding",
                    noShow: "Is No-Show",
                    submissionChangeRequest:
                        "Author Change Request (Read Only)",
                    articlePdfFileName: "Article Pdf File Name",
                    articlePdfExportFileName: "Article Pdf Export File Name",
                    articlePdfFileSize: "Article Pdf File Size",
                    articlePdfFileType: "Article Pdf File Type",
                    eid: "CPS Id",
                    givenName: "Author Given Name",
                    surName: "Author Surname",
                    authorAff: "Author Affiliation",
                    authorEmail: "Author Email",
                    orcid: "ORCID",
                };

                this.ProceedingService.get(this.pid).then((proceeding) => {
                    this.proceeding = proceeding;
                    this.toc = this.proceeding.toc;

                    this.isbn = null;
                    if (this.proceeding.applyIcx)
                        this.isbn = this.proceeding.icxData.isbn13;
                    else
                        this.isbn =
                            this.proceeding.toc.isbn13 ||
                            this.proceeding.toc.isbn;

                    if (!this.toc) this.toc = {};
                    if (!this.toc.exportKeys) this.toc.exportKeys = [];

                    this.exportKeyListMaxAge = this.$filter(
                        "tocDownloadsMaxAgeFilter",
                    )(this.toc.exportKeys);

                    this.exportKeyList = this.$filter(
                        "exportDownloadOrderByCreateDateFilter",
                    )(this.exportKeyListMaxAge, true);
                });
                //start websocket
                this.TocEntriesApiService.startSocket(this.pid, false).then(
                    (client) => {
                        this.client = client;
                        // clean up websocket connection when we navigate off the TOC
                        const stateChangeDeregister = this.$rootScope.$on(
                            "$stateChangeStart",
                            (
                                event,
                                toState,
                                toParams,
                                fromState,
                                fromParams,
                            ) => {
                                if (
                                    fromState.name === this.$state.$current.name
                                ) {
                                    // check if the state names match
                                    client.disconnect();
                                    console.log(
                                        "disconnecting from downloads websocket",
                                    );
                                }
                                stateChangeDeregister(); // stop this event from firing again
                            },
                        );

                        client.on("proceeding.updated", (proceeding) => {
                            this.toc = proceeding.toc;
                            this.WebsocketService.safeApply(this.$scope);
                        });
                    },
                );
            }

            downloadButtonClicked(exports) {
                return this.FileService.downloadTocExport(
                    exports.key,
                    this.proceeding.year,
                    this.proceeding.acronym,
                    exports.type,
                    this.proceeding.pid,
                );
            }

            onCategorySelect(type) {
                this.exportType = type;
            }

            exportStartedButtonClicked() {
                let paperIdMissing = false;
                console.log("export clicked " + this.articleFileNaming);

                this.entries.map((entry) => {
                    if (
                        entry.class !== "SD" &&
                        entry.class !== "VD" &&
                        !entry.title
                    ) {
                        this.paperTitleMissing = true;
                    }
                });

                if (!this.isbn)
                    this.NotificationService.send(
                        "warning",
                        "Cannot create FNO # for export: Invalid ISBN or ISBN13",
                    );
                else if (this.missingPageNum.length > 0) {
                    this.NotificationService.send(
                        "warning",
                        "Conference articles are missing page numbers, Please generate page numbers before exporting.",
                    );
                } else if (
                    ((this.includeStampFiles || this.includeOriginalFiles) &&
                        this.articleFileNaming === null) ||
                    (!this.includeStampFiles &&
                        !this.includeOriginalFiles &&
                        this.articleFileNaming !== null)
                ) {
                    this.NotificationService.send(
                        "warning",
                        "Choose Article File choice and its Naming",
                    );
                } else if (
                    (this.supplementFolderStructure === null &&
                        this.supplementNaming !== null) ||
                    (this.supplementFolderStructure !== null &&
                        this.supplementNaming === null)
                ) {
                    this.NotificationService.send(
                        "warning",
                        "Choose Supplement Folder structure and its Naming",
                    );
                } else if (
                    (this.supplementNaming === "paperId" ||
                        this.articleFileNaming === "paperId") &&
                    this.paperIdMissing.length > 0
                ) {
                    this.NotificationService.send(
                        "warning",
                        "Missing paperIds for entries in table of contents. Please enter paperIds before continuing to export",
                    );
                } else if (
                    (this.supplementNaming === "paperTitle" ||
                        this.articleFileNaming === "paperTitle") &&
                    this.paperTitleMissing
                ) {
                    this.NotificationService.send(
                        "warning",
                        "Missing paper titles for entries in table of contents. Please enter paper titles before continuing to export",
                    );
                } else {
                    console.log(this.manifestColumns);
                    if (
                        this.manifestColumns &&
                        Object.keys(this.manifestColumns).length > 1
                    ) {
                        this.toc = {};
                        this.toc.exportKeys = [];
                        let property = this.toc.exportKeys[this.exportType];

                        let exportType = this.exportType;
                        exportType = "manifestOnly";
                        this.manifestColumns = _.omit(
                            this.manifestColumns,
                            "authors",
                        );

                        if (this.manifestColumns["givenName"])
                            this.manifestColumns["givenName"] =
                                "Author Given Name";
                        if (this.manifestColumns["surName"])
                            this.manifestColumns["surName"] = "Author SurName";
                        if (this.manifestColumns["authorAff"])
                            this.manifestColumns["authorAff"] =
                                "Author affiliation";
                        if (this.manifestColumns["authorEmail"])
                            this.manifestColumns["authorEmail"] =
                                "Author Email";
                        if (this.manifestColumns["orcid"])
                            this.manifestColumns["orcid"] = "ORCID";

                        if (property) {
                            property.exportStartedForType = "started";
                            property.exportCompleted = false;
                        } else {
                            let newProperty = {};
                            newProperty.exportStartedForType = "started";
                            newProperty.exportCompleted = false;
                            this.toc.exportKeys.push(newProperty);
                        }
                        this.exportCompleted = false;
                        this.exportStarted = true;
                        exportType = "";
                        console.log(this.supplementFolderStructure);
                        console.log(this.supplementNaming);
                        console.log(this.includeStampFiles);
                        console.log(this.includeOriginalFiles);
                        console.log(this.articleFileNaming);
                        this.FileService.exportToc(
                            this.proceeding.pid,
                            exportType,
                            this.includeExtras,
                            this.keepRawFileName,
                            this.manifestColumns,
                            this.supplementFolderStructure,
                            this.supplementNaming,
                            this.includeStampFiles,
                            this.includeOriginalFiles,
                            this.articleFileNaming,
                            this.generateManifest,
                        ).then((socket) => {
                            this.socket = socket;
                            socket.on("export-ready", async (key) => {
                                this.exportKey = key;
                                await this.ProceedingService.get(this.pid)
                                    .then((proceeding) => {
                                        this.proceeding = proceeding;
                                        this.toc = this.proceeding.toc;
                                    })
                                    .then(() => {
                                        this.exportKeyListMaxAge = this.$filter(
                                            "tocDownloadsMaxAgeFilter",
                                        )(this.toc.exportKeys);

                                        let isNotCompleted =
                                            this.exportKeyListMaxAge.filter(
                                                (item) => {
                                                    if (!item.exportEndTime) {
                                                        return true;
                                                    }
                                                },
                                            );

                                        this.exportKeyList = this.$filter(
                                            "exportDownloadOrderByCreateDateFilter",
                                        )(this.exportKeyListMaxAge, true);

                                        if (isNotCompleted.length > 0) {
                                            this.exportCompleted = false;
                                            this.exportStarted = true;
                                        } else {
                                            this.exportCompleted = true;
                                            this.exportStarted = false;
                                        }
                                    });
                                this.WebsocketService.safeApply(this.$scope);
                            });
                            socket.on("export-failed", (err) => {
                                this.ProceedingService.get(this.pid)
                                    .then((proceeding) => {
                                        this.proceeding = proceeding;
                                        this.toc = this.proceeding.toc;
                                    })
                                    .then(() => {
                                        this.exportKeyListMaxAge = this.$filter(
                                            "tocDownloadsMaxAgeFilter",
                                        )(this.toc.exportKeys);

                                        let isNotCompleted =
                                            this.exportKeyListMaxAge.filter(
                                                (item) => {
                                                    if (!item.exportEndTime) {
                                                        return true;
                                                    }
                                                },
                                            );
                                        this.exportKeyList = this.$filter(
                                            "exportDownloadOrderByCreateDateFilter",
                                        )(this.exportKeyListMaxAge, true);

                                        if (isNotCompleted.length > 0) {
                                            this.exportCompleted = false;
                                            this.exportStarted = true;
                                        } else {
                                            this.exportCompleted = true;
                                            this.exportStarted = false;
                                        }

                                        this.WebsocketService.safeApply(
                                            this.$scope,
                                        );
                                        this.NotificationService.send(
                                            "danger",
                                            err,
                                        );
                                    });

                                //this.dismiss();
                            });
                        });
                    } else {
                        this.NotificationService.send(
                            "warning",
                            "Select fields to export",
                        );
                    }
                }
            }

            updateProperties(
                includeExtras,
                keepRawFileName,
                manifestColumns,
                supplementFolderStructure,
                supplementNaming,
                includeStampFiles,
                includeOriginalFiles,
                articleFileNaming,
                generateManifest,
            ) {
                //this.includeExtras = includeExtras;
                //this.keepRawFileName = keepRawFileName;
                this.manifestColumns = manifestColumns;
                this.isAllDeselected = false; //isAllDeselected; // check this
                this.supplementFolderStructure = supplementFolderStructure;
                this.supplementNaming = supplementNaming;
                this.includeStampFiles = includeStampFiles;
                this.includeOriginalFiles = includeOriginalFiles;
                this.articleFileNaming = articleFileNaming;
                this.generateManifest = generateManifest;
            }

            // Add a method to return to TOC with proper view mode
            returnToTOC() {
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }
        },
    );
})();
