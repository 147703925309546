(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardOrganizerInfoPaperTypePanel", {
            bindings: {
                onUpdate: "&",
                paperTypes: "<",
                trim: "<"
            },
            templateUrl:
                "application/cpir/dashboard/_shared/components/additional-information-modal/components/additional-information/components/paper-type-panel/paper-type-panel.component.html",
            controller: class {
                constructor() {}

                $onInit() {
                    this.paperTypeForm = {};

                    this.bookTrims = [
                        { name: "8.5 x 11 1-column", value: "8.5x11-1" },
                        { name: "8.5 x 11 2-column", value: "8.5x11-2" },
                        { name: "A4 1-column", value: "A4-1" },
                        { name: "A4 2-column", value: "A4-2" },
                        { name: "6 x 9", value: "6x9" }
                    ];
                    this.trim = this.bookTrims[1].value;

                    this.defaultPaper = {
                        name: "",
                        pagesAllowed: 0,
                        extraPagesAllowed: 0,
                        extraPagesCharge: "$0.00"
                    };
                    this.activePaper = angular.copy(this.defaultPaper);
                    this.paperTypes = [this.activePaper];
                    this.activePaperIndex = 0;
                }

                $onChanges(changes) {
                    if (changes.paperTypes && changes.paperTypes.currentValue) {
                        this.paperTypes = changes.paperTypes.currentValue;
                        this.activePaper = this.paperTypes[0];
                    }
                }

                activatePaperType($index) {
                    if (!this.paperTypeForm.$invalid) {
                        this.activePaper = this.paperTypes[$index];
                        this.activePaperIndex = $index;
                    }
                }

                buttonClicked($index) {
                    this.onSwitch({ paperTypeIndex: $index });
                }

                addPaperType() {
                    if (!this.paperTypeForm.$invalid) {
                        this.paperTypes.push(angular.copy(this.defaultPaper));
                        this.activatePaperType(this.paperTypes.length - 1);
                        this.modelChanged();
                    }
                }

                removePaperType($index) {
                    this.paperTypes.splice($index, 1);
                    this.activatePaperType($index - 1 > 0 ? $index - 1 : 0);
                    this.modelChanged();
                }

                modelChanged() {
                    this.onUpdate({
                        paperTypes: this.paperTypes,
                        trim: this.trim
                    });
                }
            }
        });
})();
