(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardComplianceSubmissionController",
        class {
            constructor(
                pid,
                $document,
                $state,
                $scope,
                $uibModal,
                $stateParams,
                authService,
                EmailService,
                ProceedingStatusService,
                NotificationService,
                ContactService,
                ProceedingService,
                apiQuotes,
                UploadService,
                WebSocketService
            ) {
                this.pid = pid;
                this.$document = $document;
                this.$scope = $scope;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$uibModal = $uibModal;
                this.authService = authService;
                this.EmailService = EmailService;
                this.apiQuotes = apiQuotes;
                this.ProceedingStatusService = ProceedingStatusService;
                this.NotificationService = NotificationService;
                this.ContactService = ContactService;
                this.ProceedingService = ProceedingService;
                this.UploadService = UploadService;
                this.WebSocketService = WebSocketService;
            }

            $onInit() {
                this.complianceForm = {};
                this.compliance = {};
                this.isErrorsVisible = false;
                this.userProfile = this.authService.getProfile();

                this.ProceedingService.get(this.pid)
                    .then(async proceeding => {
                        this.proceeding = proceeding;

                        // get the proceeding contacts
                        const contacts = await this.ContactService.listByProceeding(
                            proceeding
                        );

                        // guard the compliance review page such that only user's in the contact list can view or interact further with the page
                        const userEmail = this.userProfile.email;
                        if (!this.isUserInContacts(userEmail, contacts)) {
                            this.$state.go("dashboard-editor.home");
                        }

                        // check if there is existing saved compliance review data
                        if (
                            proceeding.compliance &&
                            proceeding.compliance.submission
                        ) {
                            // pre-file the compliance page with saved data
                            this.compliance = proceeding.compliance.submission;
                        }
                        // If the compliance form has not been started,
                        // pre-populate parts of the form from other sources.
                        else if (
                            this.ProceedingStatusService.isComplianceReview(
                                this.proceeding
                            )
                        ) {
                            this.compliance = {};

                            // Venue address comes from the proceeding address
                            this.compliance.venue = {
                                location: proceeding.location
                            };

                            // Estimated paper comes from the accepted quote
                            this.apiQuotes
                                .byPid(proceeding.pid)
                                .then(quote => {
                                    let acceptedQuote = quote.cart.find(
                                        c => c.accepted
                                    );
                                    if (acceptedQuote) {
                                        this.compliance.estSubmittedPapers =
                                            acceptedQuote.estSubmittedPapers;
                                        this.compliance.estAcceptedPapers =
                                            acceptedQuote.estAcceptedPapers;
                                    }
                                })
                                .catch(e => console.log(e));

                            // Contacts come from contacts
                            this.compliance.email = this.userProfile.email;
                            this.compliance.contacts = contacts;
                            // pre-fill the signature name
                            this.setSignatureName(
                                this.userProfile.email,
                                contacts
                            );
                        }
                    })
                    .catch(e => console.log(e));
            }

            getMatchingContactForUser(userEmail, contacts) {
                return contacts.find(
                    c => c.email.toLowerCase() === userEmail.toLowerCase()
                );
            }

            isUserInContacts(userEmail, contacts) {
                return contacts.some(
                    c => c.email.toLowerCase() === userEmail.toLowerCase()
                );
            }

            setSignatureName(userEmail, contacts) {
                const matchingContact = this.getMatchingContactForUser(
                    userEmail,
                    contacts
                );
                if (matchingContact) {
                    this.compliance.signatureName =
                        matchingContact.givenName +
                        " " +
                        matchingContact.surname;
                }
            }

            isComplianceReadonly() {
                if (!this.proceeding) return true;
                return !this.ProceedingStatusService.isComplianceReview(
                    this.proceeding
                );
            }

            updateCompliance(compliance, isValid, complianceFilesToUpload) {
                this.isComplianceValid = isValid;
                this.compliance = compliance;
                this.complianceFilesToUpload = complianceFilesToUpload;
            }

            uploadComplianceFiles() {
                console.log(
                    "complianceFilesToUpload: ",
                    this.complianceFilesToUpload
                );
                if (
                    this.complianceFilesToUpload &&
                    this.complianceFilesToUpload.length > 0
                ) {
                    this.isLoading = true;
                    return this.UploadService.uploadComplianceFiles(
                        this.pid,
                        this.complianceFilesToUpload
                    );
                } else {
                    return Promise.resolve(null);
                }
            }

            uploadAndSaveMetadata(setDate) {
                return Promise.all([
                    this.ProceedingService.get(this.pid),
                    this.uploadComplianceFiles()
                ]).then(([proceeding]) => {
                    // Update the compliance object in the proceeding
                    const compliance = proceeding.compliance || {};
                    compliance.submission = this.compliance;
                    if (setDate) {
                        compliance.submissionDate = new Date();
                    }

                    // reformat the files
                    this.compliance.files =
                        (this.compliance.files &&
                            this.compliance.files.map(
                                ({ name, size, type, mimeType }) => ({
                                    name,
                                    size,
                                    mimeType: type || mimeType
                                })
                            )) ||
                        undefined;

                    return this.ProceedingService.update(this.pid, {
                        compliance
                    });
                });
            }

            saveCompliance() {
                return this.uploadAndSaveMetadata(false)
                    .then(() => {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "success",
                            "The compliance submission was saved successfully."
                        );
                        return this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        console.error(err);
                        this.NotificationService.send(
                            "danger",
                            "There was an error saving the compliance submission: " +
                                err.name +
                                " " +
                                err.message
                        );
                        return this.WebSocketService.safeApply(this.$scope);
                    });
            }

            submitCompliance() {
                if (this.isComplianceValid) {
                    return this.$uibModal
                        .open({
                            component: "cpirConfirmationModal"
                        })
                        .result.then(() => this.uploadAndSaveMetadata(true))
                        .then(() =>
                            Promise.all([
                                this.ProceedingStatusService.setComplianceSubmitted(
                                    this.pid,
                                    true
                                ),
                                this.EmailService.sendImmediately(
                                    "compliance-submitted",
                                    {
                                        to: [
                                            this.proceeding.configuration
                                                .qaEditor
                                        ],
                                        data: {
                                            proceeding: this.proceeding
                                        }
                                    }
                                )
                            ])
                        )
                        .then(() => {
                            this.isLoading = false;
                            this.NotificationService.send(
                                "success",
                                "The compliance submission was submitted successfully."
                            );
                            return this.WebSocketService.safeApply(this.$scope);
                        })
                        .then(() =>
                            this.$state.go(
                                "dashboard-editor.compliance-acceptance",
                                { pid: this.pid }
                            )
                        )
                        .catch(err => {
                            console.error(err);
                            return this.ProceedingService.update(this.pid, {
                                "compliance.submissionDate": null
                            })
                                .then(() =>
                                    this.ProceedingStatusService.setComplianceReview(
                                        this.pid,
                                        true
                                    )
                                )
                                .then(() => {
                                    this.isLoading = false;
                                    this.NotificationService.send(
                                        "danger",
                                        "There was an error saving the compliance submission: " +
                                            err.name +
                                            " " +
                                            err.message
                                    );
                                    return this.WebSocketService.safeApply(
                                        this.$scope
                                    );
                                });
                        });
                } else {
                    this.NotificationService.send(
                        "warning",
                        "Please correct any missing or invalid fields above."
                    );
                    this.isErrorsVisible = true;

                    let error = $(".compliance-error");

                    if (Array.isArray(error)) {
                        error = error.first();
                    }

                    setTimeout(() => {
                        // $('html, body').animate({
                        //     scrollTop: (error.offset().top)
                        // }, 500);
                        this.$document.scrollToElement(error);
                    }, 500);
                }
            }
        }
    );
})();
