(function() {
    "use strict";

    angular.module("cpir").component("csdlExportPanelInformation", {
        bindings: {
            proceeding: "<",
            icxConferenceDetails: "<",
            originalConferenceDetails: "<",
            noShows: "<",
            missingSubmitterInfo: "<",
            includeExtras: "<",
            checkingIcx: "<",
            applyIcx: "&",
            highlightIcxTab: "<",
            onChangeConferenceDetailSource: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/csdl-export-panel/csdl-export-panel.component.html",
        controller: class {
            constructor($stateParams, $scope) {
                this.$stateParams = $stateParams;
                this.$scope = $scope;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
                this.activePanel = "ICX CORRECTIONS APPLIED";
            }

            conferenceDetailSourceChanged(applyIcx) {
                this.onChangeConferenceDetailSource({ "applyIcx": applyIcx });
            }

            $onChanges(changes) {
                if (
                    this.activePanel === "MISSING SUBMITTER INFO" &&
                    this.includeExtras === false
                ) {
                    this.activePanel = "ICX CORRECTIONS APPLIED";
                }
            }
        }
    });
})();
