(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorProceedingSummaryController",

        class {
            constructor(pid, authService, ProceedingService, ContactService) {
                this.pid = pid;
                this.authService = authService;
                this.ProceedingService = ProceedingService;
                this.ContactService = ContactService;
            }

            $onInit() {
                this.brand = this.authService.getBrand();

                this.ProceedingService.get(this.pid)
                    .then(proceeding => {
                        this.proceeding = proceeding;

                        if(this.proceeding.workshops)
                            this.filteredWorkshops = this.proceeding.workshops.filter(w => w.pid);


                        if (this.proceeding.contacts) {
                            let cids = [];

                            for (let contact of this.proceeding.contacts) {
                                cids.push(contact.cid);
                            }

                            this.ContactService.list(cids).then(contacts => {
                                this.contacts = contacts;
                            });
                        }
                    })
                    .catch(e => console.log(e));
            }

            activateWorkshop(workshopIndex) {
                this.activeWorkshopIndex = workshopIndex;
                this.activeWorkshop = this.workshops[workshopIndex];
                this.isActiveWorkshopValid = true;
            }

            isSponsoringAgencyRequired() {
                if (!this.proceeding) {
                    return false;
                } else {
                    return this.proceeding.isSponsored === true;
                }
            }
        }
    );
})();
