(function() {
    "use strict";
    angular.module("cpir").filter("webpubStatusFilter", () => {
        const statusMap = {
            "request-received": "Request Received",
            completed: "Completed",
            error: "Error",
            "building-sub-conferences": "Building Sub-Conferences",
            "building-main-webpub": "Building Main Webpub",
            zipping: "Zipping Webpub Content",
            "uploading-to-s3": "Uploading Webpub",
            "uploading-full-conference-zip-to-s3":
                "Uploading Full Conference Zip",
            "awaiting-zip-file-transfer": "Saving Zip File"
        };

        return webpub => {
            if (!webpub) return webpub;
            return statusMap[webpub.status] || webpub.status;
        };
    });
})();
