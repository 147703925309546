(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceVenuePanel", {
        bindings: {
            isErrorsVisible: "<",
            isDisabled: "<",
            onUpdate: "&",
            venue: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/venue-panel/venue-panel.component.html",
        controller: class {
            constructor($scope, InternationalDataService) {
                this.$scope = $scope;
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.venueForm = {};

                this.countryOptions = this.InternationalDataService.getCountries();

                this.$scope.$watch(
                    () => this.venueForm.$valid,
                    () => this.modelChanged()
                );
            }

            modelChanged() {
                this.onUpdate({ venue: this.venue });
            }

            removeButtonClicked() {
                this.onRemove();
            }

            updateProvince(province) {
                this.venue.location.province = province;
                this.modelChanged();
            }
        }
    });
})();
