(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifSubmissionController",
        class {
            constructor(
                pid,
                authService,
                CifService,
                ContactService,
                EmailService,
                ProceedingService,
                ProceedingStatusService
            ) {
                this.pid = pid;
                this.authService = authService;
                this.CifService = CifService;
                this.ContactService = ContactService;
                this.EmailService = EmailService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
            }

            $onInit() {
                this.configuration = this.CifService.getConfiguration();
                this.userProfile = this.authService.getProfile();

                this.ProceedingService.get(this.pid)
                    .then(proceeding => {
                        this.proceeding = proceeding;

                        if (
                            this.ProceedingStatusService.isInquiryStarted(
                                this.proceeding
                            )
                        ) {
                            // Set the status to submitted.
                            this.ProceedingStatusService.setInquirySubmitted(
                                this.pid
                            ).catch(e => console.log(e));

                            // Get the conference organizer.
                            this.ContactService.getConferenceOrganizer(
                                this.proceeding
                            )
                                .then(organizer => {
                                    if (organizer) {
                                        let organizerName = `${organizer.givenName} ${organizer.surname}`;

                                        let emailProps = {
                                            to: [organizer.email],
                                            data: {
                                                proceeding,
                                                organizerName
                                            }
                                        };

                                        this.EmailService.sendImmediately(
                                            "cif-submission",
                                            emailProps
                                        ).catch(e => console.log(e));
                                    }
                                })
                                .catch(e => console.log(e));
                        }
                    })
                    .catch(e => console.log(e));
            }
        }
    );
})();
