(function() {
    "use strict";

    angular.module("cpir").provider(
        "CloneProceedingService",
        class {
            constructor() {}
            $get(
                $uibModal,
                authService,
                ConfigurationService,
                ProceedingService,
                ProceedingStatusService,
                TocEntriesApiService
            ) {
                let cloneProceeding = proceeding => {
                    if (proceeding.isWorkshop) {
                        return $uibModal.open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                title: () => "Information",
                                message: () => "Workshops cannot be cloned.",
                                confirmButtonText: () => "Close",
                                cancelButtonDisabled: () => true
                            }
                        }).result;
                    } else if (
                        ProceedingStatusService.isInquiryStarted(proceeding)
                    ) {
                        return $uibModal.open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                title: () => "Information",
                                message: () =>
                                    "Inquiries must be submitted before they can be cloned.",
                                confirmButtonText: () => "Close",
                                cancelButtonDisabled: () => true
                            }
                        }).result;
                    } else {
                        return $uibModal
                            .open({
                                component: "cpirConfirmationModal",
                                resolve: {
                                    title: () => "Confirm",
                                    message: () =>
                                        "Please confirm that you would like to clone this inquiry."
                                }
                            })
                            .result.then(() => {
                                return ProceedingService.get(
                                    proceeding.pid
                                ).then(proceeding => {
                                    return ConfigurationService.get()
                                        .then(config => {
                                            let brand = authService.getBrand();
                                            let proceedingConfig;

                                            if (brand.name === "cps")
                                                proceedingConfig =
                                                    config.configuration.cps;
                                            else if (brand.name === "pubhub")
                                                proceedingConfig =
                                                    config.configuration.pubhub;
                                            else
                                                throw "Proceeding configuration could not be retrieved.";

                                            let newProceeding = buildProceeding(
                                                proceeding,
                                                proceedingConfig
                                            );
                                            return newProceeding;
                                        })
                                        .then(newProceeding => {
                                            return ProceedingService.create(
                                                newProceeding
                                            ).then(pid => {
                                                ProceedingStatusService.setInquiryStarted(
                                                    pid
                                                );
                                                return TocEntriesApiService.getOrCreateByPid(
                                                    pid,
                                                    newProceeding.acronym
                                                );
                                            });
                                        })
                                        .then(newProceeding => {
                                            return newProceeding.pid;
                                        });
                                });
                            })
                            .catch(e => console.log(e));
                    }
                };

                let buildProceeding = (oldProceeding, config) => {
                    let newProceeding = {};

                    newProceeding.acronym = oldProceeding.acronym;
                    newProceeding.brand = oldProceeding.brand;
                    newProceeding.configuration = config;
                    newProceeding.contacts = oldProceeding.contacts;
                    newProceeding.editor = config.defaultEditor;
                    newProceeding.hasSeparatePublicationsInXplore =
                        oldProceeding.hasSeparatePublicationsInXplore;
                    newProceeding.hasWorkshops = oldProceeding.hasWorkshops;
                    newProceeding.isIEEESponsored =
                        oldProceeding.isIEEESponsored;
                    newProceeding.isSponsored = oldProceeding.isSponsored;
                    newProceeding.location = oldProceeding.location;
                    newProceeding.postalCode = oldProceeding.postalCode;
                    newProceeding.sponsoringAgency =
                        oldProceeding.sponsoringAgency;
                    newProceeding.webURL = oldProceeding.webURL;
                    newProceeding.title = oldProceeding.title;
                    newProceeding.year = new Date().getFullYear();

                    if (oldProceeding.workshops) {
                        newProceeding.workshops = oldProceeding.workshops.map(
                            w => {
                                return {
                                    title: w.title,
                                    acronym: w.acronym
                                };
                            }
                        );
                    }

                    return newProceeding;
                };

                return { cloneProceeding };
            }
        }
    );
})();
