(function() {
    "use strict";

    angular.module("cpir").factory("dashPricingService", [
        "dashUnitPricesService",
        "authService",
        "$q",
        function(dashUnitPricesService, authService, $q) {
            let model = {};

            model.calculatePrices = quote => {
                // separate print and digital media
                let printMedia = [];
                let digitalMedia = [];
                let customMedia = [];
                quote.products.forEach(product => {
                    if (model.print.MIDs[product.mid]) {
                        printMedia = printMedia.concat(product.itemsMap);
                    } else if (model.custom.MIDs[product.mid]) {
                        customMedia = customMedia.concat(product.items);
                    } else {
                        digitalMedia = digitalMedia.concat(product.itemsMap);
                    }
                });
                return $q
                    .when(
                        !quote.unitPrices
                            ? dashUnitPricesService
                                  .newUnitPrices()
                                  .then(priceResult => {
                                      quote.unitPrices = angular.copy(
                                          priceResult
                                      );
                                      setPrices(
                                          quote,
                                          printMedia,
                                          digitalMedia,
                                          customMedia
                                      );
                                      return quote;
                                  })
                            : (() => {
                                  setPrices(quote, printMedia, digitalMedia, customMedia);
                                  return quote;
                              })()
                    )
                    .catch(err => {
                        console.error(err);
                        throw err;
                    })
                    .then(pricedQuote => {
                        quote = pricedQuote;
                        return pricedQuote;
                    });
            };

            let initPrices = media => {
                let unitPrice = { name: "unitPrice", value: -1 };
                let totalPrice = { name: "totalPrice", value: -1 };
                if (!media.unitPrice) {
                    media.fields.push(unitPrice);
                    media.unitPrice = unitPrice;
                }
                if (!media.totalPrice) {
                    media.fields.push(totalPrice);
                    media.totalPrice = totalPrice;
                }
                return media;
            };

            let setPrintPrices = (printMedia, unitPrices, prices) => {
                let setUnitPrice = media =>
                    model.print.setUnitPrice(
                        media,
                        dashUnitPricesService.print.initUnitPrice(
                            unitPrices,
                            media.mid
                        )
                    );
                let setTotalPrice = media => {
                    media.totalPrice.value =
                        (parseFloat(media.qty.value) || 0) *
                        media.unitPrice.value;
                    prices.push(media.totalPrice.value);
                    return media;
                };
                printMedia
                    .map(initPrices)
                    .map(setUnitPrice)
                    .map(setTotalPrice);
            };

            let setDigitalPrices = (digitalMedia, unitPrices, prices) => {
                let setUnitPrice = media =>
                    model.digital.setUnitPrice(media, unitPrices);
                let setTotalPrice = media => {
                    if (media.qty === undefined)
                        media.totalPrice.value = media.unitPrice.value;
                    else
                        media.totalPrice.value =
                            (parseFloat(media.qty.value) || 0) *
                            media.unitPrice.value;
                    prices.push(media.totalPrice.value);
                };
                digitalMedia
                    .map(initPrices)
                    .map(setUnitPrice)
                    .map(setTotalPrice);
            };

            let setCustomPrices = (customMedia, unitPrices, prices) => {
                let setTotalPrice = (media1) => {
                    if(media1.length > 1) {
                        prices.push(media1[1].value);
                    }
                };
                customMedia
                    .map(setTotalPrice);
            };


            let setMiscPrices = quote => {
                let brand = authService.getBrand();

                //calculate pubhub charge for submitted papers
                if (brand.isPubHub) {
                    quote.computedPrices.estSubmittedPapers =
                        quote.unitPrices.submittedPapers.flatRate +
                        quote.unitPrices.submittedPapers.rate *
                            (quote.estSubmittedPapers || 0);
                } else quote.computedPrices.estSubmittedPapers = 0;

                //calculate accepted papers charge
                let acceptedRate = quote.isSponsored
                    ? quote.unitPrices.acceptedPapers.rate.sponsored
                    : quote.unitPrices.acceptedPapers.rate.nonSponsored;
                quote.computedPrices.estAcceptedPapers =
                    acceptedRate * (quote.estAcceptedPapers || 0);

                //calculate xplore processing
                if (!quote.isSponsored) {
                    quote.computedPrices.xploreProcessing =
                        quote.unitPrices.xploreProcessing.flatRate +
                        quote.unitPrices.xploreProcessing.rate *
                            (quote.estAcceptedPapers || 0);
                } else quote.computedPrices.xploreProcessing = 0;

                let discount = quote.unitPrices.bulkDiscounts.find(
                    discount =>
                        (quote.estAcceptedPapers || 0) >= discount.min &&
                        (quote.estAcceptedPapers || 0) <= discount.max
                );
                quote.computedPrices.bulkDiscount =
                    -1 * ((discount && discount.percentage) || 0);
            };

            let setPrices = (quote, printMedia, digitalMedia, customMedia) => {
                let prices = [];

                quote.computedPrices = quote.computedPrices =
                    quote.computedPrices || {};
                if (quote.estAcceptedPapers)
                    quote.estAcceptedPapers =
                        parseInt(quote.estAcceptedPapers) || 0;
                if (quote.estSubmittedPapers)
                    quote.estSubmittedPapers =
                        parseInt(quote.estSubmittedPapers) || 0;

                setPrintPrices(printMedia, quote.unitPrices, prices);
                setDigitalPrices(digitalMedia, quote.unitPrices, prices);
                setCustomPrices( customMedia, quote.unitPrices, prices);
                setMiscPrices(quote);

                prices.push(quote.computedPrices.estSubmittedPapers);
                prices.push(quote.computedPrices.estAcceptedPapers);
                prices.push(quote.computedPrices.xploreProcessing);

                //subtotal
                quote.computedPrices.subTotal = prices
                    .filter(price => price != null)
                    .reduce(
                        (totalPrice, price) =>
                            (totalPrice += parseFloat(price)),
                        0
                    );

                //get bulk discount amount
                quote.computedPrices.bulkDiscount =
                    quote.computedPrices.estAcceptedPapers *
                    (quote.computedPrices.bulkDiscount / 100.0);

                //final total
                quote.computedPrices.totalPrice =
                    quote.computedPrices.subTotal +
                    quote.computedPrices.bulkDiscount +
                    (quote.computedPrices.manualAdjustment || 0);
            };

            model.custom = {
                MIDs: {
                    CUSTOM: true
                },
                getUnitPrice: (media, unitPrices) => {
                    if (media.mid === "CUSTOM") {
                        return unitPrices[media.mid].rate;
                    }
                },
                setUnitPrice: (media, unitPrices) => {
                    media.unitPrice.value = model.custom.getUnitPrice(
                        media,
                        unitPrices
                    );
                    return media;
                },
            };
            model.print = {
                MIDs: {
                    BOOK: true,
                    ABSTRACT_BOOK: true,
                    PRINT_PROGRAM: true
                },
                //TODO check from trim size match
                getPaperTypeRate: (rates, pages, type, trim) => {
                    let selectedRate = rates.filter(
                        rate =>
                            rate.paperType === type &&
                            rate.trimSize === trim &&
                            pages >= rate.minPages &&
                            pages <= rate.maxPages
                    );
                    return (
                        (selectedRate &&
                            selectedRate.length > 0 &&
                            selectedRate[0].rate) ||
                        0
                    );
                },
                getCoverCost: (media, coverRates) => {
                    let HARD_COVER = 1;
                    media.bookVolumes = media.bookVolumes || { value: 1 };
                    let volumes = parseFloat(media.bookVolumes.value);
                    let coverCost = coverRates.length
                        ? coverRates[volumes - 1] || coverRates.slice(-1)[0]
                        : angular.copy(dashUnitPricesService.newPrintCover());
                    return (
                        (media.bookCoverType.value === HARD_COVER
                            ? coverCost.hard
                            : coverCost.soft) * media.bookVolumes.value
                    );
                },
                getSubTotal: (media, unitPrices) => {
                    let totalPages = parseFloat(media.bookTotalPages.value);
                    let colorPages = parseFloat(media.bookColorPages.value);
                    let bwPages = totalPages - colorPages;
                    let trim = media.bookTrimSize.value;

                    return (
                        model.print.getPaperTypeRate(
                            unitPrices.rates,
                            bwPages,
                            "bw",
                            trim
                        ) *
                            bwPages +
                        model.print.getPaperTypeRate(
                            unitPrices.rates,
                            colorPages,
                            "color",
                            trim
                        ) *
                            colorPages +
                        model.print.getCoverCost(media, unitPrices.coverRates)
                    );
                },
                getTotalAdjustment: (media, unitPrices) =>
                    (model.print.getSubTotal(media, unitPrices) *
                        (unitPrices.unitPriceAdjustment || 0)) /
                   100.0,
                getUnitPrice: (media, unitPrices) => {
                    if(unitPrices.hasUnitPriceOverride && unitPrices.overridePrice)
                        return unitPrices.overridePrice;
                    else
                        return model.print.getSubTotal(media, unitPrices) +
                            model.print.getTotalAdjustment(media, unitPrices)
                },
                setUnitPrice: (media, unitPrices) => {
                    media.unitPrice.value = model.print.getUnitPrice(
                        media,
                        unitPrices
                    );
                    return media;
                }
            };

            model.digital = {
                getUnitPrice: (media, unitPrices) => {
                    if (media.mid === "CDROM" || media.mid === "DVD")
                        return model.digital.getDiskUnitPrice(
                            media,
                            unitPrices
                        );
                    else {
                        return unitPrices[media.mid].rate;
                    }
                },
                setUnitPrice: (media, unitPrices) => {
                    media.unitPrice.value = model.digital.getUnitPrice(
                        media,
                        unitPrices
                    );
                    return media;
                },
                getDiskUnitPrice: (media, unitPrices) => {
                    let diskCoverPrice = unitPrices.diskPackagingRates.find(
                        rate => rate.value === media.diskCoverType.value
                    );
                    diskCoverPrice =
                        (diskCoverPrice && diskCoverPrice.rate) || 0;
                    return diskCoverPrice + unitPrices[media.mid].rate;
                }
            };

            return model;
        }
    ]);
})();
