(function() {
    "use strict";

    angular
        .module("cpir")
        .filter(
            "exportDownloadOrderByCreateDateFilter",
            (ProceedingStatusService, moment) => {
                return function(keys, reverse) {
                    if (!keys) return null;
                    keys.sort((a, b) => {
                        let dateA = moment(a.exportStartTime);
                        let dateB = moment(b.exportStartTime);

                        console.log(a.time);

                        if (dateA.isSameOrAfter(dateB)) {
                            return reverse ? -1 : 1;
                        } else {
                            return reverse ? 1 : -1;
                        }
                    });

                    return keys;
                };
            }
        );
})();
