(function() {
    "use strict";

    angular.module("cpir").filter("proceedingActiveFilter", () => {
        return proceedings => {
            if (!proceedings) return proceedings;
            return proceedings.filter(p => p.status !== 0);
        };
    });
})();
