(function() {
    "use strict";

    angular.module("cpir").component("cpirCifAddWorkshopButton", {
        bindings: {
            addWorkshop: "&",
            isDisabled: "<",
            workshopFlag: "<"
        },
        templateUrl:
            "application/cpir/cif/workshops/components/workshops-panel/components/add-workshop-button/add-workshop-button.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.setPopOverMessage();
            }

            $onChanges(changes) {
                if(changes.workshopFlag) {
                    this.setPopOverMessage();
                }
            }

            buttonClicked() {
                if (this.isDisabled === false) {
                    this.addWorkshop();
                } else {
                    this.setPopOverMessage();
                }
            }

            setPopOverMessage() {
                if(!this.workshopFlag)
                    this.popOverMessage = "Workshops are not enabled for this proceeding.";
                else
                    this.popOverMessage = "Please complete or remove the current workshop before adding another."
            }
        }
    });
})();
