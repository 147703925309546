(function() {
    "use strict";

    angular.module("cpir").component("noShowPanel", {
        bindings: {
            resolve: "<",
            entries: "<",
            close: "&",
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/no-show-panel/no-show-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                console.log("printing noshows");

                if (this.resolve) {
                    this.entries = this.resolve.entries;
                }
            }

            cancelButtonClicked() {
                this.close();
            }

        }
    });
})();
