(function () {
    "use strict";

    /*
    This service interfaces with the stored templates, either global
    templates or those that are proceeding-specific. After accepting
    a 'key' for the name of the template, it fetches the template. If
    the template is proceeding-specific, the props.pid field must
    contain the pid.

    The props object may contain:

    'to' ([] string) recipients

    'pid' (string) will be checked and if found, the proceeding-specific
    version of the template will be retrieved

    'data' (object) will be compiled against the template to replace
    any placeholders

     */

    angular.module("cpir").provider(
        "EmailService",
        class {
            $get(
                $q,
                $http,
                $uibModal,
                authService,
                ConfigurationService,
                EnvironmentService,
                ProceedingService,
                Hogan,
                ContactService,
            ) {
                /**
                 * Private helper that gets an e-mail address according
                 * to the environment.
                 * @return {string}
                 */
                let getFromAddress = () => {
                    if (EnvironmentService.isProduction()) {
                        return authService.getBrand().isCPS
                            ? "no-reply-cps@computer.org"
                            : "pubhub-mailer@computer.org";
                    } else {
                        return authService.getBrand().isCPS
                            ? "no-reply-cps@computer.org"
                            : "pubhub-test-mailer@computer.org";
                    }
                };

                function getEditorAddress() {
                    if (EnvironmentService.isProduction()) {
                        return authService.getBrand().isCPS
                            ? ["cps@computer.org"]
                            : ["pubhub@computer.org"];
                    } else {
                        return authService.getBrand().isCPS
                            ? ["itcore-ieeecs@computer.org"]
                            : ["itcore-ieeecs@computer.org"];
                    }
                }

                function customize(
                    key,
                    statusToChange,
                    editorEmail,
                    pid,
                    entryIds,
                    entryMap,
                ) {
                    return (
                        getTemplate(key, pid)
                            // Show the customization modal
                            .then(
                                (template) =>
                                    $uibModal.open({
                                        size: "xl",
                                        component: "cpirEmailTemplateModal",
                                        resolve: {
                                            template: () => template,
                                            statusToChange: () =>
                                                statusToChange,
                                            authorComm: () => true,
                                            editorEmail: () => editorEmail,
                                            entryIds: () => entryIds,
                                            entryMap: () => entryMap,
                                            //pass in values o know if this is from author comm and then display the fields conditonally
                                        },
                                    }).result,
                            )
                    );
                }

                function sendTemplate(template, props) {
                    return (
                        $q
                            .when(
                                props.data
                                    ? compileTemplate(template, props.data)
                                    : template,
                            )
                            // Send the template
                            .then((template) => {
                                let toAddress = "";
                                if (props.to) toAddress = props.to;
                                if (template.to)
                                    toAddress = _.union(props.to, template.to);

                                console.log("inside sendtemplate");
                                console.log(toAddress);

                                let message = {
                                    from: props.from, // getFromAddress(),
                                    //cc and bcc from the props obj has to b set here
                                    to: toAddress,
                                    cc: props.cc,
                                    bcc: props.bcc,
                                    subject: template.subject,
                                    body: template.body,
                                };
                                return $http
                                    .post("/email", { message: message })
                                    .catch((e) => console.log(e));
                            })
                            .catch((e) => {
                                console.log(e);
                                throw e;
                            })
                    );
                }

                /**
                 * Send multiple emails at once through the backend bulk email endpoint
                 * This allows the browser to disconnect while emails are processed
                 * @param {Array} emailsData Array of objects containing template and props for each email
                 * @param {Number} concurrency Optional concurrency limit (default is 10)
                 * @returns {Promise} Promise that resolves when the request is accepted (not when emails are sent)
                 */
                function bulkSendTemplates(emailsData, concurrency = 10) {
                    // Process each email data to create messages
                    console.log("inside bulk send");

                    const messagePromises = emailsData.map(
                        ({ template, props }) => {
                            return $q
                                .when(
                                    props.data
                                        ? compileTemplate(template, props.data)
                                        : template,
                                )
                                .then((compiledTemplate) => {
                                    let toAddress = "";
                                    if (props.to) toAddress = props.to;
                                    if (compiledTemplate.to)
                                        toAddress = _.union(
                                            props.to,
                                            compiledTemplate.to,
                                        );

                                    return {
                                        from: getFromAddress(),
                                        to: toAddress,
                                        subject: compiledTemplate.subject,
                                        body: compiledTemplate.body,
                                        cc: props.cc,
                                        bcc: props.bcc,
                                    };
                                });
                        },
                    );

                    // Wait for all template compilations to complete
                    return $q
                        .all(messagePromises)
                        .then((messages) => {
                            console.log("printing messages");
                            console.log(messages);
                            // Send all messages to the bulk endpoint
                            return $http.post("/email/bulk", {
                                messages: messages,
                                concurrency: concurrency,
                            });
                        })
                        .catch((e) => {
                            console.error("Error preparing bulk emails:", e);
                            throw e;
                        });
                }

                /**
                 * Get the status of a bulk email operation
                 * @param {String} operationId The ID of the bulk email operation
                 * @returns {Promise} Promise that resolves with the operation status
                 */
                function getBulkOperationStatus(operationId) {
                    return $http
                        .get(`/email/bulk/${operationId}`)
                        .then((response) => response.data)
                        .catch((error) => {
                            console.error(
                                "Error fetching bulk email status:",
                                error,
                            );
                            throw error;
                        });
                }

                /**
                 * Get a list of recent bulk email operations
                 * @returns {Promise} Promise that resolves with a list of operations
                 */
                function listBulkOperations() {
                    return $http
                        .get("/email/bulk")
                        .then((response) => response.data)
                        .catch((error) => {
                            console.error(
                                "Error fetching bulk email operations:",
                                error,
                            );
                            throw error;
                        });
                }

                /**
                 * Public method that gets a template, shows a dialogue
                 * to customize the template, compiles it if necessary,
                 * and then mails it out upon confirmation.
                 *
                 * For compiling the template, it looks for a 'data'
                 * property the 'props' object. If it exists, then it
                 * will be used to compile the template in a Mustache
                 * compatible library.
                 * @param key
                 * @param props
                 */
                async function customizeAndSend(key, props) {
                    // Get the template
                    const template = await customize(
                        key,
                        null,
                        null,
                        props.pid,
                    );
                    return sendTemplate(template, props);
                }

                /**
                 * Public method that gets a template, compiles it if
                 * necessary, and then mails it out.
                 *
                 * For compiling the template, it looks for a 'data'
                 * property the 'props' object. If it exists, then it
                 * will be used to compile the template in a Mustache
                 * compatible library.
                 * @param key
                 * @param props
                 */
                function sendImmediately(key, props) {
                    props.endpoint = props.endpoint || "/email";
                    // Get the template
                    return (
                        getTemplate(key, props.pid)
                            // Compile the template if 'data' is present
                            .then((template) => {
                                return props.data
                                    ? compileTemplate(template, props.data)
                                    : template;
                            })
                            // Send the template
                            .then((template) => {
                                let toAddress = "";
                                if (props.to) toAddress = props.to;
                                if (template.to)
                                    toAddress = _.union(toAddress, template.to);
                                else {
                                    if (
                                        toAddress === undefined ||
                                        toAddress === ""
                                    ) {
                                        toAddress = template.to
                                            ? template.to
                                            : template.nonSponsoredConfEmailToList;
                                    }
                                }
                                let message = {
                                    from: getFromAddress(),
                                    to: toAddress,
                                    subject: template.subject,
                                    body: template.body,
                                };
                                return $http
                                    .post(props.endpoint, { message: message })
                                    .catch((err) => {
                                        console.log(err);
                                        throw err;
                                    });
                            })
                            .catch((e) => {
                                console.log(e);
                                throw e;
                            })
                    );
                }

                /**
                 * Private helper method that will get the requested
                 * template by delegating to the appropriate function
                 * based on whether it's a local template (stored on
                 * the proceeding) or a global template (stored in
                 * the global configuration.
                 * @param key
                 * @param pid
                 * @returns {*}
                 */
                function getTemplate(key, pid) {
                    return pid
                        ? getProceedingTemplate(key, pid)
                        : getGlobalTemplate(key);
                }

                /**
                 * Private helper that gets a local template.
                 * @param key
                 * @param pid
                 * @returns {Promise.<TResult>|*}
                 */
                function getProceedingTemplate(key, pid) {
                    return ProceedingService.get(pid).then((proceeding) => {
                        let templates =
                            proceeding.configuration.settings.email
                                .emailTemplates;
                        let result = templates
                            .filter((t) => t.isGlobal === false)
                            .find((t) => t.key === key);
                        if (result === undefined) {
                            if (templates.find((t) => t.key === key)) {
                                throw new Error(
                                    "PID not allowed for global email template",
                                );
                            } else {
                                throw new Error(
                                    `Email template is not defined for key: ${key}`,
                                );
                            }
                        } else {
                            return result;
                        }
                    });
                }

                /**
                 * Private helper that gets a global template.
                 * @param key
                 * @returns {Promise.<TResult>|*}
                 */
                function getGlobalTemplate(key) {
                    let brand = authService.getBrand().name;
                    return ConfigurationService.get().then((result) => {
                        let configuration;

                        if (brand === "cps")
                            configuration = result.configuration.cps;
                        else if (brand === "pubhub")
                            configuration = result.configuration.pubhub;

                        let templates =
                            configuration.settings.email.emailTemplates;
                        let template = templates.find((t) => t.key === key);
                        if (!template) {
                            throw new Error(
                                `Email template is not defined for key: ${key}`,
                            );
                        } else if (!template.isGlobal) {
                            throw new Error(
                                "PID required for non-global email template",
                            );
                        }
                        return template;
                    });
                }

                /**
                 * Private helper function which takes in a template
                 * and compiles into end-form HTML.
                 * @param template
                 * @param data
                 * @returns {Prompt|String|*}
                 */
                function compileTemplate(template, data) {
                    // RegEx to match [[ and ]]
                    let templateVariable = /\[\[([_a-zA-Z0-9.]+)]]/g;

                    // Change editor delimiter to be compatible
                    // with Mustache
                    template.body = template.body.replace(
                        templateVariable,
                        "{{$1}}",
                    );
                    template.subject = template.subject.replace(
                        templateVariable,
                        "{{$1}}",
                    );

                    template.body = Hogan.compile(template.body).render(data);

                    template.subject = Hogan.compile(template.subject).render(
                        data,
                    );

                    return template;
                }

                function sendToEditors(proceeding, key, props = {}) {
                    const propsCopy = angular.copy(props);
                    propsCopy.data = propsCopy.data || {};
                    console.log(propsCopy);
                    return sendImmediately(key, propsCopy);
                }

                function sendToOrganizers(proceeding, key, props) {
                    props = props || {};
                    return ContactService.list(
                        proceeding.contacts.map((c) => c.cid),
                    )
                        .then((contacts) => {
                            $q.all(
                                contacts.map((contact) => {
                                    let propsCopy = angular.copy(props); // need to make copy or all emails will have same to address
                                    propsCopy.to = [contact.email];
                                    propsCopy.data = propsCopy.data || {};
                                    propsCopy.data.organizerName = `${contact.givenName} ${contact.surname}`;
                                    console.log(propsCopy);
                                    return sendImmediately(key, propsCopy);
                                }),
                            ).then((res) => {
                                console.log(`sent ${key} emails to contacts`);
                                return res;
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                            throw err;
                        });
                }

                return {
                    customizeAndSend,
                    customize,
                    sendTemplate,
                    sendImmediately,
                    sendToOrganizers,
                    sendToEditors,
                    getTemplate,
                    getFromAddress,
                    bulkSendTemplates,
                    getBulkOperationStatus,
                    listBulkOperations,
                };
            }
        },
    );
})();
