(function () {
    "use strict";

    class ColumnSettingsService {
        constructor($q) {
            this.$q = $q;

            // Initialize Dexie database
            this.db = new Dexie("cpirColumnSettings");

            // Define database schema
            this.db.version(1).stores({
                columnSettings: "pid,settings",
            });

            // Default column settings
            this.defaultSettings = {
                visibility: {
                    type: true,
                    title: true,
                    pages: true,
                    pageNumber: true,
                    version: true,
                    file: true,
                    stamp: true,
                    supplement: true,
                    pitstop: true,
                    productionStatus: true,
                    promo: true,
                    paperId: true,
                    similarity: true,
                    copyrightType: true,
                    authors: true,
                    doi: true,
                },
                order: [
                    "type",
                    "title",
                    "pages",
                    "pageNumber",
                    "version",
                    "file",
                    "stamp",
                    "supplement",
                    "pitstop",
                    "productionStatus",
                    "promo",
                    "paperId",
                    "similarity",
                    "copyrightType",
                    "authors",
                    "doi",
                ],
                fixedColumnId: "",
            };
        }

        /**
         * Get column settings for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @returns {Promise} Promise resolving to column settings
         */
        async getSettings(pid) {
            try {
                const result = await this.db.columnSettings.get(pid);
                return result ? result.settings : this.defaultSettings;
            } catch (error) {
                console.error("Error getting column settings:", error);
                return this.defaultSettings;
            }
        }

        /**
         * Save column settings for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @param {Object} settings - Column settings to save
         * @returns {Promise} Promise resolving when save is complete
         */
        async saveSettings(pid, settings) {
            try {
                await this.db.columnSettings.put({
                    pid,
                    settings,
                });
                return settings;
            } catch (error) {
                console.error("Error saving column settings:", error);
                throw error;
            }
        }

        /**
         * Delete column settings for a specific proceeding
         * @param {string} pid - Proceeding ID
         * @returns {Promise} Promise resolving when delete is complete
         */
        async deleteSettings(pid) {
            try {
                await this.db.columnSettings.delete(pid);
            } catch (error) {
                console.error("Error deleting column settings:", error);
                throw error;
            }
        }

        /**
         * Get default column settings
         * @returns {Object} Default column settings
         */
        getDefaultSettings() {
            return this.defaultSettings;
        }
    }

    // Register the service
    angular
        .module("cpir")
        .service("ColumnSettingsService", ["$q", ColumnSettingsService]);
})();
