(function() {
    "use strict";

    angular.module("cpir").component("cifQuotesProductModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/cif/quotes/components/product-modal/product-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.isEditing = this.resolve.isEditing;

                this.medium = this.resolve.medium;

                if (this.resolve.item) {
                    let item = this.resolve.item;
                    this.medium.fields.forEach(attribute => {
                        item.forEach(existingAttribute => {
                            if (attribute.name === existingAttribute.name) {
                                attribute.value = existingAttribute.value;
                            }
                        });
                    });
                }

                this.itemAttributes = [];
            }

            /**
             * Whenever the model changes, update the value
             * of the attribute.
             * @param name
             * @param value
             */
            updateValue(name, value, order) {
                // Find the attribute to update according
                // to its name
                let attribute = this.itemAttributes.find(a => a.name === name);

                // If the attribute was found, update the value,
                // if it wasn't found, add the value into the array
                if (attribute) {
                    attribute.value = value;
                } else {
                    this.itemAttributes.push({ order, name, value });
                }
            }

            // Hack to check if the product is a book, which
            // needs 'Volumes' to be injected after adding it
            // into the cart.
            setVolumesForBooks() {
                let totalPages = this.itemAttributes.find(
                    a => a.name === "bookTotalPages"
                );

                if (totalPages) {
                    // Calculate volumes (1 for every 800 pages)
                    this.itemAttributes.push({
                        name: "bookVolumes",
                        value: Math.ceil(totalPages.value / 800)
                    });
                }
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            saveButtonClicked() {
                this.setVolumesForBooks();

                this.close({
                    $value: this.itemAttributes
                });
            }
        }
    });
})();
