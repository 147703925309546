(function() {
    "use strict";

    angular.module("cpir").component("cpirBuildBookModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/build-book-modal/build-book-modal.component.html",
        controller: class {
            constructor(
                ItextService,
                WebSocketService,
                $scope,
                $window,
                NotificationService
            ) {
                this.ItextService = ItextService;
                this.safeApply = WebSocketService.safeApply;
                this.$scope = $scope;
                this.$window = $window;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.proceeding = this.resolve.proceeding;
                this.ItextService.buildBook(this.proceeding.pid).then(
                    socket => {
                        this.socket = socket;
                        socket.disconnectHandler = () => {
                            if (socket.downloadTimeout) {
                                this.dismiss();
                            } else {
                                this.dismiss();
                                this.NotificationService.send(
                                    "danger",
                                    `Failed to build book${
                                        socket.lastError
                                            ? ": " + socket.lastError
                                            : ""
                                    }`
                                );
                            }
                        };
                        socket.on("book-ready", key => {
                            socket.off("disconnect");
                            this.bookKey = key;
                            this.safeApply(this.$scope);
                        });
                        socket.on("disconnect", () =>
                            socket.disconnectHandler()
                        ); //we need to explicitly call this since the reference may have changed
                    }
                );
            }

            downloadButtonClicked() {
                console.log(this.bookKey);
                this.ItextService.downloadBook(
                    this.bookKey,
                    this.proceeding.year,
                    this.proceeding.acronym
                );
                this.close({ $value: this.bookKey });
            }

            cancelButtonClicked() {
                this.socket.disconnectHandler = this.socket.defaultDisconnectHandler;
                if (this.socket) this.socket.disconnect();
                this.dismiss();
                //send disconnect signal
            }
        }
    });
})();
