(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceAddSponsorButton", {
        bindings: {
            addSponsor: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/sponsors-panel/components/add-sponsor-button/add-sponsor-button.component.html",
        controller: class {
            buttonClicked() {
                this.addSponsor();
            }
        }
    });
})();
