(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceChairPanel", {
        bindings: {
            chair: "<",
            isLastChair: "<",
            onUpdate: "&",
            onRemove: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/chair-panel/member-panel.component.html",
        controller: function() {
            let $ctrl = this;

            $ctrl.modelChanged = () => {
                $ctrl.onUpdate({ updatedChair: $ctrl.chair });
            };

            $ctrl.removeButtonClicked = () => {
                $ctrl.onRemove();
            };
        }
    });
})();
