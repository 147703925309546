(function() {
    "use strict";

    angular.module("cpir").filter("entryAuthorsCountFilter", () => {
        return entry => {
            if (!entry) return entry;
            if (!entry.authors) return entry.authors;
            if (entry.class === "SB") {
                return entry.authors.length ? entry.authors.length : 0;
            } else {
                return null;
            }
        };
    });
})();
