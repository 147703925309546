(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceConfGeneralPanel", {
        bindings: {
            proceeding: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/compliance-conf-general-panel/compliance-conf-general-panel.component.html",
        controller: class {
            constructor($stateParams, ProceedingStatusService) {
                this.$stateParams = $stateParams;
                this.ProceedingStatusService = ProceedingStatusService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
            }

            isWaitingDecision() {
                this.ProceedingStatusService.isComplianceReview(this.pid) ||
                    this.ProceedingStatusService.isComplianceSubmitted(
                        this.pid
                    ) ||
                    this.ProceedingStatusService.isComplianceRejected(this.pid);
            }

            saveChanges() {
                this.onSave({
                    props: { compliance: this.proceeding.compliance }
                });
            }
        }
    });
})();
