(function () {
    "use strict";

    angular.module("cpir").controller(
        "AuthDispatcherController",

        /**
         * The AuthDispatchController is in charge of sending
         * the user to the correct landing page following
         * a successful login.
         *
         * It serves as the entry point of the application,
         * and then forwards the user according to applicable
         * details.
         */
        class {
            constructor(
                $http,
                $state,
                $window,
                store,
                moment,
                authService,
                FileService,
                NotificationService,
                ProceedingService,
                ProceedingStatusService,
                TosService,
                ProfileService,
                Cpir2Service,
            ) {
                this.$http = $http;
                this.$state = $state;
                this.$window = $window;
                this.store = store;
                this.moment = moment;
                this.authService = authService;
                this.FileService = FileService;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.TosService = TosService;
                this.Cpir2Service = Cpir2Service;
            }

            $onInit() {
                const profile = this.authService.getProfile();
                const isTokenExpired = this.authService.isTokenExpired();
                if (!isTokenExpired && profile) {
                    let deepLink = this.store.get("deepLink");

                    if (deepLink) {
                        switch (deepLink.nextLocation) {
                            case "inquiry":
                                this.Cpir2Service.redirectToCif().catch((err) =>
                                    console.error(err),
                                );
                                break;
                            case "dashboard":
                                this.$state.go("dashboard-editor.home", {
                                    searchParam: deepLink.searchParam,
                                });
                                break;
                            case "ak":
                                if (deepLink.entryId) {
                                    this.$state.go(
                                        "ak.preparation-information",
                                        {
                                            pid: deepLink.pid,
                                            entryId: deepLink.entryId,
                                        },
                                    );
                                } else {
                                    this.Cpir2Service.redirectToAuthorDashboard(
                                        deepLink.pid,
                                    ).catch((err) => console.error(err));
                                }
                                break;
                            case "quote":
                                this.Cpir2Service.redirectToQuote(
                                    deepLink.pid,
                                ).catch((err) => console.error(err));
                                break;
                            case "compliance":
                                this.Cpir2Service.redirectToCompliance(
                                    deepLink.pid,
                                ).catch((err) => console.error(err));
                                break;
                            case "pa":
                                this.$state.go("dashboard-editor.home");
                                this.sendToPA(deepLink);
                                break;
                            case "configuration":
                                this.Cpir2Service.redirectToProceedingConfiguration(
                                    deepLink.pid,
                                ).catch((err) => console.error(err));
                                break;
                            case "authorReview":
                                const { entryId } = deepLink;
                                this.Cpir2Service.redirectToAkAuthorReview(
                                    entryId,
                                ).catch((err) => console.error(err));
                                break;
                            case "cpir2":
                                const { returnPath } = deepLink;
                                this.Cpir2Service.redirectToReturnPath(
                                    returnPath,
                                ).catch((err) => console.error(err));
                                break;
                            case "authorChangeRequest":
                                this.$state.go(
                                    "dashboard-editor.table-of-contents-edit-entry",
                                    {
                                        ...deepLink,
                                        changeRequested: true,
                                    },
                                );
                                break;
                            case "copyrightReturn":
                                this.$state.go("ak.paper-acceptance", {
                                    ...deepLink,
                                });
                                break;
                            case "standaloneCopyright":
                                this.$state.go("ak.copyright-release", {
                                    ...deepLink,
                                    standalone: true,
                                });
                                break;
                            case "conferenceVideo":
                                this.$state.go(
                                    "ak-conference.conference-video-submission",
                                    {
                                        ...deepLink,
                                    },
                                );
                                break;
                            case "conferenceVideoList":
                                this.$state.go(
                                    "dashboard-editor.video-browser",
                                    {
                                        ...deepLink,
                                        back: false,
                                    },
                                );
                                break;
                            case "standaloneEntryVideoUpload":
                                this.$state.go("ak.entry-video-submission", {
                                    ...deepLink,
                                    standalone: true,
                                });
                                break;
                            case "webpubDownload":
                                this.$state.go(
                                    "dashboard-editor.webpub-download",
                                    { ...deepLink },
                                );
                                break;
                            case "exportDownload":
                                this.$state.go(
                                    "dashboard-editor.table-of-contents-downloads",
                                    { ...deepLink },
                                );
                                break;
                            default:
                                console.error(
                                    `Unhandled Deep Link: ${JSON.stringify(
                                        deepLink,
                                    )}`,
                                );
                                this.handleDefaultCase();
                        }
                    } else {
                        this.handleDefaultCase();
                    }

                    this.store.remove("deepLink");
                } else {
                    console.log("Dispatcher: waiting for auth handling...");
                }
            }

            checkLoginContext() {
                let foundLoginContext = false;
                const loginContext = sessionStorage.getItem("login_context");
                if (loginContext) {
                    if (loginContext === "author") {
                        this.Cpir2Service.redirectToAuthorDashboard().catch(
                            (err) => {
                                console.error(err);
                            },
                        );
                        foundLoginContext = true;
                    }
                    sessionStorage.removeItem("login_context");
                }
                return foundLoginContext;
            }

            handleDefaultCase() {
                if (!this.checkLoginContext()) {
                    // Login context was missing or not already handled
                    this.isNewUser().then((isNew) => {
                        if (isNew) {
                            this.Cpir2Service.redirectToCif().catch((err) =>
                                console.error(err),
                            );
                        } else {
                            this.$state.go("dashboard-editor.home");
                        }
                    });
                }
            }

            isNewUser() {
                const profile = this.authService.getProfile();
                let now = this.moment();
                let created = profile.created_at;
                let diff = this.moment.duration(
                    this.moment(now).diff(this.moment(created)),
                );
                return Promise.resolve(diff < 60000);
            }

            sendToConfiguration(deepLink) {
                const { viewQuotes, pid, index } = deepLink;
                if (viewQuotes) {
                    this.$state.go(
                        "dashboard-editor.proceeding-configuration",
                        { pid, view_quotes: true, quote_index: index },
                    );
                } else {
                    this.$state.go(
                        "dashboard-editor.proceeding-configuration",
                        { pid },
                    );
                }
            }

            sendToPA(deepLink) {
                console.log("pid", deepLink.pid);
                this.ProceedingService.get(deepLink.pid)
                    .then((proceeding) => {
                        if (
                            this.ProceedingStatusService.isAtLeastAkSetup(
                                proceeding,
                            )
                        ) {
                            this.FileService.getPublicationAgreement(
                                deepLink.pid,
                                {
                                    openInCurrentWindow: true,
                                },
                            );
                        }
                        if (
                            proceeding.docuSign &&
                            proceeding.docuSign.envelopeId
                        ) {
                            this.NotificationService.send(
                                "success",
                                "Please wait while we redirect you...",
                            );
                            this.$http
                                .post("/docusign", {
                                    returnUrl: `${this.$window.location.href}?pid=${proceeding.pid}`,
                                    proceeding: proceeding,
                                })
                                .then(
                                    (successResult) => {
                                        // console.log(successResult);
                                        this.$window.location.href =
                                            successResult.data.url;
                                        return successResult;
                                    },
                                    (errorResult) => {
                                        this.NotificationService.send(
                                            "danger",
                                            `There was a problem redirecting to DocuSign: ${errorResult.data}`,
                                        );
                                        console.log(errorResult);
                                    },
                                );
                        } else {
                            this.NotificationService.send(
                                "danger",
                                "There was an error while obtaining publication agreement.",
                            );
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.NotificationService.send(
                            "warning",
                            "An error occurred. Please contact your editor.",
                        );
                    });
            }
        },
    );
})();
