(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardEditorPaTextArea", {
        bindings: {
            title: "<",
            content: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/publication-agreement/components/" +
            "pa-text-area/pa-text-area.component.html",
        controller: function(DefaultSettingsService) {
            let $ctrl = this;

            $ctrl.modelChanged = () => {
                $ctrl.onUpdate({ content: $ctrl.content });
            };

            $ctrl.$onInit = () => {};
        }
    });
})();
