(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceContactsPanel", {
        bindings: {
            contacts: "<",
            isDisabled: "<",
            isErrorsVisible: "<",
            isPrimaryInContacts: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/contacts-panel/contacts-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                if (!this.contacts) {
                    let initialContact = {};
                    this.contacts = [initialContact];
                }
                this.activeContactIndex = 0;
                this.activeContact = this.contacts[0];

                this.chairValidation = {
                    chairsMatch: false,
                    missingGeneralChair: true,
                    missingFinanceChair: true
                };
            }

            $onChanges(changes) {
                if (changes.contacts && changes.contacts.currentValue) {
                    this.activeContact = this.contacts[0];
                }
            }

            addContact() {
                this.contacts.push({
                    address: {
                        country: "US"
                    }
                });
                this.activateContact(this.contacts.length - 1);
            }

            removeActiveContact() {
                this.contacts.splice(this.activeContactIndex, 1);
                this.activateContact(this.activeContactIndex - 1);
            }

            activateContact(contactIndex) {
                this.activeContactIndex = contactIndex;
                this.activeContact = this.contacts[contactIndex];
            }

            updateContact(contact) {
                this.activeContact = contact;
                this.modelChanged();
            }

            modelChanged() {
                this.onUpdate({
                    contacts: this.contacts,
                    chairValidation: this.getChairValidation()
                });
            }

            getChairValidation() {
                if (!this.contacts) return this.chairValidation;

                const generalChairs =
                    this.contacts.filter(
                        c => c.roles && c.roles.find(r => r === "GC")
                    ) || [];
                const financeChairs =
                    this.contacts.filter(
                        c => c.roles && c.roles.find(r => r === "FC")
                    ) || [];
                const gcNames = generalChairs.map(c =>
                    (
                        (c.givenName || "").trim() + (c.surname || "").trim()
                    ).toLowerCase()
                );
                const fcNames = financeChairs.map(c =>
                    (
                        (c.givenName || "").trim() + (c.surname || "").trim()
                    ).toLowerCase()
                );
                const gcEmails = generalChairs.map(c =>
                    (c.email || "").trim().toLowerCase()
                );
                const fcEmails = financeChairs.map(c =>
                    (c.email || "").trim().toLowerCase()
                );

                const hasGeneralAndFinanceChairs =
                    generalChairs.length > 0 && financeChairs.length > 0;

                const namesMatch =
                    hasGeneralAndFinanceChairs &&
                    _.union(gcNames, fcNames).length < 2;
                const emailsMatch =
                    hasGeneralAndFinanceChairs &&
                    _.union(gcEmails, fcEmails).length < 2;
                this.chairValidation.chairsMatch =
                    hasGeneralAndFinanceChairs && (namesMatch || emailsMatch);

                this.chairValidation.missingGeneralChair =
                    generalChairs.length < 1;
                this.chairValidation.missingFinanceChair =
                    financeChairs.length < 1;

                return this.chairValidation;
            }
        }
    });
})();
