(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardEditorPaConfirmResendQuote", {
            bindings: {
                close: "&",
                dismiss: "&",
                size: "@"
            },
            templateUrl:
                "application/cpir/dashboard/editor/publication-agreement/components/modals/confirm-resend-quote.component.html",
            controller: class {
                constructor() {}

                $onInit() {}

                ok() {
                    this.close();
                }

                cancel() {
                    this.dismiss();
                }
            }
        });
})();
