(function() {
    "use strict";

    angular.module("cpir").filter("entryClassFilter", () => {
        return entry => {
            if (!entry) return entry;
            if (!entry.class) return entry.class;
            if (entry.class === "SB") return "AP";
            return entry.class;
        };
    });
})();
