(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkVideoSubmissionController",

        class {
            constructor(
                pid,
                entryId,
                standalone,
                $state,
                $stateParams,
                $scope,
                $uibModal,
                NotificationService,
                AKService,
                ProceedingService,
                ProceedingStatusService,
                TocEntriesApiService,
                UploadService,
                FileService,
                VideoService,
                WebSocketService,
                $filter,
                ItextService,
                $window,
                $document,
                Cpir2Service
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.isStandalone = !!standalone;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$scope = $scope;
                this.$uibModal = $uibModal;
                this.AKService = AKService;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.UploadService = UploadService;
                this.FileService = FileService;
                this.VideoService = VideoService;
                this.WebSocketService = WebSocketService;
                this.$filter = $filter;
                this.$window = $window;
                this.$document = $document;
                this.ItextService = ItextService;
                this.Cpir2Service = Cpir2Service;
            }

            $onInit() {
                this.sessionTypeOptions = this.sessionTypeOptions = this.entryId
                    ? [
                          {
                              name: "I don't know / Use conference session",

                              value: null
                          }
                      ]
                    : [];
                this.sessionTypeSelector = null;

                this.videoDetails = {};

                this.AKService.setActive(this.pid, this.entryId);

                this.proceeding = this.AKService.getProceeding();

                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;

                        // check video submissions settings
                        const videoSubmission =
                            this.proceeding.configuration.settings.ak
                                .videoSubmission || {};
                        const { enabled, open } = videoSubmission;
                        this.videoSubmissionsOpen = enabled && open;
                    }
                );

                this.TocEntriesApiService.getSessionDividerNames(this.pid).then(
                    sessionDividers => {
                        this.sessionTypeOptions = this.sessionTypeOptions
                            .concat(
                                _.map(sessionDividers, name => ({
                                    name,
                                    value: name
                                }))
                            )
                            .concat([{ name: "Other", value: "Other" }]);
                        this.sessionTypeSelector = null;
                    }
                );

                this.loadVideos();

                if (this.entryId) {
                    // get entry data
                    this.TocEntriesApiService.getEntry(this.entryId).then(
                        entry => {
                            this.entry = entry;
                        }
                    );
                } else {
                    this.entry = null;
                }
            }

            loadVideos() {
                if (this.entryId) {
                    // only load videos for the same article
                    this.VideoService.getVideosForEntry(this.entryId).then(
                        videos => {
                            this.existingVideoFiles = videos;
                        }
                    );
                } else {
                    this.VideoService.getVideosForUser().then(videos => {
                        this.existingVideoFiles = _.filter(videos, {
                            pid: this.pid, // same conference
                            eid: null // not an article video
                        });
                    });
                }
            }

            canSubmit() {
                return (
                    this.isLastStep() &&
                    !this.AKService.isApprovalMode() &&
                    !this.isSavingFile() &&
                    !this.isStandalone
                );
            }

            canGoToNextStep() {
                return !this.isSavingFile();
            }

            saveAndGoToNextStep() {
                this.goToNextStep();
            }

            isStateValid() {
                return true;
            }

            isLastStep() {
                return this.AKService.isLastStep();
            }

            goToPreviousStep() {
                if (this.isStandalone) {
                    this.Cpir2Service.redirectToAuthorDashboard(
                        this.pid
                    ).catch(err =>
                        console.error(
                            "Error redirection to author dashboard: ",
                            err
                        )
                    );
                } else {
                    this.AKService.goToPreviousStep();
                }
            }

            goToNextStep() {
                this.AKService.goToNextStep({
                    pid: this.pid
                });
            }

            getConferenceAcronym() {
                if (!this.proceeding) return "";
                return `${this.proceeding.acronym} ${this.proceeding.year}`;
            }

            getConferenceDates() {
                if (!this.proceeding) return;
                const startDateString = this.$filter("date")(
                    this.proceeding.dates.start,
                    "d MMMM yyyy"
                );
                const endDateString = this.$filter("date")(
                    this.proceeding.dates.end,
                    "d MMMM yyyy"
                );
                return `${startDateString} through ${endDateString}`;
            }

            isVideoDetailsRequired() {
                return !this.entryId && this.videoDetailsForm.$invalid;
            }

            hasSessionTitle() {
                return !_.isEmpty(_.trim(this.videoDetails.session));
            }

            hasExistingVideos() {
                return !_.isEmpty(this.existingVideoFiles);
            }

            isSavingFile() {
                return (
                    this.savingVideoRelease ||
                    this.savingVideo ||
                    this.savingTranscript
                );
            }

            saveVideoReleasePdf() {
                console.log("Saving video release");
                this.savingVideoRelease = true;
                // Enable navigation prompt
                window.onbeforeunload = () => true;
                const data = {
                    conferenceTitle: this.proceeding.title,
                    conferenceAcronym: this.getConferenceAcronym(),
                    conferenceDatesString: this.getConferenceDates(),
                    conferenceEndDateString: this.$filter("date")(
                        this.proceeding.dates.end,
                        "MM/dd/yyyy"
                    ),
                    speakers: this.videoDetails.speakers,
                    videoTitle: this.videoDetails.title,
                    session: this.videoDetails.session,
                    signature: this.videoDetails.releaseSignature,
                    signatureDateString: this.$filter("date")(
                        new Date(),
                        "MM/dd/yyyy"
                    )
                };
                return this.ItextService.generateVideoReleaseForm(
                    this.pid,
                    data
                )
                    .then(({ vid }) => {
                        this.savingVideoRelease = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        this.videoDetails.copyrightReleaseFileVid = vid;
                        console.log(`video release created. vid ${vid}`);
                    })
                    .catch(err => {
                        this.savingVideoRelease = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        throw err;
                    });
            }

            saveVideo() {
                // Don't attempt to save if there is an ongoing save operation
                if (this.savingVideo || this.savingTranscript) {
                    return;
                }

                const saveVideoReleaseAsync = this.entryId
                    ? Promise.resolve()
                    : this.saveVideoReleasePdf();
                saveVideoReleaseAsync
                    .then(() => {
                        console.log("Saving video");
                        this.savingVideo = true;
                        // Enable navigation prompt
                        window.onbeforeunload = () => true;

                        this.videoBytesUploaded = 0;
                        const videoUpload = this.UploadService.uploadVideo({
                            pid: this.pid,
                            eid: this.entryId,
                            video: this.selectedVideo,
                            videoDetails: this.videoDetails,
                            onProgress: uploadedBytes => {
                                this.videoBytesUploaded = uploadedBytes;
                                this.WebSocketService.safeApply(this.$scope);
                            }
                        });
                        videoUpload.processingAsync.then(() => {
                            console.log(
                                "video processing finished. Marking the new video as downloadable."
                            );
                            this.uploadedVideo.processingVideo = false;
                            this.WebSocketService.safeApply(this.$scope);
                        });
                        this.videoUpload = videoUpload;
                        return videoUpload.promise;
                    })
                    .then(({ videoId }) => this.VideoService.getVideo(videoId))
                    .then(newVideo => {
                        console.log("uploaded new video");
                        this.uploadedVideo = newVideo;
                        this.uploadedVideo.processingVideo = true;
                        this.existingVideoFiles.push(this.uploadedVideo);
                        this.savingVideo = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        if (this.selectedTranscript) {
                            return this.saveTranscript(true);
                        } else {
                            this.NotificationService.send(
                                "success",
                                "Your video has been uploaded successfully.",
                                { sticky: true }
                            );
                        }
                    })
                    .then(() => {
                        // scroll to top & reset form
                        this.videoDetails = {};
                        if (this.entryId) {
                            this.videoDetails.sessionType = "conference";
                        }
                        if (this.videoDetailsForm) {
                            this.videoDetailsForm.$setUntouched();
                            this.videoDetailsForm.$setPristine();
                        }
                        this.selectedVideo = null;
                        this.selectedTranscript = null;
                        const scrollTarget = angular.element(
                            document.getElementById("previouslyUploadedVideos")
                        );
                        this.$document
                            .scrollToElementAnimated(
                                scrollTarget,
                                this.entryId ? 90 : 160
                            )
                            .catch(err => console.log(err));
                        this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        console.error(err);
                        this.savingVideo = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        this.NotificationService.send(
                            "warning",
                            "There was an error while uploading the video submission.",
                            { sticky: true }
                        );
                        this.WebSocketService.safeApply(this.$scope);
                    });
            }

            saveTranscript(sendVideoSuccessMessage) {
                console.log("saving transcript");
                this.transcriptBytesUploaded = 0;
                this.savingTranscript = true;
                // Enable navigation prompt
                window.onbeforeunload = () => true;
                const transcriptUpload = this.UploadService.uploadTranscript({
                    pid: this.pid,
                    eid: this.entryId,
                    videoId: this.uploadedVideo.videoId,
                    transcript: this.selectedTranscript,
                    onProgress: uploadedBytes => {
                        this.transcriptBytesUploaded = uploadedBytes;
                        this.WebSocketService.safeApply(this.$scope);
                    }
                });

                transcriptUpload.processingAsync.then(() => {
                    console.log(
                        "transcript processing finished. Marking the new transcript as downloadable."
                    );
                    this.uploadedVideo.processingTranscript = false;
                });

                this.transcriptUpload = transcriptUpload;

                return transcriptUpload.promise
                    .then(({ videoId }) => this.VideoService.getVideo(videoId))
                    .then(updatedVideo => {
                        console.log("Uploaded transcript");
                        // update the video record with the transcript ids and set the processing flag for it
                        this.uploadedVideo.transcript = updatedVideo.transcript;
                        this.uploadedVideo.processingTranscript = true;
                        this.savingTranscript = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        if (sendVideoSuccessMessage) {
                            return this.NotificationService.send(
                                "success",
                                "Your video & transcript have been uploaded successfully.",
                                { sticky: true }
                            );
                        } else {
                            return this.NotificationService.send(
                                "success",
                                "Your transcript has been uploaded successfully.",
                                { sticky: true }
                            );
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        this.savingTranscript = false;
                        // Disable navigation prompt
                        window.onbeforeunload = null;
                        this.NotificationService.send(
                            "warning",
                            "There was an error while uploading the video transcript.",
                            { sticky: true }
                        );
                        this.WebSocketService.safeApply(this.$scope);
                    });
            }

            selectVideoFile(file, invalidFile) {
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose an MP4 file for this submission."
                    );
                }
            }

            cancelVideoUpload() {
                this.selectedVideo = null;
                this.savingVideo = false;
                // Disable navigation prompt
                window.onbeforeunload = null;
                if (this.videoUpload) {
                    this.videoUpload.cancelAsync.then(cancel => cancel());
                }
            }

            removeVideoFile() {
                if (this.selectedVideo) this.selectedVideo = null;
            }

            selectTranscriptFile(file, invalidFile) {
                if (_.isEmpty(file.type)) {
                    const fileExtension = _.last(
                        file.name.split(".")
                    ).toLowerCase();
                    if (fileExtension === "srt") {
                        file.alternateType = "text/srt";
                    }
                }
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose a text file for this submission."
                    );
                }
                this.transcriptDetails = {};
                this.transcriptDetails.name = file.name;
                this.transcriptDetails.size = file.size;
            }

            cancelTranscriptUpload() {
                this.selectedTranscript = null;
                this.savingTranscript = false;
                // Disable navigation prompt
                window.onbeforeunload = null;
                if (this.transcriptUpload) {
                    this.transcriptUpload.cancelAsync.then(cancel => cancel());
                }
            }

            removeTranscriptFile() {
                if (this.selectedTranscript) this.selectedTranscript = null;
            }

            canDownloadVideo(video) {
                return video.vid && !video.processingVideo;
            }

            canDownloadTranscript(video) {
                return video.transcript && !video.processingTranscript;
            }

            hasTranscript(video) {
                return video.transcript;
            }

            downloadVideo(video) {
                if (!this.canDownloadVideo(video)) return;
                this.FileService.getFile(video.vid);
            }

            downloadTranscript(video) {
                if (!this.canDownloadTranscript(video)) return;
                this.FileService.getFile(video.transcript.vid);
            }

            sessionSelected(sessionOption) {
                if (!sessionOption || sessionOption === "Other") {
                    delete this.videoDetails.session;
                } else {
                    this.videoDetails.session = sessionOption;
                }
            }
        }
    );
})();
