(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparingRenamingFilePanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/renaming-file-panel/renaming-file-panel.component.html",
        controller: function() {
            let $ctrl = this;
        }
    });
})();
