(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardEditorPaUnitPricesPrint", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/publication-agreement/components/unit-prices/unit-prices-print.component.html",

        controller: [
            "dashPricingService",
            "dashUnitPricesService",
            function(dashPricingService, dashUnitPricesService) {
                let $ctrl = this;
                let prices;
                let printEntry = $ctrl.printEntry;

                $ctrl.$onInit = () => {
                    $ctrl.printEntry = $ctrl.resolve.entry;
                    printEntry = $ctrl.printEntry;
                    prices = $ctrl.resolve.quote.unitPrices;

                    $ctrl.covers = printEntry.media.bookCoverType.options;
                    $ctrl.trims = printEntry.media.bookTrimSize.options;
                    $ctrl.paperTypes = [
                        { name: "BW", value: "bw" },
                        { name: "Color", value: "color" }
                    ];

                    let totalPages = parseFloat(
                        printEntry.bookTotalPages.value
                    );
                    let colorPages = parseFloat(
                        printEntry.bookColorPages.value
                    );
                    $ctrl.bwPages = totalPages - colorPages;
                    $ctrl.colorPages = colorPages;
                    $ctrl.volumes = parseFloat(printEntry.bookVolumes.value);

                    $ctrl.coverString = printEntry.getOptionText(
                        "bookCoverType"
                    );
                    $ctrl.trimString = printEntry.getOptionText("bookTrimSize");

                    $ctrl.unitPrices = prices[printEntry.mid];
                };

                $ctrl.ok = function() {
                    $ctrl.close({ $value: printEntry });
                };

                $ctrl.cancel = function() {
                    $ctrl.dismiss({ $value: "Cancel" });
                };

                $ctrl.addRate = mid =>
                    dashUnitPricesService.print.addRate(prices, mid);
                $ctrl.removeRate = (mid, rateToRemove) => {
                    if (confirm("Delete this rate?") === true)
                        dashUnitPricesService.print.removeRate(
                            prices,
                            mid,
                            rateToRemove
                        );
                };
                $ctrl.addCoverRate = mid =>
                    dashUnitPricesService.print.addCoverRate(prices, mid);
                $ctrl.removeCoverRate = (mid, rateToRemove) => {
                    if (confirm("Delete this rate?") === true)
                        dashUnitPricesService.print.removeCoverRate(
                            prices,
                            mid,
                            rateToRemove
                        );
                };

                $ctrl.modelChanged = () => {
                    dashPricingService.calculatePrices($ctrl.resolve.quote);
                };

                $ctrl.getPaperTypeRate = (pages, type) =>
                    dashPricingService.print.getPaperTypeRate(
                        $ctrl.unitPrices.rates,
                        pages,
                        type,
                        printEntry.bookTrimSize.value
                    );
                $ctrl.getCoverCost = () =>
                    dashPricingService.print.getCoverCost(
                        printEntry,
                        $ctrl.unitPrices.coverRates
                    );
                $ctrl.getTotalAdjustment = () =>
                    dashPricingService.print.getTotalAdjustment(
                        printEntry,
                        $ctrl.unitPrices
                    );
                $ctrl.getUnitPrice = () =>
                    dashPricingService.print.getUnitPrice(
                        printEntry,
                        $ctrl.unitPrices
                    );
                $ctrl.changeUnitPriceOverrideEnable = () =>
                    dashPricingService.calculatePrices($ctrl.resolve.quote);
            }
        ]
    });
})();
