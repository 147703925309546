(function() {
    "use strict";

    angular.module("cpir").filter("proceedingEditorFilter", () => {
        return (proceedings, email) => {
            if (!proceedings || !email) return proceedings;

            if (email === "*") return proceedings;

            return proceedings.filter(p => {
                if (!p.editor) return false;
                return p.editor.email === email;
            });
        };
    });
})();
