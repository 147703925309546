(function() {
    "use strict";

    angular.module("cpir").component("cpirSectionHeader", {
        bindings: {
            name: "@",
            subName: "<",
            progressTitle: "@",
            progressTotal: "@",
            previousButtonVisible: "<",
            previousButtonEnabled: "<",
            previousButtonHook: "&",
            showPreviousButtonPopover: "<",
            previousButtonPopoverHtml: "<",
            nextButtonVisible: "<",
            nextButtonEnabled: "<",
            showNextButtonPopover: "<",
            nextButtonPopoverHtml: "<",
            nextButtonHook: "&",
            submitButtonVisible: "<",
            submitButtonEnabled: "<",
            showSubmitButtonPopover: "<",
            submitButtonPopoverHtml: "<",
            submitButtonHook: "&"
        },
        templateUrl:
            "application/cpir/_shared/components/section-header/section-header.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            previousButtonClicked() {
                this.previousButtonHook();
            }

            nextButtonClicked() {
                this.nextButtonHook();
            }

            submitButtonClicked() {
                this.submitButtonHook();
            }
        }
    });
})();
