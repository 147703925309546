(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardOrganizerTextSection", {
        bindings: {
            sectionTitle: "<",
            content: "<",
            data: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/organizer/quote/components/text-section/quote-text-section.component.html",
        controller: class {}
    });
})();
