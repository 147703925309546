(function() {
    "use strict";

    angular.module("cpir").component("cpirCifInquiryConfPanel", {
        bindings: {
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/inquiry-configuration/components/conference-panel/components/inquiry-conference-conf-panel/inquiry-conference-conf-panel.component.html",
        controller: class {
            constructor(
                $state,
                $stateParams,
                NotificationService,
                ProceedingService
            ) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.ProceedingService = ProceedingService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.statusForm = {};

                // Get the proceeding record
                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;
                });

                this.lastUpdated = new Date();

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            saveProceeding(proceeding) {
                this.onSave({
                    pid: this.pid,
                    proceeding: this.proceeding
                });
            }
        }
    });
})();
