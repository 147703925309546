(function() {
    "use strict";

    angular.module("cpir").provider(
        "GlobalDefaultsService",
        class {
            constructor() {}
            $get($q) {
                return {
                    getSettings: () => $q.when({ editors })
                };
            }
        }
    );

    let editors = [
        {
            name: "Patrick Kellenberger",
            email: "pkellenberger@computer.org",
            phone: "+1 714 821 8380 Ext. 2104",
            fax: "+1 714 761 1784"
        },
        {
            name: `Lisa O'Connor`,
            email: "loconner@computer.org",
            phone: "+1 714 821 8380 Ext. 2104",
            fax: "+1 714 761 1784"
        }
    ];
})();
