(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardAdditionalInformation", {
        bindings: {
            onUpdate: "&",
            paperTypes: "<",
            trim: "<"
        },
        templateUrl:
            "application/cpir/dashboard/_shared/components/additional-information-modal/components/additional-information/additional-information.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            updatePaperTypes(paperTypes, trim) {
                this.paperTypes = paperTypes;
                this.trim = trim;
                this.modelChanged();
            }

            modelChanged() {
                this.onUpdate({
                    paperTypes: this.paperTypes,
                    trim: this.trim
                });
            }
        }
    });
})();
