(function() {
    "use strict";

    const FILE_CHUNK_SIZE = 1024 * 256; // 64 KB
    const FILE_CHUNK_CONCURRENCY_LIMIT = 64; // Number of chunks per file to send at the same time
    const CHUNK_CONCURRENCY_MIN_CUTOFF = 1600;
    const CHUNK_CONCURRENCY_MAX_CUTOFF = 6400;

    angular.module("cpir").provider(
        "SocketStreamService",
        class {
            constructor() {}
            $get(UuidService, moment) {
                class SocketStream {
                    constructor(client) {
                        console.log("Setting up file upload stream");
                        this.client = client;
                        this.fileChunkConcurrency = 1;

                        this._increaseFileChunkConcurrency = _.throttle(() => {
                            console.log(
                                `Send chunk time < ${CHUNK_CONCURRENCY_MIN_CUTOFF} ms. Increasing the fileChunkConcurrency to ${this.fileChunkConcurrency}`
                            );
                            this.fileChunkConcurrency++;
                        }, 200);
                        this._decreaseFileChunkConcurrency = _.throttle(() => {
                            console.log(
                                `Send chunk time > ${CHUNK_CONCURRENCY_MAX_CUTOFF} ms. Decreasing the fileChunkConcurrency to ${this.fileChunkConcurrency}`
                            );
                            this.fileChunkConcurrency--;
                        }, 200);
                    }

                    /**
                     * Sends a file stream to the server
                     * @param {string} message to identify the stream
                     * @param {File} file
                     * @param [extraData] optional extra data to send to the server side handler
                     * @param [onProgress] callback when file progress is updated
                     * @param [onSocketChange] callback if the websocket disconnects
                     * @param [getSocketAsync] function to get a new websocket
                     * @param [callback] optional callback for the message
                     */
                    emit(
                        message,
                        file,
                        extraData,
                        onProgress,
                        onSocketChange,
                        getSocketAsync,
                        callback
                    ) {
                        return new Promise((resolve, reject) => {
                            const doEmitAsync = this._sendFileMetadata({
                                file,
                                data: extraData,
                                onProgress
                            })
                                .then(state =>
                                    this._handleFileUpload(file, state)
                                )
                                .then(state =>
                                    this._finishUpload(
                                        state,
                                        message,
                                        extraData,
                                        callback
                                    )
                                );
                            // handle any disconnects through recursion
                            this.client.on("disconnect", async () => {
                                // don't do anything if the upload was closed
                                if (this.closed) {
                                    resolve();
                                    return;
                                }

                                // replace the client
                                this.client = await getSocketAsync();

                                onSocketChange(this.client);
                                // call the function again with the same arguments
                                resolve(
                                    this.emit(
                                        message,
                                        file,
                                        extraData,
                                        onProgress,
                                        onSocketChange,
                                        getSocketAsync,
                                        callback
                                    )
                                );
                            });
                            doEmitAsync
                                .then(state => resolve(state))
                                .catch(err => reject(err));
                        });
                    }

                    /**
                     * Helper function to cancel an upload
                     */
                    cancel() {
                        this.closed = true;
                        this.client.disconnect();
                    }

                    /**
                     * Helper function: Send files metadata to over the websocket
                     * @param {File} file
                     * @param [data] extra data to lookup the file
                     * @param {Function} [onProgress]
                     * @returns {(function(*): (Promise<never>))|*}
                     */
                    _sendFileMetadata({ file, data, onProgress }) {
                        console.log("sending file metadata for stream", {
                            file
                        });

                        /*
                          Get a unique UUID to track this stream's data
                          This allows concurrent messages through the same socket to be routed to the correct stream
                          wait for UUID to be resolved
                        */
                        return UuidService.getUuid().then(uuid => {
                            /*
                            Send the file's metadata and get a UUID for sending this files chunks to the server
                            */
                            return new Promise(resolve => {
                                /*
                                Transmit the file metadata over the socket.
                                */
                                this.client.emit(
                                    "socket.stream.file",
                                    {
                                        uuid,
                                        name: file.name,
                                        size: file.size,
                                        type: _.isEmpty(file.type)
                                            ? file.alternateType
                                            : file.type,
                                        data
                                    },
                                    ({ uuid, previouslyWrittenBytes }) => {
                                        /*
                                        Handle the ready signal
                                        The server may respond back with a different uuid & the number of previously written bytes if it found a previously upload which could be resumed.
                                        */

                                        console.log(
                                            "File upload stream is ready"
                                        );

                                        // initialize the upload state
                                        const state = {
                                            uuid: uuid,
                                            currentBytes: 0,
                                            uploadedBytes: 0,
                                            previouslyUploadedBytes:
                                                previouslyWrittenBytes || 0,
                                            remainingBytes: file.size,
                                            concurrencyQueue: [],
                                            uploadProgress: 0,
                                            uploadSpeed: 0,
                                            crc32: 0,
                                            progress: {
                                                update: _.throttle(() => {
                                                    if (
                                                        _.isFunction(onProgress)
                                                    ) {
                                                        onProgress(
                                                            state.currentBytes
                                                        );
                                                    }
                                                }, 200)
                                            }
                                        };
                                        resolve(state);
                                    }
                                );
                            });
                        });
                    }

                    /**
                     * Helper function. Gets a chunk of a file given a byte index.
                     * @param {File} file
                     * @param {number} byteIdx
                     * @return {Blob}
                     */
                    _getChunk(file, byteIdx) {
                        const chunk = file.slice(
                            byteIdx,
                            byteIdx + FILE_CHUNK_SIZE
                        );
                        // console.log(
                        //     `Got file chunk size ${chunk.size} at index ${byteIdx}`
                        // );
                        return chunk;
                    }

                    _adjustFileChunkConcurrency(sendMoment) {
                        if (
                            moment().isBefore(
                                sendMoment.add(
                                    CHUNK_CONCURRENCY_MIN_CUTOFF,
                                    "ms"
                                )
                            ) &&
                            this.fileChunkConcurrency <
                                FILE_CHUNK_CONCURRENCY_LIMIT
                        ) {
                            this._increaseFileChunkConcurrency();
                        }
                        if (
                            moment().isAfter(
                                sendMoment.add(
                                    CHUNK_CONCURRENCY_MAX_CUTOFF,
                                    "ms"
                                )
                            ) &&
                            this.fileChunkConcurrency > 1
                        ) {
                            this._decreaseFileChunkConcurrency();
                        }
                    }

                    /**
                     * Handles submitting chunks for the websocket. Will first check if the previously written bytes
                     * counter to see if chunk should be skipped or partially skipped. If not yet written will send
                     * the chunk to the websocket.
                     * @param {Uint8Array} chunk
                     * @param {number} chunkIdx the index of the chunk to write
                     * @param state The file upload state
                     * @return {Promise<number>} The number of bytes processed
                     */
                    _handleChunk(chunk, chunkIdx, state) {
                        // create a variable for the chunk. We may not end up writing all of it's data if the upload is being resumed
                        let chunkToWrite = chunk;

                        // load data from the write stream's state
                        const { previouslyUploadedBytes, currentBytes } = state;

                        /*
                        Check if any data was previously written. If so attempt to resume the upload
                         */
                        if (currentBytes < previouslyUploadedBytes) {
                            /*
                            Determine if the current chunk size + the current bytes is less than or equal to the previously uploaded Bytes.
                            If so we can safely skip this chunk.
                             */
                            if (
                                currentBytes + chunk.length <=
                                previouslyUploadedBytes
                            ) {
                                /*
                                Skip this chunk and update the state's currentBytes, uploadedBytes & remainingBytes counter
                                 */
                                state.currentBytes += chunk.length;
                                state.uploadedBytes += chunk.length;
                                state.remainingBytes -= chunk.length;
                                // console.log(
                                //     `Skipping current chunk since it was already sent. Total processed: ${state.currentBytes}. Uploaded bytes: ${state.uploadedBytes}. Remaining: ${state.remainingBytes}`
                                // );
                                return Promise.resolve(state.remainingBytes);
                            } else {
                                throw new Error("Partial chunk exception");
                            }
                        }

                        // update the currentBytes counter
                        state.currentBytes += chunkToWrite.length;

                        /**
                         * Send the chunk over the websocket. Once the chunk is sent remove this chunk
                         * from the concurrency queue.
                         */
                        const sendChunkAsync = this._sendChunk(state, {
                            chunk: chunkToWrite,
                            chunkIdx
                        }).then(() =>
                            _.remove(
                                state.concurrencyQueue,
                                ({ chunkIdx: idxToRemove }) =>
                                    idxToRemove === chunkIdx
                            )
                        );

                        /*
                        Add this chunk to the concurrency queue.
                         */
                        state.concurrencyQueue.push({
                            chunkIdx,
                            sendChunkAsync
                        });

                        /*
                        Return the currentBytes processed. We will not wait for the chunk to send. It is handled by
                        the concurrency queue.
                         */
                        return state;
                    }

                    /**
                     * Helper for transmitting chunks over the websocket
                     * @param state
                     * @param {null} [chunkData]
                     * @param {Uint8Array} chunkData.chunk
                     * @param {number} chunkData.chunkIdx
                     * @param {boolean} [drain]
                     * @returns {Promise}
                     */
                    _sendChunk(state, chunkData, drain) {
                        return new Promise((resolve, reject) => {
                            // update the chunk queue
                            if (chunkData) {
                                state.chunksToSend = (
                                    state.chunksToSend || []
                                ).concat({ ...chunkData, resolve });
                            }

                            const { uuid, chunksToSend } = state;

                            // drain the chunks if the queue is full or the drain flag is set
                            if (
                                drain ||
                                chunksToSend.length >= this.fileChunkConcurrency
                            ) {
                                const chunksToSendSize = _.sumBy(
                                    chunksToSend,
                                    "chunk.length"
                                );
                                const chunksToSendIdx = chunksToSend
                                    .map(c => c.chunkIdx)
                                    .join(", ");

                                // console.log(
                                //     `Uploading chunk(s) #${chunksToSendIdx} size: `,
                                //     chunksToSendSize
                                // );
                                state.chunksToSend = [];

                                /*
                                Transmit the chunk over the socket
                                 */
                                const sendTime = moment();
                                this.client.emit(
                                    "socket.stream.chunk",
                                    {
                                        uuid,
                                        chunks: chunksToSend.map(c =>
                                            _.omit(c, "resolve")
                                        )
                                    },
                                    () => {
                                        this._adjustFileChunkConcurrency(
                                            sendTime
                                        );
                                        /*
                                        Confirm the receipt of the chunk, then resolve.
                                         */
                                        state.uploadedBytes += chunksToSendSize;
                                        state.remainingBytes -= chunksToSendSize;
                                        // console.log(
                                        //     `Uploaded chunk(s) #${chunksToSendIdx}. Total processed: ${state.currentBytes}. Uploaded bytes: ${state.uploadedBytes}. Remaining: ${state.remainingBytes}`
                                        // );
                                        state.progress.update();
                                        chunksToSend.forEach(chunk =>
                                            chunk.resolve(state)
                                        );
                                    }
                                );

                                // if we're draining the chunk queue then resolve
                                if (drain) {
                                    resolve();
                                }
                            }
                        });
                    }

                    /**
                     * Helper function: Coordinates sending file chunks over the websocket connection
                     * @param {File} file
                     * @param state
                     * @return {Promise<unknown[]>}
                     */
                    _handleFileUpload(file, state) {
                        // don't do anything if the upload was closed
                        if (this.closed) {
                            return Promise.resolve(state);
                        }
                        /*
                        Transmit the file chunks
                         */
                        return Promise.mapSeries(
                            // iterate over chunks in the file
                            _.range(0, file.size, FILE_CHUNK_SIZE),
                            (byteIdx, chunkIdx) => {
                                // get the next chunk
                                const chunk = this._getChunk(file, byteIdx);

                                return (
                                    chunk
                                        .arrayBuffer() // get the file chunk as a raw binary arrayBuffer
                                        /*
                                        Update the integrity CRC32 value
                                        CRC32 is a fast algorithm for computing checksums to verify data integrity
                                        This will be used as an end-to-end data integrity check
                                         */
                                        .then(chunkData => {
                                            const chunkBinary = new Uint8Array(
                                                chunkData
                                            );
                                            state.crc32 = CRC32.buf(
                                                chunkBinary,
                                                state.crc32
                                            );
                                            return {
                                                chunk: chunkBinary, // we'll keep the binary
                                                chunkIdx
                                            };
                                        })
                                        .then(({ chunk, chunkIdx }) => {
                                            /*
                                            If we are under the concurrency limit send a new chunk over the websocket
                                             */
                                            if (
                                                state.concurrencyQueue.length <
                                                this.fileChunkConcurrency
                                            ) {
                                                return this._handleChunk(
                                                    chunk,
                                                    chunkIdx,
                                                    state
                                                );
                                            } else {
                                                /*
                                                Otherwise wait for an existing chunk to complete
                                                 */
                                                return Promise.any(
                                                    state.concurrencyQueue.map(
                                                        ({ sendChunkAsync }) =>
                                                            sendChunkAsync
                                                    )
                                                ).then(() =>
                                                    this._handleChunk(
                                                        chunk,
                                                        chunkIdx,
                                                        state
                                                    )
                                                );
                                            }
                                        })
                                );
                            }
                        )
                            .then(() =>
                                /*
                                Wait for all writes to finish
                                 */
                                this._sendChunk(state, null, true).then(() =>
                                    Promise.all(
                                        state.concurrencyQueue.map(
                                            ({ sendChunkAsync }) =>
                                                sendChunkAsync
                                        )
                                    )
                                )
                            )
                            .then(() => state);
                    }

                    /**
                     * Helper function: Sends the stream complete message over the websocket
                     * Hands control back to the application by sending the original message and extra data
                     * @param state
                     * @param {string} message The application level message to send after the file transfer is complete
                     * @param [data] extra data associated with the application message
                     * @param [callback] optional callback expected for the application message
                     * @return {Promise<any>}
                     */
                    _finishUpload(state, message, data, callback) {
                        // don't do anything if the upload was closed
                        if (this.closed) {
                            return Promise.resolve(state);
                        }

                        return new Promise(resolve => {
                            this.client.emit(
                                "socket.stream.complete",
                                {
                                    uuid: state.uuid,
                                    integrity: state.crc32
                                },
                                () => {
                                    // don't do anything if the upload was closed
                                    if (this.closed) {
                                        resolve(state);
                                        return;
                                    }
                                    // at this point control is handled outside of this class
                                    if (_.isFunction(callback)) {
                                        this.client.emit(
                                            message,
                                            state.uuid,
                                            data,
                                            response => {
                                                this.client.off("disconnect");
                                                callback(response);
                                                resolve(state);
                                            }
                                        );
                                    } else {
                                        this.client.emit(
                                            message,
                                            state.uuid,
                                            data
                                        );
                                        this.client.off("disconnect");
                                        resolve(state);
                                    }
                                }
                            );
                        });
                    }
                }

                return {
                    getSocketStream: client => new SocketStream(client)
                };
            }
        }
    );
})();
