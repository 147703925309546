(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceContactPanel", {
        bindings: {
            contact: "<",
            isErrorsVisible: "<",
            isLastContact: "<",
            isPrimaryInContacts: "<",
            chairValidation: "<",
            isDisabled: "<",
            onRemove: "&",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/contacts-panel/components/contact-panel/contact-panel.component.html",
        controller: class {
            constructor(InternationalDataService) {
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.countryOptions = this.InternationalDataService.getCountries();
                this.provinceOptions = this.InternationalDataService.getProvinces();
                this.availableRoles = [
                    { name: "General Chair", value: "GC" },
                    { name: "Publication Chair", value: "BC" },
                    { name: "Finance Chair", value: "FC" },
                    { name: "Program Chair", value: "PC" },
                    { name: "Other", value: "OT" }
                ];
            }

            $onChanges(changes) {
                if (changes.contact && changes.contact.currentValue) {
                    this.modelChanged();
                }
            }

            modelChanged() {
                this.onUpdate({ contact: this.contact });
            }

            updateProvince(province) {
                this.contact.address.province = province;
                this.modelChanged();
            }

            removeButtonClicked() {
                this.onRemove();
            }

            // Enforce only one primary is allowed by disabling the
            // box in the case that a *different* signatory is already
            // present.
            isPrimaryDisabled() {
                return this.isPrimaryInContacts && !this.contact.isPrimary;
            }
        }
    });
})();
