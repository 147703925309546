(function() {
    "use strict";

    angular.module("cpir").component("cpirInquiryConferenceConfPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/inquiry-configuration/components/conference-panel/cif-conf-settings-panel.component.html",
        controller: class {
            constructor($stateParams, NotificationService) {
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.showConfiguration("inquiry.conference");
            }

            onCategorySelect(category) {
                switch (category) {
                    case "conference":
                        this.showConfiguration("inquiry.conference");
                        break;
                }
            }

            showConfiguration(status) {
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }

            saveProceeding(pid, proceeding) {
                this.ProceedingService.update(pid, proceeding).then(() => {
                    this.NotificationService.send(
                        "success",
                        "The configuration was saved successfully."
                    );
                });
            }
        }
    });
})();
