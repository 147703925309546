(function() {
    "use strict";

    angular.module("cpir").service("TOCValuesService", TOCValuesService);

    function TOCValuesService() {
        let recordTemplate = {
            productionStatus: "not_received",
            class: "SB",
            type: "AP",
            authors: [],
            copyrightType: null,
            pdfExpress: false
        };

        let sectionDividerTemplate = {
            class: "SD",
            type: "SD_SESSION"
        };

        let volumeDividerTemplate = {
            class: "VD",
            type: "VD"
        };

        let getFrontMatterTemplate = type => {
            return {
                class: "FM",
                type: type
            };
        };

        let getBackMatterTemplate = type => {
            return {
                class: "BM",
                type: type
            };
        };

        let authorkitTemplate = {
            class: "SB",
            type: "AP",
            articleId: Date.now(),
            copyrightType: null
        };

        let productionStatusOptions = [
            { name: "Submitted", value: "submitted" },
            { name: "Incomplete", value: "incomplete" },
            { name: "Passed QA", value: "passed_qa" },
            { name: "Failed QA", value: "failed_qa" },
            { name: "Under Review", value: "under_review" },
            { name: "Stamped", value: "stamped" },
            { name: "Not Received", value: "not_received" },
            { name: "Received", value: "received" },
            { name: "Author Review", value: "author-review" },
            { name: "Incomplete AK Reopened", value: "incomplete_ak_reopened" },
            { name: "Resubmitted", value: "resubmitted" },
            { name: "Approved", value: "approved-by-author" },
            { name: "Change Request", value: "change-request" },
            { name: "Complete", value: "complete" },
            { name: undefined, value: "Undefined" },
            { name: null, value: "Undefined" }
        ];

        let copyrightTypes = [
            { name: "", value: null },
            { name: "US", value: "US" },
            { name: "USG", value: "USG" },
            { name: "CRWN", value: "CRWN" },
            { name: "IEEE", value: "IEEE" }
        ];

        let pdfExpressOptions = [
            { name: "Yes", value: true },
            { name: "No", value: false }
        ];

        let classOptions = [
            { name: "Submissions", value: "SB" },
            { name: "Front Matter", value: "FM" },
            { name: "Back Matter", value: "BM" },
            { name: "Session Divider", value: "SD" },
            { name: "Volume Divider", value: "VD" }
        ];

        let typeOptions = [
            { class: "SB", name: "Author Paper", value: "AP" },
            { class: "SD", name: "Divider - Session", value: "SD_SESSION" },
            {
                class: "SD",
                name: "Divider - SubSession",
                value: "SD_SUBSESSION"
            },
            { class: "SD", name: "Divider - Track", value: "SD_TRACK" },
            { class: "VD", name: "Divider - Volume", value: "VD" },
            { class: "BM", name: "Author Index", value: "BM_AUTHOR_INDEX" },
            {
                class: "BM",
                name: "Call for Papers or Participation",
                value: "BM_CALL_FOR_PAPERS"
            },
            {
                class: "BM",
                name: "Color Image Appendix",
                value: "BM_IMAGE_APPENDIX"
            },
            { class: "BM", name: "Note Page(s)", value: "BM_NOTES" },
            { class: "BM", name: "Roster Page", value: "BM_ROSTER" },
            { class: "FM", name: "Copyright Page", value: "FM_COPYRIGHT_PAGE" },
            { class: "FM", name: "Memoriam(s)", value: "FM_MEMORIAMS" },
            { class: "FM", name: "Sponsorship Logo", value: "FM_SPONS_LOGOS" },
            {
                class: "FM",
                name: "Table of Contents",
                value: "FM_TABLE_OF_CONTENTS"
            },
            { class: "FM", name: "Title Page i", value: "FM_TITLE_PAGE_I" },
            { class: "FM", name: "Title Page iii", value: "FM_TITLE_PAGE_III" },
            {
                class: "FM",
                name: "Text - Acknowledgements",
                value: "FM_TEXT_ACKNOWLEDGEMENTS"
            },
            {
                class: "FM",
                name: "Text and Logos - Cover Art",
                value: "FM_TEXT_LOGOS_COVER ART"
            },
            {
                class: "FM",
                name: "Message Text - Best Paper Awards",
                value: "FM_MESSAGE_BEST_PAPER_AWARDS"
            },
            {
                class: "FM",
                name: "Message Text - Call for Papers/Participation",
                value: "FM_MESSAGE_CALL_FOR_PAPERS_PARTICIPATION"
            },
            {
                class: "FM",
                name: "Message Text - Honorariums(s)",
                value: "FM_MESSAGE_HONORARIUMS"
            },
            {
                class: "FM",
                name: "Message Text - Message from Symposium Chair(s)",
                value: "FM_MESSAGE_SYMPOSIUM_CHAIR"
            },
            {
                class: "FM",
                name: "Message Text - Message from General Chair(s)",
                value: "FM_MESSAGE_GENERAL_CHAIR"
            },
            {
                class: "FM",
                name: "Message Text - Message from Conference Chair(s)",
                value: "FM_MESSAGE_CONFERENCE_CHAIR"
            },
            {
                class: "FM",
                name: "Message Text - Message from Program Chair(s)",
                value: "FM_MESSAGE_PROGRAM_CHAIR"
            },
            {
                class: "FM",
                name: "Message Text - Message from Organizing Chair(s)",
                value: "FM_MESSAGE_ORGANIZING_CHAIR"
            },
            {
                class: "FM",
                name: "Message Text - Message from Workshop Chair(s)",
                value: "FM_MESSAGE_WORKSHOP_CHAIR"
            },
            {
                class: "FM",
                name: "List of Names - Committee Lists",
                value: "FM_LON_COMMITTEE_LISTS"
            },
            {
                class: "FM",
                name: "List of Names - Conference Officers",
                value: "FM_LON_CONFERENCE_OFFICERS"
            },
            {
                class: "FM",
                name: "List of Names - Organizing Committee",
                value: "FM_LON_ORGANIZING_COMMITTEE"
            },
            {
                class: "FM",
                name: "List of Names - Program Committee",
                value: "FM_LON_PROGRAM_COMMITTEE"
            },
            {
                class: "FM",
                name: "List of Names - Reviewers List",
                value: "FM_LON_REVIEWERS_LIST"
            },
            {
                class: "FM",
                name: "List of Names - Steering Committee",
                value: "FM_LON_STEERING_COMMITTEE"
            },
            {
                class: "FM",
                name: "List of Names - Sponsors/Conference Support",
                value: "FM_LON_SPONSORS_CONFERENCE_SUPPORT"
            },
            {
                class: "FM",
                name: "List of Names - Tutorial Committee",
                value: "FM_LON_TUTORIAL_COMMITTEE"
            },
            {
                class: "FM",
                name: "List of Names - Workshops Committee",
                value: "FM_LON_WORKSHOPS_COMMITTEE"
            },
            {
                class: "FM",
                name: "List of Names (with Photos) - Committee Photos",
                value: "FM_LONP_COMMITTEE_PHOTOS"
            }
        ];

        return {
            authorkitTemplate,
            classOptions,
            copyrightTypes,
            pdfExpressOptions,
            recordTemplate,
            productionStatusOptions,
            typeOptions,
            sectionDividerTemplate,
            volumeDividerTemplate,
            getFrontMatterTemplate,
            getBackMatterTemplate
        };
    }
})();
