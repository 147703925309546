(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkPaperDetailsController",
        class {
            constructor(
                pid,
                entryId,
                $state,
                $stateParams,
                NotificationService,
                ProceedingService,
                ProceedingStatusService,
                ProceedingRoleService,
                TocEntriesApiService,
                AKService,
                authService,
                WebSocketService,
                $scope
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.AKService = AKService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.authService = authService;
                this.WebSocketService = WebSocketService;
                this.$scope = $scope;
            }

            isUnset(field) {
                return (
                    _.isNil(field) || // null or undefined
                    (_.isString(field) && _.trim(field).length < 1) // empty string
                );
            }

            $onInit() {
                this.defaultTitleStub = "[ak started by author";
                this.submitterEmailReadOnly = true;
                this.AKService.setActive(this.pid, this.entryId);

                this.proceeding = this.AKService.getProceeding();

                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                        if (
                            this.ProceedingRoleService.isEditor(this.proceeding)
                        ) {
                            this.submitterEmailReadOnly = false;
                        }
                    }
                );

                this.TocEntriesApiService.getEntry(this.entryId).then(
                    async entry => {
                        this.entry = entry;

                        let needToUpdateEntry = false;

                        // set the SubmitterEmail if not already set
                        if (this.isUnset(this.entry.submitterEmail)) {
                            console.log("Auto-filling submitter email");
                            this.entry.submitterEmail = this.authService.getProfile().email;
                            needToUpdateEntry = true;
                        }

                        // apply updates if needed
                        if (needToUpdateEntry) {
                            await this.updateEntry()
                                .then(() => {
                                    console.log("Entry updated: ", this.entry);
                                })
                                .catch(err => {
                                    console.log("Error updating entry: ", err);
                                });
                        }

                        // check for the default title and clear it if it exists
                        if (
                            _.toLower(this.entry.title).includes(
                                this.defaultTitleStub
                            )
                        ) {
                            this.entry.title = null;
                            this.WebSocketService.safeApply(this.$scope);
                        }
                    }
                );

                this.paperDetailsForm = {};

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            updateEntry() {
                this.entry.submitterEmail =
                    this.entry.submitterEmail &&
                    this.entry.submitterEmail.toLowerCase().trim();
                return this.TocEntriesApiService.updateEntries(
                    this.entry
                ).then(() =>
                    this.TocEntriesApiService.broadcastEntries(this.pid)
                );
            }

            saveChanges() {
                this.updateEntry()
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The paper details were successfully saved."
                        );
                        this.AKService.goToNextStep();
                    })
                    .catch(e => console.log(e));
            }

            isStateValid() {
                return this.paperDetailsForm.$valid;
            }

            isLastStep() {
                return this.AKService.isLastStep();
            }

            goToPreviousStep() {
                this.AKService.goToPreviousStep();
            }

            canGoToNextStep() {
                return this.AKService.isApprovalMode() || this.isStateValid();
            }
        }
    );
})();
