(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardOrganizerQuoteAcceptQuote", {
        bindings: {
            proceeding: "<",
            pricingContent: "<",
            acceptInstructions: "<",
            redirectError: "<",
            onQuoteAccept: "&",
            onQuoteReject: "&",
            onQuoteRevise: "&"
        },
        templateUrl:
            "application/cpir/dashboard/organizer/quote/components/" +
            "accept-quote/accept-quote.component.html",
        controller: [
            "$scope",
            "$uibModal",
            "ProceedingStatusService",
            function($scope, $uibModal, ProceedingStatusService) {
                let $ctrl = this;

                // add lodash to the view
                $scope._ = _;

                let createProducts = () => {
                    $ctrl.products = [];
                    $ctrl.quotes.forEach(quote => {
                        let quoteProducts = [];
                        quote.products.forEach(product => {
                            product.items.forEach((x, idx) => {
                                quoteProducts.push({
                                    mid: product.mid,
                                    fields: product.itemsMap[idx]
                                });
                            });
                        });
                        let product = {
                            odd: quoteProducts.filter(
                                (x, idx) => idx % 2 === 1
                            ),
                            even: quoteProducts.filter(
                                (x, idx) => idx % 2 === 0
                            )
                        };
                        product[
                            product.odd < product.even ? "odd" : "even"
                        ].push({
                            mid: "SUBMITTED_PAPERS",
                            qty: quote.estSubmittedPapers
                        });
                        product[
                            product.odd < product.even ? "odd" : "even"
                        ].push({
                            mid: "ACCEPTED_PAPERS",
                            qty: quote.estAcceptedPapers
                        });
                        $ctrl.products.push(product);
                    });
                    let mapDescription = item => {
                        if ($ctrl.isBook(item)) {
                            return $ctrl.getBookDescription(item);
                        } else if ($ctrl.isDigital(item)) {
                            return $ctrl.getDigitalDescription(item);
                        } else if ($ctrl.isPapers(item)) {
                            return $ctrl.getPaperDescription(item);
                        } else if ($ctrl.isCustom(item)) {
                            return $ctrl.getCustomDescription(item);
                        }
                        return `NO DESCRIPTION FOR ${item.mid}`;
                    };
                    $ctrl.products = $ctrl.products.map(p => {
                        return {
                            odd: p.odd.map(mapDescription),
                            even: p.even.map(mapDescription)
                        };
                    });
                };

                $ctrl.$onInit = () => {
                    if (
                        ProceedingStatusService.isAtLeastAccepted(
                            $ctrl.proceeding
                        )
                    ) {
                        let content = angular.copy($ctrl.pricingContent.quote);
                        content.cart = content.cart.filter(
                            quote => quote.accepted
                        );
                        $ctrl.quotes = content.cart;
                        $ctrl.accepted = true;
                    } else {
                        $ctrl.quotes = $ctrl.pricingContent.quote.cart;
                    }
                    createProducts();
                };

                $ctrl.isBook = media =>
                    media.mid === "BOOK" ||
                    media.mid === "ABSTRACT_BOOK" ||
                    media.mid === "PRINT_PROGRAM";
                $ctrl.getBookDescription = media => {
                    let fields = media.fields;
                    return `${fields.qty.value} ${fields.label}${
                        fields.qty.value > 1 ? "s" : ""
                    }, ${fields.getOptionText("bookCoverType")}, \
                     ${fields.getOptionText("bookTrimSize")}, ${
                        fields.bookTotalPages.value
                    } page${fields.qty.value > 1 ? "s" : ""}\
                     ${
                         fields.bookColorPages.value > 0
                             ? ", " + fields.bookColorPages.value + " color"
                             : ""
                     }`;
                };
                $ctrl.isDigital = media =>
                    media.mid === "CDROM" ||
                    media.mid === "DVD" ||
                    media.mid === "USB_DRIVE" ||
                    media.mid === "WEB_PUB" ||
                    media.mid === "EDITORIAL_ONLY" ||
                    media.mid === "MOBILE_APP";
                $ctrl.getDigitalDescription = media => {
                    let fields = media.fields;
                    return `${(fields.qty && fields.qty.value) || "1"} ${
                        fields.label
                    }${fields.qty && fields.qty.value > 1 ? "s" : ""}\
                    ${
                        media.mid === "CDROM" || media.mid === "DVD"
                            ? ", " + fields.getOptionText("diskCoverType")
                            : ""
                    }`;
                };

                $ctrl.isCustom = media =>
                    media.mid === "CUSTOM";
                $ctrl.getCustomDescription = media => {
                    let fields = media.fields;

                    return `${
                        fields.description.value
                    }${fields.qty && fields.qty.value > 1 ? "s" : ""}\
                    ${
                        media.mid === "CDROM" || media.mid === "DVD"
                            ? ", " + fields.getOptionText("diskCoverType")
                            : ""
                    }`;
                };
                $ctrl.isPapers = media =>
                    media.mid === "SUBMITTED_PAPERS" ||
                    media.mid === "ACCEPTED_PAPERS";
                $ctrl.getPaperDescription = media => {
                    return `${media.qty} ${
                        media.mid === "ACCEPTED_PAPERS"
                            ? "Accepted"
                            : "Submitted"
                    } Paper${media.qty > 1 ? "s" : ""}`;
                };

                $ctrl.rejectAll = () => $ctrl.confirmAction("reject");
                $ctrl.reviseQuote = (quote, product, index) =>
                    $ctrl.confirmAction("revise", quote, product, index);
                $ctrl.acceptQuote = (quote, product, index) =>
                    $ctrl.confirmAction("accept", quote, product, index);

                $ctrl.confirmAction = (type, quote, product, index) => {
                    let actionMap = {
                        accept: $ctrl.onQuoteAccept,
                        revise: $ctrl.onQuoteRevise,
                        reject: $ctrl.onQuoteReject
                    };
                    let action = { name: type };
                    action.action = actionMap[action.name];

                    const {
                        redirectError,
                        isBook,
                        getBookDescription,
                        isDigital,
                        getDigitalDescription,
                        isPapers,
                        getPaperDescription,
                        isCustom,
                        getCustomDescription
                    } = $ctrl;

                    $uibModal
                        .open({
                            size: "lg",
                            animation: true,
                            component: "cpirDashboardOrganizerConfirmQuote",
                            resolve: {
                                product,
                                quote,
                                action,
                                index,
                                redirectError,
                                productObj: {
                                    product,
                                    isBook,
                                    getBookDescription,
                                    isDigital,
                                    getDigitalDescription,
                                    isPapers,
                                    getPaperDescription,
                                    isCustom,
                                    getCustomDescription
                                }
                            }
                        })
                        .result.catch(dismiss => {});
                };
            }
        ]
    });
})();
