(function() {
    "use strict";

    angular.module("cpir").component("cpirHtmlEditor", {
        bindings: {
            content: "=",
            onChange: "&"
        },
        templateUrl:
            "application/cpir/_shared/components/html-editor/html-editor.component.html",
        controller: class {
            constructor($scope, DefaultSettingsService) {
                this.$scope = $scope;
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {
                this.tinymceOptions = {
                    ...this.DefaultSettingsService.tinymceOptions,

                    // register the change detection callback
                    setup: editor => {
                        editor.on("change", () => {
                            this.onChange({ content: editor.getContent() });
                        });
                    }
                };
            }
        }
    });
})();
