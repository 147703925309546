(function() {
    angular
        .module("template.directives", [])

        /**=========================================================
         * Module: sparkline.js
         * SparkLines Mini Charts
         =========================================================*/

        .directive("sparkline", [
            "$timeout",
            "$window",
            function($timeout, $window) {
                "use strict";

                return {
                    restrict: "EA",
                    controller: [
                        "$scope",
                        "$element",
                        function($scope, $element) {
                            var runSL = function() {
                                initSparLine($element);
                            };

                            $timeout(runSL);
                        }
                    ]
                };

                function initSparLine($element) {
                    var options = $element.data();

                    options.type = options.type || "bar"; // default chart is bar
                    options.disableHiddenCheck = true;

                    $element.sparkline("html", options);

                    if (options.resize) {
                        $(window).resize(function() {
                            $element.sparkline("html", options);
                        });
                    }
                }
            }
        ])

        /**=========================================================
        * Module: navbar-search.js
        * Navbar search toggler * Auto dismiss on ESC key
        =========================================================*/

        .directive("searchOpen", [
            "navSearch",
            function(navSearch) {
                "use strict";

                return {
                    restrict: "A",
                    controller: [
                        "$scope",
                        "$element",
                        function($scope, $element) {
                            $element
                                .on("click", function(e) {
                                    e.stopPropagation();
                                })
                                .on("click", navSearch.toggle);
                        }
                    ]
                };
            }
        ])

        .directive("searchDismiss", [
            "navSearch",
            function(navSearch) {
                "use strict";

                var inputSelector = '.navbar-form input[type="text"]';

                return {
                    restrict: "A",
                    controller: [
                        "$scope",
                        "$element",
                        function($scope, $element) {
                            $(inputSelector)
                                .on("click", function(e) {
                                    e.stopPropagation();
                                })
                                .on("keyup", function(e) {
                                    if (e.keyCode == 27)
                                        // ESC
                                        navSearch.dismiss();
                                });

                            // click anywhere closes the search
                            $(document).on("click", navSearch.dismiss);
                            // dismissable options
                            $element
                                .on("click", function(e) {
                                    e.stopPropagation();
                                })
                                .on("click", navSearch.dismiss);
                        }
                    ]
                };
            }
        ])

        /**=========================================================
        * Module: sidebar.js
        * Wraps the sidebar and handles collapsed state
        =========================================================*/

        .directive("sidebar", [
            "$rootScope",
            "$window",
            "Utils",
            function($rootScope, $window, Utils) {
                var $win = $($window);
                var $body = $("body");
                var $scope;
                var $sidebar;
                var currentState = $rootScope.$state.current.name;

                return {
                    restrict: "EA",
                    template: '<nav class="sidebar" ng-transclude></nav>',
                    transclude: true,
                    replace: true,
                    link: function(scope, element, attrs) {
                        $scope = scope;
                        $sidebar = element;

                        var eventName = Utils.isTouch()
                            ? "click"
                            : "mouseenter";
                        var subNav = $();
                        $sidebar.on(eventName, ".nav > li", function() {
                            if (
                                Utils.isSidebarCollapsed() ||
                                $rootScope.app.layout.asideHover
                            ) {
                                subNav.trigger("mouseleave");
                                subNav = toggleMenuItem($(this));

                                // Used to detect click and touch events outside the sidebar
                                sidebarAddBackdrop();
                            }
                        });

                        scope.$on("closeSidebarMenu", function() {
                            removeFloatingNav();
                        });

                        // Normalize state when resize to mobile
                        $win.on("resize", function() {
                            if (!Utils.isMobile())
                                $body.removeClass("aside-toggled");
                        });

                        // Adjustment on route changes
                        $rootScope.$on("$stateChangeStart", function(
                            event,
                            toState,
                            toParams,
                            fromState,
                            fromParams
                        ) {
                            currentState = toState.name;
                            // Hide sidebar automatically on mobile
                            $("body.aside-toggled").removeClass(
                                "aside-toggled"
                            );

                            $rootScope.$broadcast("closeSidebarMenu");
                        });

                        // Allows to close
                        if (angular.isDefined(attrs.sidebarAnyclickClose)) {
                            $(".wrapper").on("click.sidebar", function(e) {
                                // don't check if sidebar not visible
                                if (!$body.hasClass("aside-toggled")) return;

                                // if not child of sidebar
                                if (!$(e.target).parents(".aside").length) {
                                    $body.removeClass("aside-toggled");
                                }
                            });
                        }
                    }
                };

                function sidebarAddBackdrop() {
                    var $backdrop = $("<div/>", { class: "dropdown-backdrop" });
                    $backdrop
                        .insertAfter(".aside-inner")
                        .on("click mouseenter", function() {
                            removeFloatingNav();
                        });
                }

                // Open the collapse sidebar submenu items when on touch devices
                // - desktop only opens on hover
                function toggleTouchItem($element) {
                    $element
                        .siblings("li")
                        .removeClass("open")
                        .end()
                        .toggleClass("open");
                }

                // Handles hover to open items under collapsed menu
                // -----------------------------------
                function toggleMenuItem($listItem) {
                    removeFloatingNav();

                    var ul = $listItem.children("ul");

                    if (!ul.length) return $();
                    if ($listItem.hasClass("open")) {
                        toggleTouchItem($listItem);
                        return $();
                    }

                    var $aside = $(".aside");
                    var $asideInner = $(".aside-inner"); // for top offset calculation
                    // float aside uses extra padding on aside
                    var mar =
                        parseInt($asideInner.css("padding-top"), 0) +
                        parseInt($aside.css("padding-top"), 0);
                    var subNav = ul.clone().appendTo($aside);

                    toggleTouchItem($listItem);

                    var itemTop =
                        $listItem.position().top + mar - $sidebar.scrollTop();
                    var vwHeight = $win.height();

                    subNav.addClass("nav-floating").css({
                        position: $scope.app.layout.isFixed
                            ? "fixed"
                            : "absolute",
                        top: itemTop,
                        bottom:
                            subNav.outerHeight(true) + itemTop > vwHeight
                                ? 0
                                : "auto"
                    });

                    subNav.on("mouseleave", function() {
                        toggleTouchItem($listItem);
                        subNav.remove();
                    });

                    return subNav;
                }

                function removeFloatingNav() {
                    $(".dropdown-backdrop").remove();
                    $(".sidebar-subnav.nav-floating").remove();
                    $(".sidebar li.open").removeClass("open");
                }
            }
        ])

        /**=========================================================
        * Module: toggle-state.js
        * Toggle a classname from the BODY Useful to change a state that
        * affects globally the entire layout or more than one item
        * Targeted elements must have [toggle-state="CLASS-NAME-TO-TOGGLE"]
        * User no-persist to avoid saving the sate in browser storage
        =========================================================*/

        .directive("toggleState", [
            "toggleStateService",
            function(toggle) {
                "use strict";

                return {
                    restrict: "A",
                    link: function(scope, element, attrs) {
                        var $body = $("body");

                        $(element).on("click", function(e) {
                            e.preventDefault();
                            var classname = attrs.toggleState;

                            if (classname) {
                                if ($body.hasClass(classname)) {
                                    $body.removeClass(classname);
                                    if (!attrs.noPersist)
                                        toggle.removeState(classname);
                                } else {
                                    $body.addClass(classname);
                                    if (!attrs.noPersist)
                                        toggle.addState(classname);
                                }
                            }
                        });
                    }
                };
            }
        ])

        .directive("panelRefresh", [
            "$q",
            function($q) {
                "use strict";

                return {
                    restrict: "A",
                    scope: false,
                    controller: [
                        "$scope",
                        "$element",
                        function($scope, $element) {
                            var refreshEvent = "panel-refresh",
                                whirlClass = "whirl",
                                defaultSpinner = "standard";

                            // catch clicks to toggle panel refresh
                            $element.on("click", function() {
                                var $this = $(this),
                                    panel = $this.parents(".panel").eq(0),
                                    spinner =
                                        $this.data("spinner") || defaultSpinner;
                                // start showing the spinner
                                panel.addClass(whirlClass + " " + spinner);

                                // Emit event when refresh clicked
                                $scope.$emit(refreshEvent, panel.attr("id"));
                            });

                            // listen to remove spinner
                            $scope.$on("removeSpinner", removeSpinner);

                            // method to clear the spinner when done
                            function removeSpinner(ev, id) {
                                if (!id) return;
                                var newid = id.charAt(0) == "#" ? id : "#" + id;
                                angular.element(newid).removeClass(whirlClass);
                            }
                        }
                    ]
                };
            }
        ])

        .directive("onlyNumbers", function() {
            return {
                restrict: "EA",
                require: "ngModel",
                link: function(scope, element, attrs, ngModel) {
                    scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                        var spiltArray = String(newValue).split("");

                        if (attrs.allowNegative == "false") {
                            if (spiltArray[0] == "-") {
                                newValue = newValue.replace("-", "");
                                ngModel.$setViewValue(newValue);
                                ngModel.$render();
                            }
                        }

                        if (attrs.allowDecimal == "false") {
                            newValue = parseInt(newValue);
                            ngModel.$setViewValue(newValue);
                            ngModel.$render();
                        }

                        if (attrs.allowDecimal != "false") {
                            if (attrs.decimalUpto) {
                                var n = String(newValue).split(".");
                                if (n[1]) {
                                    var n2 = n[1].slice(0, attrs.decimalUpto);
                                    newValue = [n[0], n2].join(".");
                                    ngModel.$setViewValue(newValue);
                                    ngModel.$render();
                                }
                            }
                        }

                        if (spiltArray.length === 0) return;
                        if (
                            spiltArray.length === 1 &&
                            (spiltArray[0] == "-" || spiltArray[0] === ".")
                        )
                            return;
                        if (spiltArray.length === 2 && newValue === "-.")
                            return;

                        /*Check it is number or not.*/
                        if (isNaN(newValue)) {
                            ngModel.$setViewValue(oldValue || "");
                            ngModel.$render();
                        }
                    });
                }
            };
        });
})();
