(function() {
    "use strict";

    angular.module("cpir").filter("previousInquiriesStatusFilter", () => {
        return function(previousInquiry) {
            if (!previousInquiry) return null;

            return previousInquiry.isComplete === true
                ? "Complete"
                : "Incomplete";
        };
    });
})();
