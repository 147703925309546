(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardConfigurationController",
        class {
            constructor(authService) {
                this.authService = authService;
            }

            $onInit() {
                this.canAccessUserLists = this.authService.canAccessUserLists();
            }

            onCategorySelect(category) {
                switch (category) {
                    case "settings":
                        this.showConfiguration("settings.login");
                        break;
                    case "defaults":
                        this.showConfiguration("defaults.globals");
                        break;
                }
            }

            showConfiguration(status) {
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }
        }
    );
})();
