(function () {
    "use strict";

    if (typeof $ === "undefined") {
        throw new Error("Required: jQuery could not be found.");
    }

    angular
        .module("cpir", [
            // Start: Template
            "template.services",
            "template.directives",
            "template.controllers",

            // Start: CPIR
            "cpir.routes",
            "cpir.templates",

            // Start: Misc. Dependencies
            "auth0.auth0",
            "angular-jwt",
            "ngAnimate",
            "ngTagsInput",
            "growlNotifications",
            "ui.router",
            "ui.tree",
            "ui.tinymce",
            "ui.bootstrap",
            "ui.select",
            "angular.filter",
            "angular-storage",
            "angularMoment",
            "duScroll",
            "dndLists",
            "checklist-model",
            "ngFileUpload",
            "infinite-scroll",
            "ng-currency",
            "ngMaterial",
        ])

        /*
     Check whether there is a redirect request and set
     those parameters for the dispatcher.
     */
        .run(($location, store) => {
            storeDeepLink($location, store);
        })

        /*
    Register the previously used deep link service since
    we have to call it again if the login page is ever
    redirected to from within the app. (The 'run' method
    above won't run in that instance, so we need to ensure
    the deep link gets set before the user is dispatched.)
     */
        .service("storeDeepLink", storeDeepLink)

        .run(($rootScope, storeDeepLink) => {
            $rootScope.$on("$locationChangeSuccess", () => {
                storeDeepLink.storeLink();
            });
        })

        /*
     Setup settings from the template provider.
     */
        .run([
            "$rootScope",
            "$state",
            "$stateParams",
            "$window",
            "authService",
            ($rootScope, $state, $stateParams, $window, authService) => {
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
                $rootScope.$storage = $window.localStorage;

                // Add helper method for broadcasting events between views
                $rootScope.broadcastEvent = function (eventName, eventData) {
                    console.log(
                        "Broadcasting event:",
                        eventName,
                        eventData || {},
                    );
                    $rootScope.$broadcast(eventName, eventData || {});
                };

                let title =
                    authService.getBrand().name === "cps"
                        ? "CPS Online"
                        : "IEEE Publication Hub";

                $rootScope.app = {
                    name: title,
                    year: new Date().getFullYear(),
                    layout: {
                        isFixed: true,
                        isCollapsed: false,
                        isBoxed: false,
                        isRTL: false,
                        horizontal: false,
                        isFloat: false,
                        asideHover: false,
                    },
                    useFullLayout: false,
                    hiddenFooter: false,
                };
            },
        ])

        /*
         * Provide whitelist domain for PDF stamping
         */
        .config([
            "$httpProvider",
            "jwtOptionsProvider",
            ($httpProvider, jwtOptionsProvider) => {
                jwtOptionsProvider.config({
                    whiteListedDomains: ["localhost"],
                });
            },
        ])

        /*
         * Don't animate for .no-animate classed elements
         */
        .config(($animateProvider) => {
            $animateProvider.classNameFilter(/compliance-error/);
        })

        /*
         * Prove whitelist for SCE XSS protection
         */
        .config(($sceDelegateProvider) => {
            $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                "self",
                // Allow loading from our assets domain.  Notice the difference between * and **.
                "https://ecopyrightqa-beta.ieee.org/ECTT/IntroPage.jsp",
                "https://ecopyright.ieee.org/ECTT/IntroPage.jsp",
                "https://www.computer.org/open-access/checkout",
                "https://test.computer.org/open-access/checkout",
            ]);
        })

        /*
     Setup the authentication providers and token interceptor.
     */
        .config(
            (
                $httpProvider,
                jwtInterceptorProvider,
                angularAuth0Provider,
                authConfigurationProvider,
            ) => {
                jwtInterceptorProvider.tokenGetter = (store) =>
                    store.get("id_token");

                $httpProvider.interceptors.push("jwtInterceptor");

                angularAuth0Provider.init(
                    authConfigurationProvider.getSettings(),
                );
            },
        )

        /*
     Now that the authentication scheme is setup (above),
     when a token is detected in the URL, it will be
     processed and stored.
     */
        .run([
            "authService",
            (authService) => {
                authService.authenticateAndGetProfile();
            },
        ])

        .run([
            "authService",
            (authService) => {
                authService.checkForLogout();
            },
        ])

        /*
         When the page state changes, detect whether there is
         a valid token. In the instance a token does not exist or
         is no longer valid, send the client to the login page.
         */
        .run([
            "$rootScope",
            "$state",
            "authService",
            "$location",
            async ($rootScope, $state, authService, $location) => {
                // Check for accessToken in URL
                const accessToken = $location.search().accessToken;

                if (accessToken && authService.isTokenExpired()) {
                    // Store the new token using authService
                    await authService.authenticateFromCpir2(accessToken);
                }

                // Remove the token from the URL to prevent security issues
                $location.search("accessToken", null);

                $rootScope.$on("$stateChangeStart", (e, to) => {
                    if (to.data && to.data.login) {
                        if (authService.isTokenExpired()) {
                            console.log("auth token is expired");
                            e.preventDefault();
                            $state.go("auth.login");
                        }
                    }
                });
            },
        ])

        .factory("Hogan", ($window) => {
            if ($window.Hogan) {
                $window._thirdParty = $window._thirdParty || {};
                $window._thirdParty.Hogan = $window.Hogan;

                try {
                    delete $window.moment;
                } catch (e) {
                    $window.moment = undefined;
                }

                return $window._thirdParty.Hogan;
            }
        })

        /* Setup the UI toolkit defaults. */
        .config((uibDatepickerConfig, uibDatepickerPopupConfig) => {
            uibDatepickerPopupConfig.datepickerPopup = "d MMMM yyyy";
            uibDatepickerConfig.showWeeks = false;
            // uibDatepickerConfig.placement = 'auto bottom-left';
        })

        /* Setup the top level states. */
        .config([
            "$stateProvider",
            "$locationProvider",
            "$urlRouterProvider",
            ($stateProvider, $locationProvider, $urlRouterProvider) => {
                $locationProvider.html5Mode(false);

                $urlRouterProvider.otherwise("/auth/login");

                $stateProvider
                    .state("empty", {
                        url: "",
                        controller: ($state) => {
                            $state.go("dispatcher", {}, { reload: true });
                        },
                    })
                    .state("index", {
                        url: "/",
                        controller: ($state) => {
                            $state.go("dispatcher", {}, { reload: true });
                        },
                    })
                    .state("auth", {
                        url: "/auth",
                        templateUrl:
                            "application/cpir/_shared/components/auth/auth.html",
                        controller: "AuthController",
                        controllerAs: "$ctrl",
                        abstract: true,
                        resolve: {
                            pid: ($location) => $location.search().pid,
                            ak: ($location) => $location.search().ak,
                        },
                    })
                    .state("auth.login", {
                        url: "/login",
                        templateUrl:
                            "application/cpir/_shared/components/auth/login/login.html",
                        controller: "AuthLoginController",
                        controllerAs: "$ctrl",
                        resolve: {
                            prefilledEmail: ($location) =>
                                $location.search().email,
                        },
                    })
                    .state("auth.create-account", {
                        url: "/create?email",
                        templateUrl:
                            "application/cpir/_shared/components/auth/create-account/create-account.html",
                        controller: "AuthCreateAccountController",
                        controllerAs: "$ctrl",
                    })
                    .state("auth.reset-password", {
                        url: "/reset-password",
                        templateUrl:
                            "application/cpir/_shared/components/auth/reset-password/reset-password.html",
                        controller: "AuthResetPasswordController",
                        controllerAs: "$ctrl",
                    })
                    .state("dispatcher", {
                        url: "/dispatcher",
                        controller: "AuthDispatcherController",
                        controllerAs: "$ctrl",
                        data: {
                            login: true,
                        },
                    })
                    .state("auth.tos", {
                        url: "/tos",
                        templateUrl: "application/cpir/tos/tos.html",
                        controller: "TosController",
                        controllerAs: "$ctrl",
                        resolve: {},
                    })
                    .state("cif", {
                        url: "/cif",
                        abstract: true,
                        templateUrl:
                            "application/cpir/cif/_shared/layout/layout.html",
                        controller: "AppController",
                        data: {
                            login: true,
                        },
                    })
                    .state("ak-conference", {
                        url: "/ak/conference",
                        abstract: true,
                        templateUrl:
                            "application/cpir/authorkit/_shared/layout/layout.html",
                        controller: "AppController",
                        data: {
                            login: true,
                        },
                        resolve: {
                            disableHomeLink: () => true,
                        },
                    })
                    .state("ak", {
                        url: "/ak",
                        abstract: true,
                        templateUrl:
                            "application/cpir/authorkit/_shared/layout/layout.html",
                        controller: "AppController",
                        data: {
                            login: true,
                        },
                    })
                    .state("dashboard-editor", {
                        url: "/dashboard/editor",
                        abstract: true,
                        templateUrl:
                            "application/cpir/dashboard/editor/_shared/layout/layout.html",
                        controller: "AppController",
                        data: {
                            login: true,
                        },
                    })
                    .state("dashboard-organizer", {
                        url: "/dashboard/organizer",
                        abstract: true,
                        templateUrl:
                            "application/cpir/dashboard/organizer/_shared/layout/layout.html",
                        controller: "AppController",
                        data: {
                            login: true,
                        },
                    });
            },
        ]);

    function storeDeepLink($location, store) {
        let storeLink = () => {
            let queryParams = $location.search();

            if (queryParams.ak === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "ak",
                    pid: queryParams.pid,
                    entryId: queryParams.entryId,
                    video: queryParams.video,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.dashboard === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "dashboard",
                    searchParam: queryParams.searchParam,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.inquiry === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "inquiry",
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.quote === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "quote",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.compliance === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "compliance",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.pa === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "pa",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.configuration === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "configuration",
                    pid: queryParams.pid,
                };
                deepLink.viewQuotes = queryParams.view_quotes;
                deepLink.index = queryParams.index;
                store.set("deepLink", deepLink);
            } else if (queryParams.author_review === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "authorReview",
                    entryId: queryParams.entryId,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.cpir2 === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "cpir2",
                    returnPath: queryParams.return_path,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.change_request === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "authorChangeRequest",
                    pid: queryParams.pid,
                    entryId: queryParams.entryId,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.copyright_return === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "copyrightReturn",
                    pid: queryParams.pid,
                    entryId: queryParams.entryId,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.standalone_copyright === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "standaloneCopyright",
                    pid: queryParams.pid,
                    entryId: queryParams.entryId,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.conference_video === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "conferenceVideo",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.conference_video_list === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "conferenceVideoList",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.standalone_entry_video_upload === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "standaloneEntryVideoUpload",
                    pid: queryParams.pid,
                    entryId: queryParams.entryId,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.editorial_download === "1") {
                store.remove("deepLink");
                const deepLink = {
                    nextLocation: "webpubDownload",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            } else if (queryParams.export === "1") {
                store.remove("deepLink");
                let deepLink = {
                    nextLocation: "exportDownload",
                    pid: queryParams.pid,
                };
                store.set("deepLink", deepLink);
            }

            if (!queryParams) {
                store.remove("deepLink");
            }
        };

        storeLink();

        return { storeLink };
    }
})();
