(function() {
    "use strict";

    angular.module("cpir").factory("apiQuotes", [
        "apiService",
        function(api) {
            let quoteService = {};
            let firstItem = api.firstItem;
            let items = api.items;

            /**
             * Adds an itemMap for dot notation access to cart items.
             * @param quote
             */
            let addItemMap = quote => {
                quote.cart = quote.cart || [];
                quote.cart.forEach(subQuote => {
                    subQuote.products.forEach(product => {
                        let media = quote.media.find(
                            media => media.mid === product.mid
                        );
                        if (!media) {
                            console.error(
                                "Missing quote media for MID: " + product.mid
                            );
                        }
                        product.itemsMap = [];
                        product.items.forEach(item => {
                            let itemMap = {};
                            itemMap.mid = product.mid;
                            itemMap.label = media.label;
                            itemMap.fields = item;
                            itemMap.media = {};
                            item.forEach(property => {
                                itemMap[property.name] = property; // need to keep reference to the same object, so don't user property.value
                                let field = media.fields.find(
                                    field => field.name === property.name
                                );
                                itemMap.media[property.name] = field
                                    ? {
                                          label: field.label,
                                          options: field.options
                                      }
                                    : undefined;
                            });
                            itemMap.getOptionText = name => {
                                let field = media.fields.find(
                                    field => field.name === name
                                );
                                if (field.options) {
                                    return field.options.find(
                                        option =>
                                            option.value === itemMap[name].value
                                    ).name;
                                } else {
                                    return "ERROR: no options";
                                }
                            };
                            product.itemsMap.push(itemMap);
                        });
                    });
                });
                return quote;
            };

            /**
             * Removes any itemMaps before saving
             */
            let removeItemMap = quote => {
                if (quote.cart) {
                    quote.cart.forEach(subQuote => {
                        subQuote.products.forEach(product => {
                            delete product.itemsMap;
                        });
                    });
                }
                return quote;
            };

            /**
             * List quotes by proceeding id if specified, else list all quotes (listing all quotes may return a lot of data!)
             * @param [pid]
             * @returns  {object[]} quote
             */
            quoteService.list = pid =>
                api.get("quotes").then(result => items(result).map(addItemMap));

            /**
             * Get quote by proceeding id
             * @param pid
             */
            quoteService.byPid = pid =>
                api
                    .get("quotes/proceeding/" + pid)
                    .then(result =>
                        addItemMap(firstItem(result))
                    );

            /**
             * List quotes by quote id
             * @param qid
             */
            quoteService.byQid = qid =>
                api
                    .get("quotes/" + qid)
                    .then(result => addItemMap(firstItem(result)));

            /**
             * insert a quote
             * @param {object} quote quote to insert
             */
            quoteService.insert = quote =>
                api
                    .post("quotes", removeItemMap(angular.copy(quote)))
                    .then(result => result.data.qid);

            /**
             * Update a quote.
             * @param qid
             * @param quote
             */
            quoteService.update = (qid, quote) => {
                delete quote._id;
                return api.put(
                    "quotes/" + qid,
                    removeItemMap(angular.copy(quote))
                );
            };

            /**
             * delete a quote
             * @param qid
             */
            quoteService.delete = qid => api.delete("quotes/" + qid);

            return quoteService;
        }
    ]);
})();
