(function() {
    "use strict";

    angular.module("cpir").component("cpirNotifications", {
        bindings: {},
        templateUrl:
            "application/cpir/_shared/components/notifications/notifications.component.html",
        controller: class {
            constructor(NotificationService) {
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.notifications = this.NotificationService.notifications;
            }

            getTTL(notification) {
                if (notification.options && notification.options.sticky) {
                    return 1000 * 3600 * 8;
                }
                return 1000 * 10;
            }
        }
    });
})();
