(function () {
    "use strict";

    angular.module("cpir").component("cpirBulkEmailStatusModal", {
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/bulk-email-status-modal/bulk-email-status-modal.html",
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
        },
        controller: class {
            constructor($scope, $interval) {
                this.$scope = $scope;
                this.$interval = $interval;
                this.loading = true;
                this.error = null;
                this.status = {
                    completedCount: 0,
                    failedCount: 0,
                    totalCount: 0,
                    status: "processing",
                    progress: 0,
                };
                this.refreshInterval = null;
            }

            $onInit() {
                this.operationId = this.resolve.operationId;
                this.EmailService = this.resolve.EmailService;
                this.totalCount = this.resolve.totalCount;

                // Initialize status
                this.fetchStatus();

                // Set up automatic refresh every 2 seconds
                this.refreshInterval = this.$interval(() => {
                    this.fetchStatus();
                }, 2000);
            }

            $onDestroy() {
                if (this.refreshInterval) {
                    this.$interval.cancel(this.refreshInterval);
                }
            }

            fetchStatus() {
                this.loading = true;
                this.EmailService.getBulkOperationStatus(this.operationId)
                    .then((status) => {
                        this.status = status;
                        this.status.progress = Math.round(
                            ((status.completedCount + status.failedCount) /
                                status.totalCount) *
                                100,
                        );

                        // If operation is complete, stop polling
                        if (
                            status.status === "completed" ||
                            status.status === "failed"
                        ) {
                            this.$interval.cancel(this.refreshInterval);
                        }
                    })
                    .catch((err) => {
                        this.error =
                            err.message || "Error fetching operation status";
                        console.error("Error fetching bulk email status:", err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

            getStatusLabel() {
                switch (this.status.status) {
                    case "processing":
                        return "In Progress";
                    case "completed":
                        return "Completed";
                    case "failed":
                        return "Failed";
                    default:
                        return this.status.status;
                }
            }

            getStatusClass() {
                switch (this.status.status) {
                    case "processing":
                        return "info";
                    case "completed":
                        return this.status.failedCount > 0
                            ? "warning"
                            : "success";
                    case "failed":
                        return "danger";
                    default:
                        return "default";
                }
            }

            close() {
                this.dismiss();
            }
        },
    });
})();
