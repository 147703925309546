(function() {
    "use strict";

    angular.module("cpir").component("cpirCifSummaryQuotesPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/quotes/quotes.html",
        controller: class {
            constructor($stateParams, apiQuotes) {
                this.$stateParams = $stateParams;
                this.QuoteService = apiQuotes;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.activeQuoteIndex = 0;

                // Get existing quote
                this.QuoteService.byPid(this.pid).then(quote => {
                    this.quote = quote;
                    this.qid = quote.qid;
                    this.activeQuote = this.quote.cart[0];
                });
            }

            /**
             * Activate a quote according to its place in
             * the array (quoteIndex).
             * @param quoteIndex
             */
            activateQuote(quoteIndex) {
                this.activeQuoteIndex = quoteIndex;
                this.activeQuote = this.quote.cart[quoteIndex];
            }
        }
    });
})();
