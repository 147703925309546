(function() {
    "use strict";

    angular.module("cpir").component("cpirWebpubTemplateForm", {
        bindings: {
            template: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/_shared/components/webpub-template-form/webpub-template-form.html",
        controller: class {
            constructor(DefaultSettingsService) {
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {}

            modelChanged() {
                this.onUpdate({ template: this.template });
            }
        }
    });
})();
