(function() {
    "use strict";

    angular.module("cpir").provider(
        "CopyrightService",
        class {
            constructor() {}
            $get($http, $q) {
                const getBatchCopyright = icxId => {
                    let deferred = $q.defer();

                    $http({
                        method: "GET",
                        url: `copyright/${icxId}`
                    }).then(
                        response => {
                            deferred.resolve(response.data);
                        },
                        response => {
                            deferred.reject(response);
                        }
                    );

                    return deferred.promise;
                };

                const getEntryCopyright = (proceeding, entry) => {
                    const { icxId } = proceeding;
                    if (!icxId) {
                        return Promise.reject("Missing icxId");
                    }

                    let deferred = $q.defer();

                    $http({
                        method: "GET",
                        url: `copyright/${proceeding.icxId}/article/${entry.articleId}`
                    }).then(
                        response => {
                            deferred.resolve(response.data);
                        },
                        response => {
                            deferred.reject(response);
                        }
                    );

                    return deferred.promise;
                };

                return { getBatchCopyright, getEntryCopyright };
            }
        }
    );
})();
