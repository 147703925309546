(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCEditEntryFilesController",

        class {
            constructor(
                pid,
                entryId,
                $state,
                $stateParams,
                $uibModal,
                NotificationService,
                ProceedingService,
                TocEntriesApiService,
                UploadService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$uibModal = $uibModal;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.UploadService = UploadService;
            }

            $onInit() {
                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                    }
                );

                this.TocEntriesApiService.getEntry(this.entryId).then(entry => {
                    this.entry = entry;
                });
            }

            returnToEditEntry() {
                this.$state.go(
                    "dashboard-editor.table-of-contents-edit-entry",
                    {
                        pid: this.pid,
                        entryId: this.entryId
                    }
                );
            }

            saveChanges() {
                this.UploadService.upload({
                    pid: this.pid,
                    entryId: this.entryId,
                    paper: this.paperPdf,
                    source: this.paperSource,
                    extras: this.supplementalFiles
                })
                    .then(uploadResult => {
                        console.log(uploadResult);
                        this.NotificationService.send(
                            "success",
                            "The files were uploaded successfully."
                        );
                        this.returnToEditEntry();
                    })
                    .catch(err => {
                        console.log(err);
                        this.NotificationService.send(
                            "warning",
                            "There was an error uploading the files."
                        );
                    });
            }

            selectPDFFile(file, invalidFile) {
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose a PDF file for this submission."
                    );
                } else {
                    if(this.isFileNameInvalid(file.name)) {
                        this.paperPdf = null;
                        this.NotificationService.send(
                            "danger",
                            "Invalid letters in PDF filename."
                        );
                    }
                }
                this.pdfDetails = {};
                this.pdfDetails.name = file.name;
                this.pdfDetails.size = file.size;
            }

            selectSupplementFiles(files) {
                for(let i=0; i< files.length; i++) {
                    if(this.isFileNameInvalid(files[i].name)) {
                        this.supplementalFiles.splice(i, 1);
                        this.NotificationService.send(
                            "danger",
                            "Invalid letters in supplement filename. Please correct."
                        );
                    }
                }
            }

            isFileNameInvalid(fileName) {
                let decodedFilename = he.decode(fileName);
                let controlCharRegExp = /[\/:*?"<>|\u0000-\u001f\u007f\u0080-\u009f]/g;
                return controlCharRegExp.test(decodedFilename);
            }

            removePDFFile() {
                if (this.paperPdf) this.paperPdf = null;
            }

            selectSourceFile(file) {
                if(this.isFileNameInvalid(file.name)) {
                    this.paperSource = null;
                    this.NotificationService.send(
                        "danger",
                        "Invalid letters in source filename. Please correct."
                    );
                }
                this.sourceDetails = {};
                this.sourceDetails.name = file.name;
                this.sourceDetails.size = file.size;
            }

            removeSourceFile() {
                if (this.paperSource) this.paperSource = null;
            }

            removeSupplementalFile(index) {
                this.supplementalFiles.splice(index, 1);
            }
        }
    );
})();
