(function() {
    angular.module("cpir").component("cpirCifContactsSwitcher", {
        bindings: {
            contacts: "<",
            isDisabled: "<",
            activeContactIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/cif/contacts/components/contact-switcher/contact-switcher.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.buttonClicked = $index => {
                    this.onSwitch({ contactIndex: $index });
                };
            }

            showConferenceOrganizerLabel(contact) {
                if (contact.isConferenceOrganizer) {
                    return true;
                }
            }

            showEmptyOptionalContactLabel(contact) {
                if (!contact.isConferenceOrganizer && !contact.givenName) {
                    return true;
                } else {
                    return false;
                }
            }

            showNameLabel(contact) {
                if (!contact.isConferenceOrganizer && contact.givenName) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    });
})();
