(function() {
    "use strict";

    angular.module("cpir").provider(
        "PitstopService",
        class {
            $get($http, $window, $q, WebSocketService, $httpParamSerializer) {
                const pitstopService = {};
                function catchError(err) {
                    console.log(err.data);
                    throw err.data;
                }

                function getData(result) {
                    return result.data;
                }

                function pitstopValidate(pid, entryIds, articlesByEntry) {
                    return $http
                        .post(`/pitstop/validateAll/${pid}`, { entryIds: entryIds, articlesByEntry: articlesByEntry })
                        .catch(catchError)
                        .then(getData);
                }

                function getDownloadLink(vid) {
                    return $http
                        .get(`/pitstop/errorReport/${vid}`)
                        .catch(catchError);
                }

                function getFile(vid) {
                    $window.open(`/pitstop/errorReport/${vid}`);
                }

                function isPitstopMissing(entry, stampsByEntry, pitstopsByEntry) {
                    let obj = {};
                    obj.isStampMissing = isStampFilePitstopMissing(entry, stampsByEntry);
                    obj.isRawFileMissing = isRawFilePitstopMissing(entry, pitstopsByEntry);
                    obj.result = obj.isStampMissing && obj.isRawFileMissing;
                    return obj;
                }

                function isRawFilePitstopMissing(entry, pitstopsByEntry) {
                    if(pitstopsByEntry) {
                        let pitstopEntry = pitstopsByEntry[entry.eid];
                        if (pitstopEntry)
                            return pitstopEntry.pitstop ? false : true;
                        else
                            return true;
                    } else
                        return false;
                }

                function isStampFilePitstopMissing(entry, pitstopsByEntry) {
                    if(pitstopsByEntry) {
                        let pitstopEntry = pitstopsByEntry[entry.eid];
                        if (pitstopEntry)
                            return pitstopEntry.pitstop ? false : true;
                        else
                            return true;
                    } else
                        return false;
                }

                function isPitstopSubmitted(entry, stampsByEntry, pitstopsByEntry) {
                    let obj = {};
                    obj.isStampSubmitted = stampFilePitstopSubmitted(entry, stampsByEntry) ;
                    obj.isRawFileSubmitted = (rawFilePitstopSubmitted(entry, pitstopsByEntry) && !stampFilePitstopSuccess(entry, stampsByEntry));
                    obj.result = obj.isStampSubmitted || obj.isRawFileSubmitted;
                    return obj;
                }

                function rawFilePitstopSubmitted(entry, pitstopsByEntry) {
                    if(pitstopsByEntry) {
                        let pitstopEntry = pitstopsByEntry[entry.eid];
                        if (pitstopEntry && pitstopEntry.pitstop)
                            return pitstopEntry.pitstop === "submitted" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                }

                function rawFilePitstopSuccess(entry, pitstopsByEntry) {
                    if(pitstopsByEntry) {
                        let pitstopEntry = pitstopsByEntry[entry.eid];
                        if (pitstopEntry && pitstopEntry.pitstop)
                            return pitstopEntry.pitstop === "success" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                }

                function stampFilePitstopSubmitted(entry, stampsByEntry) {
                    if(stampsByEntry) {
                        let stampByEntry = stampsByEntry[entry.eid];
                        if (stampByEntry && stampByEntry.pitstop)
                            return stampByEntry.pitstop === "submitted" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                }

                function stampFilePitstopSuccess(entry, stampsByEntry) {
                    if(stampsByEntry) {
                        let stampByEntry = stampsByEntry[entry.eid];
                        if (stampByEntry && stampByEntry.pitstop)
                            return stampByEntry.pitstop === "success" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                }

                function isPitstopError(entry, stampsByEntry, pitstopsByEntry) {
                    let obj = {};
                    obj.isStampError = stampFilePitstopError(entry, stampsByEntry);
                    obj.isRawFileError = (rawFilePitstopError(entry, pitstopsByEntry) && !stampFilePitstopSubmitted(entry, stampsByEntry) &&
                        !stampFilePitstopSuccess(entry, stampsByEntry));
                    obj.result = obj.isStampError || obj.isRawFileError;
                    return obj;
                }
                let rawFilePitstopError = (entry, pitstopsByEntry) => {
                    if(pitstopsByEntry) {
                        let pitstopEntry = pitstopsByEntry[entry.eid];
                        if (pitstopEntry && pitstopEntry.pitstop)
                            return pitstopEntry.pitstop === "error" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                };

                let stampFilePitstopError = (entry, stampsByEntry) => {
                    if(stampsByEntry) {
                        let stampEntry = stampsByEntry[entry.eid];
                        if (stampEntry && stampEntry.pitstop)
                            return stampEntry.pitstop === "error" ? true : false;
                        else
                            return false;
                    } else
                        return false;
                };


                return {
                    getData,
                    pitstopValidate,
                    getDownloadLink,
                    getFile,
                    isPitstopError,
                    isPitstopSubmitted,
                    isPitstopMissing,
                    stampFilePitstopError,
                    rawFilePitstopError,
                    stampFilePitstopSuccess,
                    rawFilePitstopSuccess

                };
            }
        }
    );
})();
