(function() {
    "use strict";

    angular.module("cpir").component("cpirCifWorkshopPanel", {
        bindings: {
            isEditor: "<",
            isSponsored: "<",
            isConferenceTab: "<",
            isLastWorkshop: "<",
            workshop: "<",
            onUpdate: "&",
            onSave: "&",
            onRemove: "&",
            showSaveButton: "<",
            workshopFlag: "<",
            workshopLength: "<",
            enableConferenceTitleCheck: "<",
            isInquirySummary: "<",
            parentConference: "<"
        },
        templateUrl:
            "application/cpir/cif/workshops/components/workshops-panel/components/workshop-panel/workshop-panel.component.html",
        controller: class {
            constructor($rootScope, $scope, PureDateService, InternationalDataService) {
                this.$rootScope = $rootScope;
                this.$scope = $scope;
                this.PureDateService = PureDateService;
                this.InternationalDataService = InternationalDataService;
                this.datesModel = {};
            }

            $onInit() {
                this.workshopForm = {};

                console.log(this.enableConferenceTitleCheck);

                if(this.enableConferenceTitleCheck)
                    this.errorPopoverTitle =  "Field Validation";
                else
                    this.errorPopoverTitle = "Missing Fields";

                this.countryOptions = this.InternationalDataService.getCountries();

                if(this.workshop && !this.workshop.location && this.parentConference)
                    this.workshop.location = _.clone(this.parentConference.location);

                if(this.workshop && !this.workshop.postalCode && this.parentConference.postalCode)
                    this.workshop.postalCode = _.clone(this.parentConference.postalCode);

                if(!this.workshopFlag && !this.isConferenceTab) {
                     this.popOverMessage = "Workshops are not enabled for this proceeding.";
                     this.isPopOverEnabled = true;
                    }
                    else {
                        this.popOverMessage = "";
                        this.isPopOverEnabled = false;
                    }

                    // this is needed for the switcher to work
                this.$scope.$watch(
                    () => this.workshopForm.$valid,
                    () => {
                        if (this.workshop) {
                            if(!this.workshop.location && this.parentConference)
                                this.workshop.location = _.clone(this.parentConference.location);
                            if(!this.workshop.postalCode && this.parentConference.postalCode)
                                this.workshop.postalCode = _.clone(this.parentConference.postalCode);
                            this.workshopChanged();
                        }
                    }
                );
            }

            $onChanges({ workshop: workshopChanges , workshopFlag: workshopFlagChanges
                           }) {
                if(!this.workshopFlag && !this.isConferenceTab) {
                    this.popOverMessage = "Workshops are not enabled for this proceeding.";
                    this.isPopOverEnabled = true;
                    this.isReadOnly = "readonly";
                } else {
                    this.popOverMessage = "";
                    this.isPopOverEnabled = false;
                    this.isReadOnly = ""
                }
                if (workshopChanges) { //s needed when the first load happens of the first default workshop display when the proc config loads
                    this.workshopChanged();
                }
            }

            inflateDates() {
                if (this.workshop && this.workshop.dates) {
                    const { start, end, delivery } = {};
                    const {
                        start: pureStart,
                        end: pureEnd,
                        delivery: pureDelivery
                    } = this.workshop.dates;
                    this.workshop.dates = {
                        start:
                            !start && pureStart
                                ? this.PureDateService.inflate(pureStart)
                                : start,
                        end:
                            !end && pureEnd
                                ? this.PureDateService.inflate(pureEnd)
                                : end,
                        delivery:
                            !delivery && pureDelivery
                                ? this.PureDateService.inflate(pureDelivery)
                                : delivery
                    };
                } else {
                    const { start, end, delivery } = {};
                    this.datesModel = {
                    };
                }
            }
            updateDates() {
                this.inflateDates();
                const { start, end, delivery } = this.datesModel;
            }

            workshopChanged() {
                if (this.workshop) {
                    this.updateDates();
                    this.onUpdate({
                        workshop: this.workshop,
                        isValid: this.workshopForm?this.workshopForm.$valid:false
                    });
                }
            }


            removeButtonClicked() {
                this.onRemove();
            }

            saveProceeding() {
                this.onSave({workshop: this.workshop});
            }

            updateProvince(province) {
                this.workshop.location.province = province;
                this.workshopChanged();
            }
        }
    });
})();
