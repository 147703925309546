(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkOpenAccessCopyrightReleasesController",

        class {
            constructor(
                pid,
                entryId,
                standalone,
                $state,
                $stateParams,
                ProceedingService,
                AKService,
                TocEntriesApiService,
                WordpressService,
                $window,
                $scope,
                WebSocketService,
                ItextService,
                LambdaService,
                NotificationService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.isStandalone = standalone;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.AKService = AKService;
                this.WordpressService = WordpressService;
                this.$window = $window;
                this.$scope = $scope;
                this.WebSocketService = WebSocketService;
                this.ItextService = ItextService;
                this.LambdaService = LambdaService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.LambdaService.getPublicIp().then(ip => {
                    this.oaRelease.ip = ip;
                    this.ipIsLoaded = true;
                });
                this.openAccessForm = {};
                this.oaTypes = {
                    ccby: {
                        value: "ccby",
                        name: "CREATIVE COMMONS ATTRIBUTION 4.0 LICENSE",
                        acronym: "CCBY"
                    },
                    ccbyncnd: {
                        value: "ccbyncnd",
                        name:
                            "CREATIVE COMMONS\n" +
                            "ATTRIBUTION-NONCOMMERCIAL-NO DERIVATIVES 4.0 LICENSE",
                        acronym: "CCBY-NC-ND"
                    }
                };
                this.defaultOaType = this.oaTypes.ccby.value;
                this.entryIsLoaded = false;
                this.oaRelease = {};
                this.AKService.setActive(this.pid, this.entryId);

                this.proceeding = this.AKService.getProceeding();
                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                    }
                );
                this.WordpressService.getOpenAccessCheckoutUrl(
                    this.pid,
                    this.entryId
                ).then(url => {
                    this.checkoutUrl = url;
                    this.jwtIsLoaded = true;
                });
                this.TocEntriesApiService.getEntry(this.entryId).then(entry => {
                    this.entry = entry;
                    this.entryAuthors = this.AKService.getAuthorNames(
                        this.entry
                    );
                    this.entryIsLoaded = true;
                });
            }

            goToPreviousStep() {
                this.AKService.goToCopyrightRelease(this.$stateParams);
            }

            getOaAgreementTitle() {
                const oaTitleType = this.oaTypes[
                    this.oaRelease.oaType || this.defaultOaType
                ].name.toUpperCase();
                const oaTitleAcronym = this.oaTypes[
                    this.oaRelease.oaType || this.defaultOaType
                ].acronym.toUpperCase();
                return `TERMS AND CONDITIONS OF AN AUTHOR'S USE OF THE ${oaTitleType} (${oaTitleAcronym})`;
            }

            getOaAgreementTypeName() {
                return this.oaTypes[this.oaRelease.oaType || this.defaultOaType]
                    .name;
            }

            getOaAgreementType() {
                return this.oaTypes[this.oaRelease.oaType || this.defaultOaType]
                    .value;
            }

            getAvReleaseOption() {
                return this.oaRelease.avRelease === true ? "optIn" : "optOut";
            }

            getAvChoiceText() {
                return this.oaRelease.avRelease === true ? "DO" : "DO NOT";
            }

            isCCBYNCND() {
                return (
                    (this.oaRelease.oaType || this.defaultOaType) ===
                    this.oaTypes.ccbyncnd.value
                );
            }

            isCCBY() {
                return (
                    (this.oaRelease.oaType || this.defaultOaType) ===
                    this.oaTypes.ccby.value
                );
            }

            createOACopyrightDocument() {
                return this.ItextService.generateOAForm(
                    this.pid,
                    this.entryId,
                    this.oaRelease.signature,
                    this.getOaAgreementType(),
                    this.getAvReleaseOption()
                );
            }

            canCheckout() {
                return (
                    !this.didCheckout &&
                    this.jwtIsLoaded &&
                    this.ipIsLoaded &&
                    !this.openAccessForm.$invalid
                );
            }

            checkout() {
                this.didCheckout = true;
                this.entry.copyrightType = "open-access";
                this.entry.openAccessCopyright = {
                    ...(this.entry.openAccessCopyright || {}),
                    signed: true,
                    signature: this.oaRelease.signature,
                    signDate: new Date(),
                    type: this.oaRelease.oaType,
                    avRelease: this.oaRelease.avRelease,
                    plagiarismStatementAgreement: this.oaRelease
                        .plagiarismStatementAgreement,
                    ip: this.oaRelease.ip
                };
                return this.TocEntriesApiService.updateEntries(this.entry)
                    .then(() => this.createOACopyrightDocument())
                    .then(() => {
                        this.TocEntriesApiService.broadcastEntries(this.pid);

                        // reset form fields and redirect to OA release
                        this.oaRelease = {};
                        this.openAccessForm.$setPristine();
                        this.openAccessForm.$setValidity();
                        this.openAccessForm.$setUntouched();
                        this.WebSocketService.safeApply(this.$scope);

                        console.log("redirecting to " + this.checkoutUrl);
                        this.$window.location.href = this.checkoutUrl;
                    })
                    .catch(err => {
                        this.NotificationService.send(
                            "danger",
                            "There was a problem processing your open access request: " +
                                (err.error || err)
                        );
                        console.error(err.error || err);
                        this.didCheckout = false;
                    });
            }
        }
    );
})();
