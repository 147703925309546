(function() {
    "use strict";

    /**
     * This directive takes a template and a data object and compiles
     * them to create dynamic template. It uses Angular's own compiler.
     */

    angular.module("cpir").directive("liveTemplate", function($compile, Hogan) {
        let link = (scope, element, attr) => {
            scope.$watchCollection("[data, template]", () => {
                if (scope.data && scope.template) {
                    // RegEx to match [[ and ]]
                    let front = /(\[\[)/g;
                    let back = /(\]\])/g;

                    // Replace square braces with curly (for compilation)
                    let template = scope.template
                        .replace(front, "{{")
                        .replace(back, "}}");

                    // Copy 'data' object into the top of the scope so that
                    // during compilation, you can access an object without
                    // pre-pending it with 'data' (i.e. proceeding.title
                    // rather than data.proceeding.title)
                    angular.merge(scope, scope.data);

                    // Compile the template using Angular's template compiler
                    let compiledTemplate = $compile(template)(scope);

                    element.html(compiledTemplate);
                }
            });
        };
        return {
            restrict: "E",
            link: link,
            scope: {
                template: "<",
                data: "<"
            }
        };
    });
})();
