(function () {
    "use strict";

    angular.module("cpir").filter(
        "entryOrderByFilter",

        (
            orderByFilter,
            entryPageNumberFilterFilter,
            tocRecordNameFilterFilter,
            entryPagesPlusPaddingFilterFilter,
            PitstopService,
        ) => {
            return (
                entries,
                articlesByEntry,
                versionNumbersByEntry,
                stampsByEntry,
                supplementalFilesByEntry,
                pitstopsByEntry,
                selectedOrder,
                reverse,
            ) => {
                if (
                    !entries ||
                    !articlesByEntry ||
                    !versionNumbersByEntry ||
                    !stampsByEntry ||
                    !pitstopsByEntry
                )
                    return entries;
                let _entries = entries.concat([]);

                let finalArray = [];
                _entries.forEach((entry) => {
                    let item = {};
                    item.eid = entry.eid;
                    if (
                        PitstopService.isPitstopSubmitted(
                            entry,
                            stampsByEntry,
                            pitstopsByEntry,
                        ).result
                    )
                        item.pitstop = "submitted";
                    else if (
                        PitstopService.isPitstopError(
                            entry,
                            stampsByEntry,
                            pitstopsByEntry,
                        ).result
                    )
                        item.pitstop = "error";
                    else if (
                        PitstopService.isPitstopMissing(
                            entry,
                            stampsByEntry,
                            pitstopsByEntry,
                        ).result
                    )
                        item.pitstop = "missing";
                    else item.pitstop = "success";
                    finalArray[item.eid] = item;
                });

                function getFileSize(file) {
                    if (!file) return 0;
                    return file.size || 0;
                }

                function getSupplementalFilesSize(
                    entry,
                    supplementalFilesByEntry,
                ) {
                    if (
                        !supplementalFilesByEntry ||
                        !supplementalFilesByEntry[entry.eid]
                    ) {
                        return 0;
                    }
                    return supplementalFilesByEntry[entry.eid].reduce(
                        (sum, file) => sum + (file.size || 0),
                        0,
                    );
                }

                function scorePitstop(file) {
                    if (file) {
                        if (file.pitstop) {
                            if (file.pitstop === "success") return 4;
                            else if (file.pitstop === "error") return 3;
                            else if (file.pitstop === "submitted") return 2;
                        }
                        if (!file.pitstop) return 1;
                    }
                    return 0;
                }

                switch (selectedOrder) {
                    case "authors":
                        _entries.map((e) => {
                            e.authors =
                                e.authors && e.authors.length
                                    ? e.authors
                                    : null;
                            return e;
                        });
                        return orderByFilter(
                            _entries,
                            "authors.length",
                            !reverse,
                        );
                    case "sequence":
                        return orderByFilter(entries, "sequence", !reverse);
                    case "copyrightType":
                        _entries.map((e) => {
                            e.copyrightType = e.copyrightType || null;
                            if (typeof e.copyrightType !== "string") {
                                e.copyrightType = null;
                            }
                            return e;
                        });
                        return orderByFilter(
                            _entries,
                            "copyrightType",
                            !reverse,
                        );
                    case "doi":
                        _entries.map((e) => {
                            e.doi = e.doi || null;
                            return e;
                        });
                        return orderByFilter(_entries, "doi", !reverse);
                    case "pageNumber":
                        return _entries.sort((a, b) => {
                            // First, determine if the entries use Roman numerals
                            const aIsRoman = a.isPageNumberRoman || false;
                            const bIsRoman = b.isPageNumberRoman || false;

                            // If one is Roman and the other is not, Roman comes first when sorting in ascending order (front matter)
                            if (aIsRoman && !bIsRoman) return reverse ? -1 : 1;
                            if (!aIsRoman && bIsRoman) return reverse ? 1 : -1;

                            // Get numeric values for sorting within the same type
                            // If the page number is not a number, set it to the maximum value
                            const pageA = a.pageNumber || Number.MAX_VALUE;
                            const pageB = b.pageNumber || Number.MAX_VALUE;

                            // Sort by numeric value (within same number type)
                            return reverse ? pageA - pageB : pageB - pageA;
                        });
                    case "pages":
                        return _entries.sort((a, b) => {
                            let pagesA = entryPagesPlusPaddingFilterFilter(
                                a,
                                articlesByEntry,
                            );
                            let pagesB = entryPagesPlusPaddingFilterFilter(
                                b,
                                articlesByEntry,
                            );
                            return reverse ? pagesA - pagesB : pagesB - pagesA;
                        });
                    case "paperId":
                        _entries.map((e) => {
                            e.paperId = e.paperId || null;
                            if (typeof e.paperId !== "string") {
                                e.paperId = null;
                            }
                            return e;
                        });
                        return orderByFilter(_entries, "paperId", !reverse);
                    case "productionStatus":
                        return orderByFilter(
                            entries,
                            "productionStatus",
                            !reverse,
                        );
                    case "title":
                        return _entries.sort((a, b) => {
                            let titleA =
                                tocRecordNameFilterFilter(a).toUpperCase();
                            let titleB =
                                tocRecordNameFilterFilter(b).toUpperCase();
                            if (!reverse)
                                return titleA < titleB
                                    ? 1
                                    : titleA > titleB
                                      ? -1
                                      : 0;
                            if (reverse)
                                return titleA < titleB
                                    ? -1
                                    : titleA > titleB
                                      ? 1
                                      : 0;
                        });
                    case "type":
                        return orderByFilter(entries, "type", !reverse);
                    case "version":
                        return _entries.sort((a, b) => {
                            let versionA = versionNumbersByEntry[a.eid] || 0;
                            let versionB = versionNumbersByEntry[b.eid] || 0;
                            return reverse
                                ? versionA - versionB
                                : versionB - versionA;
                        });
                    case "file":
                        return _entries.sort((a, b) => {
                            const aFileSize = getFileSize(
                                articlesByEntry[a.eid],
                            );
                            const bFileSize = getFileSize(
                                articlesByEntry[b.eid],
                            );
                            return reverse
                                ? aFileSize - bFileSize
                                : bFileSize - aFileSize;
                        });
                    case "stamp":
                        return _entries.sort((a, b) => {
                            const aStampSize = getFileSize(
                                stampsByEntry[a.eid],
                            );
                            const bStampSize = getFileSize(
                                stampsByEntry[b.eid],
                            );
                            return reverse
                                ? aStampSize - bStampSize
                                : bStampSize - aStampSize;
                        });
                    case "supplement":
                        return _entries.sort((a, b) => {
                            const aSupplementSize = getSupplementalFilesSize(
                                a,
                                supplementalFilesByEntry,
                            );
                            const bSupplementSize = getSupplementalFilesSize(
                                b,
                                supplementalFilesByEntry,
                            );
                            return reverse
                                ? aSupplementSize - bSupplementSize
                                : bSupplementSize - aSupplementSize;
                        });
                    case "pitstop":
                        return _entries.sort((a, b) => {
                            const aStampScore = scorePitstop(finalArray[a.eid]);
                            const bStampScore = scorePitstop(finalArray[b.eid]);
                            return reverse
                                ? aStampScore - bStampScore
                                : bStampScore - aStampScore;
                        });
                    case "promo":
                        return _entries.sort((a, b) => {
                            let aHasDate = getNumberOfPromoDatesSet(a);
                            let bHasDate = getNumberOfPromoDatesSet(b);
                            return reverse
                                ? aHasDate - bHasDate
                                : bHasDate - aHasDate;
                        });
                    case "similarity":
                        return _entries.sort((a, b) => {
                            let aSimilarity = a.similarity || Number.MIN_VALUE;
                            let bSimilarity = b.similarity || Number.MIN_VALUE;
                            return reverse
                                ? aSimilarity - bSimilarity
                                : bSimilarity - aSimilarity;
                        });
                }
            };
        },
    );

    function getNumberOfPromoDatesSet(entry) {
        return (
            (entry.enablePromoDates &&
                entry.promoDates &&
                ((entry.promoDates.start && 1) || 0) +
                    ((entry.promoDates.end && 1) || 0)) ||
            0
        ); // add one for each date set
    }
})();
