(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceSponsorsPanel", {
        bindings: {
            sponsors: "<",
            isDisabled: "<",
            isErrorsVisible: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/sponsors-panel/sponsors-panel.component.html",
        controller: class {
            constructor($scope) {
                this.$scope = $scope;
            }

            $onInit() {
                if (_.isEmpty(this.sponsors)) {
                    let initialSponsor = {};
                    this.sponsors = [initialSponsor, { foo: "bar" }];
                }
                this.activateSponsor(0);
            }

            $onChanges(changes) {
                // if the changes remove the sponsor (due to proceeding loading) re-add the initial element
                if (_.isEmpty(this.sponsors)) {
                    let initialSponsor = {};
                    this.sponsors = [initialSponsor];
                }
                this.activateSponsor(0);

                // activate the initial sponsor when the proceeding loads
                if (changes.sponsors && changes.sponsors.currentValue) {
                    this.activateSponsor(0);
                }
            }

            addSponsor() {
                this.sponsors.push({
                    address: {
                        country: "US"
                    }
                });
                this.activateSponsor(this.sponsors.length - 1);
            }

            removeActiveSponsor() {
                this.sponsors.splice(this.activeSponsorIndex, 1);
                this.activateSponsor(this.activeSponsorIndex - 1);
            }

            activateSponsor(sponsorIndex) {
                this.activeSponsorIndex = sponsorIndex;
                this.activeSponsor = this.sponsors[sponsorIndex];
            }

            updateSponsor(sponsor) {
                this.activeSponsor = sponsor;
                this.modelChanged();
            }

            modelChanged() {
                this.onUpdate({
                    sponsors: this.sponsors
                });
            }
        }
    });
})();
