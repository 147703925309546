(function() {
    "use strict";

    angular.module("cpir").component("cpirAdditionalInformationModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/dashboard/_shared/components/additional-information-modal/additional-information-modal.component.html",
        controller: class {
            constructor($state, NotificationService, ProceedingService) {
                this.$state = $state;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
            }

            $onInit() {
                this.isLoading = false;
                this.proceeding = this.resolve.proceeding;
                this.isCloseable = this.resolve.isCloseable;

                this.ProceedingService.get(this.proceeding.pid)
                    .then(proceeding => {
                        if (proceeding.additionalInformation) {
                            this.paperTypes =
                                proceeding.additionalInformation.paperTypes;
                            this.trim = proceeding.additionalInformation.trim;
                        }
                    })
                    .catch(e => console.log(e));
            }

            modelChanged(paperTypes, trim) {
                this.paperTypes = paperTypes;
                this.trim = trim;
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            isSubmitDisabled() {
                return (
                    this.additionalInformationForm.$invalid || this.isLoading
                );
            }

            submitButtonClicked() {
                this.isLoading = true;
                this.proceeding.additionalInformation = {
                    paperTypes: this.paperTypes,
                    trim: this.trim
                };

                this.ProceedingService.update(
                    this.proceeding.pid,
                    this.proceeding
                )
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "Your response was successfully saved."
                        );
                        this.close();
                        this.$state.reload();
                        this.isLoading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.isLoading = false;
                    });
            }
        }
    });
})();
