(function() {
    "use strict";

    angular.module("cpir").filter("proceedingStatusFilter", () => {
        return (proceedings, status) => {
            if (!proceedings || status === "*") return proceedings;
            let _proceedings = proceedings.filter(p => {
                return p.configuration.settings.steps.status === status;
            });
            return _proceedings;
        };
    });
})();
