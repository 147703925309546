/**
 * Created by jhellwig on 1/17/2017.
 */
(function () {
    "use strict";

    angular.module("cpir").factory("ProceedingService", [
        "apiService",
        "$q",
        "$http",
        "publicationAgreementService",
        "$window",
        "IndexedDBService",
        function (
            api,
            $q,
            $http,
            publicationAgreementService,
            $window,
            IndexedDBService,
        ) {
            let proceedingService = {};
            const { from, concat, EMPTY } = rxjs;
            const { catchError, tap, map, finalize } = rxjs.operators;
            let firstItem = api.firstItem;
            let items = api.items;

            /**
             * List proceedings, optionally with a limit
             */
            proceedingService.list = (limit, all = false) => {
                if (!_.isNil(limit) || all) {
                    let queryParams = [];
                    if (!_.isNil(limit)) {
                        queryParams.push(`limit=${limit}`);
                    }
                    if (all) {
                        queryParams.push("all=true");
                    }
                    return api
                        .get(`proceedings?${queryParams.join("&")}`)
                        .then(items);
                } else {
                    return api.get("proceedings").then(items);
                }
            };

            /**
             * Find by pid
             * @param pid
             */
            proceedingService.get = (pid) =>
                api.get(`proceedings/${pid}`).then(firstItem);

            proceedingService.listWithCache$ = function () {
                const hasIndexedDBSupport = IndexedDBService.isSupported();
                if (!hasIndexedDBSupport) {
                    console.warn(
                        "IndexedDB is not supported. Falling back to API only.",
                    );
                }

                const indexedDBObservable = hasIndexedDBSupport
                    ? from(IndexedDBService.getProceedings()).pipe(
                          catchError((err) => {
                              console.error("Error accessing IndexedDB", err);
                              return EMPTY;
                          }),
                      )
                    : EMPTY;

                const apiObservable = from(
                    proceedingService.list(null, true),
                ).pipe(
                    tap((proceedings) => {
                        if (hasIndexedDBSupport) {
                            IndexedDBService.updateProceedings(proceedings);
                        }
                    }),
                    map((proceedings) =>
                        proceedings.filter((p) => p.status === 1),
                    ),
                    catchError((err) => {
                        console.error(
                            "Error fetching proceedings from API",
                            err,
                        );
                        return EMPTY; // You might want to handle this differently depending on your error handling strategy
                    }),
                );

                return concat(indexedDBObservable, apiObservable).pipe(
                    finalize(() =>
                        console.log("Completed fetching proceedings"),
                    ),
                );
            };

            proceedingService.getWithCache$ = function (pid) {
                const hasIndexedDBSupport = IndexedDBService.isSupported();
                if (!hasIndexedDBSupport) {
                    console.warn(
                        "IndexedDB is not supported. Falling back to API only.",
                    );
                }

                const indexedDBObservable = hasIndexedDBSupport
                    ? from(IndexedDBService.getProceeding(pid)).pipe(
                          catchError((err) => {
                              console.error("Error accessing IndexedDB", err);
                              return EMPTY;
                          }),
                      )
                    : EMPTY;

                const apiObservable = from(proceedingService.get(pid)).pipe(
                    tap((proceeding) => {
                        if (hasIndexedDBSupport) {
                            IndexedDBService.updateProceeding(proceeding);
                        }
                    }),
                    catchError((err) => {
                        console.error(
                            "Error fetching proceeding from API",
                            err,
                        );
                        return EMPTY; // You might want to handle this differently depending on your error handling strategy
                    }),
                );

                return concat(indexedDBObservable, apiObservable).pipe(
                    finalize(() =>
                        console.log("Completed fetching proceeding"),
                    ),
                );
            };

            /**
             * List all contacts by cids.
             */
            proceedingService.batchGet = (pids) => {
                if (!pids) return [];

                let deferred = $q.defer();
                let promises = [];
                for (let pid of pids) {
                    promises.push(api.get(`proceedings/${pid}`));
                }
                $q.all(promises)
                    .then((results) => {
                        let proceedings = [];
                        for (let result of results) {
                            proceedings.push(result.data.items[0]);
                        }
                        deferred.resolve(proceedings);
                    })
                    .catch((error) => {
                        console.log(error);
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            /**
             * Find workshop by parent pid and workshop pid
             * @param parentPid
             * @param workshopPid
             */
            proceedingService.getWorkshop = (parentPid, workshopPid) =>
                api
                    .get(`proceedings/${parentPid}/workshops/${workshopPid}`)
                    .then(firstItem);

            /**
             * Find workshops by parent pid
             * @param parentPid
             */
            proceedingService.getWorkshops = (parentPid) =>
                api.get(`proceedings/${parentPid}/workshops`).then(items);

            /**
             * Batch get workshops by parent pid and workshop pids
             * @param parentPid
             * @param workshopPid
             */
            proceedingService.batchGetWorkshops = (parentPid, workshopPids) => {
                if (!parentPid) throw "Parent PID is required.";
                if (!workshopPids) throw "Workshop PIDs are required.";
                let deferred = $q.defer();
                let promises = [];
                for (let workshopPid of workshopPids) {
                    promises.push(
                        api.get(
                            `proceedings/${parentPid}/workshops/${workshopPid}`,
                        ),
                    );
                }
                $q.all(promises)
                    .then((results) => {
                        let proceedings = [];
                        for (let result of results) {
                            proceedings.push(result.data.items[0]);
                        }
                        deferred.resolve(proceedings);
                    })
                    .catch((error) => {
                        console.log(error);
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            /**
             * Batch update workshop contacts.
             * @param parentPid
             */
            proceedingService.batchUpdateWorkshopContacts = (parentPid) => {
                if (!parentPid) throw "Parent PID is required.";
                proceedingService
                    .get(parentPid)
                    .then((proceeding) => {
                        if (!proceeding.workshops) {
                            return true;
                        } else {
                            let promises = proceeding.workshops
                                // Only try to update workshops with a pid
                                .filter((w) => w.pid)
                                // Make an array of GET calls for the workshops
                                .map((w) => {
                                    return api.get(
                                        `proceedings/${parentPid}/workshops/${w.pid}`,
                                    );
                                });
                            // Get the workshops
                            return $q.all(promises).then((results) => {
                                // Extract the workshops
                                let workshops = results.map(
                                    (r) => r.data.items[0],
                                );
                                // Update the contacts from the parent proc
                                workshops = workshops.map((w) => {
                                    w.contacts = proceeding.contacts;
                                    return w;
                                });

                                return proceedingService.batchUpdate(workshops);
                            });
                        }
                    })
                    .catch((e) => console.log(e));
            };

            /**
             * Insert a new proceeding.
             * @param proceeding
             */
            proceedingService.create = (proceeding) =>
                api
                    .post("proceedings", proceeding)
                    .then((result) => result.data.pid);

            /**
             * Insert new proceedings.
             * @param proceedings
             */
            proceedingService.batchCreate = (proceedings) => {
                let deferred = $q.defer();

                let promises = [];

                for (let p of proceedings) {
                    promises.push(api.post("proceedings", p));
                }

                $q.all(promises)
                    .then((results) => {
                        let pids = [];
                        for (let result of results) {
                            pids.push(result.data.pid);
                        }
                        deferred.resolve(pids);
                    })
                    .catch((error) => {
                        console.log(error);
                        deferred.reject(error);
                    });

                return deferred.promise;
            };

            /**
             * Update a proceeding by pid.
             * @param pid
             * @param proceeding: An updated proceeding
             */
            proceedingService.update = (pid, proceeding) => {
                return api
                    .put(`proceedings/${pid}`, proceeding)
                    .then((result) => result.data.pid);
            };

            /**
             * Update an array of proceedings.
             * @param proceedings
             */
            proceedingService.batchUpdate = (proceedings) => {
                let promises = proceedings.map((p) => {
                    return proceedingService.update(p.pid, p);
                });

                return $q
                    .all(promises)
                    .then(() => {
                        return true;
                    })
                    .catch((error) => {
                        console.log(error);
                        throw error;
                    });
            };

            /**
             * Load a default publication agreement
             */
            proceedingService.defaultPublicationAgreement = () =>
                publicationAgreementService.get();

            /**
             * Get Toc by pid
             * @param pid
             */
            proceedingService.getToc = (pid) =>
                api
                    .get(`proceedings/${pid}`)
                    .then(firstItem)
                    .then((item) => item.toc || {});

            proceedingService.updateToc = (pid, toc) =>
                proceedingService.update(pid, { toc });

            /**
             * Update proceeding settings
             */
            proceedingService.updateProceedingSettings = (
                pid,
                copyrightType,
                toc,
            ) => {
                return proceedingService.update(pid, {
                    "configuration.settings.ak.copyright.type": copyrightType,
                    toc: toc,
                });
            };

            /**
             * Deactivate a proceeding
             * @param pid
             */
            proceedingService.archive = (pid) =>
                api.delete(`proceedings/${pid}`);

            /*
            Endpoint for downloading proceeding metadata
             */
            proceedingService.downloadMetadata = (proceeding) => {
                const pid = proceeding.pid;
                return $window.open(`proceedings/${pid}/metadata`);
            };

            return proceedingService;
        },
    ]);
})();
