(function() {
    "use strict";

    angular.module("cpir").filter("proceedingRoleFilter", [
        "ProceedingRoleService",
        roleService => {
            return function(proceeding, action) {
                if (!proceeding || !roleService.getRole(proceeding))
                    return false;

                let isEditorOrganizer = roleService.isEditorOrOrganizer(
                    proceeding
                );
                let isEditor = roleService.isEditor(proceeding);

                switch (action) {
                    case "quote-summary":
                        return isEditorOrganizer && proceeding;
                    case "compliance-review":
                        return isEditorOrganizer && proceeding;
                    // case 'author-kit':
                    //     return isEditorOrganizer && proceeding;
                    case "production":
                        return isEditorOrganizer && proceeding;
                    case "configuration":
                        return isEditor && proceeding;
                    case "select-quote":
                        return isEditorOrganizer && proceeding;
                    default:
                        return proceeding;
                }
            };
        }
    ]);
})();
