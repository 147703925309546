(function() {
    "use strict";

    angular
        .module("cpir")
        .filter("proceedingStepToNameFilter", ProceedingStatusService => {
            let steps = ProceedingStatusService.getSteps();
            return step => {
                if (!step) return step;
                let stepObject = steps.find(s => s.key === step);
                if (stepObject) return stepObject.name;
                else return step;
            };
        });
})();
