(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCWebpubDownloadController",

        class {
            constructor(pid, $window, WebpubService) {
                this.pid = pid;
                this.$window = $window;
                this.WebpubService = WebpubService;
            }

            $onInit() {
                this.downloadUrl = this.WebpubService.getDownloadLink(this.pid);
                this.$window.open(this.downloadUrl, "_self");

                // attempt to close the window after 10 seconds if the window was opened automatically
                setTimeout(() => {
                    this.$window.close();
                }, 1000 * 10);
            }
        }
    );
})();
