(function() {
    "use strict";

    angular.module("cpir").factory("dashUnitPricesService", [
        "unitPricesService",
        "moment",
        "$q",
        function(unitPricesApi, moment, $q) {
            let model = {};

            let cachedPrices;
            let cacheTime;

            model.newPrintRate = () =>
                angular.copy({
                    trimSize: 0, //select
                    paperType: "bw",
                    minPages: 0,
                    maxPages: 0,
                    rate: 0.0
                });

            model.newDiskPackagingRate = () => {
                return { name: "", rate: 0 };
            };

            model.newPrintCover = () => angular.copy({ hard: 0, soft: 0 });
            model.newUnitPrices = () => {
                if (
                    !cachedPrices ||
                    !cacheTime ||
                    cacheTime.isBefore(moment().subtract(5, "seconds"))
                ) {
                    cacheTime = moment();
                    cachedPrices = unitPricesApi.get().then(prices => {
                        cacheTime = moment();
                        return prices;
                    });
                }
                return cachedPrices;
            };

            model.getField = (media, name) => {
                if (media.fields === undefined) return undefined;
                return media.fields.find(field => field.name === name);
            };

            model.getFieldOptionText = (media, name) => {
                let field = model.getField(media, name);
                return field.options.find(
                    option => option.value === field.value
                ).name;
            };

            //these are on service rather than any created models since copying will mess up the references
            model.print = {};
            model.print.addRate = (prices, mid) =>
                prices[mid].rates.push(model.newPrintRate());
            model.print.removeRate = (prices, mid, rateToRemove) =>
                (prices[mid].rates = prices[mid].rates.filter(
                    rate => rate !== rateToRemove
                ));
            model.print.addCoverRate = (prices, mid) =>
                prices[mid].coverRates.push(model.newPrintCover());
            model.print.removeCoverRate = (prices, mid, rateToRemove) =>
                (prices[mid].coverRates = prices[mid].coverRates.filter(
                    rate => rate !== rateToRemove
                ));
            model.print.initUnitPrice = (prices, mid) => {
                prices[mid] = prices[mid] || {
                    unitPriceAdjustment: 0,
                    hasUnitPriceOverride:false,
                    overridePrice: 0,
                    rates: [],
                    coverRates: []
                };
                return prices[mid];
            };

            model.disk = {
                addPackagingRate: prices =>
                    prices.diskPackagingRates.push(
                        model.newDiskPackagingRate()
                    ),
                removePackagingRate: (prices, rateToRemove) =>
                    (prices.diskPackagingRates = prices.diskPackagingRates.filter(
                        rate => rate !== rateToRemove
                    ))
            };

            model.misc = {
                addBulkDiscount: prices =>
                    prices.bulkDiscounts.push({
                        name: "New Bulk Discount",
                        min: 0,
                        max: 0,
                        percentage: 0
                    }),
                removeBulkDiscount: (prices, rateToRemove) =>
                    (prices.bulkDiscounts = prices.bulkDiscounts.filter(
                        rate => rate !== rateToRemove
                    ))
            };

            return model;
        }
    ]);
})();
