(function () {
    "use strict";

    angular
        .module("cpir")
        .directive("lengthvalidation", function ($q, $timeout) {
            return {
                require: 'ngModel',

                link: function (scope, element, attrs, ngModel) {

                    ngModel.$parsers.unshift(function (value) {

                            const field = ngModel.$name;

                            if(value !== "") {
                                    if (value.length < attrs.minLength || value.length > attrs.maxLength) {
                                        ngModel.$setValidity(field, false);
                                        return value;
                                    } else {
                                        ngModel.$setValidity(field, true);
                                        return value;
                                    }
                            } else {
                                    ngModel.$setValidity(field, true);
                                    return value;
                                }
                            });
                    }
            }
                });


          //  };
       // });
})();
