(function() {
    "use strict";

    angular.module("cpir").component("cpirCifConfSettingsPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/cif-conf-settings-panel/cif-conf-settings-panel.component.html",
        controller: class {
            constructor(
                authService,
                ConfigurationService,
                NotificationService
            ) {
                this.authService = authService;
                this.ConfigurationService = ConfigurationService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.showConfiguration("cif.conference");

                this.brand = this.authService.getBrand().name;

                this.getUpdatedConfiguration();
            }

            onCategorySelect(category) {
                switch (category) {
                    case "settings":
                        this.showConfiguration("settings.login");
                        break;
                    case "defaults":
                        this.showConfiguration("defaults.globals");
                        break;
                }
            }

            showConfiguration(status) {
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }

            getUpdatedConfiguration() {
                this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        this.configuration = result.configuration.cps;
                    else if (this.brand === "pubhub")
                        this.configuration = result.configuration.pubhub;
                });
            }

            saveConfiguration(configuration) {
                this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        result.configuration.cps = configuration;
                    else if (this.brand === "pubhub")
                        result.configuration.pubhub = configuration;

                    this.ConfigurationService.update(result).then(() => {
                        this.NotificationService.send(
                            "success",
                            "The configuration settings were successfully saved."
                        );
                        this.getUpdatedConfiguration();
                    });
                });
            }
        }
    });
})();
