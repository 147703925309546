(function() {
    "use strict";

    /*
   Service for inflating date strings of pure dates, and getting the pure date for a date
   */

    angular.module("cpir").provider(
        "PureDateService",
        class {
            constructor() {}
            $get(moment) {
                function isPure(date) {
                    return moment
                        .utc(date)
                        .startOf("day")
                        .isSame(date);
                }

                return {
                    inflate: function(date) {
                        const d = isPure(date)
                            ? moment.utc(date)
                            : moment(date);
                        return new Date(d.year(), d.month(), d.date());
                    },

                    get: function(date) {
                        return isPure(date) ? moment.utc(date) : moment(date);
                    },

                    purify: function(date) {
                        return moment(date)
                            .utcOffset(0, true)
                            .toDate();
                    },

                    today: function() {
                        return moment
                            .utc()
                            .startOf("day")
                            .toDate();
                    },

                    format(date, format) {
                        const d = isPure(date)
                            ? moment.utc(date)
                            : moment(date);
                        return d.format(format);
                    },

                    getYear(date) {
                        return moment.utc(date).year();
                    }
                };
            }
        }
    );
})();
