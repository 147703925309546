(function() {
    "use strict";

    angular
        .module("cpir")
        .controller("AuthCreateAccountController", [
            "$stateParams",
            "authService",
            "TosService",
            "NotificationService",
            AuthCreateAccountController
        ]);

    function AuthCreateAccountController(
        $stateParams,
        authService,
        TosService,
        NotificationService
    ) {
        let $ctrl = this;

        if ($stateParams.email) {
            $ctrl.email = $stateParams.email;
        }

        $ctrl.createAccount = () => {
            TosService.recordAcceptance($ctrl.email)
                .catch(error => {
                    console.log(error);
                    NotificationService.send(
                        "warning",
                        "There was a problem with the IEEE TOS Service"
                    );
                })
                .then(() => authService.signup($ctrl.email, $ctrl.password))
                .catch(error => {
                    $ctrl.invalidCredentials = true;
                    $ctrl.message = getAuth0ErrorMessage(error);
                });
        };

        $ctrl.listenForEnter = $event => {
            if ($event.which === 13 && $ctrl.createAccountForm.$valid) {
                $ctrl.login();
            }
        };
    }

    function getAuth0ErrorMessage(error) {
        switch (error.code) {
            case "user_exists":
                return "That e-mail address is already registered.";
        }
        if (error.message === "password is required") {
            return "Please enter a password.";
        }
        if (error.message === "email is required") {
            return "Please enter an e-mail address.";
        }
    }
})();
