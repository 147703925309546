(function() {
    "use strict";

    angular.module("cpir").component("cpirTocConfirmArchiveEntryModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/confirm-archive-entry-modal/confirm-archive-entry-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            saveButtonClicked() {
                this.close({ $value: 1 });
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
