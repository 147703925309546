(function() {
    "use strict";

    angular.module("cpir").component("cpirConfUserListPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/conf-user-list-panel/conf-user-list-panel.component.html",
        controller: class {
            // Inject $uibModal and UserListService
            constructor($uibModal, UserListService, NotificationService) {
                this.$uibModal = $uibModal;
                this.UserListService = UserListService;
                this.selectedUsersToArchive = {};
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.showArchivedUsers = false;
                this.sortType = "email";
                this.sortReverse = false;

                this.panelName = null;

                this.userList = [];

                this.UserListService.getStrings({ type: 1 }).then(strings => {
                    this.panelName = strings.panelName;
                });
                this.listUsers();
            }

            addUser() {
                this.$uibModal
                    .open({
                        size: "lg",
                        component: "cpirUserListAddUserModal",
                        resolve: {
                            role: () => "add",
                            modalTitle: () => "Add User"
                        }
                    })
                    .result.then(user => {
                        this.saveUser(user);
                    })
                    .catch(() => {});
            }

            editUser(user) {
                this.$uibModal
                    .open({
                        size: "lg",
                        component: "cpirUserListAddUserModal",
                        resolve: {
                            user,
                            modalTitle: () => "Edit User"
                        }
                    })
                    .result.then(user => {
                        this.saveUser(user);
                    })
                    .catch(() => {});
            }

            saveUser(user) {
                if (!user) return;
                if (!user.userId) {
                    // Create new user
                    return this.UserListService.create(user)
                        .then(createdUser => {
                            // Assuming the 'create' method returns the created user
                            this.userList.push(createdUser);
                            this.NotificationService.send(
                                "success",
                                "User successfully created"
                            );
                        })
                        .catch(error => {
                            console.error("Failed to create user", error);
                            this.NotificationService.send(
                                "danger",
                                "Failed to create user"
                            );
                        });
                } else {
                    return this.UserListService.update(user.userId, user)
                        .then(updatedUser => {
                            // Assuming the 'update' method returns the updated user
                            let index = this.userList.findIndex(
                                u => u.userId === updatedUser.userId
                            );
                            if (index !== -1) {
                                this.userList[index] = updatedUser;
                            }
                            this.NotificationService.send(
                                "success",
                                "User successfully updated"
                            );
                        })
                        .catch(error => {
                            console.error("Failed to update user", error);
                            this.NotificationService.send(
                                "danger",
                                "Failed to update user"
                            );
                        });
                }
            }

            archiveUsers() {
                Promise.all(
                    _.keys(this.selectedUsersToArchive).map(userId => {
                        return this.UserListService.archive(userId).catch(
                            error => {
                                console.error(
                                    `Failed to archive user ${userId}`,
                                    error
                                );
                            }
                        );
                    })
                )
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "User(s) successfully archived"
                        );
                        this.selectedUsersToArchive = {};
                    })
                    .catch(error => {
                        console.error("Failed to archive user(s)", error);
                        this.NotificationService.send(
                            "danger",
                            "Failed to archive user(s)"
                        );
                    })
                    .finally(() => {
                        this.listUsers();
                    });
                // remove archived users from the list immediately
                this.userList = this.userList.filter(
                    user => !this.selectedUsersToArchive[user.userId]
                );
            }

            restoreUsers() {
                Promise.all(
                    _.keys(this.selectedUsersToArchive).map(userId =>
                        this.UserListService.restore(userId).catch(error => {
                            console.error(
                                `Failed to restore user ${userId}`,
                                error
                            );
                        })
                    )
                )
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "User(s) successfully restored"
                        );
                        this.selectedUsersToArchive = {};
                    })
                    .catch(error => {
                        console.error("Failed to restore user(s)", error);
                        this.NotificationService.send(
                            "danger",
                            "Failed to restore user(s)"
                        );
                    })
                    .finally(() => {
                        this.listUsers();
                    });
                // remove restored users from the list immediately
                this.userList = this.userList.filter(
                    user => !this.selectedUsersToArchive[user.userId]
                );
            }

            toggleSelectAll() {
                if (this.areAllUsersSelected()) {
                    this.selectedUsersToArchive = {};
                } else {
                    this.userList.forEach(user => {
                        if (this.showArchivedUsers && user.status === 1) {
                            return;
                        }
                        this.selectedUsersToArchive[user.userId] = true;
                    });
                }
            }

            areAllUsersSelected() {
                return this.userList.every(user => {
                    if (this.showArchivedUsers && user.status === 1) {
                        return true;
                    }
                    return this.selectedUsersToArchive[user.userId];
                });
            }

            toggleShowArchivedUsers() {
                this.showArchivedUsers = true;
                this.listUsers();
            }

            listUsers() {
                return this.UserListService.list({
                    type: 1,
                    showArchivedUsers: this.showArchivedUsers
                })
                    .then(response => {
                        this.userList = response || [];
                        if (this.showArchivedUsers) {
                            // Filter out archived users if showArchivedUsers is false
                            this.userList = this.userList.filter(
                                user => user.status !== 1
                            );
                        }
                    })
                    .catch(error => {
                        console.error("Failed to fetch user list", error);
                    });
            }
        }
    });
})();
