(function() {
    "use strict";

    angular
        .module("cpir")
        .controller(
            "DashboardEditorTOCRepoController",
            DashboardEditorTOCRepoController
        );

    function DashboardEditorTOCRepoController() {
        let $ctrl = this;

        $ctrl.date = new Date();
    }
})();
