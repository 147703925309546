(function() {
    angular.module("cpir").component("cpirComplianceChairSwitcher", {
        bindings: {
            chairs: "<",
            activeChairIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/chair-switcher/chair-switcher.component.html",
        controller: function() {
            let $ctrl = this;

            $ctrl.buttonClicked = $index => {
                $ctrl.onSwitch({ chairIndex: $index });
            };
        }
    });
})();
