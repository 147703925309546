(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardEditorPaProductionSchedule", {
            bindings: {
                title: "<",
                proceeding: "<",
                quote: "<",
                onUpdate: "&"
            },
            templateUrl:
                "application/cpir/dashboard/editor/publication-agreement/components/" +
                "production-schedule/production-schedule.component.html",
            controller: class ProductionSchedule {
                constructor($scope, authService, moment, PureDateService) {
                    this.$scope = $scope;
                    this.authService = authService;
                    this.moment = moment;
                    this.PureDateService = PureDateService;
                    this.activated = true;
                    this.proceeding = null;
                }

                $onInit() {
                    this.brand = this.authService.getBrand();
                    this.activated = false;
                }

                $onChanges({ title: titleChanges }) {
                    const { currentValue: currentTitle } = titleChanges || {};
                    if (!this.activated && this.quote && this.proceeding) {
                        this.updateSchedule();
                        this.activated = true;
                    }
                    if (currentTitle === "Production Schedule") {
                        this.updateSchedule();
                    }
                }

                modelChanged() {
                    this.updateSchedule();
                }

                toggleOpen(idx) {
                    this[idx] = this[idx] || { opened: false };
                    this[idx].opened = !this[idx].opened;
                }

                getNewProductionSchedule(productionScheduleType, isSponsored) {
                    const schedule = [
                        {
                            name: "Compliance Information Due",
                            id: "complianceDue",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored"]
                            },
                            timing: { standard: -24, eOnly: -24 }
                        },
                        {
                            name:
                                "Author Submission Site Available for Approval",
                            id: "submissionSiteApproval",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -12, eOnly: -9 }
                        },
                        {
                            name: "Authors Notified of Final Acceptance",
                            id: "acceptanceNotification",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -10, eOnly: -7 }
                        },
                        {
                            name: "Front Matter/Cover Logos and Images Due",
                            id: "mediaDue",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -9, eOnly: -6 }
                        },
                        {
                            name:
                                "Camera-Ready Paper Submissions and Copyrights Due",
                            id: "submissionsDue",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -8, eOnly: -5 }
                        },
                        {
                            name: "Organizer Review of Publication",
                            id: "organizerReview",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -5, eOnly: -2 }
                        },
                        {
                            name: "Organizer Approval of Publication",
                            id: "organizerApproval",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -4, eOnly: -1 }
                        },
                        {
                            name: "Publication Press Date",
                            id: "pressDate",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -4, eOnly: 0 }
                        },
                        {
                            name: "Publication Ship Date",
                            id: "shipDate",
                            filters: {
                                productionScheduleType: ["standard"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: -2, eOnly: 0 }
                        },
                        {
                            name: "Conference Receives Publication",
                            id: "receiveDate",
                            filters: {
                                productionScheduleType: ["standard", "eOnly"],
                                complianceStatus: ["nonSponsored", "sponsored"]
                            },
                            timing: { standard: 0, eOnly: 0 }
                        }
                    ];
                    const sponsoredType = isSponsored
                        ? "sponsored"
                        : "nonSponsored";

                    const filteredSchedule = schedule.filter(
                        event =>
                            _.includes(
                                event.filters.productionScheduleType,
                                productionScheduleType
                            ) &&
                            _.includes(
                                event.filters.complianceStatus,
                                sponsoredType
                            )
                    );
                    const { delivery } =
                        (this.proceeding && this.proceeding.dates) || {};
                    const deliveryDate = this.fixWeekend(
                        delivery
                            ? this.PureDateService.get(delivery)
                            : this.PureDateService.today()
                    );

                    return filteredSchedule.map(({ name, id, timing }) => {
                        // get the date for the event
                        const date = this.fixWeekend(
                            this.PureDateService.get(deliveryDate).add(
                                timing[productionScheduleType],
                                "weeks"
                            )
                        );

                        // apply the final event fields
                        return {
                            name,
                            id,
                            date,
                            dateModel: this.PureDateService.inflate(date),
                            enabled: true
                        };
                    });
                }

                fixWeekend(date) {
                    if (this.PureDateService.get(date).day() === 0) {
                        //sunday
                        return date.subtract(2, "days");
                    }
                    if (this.PureDateService.get(date).day() === 6) {
                        //saturday
                        return date.subtract(1, "days");
                    }
                    return date;
                }

                addEvent() {
                    const { delivery } =
                        (this.proceeding && this.proceeding.dates) || {};
                    this.proceeding.productionSchedule.push({
                        name: "",
                        date: new Date(delivery),
                        dateModel: this.PureDateService.inflate(delivery),
                        enabled: true
                    });
                }

                inflateDate(record) {
                    const { dateModel, date } = record;
                    record.dateModel =
                        (!dateModel || typeof dateModel === "string") && date
                            ? this.PureDateService.inflate(date)
                            : dateModel;
                }

                updateDates() {
                    if (this.proceeding) {
                        const { productionSchedule } = this.proceeding;
                        productionSchedule.forEach(record => {
                            this.inflateDate(record);
                            record.date = this.PureDateService.purify(
                                record.dateModel
                            );
                        });
                    }
                }

                updateSchedule() {
                    if (!_.isEmpty(this.proceeding.productionSchedule)) {
                        this.updateDates();
                    } else {
                        this.initSchedule();
                    }
                }

                isEOnly(quote) {
                    const quoteHasPhysicalMedia = _.some(quote.cart, cart =>
                        _.some(cart.products, product => {
                            switch (product.mid) {
                                case "BOOK":
                                case "ABSTRACT_BOOK":
                                case "PRINT_PROGRAM":
                                case "CDROM":
                                case "DVD":
                                case "USB_DRIVE":
                                    return true;
                                default:
                                    return false;
                            }
                        })
                    );
                    return !quoteHasPhysicalMedia;
                }

                initSchedule() {
                    if (!this.quote || !this.proceeding) {
                        throw new Error(
                            "Proceeding and quote must be loaded for production schedule initialization."
                        );
                    }
                    const isEOnly = this.isEOnly(this.quote);
                    const productionScheduleType = isEOnly
                        ? "eOnly"
                        : "standard";

                    this.proceeding.productionSchedule = this.getNewProductionSchedule(
                        productionScheduleType,
                        this.proceeding.isSponsored
                    );
                    console.log("Created new production schedule");
                }
            }
        });
})();
