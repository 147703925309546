(function() {
    "use strict";

    angular.module("cpir").component("akFileDownloadLink", {
        bindings: {
            file: "<",
            extraText: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/file-download-link/file-download-link.component.html",
        controller: class {
            constructor(NotificationService, FileService) {
                this.NotificationService = NotificationService;
                this.FileService = FileService;
            }

            $onInit() {}

            downloadFile() {
                this.FileService.getFile(this.file.vid);
            }
        }
    });
})();
