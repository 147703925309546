(function() {
    "use strict";

    angular.module("cpir").component("cpirProceedingConfStatusPanel", {
        bindings: {
            proceeding: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/proceeding-conf-status-panel/proceeding-conf-status-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                authService,
                UserService,
                ProceedingStatusService,
                ProceedingService,
                NotificationService
            ) {
                this.$stateParams = $stateParams;
                this.authService = authService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.ProceedingService = ProceedingService;
                this.NotificationService = NotificationService;
                this.UserService = UserService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.statusForm = {};

                this.stepOptions = this.ProceedingStatusService.getSteps();

                // Get the editors
                this.UserService.list({ onlyEditors: true }).then(users => {
                    // Filter the list of users and then map
                    // only needed attributes
                    this.editors = users
                        .filter(e => {
                            return (
                                (e.roles.editor || e.roles.vendor) &&
                                e.name &&
                                this.authService
                                    .getBrand()
                                    .name.toUpperCase() === e.roles.brand
                            );
                        })
                        .map(e => {
                            let _e = {};
                            _e.email = e.email;
                            _e.name = e.name || e.email;
                            _e.title = e.title;
                            _e.division = e.division;
                            _e.company = e.company;
                            _e.address1 = e.address1;
                            _e.address2 = e.address2;
                            _e.phone = e.phone;
                            _e.fax = e.fax;
                            return _e;
                        });
                    if (this.editors.length < 1) {
                        this.NotificationService.send(
                            "danger",
                            'No editors have been configured with "name" and contact info'
                        );
                    }
                });
            }

            exportConferenceMetadata() {
                return this.ProceedingService.downloadMetadata(this.proceeding);
            }

            saveChanges() {
                this.onSave({
                    props: {
                        configuration: this.proceeding.configuration,
                        editor: this.proceeding.editor
                    }
                });
            }
        }
    });
})();
