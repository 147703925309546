(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("cpirAkApprovalModalLink", cpirAkApprovalModalLink);

    function cpirAkApprovalModalLink(
        $uibModal,
        $state,
        $stateParams,
        ProceedingService,
        ProceedingStatusService
    ) {
        return {
            restrict: "E",
            replace: true,
            templateUrl:
                "application/cpir/authorkit/_shared/components/approval-modal-link/approval-modal-link.directive.html",
            scope: true,
            controllerAs: "$ctrl",
            // link: function (scope) {
            //     scope.linkClicked = () => {
            //         $uibModal.open({
            //             component: 'cpirAkApprovalModal'
            //         });
            //     };
            // },
            controller: function() {
                let $ctrl = this;

                $ctrl.pid = $stateParams.pid;

                ProceedingService.get($ctrl.pid).then(proceeding => {
                    $ctrl.proceeding = proceeding;
                });

                $ctrl.isLinkEnabled = () => {
                    if (!$ctrl.proceeding) return false;
                    if ($state.is("ak.approval-acceptance")) return false;
                    return ProceedingStatusService.isAkPending(
                        $ctrl.proceeding
                    );
                };

                $ctrl.linkClicked = () => {
                    $uibModal.open({
                        component: "cpirAkApprovalModal"
                    });
                };
            }
        };
    }
})();
