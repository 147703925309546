(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirWorkshopConfProductionSchedulePanel", {
            bindings: {
                proceeding: "<"
            },
            templateUrl:
                "application/cpir/dashboard/editor/proceeding-configuration/components/workshop-conf-production-schedule-panel/workshop-conf-production-schedule-panel.component.html",
            controller: class {
                constructor(
                    NotificationService,
                    ProceedingService,
                    QuoteService
                ) {
                    this.NotificationService = NotificationService;
                    this.ProceedingService = ProceedingService;
                    this.QuoteService = QuoteService;
                }

                $onInit() {
                    if (this.proceeding) {
                        console.log(
                            `loading parent proceeding quote (pid ${this.proceeding.parent}`
                        );
                        this.QuoteService.byPid(this.proceeding.parent).then(
                            quote => (this.quote = quote)
                        );
                    }
                }

                saveChanges() {
                    this.ProceedingService.update(
                        this.proceeding.pid,
                        this.proceeding
                    ).then(() => {
                        this.NotificationService.send(
                            "success",
                            "The settings were successfully saved."
                        );
                    });
                }
            }
        });
})();
