(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardOrganizerContractTerms", {
        bindings: {
            sections: "<",
            data: "<"
        },
        templateUrl:
            "application/cpir/dashboard/organizer/quote/components/" +
            "contract-terms/contract-terms.component.html",
        controller: class {}
    });
})();
