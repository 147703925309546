(function () {
    "use strict";

    angular
        .module("cpir")
        .directive("orcidvalidation", function ($q, $timeout) {
            return {
                require: 'ngModel',

                link: function (scope, element, attrs, ngModel) {

                    ngModel.$parsers.unshift(function (value) {

                        const field = ngModel.$name;

                        if(value !== "") {

                                const orcidRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{3}[\\dX]$");

                                if (value.length !== 19) {
                                    ngModel.$setValidity('authorOrcId', false);
                                    return value;
                                } else {
                                    if (!orcidRegex.test(value)) {
                                        ngModel.$setValidity('authorOrcId', false);
                                        return value;
                                    } else {
                                        ngModel.$setValidity('authorOrcId', true);
                                        return value;
                                    }
                                }
                        } else {
                            ngModel.$setValidity(field, true);
                            return value;
                        }
                    });
                }
            }
        });
})();
