(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceMembersPanel", {
        bindings: {
            members: "<",
            isErrorsVisible: "<",
            isDisabled: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/members-panel/members-panel.component.html",
        controller: class {
            constructor($scope) {
                this.$scope = $scope;
            }

            $onInit() {
                if (_.isEmpty(this.members)) {
                    let initialMember = {};
                    this.members = [initialMember];
                }

                this.activateMember(0);
            }

            $onChanges(changes) {
                if (changes.members && changes.members.currentValue) {
                    this.activateMember(0);
                }
            }

            addMember() {
                this.members.push({});
                this.activateMember(this.members.length - 1);
            }

            removeActiveMember() {
                this.members.splice(this.activeMemberIndex, 1);
                this.activateMember(this.activeMemberIndex - 1);
            }

            activateMember(memberIndex) {
                this.activeMemberIndex = memberIndex;
                this.activeMember = this.members[memberIndex];
            }

            updateMember(member) {
                this.activeMember = member;
                this.modelChanged();
            }

            modelChanged() {
                this.onUpdate({ members: this.members });
            }
        }
    });
})();
