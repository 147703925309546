(function() {
    "use strict";

    angular.module("cpir").component("missingEmailsPanel", {
        bindings: {
            resolve: "<",
            entries: "<",
            close: "&",
        },
        templateUrl:
            "application/cpir/dashboard/editor/_shared/components/missing-emails-panel/missing-emails-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                console.log("printing no emails");
console.log(this.resolve);
                if (this.resolve) {
                    this.noSubmitter = this.resolve.noSubmitter;
                    this.noAuthors = this.resolve.noAuthors;
                }
            }

            cancelButtonClicked() {
                this.close();
            }

        }
    });
})();
