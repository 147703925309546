/**
 * Client side full text search for proceedings
 */
(function() {
    "use strict";

    // hyperparameters
    const ACRONYM_EDIT_DISTANCE = 1;
    const TITLE_EDIT_DISTANCE = 1;
    const REMOVE_ACRONYM_STOP_WORDS = false;
    const STEM_ACRONYM = false;
    const ACRONYM_BOOST = 2;
    const REMOVE_TITLE_STOP_WORDS = true;
    const STEM_TITLE = true;
    const TITLE_BOOST = 1;
    const YEAR_BOOST = 15;
    const YEAR_AS_FILTER = true;

    angular.module("cpir").provider(
        "ProceedingSearchService",
        class {
            $get(SearchUtilsService) {
                const { Matcher, Filter, SearchFactory } = SearchUtilsService;

                const globalYearRegex = /(19|20)\d{2}/g;
                const yearRegex = /(19|20)\d{2}/;
                const globalPidRegex = /[a-zA-Z0-9]{18,22}/g;
                const pidRegex = /[a-zA-Z0-9]{18,22}/;

                function makeIndex(proceedings) {
                    // matchers defined here
                    const matchers = [
                        new Matcher({
                            termResolver: ({ acronym }) =>
                                SearchFactory.removeRegexp(acronym, [
                                    globalYearRegex
                                ]),
                            boost: ACRONYM_BOOST,
                            maxEditDistance: ACRONYM_EDIT_DISTANCE,
                            removeStopwords: REMOVE_ACRONYM_STOP_WORDS,
                            stem: STEM_ACRONYM,
                            label: "acronym"
                        }),
                        new Matcher({
                            termResolver: ({ title }) =>
                                SearchFactory.removeRegexp(title, [
                                    globalYearRegex
                                ]),
                            boost: TITLE_BOOST,
                            maxEditDistance: TITLE_EDIT_DISTANCE,
                            removeStopwords: REMOVE_TITLE_STOP_WORDS,
                            stem: STEM_TITLE,
                            label: "title"
                        }),
                        new Matcher({
                            termResolver: ({ icxId }) =>
                                (icxId && icxId.toString()) || null,
                            boost: 10,
                            maxEditDistance: 0,
                            removeStopwords: false,
                            stem: false,
                            lowerCase: false,
                            label: "icxId"
                        })
                    ];

                    if (!YEAR_AS_FILTER) {
                        matchers.push(
                            new Matcher({
                                termResolver: ({ year }) => year.toString(),
                                maxEditDistance: 0,
                                boost: YEAR_BOOST,
                                label: "year"
                            })
                        );
                    }

                    // filters defined here
                    const filters = [
                        new Filter({
                            path: "pid",
                            termRegexp: pidRegex
                        })
                    ];
                    if (YEAR_AS_FILTER) {
                        filters.push(
                            new Filter({
                                path: "year",
                                termRegexp: yearRegex
                            })
                        );
                    }

                    const searchInstance = new SearchFactory({
                        documents: proceedings,
                        keyResolver: "pid",
                        matchers,
                        filters,
                        searchRemoveRegexp: [globalYearRegex, globalPidRegex]
                    });

                    return {
                        search: function(query) {
                            const searchResults = searchInstance.search(query);

                            // attach score to decorations
                            return searchResults.map(({ score, document }) => {
                                document.decorations.score = score;
                                return document;
                            });
                        },

                        tokenizeAndStem: function(query) {
                            return SearchFactory.tokenizeAndStem(
                                query,
                                false,
                                false,
                                true
                            ).tokens();
                        }
                    };
                }

                return {
                    makeIndex
                };
            }
        }
    );
})();
