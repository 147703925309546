(function() {
    "use strict";

    angular.module("cpir").component("cpirEmailTemplateModal", {
        bindings: {
            size: "@",
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/_shared/components/email-template-modal/email-template-modal.component.html",
        controller: class {
            constructor(NotificationService, $uibModal) {
                this.NotificationService = NotificationService;
                this.$uibModal = $uibModal;
            }

            $onInit() {
                console.log("inside modal init");
                this.template = this.resolve.template;
                this.statusToChange = this.resolve.statusToChange?this.resolve.statusToChange:null;
                //If its from author comm then conditionally display the CC and BCC fields
                this.authorComm = this.resolve.authorComm;
                this.fromAddress = "no-reply-cps@computer.org";
                this.toAddress = "";
                this.ccAddress = this.resolve.editorEmail;
                this.bccAddress = "";
                this.toAddressList = [
                    { key: "submitterEmail", name: "Submitter"},
                    { key: "allAuthors", name: "All Authors"},
                    { key: "submitterAndAuthors", name: "Submitter and All Authors"},
                ];

                this.entryIds = this.resolve.entryIds;
                this.entryMap = this.resolve.entryMap;

                this.noAuthors = [];
                this.noSubmitter = [];

               if(this.entryIds && this.entryMap) {
                   this.entryIds.forEach((entryId) => {
                       // get the current entry out of the list
                       const entry = this.entryMap[entryId];
                       let authorEmailList = [];

                       entry.authors.forEach((author) => {
                          if (!author.email || (author.email && author.email === ""))
                               authorEmailList.push(author.email);
                       });
                       if(authorEmailList.length > 0)
                           this.noAuthors.push({eid: entry.eid, sequence: entry.sequence, title: entry.title, pageNum: entry.pageNumber});

                       if(!entry.submitterEmail || entry.submitterEmail === "")
                           this.noSubmitter.push({eid: entry.eid, sequence: entry.sequence, title: entry.title, pageNum: entry.pageNumber});
                   });
               }
            }

            updateTemplate(template) {
                this.template = template;
            }

            showMissingEmailPanel() {
                console.log("opening panel");
                this.$uibModal.open({
                    component: "missingEmailsPanel",
                    size: "lg",
                    resolve: {
                        "noAuthors": () => this.noAuthors,
                        "noSubmitter": () => this.noSubmitter
                    }
                });
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            sendButtonClicked() {
                console.log(this.toAddress);
                console.log(this.emailForm.$invalid);
                if(this.emailForm.$invalid)
                    this.NotificationService.send(
                        "warning",
                        "Please correct invalid fields"
                    );
                else if(this.toAddress === undefined || this.toAddress === null || this.toAddress === "") {
                    this.NotificationService.send(
                        "warning",
                        "Choose To address for the email"
                    );
                } else {
                    console.log("inside closing");
                    console.log(this.toAddress);
                    this.close({
                        $value: {
                            template: this.template,
                            fromAddress: this.fromAddress,
                            toAddress: this.toAddress,
                            ccAddress: this.ccAddress,
                            bccAddress: this.bccAddress
                        }
                    });
                }
            }
        }
    });
})();
