(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardOrganizerQuoteSectionHeader", {
            bindings: {
                sections: "<",
                proceedingTitle: "<",
                currentIndex: "<",
                showTitle: "<",
                showProgress: "<",
                onNextSection: "&",
                onPreviousSection: "&"
            },
            templateUrl:
                "application/cpir/dashboard/organizer/quote/components/section-header/section-header.component.html",
            controller: function() {
                let $ctrl = this;

                $ctrl.$onInit = () => {
                    $ctrl.showTitle = $ctrl.showTitle === undefined;
                    $ctrl.showProgress = $ctrl.showProgress === undefined;
                };

                $ctrl.setProgressTotal = () => {
                    if ($ctrl.sections && $ctrl.sections.length > 0)
                        $ctrl.progressTotal =
                            ($ctrl.currentIndex / ($ctrl.sections.length - 1)) *
                            100;
                    else $ctrl.progressTotal = 0;
                };

                $ctrl.$onChanges = changes => $ctrl.setProgressTotal();
            }
        });
})();
