(function () {
    "use strict";

    angular.module("cpir").controller(
        "AuthController",
        class {
            constructor(
                ak,
                pid,
                authService,
                storeDeepLink,
                PublicConfigurationService,
                Cpir2Service,
            ) {
                this.ak = ak;
                this.pid = pid;
                this.authService = authService;
                this.storeDeepLink = storeDeepLink;
                this.PublicConfigurationService = PublicConfigurationService;
                this.Cpir2Service = Cpir2Service;
                this.isHidden = true;
            }

            $onInit() {
                this.Cpir2Service.forwardToCPIR2LandingPage();

                this.brand = this.authService.getBrand().name;

                this.PublicConfigurationService.get(
                    this.ak ? this.pid : null,
                ).then((result) => {
                    if (this.brand === "cps")
                        this.configuration = result.configuration.cps;
                    else if (this.brand === "pubhub")
                        this.configuration = result.configuration.pubhub;

                    this.proceeding = result.proceeding;
                });
            }

            showAkInstructions() {
                return this.pid && this.ak;
            }
        },
    );
})();
