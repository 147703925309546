(function() {
    "use strict";

    angular.module("cpir").component("cpirTocProductionLogoModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/production-logo-modal/production-logo-modal.component.html",
        controller: class {
            constructor($stateParams, NotificationService, UploadService) {
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.UploadService = UploadService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
            }

            saveButtonClicked() {
                this.UploadService.uploadLogo(this.pid, this.logoFile)
                    .then(() => {
                        this.close({});
                    })
                    .catch(err => {
                        console.log(err);
                        this.NotificationService.send(
                            "warning",
                            "There was an error while completing the submission."
                        );
                    });
            }

            selectFile(file, invalidFile) {
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose an image file for this submission."
                    );
                } else {
                    this.fileDetails = {};
                    this.fileDetails.name = file.name;
                    this.fileDetails.size = file.size;
                }
            }

            removeFile() {
                if (this.logoFile) this.logoFile = null;
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
