(function() {
    "use strict";

    angular.module("cpir").component("missingSubmitterInfoPanel", {
        bindings: {
            missingSubmitterInfo: "<",
            includeExtras: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/missing-submitter-info-panel/missing-submitter-info-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                if(this.missingSubmitterInfo)
                    console.log(this.missingSubmitterInfo);
            }
        }
    });
})();
