(function() {
    angular.module("cpir").component("cpirDashboardEditorPaUnitPricesDigital", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/publication-agreement/components/unit-prices/unit-prices-digital.component.html",

        controller: [
            "dashPricingService",
            "dashUnitPricesService",
            function(dashPricingService, dashUnitPricesService) {
                let $ctrl = this;

                $ctrl.$onInit = () => {
                    $ctrl.unitPrices = $ctrl.resolve.quote.unitPrices;
                };

                $ctrl.addDiskPackagingRate = () =>
                    dashUnitPricesService.disk.addPackagingRate(
                        $ctrl.unitPrices
                    );
                $ctrl.removeDiskPackagingRate = packagingToRemove => {
                    if (confirm("Delete this packaging?") === true) {
                        dashUnitPricesService.disk.removePackagingRate(
                            $ctrl.unitPrices,
                            packagingToRemove
                        );
                    }
                };

                $ctrl.modelChanged = () => {
                    dashPricingService.calculatePrices($ctrl.resolve.quote);
                };

                $ctrl.getCdUnitPrice = () =>
                    dashPricingService.digital.getUnitPrice(
                        $ctrl.resolve.entry,
                        $ctrl.unitPrices
                    );

                $ctrl.ok = function() {
                    $ctrl.close({ $value: $ctrl.resolve.quote });
                };

                $ctrl.cancel = function() {
                    $ctrl.dismiss({ $value: "Cancel" });
                };
            }
        ]
    });
})();
