(function() {
    "use strict";

    /*
    The additional information service functions are invoked when the dashboard
    determines that an action must occur for a particular client, i.e.
    electronic signature, etc.
     */

    angular.module("cpir").provider(
        "ProceedingAdditionalInformationService",
        class {
            constructor() {}
            $get($uibModal, ProceedingService) {
                /**
                 * Invoked to bring up a modal that will capture some
                 * information after an organizer signs the PA.
                 * @param proceeding
                 */
                let getPaperTypeInfo = (pid, props) => {
                    ProceedingService.get(pid).then(proceeding => {
                        props = props || {};

                        if (
                            (proceeding.docuSign &&
                                proceeding.docuSign.status ===
                                    "signatory_completed") ||
                            props.isEditor
                        ) {
                            $uibModal
                                .open({
                                    backdrop: "static",
                                    keyboard: false,
                                    component: "cpirAdditionalInformationModal",
                                    size: "lg",
                                    resolve: {
                                        isCloseable: props.isEditor,
                                        proceeding: proceeding
                                    }
                                })
                                .result.catch(dismiss => {
                                    console.log("no paper types submitted");
                                });
                        }
                    });
                };

                return { getPaperTypeInfo };
            }
        }
    );
})();
