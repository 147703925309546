(function() {
    "use strict";

    angular
        .module("cpir")
        .factory("contextMenuService", contextMenuService)
        .directive("contextMenu", [
            "$timeout",
            "contextMenuService",
            contextMenu
        ]);

    function contextMenuService() {
        let idBase = "contextMenu-";
        let i = 0;

        return {
            getId: () => idBase + ++i,
            getLastId: () => idBase + i
        };
    }

    function contextMenu($timeout, contextMenuService) {
        return {
            restrict: "A",
            compile: (elem, attrs) => {
                let selector;
                let hasSelector = attrs.contextMenuSelector;

                if (!hasSelector && !selector) {
                    elem.wrapInner(
                        '<div id="' + contextMenuService.getId() + '"></div>'
                    );
                    selector = contextMenuService.getLastId();
                }

                return (scope, elem, attrs) => {
                    let option = scope.$eval(attrs.contextMenu);
                    option.selector = hasSelector
                        ? scope.$eval(attrs.contextMenuSelector)
                        : "#" + selector;

                    if (angular.isObject(option))
                        $timeout(() => elem.contextMenu(option));
                };
            }
        };
    }
})();
