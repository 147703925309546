(function() {
    "use strict";

    angular
        .module("cpir")
        .filter("entryProductionStatusFilter", TOCValuesService => {
            const statusMap = TOCValuesService.productionStatusOptions.reduce(
                (statusMap, option) => ({
                    ...statusMap,
                    [option.value]: option.name
                }),
                {}
            );

            return entry => {
                if (!entry) return null;
                if (!entry.productionStatus) return null;
                if (!TOCValuesService) return null;
                return statusMap[entry.productionStatus] || null;
            };
        });
})();
