(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparingDeadlinesPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/important-deadline-panel/important-deadline-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {}
        }
    });
})();
