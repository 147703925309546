(function() {
    "use strict";

    angular.module("cpir").component("cpirAkAddAuthorButton", {
        bindings: {
            addAuthor: "&",
            isDisabled: "<"
        },
        templateUrl:
            "application/cpir/authorkit/authors/components/add-author-button/add-author-button.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            buttonClicked() {
                if (!this.isDisabled) {
                    this.addAuthor();
                }
            }
        }
    });
})();
