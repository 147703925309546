(function() {
    "use strict";

    angular.module("cpir").filter("tocEntriesNoShowFilter", () => {
        return entries => {
            if (!entries) return 0;
            return entries.filter(e => e.noShow === true)
                .length;
        };
    });
})();
