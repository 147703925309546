(function () {
    "use strict";

    angular
        .module("cpir.routes", ["ui.router"])
        .config(["$stateProvider", Routes]);

    function Routes($stateProvider) {
        $stateProvider
            .state("dashboard-editor.home", {
                url: "/home?pid&event&signatory_uid&signatory_email",
                templateUrl:
                    "application/cpir/dashboard/editor/home/dashboard-editor-home.html",
                controller: "DashboardEditorHomeController",
                controllerAs: "$ctrl",
                params: {
                    searchParam: { value: null },
                },
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    event: ($stateParams) => $stateParams.event,
                    signatory_uid: ($stateParams) => $stateParams.signatory_uid,
                    signatory_email: ($stateParams) =>
                        $stateParams.signatory_email,
                    searchParam: ($stateParams) => $stateParams.searchParam,
                },
            })
            .state("dashboard-editor.global-configuration", {
                url: "/configuration",
                templateUrl:
                    "application/cpir/dashboard/editor/global-configuration/global-configuration.html",
                controller: "DashboardConfigurationController",
                controllerAs: "$ctrl",
            })
            .state("dashboard-editor.proceeding-configuration", {
                url: "/:pid/proceeding-configuration?view_quotes&quote_index",
                templateUrl:
                    "application/cpir/dashboard/editor/proceeding-configuration/proceeding-configuration.html",
                controller: "DashboardProceedingConfigurationController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    viewQuotes: ($stateParams) => $stateParams.view_quotes,
                    quoteIndex: ($stateParams) => {
                        const { quote_index } = $stateParams;
                        // check that the parameter is defined and parse it as an integer if it exists
                        return _.isNil(quote_index)
                            ? null
                            : parseInt(quote_index);
                    },
                    // automatically search for a quote revision if we are linking to the quotes tab and there is no pre-defined index
                    findQuoteRevision: ($stateParams) =>
                        $stateParams.view_quotes && !$stateParams.quote_index,
                },
            })
            .state("dashboard-editor.proceeding-summary", {
                url: "/:pid/proceeding-summary",
                templateUrl:
                    "application/cpir/dashboard/editor/proceeding-summary/proceeding-summary.html",
                controller: "DashboardEditorProceedingSummaryController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.publication-agreement", {
                url: "/:pid/publication-agreement",
                templateUrl:
                    "application/cpir/dashboard/editor/publication-agreement/publication-agreement-wrapper.html",
                controller: "DashboardEditorPublicationAgreementController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-organizer.quote", {
                url: "/:pid/quote",
                templateUrl:
                    "application/cpir/dashboard/organizer/quote/quote.html",
                controller: "DashboardOrganizerQuoteController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.compliance-submission", {
                url: "/:pid/compliance",
                templateUrl:
                    "application/cpir/dashboard/editor/compliance-submission/compliance-submission.html",
                controller: "DashboardComplianceSubmissionController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.compliance-acceptance", {
                url: "/:pid/compliance-acceptance",
                templateUrl:
                    "application/cpir/dashboard/editor/compliance-acceptance/compliance-acceptance.html",
                controller: "DashboardComplianceAcceptanceController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.table-of-contents", {
                url: "/:pid/table-of-contents",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents/table-of-contents.html",
                controller: "DashboardEditorTOCController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.table-of-contents-fast", {
                url: "/:pid/table-of-contents/fast",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents/table-of-contents-fast.html",
                controller: "DashboardEditorTOCController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.table-of-contents-add-entry", {
                url: "/:pid/table-of-contents/add-entry",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-add-entry/table-of-contents-add-entry.html",
                controller: "DashboardEditorTOCAddEntryController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.table-of-contents-edit-entry", {
                url: "/:pid/:entryId/table-of-contents/edit-entry?changeRequested",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-edit-entry/table-of-contents-edit-entry.html",
                controller: "DashboardEditorTOCEditEntryController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                    changeRequested: ($stateParams) =>
                        $stateParams.changeRequested,
                },
            })
            .state("dashboard-editor.table-of-contents-edit-entry-authors", {
                url: "/:pid/:entryId/table-of-contents/edit-entry/authors",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-edit-entry-authors/table-of-contents-edit-entry-authors.html",
                controller: "DashboardEditorTOCEditEntryAuthorsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("dashboard-editor.table-of-contents-edit-entry-files", {
                url: "/:pid/:entryId/table-of-contents/edit-entry/files",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-edit-entry-files/table-of-contents-edit-entry-files.html",
                controller: "DashboardEditorTOCEditEntryFilesController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("dashboard-editor.table-of-contents-repository", {
                url: "/:pid/table-of-contents/repository",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-repository/table-of-contents-repository.html",
                controller: "DashboardEditorTOCRepoController",
                controllerAs: "$ctrl",
            })
            .state("dashboard-editor.table-of-contents-repository-versions", {
                url: "/:pid/:entryId/table-of-contents/repository/versions",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-repository-versions/table-of-contents-repository-versions.html",
                controller: "DashboardEditorTOCRepoVersionsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("dashboard-editor.table-of-contents-downloads", {
                url: "/:pid/table-of-contents/downloads",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-downloads/table-of-contents-downloads.html",
                controller: "DashboardEditorTOCDownloadsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entries: ($stateParams) => $stateParams.entries,
                },
            })
            .state("dashboard-editor.table-of-contents-batch-import", {
                url: "/:pid/table-of-contents/import",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-import/table-of-contents-import.html",
                controller: "DashboardEditorTOCImportController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.table-of-contents-multi-pdf-viewer", {
                url: "/:pid/table-of-contents/multi-pdf-viewer",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents/components/multi-pdf-viewer/multi-pdf-viewer.component.html",
                controller: "DashboardEditorTocMultiPdfViewerController",
                controllerAs: "$ctrl",
                params: {
                    pdfMetadata: { value: null }, // {vid:string, title:string, number:string}
                    previousState: { value: null },
                    previousStateParams: { value: null },
                },
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    pdfMetadata: ($stateParams) => $stateParams.pdfMetadata,
                    previousState: ($stateParams) => $stateParams.previousState,
                    previousStateParams: ($stateParams) =>
                        $stateParams.previousStateParams,
                },
            })
            .state("dashboard-editor.table-of-contents-video-browser", {
                url: "/:pid/table-of-contents/videos",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-video-browser/table-of-contents-video-browser.html",
                controller: "DashboardEditorTOCVideoBrowserController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    previousState: () => "dashboard-editor.table-of-contents",
                    readOnly: () => false,
                },
            })
            .state("dashboard-editor.video-browser", {
                url: "/:pid/videos?back",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-video-browser/table-of-contents-video-browser.html",
                controller: "DashboardEditorTOCVideoBrowserController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    previousState: () => "dashboard-editor.home",
                    readOnly: ($stateParams) =>
                        _.isNil($stateParams.back)
                            ? false
                            : $stateParams.back !== "true",
                },
            })
            .state("dashboard-editor.webpub-download", {
                url: "/:pid/editorial-download",
                templateUrl:
                    "application/cpir/dashboard/editor/table-of-contents-webpub-download/table-of-contents-webpub-download.html",
                controller: "DashboardEditorTOCWebpubDownloadController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("dashboard-editor.inquiry-configuration", {
                url: "/:pid/inquiry-configuration",
                templateUrl:
                    "application/cpir/dashboard/editor/inquiry-configuration/inquiry-configuration.html",
                controller: "InquiryConfigurationController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("cif.conference", {
                url: "/conference",
                templateUrl: "application/cpir/cif/conference/conference.html",
                controller: "CifConferenceController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("cif.contacts", {
                url: "/:pid/contacts",
                templateUrl: "application/cpir/cif/contacts/contacts.html",
                controller: "CifContactsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            /*.state("cif.conference-update", {
                url: "/:pid/conference",
                templateUrl: "application/cpir/cif/conference/conference.html",
                controller: "CifConferenceController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: $stateParams => $stateParams.pid
                }
            })*/
            .state("cif.workshops", {
                url: "/:pid/workshops",
                templateUrl: "application/cpir/cif/workshops/workshops.html",
                controller: "CifWorkshopsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("cif.quotes", {
                url: "/:pid/quotes",
                templateUrl: "application/cpir/cif/quotes/quotes.html",
                controller: "CifQuotesController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("cif.submission", {
                url: "/:pid/submission",
                templateUrl: "application/cpir/cif/submission/submission.html",
                controller: "CifSubmissionController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                },
            })
            .state("ak.preparation-information", {
                url: "/:pid/preparation-information?entryId",
                templateUrl:
                    "application/cpir/authorkit/preparation-information/preparation-information.html",
                controller: "AkPreparationInformationController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak.copyright-release", {
                url: "/:pid/:entryId/copyright-release?standalone",
                templateUrl:
                    "application/cpir/authorkit/copyright-release/copyright-release.html",
                controller: "AkCopyrightReleaseController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                    standalone: ($stateParams) =>
                        $stateParams.standalone === "true",
                },
            })
            .state("ak.open-access-copyright-release", {
                url: "/:pid/:entryId/copyright-release/open-access?standalone",
                templateUrl:
                    "application/cpir/authorkit/open-access-copyright-release/open-access-copyright-release.html",
                controller: "AkOpenAccessCopyrightReleasesController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                    standalone: ($stateParams) =>
                        $stateParams.standalone === "true",
                },
            })
            .state("ak.paper-details", {
                url: "/:pid/:entryId/paper-details",
                templateUrl:
                    "application/cpir/authorkit/paper-details/paper-details.html",
                controller: "AkPaperDetailsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak.authors", {
                url: "/:pid/:entryId/authors",
                templateUrl: "application/cpir/authorkit/authors/authors.html",
                controller: "AkAuthorsController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak.paper-submission", {
                url: "/:pid/:entryId/paper-submission",
                templateUrl:
                    "application/cpir/authorkit/paper-submission/paper-submission.html",
                controller: "AkPaperSubmissionController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak-conference.conference-video-submission", {
                url: "/:pid/video-submission",
                templateUrl:
                    "application/cpir/authorkit/video-submission/video-submission.html",
                controller: "AkVideoSubmissionController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId || null,
                    standalone: () => false,
                },
            })
            .state("ak.entry-video-submission", {
                url: "/:pid/:entryId/video-submission?standalone",
                templateUrl:
                    "application/cpir/authorkit/video-submission/video-submission.html",
                controller: "AkVideoSubmissionController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                    standalone: ($stateParams) =>
                        $stateParams.standalone === "true",
                },
            })

            .state("ak.paper-acceptance", {
                url: "/:pid/:entryId/paper-acceptance",
                templateUrl:
                    "application/cpir/authorkit/paper-acceptance/paper-acceptance.html",
                controller: "AkPaperAcceptanceController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak.approval-acceptance", {
                url: "/:pid/approval-acceptance",
                templateUrl:
                    "application/cpir/authorkit/approval-acceptance/approval-acceptance.html",
                controller: "AkApprovalAcceptanceController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            })
            .state("ak.approval-denied", {
                url: "/:pid/approval-denied",
                templateUrl:
                    "application/cpir/authorkit/approval-denied/approval-denied.html",
                controller: "AkApprovalDeniedController",
                controllerAs: "$ctrl",
                resolve: {
                    pid: ($stateParams) => $stateParams.pid,
                    entryId: ($stateParams) => $stateParams.entryId,
                },
            });
    }
})();
