(function () {
    "use strict";

    angular
        .module("template.controllers", [])

        /**=========================================================
         * Module: template-controller.js
         * Main Template Controller
         =========================================================*/

        .controller("AppController", [
            "$rootScope",
            "$scope",
            "$state",
            "$window",
            "$timeout",
            "browser",
            "authService",
            "$uibModal",
            "UserService",
            "AKService",
            "Cpir2Service",
            function (
                $rootScope,
                $scope,
                $state,
                $window,
                $timeout,
                browser,
                authService,
                $uibModal,
                UserService,
                AKService,
                Cpir2Service,
            ) {
                // Setup the layout mode
                $rootScope.app.layout.horizontal =
                    $rootScope.$stateParams.layout == "app-h";

                // Hook not found
                $rootScope.$on(
                    "$stateNotFound",
                    function (event, unfoundState, fromState, fromParams) {
                        console.log(unfoundState.to); // "lazy.state"
                        console.log(unfoundState.toParams); // {a:1, b:2}
                        console.log(unfoundState.options); // {inherit:false} + default options
                    },
                );
                // Hook error
                $rootScope.$on(
                    "$stateChangeError",
                    function (
                        event,
                        toState,
                        toParams,
                        fromState,
                        fromParams,
                        error,
                    ) {
                        console.log(error);
                    },
                );
                // Hook success
                $rootScope.$on(
                    "$stateChangeSuccess",
                    function (event, toState, toParams, fromState, fromParams) {
                        // display new view from top
                        $window.scrollTo(0, 0);
                        // Save the route title
                        $rootScope.currTitle = $state.current.name;
                        // set the author LOGO link for the author kit
                        // ak-conference states won't have the link.
                        if ($state.current.name.startsWith("ak.")) {
                            getAkLogoLink().then(
                                (url) => ($scope.authorDashboardLink = url),
                            );
                        }
                    },
                );

                $rootScope.currTitle = $state.current.name;
                $rootScope.pageTitle = function () {
                    var title = $rootScope.app.name;
                    document.title = title;
                    return title;
                };

                // iPad may presents ghost click issues
                // if( ! browser.ipad )
                // FastClick.attach(document.body);

                // Close submenu when sidebar change from collapsed to normal
                $rootScope.$watch(
                    "app.layout.isCollapsed",
                    function (newValue, oldValue) {
                        if (newValue === false)
                            $rootScope.$broadcast("closeSidebarMenu");
                    },
                );

                // Allows to use branding color with interpolation
                // {{ colorByName('primary') }}
                // $scope.colorByName = colors.byName;

                // Restore application classes state
                // toggle.restoreState( $(document.body) );

                function getAkLogoLink() {
                    const authorKitProceeding = AKService.getProceeding();
                    if (!authorKitProceeding) {
                        return AKService.refreshProceeding().then(() =>
                            getAkLogoLink(),
                        );
                    } else {
                        return Cpir2Service.getAuthorDashboardURL(
                            authorKitProceeding.pid,
                        );
                    }
                }

                // cancel click event easily
                $rootScope.cancel = function ($event) {
                    $event.stopPropagation();
                };

                $scope.logout = function () {
                    authService.logout();
                    UserService.logout();
                };

                $scope.showHelpInfo = () => {
                    $uibModal.open({
                        component: "cpirHelpModal",
                    });
                };

                $scope.goToGlobalSettings = () => {
                    Cpir2Service.redirectToGlobalSettings().catch((err) => {
                        console.error(err);
                        throw err;
                    });
                };

                $scope.goToCif = () => {
                    Cpir2Service.redirectToCif().catch((err) => {
                        console.error(err);
                        throw err;
                    });
                };

                $scope.profile = authService.getProfile();

                $scope.brand = authService.getBrand();

                UserService.get($scope.profile)
                    .then((user) => {
                        $scope.isEditor =
                            (user &&
                                user.auth0Id ===
                                    ($scope.profile.sub ||
                                        $scope.profile.user_id) &&
                                user.roles.editor) ||
                            false;
                        $scope.user = user;
                    })
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });

                $scope.showPreviousInquiriesModal = () => {
                    $uibModal.open({
                        component:
                            "cpirDashboardOrganizerPreviousInquiriesModal",
                    });
                };
            },
        ]);
})();
