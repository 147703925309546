(function() {
    "use strict";

    angular.module("cpir").filter("proceedingPressDate", [
        "PureDateService",
        PureDateService => {
            return function(proceeding) {
                if (!proceeding) return null;
                if (!proceeding.productionSchedule) return null;

                let pressDate = null;

                proceeding.productionSchedule.forEach(schedule => {
                    if (schedule.id === "pressDate") {
                        pressDate = PureDateService.inflate(
                            schedule.date
                        ).toISOString();
                    }
                });

                return pressDate;
            };
        }
    ]);
})();
