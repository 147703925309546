(function () {
    "use strict";

    const prePrintStatusOptions = [
        { name: "Open Preview", value: "open_preview" },
        // {name: 'Early Access', value: 'early_access'}, // early-access is not yet enabled
        { name: "Final", value: "final" },
    ];

    const continuousPubStatusOptions = [
        { name: "Preprint", value: "preprint" },
        { name: "Final", value: "final" },
    ];

    const namingConventionOptions = [
        { name: "Paper Title", value: "paper_title" },
        { name: "Paper ID", value: "paper_id" },
        { name: "Digital Library Nomenclature", value: "dl_nomenclature" },
        { name: "Original Filename", value: "original_filename" },
    ];

    angular.module("cpir").component("cpirTocProductionSettingsModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@",
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/production-settings-modal/production-settings-modal.component.html",
        controller: class {
            constructor(
                $stateParams,
                ProceedingService,
                NotificationService,
                $uibModal,
            ) {
                this.$stateParams = $stateParams;
                this.ProceedingService = ProceedingService;
                this.NotificationService = NotificationService;
                this.$uibModal = $uibModal;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
                this.proceeding = this.resolve.proceeding;

                this.bookTrims = [
                    { name: "8.5 x 11 1-column", value: "8.5x11-1" },
                    { name: "8.5 x 11 2-column", value: "8.5x11-2" },
                    { name: "A4 1-column", value: "A4-1" },
                    { name: "A4 2-column", value: "A4-2" },
                    { name: "6 x 9", value: "6x9" },
                ];

                this.copyrightTypeOptions = [
                    { name: "IEEE", value: "ieee" },
                    { name: "ACM", value: "acm" },
                    { name: "Other", value: "other" },
                ];

                this.copyrightLineTypeOptions = [
                    { name: "Standard", value: "standard" },
                    { name: "OA", value: "oa" },
                    { name: "Blank", value: "blank" },
                    { name: "Override", value: "override" },
                ];

                this.fontTemplateOptions = [
                    { name: "Times New Roman", value: "1" },
                    { name: "Helvetica", value: "2" },
                ];
                this.prePrintStatusOptions = prePrintStatusOptions;
                this.continuousPubStatusOptions = continuousPubStatusOptions;
                this.namingConventionOptions = namingConventionOptions;

                this.popOverMessage = "";

                this.ProceedingService.getToc(this.pid).then((toc) => {
                    this.toc = toc;
                    this.toc.articleNamingConvention =
                        this.toc.articleNamingConvention || "paper_title";
                    this.toc.supplementNamingConvention =
                        this.toc.supplementNamingConvention || "paper_title";
                });
            }

            doiAcronymChanged() {
                if (this.productForm.$invalid) {
                    this.popOverMessage =
                        "Scroll to top to check missing fields.";
                } else {
                    this.popOverMessage = "";
                }
            }

            continuousPubStatusChanged() {
                if (
                    this.toc.isContinuousPub &&
                    this.toc.continuousPubStatus !== undefined
                ) {
                    this.popOverMessage = "";
                    this.isPopOverEnabled = false;
                }
            }

            isPopOverEnabled() {
                if ($ctrl.productForm.$invalid) return false;
            }
            resetContPubStatus(value) {
                if (this.toc.isContinuousPub) {
                    this.$uibModal
                        .open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                message: () =>
                                    "The conference can either be designated as Continuous Publishing or Open Preview. Are you sure you want to change the current designation?",
                            },
                        })
                        .result.then(() => {
                            this.toc.isContinuousPub = false;
                            this.toc.continuousPubStatus = null;
                        })
                        .catch((e) => {
                            this.toc.isPrePrint = null;
                            console.error("Error: ", e);
                        });
                }
                if (!this.toc.isPrePrint) {
                    this.toc.isPrePrint = false;
                    this.toc.prePrintStatus = null;
                    this.toc.originalPrePrintStatus = null;
                }
            }

            resetPreprintStatus1(value) {
                if (this.toc.isPrePrint) {
                    //this.toc.isPrePrint = false;
                    this.$uibModal
                        .open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                message: () =>
                                    "The conference can either be designated as Continuous Publishing or Open Preview. Are you sure you want to change the current designation?",
                            },
                        })
                        .result.then(() => {
                            this.toc.isPrePrint = false;
                            this.toc.prePrintStatus = null;
                            this.toc.originalPrePrintStatus = null;
                        })
                        .catch((e) => {
                            this.toc.isContinuousPub = null;
                            console.error("Error: ", e);
                        });
                }
                if (
                    this.toc.isContinuousPub &&
                    (this.toc.continuousPubStatus === undefined ||
                        this.toc.continuousPubStatus === null)
                ) {
                    this.popOverMessage =
                        "Scroll to top to check missing fields.";
                    this.isPopOverEnabled = true;
                } else {
                    this.popOverMessage = "";
                    this.isPopOverEnabled = false;
                }
                if (!this.toc.isContinuousPub) {
                    this.toc.isContinuousPub = false;
                    this.toc.continuousPubStatus = null;
                }
            }

            saveButtonClicked() {
                if (
                    this.toc.isContinuousPub &&
                    (this.toc.continuousPubStatus === undefined ||
                        this.toc.continuousPubStatus === null)
                ) {
                    this.popOverMessage =
                        "Scroll to top to check missing fields.";
                    this.isPopOverEnabled = true;
                } else {
                    this.popOverMessage = "";
                    this.isPopOverEnabled = false;
                    if (
                        this.toc.isPrePrint &&
                        !this.toc.originalPrePrintStatus
                    ) {
                        this.toc.originalPrePrintStatus =
                            this.toc.prePrintStatus;
                    }
                    if (!this.productForm.$invalid) {
                        this.ProceedingService.updateProceedingSettings(
                            this.pid,
                            this.proceeding.configuration.settings.ak.copyright
                                .type,
                            this.toc,
                        ).then(() => {
                            this.close({ $value: 1 });
                        });
                    }
                }
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            resetPrePrintStatus() {
                console.log("Pre print Status has been reset!");
                this.toc.prePrintStatus = null;
                this.toc.originalPrePrintStatus = null;
            }
        },
    });

    angular.module("cpir").filter("prePrintStatusFilter", () => {
        return (status) => {
            const option = prePrintStatusOptions.find(
                (s) => s.value === status,
            );
            return (option && option.name) || "";
        };
    });

    angular.module("cpir").filter("continuousPubStatusFilter", () => {
        return (status) => {
            const option = continuousPubStatusOptions.find(
                (s) => s.value === status,
            );
            return (option && option.name) || "";
        };
    });
})();
