(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceConfApprovalPanel", {
        bindings: {
            proceeding: "<",
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/compliance-conf-approval-panel/compliance-conf-approval-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                $uibModal,
                authService,
                EmailService,
                NotificationService,
                ProceedingStatusService,
                DeepLinkService
            ) {
                this.$stateParams = $stateParams;
                this.$uibModal = $uibModal;
                this.authService = authService;
                this.EmailService = EmailService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.NotificationService = NotificationService;
                this.DeepLinkService = DeepLinkService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
            }

            isDecisionDisabled(action) {
                // Only allow approve and disapprove buttons to work
                // when the proceeding is has had a compliance submission submitted and is not rejected or in the next stage
                const complianceWasPreviouslySubmitted =
                    this.proceeding &&
                    this.configuration.settings.steps.changes.find(
                        ({ status }) => status === "compliance-submitted"
                    );
                return (
                    !this.proceeding ||
                    !this.ProceedingStatusService.isAtLeastComplianceReview(
                        this.proceeding
                    ) ||
                    // if checking if approve is enabled, ensure there is a compliance submission
                    // rejecting compliance does not require a submission
                    (action === "approve" &&
                        (!this.proceeding.compliance ||
                            !this.proceeding.compliance.submissionDate ||
                            !complianceWasPreviouslySubmitted)) ||
                    this.ProceedingStatusService.isAtLeastInternalSignatory(
                        this.proceeding
                    )
                );
            }

            approveConfirmation() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        let isApproved = true;

                        this.ProceedingStatusService.setInternalSignatory(
                            this.proceeding,
                            false
                        );
                        return this.updateProceeding(isApproved).then(() => {
                            this.EmailService.sendToEditors(
                                this.pid,
                                "editor-compliance-complete",
                                {
                                    data: {
                                        proceeding: this.proceeding,
                                        paLink: this.DeepLinkService.getPADeepLink(
                                            this.proceeding.pid
                                        )
                                    },
                                    endpoint: "/email"
                                }
                            );
                            this.EmailService.customizeAndSend(
                                "compliance-accepted",
                                {
                                    data: {
                                        proceeding: this.proceeding
                                    },
                                    to: [
                                        this.proceeding.compliance.submission
                                            .email
                                    ]
                                }
                            );
                        });
                    });
            }

            disapproveConfirmation() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        let isApproved = false;
                        this.ProceedingStatusService.setComplianceRejected(
                            this.proceeding,
                            false
                        );
                        return this.updateProceeding(isApproved).then(
                            proceeding => {
                                this.proceeding = proceeding;
                                this.EmailService.sendToOrganizers(
                                    this.proceeding,
                                    "compliance-rejected",
                                    {
                                        data: {
                                            proceeding
                                        }
                                    }
                                );
                            }
                        );
                    });
            }

            updateProceeding(isApproved) {
                let decision = {};
                decision.date = new Date();
                decision.isApproved = isApproved;
                decision.submitter = this.authService.getProfile().email;
                this.configuration.settings.compliance.approval.isApproved = true;
                this.configuration.settings.compliance.approval.decisions.push(
                    decision
                );
                return this.saveConfiguration().then(() => {
                    return this.NotificationService.send(
                        "success",
                        "Your decision was saved successfully."
                    );
                });
            }

            saveConfiguration() {
                this.onSave({
                    props: {
                    configuration: this.configuration
                    }
                });
            }
        }
    });
})();
