(function () {
    "use strict";

    angular.module("cpir").service(
        "TocNavigationService",
        class {
            constructor() {
                this.reset();
            }

            reset() {
                this.entries = [];
                this.currentIndex = -1;
                this.currentPid = null;
                this.sortType = "sequence";
                this.sortReverse = true;
                this.defaultAllSelected = false;
            }

            setEntries(entries, pid, defaultAllSelected = false) {
                // Reset state if pid has changed
                if (this.currentPid !== pid) {
                    this.reset();
                    this.currentPid = pid;
                }
                this.entries = entries;
                this.defaultAllSelected = defaultAllSelected;
            }

            getDefaultAllSelected() {
                return this.defaultAllSelected;
            }

            getEntries() {
                return this.entries;
            }

            getSelectedEntryIds() {
                return this.entries.map((entry) => entry.eid);
            }

            setCurrentEntry(entryId) {
                this.currentIndex = this.entries.findIndex(
                    (entry) => entry.eid === entryId,
                );
            }

            getNextEntry() {
                if (this.currentIndex < this.entries.length - 1) {
                    return this.entries[this.currentIndex + 1];
                }
                return null;
            }

            getPreviousEntry() {
                if (this.currentIndex > 0) {
                    return this.entries[this.currentIndex - 1];
                }
                return null;
            }

            setSortOrder(sortType, sortReverse) {
                this.sortType = sortType;
                this.sortReverse = sortReverse;
            }

            getSortOrder() {
                return {
                    sortType: this.sortType,
                    sortReverse: this.sortReverse,
                };
            }

            getCurrentPid() {
                return this.currentPid;
            }
        },
    );
})();
