(function() {
    "use strict";

    /*
     * Description: This filter accepts a 'value' for an
     * item attribute, and the corresponding medium. It
     * then outputs the correct label for that name/value
     * pair.
     */

    angular
        .module("cpir")
        .filter("cpirQuoteAttributeValueToLabelFilter", () => {
            return (value, name, medium) => {
                if (!name || !medium) return null;
                let field = medium.fields.find(f => f.name === name);
                if (field && field.type) {
                    switch (field.type) {
                        case "number":
                            return value;
                        case "select":
                            let option = field.options.find(
                                o => o.value === value
                            );
                            return option.name;
                    }
                }
                return value;
            };
        });
})();
