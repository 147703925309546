(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardOrganizerContactInformation", {
            bindings: {
                sectionTitle: "<",
                contactInformation: "<",
                onUpdate: "&"
            },
            templateUrl:
                "application/cpir/dashboard/organizer/quote/components/" +
                "contact-information/contact-information.component.html",
            controller: function(
                $stateParams,
                ContactService,
                ProceedingService,
                InternationalDataService
            ) {
                let $ctrl = this;

                $ctrl.pid = $stateParams.pid;

                $ctrl.modelChanged = () => {
                    $ctrl.onUpdate({
                        updatedContactInformation: $ctrl.contactInformation
                    });
                };

                $ctrl.countryOptions = InternationalDataService.getCountries();

                $ctrl.provinceOptions = InternationalDataService.getProvinces();

                $ctrl.availableRoles = [
                    { name: "General Chair", value: "GC" },
                    { name: "Publication Chair", value: "BC" },
                    { name: "Finance Chair", value: "FC" },
                    { name: "Program Chair", value: "PC" },
                    { name: "Other", value: "OT" }
                ];

                // Call the API to receive the proceeding
                ProceedingService.get($ctrl.pid).then(proceeding => {
                    if (proceeding.contacts) {
                        let cids = [];
                        for (let contact of proceeding.contacts) {
                            cids.push(contact.cid);
                        }
                        ContactService.list(cids).then(contacts => {
                            $ctrl.contact = contacts.find(c => c.isSignatory);
                        });
                    }
                });
            }
        });
})();
