(function() {
    "use strict";

    angular.module("cpir").filter("tocEntriesPapersFilter", () => {
        return entries => {
            if (!entries) return 0;
            return entries.filter(e => e.type === "AP").length;
        };
    });
})();
