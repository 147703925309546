(function () {
    "use strict";

    angular
        .module("cpir")
        .directive("controlcharvalidation", function ($q, $timeout) {
            return {
                require: 'ngModel',

                link: function (scope, element, attrs, ngModel) {

                    ngModel.$parsers.unshift(function (value) {

                            const field = ngModel.$name;

                            if(value !== "") {
                                 let decodedValue = he.decode(value);
                                 let controlCharRegExp = /[\u0000-\u001f\u007f\u0080-\u009f]/g;

                                 if (controlCharRegExp.test(decodedValue)) {
                                     ngModel.$setValidity(field, false);
                                     return value;
                                 } else {
                                     ngModel.$setValidity(field, true);
                                     return value;
                                 }
                            } else {
                                    ngModel.$setValidity(field, true);
                                    return value;
                                }
                            });
                    }
            }
                });


          //  };
       // });
})();
