(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirProceedingConfInfoPanel", {
            bindings: {
                proceeding: "<",
                isParentConfTab: "<",
                onUpdate: "&",
                onSave: "&"
            },
            templateUrl:
                "application/cpir/dashboard/editor/proceeding-configuration/components/" +
                "proceeding-conf-information-panel/proceeding-conf-info-panel.component.html",
            controller: class {

                $onInit() {
                }
                setSaveButton() {
                    this.showSaveButton = true;
                }
                saveProceeding() {
                    this.onSave({proceeding: this.proceeding});
                }

                proceedingChanged(proceeding) {
                    this.onUpdate({hasSeparatePublicationsInXplore: proceeding.hasSeparatePublicationsInXplore});
                }
            }
        });
})();
