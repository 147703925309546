(function() {
    "use strict";

    angular.module("cpir").provider(
        "ConfigurationService",
        class {
            constructor() {}
            $get(apiService) {
                let firstItem = apiService.firstItem;
                return {
                    get: () =>
                        apiService
                            .get("application")
                            .then(result => firstItem(result)),
                    update: configuration =>
                        apiService
                            .put(
                                "application/Ou1fbx3dQ3O9PardDk66CJvhUD2IaACI",
                                configuration
                            )
                            .then(result => result.data)
                };
            }
        }
    );
})();
