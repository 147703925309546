(function() {
    "use strict";

    angular.module("cpir").component("cpirHelpModal", {
        bindings: {
            size: "@",
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/_shared/components/help-modal/help-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            okButtonClicked() {
                this.close();
            }
        }
    });
})();
