(function() {
    "use strict";

    angular
        .module("cpir")
        .filter("proceedingOrderByPressDateFilter", moment => {
            return function(proceedings, reverse) {
                if (!proceedings) return null;

                let proceedingsWithoutSchedule = proceedings.filter(
                    p => !p.productionSchedule
                );

                let proceedingsWithSchedule = proceedings.filter(
                    p => p.productionSchedule
                );

                proceedingsWithSchedule.sort((a, b) => {
                    let statusA = a.productionSchedule.find(
                        s => s.id === "pressDate"
                    );
                    let statusB = b.productionSchedule.find(
                        s => s.id === "pressDate"
                    );

                    if (!statusA && !statusB) {
                        return 0;
                    } else if (!statusA) {
                        return 1;
                    } else if (!statusB) {
                        return -1;
                    }

                    let dateA = moment(statusA.date);
                    let dateB = moment(statusB.date);

                    if (dateA.isSameOrAfter(dateB)) {
                        return reverse ? -1 : 1;
                    } else {
                        return reverse ? 1 : -1;
                    }
                });

                return proceedingsWithSchedule.concat(
                    proceedingsWithoutSchedule
                );
            };
        });
})();
