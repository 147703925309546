(function() {
    "use strict";

    angular.module("cpir").component("cpirCifSummaryContactsPanel", {
        bindings: {
            contacts: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/contacts/contacts.html",
        controller: class {
            constructor($scope) {
                this.$scope = $scope;
            }

            $onInit() {
                this.activeContactIndex = 0;
                this.countriesWithProvincesEnabled = ["CA", "US"];

                this.$scope.$watch("$ctrl.contacts", () => {
                    if (this.contacts) {
                        this.activeContact = this.contacts[
                            this.activeContactIndex
                        ];
                    }
                });
            }

            activateContact(contactIndex) {
                this.activeContactIndex = contactIndex;
                this.activeContact = this.contacts[contactIndex];
            }

            isProvinceEnabled() {
                for (let country of $this.countriesWithProvincesEnabled) {
                    if (this.activeContact.address.country === country)
                        return true;
                }
                return false;
            }
        }
    });
})();
