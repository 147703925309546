(function() {
    "use strict";

    angular.module("cpir").component("tocBatchUpload", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/batch-upload/batch-upload.component.html",
        controller: class {
            constructor(NotificationService, UploadService) {
                this.UploadService = UploadService;
                this.NotificationService = NotificationService;
                this.progressTotal = 0;
            }

            $onInit() {
                this.pid = this.resolve.pid;
            }

            doUpload(onError, onFinish) {
                this.uploadStarted = true;
                this.UploadService.batchUpload({
                    pid: this.pid,
                    manifest: this.manifestFile,
                    articles: this.tocFiles,
                    isVendorUpload: this.isVendorUpload,
                    actions: this.resolve.actions,
                    onFinish: onFinish,
                    onError: onError,
                    progress: progress => {
                        console.log("progress: ", progress);
                        this.progressTotal = progress;
                    }
                });
            }

            ok() {
                this.doUpload(
                    err => {
                        this.NotificationService.send(
                            "danger",
                            `Upload Failed: ${err.data}`
                        );
                        this.dismiss({ $value: err });
                    },
                    () => {
                        this.NotificationService.send(
                            "success",
                            "Upload Successful"
                        );
                        this.close({ $value: result });
                    }
                );
            }

            cancel() {
                this.dismiss({ $value: "cancel" });
            }

            selectManifestFile(file, invalidFile) {
                if (invalidFile) {
                    console.log("Invalid File: ", invalidFile);
                    this.NotificationService.send(
                        "danger",
                        "Please choose an Excel file (.xlsx) for this submission."
                    );
                    return;
                }
                this.manifestDetails = {};
                this.manifestDetails.name = file.name;
                this.manifestDetails.size = file.size;
            }

            removeManifestFile() {
                if (this.manifestFile) this.manifestFile = null;
            }

            removeTocFile(index) {
                this.tocFiles.splice(index, 1);
            }
        }
    });
})();
