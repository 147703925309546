angular.module("cpir").directive("trimInput", function() {
    return {
        require: "ngModel",
        link: function(scope, element, attrs, ngModelController) {
            ngModelController.$parsers.push(function(value) {
                if (value !== null && value !== undefined) {
                    return value.trim();
                }
                return value;
            });

            // For model to view transformation
            ngModelController.$formatters.push(function(value) {
                if (value !== null && value !== undefined) {
                    return value.trim();
                }
                return value;
            });
        }
    };
});
