(function () {
    "use strict";

    angular
        .module("cpir")
        .directive("charvalidation", function ($q, $timeout) {
            return {
                require: 'ngModel',

                link: function (scope, element, attrs, ngModel) {

                    ngModel.$parsers.unshift(function (value) {

                            const field = ngModel.$name;

                            if(value !== "") {

                                if ((field === 'givenName' || field === 'surname')) {
                                    /**
                                     * Decode the incoming name first to convert any entered with html or unicode encoding already from input
                                     */
                                    let decodedName = he.decode(value);


                                    let xploreName = removeDiacritics(decodedName); //use it here
                                    const asciiRegex = new RegExp("^[\\sa-zA-Z.-]+('[\\sa-zA-Z.-]+)?$"); //change var to asciiRegex

                                    /**
                                     * eliminate keyboard special chars and numbers first
                                     */

                                    console.log("printing decoded val " + decodedName);
                                    if (decodedName.match(/[(){}+=*^%$#@!~<>,:;"&_?\d]+/g) !== null) {
                                        ngModel.$setValidity('authorname', false);
                                        console.log("inside keybd chars");
                                        return value;
                                    } else {
                                        /**
                                         * Replace all diacritics with equivalent ascii and check if any non ascii is left out in he result, if so then return error
                                         */
                                        if (!asciiRegex.test(xploreName)) {
                                            ngModel.$setValidity('authorname', false);
                                            console.log("inside diatrics");
                                            return value;
                                        } else {
                                            ngModel.$setValidity('authorname', true);
                                            return value;
                                        }
                                    }
                                }
                            } else {
                                    ngModel.$setValidity(field, true);
                                    return value;
                                }
                            });
                    }
            }
                });


          //  };
       // });
})();
