(function() {
    "use strict";

    angular.module("cpir").provider(
        "PublicConfigurationService",
        class {
            constructor() {}
            $get(apiService) {
                let firstItem = apiService.firstItem;
                return {
                    get: pid => {
                        if (pid) {
                            return apiService
                                .getPublic(`configuration/${pid}`)
                                .then(result => firstItem(result));
                        } else {
                            return apiService
                                .getPublic(`configuration`)
                                .then(result => firstItem(result));
                        }
                    }
                };
            }
        }
    );
})();
