(function () {
    "use strict";

    /**
     * Chart Service
     *
     * This service provides a wrapper around the Chart.js library.
     * It ensures Chart.js is loaded before being used.
     */
    angular.module("cpir").factory("Chart", ChartService);

    ChartService.$inject = ["$window", "$document", "$q", "$timeout"];

    function ChartService($window, $document, $q, $timeout) {
        // Create a promise that resolves when Chart.js is loaded
        var chartPromise = $q(function (resolve, reject) {
            // If Chart.js is already available, resolve immediately
            if ($window.Chart) {
                resolve($window.Chart);
                return;
            }

            // Otherwise, dynamically load Chart.js
            var script = $document[0].createElement("script");
            script.src =
                "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.min.js";
            script.integrity =
                "sha512-d9xgZrVZpmmQlfonhQUvTR7lMPtO7NkZMkA0ABN3PHCbKA5nqylQ/yWlFAyY6hYgdF1Qh6nYiuADWwKB4C2WSw==";
            script.crossOrigin = "anonymous";
            script.onload = function () {
                resolve($window.Chart);
            };
            script.onerror = function () {
                reject(new Error("Failed to load Chart.js"));
            };
            $document[0].head.appendChild(script);
        });

        // Return a constructor function that mimics Chart.js constructor
        function ChartConstructor(ctx, options) {
            var self = this;
            this._initialized = false;

            // Properties that will be copied from the real Chart instance
            this.data = null;
            this.options = options;
            this.chart = null;

            // Create the real Chart instance once Chart.js is loaded
            chartPromise
                .then(function (ChartClass) {
                    try {
                        // Create the actual Chart instance
                        var chart = new ChartClass(ctx, options);

                        // Copy all properties and methods from the real chart to this
                        Object.keys(chart).forEach(function (key) {
                            self[key] = chart[key];
                        });

                        // Save reference to the real chart
                        self.chart = chart;
                        self._initialized = true;
                    } catch (e) {
                        console.error("Error creating chart:", e);
                    }
                })
                .catch(function (error) {
                    console.error("Failed to load Chart.js:", error);
                });
        }

        // Add key methods that might be called before initialization
        ChartConstructor.prototype.destroy = function () {
            var self = this;
            if (this._initialized && this.chart) {
                this.chart.destroy();
            } else {
                // If not initialized yet, wait for initialization
                chartPromise.then(function () {
                    $timeout(function () {
                        if (self.chart) {
                            self.chart.destroy();
                        }
                    }, 0);
                });
            }
        };

        ChartConstructor.prototype.update = function () {
            var self = this;
            if (this._initialized && this.chart) {
                this.chart.update();
            } else {
                // If not initialized yet, wait for initialization
                chartPromise.then(function () {
                    $timeout(function () {
                        if (self.chart) {
                            self.chart.update();
                        }
                    }, 0);
                });
            }
        };

        return ChartConstructor;
    }
})();
