(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfMiscPanel", {
        bindings: {
            proceeding: "<",
            configuration: "<",
            isGlobal: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/configuration-common/components/ak-conf-misc-panel/ak-conf-misc-panel.component.html",
        controller: class {
            constructor(
                $q,
                $state,
                $stateParams,
                $uibModal,
                ConfigurationService,
                DefaultSettingsService,
                authService,
                DeepLinkService,
                ProceedingAdditionalInformationService,
                NotificationService,
                ProceedingStatusService
            ) {
                this.$q = $q;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$uibModal = $uibModal;
                this.DeepLinkService = DeepLinkService;
                this.authService = authService;
                this.ConfigurationService = ConfigurationService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.ProceedingAdditionalInformationService = ProceedingAdditionalInformationService;
                this.NotificationService = NotificationService;
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;
                if (this.pid) {
                    this.akLink = this.DeepLinkService.getAkDeepLink(this.pid);
                    this.videoLink = this.DeepLinkService.getConferenceVideoDeepLink(
                        this.pid
                    );
                    this.videoListLink = this.DeepLinkService.getConferenceVideoListDeepLink(
                        this.pid
                    );
                }
            }

            supplyAdditionalInformation() {
                this.ProceedingAdditionalInformationService.getPaperTypeInfo(
                    this.proceeding.pid,
                    {
                        isEditor: true
                    }
                );
            }

            showAuthorKitToggle() {
                if (this.proceeding) {
                    return this.ProceedingStatusService.isAtLeastAkActive(
                        this.proceeding
                    );
                } else return false;
            }

            isAuthorKitDisabled() {
                if (!this.proceeding) return false;
                return this.proceeding.configuration.settings.ak.isDisabled;
            }

            openAuthorKit() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        this.configuration.settings.ak.isDisabled = false;
                        return this.onSave({
                            props: {
                                configuration: this.configuration,
                                hideNotification: true
                            }
                        });
                    })
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The Author Kit was successfully reopened."
                        );
                    })
                    .catch(e => console.log(e));
            }

            closeAuthorKit() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        if (
                            !this.ProceedingStatusService.isProduction(
                                this.proceeding
                            )
                        ) {
                            this.ProceedingStatusService.setProduction(
                                this.proceeding,
                                false
                            );
                        }

                        this.proceeding.configuration.settings.ak.isDisabled = true;
                        return this.onSave({
                            props: {
                                configuration: this.configuration,
                                hideNotification: true
                            }
                        });
                    })
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The Author Kit was successfully closed."
                        );
                    })
                    .catch(e => console.log(e));
            }

            confirmResetAK() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        this.brand = this.authService.getBrand().name;

                        this.ConfigurationService.get()
                            .then(config => {
                                if (this.brand === "cps") {
                                    this.configuration =
                                        config.configuration.cps;
                                } else if (this.brand === "pubhub") {
                                    this.configuration =
                                        config.configuration.pubhub;
                                }

                                this.proceeding.configuration.settings.ak = this.configuration.settings.ak;

                                return this.onSave({
                                    props: {
                                        configuration: this.configuration,
                                        hideNotification: true
                                    }
                                });
                            })
                            .then(() => {
                                this.NotificationService.send(
                                    "success",
                                    "The configuration settings were successfully reset."
                                );
                                this.$state.go(
                                    "dashboard-editor.proceeding-configuration",
                                    { pid: this.pid }
                                );
                                this.$state.reload();
                            });
                    });
            }

            saveConfiguration() {
                this.onSave({ props: { configuration: this.configuration } });
            }
        }
    });
})();
