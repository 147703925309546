(function() {
    "use strict";

    angular.module("cpir").component("cpirCifContactsContactPanel", {
        bindings: {
            isEmailDisabled: "<",
            isSignatoryInContacts: "<",
            chairValidation: "<",
            contact: "<",
            disableSignatory: "<",
            hideRemoveButton: "<",
            forEditor: "<",
            onUpdate: "&",
            onRemove: "&"
        },
        templateUrl:
            "application/cpir/cif/contacts/components/contact-panel/contact-panel.component.html",
        controller: class {
            constructor($rootScope, $scope, InternationalDataService) {
                this.$rootScope = $rootScope;
                this.$scope = $scope;
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.contactForm = {};

                this.$rootScope.$on("CONTACT_ADDED", () => {
                    if (this.contactForm) {
                        this.contactForm.$setPristine();
                    }
                });

                this.countryOptions = this.InternationalDataService.getCountries();

                this.availableRoles = [
                    { name: "General Chair", value: "GC" },
                    { name: "Publication Chair", value: "BC" },
                    { name: "Finance Chair", value: "FC" },
                    { name: "Program Chair", value: "PC" },
                    { name: "Other", value: "OT" }
                ];

                this.$scope.$watch(
                    () => this.contactForm.$valid,
                    () => this.contactChanged()
                );
            }

            contactChanged() {
                this.onUpdate({
                    contact: this.contact,
                    isValid: this.contactForm.$valid
                });
            }

            removeButtonClicked() {
                this.onRemove();
            }

            updateProvince(province) {
                this.contact.address.province = province;
                this.contactChanged();
            }

            hasErrors() {
                if (this.contactForm.$pristine) {
                    return false;
                }
                if (this.isSignatoryInContacts === false) return true;
                else if (this.contactForm.$invalid === true) return true;
                else if (
                    this.chairValidation.conferenceIsNonSponsored &&
                    (this.chairValidation.chairsMatch ||
                        this.chairValidation.missingGeneralChair ||
                        this.chairValidation.missingFinanceChair)
                )
                    return true;
                else return false;
            }

            isOtherContactSelected() {
                if (!this.contact || !this.contact.roles) return false;

                for (let role of this.contact.roles) {
                    if (role === "other") return true;
                }

                return false;
            }

            // Enforce only one signatory is allowed by disabling the
            // box in the case that *another* signatory is already
            // present.
            isSignatoryDisabled() {
                if (this.disableSignatory) return true;
                return this.isSignatoryInContacts && !this.contact.isSignatory;
            }
        }
    });
})();
