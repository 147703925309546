(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfPreparationPanel", {
        bindings: {
            isGlobal: "<",
            configuration: "<",
            proceeding: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/configuration-common/components/ak-conf-preparation-panel/ak-conf-preparation-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                moment,
                NotificationService,
                DefaultSettingsService
            ) {
                this.$stateParams = $stateParams;
                this.moment = moment;
                this.DefaultSettingsService = DefaultSettingsService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];

                this.sectionOptions = [
                    { name: "Production Editor", value: "PRODUCTION_EDITOR" },
                    {
                        name: "Important Deadlines",
                        value: "IMPORTANT_DEADLINES"
                    },
                    { name: "Paper Preparation", value: "PREPARING_PAPER" },
                    { name: "Paper Formatting", value: "FORMATTING_PAPER" },
                    { name: "Paper Renaming", value: "RENAMING_PDF" },
                    { name: "Paper Validation", value: "PAPER_VALIDATION" }
                ];

                // convert date strings back to Date objects
                this.configuration.settings.ak.prep.deadlines.paperDeadlineDate = new Date(
                    this.configuration.settings.ak.prep.deadlines.paperDeadlineDate
                );

                this.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate = new Date(
                    this.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate
                );
            }

            /**
             * The import production schedule process replaces the HTMl
             * in the instructions for the 'deadlines' section of the AK
             * preparation information.
             *
             * It works by making a new HTML table based on the dates in the
             * production schedule array.
             */
            importPaperTypes() {
                // Only update the table if the paper types are set
                if (
                    !this.isGlobal &&
                    (!this.proceeding.additionalInformation ||
                        !this.proceeding.additionalInformation.paperTypes)
                ) {
                    this.NotificationService.send(
                        "warning",
                        "The paper types could not be found."
                    );
                } else {
                    try {
                        // Holds HTML rows in this variable
                        let paperTypesRowsHtml = "";

                        // Dynamically add each row into the collection
                        this.proceeding.additionalInformation.paperTypes.forEach(
                            p => {
                                let rowHtml = `<tr><td>${p.name}</td><td>${p.pagesAllowed}</td><td>${p.extraPagesAllowed}</td><td>${p.extraPagesCharge}</td></tr>`;
                                paperTypesRowsHtml += rowHtml;
                            }
                        );

                        // The table around the rows is already defined
                        const tableHtml = `<table class="table"><thead><tr><th>Paper Type</th><th>Pages Allowed</th><th>Extra Pages Allowed</th><th>Extra Pages Charge</th></tr></thead><tbody>${paperTypesRowsHtml}</tbody></table>`;

                        // Update the content in the proceeding
                        this.configuration.settings.ak.prep.renaming.instructions = tableHtml;
                    } catch (err) {
                        // If something in this process fails, alert the client
                        this.NotificationService.send(
                            "warning",
                            "The settings could not be updated."
                        );
                        throw err;
                    }
                }
            }

            /**
             * The import production schedule process replaces the HTMl
             * in the instructions for the 'deadlines' section of the AK
             * preparation information.
             *
             * It works by making a new HTML table based on the dates in the
             * production schedule array.
             */
            importProductionSchedule() {
                // Only update the table if a production schedule actually exists
                if (!this.isGlobal && !this.proceeding.productionSchedule) {
                    this.NotificationService.send(
                        "warning",
                        "The production schedule could not be found."
                    );
                } else {
                    try {
                        // Holds HTML rows in this variable
                        let productionScheduleRowsHtml = "";

                        // Dynamically add each row into the collection
                        this.proceeding.productionSchedule.forEach(p => {
                            let name = p.name;
                            let date = this.moment(
                                p.date,
                                this.moment.ISO_8601,
                                true
                            ).format("DD MMMM YYYY");
                            let rowHtml = `<tr><td>${name}</td><td>${date}</td></tr>`;
                            productionScheduleRowsHtml += rowHtml;
                        });

                        // The table around the rows is already defined
                        const tableHtml = `<table class="table"><thead><tr><th>Deadline</th><th>Date</th></tr></thead><tbody>${productionScheduleRowsHtml}</tbody></table>`;

                        // Update the content in the proceeding
                        this.configuration.settings.ak.prep.deadlines.instructions = tableHtml;
                    } catch (err) {
                        // If something in this process fails, alert the client
                        this.NotificationService.send(
                            "warning",
                            "The settings could not be updated."
                        );
                        throw err;
                    }
                }
            }

            saveConfiguration() {
                this.onSave({ props: { configuration: this.configuration } });
            }
        }
    });
})();
