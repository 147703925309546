(function () {
    "use strict";

    /*
    FileHelper class definition

    The FileHelper class is used to coordinate loading, tracking active, missing,
    and selected files within a fileType category
     */
    function FileHelper(type, active, typeFriendlyName) {
        this.type = type;
        this.active = active;
        this.typeFriendlyName = typeFriendlyName;
        this.files = null;
        this.fileMap = {};
        this.activeFile = null;
        this.selectedIdsToCompare = {};
        this.selectedFilesToCompare = [];
        this.selectedIdsToRemove = {};
        this.selectedFilesToRemove = [];

        // load the data
        this.load();
    }

    FileHelper.prototype.checkMissing = function () {
        this.files.forEach((file) => {
            this.FileService.exists(file).then(({ exists: fileExists }) => {
                file.missing = !fileExists;
            });
        });
    };

    FileHelper.prototype.load = function () {
        this.FileService.getFilesMetadataFoEntry(
            this.eid,
            this.type,
            this.active,
        ).then((files) => {
            this.files = files;
            this.checkMissing(files);
            this.setActiveAndMap();
        });
    };

    FileHelper.prototype.setActiveAndMap = function () {
        this.activeFile = _.findLast(this.files, "active");
        this.fileMap = _.keyBy(this.files, "vid");
    };

    // method to handle comparison selections
    FileHelper.prototype.setSelectedFilesToCompare = function (
        clear,
        vid,
        displayIndex,
    ) {
        // Optionally clear the selected Ids
        if (clear) {
            this.selectedIdsToCompare = {};
        }

        // Find the selected articleIds for which the checkbox is marked "true"
        const filteredIdsToCompare = _.pickBy(
            this.selectedIdsToCompare,
            (value) => value,
        );

        // Map the vids to files
        this.selectedFilesToCompare = _.map(
            filteredIdsToCompare,
            (value, key) => {
                const file = this.fileMap[key];
                if (vid === key) {
                    file.index = displayIndex;
                }
                return file;
            },
        );

        // sort the files by displayIndex
        this.selectedFilesToCompare = _.sortBy(
            this.selectedFilesToCompare,
            "displayIndex",
        );

        return this.selectedIdsToCompare;
    };

    FileHelper.prototype.setSelectedFilesToRemove = function (clear) {
        // optionally clear the selected Ids in the UI
        if (clear) {
            this.selectedIdsToRemove = null;
        }

        // find the selected articleIds for which the checkBox is marked "true"
        const filteredIdsToRemove = _.pickBy(
            this.selectedIdsToRemove,
            (value) => value,
        );
        // map the vids to files
        this.selectedFilesToRemove = _.map(
            filteredIdsToRemove,
            (value, key) => this.fileMap[key],
        );
        console.log("selected files to remove:", this.selectedFilesToRemove);

        return this.selectedIdsToRemove;
    };

    FileHelper.prototype.removeFilesFromUI = function () {
        this.files = _.differenceBy(
            this.files,
            this.selectedFilesToRemove,
            "vid",
        );
        this.setActiveAndMap();
    };

    angular.module("cpir").controller(
        "DashboardEditorTOCRepoVersionsController",

        class {
            constructor(
                pid,
                entryId,
                FileService,
                NotificationService,
                $uibModal,
                $q,
                $state,
                TOCViewModeService,
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.FileService = FileService;
                this.NotificationService = NotificationService;
                this.$uibModal = $uibModal;
                this.$q = $q;
                this.$state = $state;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                FileHelper.prototype.FileService = this.FileService;
                FileHelper.prototype.eid = this.entryId;

                this.articleHelper = new FileHelper(
                    "article",
                    false,
                    "article",
                );
                this.stampHelper = new FileHelper("stamp", true, "stamp");
                this.sourceHelper = new FileHelper("source", true, "source");
                this.extraHelper = new FileHelper("extra", true, "supplement");
                this.oaDocumentHelper = new FileHelper(
                    "oa-ccby",
                    true,
                    "open access document",
                );
            }

            getFile(file) {
                this.FileService.getFile(file.vid);
            }

            updateActiveVersion(file, fileHelper) {
                this.FileService.activateFileVersion(file)
                    .then((result) => {
                        fileHelper.load();
                    })
                    .catch((err) => console.log(err));
            }

            compareSelectedVersions(fileHelper) {
                const selectedFiles = fileHelper.selectedFilesToCompare;

                console.log("selected files to compare:", selectedFiles);

                if (selectedFiles.length < 1) {
                    this.NotificationService.send(
                        "warning",
                        "Please select files to compare",
                    );
                    return;
                }

                // Create array of PDF metadata objects using the stored indices
                const pdfMetadata = selectedFiles.map((file) => {
                    return {
                        vid: file.vid,
                        title: file.original || "",
                        number: `Version ${file.index}`, // Use the stored index
                    };
                });

                // Navigate to the PDF viewer with metadata
                this.$state.go(
                    "dashboard-editor.table-of-contents-multi-pdf-viewer",
                    {
                        pid: this.pid,
                        pdfMetadata,
                        previousState:
                            "dashboard-editor.table-of-contents-repository-versions",
                        previousStateParams: {
                            pid: this.pid,
                            entryId: this.entryId,
                        },
                    },
                );

                fileHelper.setSelectedFilesToCompare(true);
            }

            reviewStamp() {
                const file = this.stampHelper.activeFile;
                this.stampHelper.selectedIdsToCompare[file.vid] = true;
                this.stampHelper.setSelectedFilesToCompare();
                this.compareSelectedVersions(this.stampHelper);
            }

            removeFiles(fileHelper) {
                // get the file objects being removed
                const selectedFilesToRemove = fileHelper.selectedFilesToRemove;
                if (selectedFilesToRemove.length < 1) {
                    return;
                }

                // clear the list of selected IDs. This will disable the delete button
                fileHelper.setSelectedFilesToRemove(true);

                // generate HTML markup for the confirmation window
                const fileTitleList = selectedFilesToRemove.map(
                    (file) => file.original,
                );
                const fileTitlesString = `<ul><li>${_.join(
                    fileTitleList,
                    "</li><li>",
                )}</li></ul>`;

                // confirm the selection
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () =>
                                `<p>Are you sure you want to delete the selected ${fileHelper.typeFriendlyName} file(s)?</p>${fileTitlesString}`,
                        },
                    })
                    .result.then(() => {
                        // remove the file from the list of files
                        fileHelper.removeFilesFromUI();
                        // remove the files from the DB
                        return this.FileService.removeFileVersions(
                            selectedFilesToRemove,
                        );
                    })
                    .then((results) => {
                        console.log("remove: ", results);
                        this.NotificationService.send(
                            "success",
                            `The ${fileHelper.typeFriendlyName} file was removed successfully`,
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        this.NotificationService.send(
                            "warning",
                            `There was an error in removing the ${fileHelper.typeFriendlyName} file.`,
                        );
                    })
                    // reload the files of the selected type
                    .then(() => {
                        fileHelper.load();
                    })
                    .catch((e) => console.log(e));
            }

            // Add a method to return to TOC with proper view mode
            returnToTOC() {
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }
        },
    );
})();
