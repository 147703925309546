(function() {
    "use strict";

    angular.module("cpir").provider(
        "IThenticateService",
        class {
            $get($http) {
                function catchError(err) {
                    console.log(err);
                    throw err.data;
                }

                function batchSubmitDocuments(pid, entryIds) {
                    return $http
                        .post(`/ithenticate/${pid}/document`, entryIds)
                        .catch(catchError)
                        .then(({ data }) => data);
                }

                function getDocumentStatus(pid, documentId) {
                    return $http
                        .get(`/ithenticate/${pid}/document/${documentId}`)
                        .catch(catchError)
                        .then(({ data }) => data);
                }

                function getSimilarityReportLink(pid, documentId) {
                    return $http
                        .get(
                            `/ithenticate/${pid}/document/${documentId}/report`
                        )
                        .catch(catchError)
                        .then(({ data: { view_only_url } }) => view_only_url);
                }

                function createFolder(pid) {
                    return $http
                        .post(`/ithenticate/${pid}/folder`, {})
                        .catch(catchError)
                        .then(({ data }) => data);
                }

                return {
                    createFolder,
                    batchSubmitDocuments,
                    getDocumentStatus,
                    getSimilarityReportLink
                };
            }
        }
    );
})();
