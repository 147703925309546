(function() {
    "use strict";

    const PRODUCTION_HOSTS = [
        "ieeecps.org",
        "www.ieeecps.org",
        "preprod.ieeecps.org",
        "ieeepubhub.org",
        "www.ieeepubhub.org"
    ];
    const TEST_HOSTS = ["cpir-uat.computer.org", "cpir-test.computer.org"];

    const PRODUCTION = "production";
    const DEVELOPMENT = "development";
    const TEST = "test";

    angular.module("cpir").provider(
        "EnvironmentService",
        class {
            $get($location) {
                /**
                 * Public method returns if env. is development.
                 * @return {boolean}
                 */
                let isDevelopment = () => {
                    return getEnvironment() === DEVELOPMENT;
                };

                /**
                 * Public method returns if env. is test (UAT).
                 * @return {boolean}
                 */
                let isTest = () => {
                    return getEnvironment() === TEST;
                };

                /**
                 * Public method returns if env. is development or test.
                 * @return {boolean}
                 */
                let isDevelopmentOrTest = () => {
                    return (
                        getEnvironment() === DEVELOPMENT ||
                        getEnvironment() === TEST
                    );
                };

                /**
                 * Public method returns if env. is production.
                 * @return {boolean}
                 */
                let isProduction = () => {
                    return getEnvironment() === PRODUCTION;
                };

                /**
                 * Private helper method that returns a string of which
                 * environment is currently being accessed. It does this by
                 * checking checking the server's host name against a list
                 * of predefined host names. If production or test is not
                 * found first, development will be chosen by default.
                 * @return {string}
                 */
                let getEnvironment = () => {
                    const currentHost = $location.host();

                    if (PRODUCTION_HOSTS.indexOf(currentHost) > -1) {
                        //console.log("Loading production environment.");
                        return PRODUCTION;
                    }

                    if (TEST_HOSTS.indexOf(currentHost) > -1) {
                        //console.log("Loading test environment.");
                        return TEST;
                    }

                    //console.log("Loading dev. environment by default.");
                    return DEVELOPMENT;
                };

                return {
                    isDevelopment,
                    isTest,
                    isDevelopmentOrTest,
                    isProduction
                };
            }
        }
    );
})();
