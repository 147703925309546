(function() {
    "use strict";

    angular.module("cpir").component("cpirCifQuotesProductTable", {
        bindings: {
            media: "<",
            product: "<",
            onRemove: "&"
        },
        templateUrl:
            "application/cpir/cif/quotes/components/product-table/product-table.component.html",
        controller: class {
            constructor($uibModal) {
                this.$uibModal = $uibModal;
            }

            $onChanges(changes) {
                if (changes.media && changes.media.currentValue) {
                    // Get the type of product (medium)
                    this.medium = this.media.find(
                        m => m.mid === this.product.mid
                    );
                }
            }

            $onInit() {
                // Get a list of the names of the attributes
                this.attributeNames = this.product.items[0]
                    .sort((a, b) => {
                        if (a.order === null || a.order === undefined) {
                            return 0;
                        }
                        if (b.order === null || b.order === undefined) {
                            return 0;
                        } else {
                            return a.order - b.order;
                        }
                    })
                    .map(p => {
                        return p.name;
                    });
            }

            updateItem(item, index) {
                this.$uibModal
                    .open({
                        component: "cifQuotesProductModal",
                        resolve: {
                            item: () => item,
                            medium: () => this.medium,
                            isEditing: () => true
                        }
                    })
                    .result.then(
                        updatedItem => {
                            this.product.items[index] = updatedItem;
                        },
                        reason => {}
                    );
            }

            removeButtonClicked($index) {
                this.onRemove({ index: $index });
            }
        }
    });
})();
