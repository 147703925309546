(function() {
    "use strict";

    angular.module("cpir").factory("UserService", [
        "$q",
        "$http",
        "apiService",
        function($q, $http, api) {
            let items = api.items;

            let UserService = {};
            let userMap = {};

            UserService.get = profile => {
                let result;
                if (!profile) {
                    result = null;
                } else if (userMap[profile.sub]) {
                    result = userMap[profile.sub];
                } else {
                    result = $http.get(`/user`).then(response => {
                        userMap[profile.sub] = result;
                        return response.data;
                    });
                }
                return $q.when(result);
            };

            UserService.list = ({ onlyEditors = false }) => {
                const path = `users${onlyEditors ? "?editor" : ""}`;
                return api.get(path).then(items);
            };

            UserService.logout = () => (userMap = {});

            return UserService;
        }
    ]);
})();
