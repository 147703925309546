(function() {
    "use strict";

    angular.module("cpir").component("cpirAkApprovalModal", {
        bindings: {
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/approval-modal/approval-modal.component.html",
        controller: class {
            constructor(
                $state,
                $stateParams,
                ContactService,
                DeepLinkService,
                EmailService,
                ProceedingService,
                NotificationService,
                ProceedingStatusService
            ) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.ContactService = ContactService;
                this.EmailService = EmailService;
                this.DeepLinkService = DeepLinkService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.submission = {};
                this.submission.date = new Date();

                this.booleanOptions = [
                    { name: "Approved", value: true },
                    { name: "Disapproved", value: false }
                ];

                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;
                });
            }

            saveSubmission() {
                this.proceeding.configuration.settings.ak.approval.isApproved = this.submission.isApproved;
                this.proceeding.configuration.settings.ak.approval.submissions.push(
                    this.submission
                );

                // If the AK was approved, log the response and forward the proceeding
                // into the AK approved stage.
                //
                // If it's not approved, log the response
                // and take the client to the rejection page.
                if (this.submission.isApproved) {
                    this.ProceedingService.update(this.pid, this.proceeding)
                        .then(() => {
                            this.ContactService.getConferenceOrganizer(
                                this.proceeding
                            )
                                .then(organizer => {
                                    if (organizer) {
                                        let organizerName = `${organizer.givenName} ${organizer.surname}`;
                                        let emailProps = {
                                            to: [organizer.email],
                                            data: {
                                                organizerName,
                                                proceeding: this.proceeding,
                                                akLink: this.DeepLinkService.getAkDeepLink(
                                                    this.pid
                                                )
                                            }
                                        };
                                        this.EmailService.sendImmediately(
                                            "ak-approved",
                                            emailProps
                                        ).catch(e => console.log(e));
                                    }
                                })
                                .catch(e => console.log(e));

                            this.ProceedingStatusService.setAkActive(
                                this.pid
                            ).then(() => {
                                this.NotificationService.send(
                                    "success",
                                    "Your response was saved successfully."
                                );
                                this.close();
                                this.$state.go("ak.approval-acceptance", {
                                    pid: this.pid
                                });
                            });
                        })
                        .catch(e => console.log(e));
                } else {
                    this.ProceedingService.update(
                        this.pid,
                        this.proceeding
                    ).then(() => {
                        this.close();
                        this.$state.go("ak.approval-denied", { pid: this.pid });
                        this.NotificationService.send(
                            "success",
                            "Your response was saved successfully."
                        );
                    });
                }

                // .then(() => {
                //     this.NotificationService.send('success', 'Your response was saved successfully.');
                //     this.close();
                //
                //     if (this.proceeding.configuration.settings.ak.approval.isApproved) {
                //
                //     } else {
                //
                //     }
                // });
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
