(function() {
    "use strict";

    angular.module("cpir").directive("errors", Errors);

    function Errors() {
        return {
            restrict: "A",
            template: template
        };
        function template() {
            return (
                `<alert data-ng-if="!cpir.settings.showAdvancedErrors && errors.length > 0 && (errors | hasDefinedError) == false" type="danger">An error occurred while processing the request.</alert>` +
                `<alert data-ng-if="!cpir.settings.showAdvancedErrors" data-ng-repeat="error in errors | definedError" type="danger" >{{ error.message }}</alert>` +
                `<alert data-ng-if="cpir.settings.showAdvancedErrors"  data-ng-repeat="error in errors" type="danger">{{ error.code }}</alert>`
            );
        }
    }
})();
