(function() {
    "use strict";

    angular.module("cpir").filter("tocDownloadsMaxAgeFilter", [
        "moment",
        function(moment) {
            return function(downloads) {
               return downloads.filter((download) => {
                    if (download.exportStartTime) {
                        const downloadCreationTime = moment(download.exportStartTime);
                        if(downloadCreationTime.isAfter(moment().subtract(3, "days")))
                            return true;
                        }
                });
            }
        }
    ]);
})();
