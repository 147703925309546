(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCEditEntryController",
        class {
            constructor(
                pid,
                entryId,
                $state,
                ProceedingService,
                TocEntriesApiService,
                TOCValuesService,
                NotificationService,
                $uibModal,
                AKService,
                FileService,
                store,
                TocNavigationService,
                TOCViewModeService,
            ) {
                this.entryId = entryId;
                this.pid = pid;
                this.$state = $state;
                this.$uibModal = $uibModal;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.FileService = FileService;
                this.TOCValuesService = TOCValuesService;
                this.AKService = AKService;
                this.store = store;
                this.TocNavigationService = TocNavigationService;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                this.TocEntriesApiService.getEntry(this.entryId)
                    .then((entry) => {
                        this.entry = entry;
                    })
                    .catch((err) => {
                        console.error(err);
                    });

                this.FileService.getFilesMetadataFoEntry(
                    this.entryId,
                    null,
                    true,
                ).then((entryFiles) => {
                    this.article = entryFiles.find(
                        (file) => file.type === "article",
                    );
                    this.stamp = entryFiles.find(
                        (file) => file.type === "stamp",
                    );
                    const windowLocation = window.location.href;
                    const protocol = windowLocation.split("/")[0];
                    const domain = windowLocation.split("/")[2];
                    const vid =
                        (this.stamp && this.stamp.vid) ||
                        (this.article && this.article.vid);
                    if (vid) {
                        const idToken = this.store.get("id_token");
                        this.pdfUrl = `${protocol}//${domain}/files/${vid}?id_token=${idToken}`;
                    }
                });

                this.productionStatusOptions =
                    this.TOCValuesService.productionStatusOptions;

                this.copyrightTypes = this.TOCValuesService.copyrightTypes;

                this.verificationOptions =
                    this.TOCValuesService.verificationOptions;

                this.pdfExpressOptions =
                    this.TOCValuesService.pdfExpressOptions;

                this.classOptions = this.TOCValuesService.classOptions;

                this.typeOptions = this.TOCValuesService.typeOptions;

                // Get next/previous entries
                this.nextEntry = this.TocNavigationService.getNextEntry();
                this.previousEntry =
                    this.TocNavigationService.getPreviousEntry();
            }

            // Add this method to handle saving and navigation
            saveAndNavigate(entry) {
                if (!entry) return;

                // First save current entry
                if (!this.entry.enablePromoDates) {
                    this.entry.promoDates = null;
                }

                // force submitter email to be lowercase (for author kit user lookup)
                this.entry.submitterEmail =
                    this.entry.submitterEmail &&
                    this.entry.submitterEmail.toLowerCase().trim();

                // remove empty authors
                this.entry.authors = this.entry.authors.filter(
                    (author) =>
                        author.givenName ||
                        author.surname ||
                        author.affiliation ||
                        author.email ||
                        author.orcid,
                );

                return this.TocEntriesApiService.updateEntries(this.entry)
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The entry was saved successfully.",
                        );
                        // Then navigate to next/previous entry
                        this.TocNavigationService.setCurrentEntry(entry.eid);
                        this.$state.go(
                            "dashboard-editor.table-of-contents-edit-entry",
                            {
                                pid: this.pid,
                                entryId: entry.eid,
                            },
                        );
                    })
                    .catch((err) => {
                        console.error(err);
                        this.NotificationService.send(
                            "danger",
                            "Failed to save changes before navigation.",
                        );
                    });
            }

            goToNextEntry() {
                this.saveAndNavigate(this.nextEntry);
            }

            goToPreviousEntry() {
                this.saveAndNavigate(this.previousEntry);
            }

            saveChanges() {
                if (!this.entry.enablePromoDates) {
                    this.entry.promoDates = null;
                }
                // force submitter email to be lowercase (for author kit user lookup)
                this.entry.submitterEmail =
                    this.entry.submitterEmail &&
                    this.entry.submitterEmail.toLowerCase().trim();

                // remove empty authors
                this.entry.authors = this.entry.authors.filter(
                    (author) =>
                        author.givenName ||
                        author.surname ||
                        author.affiliation ||
                        author.email ||
                        author.orcid,
                );

                return this.TocEntriesApiService.updateEntries(this.entry).then(
                    () => {
                        this.NotificationService.send(
                            "success",
                            "The entry was saved successfully.",
                        );
                        // Use TOCViewModeService to navigate to the correct view mode
                        this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                            this.pid,
                        );
                    },
                );
            }

            updateEntry(entry) {
                this.entry = entry;
            }

            editAuthors() {
                this.$state.go(
                    "dashboard-editor.table-of-contents-edit-entry-authors",
                    {
                        pid: this.pid,
                        entryId: this.entryId,
                    },
                );
            }

            editFiles() {
                this.$state.go(
                    "dashboard-editor.table-of-contents-edit-entry-files",
                    {
                        pid: this.pid,
                        entryId: this.entryId,
                    },
                );
            }

            archiveEntry() {
                this.AKService.checkActive([this.entryId])
                    .then((activeAks) => {
                        let hasOpen = activeAks.length;
                        let entryListHtml = `<p><strong>CAUTION: This entry is currently open in an author kit</strong></p>`;
                        this.$uibModal
                            .open({
                                component: "cpirConfirmationModal",
                                resolve: {
                                    title: () => "Warning",
                                    message: () =>
                                        `${
                                            hasOpen ? entryListHtml : ""
                                        }<p>Are you sure you want to delete the selected entry?</p>`,
                                },
                            })
                            .result.then(() => {
                                this.TocEntriesApiService.deleteEntries(
                                    new Array(this.entryId),
                                )
                                    .then(() => {
                                        this.NotificationService.send(
                                            "success",
                                            "The entry was deleted successfully.",
                                        );
                                        this.$state.go(
                                            "dashboard-editor.table-of-contents",
                                            { pid: this.pid },
                                        );
                                    })
                                    .catch((e) => console.error(e));
                            })
                            .catch((dismiss) => {});
                    })
                    .catch((err) => console.error(err));
            }

            toggleDoiLock() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () =>
                                this.entry.isDoiLocked
                                    ? `Are you sure you want to unlock this DOI?`
                                    : `Are you sure you want to lock this DOI?`,
                        },
                    })
                    .result.then(() => {
                        return this.TocEntriesApiService.getEntry(this.entryId);
                    })
                    .then((entry) => {
                        entry.isDoiLocked = !entry.isDoiLocked;
                        return this.TocEntriesApiService.updateEntries(
                            entry,
                        ).then(() => entry);
                    })
                    .then((entry) => {
                        this.entry.isDoiLocked = entry.isDoiLocked;
                        console.log(this.entry.isDoiLocked);
                    })
                    .catch((e) => console.log(e));
            }

            returnToToc() {
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }
        },
    );
})();
