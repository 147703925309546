(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceFileUpload", {
        bindings: {
            isDisabled: "<",
            isErrorsVisible: "<",
            pid: "<",
            complianceFiles: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/file-upload/file-upload.component.html",
        controller: class {
            constructor(FileService) {
                this.FileService = FileService;
            }

            $onInit() {}

            removeComplianceFile(index) {
                const fileToRemove = this.complianceFiles[index];
                this.complianceFiles.splice(index, 1);
                this.FileService.removeComplianceFileForProceeding(
                    this.proceeding,
                    fileToRemove.filename
                );
                this.complianceFilesChanged();
            }

            complianceFilesChanged() {
                this.onUpdate({
                    complianceFiles: this.complianceFiles
                });
            }

            openFile(file) {
                this.FileService.getComplianceFileForProceeding(
                    this.pid,
                    file.name
                );
            }
        }
    });
})();
