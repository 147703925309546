(function() {
    "use strict";

    angular.module("cpir").provider(
        "MediaService",
        class {
            constructor() {}
            $get($q, apiService) {
                return {
                    getMedia: () => {
                        let deferred = $q.defer();
                        apiService.get("media").then(
                            result => deferred.resolve(result.data.items),
                            error => deferred.reject(error)
                        );
                        return deferred.promise;
                    }
                };
            }
        }
    );
})();
