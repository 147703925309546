(function () {
    "use strict";

    angular.module("cpir").component("cpirPdfViewer", {
        bindings: {
            pdfUrl: "<",
            height: "<",
        },
        templateUrl:
            "application/cpir/_shared/components/pdf-viewer/pdf-viewer.component.html",
        controller: class {
            constructor($sce, $element, $scope, WebSocketService) {
                this.$sce = $sce;
                this.$element = $element;
                this.$scope = $scope;
                this.isLoading = true;
                this.WebSocketService = WebSocketService;
            }

            getPdfUrl() {
                if (!this.pdfUrl) {
                    console.warn("PDF URL is not defined");
                    return "";
                }
                return this.$sce.trustAsResourceUrl(this.pdfUrl);
            }

            $onInit() {
                this.height = this.height || "140vh";
                this.updateHeight();
            }

            $onChanges(changes) {
                if (changes.pdfUrl) {
                    // Reset loading state when URL changes
                    this.isLoading = true;
                }
                if (changes.height) {
                    this.updateHeight();
                }
            }

            updateHeight() {
                if (this.height) {
                    this.$element.css("height", this.height);
                }
            }

            onPdfLoad() {
                this.isLoading = false;
                this.WebSocketService.safeApply(this.$scope);
            }

            onPdfError() {
                this.isLoading = false;
                this.hasError = true;
                this.WebSocketService.safeApply(this.$scope);
            }
        },
    });
})();
