(function() {
    "use strict";

    angular.module("cpir").component("cpirUserListAddUserModal", {
        bindings: {
            size: "@",
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/conf-user-list-panel/components/cpir-user-list-add-user-modal/cpir-user-list-add-user-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.user = this.resolve.user;
                this.role = this.resolve.role;
                this.modalTitle = this.resolve.modalTitle;
            }

            cancelButtonClicked() {
                this.dismiss();
            }

            saveButtonClicked() {
                this.close({
                    $value: this.user
                });
            }
        }
    });
})();
