(function() {
    "use strict";

    angular.module("cpir").component("cpirCifWorkshopSwitcher", {
        bindings: {
            isDisabled: "<",
            workshops: "<",
            activeWorkshopIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/cif/workshops/components/workshops-panel/components/workshop-switcher/workshop-switcher.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            buttonClicked($index) {
                if (this.isDisabled === false) {
                    this.onSwitch({ workshopIndex: $index });
                }
            }
        }
    });
})();
