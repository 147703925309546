(function() {
    "use strict";

    /**
     * This service provides functions needed for the CIF.
     */
    angular.module("cpir").provider(
        "CifService",
        class {
            constructor() {}

            $get(
                $q,
                authService,
                store,
                ConfigurationService,
                ProceedingService,
                ProceedingStatusService
            ) {
                /**
                 * Get the config from the browser storage if it had
                 * already been placed there from the last time this
                 * service was loaded (for caching between steps).
                 *
                 * Still, though, get the config from the API and
                 * replace the cached version after it is loaded.
                 *
                 * This has the effect of minimizing perceived loading
                 * delays of the dynamic portions of the CIF.
                 */
                let configuration = store.get("global.configuration");

                if (configuration) {
                    this.configuration = configuration;
                }

                ConfigurationService.get().then(result => {
                    let brand = authService.getBrand();

                    if (brand.name === "cps")
                        this.configuration = result.configuration.cps;
                    else if (brand.name === "pubhub")
                        this.configuration = result.configuration.pubhub;

                    store.set("global.configuration", this.configuration);
                });

                let getConfiguration = () => this.configuration;

                /**
                 * Breakout workshops from the proceeding into their
                 * own proceedings (with a flag designating they are
                 * workshops, hidden, and the parent pid).
                 */
                let breakoutWorkshops = proceeding => {
                    let workshops = proceeding.workshops;

                    if(workshops!== undefined) {
                        let newWorkshops = workshops.filter(w => !w.pid && w.title && w.acronym);
                        let existingWorkshops = workshops.filter(w => w.pid);

                        newWorkshops.forEach(w => {
                            w.brand = proceeding.brand;
                        });

                        existingWorkshops.forEach(w => {
                            w.brand = proceeding.brand;
                        });

                        return saveNewWorkshops(
                            newWorkshops,
                            existingWorkshops,
                            proceeding
                        )
                            .then(() =>
                                updateOldWorkshops(existingWorkshops, proceeding)
                            )
                            .then(() => console.log("Finished saving workshops."))
                            .catch(e => console.log(e));
                    }
                };

                let saveNewWorkshops = (
                    newWorkshops,
                    existingWorkshops,
                    proceeding
                ) => {
                    if (newWorkshops && newWorkshops.length > 0) {
                        console.log("Starting save process.");
                        return getWorkshopSettings(proceeding)
                            .then(config => {
                                return newWorkshops.map(workshop => {
                                    return buildWorkshop(
                                        workshop,
                                        proceeding,
                                        config
                                    );
                                });
                            })
                            .then(workshops =>
                                ProceedingService.batchCreate(workshops)
                            )
                            .then(pids => {
                                if (pids) {
                                    return newWorkshops.map((w, index) => {
                                        w.pid = pids[index];
                                        return w;
                                    });
                                }
                            })
                            .then(createdWorkshops => {
                                let combinedWorkshops = existingWorkshops
                                    ? createdWorkshops.concat(existingWorkshops)
                                    : createdWorkshops;
                                proceeding.workshops = combinedWorkshops;
                                return ProceedingService.update(
                                    proceeding.pid,
                                    proceeding
                                );
                            });
                    } else {
                        console.log("Skipping adding new workshops.");
                        return $q.when(0);
                    }
                };

                let updateOldWorkshops = (existingWorkshops, proceeding) => {
                    // Only update workshops when there are existing workshops
                    // to update.
                    if (existingWorkshops && existingWorkshops.length > 0) {
                        console.log("Starting to update existing workshops.");
                        // Get the PIDs of the workshops to update
                        let workshopPids = existingWorkshops.map(w => w.pid);
                        // Get the workshops that need to be updated
                        console.log("Getting existing from API.");
                        return ProceedingService.batchGetWorkshops(
                            proceeding.pid,
                            workshopPids
                        )
                            .then(workshops => {
                                // For each workshop returned by the API, update
                                // the workshop with the values from the edited
                                // workshop
                                console.log(
                                    "Got existing from API, begin editing."
                                );
                                workshops.forEach(w => {
                                    let existingWorkshop = existingWorkshops.find(
                                        ew => w.pid === ew.pid
                                    );
                                    w.title = existingWorkshop.title;
                                    w.acronym = existingWorkshop.acronym;
                                    w.dates = existingWorkshop.dates;
                                    w.icxId = existingWorkshop.icxId;
                                    w.location = existingWorkshop.location;
                                    w.postalCode = existingWorkshop.postalCode;
                                    w.webURL = existingWorkshop.webURL;
                                });
                                return workshops;
                            })
                            .then(updatedWorkshops => {
                                // Update all of the workshops in the updated list.
                                console.log("Saving back edited workshops.");
                                let promises = [];
                                updatedWorkshops.forEach(w => {
                                    promises.push(
                                        ProceedingService.update(w.pid, {"title": w.title, "acronym":w.acronym, "dates": w.dates, "icxId" : w.icxId,
                                            "location": w.location, "postalCode": w.postalCode, "webURL": w.webURL})
                                    );
                                });
                                return $q.all(promises);
                            })
                            .then(() => {
                                console.log("Done saving workshops.");
                                return ProceedingService.update(
                                    proceeding.pid,
                                    {"workshops" : proceeding.workshops, "hasSeparatePublicationsInXplore": proceeding.hasSeparatePublicationsInXplore}
                                );
                            });
                    } else {
                        return $q.when(0);
                    }
                };

                let deleteWorkshop = (workshop, proceeding) => {
                    if (!workshop) throw "Workshop is required.";
                    if (!proceeding) throw "Proceeding is required.";
                    workshop.isWorkshopDeleted = true;
                    workshop.status = 0;
                    return ProceedingService.update(
                        workshop.pid,
                        workshop
                    ).then(() => {
                        let index = proceeding.workshops.findIndex(
                            w => w.pid === workshop.pid
                        );
                        if (index >= 0) {
                            proceeding.workshops.splice(index, 1);
                            ProceedingService.update(
                                proceeding.pid,
                                proceeding
                            );
                        }
                    });
                };

                let buildWorkshop = (workshop, proceeding, config) => {
                    let isPASigned = ProceedingStatusService.isAtLeastInternalSignatory(
                        proceeding
                    );
                    let builtWorkshop = {
                        isWorkshop: true,
                        originator: proceeding.originator,
                        parent: proceeding.pid,
                        contacts: proceeding.contacts,
                        year: proceeding.year,
                        status: isPASigned ? 1 : 0,
                        brand: workshop.brand,
                        acronym: workshop.acronym,
                        dates: workshop.dates,
                        icxId: workshop.icxId,
                        title: workshop.title,
                        configuration: config,
                        editor: proceeding.editor,
                        location: workshop.location,
                        postalCode: workshop.postalCode,
                        webURL: workshop.webURL
                    };
                    if (isPASigned) {
                        builtWorkshop.productionSchedule =
                            proceeding.productionSchedule;
                    }
                    return builtWorkshop;
                };

                let getWorkshopSettings = proceeding => {
                    return ConfigurationService.get()
                        .then(config => {
                            let workshopConfig;

                            if (proceeding.brand.name === "cps")
                                workshopConfig = config.configuration.cps;
                            else if (proceeding.brand.name === "pubhub")
                                workshopConfig = config.configuration.pubhub;
                            else throw "Brand could not be determined.";

                            try {
                                workshopConfig.settings.steps.status =
                                    "ak-setup";
                            } catch (e) {
                                throw "Could not set the default AK status for workshop.";
                            }

                            return workshopConfig;
                        })
                        .catch(e => console.log(e));
                };

                return { breakoutWorkshops, deleteWorkshop, getConfiguration };
            }
        }
    );
})();
