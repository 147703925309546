(function() {
    "use strict";

    angular.module("cpir").provider(
        "ProfileService",
        class {
            $get($http) {
                function get() {
                    return $http
                        .get("/auth/user/profile", {
                            "content-type": "application/json"
                        })
                        .then(result => result.data)
                        .catch(err => {
                            console.error(err);
                            throw err;
                        });
                }

                return { get };
            }
        }
    );
})();
