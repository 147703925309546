(function() {
    "use strict";

    angular.module("cpir").component("cpirTocConfirmAuthorCommunicationModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/confirm-author-communication-modal/confirm-author-communication-modal.component.html",
        controller: class {
            constructor(TOCValuesService, ConfigurationService, NotificationService) {
                this.TOCValuesService = TOCValuesService;
                this.ConfigurationService = ConfigurationService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.template = null;
                this.needStatusChange = false;
                this.productionStatus = "";
                _.entries(this.resolve).forEach(
                    entry => (this[entry[0]] = entry[1])
                );
                this.proceeding = this.resolve.proceeding;
                this.productionStatusOptions = this.TOCValuesService.productionStatusOptions.filter(
                    option => option.value !== "Undefined"
                );
                this.productionStatusOptions = [...this.productionStatusOptions];

                this.ConfigurationService.get()
                    .then(config => {
                        if (this.brand === "cps") {
                            this.configuration =
                                config.configuration.cps;
                        } else if (this.brand === "pubhub") {
                            this.configuration =
                                config.configuration.pubhub;
                        }
                        this.templates = config.configuration.cps.settings.email.emailTemplates.filter(
                            option => (option.key.startsWith("author-comm"))
                                //  option => (option.name === "Author Comm: Author Kit Author Review" || option.name === "Author Comm: Introductory & Login Email for Preloaded Projects"
                            //    || option.name === "Author Comm: Incomplete Submission Email" )
                            );
                    });
            }

            sendButtonClicked() {
                if(this.template === null)
                    this.NotificationService.send(
                        "warning",
                        "Choose an email template for author communication...",
                    );
                else if(!this.proceeding.productionSchedule) {
                        this.NotificationService.send(
                            "warning",
                            "Conference is missing production schedule. Please add.",
                        );
                    } else if(!this.proceeding.dates || (this.proceeding.dates && (!this.proceeding.dates.start || !this.proceeding.dates.end || !this.proceeding.dates.delivery))) {
                        this.NotificationService.send(
                            "warning",
                            "Conference is missing start/end/delivery dates. Please add.",
                        );
                    }

                else {
                    const statusToChange = this.TOCValuesService.productionStatusOptions.find(
                        option => option.value === this.productionStatus
                    );
                    this.close({
                        $value: {
                            template: this.template,
                            needStatusChange: this.needStatusChange,
                            status: statusToChange? statusToChange: null
                        }
                    });
                }
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
