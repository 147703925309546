(function() {
    "use strict";

    /*
     * Description: The PA process adds attributes
     * to the products for pricing, but these attributes
     * need to be distinguished from regular product
     * attributes.
     */

    angular
        .module("cpir")
        .filter("cpirQuoteIsPricingAttributeNameFilter", () => {
            return attributeNames => {
                if (!attributeNames) return attributeNames;
                attributeNames.forEach((elem, index, array) => {
                    if (elem === "unitPrice" || elem === "totalPrice") {
                        array.splice(index, 1);
                    }
                });
                return attributeNames;
            };
        });

    angular.module("cpir").filter("cpirQuoteIsPricingAttributeFilter", () => {
        return items => {
            if (!items) return items;
            items.forEach((attributes, index, array) => {
                attributes.forEach((attr, index, array) => {
                    if (
                        attr.name === "unitPrice" ||
                        attr.name === "totalPrice"
                    ) {
                        array.splice(index, 1);
                    }
                });
            });
            return items;
        };
    });
})();
