(function() {
    angular.module("cpir").component("cpirComplianceMemberSwitcher", {
        bindings: {
            members: "<",
            activeMemberIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/members-panel/components/member-switcher/member-switcher.component.html",
        controller: class {
            buttonClicked($index) {
                this.onSwitch({ memberIndex: $index });
            }
        }
    });
})();
