(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifConferenceController",

        class CifConferenceController {
            constructor(
                pid,
                $q,
                $scope,
                $state,
                $stateParams,
                $uibModal,
                CifService,
                authService,
                ConfigurationService,
                ProceedingStatusService,
                NotificationService,
                ProceedingService,
                TimerService
            ) {
                this.pid = pid;
                this.$q = $q;
                this.$scope = $scope;
                this.$state = $state;
                this.$uibModal = $uibModal;
                this.$stateParams = $stateParams;
                this.CifService = CifService;
                this.NotificationService = NotificationService;
                this.ConfigurationService = ConfigurationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.authService = authService;
                this.TimerService = TimerService;
            }

            $onInit() {
                this.isLoading = true;
                this.loadWaitAsync = this.TimerService.getLoadingTimer();
                this.brand = this.authService.getBrand().name;

                if (this.pid) {
                    this.ProceedingService.get(this.pid).then(proceeding => {
                        this.proceeding = proceeding;
                        this.isProceedingValid = true;
                        this.loadWaitAsync.then(() => {
                            this.isLoading = false;
                            this.TimerService.applyTimer(this.$scope);
                        });
                    });
                } else {
                    this.isProceedingValid = false;

                    this.ConfigurationService.get().then(config => {
                        if (this.brand === "cps")
                            this.proceedingConfig = config.configuration.cps;
                        else if (this.brand === "pubhub")
                            this.proceedingConfig = config.configuration.pubhub;

                        this.proceeding.editor = this.proceedingConfig.defaultEditor;
                        this.loadWaitAsync.then(() => {
                            this.isLoading = false;
                            this.TimerService.applyTimer(this.$scope);
                        });
                    });
                }

                this.configuration = this.CifService.getConfiguration();
            }

            preSaveCheck() {
                if (this.isSponsoringAgencyRequired() && this.brand === "cps") {
                    this.$uibModal
                        .open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                title: () => "Disclaimer",
                                message: () =>
                                    "You have stated your conference is not sponsored by IEEE. A mandatory compliance review will be required once your agreement is received.<br/><br/>Any workshops or events added must be co-budgeted with the main conference. Those that are not must be submitted separately to receive a quote.",
                                cancelButtonDisabled: () => true
                            }
                        })
                        .result.then(() => {
                            this.saveProceeding();
                        });
                } else {
                    this.saveProceeding();
                }
            }

            saveProceeding() {
                this.TimerService.getLoadingTimer();
                this.isLoading = true;
                if (this.pid) {
                    this.ProceedingService.update(
                        this.pid,
                        this.proceeding
                    ).then(() => {
                        this.NotificationService.send(
                            "success",
                            "The conference was saved successfully."
                        );
                        this.goToNextState();
                    });
                } else {
                    this.proceeding.configuration = this.proceedingConfig;
                    this.ProceedingStatusService.setInquiryStarted(
                        this.proceeding,
                        false
                    );
                    this.proceeding.originator = this.authService.getProfile().email;

                    this.ProceedingService.create(this.proceeding).then(pid => {
                        this.pid = pid;

                        this.NotificationService.send(
                            "success",
                            "The conference was saved successfully."
                        );
                        this.goToNextState();
                    });
                }
            }

            proceedingChanged(proceeding, isValid) {
                this.proceeding = proceeding;
                this.isProceedingValid = isValid;
            }

            isStateValid() {
                return this.isProceedingValid === true;
            }

            isSponsoringAgencyRequired() {
                return !this.proceeding.isSponsored;
            }

            goToNextState() {
                if (this.proceeding.hasSeparatePublicationsInXplore) {
                    this.$state.go("cif.workshops", { pid: this.pid });
                } else {
                    this.$state.go("cif.contacts", { pid: this.pid });
                }
            }
        }
    );
})();
