(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceSponsorPanel", {
        bindings: {
            sponsor: "<",
            isErrorsVisible: "<",
            isLastSponsor: "<",
            isDisabled: "<",
            onRemove: "&",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/sponsors-panel/components/sponsor-panel/sponsor-panel.component.html",
        controller: class {
            constructor(InternationalDataService) {
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.countryOptions = this.InternationalDataService.getCountries();
                this.provinceOptions = this.InternationalDataService.getProvinces();
            }

            $onChanges(changes) {
                if (changes.sponsor && changes.sponsor.currentValue) {
                    this.modelChanged();
                }
            }

            modelChanged() {
                this.onUpdate({ sponsor: this.sponsor });
            }

            updateProvince(province) {
                this.sponsor.address.province = province;
                this.modelChanged();
            }

            removeButtonClicked() {
                this.onRemove();
            }
        }
    });
})();
