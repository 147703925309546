(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceMemberPanel", {
        bindings: {
            member: "<",
            isDisabled: "<",
            isErrorsVisible: "<",
            isLastMember: "<",
            onUpdate: "&",
            onRemove: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/members-panel/components/member-panel/member-panel.component.html",
        controller: class {
            modelChanged() {
                this.onUpdate({ member: this.member });
            }

            removeButtonClicked() {
                this.onRemove();
            }
        }
    });
})();
