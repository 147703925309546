(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceForm", {
        bindings: {
            pid: "<",
            compliance: "<",
            isDisabled: "<",
            isErrorsVisible: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/compliance-form.component.html",
        controller: class {
            constructor($stateParams, DateFixerService) {
                this.$stateParams = $stateParams;
                this.DateFixerService = DateFixerService;
            }

            $onInit() {
                this.compliance = this.fixComplianceDataTypes(this.compliance);
                this.complianceForm = {};
                this.pid = this.$stateParams.pid;

                this.keywordsString = (this.compliance.keywords || [])
                    .map(k => k.text)
                    .join(", ");

                this.agreementOptions = [
                    { name: "I agree", value: true },
                    { name: "I do not agree", value: false }
                ];

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];

                this.conferenceFormat = [
                    { name: "Hybrid", value: "hybrid" },
                    { name: "In-Person", value: "in-person" },
                    { name: "Virtual", value: "virtual" }
                ];

                this.reviewedMaterialsOptions = [
                    { name: "Abstracts", value: "abstracts" },
                    {
                        name: "Supporting materials",
                        value: "supporting-materials"
                    },
                    { name: "Full Papers", value: "full-papers" },
                    {
                        name: "Abbreviated Manuscripts",
                        value: "abbreviated-manuscripts"
                    },
                    {
                        name: "A combination of these Materials",
                        value: "combination"
                    },
                    { name: "Other", value: "other" }
                ];

                this.reviewOptions = [
                    { name: "Blind", value: "blind" },
                    { name: "Double Blind", value: "double-blind" },
                    { name: "Other", value: "other" }
                ];
                this.DateFixerService.fixDates(this.compliance);
            }

            $onChanges(changes) {
                this.modelChanged();
                if (changes.compliance) {
                    this.DateFixerService.fixDates(this.compliance);
                }
            }

            updateMembers(members) {
                this.compliance.members = members;
                this.modelChanged();
            }

            updateContacts(contacts, chairValidation) {
                if (this.compliance) {
                    this.compliance.contacts = contacts;
                    this.chairValidation = chairValidation;
                    this.modelChanged();
                }
            }

            updateSponsors(sponsors) {
                this.compliance.sponsors = sponsors;
                this.modelChanged();
            }

            updateVenue(venue) {
                if (this.compliance) {
                    this.compliance.venue = venue;
                    this.modelChanged();
                }
            }

            isPrimaryInContacts() {
                if (!this.compliance || !this.compliance.contacts) {
                    return false;
                }
                let primaryContact = this.compliance.contacts.find(
                    c => c.isPrimary
                );

                return !!primaryContact;
            }

            isComplianceValid() {
                if (!this.complianceForm || !this.chairValidation) return false;
                return (
                    this.complianceForm.$valid &&
                    this.isPrimaryInContacts() &&
                    !this.chairValidation.missingGeneralChair &&
                    !this.chairValidation.missingFinanceChair &&
                    !this.chairValidation.chairsMatch &&
                    this.compliance.files &&
                    this.compliance.files.length > 0
                );
            }

            complianceFilesUpdated(files) {
                // new files will have type instead of mimeType set
                this.complianceFilesToUpload = files.filter(f => f.type);
                this.compliance.files = files;
                console.log("compliance files: ", this.compliance.files);
                console.log("files to upload: ", this.complianceFilesToUpload);
                this.modelChanged();
            }

            modelChanged() {
                this.onUpdate({
                    compliance: this.compliance,
                    isValid: this.isComplianceValid(),
                    complianceFilesToUpload: this.complianceFilesToUpload
                });
            }

            fixComplianceDataTypes(complianceSubmission) {
                if (!complianceSubmission) {
                    return null;
                }
                complianceSubmission.estimatedAttendance = !_.isNil(
                    complianceSubmission.estimatedAttendance
                )
                    ? Number(complianceSubmission.estimatedAttendance)
                    : null;
                complianceSubmission.estSubmittedPapers = !_.isNil(
                    complianceSubmission.estSubmittedPapers
                )
                    ? Number(complianceSubmission.estSubmittedPapers)
                    : null;
                complianceSubmission.estAcceptedPapers = !_.isNil(
                    complianceSubmission.estAcceptedPapers
                )
                    ? Number(complianceSubmission.estAcceptedPapers)
                    : null;
                complianceSubmission.numReviewers = !_.isNil(
                    complianceSubmission.numReviewers
                )
                    ? Number(complianceSubmission.numReviewers)
                    : null;
                complianceSubmission.minNumReviewsEachPaper = !_.isNil(
                    complianceSubmission.minNumReviewsEachPaper
                )
                    ? Number(complianceSubmission.minNumReviewsEachPaper)
                    : null;
                complianceSubmission.maxNumReviewsEachReviewer = !_.isNil(
                    complianceSubmission.maxNumReviewsEachReviewer
                )
                    ? Number(complianceSubmission.maxNumReviewsEachReviewer)
                    : null;
                return complianceSubmission;
            }
        }
    });
})();
