(function () {
    "use strict";

    angular.module("cpir").component("cpirCsdlExportModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/csdl-export-modal/csdl-export-modal.component.html",
        controller: class {
            constructor(FileService, CsdlService, ProceedingService,
                        $uibModal, NotificationService, WebSocketService, InternationalDataService, ProceedingXploreStatusService, ProceedingStatusService) {
                this.FileService = FileService;
                this.CsdlService = CsdlService;
                this.ProceedingService = ProceedingService;
                this.$uibModal = $uibModal;
                this.NotificationService = NotificationService;
                this.WebSocketService = WebSocketService;
                this.InternationalDataService = InternationalDataService;
                this.ProceedingXploreStatusService = ProceedingXploreStatusService;
                this.ProceedingStatusService = ProceedingStatusService;
            }

            $onInit() {
                this.safeApply = this.WebSocketService.safeApply;
                this.entries = this.resolve.entries;
                this.includeExtras = this.resolve.includeExtras;
                this.pid = this.resolve.pid;
                this.proceeding = this.resolve.proceeding;
                this.missingSubInfo = "";
                this.noShowEntries = this.entries.filter(
                    entry => entry.noShow === true
                );
                this.entriesMissingSubmitterInfo = new Set();
                this.checkingIcx = true;
                this.applyIcx = this.proceeding.applyIcx;
                this.popOverMessage = {};
                this.countryOptions = this.InternationalDataService.getCountries();
                this.proceedingXploreStatus = this.ProceedingXploreStatusService.getXploreStatus(this.proceeding);
                this.syndicationStartMessage = this.getSyndicationStart(this.proceeding);
                this.syndicationStatusMessage = this.getSyndicationStatus(this.proceeding);
                this.checkConferenceDetailsInICX();
                this.checkMissingSubmitterInfo();
                this.checkMissingStamps();
                this.editorCheckList = "<ul><li>Before you syndicate content to IEEE Xplore\n \t. Please confirm the following have been completed:\n<ul>" +
                    "" +
                    "<li> All PDF files have been validated for Xplore compliance.\n</li>" +
                    "<li> All no-show papers have been identified in CPIR.\n</li>" +
                    "<li> Any Open Access copyrights have been processed.\n</li>" +
                    "<li><b> If not sponsored by IEEE, payment from the conference has been received</b></li></ul></li>" ;
            }

            showAuthorKitOpen() {
                if (this.proceeding) {
                    return ((this.proceeding.configuration.settings.steps.status !== "production") && this.ProceedingStatusService.isAtLeastAkActive(
                        this.proceeding
                    ) && !(this.proceeding.toc.isContinuousPub &&
                        this.proceeding.toc.continuousPubStatus === "preprint"));
                } else return false;
            }

            isAuthorKitDisabled() {
                if (!this.proceeding) return false;
                return this.proceeding.configuration.settings.ak.isDisabled;
            }

            closeAuthorKit() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal"
                    })
                    .result.then(() => {
                        this.proceeding.configuration.settings.steps.status = "production";
                        this.ProceedingStatusService.setProduction(
                            this.proceeding,
                            true
                        );
                    })
                    .then(() => {
                        this.showAuthorKitOpen();
                        this.NotificationService.send(
                            "success",
                            "The Author Kit was successfully closed."
                        );
                    })
                    .catch(e => console.log(e));
            }

            getSyndicationStart(proceeding) {
                if(proceeding.exportDate)
                    return "Syndication Start: " + proceeding.exportDate.substring(0, proceeding.exportDate.indexOf('T')) ;
                else
                    return "";
            }

            isSyndicationError() {
                if(this.proceedingXploreStatus && this.proceedingXploreStatus.syndicationError)
                    return true;
            }

            isSyndicating() {
                if(this.proceedingXploreStatus && this.proceedingXploreStatus.syndicating)
                    return true;
            }

            isSyndicated() {
                if(this.proceedingXploreStatus && this.proceedingXploreStatus.syndicated)
                    return true;
            }

            getSyndicationStatus(proceeding) {
                if(proceeding.exportDate && !this.isSyndicationError() && this.isSyndicated() && !this.isSyndicating())
                    return "Syndication Status: Syndicated";
                else if(proceeding.exportDate && !this.isSyndicationError() && this.isSyndicating())
                    return "Syndication Status: Syndicating";
                else if(proceeding.exportDate && this.isSyndicationError())
                    return "Syndication Error: Verify notification email or contact IT";
                else
                    return "";
            }




            /**
             * Collect icx conference details and compare data with cpir conference info
             * @returns {*}
             */
            checkConferenceDetailsInICX() {
                this.checkingIcx = true;
                const conferenceLocation = this.prepareCpirConferenceLocation();
                this.originalConferenceDetails = [];
                const proceedingStartDate = this.proceeding.dates.start.substring(0, this.proceeding.dates.start.indexOf('T'));
                const proceedingEndDate = this.proceeding.dates.end.substring(0, this.proceeding.dates.end.indexOf('T'));
                this.originalConferenceDetails = this.makeInitialCPIRDataArray(conferenceLocation, proceedingStartDate, proceedingEndDate);

                this.checkedCpirFieldArray = _.values(this.originalConferenceDetails).filter(function (detail) {
                    if (detail.key === "issn")
                        return detail;
                    else if (detail.value.content !== undefined && detail.value.content !== null && detail.value.content !== "")
                        return detail;
                });


                if (this.validateConferenceData(this.checkedCpirFieldArray))
                    this.popOverMessage.conferenceInfoError = "There is missing data in CPIR. Please enter details before exporting or use ICX data.";

                /**
                 * Check icx endpoint in core api to find the icxId record
                 */
                return this.CsdlService.checkConferenceDetailsInICX(
                    this.proceeding.icxId,
                    this.proceeding.acronym,
                    this.proceeding.year
                ).then((result) => {
                    this.checkingIcx = false;

                    if (result !== false) {
                        let conferenceLocationFromIcx = this.prepareIcxConferenceLocation(result.data[0]);
                        this.icxConferenceDetails = [];
                        this.comparisonDetails = [];
                        this.icxCorrections = this.proceeding.icxData ? this.proceeding.icxData : {};
                        const endDateFromICX = result.data[0].endDate? result.data[0].endDate.substring(0, result.data[0].endDate.indexOf('T')):"";
                        const startDateFromICX = result.data[0].startDate? result.data[0].startDate.substring(0, result.data[0].startDate.indexOf('T')):"";

                        /**
                         * Compare the following fields in cpir and icx and make 2 separate lists-
                         * conference Start Date
                         * conference End Date
                         * conference Location
                         * conference Normalized title
                         * conference title
                         * conference isbn and issn
                         */
                        this.processStartDate(startDateFromICX, proceedingStartDate, result.data[0].startDate);
                        this.processEndDate(endDateFromICX, proceedingEndDate, result.data[0].endDate);
                        this.processLocation(conferenceLocation, conferenceLocationFromIcx.replaceAll("||", "|"));
                        this.processNormTitle(result.data[0].normalizedConferenceName);
                        this.processTitle(result.data[0].title);
                        this.processIsbn13(result.data[0].isbn);
                        this.processIssn(result.data[0].issn);

                        /**
                         * Check icx array for fields with valid content                         *
                         */
                        this.checkedIcxFieldArray = _.values(this.icxConferenceDetails).filter(function (detail) {
                            if (detail.key === "issn")
                                return detail;
                            else if (detail.value.content !== undefined && detail.value.content !== null && detail.value.content !== "")
                                return detail;
                        });

                        if (this.applyIcx && this.validateConferenceData(this.checkedIcxFieldArray))
                            this.popOverMessage.conferenceInfoError = "There is missing data in ICX. Please contact IT about missing ICX data.";

                        this.ProceedingService.update(
                            this.proceeding.pid,
                            {icxData: this.icxCorrections}
                        );
                    } else {
                        this.makeIcxDataArray();
                        this.checkedIcxFieldArray = _.values(this.icxConferenceDetails).filter(function (detail) {
                            if (detail.value.content !== "")
                                return detail;
                        });
                        if (this.applyIcx && this.validateConferenceData(this.checkedIcxFieldArray))
                            this.popOverMessage.conferenceInfoError = "There is missing data in ICX. Please contact IT about missing ICX data.";
                    }
                })
            }

            /**
             * Build conference location from cpir data fields
             * @returns {string}
             */
            prepareCpirConferenceLocation() {
                let conferenceCity = "";
                let conferenceProvince = "";
                let conferenceCountry = "";

                if (this.proceeding.location) {
                    conferenceCity = (this.proceeding.location.city) ? this.proceeding.location.city.trim() + "|" : "";
                    conferenceProvince = (this.proceeding.location.province) ? this.proceeding.location.province.trim() + "|" : "";
                    conferenceCountry = (this.proceeding.location.country) ? this.proceeding.location.country.trim() : "";
                    console.log(conferenceCountry);
                    if(conferenceCountry && conferenceCountry !== "")
                        conferenceCountry = this.countryOptions.find(element => element.value === conferenceCountry).name;
                }
                return conferenceCity + conferenceProvince + conferenceCountry;
            }

            /**
             * Build conference location from icx data fields
             * @returns {string}
             */
            prepareIcxConferenceLocation(icxData) {
                const locationRegion = icxData.locationState ? icxData.locationState : "";
                const locationCity = icxData.locationCity ? icxData.locationCity : "";
                const locationCountry = icxData.locationCountry ? icxData.locationCountry : "";

                let conferenceLocationFromIcx = (locationRegion.indexOf(locationCity) < 0) ? locationCity.trim() + "|" : "";
                conferenceLocationFromIcx = conferenceLocationFromIcx.concat(locationRegion.trim() + "|");
                conferenceLocationFromIcx = conferenceLocationFromIcx.concat(locationCountry.trim());
                conferenceLocationFromIcx = conferenceLocationFromIcx.replaceAll(",", "|").trim();

                if (conferenceLocationFromIcx.charAt(0) === "|")
                    conferenceLocationFromIcx = conferenceLocationFromIcx.replace("|", "");
                return conferenceLocationFromIcx;
            }

            /**
             * Takes a date and returns a formatted date used for date comparison and ui display
             * @param date
             * @returns {string}
             */
            getConferenceFormattedDate(date) {
                if(date !== "") {
                    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];
                    let [dateYear, dateMonth, dateDay] = date.split("-");
                    if (dateMonth) {
                        dateMonth = monthNames[((dateMonth.charAt(0) === "0") ? dateMonth.charAt(1) : dateMonth) - 1];
                    }
                    return dateDay + " " + dateMonth + " " + dateYear;
                } else
                    return "";
            }

            /**
             * Check missing submitter info for article entries having supplement material(extras)
             */
            checkMissingSubmitterInfo() {
                let missingInfo = null;
                this.extrasAsync = this.FileService.getFilesMetadataForConference(
                    this.pid,
                    "extra",
                    true
                ).then(extras => {
                    this.extrasByEntry = _.groupBy(extras, "eid");
                    this.extraEntryEids = _.keys(this.extrasByEntry);

                    this.entriesMissingSubmitterInfo = this.entries.filter(entry => {
                        if(this.extraEntryEids.indexOf(entry.eid) !== -1) {
                            if (!entry.submitterName || !entry.submitterEmail) {
                                return true;
                            }
                        } else
                            return false;
                    });
                    /*if (this.entriesMissingSubmitterInfo.length > 0) {
                        let ctr = 1;
                        missingInfo = [];
                        this.entriesMissingSubmitterInfo.forEach(entry => {
                            missingInfo.push(entry.title);
                        });
                    }*/

                    missingInfo = this.entriesMissingSubmitterInfo;

                    if (missingInfo && missingInfo.length > 0) {
                        this.missingSubmitterInfo =
                            missingInfo;
                        this.missingSubInfo = true;
                    } else {
                        this.missingSubInfo = false;
                    }
                    this.checkMissingInfoFlags();
                });
            }


            /**
             * Export to csdl button is clicked
             */
            exportButtonClicked() {
                let confirmButton = false;
                let alertText = "";


                if (this.applyIcx !== true) {
                    if (this.validateConferenceData(this.checkedCpirFieldArray)) {
                        alertText = "There is missing CPIR data. Please enter details before exporting or use ICX data.";
                    } else {
                        alertText = this.editorCheckList +
                            "<li>Warnings:<ul><li>You have NOT chosen the ICX conference details, <br>" +
                                "The ICX data will not be applied to CSDL export if you continue." +
                                "Are you sure you wish to continue?</li>" ;
                        if(!this.checkMissingStamps) {
                            alertText = alertText.concat("<li>There are unstamped entries in the table of contents. Are you sure you wish to continue?</li>");
                        }
                        if(this.proceeding.configuration.settings.steps.status !== "production") {
                            alertText = alertText.concat("<li>The Author Kit is Open. Are you sure you wish to continue?</li>");
                        }
                        if(this.proceeding.exportDate) {
                            alertText = alertText.concat("<li>The conference is already exported. Do you want to re-export?</li>");
                        } else
                            alertText = alertText.concat("</ul></li></ul>");


                    }

                } else {
                    if (this.validateConferenceData(this.checkedIcxFieldArray)) {
                        alertText = "There is missing data in ICX. Please contact IT about missing ICX data.";
                    }

                    alertText = this.editorCheckList +
                        "<li>Warnings:<ul><li>You have chosen the ICX conference details, <br>" +
                            "The ICX data will be applied to CSDL export if you continue." +
                            "Are you sure you wish to continue?</li>";
                    if(!this.checkMissingStamps) {
                        alertText = alertText.concat("<li>There are unstamped entries in the table of contents. Are you sure you wish to continue?</li>");
                    }
                    if(this.proceeding.configuration.settings.steps.status !== "production") {
                        alertText = alertText.concat("<li>The Author Kit is Open. Are you sure you wish to continue?</li>");
                    }
                    if(this.proceeding.exportDate) {
                        alertText = alertText.concat("<li>The conference is already exported. Do you want to re-export?</li>");
                        }  else {
                        alertText = alertText.concat("</ul></li></ul>");
                    }
                }


                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () => alertText,
                            confirmButtonDisabled: () => confirmButton
                        }
                    })
                    .result.then((r) => {
                    this.ProceedingService.update(
                        this.proceeding.pid, {
                            "applyIcx": this.applyIcx
                        }
                    );
                    if (this.includeExtras) {
                        this.close({
                            $value: {includeExtras: this.includeExtras}
                        });
                    } else
                        this.close({
                            $value: {includeExtras: this.includeExtras}
                        });
                });

            }


            cancelButtonClicked() {
                this.dismiss();
            }


            /**
             * Check validation flags for missing submitter info and update validation error message
             */
            checkMissingInfoFlags() {
                if (this.missingSubInfo && this.includeExtras) {
                    this.popOverMessage.submitterInfoError = "There are missing submitter info. Please correct.";
                } else {
                    delete this.popOverMessage.submitterInfoError;
                }
            }

            /**
             * Check radio button toggle choice selected
             * Validate data
             * Update validation error message
             * @param applyIcx
             */
            conferenceDetailSourceChanged(applyIcx) {
                this.applyIcx = applyIcx;
                if (!this.applyIcx) {
                    if (this.checkedCpirFieldArray && this.validateConferenceData(this.checkedCpirFieldArray)) {
                        this.popOverMessage.conferenceInfoError = "There is missing data in CPIR. Please enter details before exporting or use ICX data.";
                    } else
                        delete this.popOverMessage.conferenceInfoError;
                }
                if (this.applyIcx) {
                    if (this.checkedIcxFieldArray && this.validateConferenceData(this.checkedIcxFieldArray))
                        this.popOverMessage.conferenceInfoError = "There is missing data in ICX. Please contact IT about missing ICX data.";
                    else
                        delete this.popOverMessage.conferenceInfoError;
                }
            }

            /**
             * Check flags to show export button
             * @returns {boolean}
             */
            showExportButton() {
                return (!this.includeExtras || !this.missingSubInfo)
                    && !(!this.applyIcx && this.checkedCpirFieldArray && this.validateConferenceData(this.checkedCpirFieldArray))
                    && !(this.applyIcx && this.checkedIcxFieldArray && this.validateConferenceData(this.checkedIcxFieldArray))
            }

            /**
             * Check flags to show export error button
             * @returns {boolean}
             */
            showExportValidationErrorButton() {
                return (this.missingSubInfo && this.includeExtras)
                    || (this.applyIcx && this.checkedIcxFieldArray && this.validateConferenceData(this.checkedIcxFieldArray)) ||
                    (!this.applyIcx && this.checkedCpirFieldArray && this.validateConferenceData(this.checkedCpirFieldArray));
            }

            /**
             * Check flags to pass result to child component to highlight icx tab on errors
             * @returns {*|applyIcx|T[]|boolean|(boolean|T[])}
             */
            highlightIcxTab() {
                return (this.applyIcx && this.checkedIcxFieldArray && this.validateConferenceData(this.checkedIcxFieldArray)) ||
                    (!this.applyIcx && this.checkedCpirFieldArray && this.validateConferenceData(this.checkedCpirFieldArray));
            }


            /**
             * Check input validation array length with the exception of issn field
             * @param inputArray
             * @returns {boolean}
             */
            validateConferenceData(inputArray) {
                if (inputArray.length < 7) {
                    const issnId = inputArray.filter(function (detail) {
                        if (detail.value.key === "issn" && !detail.value.isMatching)
                            return detail;
                    });
                    if (inputArray.length === 7 && issnId)
                        return false;
                    else
                        return true;
                } else
                    return false;
            }

            /**
             * Compare cpir and icx start dates and update flags in the respective data arrays
             * @param startDateFromICX
             * @param proceedingStartDate
             */
            processStartDate(startDateFromICX, proceedingStartDate, rawStartDateFromICX) {
                let isMatching = (startDateFromICX !== proceedingStartDate) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "startDate", "value":
                        {
                            "label": "ICX Start Date:  ",
                            "content": this.getConferenceFormattedDate(startDateFromICX),
                            "isMatching": isMatching
                        }
                });

                let objIndex = this.originalConferenceDetails.findIndex((obj => obj.key == "startDate"));
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;

                if (!this.icxCorrections.dates)
                    this.icxCorrections.dates = this.proceeding.dates ? this.proceeding.dates : {};
                this.icxCorrections.dates.start = rawStartDateFromICX;
            }

            /**
             * Compare cpir and icx end dates and update flags in the respective data arrays
             * @param endDateFromICX
             * @param proceedingEndDate
             */
            processEndDate(endDateFromICX, proceedingEndDate, rawEndDateFromICX) {
                let isMatching = (endDateFromICX !== proceedingEndDate) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "endDate", "value":
                        {
                            "label": "ICX End Date:  ",
                            "content": this.getConferenceFormattedDate(endDateFromICX),
                            "isMatching": isMatching
                        }
                });

                let objIndex = this.originalConferenceDetails.findIndex((obj => obj.key == "endDate"));
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;
                if (!this.icxCorrections.dates)
                    this.icxCorrections.dates = this.proceeding.dates;
                this.icxCorrections.dates.end = rawEndDateFromICX;
            }

            /**
             * Compare cpir and icx conference location and update flags in the respective data arrays
             * @param conferenceLocation
             * @param conferenceLocationFromIcx
             */
            processLocation(conferenceLocation, conferenceLocationFromIcx) {
                let isMatching = (conferenceLocation !== conferenceLocationFromIcx) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "location", "value":
                        {
                            "label": "ICX Location:  ", "content": conferenceLocationFromIcx.replaceAll("||","|").replaceAll("|", ", "),
                            "isMatching": isMatching
                        }
                });

                let objIndex = this.originalConferenceDetails.findIndex((obj => obj.key === "location"));
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;

                this.icxCorrections.location = {};
                this.icxCorrections.location.city = conferenceLocationFromIcx.split("|")[0];
                this.icxCorrections.location.province = conferenceLocationFromIcx.split("|")[1];
                this.icxCorrections.location.country = conferenceLocationFromIcx.split("|")[2];
            }

            /**
             * Compare cpir and icx conference normalized title and update flags in the respective data arrays
             * @param normTitle
             */
            processNormTitle(normTitle) {
                normTitle = normTitle ? normTitle : "";
                this.icxCorrections.normTitle = normTitle;
                const tocNormTitle = this.proceeding.toc.normalizedConferenceName?this.proceeding.toc.normalizedConferenceName:"";

                let isMatching = (tocNormTitle !== normTitle) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "normTitle", "value":
                        {
                            "label": "ICX Norm Title:  ", "content": normTitle,
                            "isMatching": isMatching
                        }
                });

                let objIndex = this.originalConferenceDetails.findIndex((obj => obj.key === "normTitle"));
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;
            }

            /**
             * Compare cpir and icx conference titles and update flags in the respective data arrays
             * @param title
             */
            processTitle(title) {
                title = title?title:"";
                const tocTitle = this.proceeding.title?this.proceeding.title:"";
                let isMatching = (title !== tocTitle) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "title",
                    "value": {"label": "ICX Title:  ", "content": title, "isMatching": isMatching}
                });

                this.icxCorrections.title = title;

                let objIndex = this.originalConferenceDetails.findIndex(obj => obj.key == "title");
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;
            }

            /**
             * Compare cpir and icx isbn and update flags in the respective data arrays
             * @param isbn13
             */
            processIsbn13(isbn13) {
                isbn13 = isbn13?isbn13:"";
                const tocIsbn = this.proceeding.toc.isbn13?this.proceeding.toc.isbn13:"";

                let isMatching = (isbn13 !== tocIsbn) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "isbn13",
                    "value": {"label": "ICX ISBN-13:  ", "content": isbn13, "isMatching": isMatching}
                });

                this.icxCorrections.isbn13 = isbn13;
                let objIndex = this.originalConferenceDetails.findIndex(obj => obj.key == "isbn13");
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;
            }

            /**
             * Compare cpir and icx issn and update flags in the respective data arrays
             * @param issn
             */
            processIssn(issn) {
                issn = issn?issn:"";
                const tocIssn = this.proceeding.toc.issn?this.proceeding.toc.issn:"";
                let isMatching = (issn !== tocIssn) ? false : true;
                this.icxConferenceDetails.push({
                    "key": "issn",
                    "value": {"label": "ICX ISSN:  ", "content": issn, "isMatching": isMatching}
                });

                this.icxCorrections.issn = issn;
                let objIndex = this.originalConferenceDetails.findIndex(obj => obj.key == "issn");
                this.originalConferenceDetails[objIndex].value.isMatching = isMatching;
            }

            /**
             * Make initial data array with cpir conference data
             * @param conferenceLocation
             * @param proceedingStartDate
             * @param proceedingEndDate
             * @returns {[]}
             */
            makeInitialCPIRDataArray(conferenceLocation, proceedingStartDate, proceedingEndDate) {
                let originalConferenceDetails = [];
                originalConferenceDetails.push({
                    "key": "startDate", "value":
                        {
                            "label": "CPIR Start Date:  ",
                            "content": this.getConferenceFormattedDate(proceedingStartDate),
                            "isMatching": false
                        }
                });
                originalConferenceDetails.push({
                    "key": "endDate", "value":
                        {
                            "label": "CPIR End Date:  ",
                            "content": this.getConferenceFormattedDate(proceedingEndDate),
                            "isMatching": false
                        }
                });

                originalConferenceDetails.push({
                    "key": "location",
                    "value": {
                        "label": "CPIR Location:  ",
                        "content": conferenceLocation.replaceAll("|", ", "),
                        "isMatching": false
                    }
                });
                originalConferenceDetails.push({
                    "key": "normTitle",
                    "value": {
                        "label": "CPIR Norm Title:  ",
                        "content":
                            this.proceeding.toc.normalizedConferenceName ? this.proceeding.toc.normalizedConferenceName : "",
                        "isMatching": false
                    }
                });
                originalConferenceDetails.push({
                    "key": "title",
                    "value": {
                        "label": "CPIR Title:  ",
                        "content": this.proceeding.title ? this.proceeding.title : "",
                        "isMatching": false
                    }
                });

                originalConferenceDetails.push({
                    "key": "isbn13",
                    "value": {
                        "label": "CPIR ISBN-13:  ",
                        "content": this.proceeding.toc.isbn13 ? this.proceeding.toc.isbn13 : "",
                        "isMatching": false
                    }
                });
                originalConferenceDetails.push({
                    "key": "issn",
                    "value": {
                        "label": "CPIR ISSN:  ",
                        "content": this.proceeding.toc.issn ? this.proceeding.toc.issn : "",
                        "isMatching": false
                    }
                });

                return originalConferenceDetails;
            }

            /**
             * Make icx data array when there are no results from icx api
             */
            makeIcxDataArray() {
                this.icxConferenceDetails = [];
                this.icxConferenceDetails.push({
                    "key": "startDate", "value":
                        {"label": "ICX Start Date:  ", "content": "", "isMatching": false}
                });
                this.icxConferenceDetails.push({
                    "key": "endDate", "value":
                        {"label": "ICX End Date:  ", "content": "", "isMatching": false}
                });
                this.icxConferenceDetails.push({
                    "key": "location",
                    "value": {"label": "ICX Location:  ", "content": "", "isMatching": false}
                });
                this.icxConferenceDetails.push({
                    "key": "normTitle",
                    "value": {
                        "label": "ICX Norm Title:  ", "content":
                            "", "isMatching": false
                    }
                });
                this.icxConferenceDetails.push({
                    "key": "title",
                    "value": {"label": "ICX Title:  ", "content": "", "isMatching": false}
                });
                this.icxConferenceDetails.push({
                    "key": "isbn13",
                    "value": {"label": "ICX ISBN-13:  ", "content": "", "isMatching": false}
                });
                this.icxConferenceDetails.push({
                    "key": "issn",
                    "value": {"label": "ICX ISSN:  ", "content": "", "isMatching": false}
                });
            }

            checkMissingStamps() {
                this.checkMissingStamps = true;
                this.FileService.getStampFilesMetadataForConference(
                        this.proceeding.pid,
                        "stamp",
                        true
                    ).then((filesByEntry) => {
                       if(filesByEntry) {
                            this.entries.filter(entry => {
                                if(
                                entry.class !== "SD" &&
                                entry.class !== "VD" &&
                                entry.type !== "FM_TITLE_PAGE_I" &&
                                entry.type !== "FM_TITLE_PAGE_III" &&
                                entry.type !== "FM_COPYRIGHT_PAGE"
                                ) {
                                    const stampForEntry = filesByEntry.filter(f => {
                                        if (f.eid === entry.eid)
                                            return f;
                                    });
                                    if (stampForEntry.length === 0) {
                                        this.checkMissingStamps = false;
                                    }
                                }
                            });
                        }

                        return true;


                    });
                }


            }
    });
})();
