(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkApprovalAcceptanceController",
        class {
            constructor($stateParams, AKService, ProceedingService) {
                this.$stateParams = $stateParams;
                this.AKService = AKService;
                this.ProceedingService = ProceedingService;
            }

            $onInit() {
                this.proceeding = this.AKService.getProceeding();
                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                    }
                );
            }
        }
    );
})();
