(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceAddMemberButton", {
        bindings: {
            addMember: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/members-panel/components/add-member-button/add-member-button.component.html",
        controller: class {
            buttonClicked() {
                this.addMember();
            }
        }
    });
})();
