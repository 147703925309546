(function() {
    angular.module("cpir").component("cpirAkAuthorSwitcher", {
        bindings: {
            authors: "<",
            activeAuthorIndex: "<",
            isDisabled: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/authorkit/authors/components/author-switcher/author-switcher.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.buttonClicked = $index => {
                    if (!this.isDisabled) {
                        this.onSwitch({ authorIndex: $index });
                    }
                };

                this.treeOptions = {
                    dropped: (sourceNodeScope, destNodesScope, destIndex) => {
                        // If you're dragging the currently selected author, make the
                        // new placement the selected author.
                        if (
                            sourceNodeScope.source.index ===
                            this.activeAuthorIndex
                        ) {
                            this.onSwitch({
                                authorIndex: sourceNodeScope.dest.index
                            });
                        }

                        // If you're moving a non-selected author passed the currently
                        // selected author, move the drag point accordingly. (Unless the
                        // position is at the beginning.
                        if (
                            sourceNodeScope.source.index <
                                this.activeAuthorIndex &&
                            sourceNodeScope.dest.index >= this.activeAuthorIndex
                        ) {
                            if (
                                sourceNodeScope.source.index === 0 &&
                                this.activeAuthorIndex === 1
                            ) {
                                this.onSwitch({ authorIndex: 0 });
                            } else {
                                this.onSwitch({
                                    authorIndex: this.activeAuthorIndex - 1
                                });
                            }
                        }

                        // Same as above, except the other direction.
                        if (
                            sourceNodeScope.source.index >
                                this.activeAuthorIndex &&
                            sourceNodeScope.dest.index <= this.activeAuthorIndex
                        ) {
                            if (sourceNodeScope.source.index === 0) {
                                this.onSwitch({ authorIndex: 1 });
                            } else {
                                this.onSwitch({
                                    authorIndex: this.activeAuthorIndex + 1
                                });
                            }
                        }
                    }
                };
            }
        }
    });
})();
