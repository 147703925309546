(function() {
    "use strict";

    angular.module("cpir").component("cpirCifSummaryContactPanel", {
        bindings: {
            contact: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/contacts/components/contact-panel/contact-panel.component.html",
        controller: class {
            constructor(InternationalDataService) {
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.countryOptions = this.InternationalDataService.getCountries();

                this.provinceOptions = this.InternationalDataService.getProvinces();

                this.availableRoles = [
                    { name: "General Chair", value: "GC" },
                    { name: "Publication Chair", value: "BC" },
                    { name: "Finance Chair", value: "FC" },
                    { name: "Program Chair", value: "PC" },
                    { name: "Other", value: "OT" }
                ];
            }
        }
    });
})();
