(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCImportController",

        class {
            constructor(
                pid,
                NotificationService,
                UploadService,
                $scope,
                $state,
                WebSocketService,
                $window,
                TOCViewModeService,
            ) {
                this.UploadService = UploadService;
                this.NotificationService = NotificationService;
                this.progressTotal = 0;
                this.pid = pid;
                this.$scope = $scope;
                this.$state = $state;
                this.$window = $window;
                this.safeApply = WebSocketService.safeApply;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                this.$scope.$watch(
                    () => this.articleFiles,
                    () => {
                        console.log("article files changed, refreshing...");
                        this.safeApply(this.$scope);
                    },
                );
                this.$scope.$watch(
                    () => this.supplementalFiles,
                    () => {
                        console.log(
                            "supplemental files changed, refreshing...",
                        );
                        this.safeApply(this.$scope);
                    },
                );
                // Need to detect if browser is Chrome on windows due to bug in file uploader.
                this.isChromeOnWindows = (function ($ctrl) {
                    let userAgent =
                        $ctrl.$window.navigator.userAgent.toLowerCase();
                    console.log(userAgent);
                    return (
                        userAgent.includes("chrome") &&
                        userAgent.includes("windows")
                    );
                })(this);
                this.supplementalMode = "add";
            }

            doUpload() {
                this.uploadStarted = true;
                return this.UploadService.batchUpload({
                    pid: this.pid,
                    manifest: this.manifestFile,
                    articles: this.articleFiles,
                    extras: this.supplementalFiles,
                    isVendorUpload: this.isVendorUpload,
                    supplementalMode: this.supplementalMode,
                    onNewData: null,
                    progress: (progress) => {
                        this.progressTotal = progress;
                        this.safeApply(this.$scope);
                    },
                });
            }

            import() {
                if (this.uploadStarted) return;

                this.doUpload()
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "Upload Successful",
                        );
                        this.safeApply(this.$scope);
                        this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                            this.pid,
                        );
                    })
                    .catch((err) => {
                        this.NotificationService.send(
                            "danger",
                            `Upload Failed: ${err}`,
                        );
                        this.uploadStarted = false;
                        this.safeApply(this.$scope);
                    });
            }

            returnToTOC() {
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }

            selectManifestFile(file, invalidFile) {
                if (invalidFile) {
                    console.log("Invalid File: ", invalidFile);
                    this.NotificationService.send(
                        "danger",
                        "Please choose an Excel file (.xlsx) for this submission.",
                    );
                    return;
                }
                this.manifestDetails = {};
                this.manifestDetails.name = file.name;
                this.manifestDetails.size = file.size;
            }

            removeManifestFile() {
                if (this.manifestFile) this.manifestFile = null;
            }

            removeArticleFile(index) {
                this.articleFiles.splice(index, 1);
            }

            removeSupplementalFile(index) {
                this.supplementalFiles.splice(index, 1);
            }
        },
    );
})();
