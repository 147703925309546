(function() {
    "use strict";

    angular.module("cpir").provider(
        "NotificationService",
        class {
            constructor() {}
            $get() {
                let notifications = [];
                let send = (type, message, options = {}) => {
                    notifications.push({
                        type,
                        message,
                        options
                    });
                };
                let sendArray = (type, messages) => {
                    messages.forEach(
                        message => {
                            notifications.push({
                                type,
                                message
                            });
                        });
                };
                return {
                    notifications: notifications,
                    send: send,
                    sendArray: sendArray
                };
            }
        }
    );
})();
