(function() {
    "use strict";

    angular.module("cpir").component("cpirCifSummaryQuotesProductTable", {
        bindings: {
            media: "<",
            product: "<",
            onRemove: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/quotes/components/product-table/product-table.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                // Get the type of product (medium)
                this.medium = this.media.find(m => m.mid === this.product.mid);

                // Get a list of the names of the attributes
                this.attributeNames = this.product.items[0].map(p => {
                    return p.name;
                });
            }
        }
    });
})();
