(function() {
    "use strict";

    angular.module("cpir").controller(
        "TosController",

        class {
            constructor(TosService, authService, $state) {
                this.TosService = TosService;
                this.AuthService = authService;
                this.$state = $state;
            }

            $onInit() {
                this.userEmail = this.AuthService.getProfile().email;
            }

            acceptTerms() {
                this.TosService.recordAcceptance(this.userEmail)
                    .then(() => this.$state.go("dispatcher"))
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    );
})();
