(function() {
    "use strict";

    angular.module("cpir").filter("as_trusted", [
        "$sce",
        function($sce) {
            return function(text) {
                return $sce.trustAsHtml(text);
            };
        }
    ]);
})();
