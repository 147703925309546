(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfLoginPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/configuration-common/components/ak-conf-login-panel/ak-conf-login-panel.component.html",
        controller: class {
            constructor(DefaultSettingsService) {
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {}

            saveButtonClicked() {
                this.onSave({ props: { configuration: this.configuration } });
            }
        }
    });
})();
