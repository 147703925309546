(function() {
    "use strict";

    angular.module("cpir").component("cpirDashboardPricingInformation", {
        bindings: {
            content: "<",
            role: "<",
            title: "<",
            index: "<",
            onUpdate: "&",
            onChangeSelection: "&"
        },
        templateUrl:
            "application/cpir/dashboard/_shared/components/pricing-information/pricing-information.component.html",
        controller: [
            "$scope",
            "$uibModal",
            "dashPricingService",
            "authService",
            "MediaService",
            "apiQuotes",
            function($scope, $uibModal, dashPricingService, authService, MediaService, QuoteService) {
                let $ctrl = this;

                let printMIDs = dashPricingService.print.MIDs;
                let customMIDs = dashPricingService.custom.MIDs;

                $ctrl.$onInit = () => {
                    $ctrl.brand = authService.getBrand();
                    $ctrl.isEditor = $ctrl.role === "editor";
                    $ctrl.isOrganizer = $ctrl.role === "organizer";
                    $ctrl.MediaService = MediaService;
                    $ctrl.MediaService.getMedia()
                        .then(media => ($ctrl.media = media))
                        .catch(err => console.log(err));
                    $ctrl.QuoteService = QuoteService;
                };

                $ctrl.initPrices = () => {
                    if ($ctrl.isEditor && $ctrl.content.quote) {
                        // init prices
                        $ctrl.content.quote.cart.forEach(subQuote => {
                            subQuote.isSponsored = $ctrl.content.isSponsored;
                            dashPricingService.calculatePrices(subQuote);
                        });
                    }
                };

                let activated = false;
                $ctrl.$onChanges = change => {
                    // load the first quote when data is available
                    if (
                        !activated &&
                        change.content &&
                        change.content.currentValue &&
                        change.content.currentValue.quote
                    ) {
                        $ctrl.initPrices();
                        $ctrl.activateQuote(0);
                        activated = true;
                    }

                    // change to externally selected index
                    if (change && change.index && change.index.currentValue) {
                        $ctrl.activateQuote($ctrl.index);
                    }
                };

                $scope.$watch(
                    () => $ctrl.content.isSponsored,
                    $ctrl.initPrices
                );

                $ctrl.modelChanged = () => {
                    if ($ctrl.role === "editor")
                        dashPricingService.calculatePrices($ctrl.activeQuote);
                    $ctrl.onUpdate({ content: $ctrl.content });
                };

                $ctrl.removeItemFromCart = (medium, index) =>  {
                    let existingProduct = $ctrl.activeQuote.products.find(
                        p => p.mid === "CUSTOM"
                    );
                    existingProduct.items.splice(index, 1);
                    dashPricingService.calculatePrices($ctrl.activeQuote);
                }

                $ctrl.addNewCustomLine = (newItem, medium) =>  {
                    let item = [];
                    let existingProduct = $ctrl.activeQuote.products.find(
                        p => p.mid === medium.mid
                    );

                    if (existingProduct) {
                        existingProduct.modified = new Date();
                        existingProduct.items.push(newItem);

                        $ctrl.activeAdditionalLineItems = [];
                        $ctrl.activeAdditionalLineItems = $ctrl.activeAdditionalLineItems.concat(
                            existingProduct.items
                        );
                    } else {
                        $ctrl.activeQuote.products.push({
                            modified: new Date(),
                            mid: medium.mid,
                            items: []
                        });

                        existingProduct = $ctrl.activeQuote.products.find(
                            p => p.mid === medium.mid
                        );
                        existingProduct.itemsMap = [];
                        existingProduct.items.push(newItem);

                        $ctrl.activeAdditionalLineItems = [];
                        $ctrl.activeAdditionalLineItems = $ctrl.activeAdditionalLineItems.concat(
                            existingProduct.items
                        );

                    }
                        let itemMap = {};
                        itemMap.mid = existingProduct.mid;
                        itemMap.label = medium.label;
                        itemMap.fields = newItem;
                        itemMap.media = {};
                        item.forEach(property => {
                            itemMap[property.name] = property;
                            let field = medium.fields.find(
                                field => field.name === property.name
                            );
                            itemMap.media[property.name] = field
                                ? {
                                    label: field.label,
                                    options: field.options
                                }
                                : undefined;
                        });
                        itemMap.getOptionText = name => {
                            let field = medium.fields.find(
                                field => field.name === name
                            );
                            if (field.options) {
                                return field.options.find(
                                    option =>
                                        option.value === itemMap[name].value
                                ).name;
                            } else {
                                return "ERROR: no options";
                            }
                        };
                        existingProduct.itemsMap.push(itemMap);

                    $ctrl.updateQuote();
                }
                $ctrl.updateQuote = () => {
                    $ctrl.content.quote.media = $ctrl.media;
                    return "";
                }

                // sets a quote to be the current active quote
                $ctrl.activateQuote = quoteIndex => {
                    $ctrl.activeQuoteIndex = quoteIndex;
                    $ctrl.activeQuote = $ctrl.content.quote.cart[quoteIndex];
                    $ctrl.activePrintMedia = [];
                    $ctrl.activeDigitalMedia = [];
                    $ctrl.activeAdditionalLineItems = [];
                    $ctrl.activeQuote.products.forEach(product => {
                        if (printMIDs[product.mid]) {
                            $ctrl.activePrintMedia = $ctrl.activePrintMedia.concat(
                                product.itemsMap
                            );
                        } else if(customMIDs[product.mid]) {
                            $ctrl.activeAdditionalLineItems = $ctrl.activeAdditionalLineItems.concat(
                                product.items
                            );
                        } else {
                            $ctrl.activeDigitalMedia = $ctrl.activeDigitalMedia.concat(
                                product.itemsMap
                            );
                        }
                    });
                    dashPricingService.calculatePrices($ctrl.activeQuote); // set prices
                    $ctrl.onChangeSelection({
                        selectedQuote: $ctrl.activeQuote,
                        index: quoteIndex
                    });
                };

                //open the unit prices component for a quote
                $ctrl.openUnitPrices = (quote, entry, mediaType) => {
                    if ($ctrl.role === "editor") {
                        let unitPriceComponents = {
                            print: "cpirDashboardEditorPaUnitPricesPrint",
                            digital: "cpirDashboardEditorPaUnitPricesDigital",
                            other: "cpirDashboardEditorPaUnitPricesOther"
                        };
                        $uibModal.open({
                            animation: true,
                            component: unitPriceComponents[mediaType],
                            resolve: {
                                quote: quote,
                                entry: entry
                            },
                            size: "lg"
                        });
                    }
                };

                $ctrl.getDesc = (customRow) => {
                    return customRow.find(
                            obj =>
                                obj.name === "description"
                        );
                };

                $ctrl.getPrice = (customRow) => {
                    return customRow.find(
                        obj =>
                            obj.name === "price"
                    );
                };

                $ctrl.addRow = () => {
                    let customMediaObject = $ctrl.media.find(mediaType => mediaType.mid === 'CUSTOM');
                    let newRow = angular.copy(customMediaObject.fields);
                    $ctrl.addNewCustomLine(newRow, customMediaObject);

                };

                $ctrl.removeLineItem = (lineItem, index) => {
                    $ctrl.activeAdditionalLineItems.splice(index, 1);
                    $ctrl.removeItemFromCart(lineItem, index);
                };
            }
        ]
    });
})();
