(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifQuotesController",

        class {
            constructor(
                pid,
                $state,
                $scope,
                $uibModal,
                CifService,
                NotificationService,
                ProceedingStatusService,
                MediaService,
                ProceedingService,
                apiQuotes,
                $q,
                TimerService
            ) {
                this.pid = pid;
                this.$state = $state;
                this.$scope = $scope;
                this.$uibModal = $uibModal;
                this.CifService = CifService;
                this.NotificationService = NotificationService;
                this.MediaService = MediaService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.QuoteService = apiQuotes;
                this.$q = $q;
                this.TimerService = TimerService;
            }

            $onInit() {
                this.isLoading = true;
                this.loadWaitAsync = this.TimerService.getLoadingTimer();
                this.configuration = this.CifService.getConfiguration();

                this.activeQuoteIndex = 0;

                this.initialQuote = angular.copy(Quote);

                this.quote = { pid: this.pid, cart: [this.initialQuote] };

                this.activeQuote = this.quote.cart[0];

                // Call the API to receive the media, get all media except the custom mid
                const mediaAsync = this.MediaService.getMedia()
                    .then(media => {
                        this.filteredMedia = media.filter(
                            item => item.mid !== "CUSTOM"
                        );
                        return media;
                    })
                    .then(media => (this.media = media))
                    .catch(err => console.log(err));

                // Get the proceeding record
                const proceedingAsync = this.ProceedingService.get(this.pid)
                    .then(proceeding => {
                        this.proceeding = proceeding;
                        return this.proceeding;
                    })
                    .catch(err => console.log(err));

                // Get existing quote
                const quoteAsync = this.QuoteService.byPid(this.pid)
                    .then(quote => {
                        this.qid = quote.qid;
                        this.quote = quote;
                        this.activeQuote = this.quote.cart[0];
                        return this.quote;
                    })
                    .catch(err => {
                        this.hasExistingQuotes = false;
                    });

                this.$q
                    .all([mediaAsync, proceedingAsync, quoteAsync])
                    .then(() => {
                        this.loadWaitAsync.then(() => {
                            this.isLoading = false;
                            this.TimerService.applyTimer(this.$scope);
                        });
                    });
            }

            saveQuotes(goToPreviousState) {
                this.isLoading = true;
                this.TimerService.getLoadingTimer();
                let updateQuote = () => {
                    if (this.qid) {
                        this.quote.media = this.media;
                        return this.QuoteService.update(this.qid, this.quote);
                    }
                    return this.QuoteService.insert(this.quote);
                };

                updateQuote()
                    .then(() => {
                        if (goToPreviousState) {
                            this.NotificationService.send(
                                "success",
                                "The quote was saved successfully."
                            );
                            this.goToPreviousState(this.pid);
                        } else {
                            this.NotificationService.send(
                                "success",
                                "The quote was submitted successfully."
                            );
                            this.goToNextState(this.pid);
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        console.log(error);
                    });
            }

            addProduct(medium) {
                if (this.isDirectAddProduct(medium)) {
                    let item = [];
                    medium.fields.forEach(f => {
                        console.log(f);
                        item.push({
                            name: f.name,
                            value: f.default
                        });
                    });
                    this.addItemIntoCart(medium, item);
                } else {
                    this.$uibModal
                        .open({
                            component: "cifQuotesProductModal",
                            resolve: {
                                medium: () => medium
                            }
                        })
                        .result.then(
                            item => {
                                this.addItemIntoCart(medium, item);
                            },
                            reason => {}
                        );
                }
            }

            /**
             * Check if all the fields in the product are
             * readonly.
             * @param medium
             * @returns {boolean}
             */
            isDirectAddProduct(medium) {
                let numReadonlyFields = 0;
                medium.fields.forEach(f => {
                    if (f.readonly === true) {
                        numReadonlyFields++;
                    }
                });
                return medium.fields.length === numReadonlyFields;
            }

            /**
             * Adds a processed item into the current cart.
             * @param medium
             * @param item
             */
            addItemIntoCart(medium, item) {
                let existingProduct = this.activeQuote.products.find(
                    p => p.mid === medium.mid
                );

                if (existingProduct) {
                    existingProduct.modified = new Date();
                    existingProduct.items.push(item);
                } else {
                    this.activeQuote.products.push({
                        modified: new Date(),
                        mid: medium.mid,
                        items: [item]
                    });
                }
            }

            removeProduct(mid, index) {
                let existingProduct = this.activeQuote.products.find(
                    p => p.mid === mid
                );

                // If the last item of a certain product type is being
                // removed, just remove the entire product type from the
                // quote.
                if (existingProduct.items.length === 1) {
                    let index = this.activeQuote.products.findIndex(
                        p => p.mid === mid
                    );
                    this.activeQuote.products.splice(index, 1);
                } else {
                    // Since there are other items of that product type in
                    // the array, just remove that particular item.
                    existingProduct.items.splice(index, 1);
                }
            }

            /**
             * Remove the active quote and set the previous
             * quote as the active quote.
             */
            removeActiveQuote() {
                // Check if the active quote is the first quote
                let isFirstQuote = this.activeQuoteIndex === 0;

                // Remove the current quote
                this.quote.cart.splice(this.activeQuoteIndex, 1);

                // Set the previous or next quote as the active
                // quote based on earlier saved position.
                if (isFirstQuote === true) {
                    this.activateQuote(0);
                } else {
                    this.activateQuote(this.activeQuoteIndex - 1);
                }
            }

            /**
             * Activate a quote according to its place in
             * the array (quoteIndex).
             * @param quoteIndex
             */
            activateQuote(quoteIndex) {
                this.activeQuoteIndex = quoteIndex;
                this.activeQuote = this.quote.cart[quoteIndex];
            }

            /**
             * Create a new quote by adding a default
             * quote into the array.
             */
            addQuote() {
                if (this.isStateValid()) {
                    // Create a new quote
                    let newQuote = angular.copy(Quote);

                    // Push the new quote into the array
                    this.quote.cart.push(newQuote);

                    // Set the new quote as the active quote
                    this.activateQuote(this.quote.cart.length - 1);
                }
            }

            /**
             * Clone a quote by creating a new empty quote
             * and copying the values from the desired quote.
             */
            cloneActiveQuote() {
                // Save the previous position of the quote to be cloned
                let cloneThisQuoteIndex = this.activeQuoteIndex;

                // Add a new quote
                this.addQuote();

                // Copy the properties of the old quote to the new quote
                angular.copy(
                    this.quote.cart[cloneThisQuoteIndex],
                    this.quote.cart[this.activeQuoteIndex]
                );

                // Mark the cloned quote as a clone
                this.quote.cart[this.activeQuoteIndex].isClone = true;
            }

            isStateValid() {
                if (
                    !this.activeQuote.estSubmittedPapers ||
                    !this.activeQuote.estAcceptedPapers
                ) {
                    return false;
                }
                if (this.activeQuote.products.length === 0) {
                    return false;
                }
                if (this.acceptanceRate() === "n/a") {
                    return false;
                }
                return true;
            }

            goBack() {
                if (this.isStateValid()) {
                    let GO_TO_PREV_STATE_AFTER = true;
                    this.saveQuotes(GO_TO_PREV_STATE_AFTER);
                } else {
                    this.goToPreviousState();
                }
            }

            goToPreviousState() {
                this.$state.go("cif.contacts", { pid: this.pid });
            }

            goToNextState() {
                if (
                    this.ProceedingStatusService.isInquirySubmitted(
                        this.proceeding
                    )
                ) {
                    this.$state.go("dashboard-editor.home");
                } else {
                    this.$state.go("cif.submission", { pid: this.pid });
                }
            }

            acceptanceRate() {
                let badRate = "n/a";
                let estSubmittedPapers = parseInt(
                    this.activeQuote.estSubmittedPapers
                );
                let estAcceptedPapers = parseInt(
                    this.activeQuote.estAcceptedPapers
                );

                if (!estSubmittedPapers || !estAcceptedPapers) return badRate;

                if (estAcceptedPapers > estSubmittedPapers) return badRate;

                let percentage = estAcceptedPapers / estSubmittedPapers;
                let rate = percentage * 100;

                return rate ? `${Math.round(rate)}%` : badRate;
            }
        }
    );

    // Model for Quote object
    let Quote = {
        isClone: false,
        estSubmittedPapers: null,
        estAcceptedPapers: null,
        products: []
    };
})();
