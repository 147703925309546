(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorHomeController",

        class {
            constructor(
                $q,
                $uibModal,
                $http,
                $window,
                $location,
                $document,
                $state,
                $scope,
                $rootScope,
                $filter,
                $timeout,
                ProceedingStatusService,
                ProceedingRoleService,
                ProceedingAdditionalInformationService,
                DeepLinkService,
                ContactService,
                ProceedingService,
                NotificationService,
                AccessCheckService,
                pid,
                event,
                signatory_uid,
                signatory_email,
                searchParam,
                EmailService,
                FileService,
                WebSocketService,
                GlobalDefaultsService,
                CloneProceedingService,
                IThenticateService,
                ProceedingSearchService,
                Cpir2Service,
                ProceedingXploreStatusService,
                authService,
            ) {
                this.$uibModal = $uibModal;
                this.GlobalDefaultsService = GlobalDefaultsService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.ContactService = ContactService;
                this.DeepLinkService = DeepLinkService;
                this.NotificationService = NotificationService;
                this.ProceedingAdditionalInformationService =
                    ProceedingAdditionalInformationService;
                this.$http = $http;
                this.$window = $window;
                this.$location = $location;
                this.$state = $state;
                this.pid = pid;
                this.event = event;
                this.signatory_uid = signatory_uid;
                this.signatory_email = signatory_email;
                this.searchParam = searchParam;
                this.accessCheck = AccessCheckService;
                this.EmailService = EmailService;
                this.$q = $q;
                this.FileService = FileService;
                this.CloneProceedingService = CloneProceedingService;
                this.WebSocketService = WebSocketService;
                this.$scope = $scope;
                this.$rootScope = $rootScope;
                this.IThenticateService = IThenticateService;
                this.$timeout = $timeout;
                this.$filter = $filter;
                this.safeApply = this.WebSocketService.safeApply;
                this.showTop = false;
                this.scrollBusy = true;
                this.$document = $document;
                this.ProceedingSearchService = ProceedingSearchService;
                this.ProceedingXploreStatusService =
                    ProceedingXploreStatusService;
                this.authService = authService;
                this.doFilter = _.debounce(this._doFilter, 300, {
                    maxWait: 1000,
                });
                this.Cpir2Service = Cpir2Service;
                this.isHidden = true;
            }

            loadProceedings(displayLoading) {
                this.isLoading = displayLoading;

                let proceedingResolve;
                let proceedingCacheResolve;

                this.proceedingsAsync = new Promise((resolve) => {
                    proceedingResolve = resolve;
                });
                const proceedingsCacheAsync = new Promise((resolve) => {
                    proceedingCacheResolve = resolve;
                });

                this.ProceedingService.listWithCache$().subscribe({
                    next: (proceedingsList) => {
                        this.$timeout(() => {
                            if (proceedingsList.length > 0) {
                                this.searchIndex =
                                    this.ProceedingSearchService.makeIndex(
                                        proceedingsList,
                                    );

                                this.proceedingsList = this.$filter(
                                    "proceedingOrderByFilter",
                                )(proceedingsList, "created_date_desc", true);

                                this.editors =
                                    this.extractEditorsFromProceedings(
                                        this.proceedingsList,
                                    );
                            }

                            proceedingCacheResolve(this.proceedingsList);

                            if (!this.checkingDocuSign) this.isLoading = false;
                        });
                    },
                    error: (err) => console.error(err),
                    complete: () => {
                        this.timeout = this.$timeout(() => {
                            if (this.proceedingsList.length < 1) {
                                this.noProceedings = true;
                            }
                            proceedingResolve(this.proceedingsList);
                        });
                    },
                });
                return proceedingsCacheAsync;
            }

            isSyndicationError(proceeding) {
                let proceedingXploreStatus =
                    this.ProceedingXploreStatusService.getXploreStatus(
                        proceeding,
                    );
                if (
                    proceedingXploreStatus &&
                    proceedingXploreStatus.syndicationError === true
                )
                    return true;
            }

            /**
             * Handles attaching, handling, and removing scroll events for showing & hiding the scroll to top button
             */
            initScrollHandler() {
                // the handler
                const scrollHandler = function () {
                    if (!this.handlingScrollEvent) {
                        requestAnimationFrame(() => {
                            // it is recommended to rate-limit scroll animations using requestAnimationFrame

                            // determine whether to show the "jump to top" button
                            this.showTop =
                                this.$document[0].body.scrollTop > 120 ||
                                this.$document[0].documentElement.scrollTop >
                                    120;

                            // determine the sticky search bar position
                            const scrollHeight = _.max([
                                this.$document[0].body.scrollTop,
                                this.$document[0].documentElement.scrollTop,
                            ]);
                            this.searchBarTop = {
                                top: `${_.max([0, 60 - scrollHeight]) + 15}px`,
                            };

                            this.safeApply(this.$scope); // tell angular to re-run the digest cycle
                            this.handlingScrollEvent = false;
                        });
                    }
                    this.handlingScrollEvent = true;
                };

                // bind to the window scroll event
                this.$window.addEventListener(
                    "scroll",
                    scrollHandler.bind(this),
                );

                // clean up scroll handling events when we navigate off dashboard
                const stateChangeDeregister = this.$rootScope.$on(
                    "$stateChangeStart",
                    (event, toState, toParams, fromState, fromParams) => {
                        if (fromState.name === this.$state.$current.name) {
                            // check if the state names match
                            this.$window.removeEventListener(
                                "scroll",
                                scrollHandler,
                            );
                        }
                        stateChangeDeregister(); // stop this event from firing again
                    },
                );
            }

            $onInit() {
                this.Cpir2Service.forwardToCPIR2LandingPage();
                return;

                this.isEditor = this.authService.isEditor();
                this.sortOptions = [
                    { value: "acronym", name: "Acronym (Asc.)" },
                    { value: "acronym_desc", name: "Acronym (Desc.)" },
                    { value: "created_date_asc", name: "Created Date (Asc.)" },
                    {
                        value: "created_date_desc",
                        name: "Created Date (Desc.)",
                    },
                    {
                        value: "submitted_date_asc",
                        name: "Submitted Date (Asc.)",
                    },
                    {
                        value: "submitted_date_desc",
                        name: "Submitted Date (Desc.)",
                    },
                    {
                        value: "submission_date_asc",
                        name: "Papers Due Date (Asc.)",
                    },
                    {
                        value: "submission_date_desc",
                        name: "Papers Due Date (Desc.)",
                    },
                    { value: "press_date_asc", name: "Press Date (Asc.)" },
                    { value: "press_date_desc", name: "Press Date (Desc.)" },
                    { value: "best_match", name: "Best Match (Desc.)" },
                ];

                this.xploreStatus = [
                    { name: "Not Syndicated", value: "" },
                    { name: "Syndicating", value: "exportDate" },
                    { name: "Syndicated", value: "syndicationDate" },
                    { name: "Error", value: "syndicationError" },
                ];

                this.bestMatchOption = this.sortOptions.find(
                    (o) => o.value === "best_match",
                );
                this.createdDateDescOption = this.sortOptions.find(
                    (o) => o.value === "created_date_desc",
                );
                this.PROCEEDING_LOAD_SIZE = 40;

                this.initScrollHandler();

                // make a copy of the search state to reset to
                this.initSearchState = _.cloneDeep(
                    JSON.parse(
                        sessionStorage.getItem("dashboardSearchState") || "{}",
                    ),
                );

                this.resetView(false, this.initSearchState);

                this.stepOptions = this.ProceedingStatusService.getSteps();

                this.checkDocuSignComplete();

                this.startStatusSocket().then((socket) =>
                    socket.on("refresh", () => {
                        console.log("reloading updated proceedings");
                        this.loadProceedings(false).catch((err) =>
                            console.error(err),
                        );
                    }),
                );

                this.loadProceedings(false)
                    .then(() => {
                        this._initFilter();
                        return this.proceedingsAsync;
                    })
                    .then(() => {
                        const searchState = JSON.parse(
                            sessionStorage.getItem("dashboardSearchState") ||
                                "{}",
                        );
                        this._initFilter({
                            status: searchState.status,
                            editorEmail: searchState.editorEmail,
                            xploreStatus: searchState.xploreStatus,
                            orderBy: searchState.orderBy,
                            searchKeyword: searchState.searchKeyword,
                        });
                    })
                    .catch((err) => console.error(err));
            }

            _initFilter(initSearchState) {
                this.$timeout(() => {
                    if (
                        this.proceedingsList &&
                        this.proceedingsList.length > 0
                    ) {
                        // deep link keyword handling
                        if (
                            typeof this.searchParam !== "undefined" &&
                            this.searchParam !== null
                        ) {
                            // override the search state with the deep link keyword
                            this.initSearchState = {
                                searchKeyword: this.searchParam,
                            };
                        }

                        this.resetView(
                            true,
                            initSearchState || this.initSearchState,
                        );
                    }
                });
            }

            scrollToTop() {
                this.loadMore(0);
                this.$document[0].body.scrollTop = 0; // For Safari
                this.$document[0].documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }

            _filter(proceedings) {
                let filteredProceedings = proceedings || [];

                // save the current search state
                sessionStorage.setItem(
                    "dashboardSearchState",
                    JSON.stringify(this.filter),
                );

                // search filter
                // filter terms first to avoid having to re-index
                // don't activate search until there is query terms that aren't stopwords
                const query =
                    this.filter.searchKeyword &&
                    this.filter.searchKeyword.trim();
                const queryTokens =
                    (this.searchIndex &&
                        this.searchIndex.tokenizeAndStem(query)) ||
                    [];
                if (queryTokens.length > 0) {
                    if (!this.sortIsDirty) {
                        this.filter.orderBy = this.bestMatchOption.value;
                    }
                    filteredProceedings = this.$filter(
                        "proceedingSearchFilter",
                    )(
                        filteredProceedings,
                        this.searchIndex,
                        this.filter.searchKeyword,
                    );
                } else if (!this.sortIsDirty) {
                    this.filter.orderBy = this.createdDateDescOption.value;
                }

                // editor filter
                if (
                    this.filter.editorEmail !== "*" &&
                    this.filter.editorEmail !== ""
                ) {
                    filteredProceedings = this.$filter(
                        "proceedingEditorFilter",
                    )(filteredProceedings, this.filter.editorEmail, false);
                }

                // status filter
                if (this.filter.status !== "*" && this.filter.status !== "") {
                    filteredProceedings = this.$filter(
                        "proceedingStatusFilter",
                    )(filteredProceedings, this.filter.status, false);
                }

                // xploreStatus filter
                if (
                    this.filter.xploreStatus !== "*" &&
                    this.filter.xploreStatus !== ""
                ) {
                    filteredProceedings = this.$filter(
                        "proceedingXploreStatusFilter",
                    )(filteredProceedings, this.filter.xploreStatus, false);
                }

                // order based on filters
                if (this.filter.orderBy !== "*" && this.filter.orderBy !== "") {
                    filteredProceedings = this.$filter(
                        "proceedingOrderByFilter",
                    )(filteredProceedings, this.filter.orderBy, false);
                }
                return filteredProceedings;
            }

            _doFilter() {
                this.scrollBusy = true;
                this.filteredProceedings =
                    this._filter(this.proceedingsList) || [];
                this.proceedings = this.filteredProceedings.slice(
                    0,
                    this.PROCEEDING_LOAD_SIZE,
                );
                this.scrollToTop();
                this.scrollBusy = false;
                this.safeApply(this.$scope);
                return this.proceedings;
            }

            onOrderBy() {
                this.sortIsDirty = true;
                return this.doFilter(...arguments);
            }

            loadMore(startIndex) {
                if (this.scrollBusy) return;

                this.scrollBusy = true;

                // extend the proceedings list
                const currentIndex = _.isNumber(startIndex)
                    ? startIndex
                    : this.proceedings.length;
                this.proceedings = this.filteredProceedings.slice(
                    0,
                    currentIndex + this.PROCEEDING_LOAD_SIZE,
                );
                console.log(
                    `${this.proceedings.length} proceedings loaded of ${this.filteredProceedings.length} filtered proceedings`,
                );

                // check if there is any more data to load
                if (
                    this.proceedings.length === this.filteredProceedings.length
                ) {
                    console.log(`No more proceedings to load`);
                    this.scrollBusy = true;
                } else {
                    this.scrollBusy = false;
                }

                // tell Angular to re-check for changes to models
                this.safeApply(this.$scope);

                return this.proceedings;
            }

            resetView(
                filter = true,
                {
                    status,
                    editorEmail,
                    xploreStatus,
                    orderBy,
                    searchKeyword,
                } = {},
            ) {
                // clear the storage state
                sessionStorage.removeItem("dashboardSearchState");

                this.scrollBusy = true;
                this.filter = {};
                this.filter.status = status || "*";
                this.filter.editorEmail = editorEmail || "*";
                this.filter.xploreStatus = xploreStatus || "*";
                this.filter.orderBy = orderBy || "created_date_desc";
                this.filter.searchKeyword = searchKeyword || "";
                this.sortIsDirty = false;
                if (filter) this._doFilter();
            }

            extractEditorsFromProceedings(proceedings) {
                let editors = [];
                proceedings.forEach((p) => {
                    if (!p.editor) return;
                    if (!editors.find((e) => e.email === p.editor.email)) {
                        editors.push(p.editor);
                    }
                });
                return editors;
            }

            checkComplianceEmailNeeded(proceeding, contacts) {
                //check for compliance review
                if (
                    !proceeding.isSponsored &&
                    this.ProceedingRoleService.isOrganizer(proceeding)
                ) {
                    //send email for each contact separately
                    contacts.forEach((contact) => {
                        this.EmailService.sendImmediately("compliance-review", {
                            to: [contact.email],
                            data: {
                                contactName: `${contact.givenName} ${contact.surname}`,
                                proceeding: proceeding,
                                contact: contact,
                                complianceLink:
                                    this.DeepLinkService.getComplianceDeepLink(
                                        proceeding.pid,
                                    ),
                            },
                        });
                    });
                }
            }

            docuSignPostCompleteActions({
                updatedProceeding,
                contacts,
                docuSignResponse,
            }) {
                if (docuSignResponse !== "already complete") {
                    this.ProceedingAdditionalInformationService.getPaperTypeInfo(
                        updatedProceeding.pid,
                    );
                    this.checkComplianceEmailNeeded(
                        updatedProceeding,
                        contacts,
                    );
                }

                // check if compliance review needed
                if (
                    !updatedProceeding.isSponsored &&
                    updatedProceeding.docuSign.status !== "completed"
                ) {
                    //go to compliance review form
                    this.Cpir2Service.redirectToCompliance(
                        updatedProceeding.pid,
                    ).catch((err) => {
                        console.error(err);
                        throw err;
                    });
                } else {
                    //refresh without query params
                    this.$state.go("dashboard-editor.home", {
                        pid: null,
                        event: null,
                    });
                }
            }

            docuSignComplete() {
                //send notification
                this.NotificationService.send(
                    "success",
                    `Hooray, You've signed the document!`,
                );

                //get proceeding from pid
                this.ProceedingService.get(this.pid)
                    .then((proceeding) => {
                        const requestBody = { proceeding: proceeding };
                        if (this.signatory_email) {
                            requestBody.apiUser = {
                                email: this.signatory_email,
                            };
                        }
                        return this.$q.all([
                            this.ContactService.list(
                                proceeding.contacts.map(
                                    (contact) => contact.cid,
                                ),
                            ),
                            this.$http.post(
                                "/docusign/complete-signing",
                                requestBody,
                            ),
                        ]);
                    })
                    .then((resolved) => {
                        let updatedProceeding = resolved[1].data.proceeding;
                        let docuSignResponse = resolved[1].data.message;
                        let contacts = resolved[0];
                        return this.docuSignPostCompleteActions({
                            updatedProceeding,
                            contacts,
                            docuSignResponse,
                        });
                    })
                    .catch((err) => {
                        this.NotificationService.send(
                            "danger",
                            "There was a problem communicating with DocuSign.",
                        );
                        console.log(err);
                        this.$state.go("dashboard-editor.home", {
                            pid: null,
                            event: null,
                        });
                    });
            }

            fixDocuSignComplete() {
                //this status is used for fixing broken docusign integrations
                let proceeding;
                this.ProceedingService.get(this.pid)
                    .then((r) => (proceeding = r))
                    .then(() =>
                        this.$http.post("/docusign/redo-complete-signing", {
                            apiUser: { uid: this.signatory_uid },
                            proceeding: proceeding,
                        }),
                    )
                    .then(() =>
                        this.$state.go("dashboard-editor.home", {
                            pid: null,
                            event: null,
                        }),
                    )
                    .catch((err) => {
                        this.NotificationService.send(
                            "danger",
                            "There was a problem communicating with DocuSign.",
                        );
                        console.log(err);
                        this.$state.go("dashboard-editor.home", {
                            pid: null,
                            event: null,
                        });
                    });
            }

            checkDocuSignComplete() {
                this.checkingDocuSign = true;
                //check for evidence of Docusign landing
                if (this.event || this.pid) {
                    this.isLoading = true;
                    if (this.event === "signing_complete" && this.pid) {
                        this.docuSignComplete();
                    } else if (
                        this.event === "redo_internal_signatory" &&
                        this.pid
                    ) {
                        this.fixDocuSignComplete();
                    } else {
                        //query params from docusign don't indicated completed status
                        //refresh without query params
                        this.$state.go("dashboard-editor.home", {
                            pid: null,
                            event: null,
                        });
                    }
                } else {
                    this.checkingDocuSign = false;
                }
            }

            startStatusSocket() {
                let deferred = this.$q.defer();
                this.WebSocketService.openSocket((client) => {
                    // clean up websocket connection when we navigate off dashboard
                    const stateChangeDeregister = this.$rootScope.$on(
                        "$stateChangeStart",
                        (event, toState, toParams, fromState, fromParams) => {
                            if (fromState.name === this.$state.$current.name) {
                                // check if the state names match
                                client.disconnect();
                                console.log(
                                    "disconnecting from dashboard socket",
                                );
                            }
                            stateChangeDeregister(); // stop this event from firing again
                        },
                    );

                    client.on("ok", () => {
                        console.log("dashboard status socket connected");
                        deferred.resolve(client);
                    });
                    client.emit("dashboard");
                });
                return deferred.promise.catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            signPA(proceeding) {
                if (this.ProceedingStatusService.isAtLeastAkSetup(proceeding)) {
                    this.FileService.getPublicationAgreement(proceeding.pid);
                    return;
                }
                if (proceeding.docuSign && proceeding.docuSign.envelopeId) {
                    this.NotificationService.send(
                        "success",
                        "Please wait while we redirect you...",
                    );
                    this.$http
                        .post("/docusign", {
                            returnUrl: `${this.$window.location.href}?pid=${proceeding.pid}`,
                            proceeding: proceeding,
                        })
                        .then(
                            (successResult) => {
                                // console.log(successResult);
                                this.$window.location.href =
                                    successResult.data.url;
                                return successResult;
                            },
                            (errorResult) => {
                                this.NotificationService.send(
                                    "danger",
                                    `There was a problem redirecting to DocuSign: ${errorResult.data}`,
                                );
                                console.log(errorResult);
                            },
                        );
                } else {
                    this.NotificationService.send(
                        "warning",
                        "A publication agreement has not yet been created for this Inquiry.",
                    );
                }
            }

            cloneProceeding(proceeding) {
                this.CloneProceedingService.cloneProceeding(proceeding).then(
                    (pid) => {
                        if (pid) {
                            this.$state.go("cif.conference-update", {
                                pid: pid,
                            });
                            this.NotificationService.send(
                                "success",
                                "The proceeding was successfully cloned.",
                            );
                        }
                    },
                );
            }

            archiveProceeding(proceeding) {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            message: () =>
                                "Are you sure you want to archive the selected proceeding?",
                        },
                    })
                    .result.then(() => {
                        return this.ProceedingService.archive(proceeding.pid);
                    })
                    .then(() =>
                        this.loadProceedings(true).then(() => this.resetView()),
                    )
                    .catch((e) => console.error("Error: ", e));
            }

            openAuthorKit(proceeding) {
                this.Cpir2Service.getAuthorDashboardURL(proceeding.pid).then(
                    (url) => {
                        this.$window.open(url, "_blank");
                    },
                );
            }

            openConfiguration(proceeding) {
                this.Cpir2Service.redirectToProceedingConfiguration(
                    proceeding.pid,
                ).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            openComplianceReview(proceeding) {
                this.Cpir2Service.getComplianceURL(proceeding.pid).then(
                    (url) => {
                        this.$window.open(url, "_blank");
                    },
                );
            }

            goToNewCifWizard(proceeding) {
                this.Cpir2Service.redirectToCif(proceeding.pid).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            goToNewInquirySummary(proceeding) {
                this.Cpir2Service.redirectToInquirySummary(
                    proceeding.pid,
                ).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            goToNewQuote(proceeding) {
                this.Cpir2Service.redirectToQuote(proceeding.pid).catch(
                    (err) => {
                        console.error(err);
                        throw err;
                    },
                );
            }
        },
    );
})();
