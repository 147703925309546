(function() {
    "use strict";

    angular.module("cpir").filter("proceedingSubmittedDate", () => {
        return function(proceeding) {
            if (!proceeding) return null;

            let submittedDate = null;

            proceeding.configuration.settings.steps.changes.forEach(change => {
                if (change.status === "inquiry-submitted") {
                    submittedDate = change.date;
                }
            });

            return submittedDate;
        };
    });
})();
