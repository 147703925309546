(function() {
    "use strict";

    angular.module("cpir").filter("tocEntriesFailedQAFilter", () => {
        return entries => {
            if (!entries) return 0;
            return entries.filter(e => e.productionStatus === "failed_qa")
                .length;
        };
    });
})();
