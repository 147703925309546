(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfirmSaveModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/confirm-save-modal/confirm-save-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            cancelButtonClicked() {
                this.dismiss();
            }

            confirm() {
                this.close({});
            }
        }
    });
})();
