(function() {
    "use strict";

    angular.module("cpir").component("cpirCifConfSettingsWorkshopsPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/cif-conf-settings-panel/components/cif-conf-settings-workshops-panel/cif-conf-settings-workshops-panel.component.html",
        controller: class {
            constructor(DefaultSettingsService) {
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {}

            saveChanges() {
                this.onSave({
                    configuration: this.configuration
                });
            }
        }
    });
})();
