(function () {
    "use strict";

    angular.module("cpir").filter("entrySimilarityFilter", () => {
        return (entry, pendingEntryIdsSet) => {
            if (!entry) return entry;
            if (pendingEntryIdsSet.has(entry.eid)) {
                return "processing";
            }
            if (
                !entry.iThenticateId &&
                !Number.isNaN(parseInt(entry.similarity, 10))
            ) {
                return null;
            }
            return entry.similarity;
        };
    });
})();
