(function() {
    "use strict";

    angular
        .module("cpir")
        .filter(
            "proceedingOrderBySubmittedDateFilter",
            (ProceedingStatusService, moment) => {
                return function(proceedings, reverse) {
                    if (!proceedings) return null;

                    let proceedingsNotSubmitted = proceedings.filter(p => {
                        return ProceedingStatusService.isInquiryStarted(p);
                    });

                    let proceedingsSubmitted = proceedings.filter(p => {
                        return !ProceedingStatusService.isInquiryStarted(p);
                    });

                    proceedingsSubmitted.sort((a, b) => {
                        let statusA = a.configuration.settings.steps.changes.find(
                            s => s.status === "inquiry-submitted"
                        );
                        let statusB = b.configuration.settings.steps.changes.find(
                            s => s.status === "inquiry-submitted"
                        );

                        if (!statusA && !statusB) {
                            return 0;
                        } else if (!statusA) {
                            return 1;
                        } else if (!statusB) {
                            return -1;
                        }

                        let dateA = moment(statusA.date);
                        let dateB = moment(statusB.date);

                        if (dateA.isSameOrAfter(dateB)) {
                            return reverse ? -1 : 1;
                        } else {
                            return reverse ? 1 : -1;
                        }
                    });

                    return proceedingsSubmitted.concat(proceedingsNotSubmitted);
                };
            }
        );
})();
