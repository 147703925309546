(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceAddContactButton", {
        bindings: {
            addContact: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/contacts-panel/components/add-contact-button/add-contact-button.component.html",
        controller: class {
            buttonClicked() {
                this.addContact();
            }
        }
    });
})();
