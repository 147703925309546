(function() {
    "use strict";

    angular
        .module("cpir")
        .controller("AuthResetPasswordController", [
            "authService",
            AuthResetPasswordController
        ]);

    function AuthResetPasswordController(authService) {
        let $ctrl = this;

        $ctrl.resetPassword = () => {
            authService
                .resetPassword($ctrl.email)
                .then(() => {
                    $ctrl.errorHappened = false;
                    $ctrl.passwordSent = true;
                    $ctrl.email = "";
                })
                .catch(() => {
                    $ctrl.errorHappened = true;
                    $ctrl.passwordSent = false;
                });
        };

        $ctrl.listenForEnter = $event => {
            if ($event.which === 13 && $ctrl.passwordResetForm.$valid) {
                $ctrl.resetPassword();
            }
        };
    }
})();
