(function () {
    "use strict";

    angular
        .module("cpir")
        .directive("emailvalidation", function ($q, $timeout) {
            return {
                require: 'ngModel',

                link: function (scope, element, attrs, ngModel) {

                    ngModel.$parsers.unshift(function (value) {

                            const field = ngModel.$name;

                            if(value !== "") {

                                console.log(value);
                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;



                                    // Split the input string into an array of emails
                                    const emails = value.split(',').map(email => email.trim());

                                    // Check if each email is valid
                                    for (let i = 0; i < emails.length; i++) {
                                        if (!emailRegex.test(emails[i])) {
                                            console.log("not valid");
                                            ngModel.$setValidity(field, false);
                                            return value; // Return false if any email is invalid
                                        }
                                    }
                                ngModel.$setValidity(field, true);
                                    return value; // Return true if all emails are valid

                            } else {
                                    ngModel.$setValidity(field, true);
                                    return value;
                                }
                            });
                    }
            }
                });


          //  };
       // });
})();
