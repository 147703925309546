(function() {
    "use strict";

    angular.module("cpir").filter("emptyValueFilter", () => {
        return value => {
            if (value) return value;
            return "n/a";
        };
    });
})();
