(function() {
    "use strict";

    angular.module("cpir").component("cpirExportTocModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/export-toc-modal/export-toc-modal.component.html",
        controller: class {
            constructor(
                WebSocketService,
                $scope,
                $window,
                FileService,
                NotificationService
            ) {
                this.safeApply = WebSocketService.safeApply;
                this.$scope = $scope;
                this.$window = $window;
                this.FileService = FileService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.proceeding = this.resolve.proceeding;
            }

            exportStartedButtonClicked(exportType) {
                this.exportType = exportType;
                this.exportStarted = true;
                this.FileService.exportToc(
                    this.proceeding.pid,
                    exportType,
                    this.includeExtras
                ).then(socket => {
                    this.socket = socket;
                    socket.on("export-ready", key => {
                        this.exportKey = key;
                        this.safeApply(this.$scope);
                    });
                    socket.on("export-failed", err => {
                        this.NotificationService.send("danger", err);
                        this.dismiss();
                    });
                });
            }

            downloadButtonClicked() {
                    this.FileService.downloadTocExport(
                    this.exportKey,
                    this.proceeding.year,
                    this.proceeding.acronym,
                    this.exportType
                );
                this.close({ $value: this.exportKey });
            }

            cancelButtonClicked() {
                if (this.socket) this.socket.disconnect();
                this.dismiss();
                //send disconnect signal
            }
        }
    });
})();
