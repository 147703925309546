(function () {
    /* eslint-disable no-undef */
    "use strict";

    function updateFeathersToken() {
        // remove quotes from the token, feathers doesn't like them
        const key = localStorage.getItem("id_token").replaceAll('"', "");
        localStorage.setItem("feathers-jwt", key);
    }

    angular.module("cpir").provider(
        "FeathersService",
        class {
            constructor() {
                // create a promise that will be resolved when feathers is authenticated
                this.authenticated = new Promise((resolve, reject) => {
                    this.authenticatedPromiseResolve = resolve;
                    this.authenticatedPromiseReject = reject;
                });
            }

            // create a feathersjs socket if one doesn't exist
            get socket() {
                if (!this._socket) {
                    this._socket = feathersjs.io(this.socketHost, {
                        path: this.socketPath,
                    });
                }
                return this._socket;
            }

            get authenticatedClient() {
                return this.authenticated.then(() => this.client); // wait for authentication to complete before returning the client
            }

            // create a feathersjs client if one doesn't exist
            get client() {
                if (!this._client) {
                    console.log("Creating feathers client");
                    this._client = feathersjs
                        .feathers()
                        .configure(feathersjs.socketio(this.socket))
                        .configure(
                            feathersjs.auth({
                                storage: localStorage,
                                storageKey: "feathers-jwt",
                            }),
                        );
                }
                return this._client;
            }

            _reAuthenticate() {
                updateFeathersToken();
                return this.client.authentication
                    .reAuthenticate()
                    .then(() => {
                        console.log("Feathers authentication successful");
                        this.authenticatedPromiseResolve();
                    })
                    .catch((err) => {
                        console.error("Feather authentication error:", err);
                        this.authenticatedPromiseReject();
                    });
            }

            // join a channel for a given proceedings id
            joinProceedingsChannel(pid) {
                return this.authenticatedClient.then((client) => {
                    console.log("joining proceedings channel");
                    return client
                        .service("proceedings-channel")
                        .create({ pid });
                });
            }

            leaveProceedingsChannel(pid) {
                return this.authenticatedClient.then((client) => {
                    console.log("leaving proceedings channel");
                    return client.service("proceedings-channel").remove(pid);
                });
            }

            // TODO: temporary solution for legacy cpir. Normally this would be part of it's own service.
            // TODO: move to own service if this becomes polluted
            /**
             * Get the active author kit connections for a given proceedings id
             * @param {string} pid
             * @returns Promise<{pid: string, eid: string}[]>
             */
            getActiveAuthorKitConnections(pid) {
                return this.authenticatedClient.then((client) =>
                    client
                        .service("author-kit-connection")
                        .find({ query: { pid } }),
                );
            }

            $get(EnvironmentService) {
                this.socketHost = EnvironmentService.isProduction()
                    ? "https://ieeecps.org"
                    : EnvironmentService.isTest()
                      ? "https://cpir-test.computer.org"
                      : "http://localhost:3030";
                this.socketPath = EnvironmentService.isDevelopment()
                    ? "/socket.io"
                    : "/cps/v2/api/socket.io";

                this._reAuthenticate();

                return {
                    joinProceedingsChannel:
                        this.joinProceedingsChannel.bind(this),
                    leaveProceedingsChannel:
                        this.leaveProceedingsChannel.bind(this),
                    getActiveAuthorKitConnections:
                        this.getActiveAuthorKitConnections.bind(this),
                    authenticatedClient: this.authenticatedClient,
                    client: this.client,
                };
            }
        },
    );
})();
