(function() {
    "use strict";

    angular.module("cpir").component("cpirCifWorkshopsPanel", {
        bindings: {
            proceeding: "<",
            isSponsored: "<",
            workshops: "<",
            onUpdate: "&",
            onSave: "&",
            enableConferenceTitleCheck: "<",
            isInquirySummary: "<",
            isConferenceTab: "<"
        },
        templateUrl:
            "application/cpir/cif/workshops/components/workshops-panel/workshops-panel.component.html",
        controller: class {
            constructor(
                $scope,
                $stateParams,
                authService,
                CifService,
                ProceedingRoleService,
                NotificationService
            ) {
                this.$scope = $scope;
                this.$stateParams = $stateParams;
                this.authService = authService;
                this.CifService = CifService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.activeWorkshopIndex = 0;
                if(this.workshops) {
                    this.activeWorkshop = this.workshops[
                        this.activeWorkshopIndex
                        ];
                }
            }

            $onChanges(changes) {
                if (
                    changes.workshops &&
                    changes.workshops.currentValue &&
                    !changes.workshops.previousValue
                ) {
                    this.workshops = changes.workshops.currentValue;
                    this.activeWorkshop = this.workshops[
                        this.activeWorkshopIndex
                        ];
                }
                if(changes.proceeding) {
                    this.proceeding = changes.proceeding.currentValue;
                    if (this.proceeding && !this.workshops) {
                        this.workshops = [];
                        this.addWorkshop();
                    }
                }

            }

            isEditor() {
                if (!this.proceeding) return false;
                return this.ProceedingRoleService.isEditor(this.proceeding);
            }

            removeActiveWorkshop() {
                if (this.activeWorkshop.pid) {
                    this.CifService.deleteWorkshop(
                        this.activeWorkshop,
                        this.proceeding
                    ).then(() => {
                        // this.workshops.splice(this.activeWorkshopIndex, 1);
                        this.NotificationService.send(
                            "success",
                            "The workshop was removed successfully."
                        );
                        this.checkForEmptyWorkshop();
                    });
                } else {
                    this.workshops.splice(this.activeWorkshopIndex, 1);
                    this.checkForEmptyWorkshop();
                }
            }

            activateWorkshop(workshopIndex) {
                this.activeWorkshopIndex = workshopIndex;
                this.activeWorkshop = this.workshops[workshopIndex];
                this.isActiveWorkshopValid = true;
            }

            updateWorkshop(workshop, isValid) {
                this.activeWorkshop = workshop;

                this.isActiveWorkshopValid = isValid;
                if(this.workshops === undefined) {
                    this.workshops = [];
                    workshop.brand = this.authService.getBrand();
                    this.workshops = [workshop];
                }
                this.onUpdate({
                    workshop: workshop,
                    workshops: this.workshops,
                    isValid: isValid
                });
            }

            addWorkshop() {
                console.log("inside add workshop");
                this.workshops.push({
                    brand: this.authService.getBrand(),
                    location: _.clone(this.proceeding.location),
                    postalCode: _.clone(this.proceeding.postalCode),
                    dates: _.clone(this.proceeding.dates)
                });

                this.activateWorkshop(this.workshops.length - 1);
                this.isActiveWorkshopValid = false;
            }

            checkForEmptyWorkshop() {
                if (this.workshops.length === 0) {
                    this.addWorkshop();
                } else {
                    this.activateWorkshop(0);
                }
            }
        }
    });
})();
