(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfSendApprovalToolbar", {
        bindings: {
            onRefresh: "&",
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/ak-conf-send-approval-toolbar/ak-conf-send-approval-toolbar.component.html",
        controller: class {
            constructor(
                ContactService,
                DeepLinkService,
                EmailService,
                NotificationService,
                ProceedingStatusService,
                IThenticateService
            ) {
                this.ContactService = ContactService;
                this.DeepLinkService = DeepLinkService;
                this.EmailService = EmailService;
                this.NotificationService = NotificationService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.IThenticateService = IThenticateService;
            }

            $onInit() {
                this.sendingEmail = false;
            }

            $onChanges(changes) {
                if (changes.proceeding && changes.proceeding.currentValue) {
                    this.ContactService.listByProceeding(this.proceeding)
                        .then(contacts => {
                            this.akLink = this.DeepLinkService.getAkDeepLink(
                                this.proceeding.pid
                            );
                            this.conferenceOrganizer = contacts.find(
                                c => c.isConferenceOrganizer
                            );
                        })
                        .catch(e => console.log(e));
                }
            }

            isSendButtonEnabled() {
                return (
                    this.akLink &&
                    this.conferenceOrganizer &&
                    this.proceeding &&
                    !this.sendingEmail
                );
            }

            sendButtonClicked() {
                const {
                    iThenticateEnabled
                } = this.proceeding.configuration.settings.ak.submission;

                if (iThenticateEnabled && !this.proceeding.icxId) {
                    this.NotificationService.send(
                        "danger",
                        "A conference ID is required before sending the author kit"
                    );
                    return;
                }
                this.sendingEmail = true;
                this.EmailService.customizeAndSend("ak-approval", {
                    to: [this.conferenceOrganizer.email],
                    data: {
                        organizerName: `${this.conferenceOrganizer.givenName} ${this.conferenceOrganizer.surname}`,
                        akLink: this.akLink,
                        contact: this.conferenceOrganizer,
                        proceeding: this.proceeding
                    }
                })
                    .then(() => {
                        return this.ProceedingStatusService.setAkPending(
                            this.proceeding.pid
                        );
                    })
                    .then(() => {
                        if (
                            iThenticateEnabled &&
                            !this.proceeding.iThenticateId
                        ) {
                            return this.IThenticateService.createFolder(
                                this.proceeding.pid
                            );
                        }
                    })
                    .then(() => {
                        this.onRefresh();
                        this.NotificationService.send(
                            "success",
                            "The message was successfully sent to the organizer."
                        );
                        this.sendingEmail = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.sendingEmail = false;
                    });
            }
        }
    });
})();
