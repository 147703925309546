(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkPaperAcceptanceController",
        class {
            constructor(
                pid,
                entryId,
                AKService,
                ProceedingService,
                ProceedingStatusService,
                TocEntriesApiService,
                FileService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.AKService = AKService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.FileService = FileService;
            }

            $onInit() {
                this.ProceedingService.get(this.pid).then(
                    proceeding => (this.proceeding = proceeding)
                );

                const openAccessFileAsync = this.FileService.getOpenAccessMetadataForEntry(
                    this.entryId
                );

                this.TocEntriesApiService.getEntry(this.entryId).then(
                    async entry => {
                        this.entry = entry;
                        this.didPurchaseOpenAccessCopyright =
                            this.entry.openAccessCopyright &&
                            this.entry.openAccessCopyright.purchased;
                        if (this.didPurchaseOpenAccessCopyright) {
                            this.openAccessRelease = await openAccessFileAsync;
                            if (!this.openAccessRelease) {
                                console.error(
                                    "Open Access was purchased but no release document was found"
                                );
                            }
                        }
                        if (
                            !entry.sentAkAcceptanceEmail &&
                            !this.didPurchaseOpenAccessCopyright
                        ) {
                            this.entry.sentAkAcceptanceEmail = true;
                            this.TocEntriesApiService.updateEntries(
                                this.entry
                            ).then(() => {
                                this.AKService.sendAKAcceptanceEmail();
                            });
                        }
                    }
                );

                this.AKService.setActive(this.pid, this.entryId).then(
                    client => {
                        console.log("disconnecting the client");
                        client.disconnect();
                    }
                );
            }

            downloadOpenAccessRelease() {
                this.FileService.getFile(this.openAccessRelease.vid);
            }
        }
    );
})();
