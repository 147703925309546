(function() {
    "use strict";

    angular.module("cpir").component("cpirCifSummaryConfPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-summary/components/conference-panel/conference-panel.component.html",
        controller: class {
            constructor($scope, InternationalDataService, PureDateService) {
                this.$scope = $scope;
                this.InternationalDataService = InternationalDataService;
                this.PureDateService = PureDateService;
            }

            $onInit() {
                this.countryOptions = this.InternationalDataService.getCountries();

                this.provinceOptions = this.InternationalDataService.getProvinces();

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            $onChanges({ proceeding: { currentValue: proceeding } }) {
                console.log(proceeding);
                if (proceeding && proceeding.dates) {
                    const { start, end, delivery } = proceeding.dates;
                    this.datesModel = {
                        start: this.PureDateService.inflate(start),
                        end: this.PureDateService.inflate(end),
                        delivery: this.PureDateService.inflate(delivery)
                    };
                }
            }

            isSponsoringAgencyRequired() {
                if (!this.proceeding) {
                    return false;
                } else {
                    return this.proceeding.isSponsored === true;
                }
            }
        }
    });
})();
