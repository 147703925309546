(function() {
    angular.module("cpir").component("cpirDashboardEditorPaSectionSwitcher", {
        bindings: {
            sections: "<",
            unorderedSections: "<",
            activeSection: "<",
            onSwitch: "&",
            onReorder: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/publication-agreement/components/section-switcher/section-switcher.component.html",
        controller: function() {
            let $ctrl = this;

            $ctrl.buttonClicked = ($index, type) => {
                $ctrl.onSwitch({ activeSectionIndex: $index, type: type });
            };

            $ctrl.moved = $index => {
                let name = $ctrl.sections[$index].name;
                $ctrl.sections.splice($index, 1);
                $ctrl.onReorder({ sections: $ctrl.sections, name: name });
            };
        }
    });
})();
