(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCController",

        class {
            constructor(
                $filter,
                $q,
                $state,
                $uibModal,
                $window,
                $timeout,
                CopyrightService,
                moment,
                FileService,
                NotificationService,
                EmailService,
                TocEntriesApiService,
                CsdlService,
                ItextService,
                pid,
                ProceedingService,
                ProceedingRoleService,
                AKService,
                $scope,
                $rootScope,
                WebSocketService,
                IThenticateService,
                PitstopService,
                DeepLinkService,
                FeathersService,
                Cpir2Service,
                store,
                TocNavigationService,
                ColumnSettingsService,
                TOCViewModeService,
            ) {
                this.pid = pid;
                this.$state = $state;
                this.$scope = $scope;
                this.$rootScope = $rootScope;
                this.CopyrightService = CopyrightService;
                this.EmailService = EmailService;
                this.NotificationService = NotificationService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.$filter = $filter;
                this.$uibModal = $uibModal;
                this.$window = $window;
                this.$timeout = $timeout;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.$q = $q;
                this.ItextService = ItextService;
                this.FileService = FileService;
                this.moment = moment;
                this.CsdlService = CsdlService;
                this.AKService = AKService;
                this.WebSocketService = WebSocketService;
                this.IThenticateService = IThenticateService;
                this.PitstopService = PitstopService;
                this.DeepLinkService = DeepLinkService;
                this.FeathersService = FeathersService;
                this.Cpir2Service = Cpir2Service;
                this.store = store;
                this.TocNavigationService = TocNavigationService;
                this.ColumnSettingsService = ColumnSettingsService;
                this.TOCViewModeService = TOCViewModeService;

                // Initialize column settings with both visibility and order
                const defaultSettings = {
                    visibility: {
                        type: true,
                        title: true,
                        pages: true,
                        pageNumber: true,
                        version: true,
                        file: true,
                        stamp: true,
                        supplement: true,
                        pitstop: true,
                        productionStatus: true,
                        promo: true,
                        paperId: true,
                        similarity: true,
                        copyrightType: true,
                        authors: true,
                        doi: true,
                    },
                    order: [
                        "type",
                        "title",
                        "pages",
                        "pageNumber",
                        "version",
                        "file",
                        "stamp",
                        "supplement",
                        "pitstop",
                        "productionStatus",
                        "promo",
                        "paperId",
                        "similarity",
                        "copyrightType",
                        "authors",
                        "doi",
                    ],
                };

                // Load column settings for this proceeding
                this.ColumnSettingsService.getSettings(this.pid)
                    .then((settings) => {
                        this.columnSettings = settings;
                        this.columnVisibility = this.columnSettings.visibility;
                    })
                    .catch((error) => {
                        console.error("Error loading column settings:", error);
                        // Use default settings if there's an error
                        this.columnSettings = defaultSettings;
                        this.columnVisibility = this.columnSettings.visibility;
                    });

                // Add scroll state properties
                this.isScrolledRight = false;
                this.hasMoreToScroll = false;
                this.canScrollLeft = false;
                this.canScrollRight = false;
                this.scrollHelperTopPosition = 0;
                this.horizontalScrollOffset = 0;

                // FAST MODE: Add header sync properties
                this.fastModeHeaderPosition = 0;
                this.fastModeContentScrollLeft = 0;

                // Bind scroll handlers
                this.handleScroll = this.handleScroll.bind(this);
                this.handleWindowScroll = this.handleWindowScroll.bind(this);
                this.scrollLeft = this.scrollLeft.bind(this);
                this.scrollRight = this.scrollRight.bind(this);
                this.getScrollHelperTop = this.getScrollHelperTop.bind(this);
                this.checkScrollable = this.checkScrollable.bind(this);
                this.getHeaderStyle = this.getHeaderStyle.bind(this);
                this.updateHeaderPosition =
                    this.updateHeaderPosition.bind(this);
                this.handleResize = this.handleResize.bind(this);

                // FAST MODE: Bind fast mode specific handlers
                this.syncFastModeHeader = this.syncFastModeHeader.bind(this);
                this.handleFastModeScroll =
                    this.handleFastModeScroll.bind(this);

                // Clean up scroll and resize event listeners on destroy (standard mode)
                $scope.$on("$destroy", () => {
                    const container =
                        document.querySelector(".table-container");
                    if (container) {
                        container.removeEventListener(
                            "scroll",
                            this.handleScroll,
                        );
                        window.removeEventListener("resize", this.handleResize);
                    }

                    window.removeEventListener(
                        "scroll",
                        this.handleWindowScroll,
                    );
                    window.removeEventListener("resize", this.handleResize);
                });

                // Add these new methods to the controller
                this.isFastMode = false;
                this.isToolbarExpanded = false;

                // FAST MODE: Toggle expanded toolbar in fast mode
                this.toggleExpandedToolbar = function () {
                    this.isToolbarExpanded = !this.isToolbarExpanded;

                    // Need to delay to ensure DOM is updated
                    this.$timeout(() => {
                        this.checkScrollable();
                        this.handleResize();
                    }, 100);
                };

                // Open summary modal instead of showing panels directly
                this.openSummaryModal = function () {
                    this.$uibModal.open({
                        component: "summaryModal",
                        size: "xl",
                        resolve: {
                            entries: () => this.entries,
                            proceeding: () => this.proceeding,
                            articles: () => this.articlesByEntry,
                            stampsByEntry: () => this.stampsByEntry,
                        },
                    });
                };

                // Handle inner scrolling for fast mode
                this.handleInnerScroll = function (event) {
                    if (!this.isFastMode) return;

                    var container = event.target;

                    // Update scroll state
                    this.isScrolledRight = container.scrollLeft > 0;
                    this.hasMoreToScroll =
                        container.scrollLeft <
                        container.scrollWidth - container.clientWidth - 1;

                    // Force digest to update UI
                    if (!this.$scope.$$phase) {
                        this.$scope.$apply();
                    }
                };

                // Store current proceeding info for navbar access
                this.$rootScope.currentPid = this.pid;
                this.$rootScope.currentProceeding = this.proceeding;
            }

            $onInit() {
                this.pid = this.pid;

                // Array to store event listener deregistration functions
                this._rootScopeListeners = [];

                // Add watcher for sorting changes to update fast mode entries
                this.$scope.$watchGroup(
                    [
                        "$ctrl.sortType",
                        "$ctrl.sortReverse",
                        "$ctrl.search.keyword",
                    ],
                    () => {
                        if (this.isFastMode) {
                            this.updateSortedEntries();
                        }
                    },
                );

                // Listen for events from the navbar via $rootScope
                this._rootScopeListeners.push(
                    this.$rootScope.$on("openColumnCustomizationModal", () => {
                        console.log(
                            "Received openColumnCustomizationModal event",
                        );
                        this.customizeColumns();
                    }),
                );

                this._rootScopeListeners.push(
                    this.$rootScope.$on("openSummaryModal", () => {
                        console.log("Received openSummaryModal event");
                        this.openSummaryModal();
                    }),
                );

                // Add event listeners for navigation dropdown in fast mode
                this._rootScopeListeners.push(
                    this.$rootScope.$on("goToConfiguration", () => {
                        console.log("Received goToConfiguration event");
                        this.goToConfiguration();
                    }),
                );

                this._rootScopeListeners.push(
                    this.$rootScope.$on("goToCif", () => {
                        console.log("Received goToCif event");
                        this.goToCif();
                    }),
                );

                this._rootScopeListeners.push(
                    this.$rootScope.$on("goToAk", () => {
                        console.log("Received goToAk event");
                        this.goToAk();
                    }),
                );

                this._rootScopeListeners.push(
                    this.$rootScope.$on("toggleViewMode", () => {
                        this.toggleViewMode();
                    }),
                );

                // handle the cleanup upon leaving the page
                this.$scope.$on("$destroy", () => {
                    this.client && this.client.disconnect();
                    this.stopIThenticateInterval();
                    this.FeathersService.leaveProceedingsChannel(this.pid);
                    console.log("disconnecting from TOC websocket");

                    // Reset fast mode flag when leaving
                    this.$rootScope.inTocFastMode = false;
                    this.$rootScope.currentPid = null;
                    this.$rootScope.currentProceeding = null;

                    // Remove keyboard event handler when leaving
                    if (this._fastModeKeyboardHandler) {
                        window.removeEventListener(
                            "keydown",
                            this._fastModeKeyboardHandler,
                        );
                        this._fastModeKeyboardHandler = null;
                    }

                    // Deregister all rootScope listeners
                    this._rootScopeListeners.forEach((deregisterFn) => {
                        if (typeof deregisterFn === "function") {
                            deregisterFn();
                        }
                    });
                    this._rootScopeListeners = [];
                });

                // Detect if we're in fast mode based on current state
                this.isFastMode =
                    this.$state.current.name ===
                    "dashboard-editor.table-of-contents-fast";
                console.log(
                    "TOC initialized in mode:",
                    this.isFastMode ? "Fast Mode" : "Standard Mode",
                );

                // Set the session view mode based on current state for this session
                this.TOCViewModeService.setSessionViewModeFromState(
                    this.pid,
                    this.$state.current.name,
                );

                // Set global flag for the layout to respond to
                this.$rootScope.inTocFastMode = this.isFastMode;

                // FAST MODE: Initialize fast mode specific scroll handling and search shortcut handler
                if (this.isFastMode) {
                    // Add class to body element for styling hooks
                    angular.element("body").addClass("has-fastmode-navbar");

                    this.$scope.$on("$viewContentLoaded", () => {
                        this.initFastModeScrollHandling();
                        this.initFastModeKeyboardHandlers();
                    });
                } else {
                    // Remove class from body element for styling hooks
                    angular.element("body").removeClass("has-fastmode-navbar");
                }

                // Store current proceeding info for navbar access
                this.ProceedingService.get(this.pid).then((proceeding) => {
                    this.$rootScope.currentProceeding = proceeding;
                    this.$rootScope.currentPid = this.pid;
                });

                this.useDropdown = false;

                this.search = {};
                this.search.keyword = "";

                this.sortType = "sequence";
                this.sortReverse = true;

                // throttle to prevent too many apply calls
                this.safeApply = _.throttle(
                    this.WebSocketService.safeApply || _.noop,
                    1000,
                );

                // batch save toc entries
                this.batchUpdateTocEntries =
                    this._batchUpdateTocEntries.bind(this);
                this.pitstopSuccessTooltip = "";
                this.pitstopSubmittedTooltip = "";
                this.pitstopErrorTooltip = "";
                this.selectedEntries = [];
                this.pendingIThenticateDocuments = new Set();

                // Restore any previously selected entries and sort order from navigation service
                if (this.TocNavigationService.getCurrentPid() === this.pid) {
                    if (!this.TocNavigationService.defaultAllSelected) {
                        this.selectedEntries =
                            this.TocNavigationService.getSelectedEntryIds();
                    }
                    const sortOrder = this.TocNavigationService.getSortOrder();
                    this.sortType = sortOrder.sortType;
                    this.sortReverse = sortOrder.sortReverse;
                }

                //start websocket
                this.clientAsync = this.TocEntriesApiService.startSocket(
                    this.pid,
                ).then((client) => {
                    this.client = client;
                    console.log("got client: ", client);

                    client.on("entries.file.missing", (missing) => {
                        this.missingFiles = this.missingFiles || {};
                        this.missingFiles[missing.vid] = missing;
                        this.applyMissingFiles(missing);
                    });

                    // handle iThenticate document status results
                    client.on(
                        "ithenticate.document.similarity",
                        this.getIThenticateDocumentResultHandler(client),
                    );

                    // handle pitstop document status results
                    client.on("pitstop.update", (eid, vid, pitstopStatus) => {
                        console.log(
                            "inside start socket for pitstop " +
                                this.pitstopStatus,
                        );
                        if (eid) {
                            let pitstopEntry = this.pitstopsByEntry[eid];
                            if (pitstopEntry.vid === vid)
                                pitstopEntry.pitstop = pitstopStatus;

                            let stampByEntry = this.stampsByEntry[eid];
                            if (stampByEntry.vid === vid)
                                stampByEntry.pitstop = pitstopStatus;

                            try {
                                this.safeApply(this.$scope);
                            } catch (err) {
                                console.log(err);
                            }
                        }
                    });

                    client.emit("get.ithenticate.documents");
                    client.emit("get.entries.files.missing");
                    return this.client;
                });

                /*
                 * Feathers
                 */
                // join the Feathers channel for this proceeding
                this.FeathersService.joinProceedingsChannel(this.pid)
                    .then(() =>
                        // get the active author-kit connections for this proceeding
                        this.FeathersService.getActiveAuthorKitConnections(
                            this.pid,
                        ),
                    )
                    .then((connections) => {
                        // set the activeEids to the eids of the active connections
                        console.log(
                            "active connections: ",
                            connections.map((c) => c.eid),
                        );
                        this.activeEids = new Set(
                            connections.map((c) => c.eid),
                        );
                        this.safeApply(this.$scope);
                    })
                    .then(() => {
                        const client = this.FeathersService.client;
                        // register event listeners for the proceedings channel
                        client
                            .service("toc-entries")
                            .on("created", (tocEntry) => {
                                console.log(
                                    "A toc-entry was created:",
                                    tocEntry,
                                );
                                this.refreshToc();
                            });

                        client
                            .service("toc-entries")
                            .on("patched", (tocEntry) => {
                                console.log(
                                    "A toc-entry was patched:",
                                    tocEntry,
                                );
                                this.refreshToc();
                            });

                        client
                            .service("toc-entries")
                            .on("removed", (tocEntry) => {
                                console.log(
                                    "A toc-entry was removed:",
                                    tocEntry,
                                );
                                this.refreshToc();
                            });

                        client
                            .service("author-kit-connection")
                            .on("created", (connectionTracker) => {
                                this.activeEids.add(connectionTracker.eid);
                                this.safeApply(this.$scope);
                            });

                        client
                            .service("author-kit-connection")
                            .on("removed", (connectionTracker) => {
                                this.activeEids.delete(connectionTracker.eid);
                                this.safeApply(this.$scope);
                            });
                        client.service("pitstop").on("created", (pid) => {
                            console.log(
                                "A toc-entry file was uploaded to pitstop:",
                                pid,
                            );
                            this.refreshToc();
                        });
                    });
                /*
                 * End Feathers
                 */

                /*
                 * Right click menu
                 */
                this.contextMenu = {
                    callback: (key, options) => {
                        switch (key) {
                            case "insertDivider":
                                this.enterTitle()
                                    .then((result) => {
                                        let sequence = $(
                                            options.$trigger[0],
                                        ).attr("data-sequence");
                                        angular.merge(result, {
                                            sequence: sequence,
                                        });
                                        let entryId;
                                        return this.TocEntriesApiService.insertSessionDivider(
                                            this.proceeding.pid,
                                            result.title,
                                            result,
                                        )
                                            .then((entryIdResult) => {
                                                entryId = entryIdResult;
                                                return this.refreshToc();
                                            })
                                            .then(() => {
                                                if (result.hasPdf) {
                                                    return this.ItextService.generateSessionDivider(
                                                        this.pid,
                                                        entryId,
                                                    );
                                                }
                                                return entryId;
                                            })
                                            .then(() =>
                                                this.NotificationService.send(
                                                    "success",
                                                    `Successfully inserted a new session divider.`,
                                                ),
                                            )
                                            .then(() => this.refreshToc());
                                    })
                                    .catch((err) => {
                                        if (
                                            err !== "cancel" &&
                                            err !== "backdrop click"
                                        ) {
                                            console.log("error: ", err);
                                            this.NotificationService.send(
                                                "danger",
                                                `There was a problem creating the session divider.`,
                                            );
                                        }
                                    });
                                break;
                            case "insertVolume":
                                return this.TocEntriesApiService.insertVolumeDivider(
                                    this.proceeding.pid,
                                    {
                                        sequence: $(options.$trigger[0]).attr(
                                            "data-sequence",
                                        ),
                                    },
                                )
                                    .then(() => this.refreshToc())
                                    .then(() =>
                                        this.NotificationService.send(
                                            "success",
                                            `Successfully inserted a new volume divider.`,
                                        ),
                                    )
                                    .catch((err) => {
                                        console.log("error: ", err);
                                        this.NotificationService.send(
                                            "danger",
                                            `There was a problem creating the volume divider.`,
                                        );
                                    });
                                break;
                        }
                    },
                    items: {
                        insertDivider: {
                            name: "Insert Session Divider After",
                            icon: "fa-plus",
                        },
                        insertVolume: {
                            name: "Insert Volume Divider After",
                            icon: "fa-plus",
                        },
                    },
                };

                this.isLoading = true;
                this.refreshToc(true).then(() => {
                    // Initialize scroll handling after view is ready
                    const container =
                        document.querySelector(".table-container");
                    if (container) {
                        container.addEventListener("scroll", this.handleScroll);
                        window.addEventListener(
                            "scroll",
                            this.handleWindowScroll,
                        );
                        window.addEventListener("resize", this.handleResize);

                        // Initial checks
                        this.checkScrollable();
                        this.handleScroll({ target: container });
                        this.updateScrollHelperPosition();
                    }
                });
            }

            handleResize() {
                this.checkScrollable();
                this.updateHeaderPosition();
                this.safeApply(this.$scope);
            }

            // Add new scroll handling methods
            checkScrollable() {
                const container = document.querySelector(".table-container");
                if (!container) return;

                const table = container.querySelector("table");
                if (!table) return;

                // Account for fixed columns in scroll calculation
                const fixedWidth = this.getFixedColumnId()
                    ? parseInt(this.getFixedColumnMinWidth()) + 80 // 80px for select column
                    : 80; // Just select column

                // Check if table is wider than container accounting for fixed columns
                const isScrollable =
                    table.scrollWidth - fixedWidth >
                    container.clientWidth - fixedWidth;

                // Update both left and right button visibility based on scrollability
                this.canScrollLeft = isScrollable;
                this.canScrollRight = isScrollable;

                // Update scroll position state
                if (isScrollable) {
                    const scrollLeft = container.scrollLeft;
                    const maxScroll =
                        container.scrollWidth - container.clientWidth;
                    this.hasMoreToScroll = scrollLeft < maxScroll;
                } else {
                    this.hasMoreToScroll = false;
                }

                // Force Angular digest cycle since this is outside Angular's knowledge
                this.safeApply(this.$scope);
            }

            handleWindowScroll() {
                this.updateScrollHelperPosition();

                this.updateHeaderPosition();

                // Force Angular digest cycle since this is outside Angular's knowledge
                if (!this.$scope.$$phase) {
                    this.$scope.$apply();
                }
            }

            handleScroll(event) {
                const container = event.target;
                const scrollLeft = container.scrollLeft;
                const maxScroll = container.scrollWidth - container.clientWidth;

                // Update scroll state
                this.isScrolledRight = scrollLeft > 0;
                this.hasMoreToScroll = scrollLeft < maxScroll;
                this.horizontalScrollOffset = scrollLeft;

                // Update helper button position
                this.updateScrollHelperPosition();

                // Force Angular digest cycle since this is outside Angular's knowledge
                if (!this.$scope.$$phase) {
                    this.$scope.$apply();
                }
            }

            updateHeaderPosition() {
                const container = document.querySelector(".table-container");
                if (!container) return;

                const table = container.querySelector("table");

                const headerRow = table.querySelector("thead tr:last-child");
                if (!headerRow) return;

                // Get positioning context
                const containerRect = container.getBoundingClientRect();

                // Account for the header position relative to the container
                const offset = 150;

                // Only apply relative positioning when scrolled past original position
                if (containerRect.top + offset < 0) {
                    // Calculate how far we've scrolled past the original position
                    this.headerTop = Math.abs(containerRect.top + offset);
                } else {
                    // Reset to static positioning when scrolled back up
                    this.headerTop = null;
                }
            }

            updateScrollHelperPosition() {
                const container = document.querySelector(".table-container");
                if (!container) return;

                const containerRect = container.getBoundingClientRect();
                const tableRect = container
                    .querySelector("table")
                    .getBoundingClientRect();

                // Calculate the visible area of the table
                const windowHeight = window.innerHeight;
                const visibleTop = Math.max(containerRect.top, 0);
                const visibleBottom = Math.min(tableRect.bottom, windowHeight);
                const visibleHeight = visibleBottom - visibleTop;

                // Position the buttons in the middle of the visible area
                // but ensure they stay within the table boundaries
                const middlePoint = visibleTop + visibleHeight / 2;

                // Adjust for fast mode
                const headerHeight = this.isFastMode ? 130 : 152; // Account for header and fixed navbar
                const minTop = containerRect.top + headerHeight;
                const maxTop = tableRect.bottom - 60; // Account for button height

                // Controls whether to use the middle point or the max top
                const factor = 30;

                this.scrollHelperTopPosition = Math.max(
                    minTop,
                    Math.min(middlePoint - factor, maxTop),
                );

                // Make sure helpers are always visible in fast mode
                if (this.isFastMode) {
                    // Ensure the helpers are at least below the navbar
                    this.scrollHelperTopPosition = Math.max(
                        this.scrollHelperTopPosition,
                        70,
                    );
                }
            }

            scrollLeft() {
                let container;

                if (this.isFastMode) {
                    // In fast mode, we need to target the virtual repeat scroller which handles the actual horizontal scrolling
                    container = document.querySelector(
                        ".md-virtual-repeat-scroller",
                    );
                    if (!container) {
                        // Fallback to the inner container if scroller not found
                        container = document.querySelector(
                            ".table-inner-container",
                        );
                    }
                } else {
                    container = document.querySelector(".table-container");
                }

                if (!container) return;

                const currentScroll = container.scrollLeft;
                const targetScroll = Math.max(0, currentScroll - 300);

                container.scrollTo({
                    left: targetScroll,
                    behavior: "smooth",
                });
            }

            scrollRight() {
                let container;

                if (this.isFastMode) {
                    // In fast mode, we need to target the virtual repeat scroller which handles the actual horizontal scrolling
                    container = document.querySelector(
                        ".md-virtual-repeat-scroller",
                    );
                    if (!container) {
                        // Fallback to the inner container if scroller not found
                        container = document.querySelector(
                            ".table-inner-container",
                        );
                    }
                } else {
                    container = document.querySelector(".table-container");
                }

                if (!container) return;

                const currentScroll = container.scrollLeft;
                const targetScroll = currentScroll + 300;

                container.scrollTo({
                    left: targetScroll,
                    behavior: "smooth",
                });
            }

            getHeaderStyle() {
                if (this.headerTop === null) {
                    return {}; // Return empty object for static positioning
                }
                return {
                    position: "relative",
                    top: this.headerTop + "px",
                };
            }

            isHeaderFloating() {
                return this.headerTop !== null;
            }

            getScrollHelperTop() {
                return `${this.scrollHelperTopPosition}px`;
            }

            /**
             * Get toolbar positioning style based on scroll position
             * @returns {Object} Style object with left position
             */
            getToolbarStyle() {
                if (!this.horizontalScrollOffset) {
                    return {};
                }

                return {
                    position: "relative",
                    left: `${this.horizontalScrollOffset}px`,
                };
            }

            /**
             * Calculate total number of visible columns for colspan
             * @returns {number} Total count of visible columns including fixed columns
             */
            getVisibleColumnCount() {
                // Always include the fixed columns (select and sequence)
                let count = 2;

                // Add count of visible dynamic columns
                if (this.columnVisibility) {
                    count += Object.keys(this.columnVisibility).filter(
                        (columnId) =>
                            columnId !== "sequence" &&
                            this.columnVisibility[columnId],
                    ).length;
                }

                console.log("count: ", count);

                return count;
            }

            /**
             * Calculate width percentage for toolbar container
             * @returns {string} CSS width value
             */
            getToolbarWidth() {
                const minWidth = this.getVisibleColumnCount() * 100; // Minimum width in pixels
                console.log("minWidth: ", minWidth);
                return `max(100%, ${minWidth}px)`;
            }

            /**
             * Handle toolbar scroll synchronization with table
             * @param {Event} event Scroll event
             */
            handleToolbarScroll(event) {
                const tableContainer =
                    document.querySelector(".table-container");
                const toolbarWrapper = event.target;

                // Synchronize horizontal scroll positions
                if (tableContainer) {
                    const scrollPercentage =
                        toolbarWrapper.scrollLeft /
                        (toolbarWrapper.scrollWidth -
                            toolbarWrapper.clientWidth);

                    const tableScrollMax =
                        tableContainer.scrollWidth - tableContainer.clientWidth;
                    tableContainer.scrollLeft =
                        scrollPercentage * tableScrollMax;
                }
            }

            applyMissingFiles(missingFile) {
                const missingFilesList =
                    (missingFile && [missingFile]) || this.missingFiles || [];
                _.map(missingFilesList, (missing) => {
                    switch (missing.type) {
                        case "article":
                            this.articlesAsync.then(() => {
                                this.articlesByEntry[missing.eid].missing =
                                    true;
                                // need to signal changes for websockets
                                this.safeApply(this.$scope);
                            });

                            break;
                        case "stamp":
                            this.stampsAsync.then(() => {
                                this.stampsByEntry[missing.eid].missing = true;
                                // need to signal changes for websockets
                                this.safeApply(this.$scope);
                            });
                    }
                });
            }

            /**
             * Creates a handler for iThenticate document results.
             * A closure is used to scope variables across calls
             * @param client
             */
            getIThenticateDocumentResultHandler(client) {
                let ithenticateRefreshInterval = null;

                // hook to stop the interval from outside
                this.stopIThenticateInterval = () => {
                    if (ithenticateRefreshInterval) {
                        console.log("Stopping existing iThenticate interval");
                        clearInterval(ithenticateRefreshInterval);
                        ithenticateRefreshInterval = null;
                    }
                };

                // the handler
                function handler(
                    entryId,
                    { iThenticateId, similarity, pending, error },
                ) {
                    // if TOC is not loaded wait for it to load
                    if (!this.entries) {
                        this.entriesAsync
                            .then(() => {
                                return handler.bind(this)(entryId, {
                                    similarity,
                                    pending,
                                    error,
                                });
                            })
                            .catch((err) => console.error(err));
                        return;
                    }

                    pending = pending === "1";
                    const entry = this.entryMap[entryId];

                    if (entry) {
                        if (iThenticateId) {
                            entry.iThenticateId = iThenticateId;
                        }
                        // check if the document is still being processed by iThenticate
                        if (pending) {
                            this.pendingIThenticateDocuments.add(entryId);

                            // set up an interval to check for iThenticate status updates
                            if (!ithenticateRefreshInterval) {
                                console.log(
                                    "starting iThenticate refresh interval",
                                );
                                ithenticateRefreshInterval = setInterval(() => {
                                    console.log(
                                        "checking for newly processed iThenticate documents",
                                    );
                                    client.emit("get.ithenticate.documents");
                                }, 1000 * 30);
                            }
                        } else if (error) {
                            this.pendingIThenticateDocuments.delete(entryId);

                            // if there is a status check interval running check if it can be cleared
                            if (
                                this.pendingIThenticateDocuments.size < 1 &&
                                ithenticateRefreshInterval
                            ) {
                                this.stopIThenticateInterval();
                            }

                            function makeHumanReadable(error) {
                                return error;
                            }

                            entry.similarity = "Not Processed";
                            entry.iThenticateError = makeHumanReadable(error);
                        } else {
                            // document is done being processed
                            this.pendingIThenticateDocuments.delete(entryId);

                            // if there is a status check interval running check if it can be cleared
                            if (
                                this.pendingIThenticateDocuments.size < 1 &&
                                ithenticateRefreshInterval
                            ) {
                                this.stopIThenticateInterval();
                            }
                            entry.similarity = similarity;
                        }

                        // need to signal changes for websockets
                        this.safeApply(this.$scope);
                    }
                }

                return handler.bind(this); // need to bind global scope
            }

            /*
             * Creates a handler for Pitstop document results.
             */
            onPitstopDocumentResult(eid, pitstopStatus) {
                console.log(
                    "inside start socket for pitstop " + this.pitstopStatus,
                );
                let entry = null;
                if (eid) {
                    entry = this.entryMap[eid];

                    entry.pitstop = pitstopStatus;
                    this.safeApply(this.$scope);
                }
                this.safeApply(this.$scope);
            }

            enterTitle() {
                return this.$uibModal.open({
                    animation: true,
                    component: "cpirTocAddSectionDividerModal",
                }).result;
            }

            isEntryAkActive(entry) {
                return this.activeEids && this.activeEids.has(entry.eid);
            }

            async _batchUpdateTocEntries(entries, batchSize = 400) {
                const batches = [];
                for (let i = 0; i < entries.length; i += batchSize) {
                    const batch = entries.slice(i, i + batchSize);
                    batches.push(batch);
                }

                await Promise.map(
                    batches,
                    async (batch) => {
                        await this.TocEntriesApiService.updateEntriesWithProjection(
                            batch,
                        );
                    },
                    { concurrency: 4 },
                );

                return this.entries;
            }

            refreshToc(setBatchSeqNull = false) {
                let entriesResolve;
                let articlesResolve;
                let stampsResolve;
                let supplementalResolve;
                let proceedingResolve;

                let proceedingCacheResolve;
                let entriesCacheResolve;
                let articlesCacheResolve;
                let stampsCacheResolve;
                let supplementalCacheResolve;

                let articlesFromCache;
                let stampsFromCache;
                let supplementalFromCache;
                let entriesFromCache;
                let proceedingFromCache;

                let tocEntrySaveData;

                this.entriesAsync = new Promise((resolve) => {
                    entriesResolve = resolve;
                });
                this.articlesAsync = new Promise((resolve) => {
                    articlesResolve = resolve;
                });
                this.stampsAsync = new Promise((resolve) => {
                    stampsResolve = resolve;
                });
                this.supplementalAsync = new Promise((resolve) => {
                    supplementalResolve = resolve;
                });
                this.proceedingAsync = new Promise((resolve) => {
                    proceedingResolve = resolve;
                });
                const proceedingFromCacheAsync = new Promise((resolve) => {
                    proceedingCacheResolve = resolve;
                });
                const entriesFromCacheAsync = new Promise((resolve) => {
                    entriesCacheResolve = resolve;
                });
                const articlesFromCacheAsync = new Promise((resolve) => {
                    articlesCacheResolve = resolve;
                });
                const stampsFromCacheAsync = new Promise((resolve) => {
                    stampsCacheResolve = resolve;
                });
                const supplementalFromCacheAsync = new Promise((resolve) => {
                    supplementalCacheResolve = resolve;
                });

                // start the iThenticate and missing files checks
                if (this.client) {
                    this.client.emit("get.ithenticate.documents");
                    this.missingFiles = null;
                    this.client.emit("get.entries.files.missing");
                }

                this.ProceedingService.getWithCache$(this.pid).subscribe({
                    next: (proceedingResult) => {
                        this.$timeout(() => {
                            proceedingFromCache = proceedingResult;
                            proceedingCacheResolve(proceedingResult);
                            this.proceeding = proceedingResult;
                            this.showEditorOptions =
                                this.ProceedingRoleService.isEditor(
                                    proceedingResult,
                                ) ||
                                this.ProceedingRoleService.isVendor(
                                    proceedingResult,
                                );
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log("Proceeding fetching completed");
                            proceedingResolve(proceedingFromCache);
                        });
                    },
                });

                // first load the page proceeding followed by the entries, file data, and full proceedings list

                // get article file data
                this.FileService.getFilesMetadataForConferenceWithCache$(
                    this.pid,
                    "article",
                    false,
                ).subscribe({
                    next: (fileData) => {
                        this.$timeout(() => {
                            articlesFromCache = fileData;
                            articlesCacheResolve(fileData);
                            this.articles = fileData;
                            this.articlesByEntry = _.keyBy(
                                _.filter(this.articles, "active"),
                                "eid",
                            );
                            this.pitstopsByEntry = _.keyBy(
                                _.filter(this.articles, "active"),
                                "eid",
                            );
                            this.versionNumbersByEntry = _.countBy(
                                this.articles,
                                "eid",
                            );
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log("Article files fetching completed");
                            articlesResolve(articlesFromCache);
                        });
                    },
                });

                // get stamp file data
                this.FileService.getFilesMetadataForConferenceWithCache$(
                    this.pid,
                    "stamp",
                    true,
                ).subscribe({
                    next: (fileData) => {
                        this.$timeout(() => {
                            stampsFromCache = fileData;
                            stampsCacheResolve(fileData);
                            this.stamps = fileData;
                            this.stampsByEntry = _.keyBy(this.stamps, "eid");
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log("Stamp files fetching completed");
                            stampsResolve(stampsFromCache);
                        });
                    },
                });

                // get supplemental file data
                this.FileService.getFilesMetadataForConferenceWithCache$(
                    this.pid,
                    "extra",
                ).subscribe({
                    next: (fileData) => {
                        this.$timeout(() => {
                            supplementalFromCache = fileData;
                            supplementalCacheResolve(fileData);
                            this.supplementalFiles = fileData;
                            // Group supplemental files by entry ID since there may be multiple per entry
                            this.supplementalFilesByEntry = _.groupBy(
                                this.supplementalFiles,
                                "eid",
                            );
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log(
                                "Supplemental files fetching completed",
                            );
                            supplementalResolve(supplementalFromCache);
                        });
                    },
                });

                // get entry data
                this.TocEntriesApiService.getEntriesWithCache$(
                    this.pid,
                ).subscribe({
                    next: (entries) => {
                        this.$timeout(() => {
                            entriesFromCache = entries;
                            entriesCacheResolve(entries);
                            this.entries = entries;

                            this.entryMap = _.keyBy(entries, "eid");
                            this.applyMissingFiles();

                            // Update sorted entries for fast mode
                            if (this.isFastMode) {
                                this.updateSortedEntries();
                            }
                        });
                    },
                    error: (err) => {
                        console.error("Error: ", err);
                    },
                    complete: () => {
                        this.$timeout(() => {
                            console.log("Entries fetching completed");
                            entriesResolve(entriesFromCache);
                        });
                    },
                });

                // load proceedings in background (for webpub search)
                this.proceedingsPromise = this.ProceedingService.list();

                return (
                    proceedingFromCacheAsync
                        .then(() => entriesFromCacheAsync)
                        .then((entries) => {
                            // Stop the loading spinner early if there are cached entries
                            if (entries.length > 0) {
                                this.isLoading = false;
                            } else {
                                // Otherwise, wait for the entries to load from the server
                                this.entriesAsync.then(() => {
                                    this.isLoading = false;
                                });
                            }
                        })
                        .then(() => this.proceedingAsync)
                        .then(() => {
                            // ensure iThenticate folder is created
                            const { iThenticateId } = this.proceeding;
                            const { iThenticateEnabled } =
                                this.proceeding.configuration.settings.ak
                                    .submission;

                            if (
                                iThenticateEnabled &&
                                !iThenticateId &&
                                this.proceeding.icxId
                            ) {
                                return this.IThenticateService.createFolder(
                                    this.pid,
                                )
                                    .then(() =>
                                        this.ProceedingService.get(this.pid),
                                    ) // reset the proceeding result
                                    .then(
                                        (proceedingResult) =>
                                            (this.proceeding =
                                                proceedingResult),
                                    )
                                    .then(() =>
                                        console.log(
                                            "created iThenticate Folder",
                                        ),
                                    );
                            }
                            return null;
                        })
                        .then(() => {
                            this.proceeding.toc = this.proceeding.toc || {};
                            this.toc = this.proceeding.toc;

                            // Add missing settings to the TOC if they are
                            // in the proceeding and ready to be transferred.
                            if (
                                !this.toc.trim &&
                                this.proceeding.additionalInformation
                            ) {
                                this.toc.trim =
                                    this.proceeding.additionalInformation.trim;
                                return this.ProceedingService.update(this.pid, {
                                    "toc.trim": this.toc.trim,
                                });
                            }
                        })
                        .then(() => this.entriesAsync)
                        .then((entries) => {
                            console.log("sorting entries");
                            // Set sequences
                            let entryNumber = 1;

                            this.entries = entries
                                .sort((a, b) => {
                                    if (!a.sequence) {
                                        return 1;
                                    } else {
                                        return a.sequence - b.sequence;
                                    }
                                })
                                .map((entry) => {
                                    entry.sequence = entryNumber++;
                                    if (setBatchSeqNull) {
                                        entry.batchSeq = null;
                                    }
                                    return entry;
                                });

                            // create an updated sequence number array for saving
                            tocEntrySaveData = this.entries.map((e) => ({
                                eid: e.eid,
                                sequence: e.sequence,
                                batchSeq: e.batchSeq,
                            }));
                        })
                        .then(() => {
                            // clear any existing iThenticate interval if there are no entries;
                            const entriesInIthenticate = _.some(
                                this.entries,
                                "iThenticateId",
                            );
                            console.log(
                                "entries in iThenticate: ",
                                entriesInIthenticate,
                            );
                            if (
                                !entriesInIthenticate &&
                                this.stopIThenticateInterval
                            ) {
                                typeof this.stopIThenticateInterval ===
                                    "function" &&
                                    this.stopIThenticateInterval();
                            }
                        })
                        .catch((e) => console.log(e))
                        // defer pagination until after loading spinner since it depends on articlesByEntry response
                        .then(async () => {
                            await this.articlesAsync;
                            // paginate the entries
                            await this.batchPaginate({
                                save: false,
                                hideNotification: true,
                            });
                            // add page numbers to save data
                            tocEntrySaveData = tocEntrySaveData.map((e) => {
                                const entry = this.entryMap[e.eid];
                                return {
                                    ...e,
                                    pageNumber: entry.pageNumber,
                                    pages: entry.pages,
                                    isPageNumberRoman: entry.isPageNumberRoman,
                                };
                            });

                            // Restore the previous sort order from navigation service
                            // This is applied again after pagination since it resets the sort order
                            if (
                                this.TocNavigationService.getCurrentPid() ===
                                this.pid
                            ) {
                                const sortOrder =
                                    this.TocNavigationService.getSortOrder();
                                this.sortType = sortOrder.sortType;
                                this.sortReverse = sortOrder.sortReverse;
                            }

                            // clear the temporary state in the navigation service
                            this.TocNavigationService.reset();
                        })
                        .then(() => {
                            /* update entry sequence numbers in the background
                             * Update in batches to avoid large payloads
                             * */
                            this.batchUpdateTocEntries(tocEntrySaveData).catch(
                                (err) => {
                                    console.error(
                                        "Error update entry sequence numbers:",
                                        err,
                                    );
                                },
                            );
                        })
                );
            }

            isArticleMissing(entry) {
                return (
                    this.articlesByEntry &&
                    this.articlesByEntry[entry.eid] &&
                    this.articlesByEntry[entry.eid].missing
                );
            }

            isStampMissing(entry) {
                return (
                    this.stampsByEntry && this.stampsByEntry[entry.eid].missing
                );
            }

            editVersions(entry) {
                if (this.showEditorOptions) {
                    this.$state.go(
                        "dashboard-editor.table-of-contents-repository-versions",
                        {
                            pid: this.pid,
                            entryId: entry.eid,
                        },
                    );
                }
            }

            getVersionNumber(entry) {
                return (
                    this.versionNumbersByEntry &&
                    this.versionNumbersByEntry[entry.eid]
                );
            }

            quickSelect() {
                this.$uibModal
                    .open({
                        component: "cpirTocQuickSelectModal",
                        resolve: {
                            articles: () => this.articles,
                            entries: () => this.entries,
                            isEntryAkActive: () =>
                                this.isEntryAkActive.bind(this),
                        },
                    })
                    .result.then((ids) => {
                        // merging with previously selected may be dangerous and cause the editor to unintentionally delete an paper
                        // this.selectedEntries = Array.from(new Set((this.selectedEntries || []).concat(ids)));

                        this.selectedEntries = ids;
                    });
            }

            hasStamp(entry) {
                return this.stampsByEntry && this.stampsByEntry[entry.eid];
            }

            isPitstopMissing(entry) {
                const obj = this.PitstopService.isPitstopMissing(
                    entry,
                    this.stampsByEntry,
                    this.pitstopsByEntry,
                );
                return obj.result;
            }

            isPitstopSubmitted(entry) {
                const obj = this.PitstopService.isPitstopSubmitted(
                    entry,
                    this.stampsByEntry,
                    this.pitstopsByEntry,
                );
                return obj.result;
            }

            isPitstopError(entry) {
                const obj = this.PitstopService.isPitstopError(
                    entry,
                    this.stampsByEntry,
                    this.pitstopsByEntry,
                );
                return obj.result;
            }

            isPitstopSuccess(entry) {
                if (
                    this.PitstopService.stampFilePitstopSuccess(
                        entry,
                        this.stampsByEntry,
                    )
                )
                    this.pitstopSuccessTooltip = "stamp PDF";
                else if (
                    this.PitstopService.rawFilePitstopSuccess(
                        entry,
                        this.pitstopsByEntry,
                    )
                )
                    this.pitstopSuccessTooltip = "article PDF";
            }

            getPitstopErrorTooltip(entry) {
                const obj = this.PitstopService.isPitstopError(
                    entry,
                    this.stampsByEntry,
                    this.pitstopsByEntry,
                );
                this.pitstopErrorTooltip =
                    obj.isStampError === true ? "stamp PDF" : "article PDF";
                return this.pitstopErrorTooltip;
            }

            getPitstopSubmittedTooltip(entry) {
                const obj = this.PitstopService.isPitstopSubmitted(
                    entry,
                    this.stampsByEntry,
                    this.pitstopsByEntry,
                );
                this.pitstopSubmittedTooltip =
                    obj.isStampSubmitted === true ? "stamp PDF" : "article PDF";
                return this.pitstopSubmittedTooltip;
            }

            editEntry(entry) {
                if (this.showEditorOptions) {
                    // Store sorted entries in navigation service before navigating
                    let entriesToUse = this.entries;
                    let selectAll = true;
                    if (
                        this.selectedEntries &&
                        this.selectedEntries.length > 0
                    ) {
                        // Filter to just the selected entries
                        entriesToUse = this.entries.filter((e) =>
                            this.selectedEntries.includes(e.eid),
                        );
                        selectAll = false;
                    }

                    // Apply sorting to the entries
                    const sortedEntries = this.$filter("entryOrderByFilter")(
                        entriesToUse,
                        this.articlesByEntry,
                        this.versionNumbersByEntry,
                        this.stampsByEntry,
                        this.supplementalFilesByEntry,
                        this.pitstopsByEntry,
                        this.sortType,
                        this.sortReverse,
                    );

                    // Store entries, sort order, and navigate
                    this.TocNavigationService.setEntries(
                        sortedEntries,
                        this.pid,
                        selectAll,
                    );
                    this.TocNavigationService.setSortOrder(
                        this.sortType,
                        this.sortReverse,
                    );
                    this.TocNavigationService.setCurrentEntry(entry.eid);

                    this.$state.go(
                        "dashboard-editor.table-of-contents-edit-entry",
                        {
                            pid: this.pid,
                            entryId: entry.eid,
                        },
                    );
                }
            }

            batchReorder() {
                // Toggle whether isReordering
                this.isReordering = !this.isReordering;

                // Whenever reordering is entered or exited,
                // set the table to order by sequence
                this.sortType = "sequence";
                this.sortReverse = true;

                // Do actions based on leaving or entering
                // the isReordering mode
                if (this.isReordering) {
                    this.entries = this.entries.map((e) => {
                        if (!e.batchSeq) e.batchSeq = e.sequence;
                        return e;
                    });
                } else {
                    this.entries = this.entries.map((e) => {
                        e.sequence = e.batchSeq;
                        return e;
                    });

                    // Save sequence numbers in the DB
                    const entrySequenceNumberData = this.entries.map((e) => ({
                        eid: e.eid,
                        batchSeq: e.batchSeq,
                        sequence: e.sequence,
                    }));
                    return this.batchUpdateTocEntries(entrySequenceNumberData);
                }
            }

            batchResequence() {
                let newSeq = 1;

                this.entries = this.entries
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((e) => {
                        e.batchSeq = null;
                        e.sequence = newSeq++;
                        return e;
                    });

                this.resetTableView();

                // Save sequence numbers in the DB
                const entrySequenceNumberData = this.entries.map((e) => ({
                    eid: e.eid,
                    batchSeq: e.batchSeq,
                    sequence: e.sequence,
                }));
                return this.batchUpdateTocEntries(entrySequenceNumberData).then(
                    () => this.refreshToc(),
                );
            }

            async checkDoiAlreadyExistsInDB(newDoi) {
                return Promise.join(
                    this.doiAlreadyExistsInCSDL(newDoi),
                    this.doiAlreadyExistsInCPIR(newDoi),
                    function (isDoiInCsdl, isDoiInCpir) {
                        console.log("isDoiInCsdl: " + isDoiInCsdl);
                        console.log("isDoiInCpir: " + isDoiInCpir);
                        if (isDoiInCsdl || isDoiInCpir) {
                            return true;
                        } else return false;
                    },
                );
            }
            doiAlreadyExistsInCSDL(newDoi) {
                console.log(
                    "Check doi already exists in csdl - toc.js- " + newDoi,
                );
                return this.CsdlService.checkDoiDuplicate(
                    newDoi,
                    this.proceeding.acronym,
                    this.proceeding.year,
                ).then((result) => {
                    return result;
                });
            }

            doiAlreadyExistsInCPIR(newDoi) {
                console.log(
                    "Check doi already exists in CPIR - toc.js- " + newDoi,
                );
                return this.TocEntriesApiService.getDoi(newDoi).then(
                    (entries) => {
                        if (entries.length !== 0) return true;
                        else return false;
                    },
                );
            }

            async getNextAvailableDoi({
                entry,
                doi,
                volume,
                sequence,
                doiPrefix,
            }) {
                const acronym = this.toc.acronym || this.proceeding.acronym;
                const year = this.proceeding.year;

                // helper function for finding duplicate DOIs
                const doiAlreadyExistsInSameConference = (doi) =>
                    this.doiMap[doi] && this.doiMap[doi].eid !== entry.eid;

                let newDoi = doi;

                // Create an alternate numbering sequence in case the DOI is
                // already taken.
                let alternateSequence = 1;

                console.log(
                    "Alternate DOI sequence -",
                    "sequence:",
                    sequence,
                    "alt-sequence:",
                    alternateSequence,
                );
                while (
                    doiAlreadyExistsInSameConference(newDoi) ||
                    (await this.checkDoiAlreadyExistsInDB(newDoi))
                ) {
                    newDoi = `${doiPrefix}/${acronym}.${year}.${volume}${pad(
                        alternateSequence++,
                        4,
                    )}`;
                }

                if (doi !== newDoi) {
                    console.log(
                        `Doi collision found for ${doi}. Using next available DOI: ${newDoi} `,
                    );
                }

                return newDoi;
            }

            async batchDOIGeneration() {
                this.doiMap = _.keyBy(this.entries, "doi");
                let copyrightType =
                    this.proceeding.configuration.settings.ak.copyright.type;

                let acronym = this.toc.acronym || this.proceeding.acronym;
                let year = this.proceeding.year;

                if (!acronym || !year || !copyrightType) {
                    this.NotificationService.send(
                        "warning",
                        "The DOIs could not be generated due to missing parameters.",
                    );
                }
                if (
                    this.proceeding.acronym &&
                    this.proceeding.acronym.indexOf("/") > -1
                ) {
                    this.NotificationService.send(
                        "danger",
                        "Replace slashes in conference acronym with hyphen and retry Doi generation",
                    );
                    return;
                }

                let sequence = 1;
                let volume = 0;
                this.entries = this.entries.sort(
                    (a, b) => a.sequence - b.sequence,
                );

                let newEntries = [];
                for (let i = 0; i < this.entries.length; i++) {
                    let e = this.entries[i];
                    if (!e.doi || (e.doi && e.doi.trim() === "")) {
                        if (
                            e.class === "SB" ||
                            e.class === "FM" ||
                            e.class === "BM"
                        ) {
                            if (
                                copyrightType === "ieee" ||
                                copyrightType === "acm"
                            ) {
                                const doiPrefix =
                                    copyrightType === "ieee"
                                        ? "10.1109"
                                        : "10.1145";
                                const startingDoi = `${doiPrefix}/${acronym}.${year}.${volume}${pad(
                                    sequence++, // incrementing the sequence
                                    4,
                                )}`;
                                const newdoi = await this.getNextAvailableDoi({
                                    entry: e,
                                    sequence,
                                    doi: startingDoi,
                                    doiPrefix,
                                    volume,
                                });

                                if (
                                    // only change DOIs if they haven't already been set
                                    e.isDoiLocked ||
                                    (e.doi && e.doi.trim() !== "")
                                ) {
                                    console.log("inside doi locked");
                                    return e;
                                } else {
                                    e.doi = newdoi;
                                    this.doiMap[newdoi] = e;
                                    newEntries.push(e);
                                }
                            } else {
                                throw true;
                            }
                        } else {
                            if (e.class === "VD") {
                                volume++;
                            }
                            e.doi = null;
                            newEntries.push(e);
                        }
                    }
                }
                this.entries = newEntries;
                const entryDoiData = this.entries.map((e) => ({
                    eid: e.eid,
                    doi: e.doi,
                }));

                this.resetTableView();
                this.batchUpdateTocEntries(entryDoiData)
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The DOIs were successfully generated.",
                        );
                    })
                    .then(() => this.refreshToc());
            }

            batchArchiveEntries() {
                if (this.isEntriesSpecified()) {
                    let entryIds = this.getEntriesSelected();
                    this.AKService.checkActive(entryIds).then((activeAks) => {
                        let hasOpen = activeAks.length;
                        activeAks = activeAks
                            .map((id) => this.entryMap[id])
                            .filter((e) => e.eid)
                            .map(
                                (e) =>
                                    `<li>seq: ${e.sequence} - title: ${e.title}</li>`,
                            );

                        let entryListHtml = `<p><strong>CAUTION: The following entries are currently opened in the author kit:</strong></p><ul>${activeAks.join(
                            "",
                        )}</ul>`;
                        this.$uibModal
                            .open({
                                component: "cpirConfirmationModal",
                                resolve: {
                                    title: () => "Warning",
                                    message: () =>
                                        `${
                                            hasOpen ? entryListHtml : ""
                                        }<p>Are you sure you want to delete the ${
                                            entryIds.length
                                        } selected entries?</p>`,
                                },
                            })
                            .result.then(() => {
                                return this.TocEntriesApiService.deleteEntries(
                                    entryIds,
                                );
                            })
                            .then(() => this.refreshToc())
                            .catch((e) => console.log(e));
                    });
                } else {
                    this.NotificationService.send(
                        "warning",
                        "Please select the entries to be deleted.",
                    );
                }
            }

            /**
             * Function for manual submission of documents to iThenticate
             * @return {Promise<void>}
             */
            async batchSubmitToIThenticate() {
                const { iThenticateEnabled } =
                    this.proceeding.configuration.settings.ak.submission;

                if (!iThenticateEnabled) {
                    this.NotificationService.send(
                        "warning",
                        "CrossRef submission is disabled for this conference",
                    );
                    return;
                }

                if (iThenticateEnabled && !this.proceeding.icxId) {
                    this.NotificationService.send(
                        "danger",
                        "Proceeding is missing a Conference ID",
                    );
                    return;
                }
                if (this.isEntriesSpecified()) {
                    this.isLoading = true;
                    const entries = this.getEntriesSelected();
                    await this.IThenticateService.batchSubmitDocuments(
                        this.pid,
                        entries,
                    );
                    this.NotificationService.send(
                        "success",
                        "Submitted documents to iThenticate. It may take some time to submit all documents and receive results.",
                    );
                    this.client.emit("get.ithenticate.documents");
                    this.refreshToc();
                } else {
                    this.NotificationService.send(
                        "warning",
                        "Please select the entries to be submitted to iThenticate.",
                    );
                }
            }

            batchCopyrightCheck() {
                /**
                 * Helper function to update the entries and refresh the table of contents
                 * @returns {*}
                 */
                const updateTocEntries = (changedEntries) => {
                    const entryCopyrightData = this.entries.map((e) => ({
                        eid: e.eid,
                        copyrightLine: e.copyrightLine,
                        copyrightType: e.copyrightType,
                    }));
                    return this.batchUpdateTocEntries(entryCopyrightData)
                        .then(() => {
                            this.NotificationService.send(
                                "success",
                                `Copyrights for ${changedEntries} ${
                                    changedEntries === 1 ? "entry" : "entries"
                                } were successfully updated.`,
                            );
                        })
                        .catch((err) => {
                            console.error(err);
                            this.NotificationService.send(
                                "danger",
                                `Error updating entries: ${err}`,
                            );
                        })
                        .then(() => this.refreshToc());
                };

                this.isLoading = true;
                const copyrightLineType = this.toc.copyrightLineType;
                let changedEntries = 0;

                this.resetTableView();

                // Get all entries that are selected or all entries if none are selected
                const batchCopyrightEntries = this.entries
                    .sort((a, b) => a.sequence - b.sequence)
                    .filter((e) => e.class === "SB" && e.type === "AP");

                // If the copyright line type is set to 'Override', then
                // update all entries with that line 'other'.
                if (copyrightLineType === "override") {
                    // If the override line is null or undefined, set it to ''
                    if (!this.toc.copyrightOtherLineType) {
                        this.toc.copyrightOtherLineType = "";
                    }

                    batchCopyrightEntries.forEach((e) => {
                        // Only update entries when there none selected (global-mode) or
                        // the entry is specified (selected-mode)
                        if (
                            !this.isEntriesSpecified() ||
                            this.isEntrySelected(e.eid)
                        ) {
                            const entry = this.entryMap[e.eid];
                            if (
                                entry.copyrightLine !==
                                this.toc.copyrightOtherLineType
                            ) {
                                changedEntries++;
                                entry.copyrightLine =
                                    this.toc.copyrightOtherLineType || "";
                            }
                        }
                    });

                    return updateTocEntries(changedEntries);
                }

                // If the copyright line type is set to 'Blank', then
                // update all entries with an empty field.
                if (copyrightLineType === "blank") {
                    batchCopyrightEntries.forEach((e) => {
                        // Only update entries when there none selected (global-mode) or
                        // the entry is specified (selected-mode)
                        if (
                            !this.isEntriesSpecified() ||
                            this.isEntrySelected(e.eid)
                        ) {
                            const entry = this.entryMap[e.eid];
                            if (entry.copyrightLine !== "") {
                                entry.copyrightLine = "";
                                changedEntries++;
                            }
                        }
                    });

                    return updateTocEntries(changedEntries);
                }

                // If the copyright line type is set to 'OA', then
                // update all entries selected with the OA line.
                if (copyrightLineType === "oa") {
                    batchCopyrightEntries.forEach((entry) => {
                        if (
                            (!this.isEntriesSpecified() ||
                                this.isEntrySelected(entry.eid)) &&
                            !entry.copyrightLine &&
                            entry.authors &&
                            entry.authors.length > 0
                        ) {
                            const author = entry.authors[0];
                            entry.copyrightLine = `© ${
                                this.proceeding.year
                            }, ${
                                author.givenName + " " + author.surname
                            }. Under license to IEEE.`;
                            changedEntries++;
                        }
                    });

                    return updateTocEntries(changedEntries);
                }

                // If the copyright line type is set to 'standard', then
                // update all entries selected with IEEE Copyright Data & set the Copyright Line.
                if (copyrightLineType === "standard") {
                    // check if the proceeding has an ICX ID
                    const icxId = this.proceeding.icxId;
                    if (!icxId) {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "warning",
                            "The proceeding does not have an ICX ID.",
                        );
                        return;
                    }

                    // check if the production settings are valid
                    if (!this.isProductionSettingsValid()) {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "warning",
                            "The production settings are incomplete.",
                        );
                        return;
                    }

                    // get the list of copyrights from the IEEE ECF Service
                    this.CopyrightService.getBatchCopyright(icxId)
                        .then((ieeeCopyrightMap) => {
                            batchCopyrightEntries.forEach((entry) => {
                                let entryChanged = false;

                                // get the IEEE Copyright value
                                const articleIdValue =
                                    entry.articleId &&
                                    entry.articleId.toString();
                                const ieeeCopyrightValue = articleIdValue
                                    ? ieeeCopyrightMap[articleIdValue]
                                    : null;

                                // If IEEE copyright data wasn't found (maybe missing articleId)
                                // then end this iteration
                                if (!ieeeCopyrightValue) return;

                                // Only update entries when there none selected (global-mode) or
                                // the entry is specified (selected-mode)
                                if (
                                    !this.isEntriesSpecified() ||
                                    this.isEntrySelected(entry.eid)
                                ) {
                                    // set the IEEE copyright type if it was found and the entry doesn't have one specified
                                    if (
                                        !entry.copyrightType &&
                                        ieeeCopyrightValue
                                    ) {
                                        entry.copyrightType =
                                            ieeeCopyrightValue;
                                        entryChanged = true;
                                    }

                                    // Only update copyright line if it isn't already set
                                    if (
                                        entry.copyrightType &&
                                        !entry.copyrightLine
                                    ) {
                                        entryChanged = true;
                                        const year = this.proceeding.year;
                                        const shortYear = year - 2000;
                                        const issnOrIsbn =
                                            this.toc.issn ||
                                            this.toc.isbn13 ||
                                            this.toc.isbn;
                                        const reprintCost =
                                            this.toc.reprintCost;

                                        switch (entry.copyrightType) {
                                            case "US":
                                            case "USG":
                                                entry.copyrightLine = `U.S. Government work not protected by U.S. copyright`;
                                                break;
                                            case "EU":
                                                entry.copyrightLine = `${issnOrIsbn}/${shortYear}/${reprintCost} ©${year} European Union`;
                                                break;
                                            case "CRWN":
                                                entry.copyrightLine = `${issnOrIsbn}/${shortYear}/${reprintCost} ©${year} Crown`;
                                                break;
                                            default:
                                                entry.copyrightLine = `${issnOrIsbn}/${shortYear}/${reprintCost} ©${year} IEEE`;
                                                break;
                                        }
                                    }
                                }

                                if (entryChanged) changedEntries++;
                            });

                            return updateTocEntries(changedEntries);
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            this.NotificationService.send(
                                "error",
                                "Error loading IEEE ECF data: " + error,
                            );
                            console.error(error);
                        });
                }
            }

            batchPaginate({ save, hideNotification }) {
                this.sortType = "sequence";
                this.sortReverse = true;

                let isPassedSessionDivider = false;
                let currentPageTotal = 1;

                this.entries = this.entries
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((entry) => {
                        if (entry.class === "VD") entry.pages = 0;
                        else entry.pages = entry.pages || 1;

                        // Return true or false depending on whether there
                        // are actually pages in the document.
                        let hasNoPages = !this.$filter(
                            "entryPagesPlusPaddingFilter",
                        )(entry, this.articlesByEntry);

                        if (
                            hasNoPages &&
                            entry.class !== "SD" &&
                            entry.class !== "VD"
                        ) {
                            entry.pageNumber = "";
                            return entry;
                        }

                        entry.isPageNumberRoman = false;

                        switch (entry.class) {
                            case "FM":
                                switch (entry.type) {
                                    case "CA":
                                        entry.pageNumber = "";
                                        return entry;
                                    default:
                                        entry.pageNumber = currentPageTotal;
                                        if (isPassedSessionDivider === false) {
                                            entry.isPageNumberRoman = true;
                                        }
                                        break;
                                }
                                break;
                            case "BM":
                                switch (entry.type) {
                                    case "BM_ROSTER": //pub info even
                                        if (isOdd(currentPageTotal))
                                            currentPageTotal++;
                                        entry.pageNumber = currentPageTotal;
                                        break;
                                    case "BM_AUTHOR_INDEX": //author index odd
                                        if (isEven(currentPageTotal))
                                            currentPageTotal++;
                                        entry.pageNumber = currentPageTotal;
                                        break;
                                }
                                break;
                            case "SB":
                                entry.pageNumber = currentPageTotal;
                                break;
                            case "SD":
                                if (hasNoPages) {
                                    entry.pageNumber = "";
                                    if (isPassedSessionDivider) {
                                    } else {
                                        currentPageTotal = 1;
                                    }
                                    isPassedSessionDivider = true;
                                } else {
                                    if (isPassedSessionDivider)
                                        entry.pageNumber = currentPageTotal;
                                    else {
                                        currentPageTotal = 1;
                                        entry.pageNumber = currentPageTotal;
                                        isPassedSessionDivider = true;
                                    }
                                }
                                break;
                            case "VD":
                                entry.pages = 0;
                                currentPageTotal = 1;
                                entry.pageNumber = "";
                                isPassedSessionDivider = false;
                                break;
                        }

                        if (hasNoPages) {
                            return entry;
                        }

                        // Add to the current page count as needed
                        let entryPages = entry.pages
                            ? parseInt(entry.pages)
                            : 0;
                        let entryPagePadding = entry.pagePadding
                            ? parseInt(entry.pagePadding)
                            : 0;

                        let accumulatedPages = entryPages + entryPagePadding;

                        currentPageTotal = currentPageTotal + accumulatedPages;

                        return entry;
                    });

                const entryPageData = this.entries.map((e) => ({
                    eid: e.eid,
                    pages: e.pages,
                    pageNumber: e.pageNumber,
                }));

                if (save) {
                    return this.batchUpdateTocEntries(entryPageData).then(
                        () => {
                            if (!hideNotification) {
                                this.NotificationService.send(
                                    "success",
                                    "The page numbers were successfully saved.",
                                );
                            }
                            return this.entries;
                        },
                    );
                } else {
                    return this.$q.when(this.entries);
                }
            }

            editProductionSettings() {
                this.$uibModal
                    .open({
                        component: "cpirTocProductionSettingsModal",
                        resolve: { proceeding: this.proceeding },
                        size: "lg",
                    })
                    .result.then(
                        () => {
                            this.refreshToc();
                            this.NotificationService.send(
                                "success",
                                "The settings were successfully updated.",
                            );
                        },
                        (reason) => {},
                    );
            }

            editProductionLogo() {
                this.$uibModal
                    .open({
                        component: "cpirTocProductionLogoModal",
                        resolve: {},
                    })
                    .result.then(
                        () => {
                            this.refreshToc();
                            this.NotificationService.send(
                                "success",
                                "The logo was successfully updated.",
                            );
                        },
                        (reason) => {},
                    );
            }

            exportTOC() {
                this.$uibModal
                    .open({
                        backdrop: "static",
                        keyboard: false,
                        size: "lg",
                        component: "cpirExportTocModal",
                        resolve: {
                            proceeding: this.proceeding,
                        },
                    })
                    .result.then(() => {})
                    .catch(() => {});
            }

            // modal and component are not currently used.
            openImportModal() {
                let modalInstance = this.$uibModal
                    .open({
                        size: "lg",
                        component: "tocBatchUpload",
                        resolve: {
                            pid: () => this.pid,
                            actions: {
                                refreshToc: this.refreshToc.bind(this),
                            },
                        },
                    })
                    .result.catch((err) => {
                        if (err !== "cancel" && err !== "backdrop click")
                            console.error(err);
                    });
            }

            stamp() {
                this.$uibModal
                    .open({
                        component: "cpirTocStampingCustomizationModal",
                        resolve: {
                            proceeding: () => this.proceeding,
                        },
                    })
                    .result.then((stampOptions) => {
                        this.isLoading = true;
                        if (this.isEntriesSpecified()) {
                            let selectedEntryIds = this.entries
                                .filter((e) => this.isEntrySelected(e.eid))
                                .map((e) => e.eid);

                            return this.ItextService.stamp(
                                this.pid,
                                selectedEntryIds,
                                stampOptions,
                            )
                                .then(() =>
                                    this.NotificationService.send(
                                        "success",
                                        "Successfully stamped all selected documents.",
                                    ),
                                )
                                .catch((err) => {
                                    console.log(err);
                                    this.NotificationService.send(
                                        "danger",
                                        `There was a problem stamping some or all documents: ${err}`,
                                    );
                                })
                                .then(() => this.refreshToc());
                        } else {
                            return this.ItextService.stamp(
                                this.pid,
                                null,
                                stampOptions,
                            )
                                .then(() =>
                                    this.NotificationService.send(
                                        "success",
                                        "Successfully stamped all documents.",
                                    ),
                                )
                                .catch((err) => {
                                    console.log(err);
                                    this.NotificationService.send(
                                        "danger",
                                        `There was a problem stamping some or all documents: ${err}`,
                                    );
                                })
                                .then(() => this.refreshToc());
                        }
                    })
                    .catch((err) => {});
            }

            pitstop() {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () =>
                                this.isEntriesSpecified()
                                    ? "Are you sure you want to submit selected PDFs to pitstop?"
                                    : "Are you sure you want to submit all PDFs to pitstop?",
                        },
                    })
                    .result.then(() => {
                        this.isLoading = true;
                        if (this.isEntriesSpecified()) {
                            let selectedEntryIds = this.entries
                                .filter((e) => this.isEntrySelected(e.eid))
                                .filter(
                                    (e) =>
                                        this.articlesByEntry[e.eid] &&
                                        this.articlesByEntry[e.eid] !==
                                            undefined &&
                                        this.articlesByEntry[e.eid] !== null,
                                )
                                .map((e) => e.eid);
                            console.log(selectedEntryIds);
                            return this.PitstopService.pitstopValidate(
                                this.pid,
                                selectedEntryIds,
                                this.articlesByEntry,
                            )
                                .then(() => {
                                    this.NotificationService.send(
                                        "success",
                                        "Successfully submitted all selected documents for pitstop validation.",
                                    );
                                    this.selectedEntries = [];
                                    this.refreshToc();
                                })
                                .catch((err) => {
                                    console.log(err);
                                    this.NotificationService.send(
                                        "danger",
                                        `There was a problem submitting some or all documents to pitstop: ${err}`,
                                    );
                                })
                                .then(() => this.refreshToc());
                        } else {
                            let selectedEntryIds =
                                this.getUnprocessedPitstopEntries();
                            return this.PitstopService.pitstopValidate(
                                this.pid,
                                selectedEntryIds,
                                this.articlesByEntry,
                            )
                                .then(() =>
                                    this.NotificationService.send(
                                        "success",
                                        "Successfully submitted all documents for pitstop validation.",
                                    ),
                                )
                                .catch((err) => {
                                    console.log(err);
                                    this.NotificationService.send(
                                        "danger",
                                        `There was a problem submitting some or all documents to pitstop: ${err}`,
                                    );
                                })
                                .then(() => this.refreshToc());
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            createTOCEntry() {
                this.isLoading = true;
                const volumes = this.TocEntriesApiService.getVolumes(
                    this.entries,
                );
                let tocEntries = volumes
                    .map((v) =>
                        v.find((e) => e.type === "FM_TABLE_OF_CONTENTS"),
                    )
                    .filter((e) => e);
                const entriesMatchVolumes =
                    tocEntries && tocEntries.length === volumes.length;
                let entriesPromise;
                if (!entriesMatchVolumes) {
                    entriesPromise = this.batchResequence()
                        .then(() => {
                            this.isLoading = true;
                            return this.batchPaginate({
                                save: true,
                                hideNotification: true,
                            });
                        })
                        .then(() => {
                            return this.TocEntriesApiService.insertTableOfContentsEntries(
                                this.pid,
                            );
                        })
                        .then((entryResults) => (tocEntries = entryResults));
                }
                this.$q
                    .when(entriesMatchVolumes ? tocEntries : entriesPromise)
                    .then(() => this.refreshToc())
                    .then(() => {
                        this.isLoading = true;
                        return this.ItextService.generateTOC(this.pid);
                    })
                    .then(() =>
                        this.NotificationService.send(
                            "success",
                            `Successfully generated a new TOC.`,
                        ),
                    )
                    .catch((err) => {
                        console.log(err);
                        return this.NotificationService.send(
                            "danger",
                            `There was a problem creating a new TOC: ${
                                err.message || err
                            }`,
                        );
                    })
                    .then(() => this.refreshToc());
            }

            createAuthorIndex() {
                this.isLoading = true;
                const volumes = this.TocEntriesApiService.getVolumes(
                    this.entries,
                );
                let entries = volumes
                    .map((v) => v.find((e) => e.type === "BM_AUTHOR_INDEX"))
                    .filter((e) => e);
                const entriesMatchVolumes =
                    entries && entries.length === volumes.length;
                let entriesPromise;
                if (!entriesMatchVolumes) {
                    entriesPromise = this.batchResequence()
                        .then(() => {
                            this.isLoading = true;
                            return this.batchPaginate({
                                save: true,
                                hideNotification: true,
                            });
                        })
                        .then(() => {
                            return this.TocEntriesApiService.insertAuthorIndices(
                                this.pid,
                            );
                        })
                        .then((entryResults) => (entries = entryResults));
                }
                this.$q
                    .when(entriesMatchVolumes ? entries : entriesPromise)
                    .then(() => this.refreshToc())
                    .then(() => {
                        this.isLoading = true;
                        return this.ItextService.generateAuthorIndex(this.pid);
                    })
                    .then(() =>
                        this.NotificationService.send(
                            "success",
                            `Successfully generated a new author index.`,
                        ),
                    )
                    .catch((err) => {
                        console.log(err);
                        this.NotificationService.send(
                            "danger",
                            `There was a problem creating a new author index:\n${
                                err.message || err
                            }`,
                        );
                    })
                    .then(() => this.refreshToc());
            }

            buildBook() {
                this.$uibModal
                    .open({
                        backdrop: "static",
                        keyboard: false,
                        size: "lg",
                        component: "cpirBuildBookModal",
                        resolve: {
                            proceeding: this.proceeding,
                        },
                    })
                    .result.then(() => {})
                    .catch(() => {});
            }

            buildWebPub() {
                this.proceedingsPromise
                    .then((proceedings) => {
                        return this.$uibModal.open({
                            size: "xl",
                            component: "tocWebPub",
                            resolve: {
                                proceeding: () => this.proceeding,
                                proceedings: () => proceedings,
                            },
                        }).result;
                    })
                    .then(() => {
                        this.$state.go(
                            "dashboard-editor.table-of-contents-downloads",
                            { pid: this.pid },
                        );
                    })

                    .catch((err) => {
                        if (err && err !== "cancel" && err !== "backdrop click")
                            console.error(err);
                    });
            }

            countPages() {
                this.isLoading = true;
                this.ItextService.countAllPages(this.pid)
                    .then(() => {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "success",
                            "The entry pages were successfully counted.",
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                        this.NotificationService.send(
                            "danger",
                            `There was a problem counting pages: ${err.message}`,
                        );
                    })
                    .then(() => this.refreshToc());
            }

            isProductionSettingsValid() {
                if (!this.toc) return false;
                if (!this.toc.acronym) return false;
                if (!this.toc.issn && !this.toc.isbn && !this.toc.isbn13)
                    return false;
                if (!this.toc.reprintCost) return false;
                if (!this.toc.copyrightLineType) return false;
                return true;
            }

            sequenceButtonLabel() {
                return this.isReordering ? "Save Sequence" : "Edit Sequence";
            }

            resetTableView() {
                this.isReordering = false;
                this.sortType = "sequence";
                this.sortReverse = true;
            }

            selectAll() {
                if (
                    this.selectedEntries &&
                    this.selectedEntries.length === this.entries.length
                ) {
                    this.selectedEntries = [];
                } else if (
                    !this.selectedEntries ||
                    !this.selectedEntries.length
                ) {
                    this.selectedEntries = this.entries.map((e) => e.eid);
                } else {
                    this.selectedEntries = this.entries.map((e) => e.eid);
                }
            }

            isEntriesSpecified() {
                return this.selectedEntries && this.selectedEntries.length > 0;
            }

            isEntrySelected(entryId) {
                return this.isEntriesSpecified()
                    ? this.selectedEntries.find((id) => id === entryId)
                    : false;
            }

            getEntriesSelected() {
                return this.entries
                    .filter((e) => this.isEntrySelected(e.eid))
                    .map((e) => e.eid);
            }

            getUnprocessedPitstopEntries() {
                return this.entries
                    .filter((e) => {
                        if (
                            this.pitstopsByEntry[e.eid] &&
                            (this.pitstopsByEntry[e.eid].pitstop ===
                                undefined ||
                                this.pitstopsByEntry[e.eid].pitstop === "error")
                        ) {
                            return true;
                        }
                    })
                    .map((e) => e.eid);
            }

            showLockDois() {
                if (!this.entries) return false;
                let isAllPapersLocked = true;
                this.entries
                    .filter((e) => e.class === "SB" && e.type === "AP")
                    .forEach((e) => {
                        if (!e.isDoiLocked) {
                            isAllPapersLocked = false;
                        }
                    });
                return !isAllPapersLocked;
            }

            showUnlockDois() {
                return !this.showLockDois();
            }

            downloadArticle(entry) {
                if (!this.articlesByEntry || !this.articlesByEntry[entry.eid]) {
                    this.NotificationService.send(
                        "error",
                        "No article exists for the entry",
                    );
                    return;
                }

                this.FileService.getFile(this.articlesByEntry[entry.eid].vid);
            }

            downloadStamp(entry) {
                if (!this.stampsByEntry || !this.stampsByEntry[entry.eid]) {
                    this.NotificationService.send(
                        "error",
                        "No stamp exists for the entry",
                    );
                    return;
                }

                this.FileService.getFile(this.stampsByEntry[entry.eid].vid);
            }

            uploadArticleFile(entry) {
                let currentFileRecordForEntry;
                if (
                    this.PitstopService.stampFilePitstopError(
                        entry,
                        this.stampsByEntry,
                    )
                ) {
                    currentFileRecordForEntry = this.stampsByEntry[entry.eid];
                } else if (
                    this.PitstopService.rawFilePitstopError(
                        entry,
                        this.pitstopsByEntry,
                    )
                ) {
                    currentFileRecordForEntry = this.pitstopsByEntry[entry.eid];
                }
                this.$uibModal
                    .open({
                        component: "cpirTocReUploadModal", // pid: () => this.pid,
                        resolve: {
                            proceeding: () => this.proceeding,
                            pid: () => this.pid,
                            entry: () => entry,
                            pitstopEntry: () => currentFileRecordForEntry,
                        },
                    })
                    .result.then(
                        () => {
                            this.refreshToc();
                            this.NotificationService.send(
                                "success",
                                "The pdf were successfully updated.",
                            );
                        },
                        (reason) => {},
                    );
            }

            checkConfExistsInCSDL(proceeding) {
                return this.CsdlService.isConferenceInCsdl(proceeding).then(
                    (result) => {
                        return result;
                    },
                );
            }

            async checkConf(proceeding) {
                return Promise.join(
                    this.checkConfExistsInCSDL(proceeding),
                    function (isConfInCsdl) {
                        console.log("isConfInCsdl: " + isConfInCsdl);

                        if (isConfInCsdl) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                );
            }

            csdlExport() {
                const allDatesSet = this.entries.reduce((allValid, entry) => {
                    if (this.hasDoi(entry)) {
                        return allValid && this.hasPromoDates(entry);
                    } else {
                        return allValid;
                    }
                }, true);
                if (this.toc.isPrePrint && !allDatesSet) {
                    this.NotificationService.send(
                        "warning",
                        "Promotional dates are required for all articles of open preview conferences.  Please add any missing dates",
                    );
                    return;
                }
                if (
                    this.proceeding.acronym &&
                    this.proceeding.acronym.indexOf("/") > -1
                ) {
                    this.NotificationService.send(
                        "danger",
                        "Replace slashes in conference acronym with hyphen and retry Doi generation",
                    );
                    return;
                }
                if (
                    _.isNil(this.proceeding.icxId) ||
                    _.trim(this.proceeding.icxId) === ""
                ) {
                    this.NotificationService.send(
                        "danger",
                        `Missing ConferenceNumber(ICX ID) - Please enter before exporting to CSDL`,
                    );
                    return;
                }
                this.$uibModal
                    .open({
                        component: "cpirCsdlExportModal",
                        size: "xl",
                        resolve: {
                            pid: () => this.pid,
                            entries: () => this.entries,
                            includeExtras: () => true,
                            proceeding: () => this.proceeding,
                        },
                    })
                    .result.then(async (modalResult) => {
                        //update the status of the selected entries
                        const entryProductionStatusData = this.entries.map(
                            (entry) => {
                                entry.productionStatus = "complete";
                                return {
                                    eid: entry.eid,
                                    productionStatus: entry.productionStatus,
                                };
                            },
                        );
                        await this.batchUpdateTocEntries(
                            entryProductionStatusData,
                        );
                        return modalResult;
                    })
                    .then(async ({ includeExtras }) => {
                        this.isLoading = true;
                        let proceedingExistsInCSDL = false;

                        console.log(this.proceeding.toc.isContinuousPub);
                        console.log(this.proceeding.toc.continuousPubStatus);
                        if (
                            this.proceeding.toc.isContinuousPub &&
                            this.proceeding.toc.continuousPubStatus !== "final"
                        ) {
                            if (await this.checkConf(this.proceeding)) {
                                console.log("present");
                                proceedingExistsInCSDL = true;
                                throw new Error(`Proceeding already in CSDL`);
                            }
                        }
                        if (!proceedingExistsInCSDL) {
                            console.log("to export");
                            return this.CsdlService.exportToCsdl(
                                this.pid,
                                includeExtras,
                            );
                        }
                    })
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "Successfully exported to CSDL.",
                        );
                        return this.refreshToc();
                    })
                    .catch((err) => {
                        if (
                            err &&
                            err !== "dismiss" &&
                            err !== "backdrop click" &&
                            !err.errorType
                        ) {
                            this.NotificationService.send("danger", err);
                            return this.refreshToc();
                        } else {
                            if (err !== "backdrop click") {
                                this.NotificationService.sendArray(
                                    "danger",
                                    err.results,
                                );
                                return this.refreshToc();
                            }
                        }
                    });
            }

            hasPromoDates(entry) {
                return (
                    (entry.enablePromoDates &&
                        entry.promoDates &&
                        ((entry.promoDates.start && 1) || 0) +
                            ((entry.promoDates.end && 1) || 0)) ||
                    0
                ); // add 1 for each date set
            }

            batchSetPromoDates() {
                this.$uibModal
                    .open({
                        component: "cpirBatchSetPromoDatesModal",
                        resolve: {
                            entries: () => this.entries,
                        },
                    })
                    .result.then(() => {
                        this.isLoading = true;
                        const entryPromoDatesData = this.entries.map(
                            (entry) => ({
                                eid: entry.eid,
                                enablePromoDates: entry.enablePromoDates,
                                promoDates: entry.promoDates,
                            }),
                        );
                        return this.batchUpdateTocEntries(entryPromoDatesData);
                    })
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            "The promotional dates have been updated",
                        );
                        return this.refreshToc();
                    });
            }

            hasDoi(entry) {
                return entry.doi;
            }

            async openSimilarityReport({ iThenticateId }) {
                const url =
                    await this.IThenticateService.getSimilarityReportLink(
                        this.pid,
                        iThenticateId,
                    );
                if (url) {
                    window.open(url, "_blank");
                }
            }

            /**
             * Locks or unlocks all DOIs.
             * @param {boolean} lock
             */
            toggleDoiLock(lock) {
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () =>
                                lock
                                    ? `Are you sure you want to lock all DOIs?`
                                    : `Are you sure you want to unlock all DOIs?`,
                        },
                    })
                    .result.then(() => {
                        const entryDoiLockData = this.entries.map((e) => {
                            e.isDoiLocked = lock;
                            return {
                                eid: e.eid,
                                isDoiLocked: e.isDoiLocked,
                            };
                        });
                        return this.batchUpdateTocEntries(entryDoiLockData);
                    })
                    .then(() => {
                        this.NotificationService.send(
                            "success",
                            `All DOIs were successfully ${
                                lock ? "locked" : "unlocked"
                            }.`,
                        );
                        this.refreshToc();
                    })
                    .catch((e) => console.log(e));
            }

            authorReview() {
                if (this.isEntriesSpecified()) {
                    let entryIds = this.getEntriesSelected();
                    // open modal to confirm the email submission
                    const modelInstance = this.$uibModal
                        .open({
                            component: "cpirTocConfirmAuthorCommunicationModal",
                            size: "xl",
                            windowClass: "custom-modal-height",
                            resolve: {
                                entryIds: () => entryIds,
                                proceeding: () => this.proceeding,
                            },
                        })
                        .result.then(
                            async (template, needStatusChange, status) => {
                                needStatusChange = false;
                                let templateToSend = template.template;
                                let statusToChange = template.status;

                                // open the email customization window & get the customized template
                                const result =
                                    await this.EmailService.customize(
                                        templateToSend,
                                        statusToChange,
                                        this.proceeding.editor.email,
                                        null,
                                        entryIds,
                                        this.entryMap,
                                    );
                                const emailTemplate = result.template;
                                const fromAddress = result.fromAddress;
                                let toAddress = []; //result.toAddress;
                                const ccAddress = result.ccAddress;
                                const bccAddress = result.bccAddress;

                                // Prepare email data for bulk sending
                                const emailsData = [];

                                // Process entry data but send emails in bulk on the backend
                                entryIds.forEach((entryId) => {
                                    // get the current entry out of the list
                                    const entry = this.entryMap[entryId];
                                    toAddress = [];

                                    // update the status to author-review
                                    if (
                                        !_.isNil(entry) &&
                                        statusToChange !== null
                                    ) {
                                        entry.productionStatus =
                                            statusToChange.value;
                                        needStatusChange = true;
                                    }

                                    let data = {};
                                    // create deep link for entry
                                    const authorReviewLink =
                                        this.DeepLinkService.getAkAuthorReviewDeepLink(
                                            entryId,
                                        );
                                    // create email data and props
                                    // create deep link for entry
                                    const akLink =
                                        this.DeepLinkService.getAkDeepLink(
                                            entry.pid,
                                        );

                                    let dueDate =
                                        this.proceeding.productionSchedule.find(
                                            (item) => {
                                                if (
                                                    item.name.indexOf(
                                                        "Camera-Ready",
                                                    ) > -1
                                                ) {
                                                    return true;
                                                }
                                            },
                                        );
                                    if (dueDate) {
                                        const date = new Date(dueDate.date);
                                        const day = date.getDate() + 1; // Day of the month (1-31)
                                        const month = date.getMonth() + 1; // Month (0-11, so add 1 to get the 1-12 range)
                                        dueDate = month + "/" + day;
                                    }

                                    let validationArray = [];

                                    if (!this.proceeding.productionSchedule) {
                                        validationArray.push(
                                            "missingProdSchedule",
                                            "true",
                                        );
                                    } else if (
                                        !this.proceeding.dates ||
                                        (this.proceeding.dates &&
                                            (!this.proceeding.dates.start ||
                                                !this.proceeding.dates.end ||
                                                !this.proceeding.dates
                                                    .delivery))
                                    ) {
                                        validationArray.push(
                                            "missingConferenceDates",
                                            "true",
                                        );
                                    } else {
                                        console.log("before prodsch");
                                        let productionSchedule = _.keyBy(
                                            this.proceeding.productionSchedule,
                                            "id",
                                        );

                                        Object.values(
                                            productionSchedule,
                                        ).forEach((item) => {
                                            if (item && item.date) {
                                                const date = new Date(
                                                    item.date,
                                                );
                                                item.formattedDate =
                                                    date.getMonth() +
                                                    1 +
                                                    "/" +
                                                    (date.getDate() + 1) +
                                                    "/" +
                                                    date.getUTCFullYear(); // Day of the month (1-31)
                                            }
                                        });

                                        const startDate = new Date(
                                            this.proceeding.dates.start,
                                        );
                                        const endDate = new Date(
                                            this.proceeding.dates.end,
                                        );
                                        const deliveryDate = new Date(
                                            this.proceeding.dates.delivery,
                                        );

                                        const conferenceDates = {
                                            start:
                                                startDate.getMonth() +
                                                1 +
                                                "/" +
                                                (startDate.getDate() + 1) +
                                                "/" +
                                                startDate.getUTCFullYear(),
                                            end:
                                                endDate.getMonth() +
                                                1 +
                                                "/" +
                                                (endDate.getDate() + 1) +
                                                "/" +
                                                endDate.getUTCFullYear(),
                                            delivery:
                                                deliveryDate.getMonth() +
                                                1 +
                                                "/" +
                                                (deliveryDate.getDate() + 1) +
                                                "/" +
                                                deliveryDate.getUTCFullYear(),
                                        };

                                        // create email data and props
                                        data = {
                                            proceeding: this.proceeding,
                                            entry,
                                            akLink,
                                            dueDate,
                                            authorReviewLink,
                                            productionSchedule,
                                            conferenceDates,
                                        };

                                        let authorEmailList = [];
                                        let ccEmailList = [];
                                        let bccEmailList = [];

                                        if (
                                            entry.authors &&
                                            entry.authors.length > 0
                                        ) {
                                            entry.authors.forEach((author) => {
                                                if (
                                                    author.email &&
                                                    author.email !== ""
                                                )
                                                    authorEmailList.push(
                                                        author.email,
                                                    );
                                            });
                                        }
                                        if (ccAddress) {
                                            let ccList = ccAddress.split(",");
                                            ccList.forEach((email) => {
                                                ccEmailList.push(email);
                                            });
                                        }

                                        if (bccAddress) {
                                            let bccList = bccAddress.split(",");
                                            bccList.forEach((email) => {
                                                bccEmailList.push(email);
                                            });
                                        }

                                        bccEmailList.push(
                                            "no-reply-cps@computer.org",
                                        );

                                        if (
                                            result.toAddress ===
                                            "submitterEmail"
                                        ) {
                                            if (
                                                entry.submitterEmail ===
                                                    undefined ||
                                                !entry.submitterEmail ||
                                                entry.submitterEmail === ""
                                            ) {
                                                validationArray.push(
                                                    "missingSubmitterEmail",
                                                    entry.eid,
                                                );
                                            } else {
                                                toAddress = [
                                                    entry.submitterEmail,
                                                ];
                                            }
                                        } else if (
                                            result.toAddress === "allAuthors"
                                        ) {
                                            toAddress = authorEmailList; // this is an array
                                            if (toAddress.length === 0) {
                                                validationArray.push(
                                                    "missingAuthors",
                                                    entry.eid,
                                                );
                                            }
                                        } else if (
                                            result.toAddress ===
                                            "submitterAndAuthors"
                                        ) {
                                            toAddress = authorEmailList;
                                            if (
                                                entry.submitterEmail ===
                                                    undefined ||
                                                !entry.submitterEmail ||
                                                entry.submitterEmail === ""
                                            ) {
                                                validationArray.push(
                                                    "missingSubmitterEmail",
                                                    entry.eid,
                                                );
                                            } else
                                                toAddress.push(
                                                    entry.submitterEmail,
                                                );
                                            console.log("allllll");
                                            if (toAddress.length === 0) {
                                                validationArray.push(
                                                    "missingSubmitterAndAuthor",
                                                    entry.eid,
                                                );
                                            }
                                        }

                                        const props = {
                                            to: toAddress, //toAddress, //this will b the choice passed eiher submi, or list of authora
                                            from: fromAddress,
                                            cc: ccEmailList,
                                            bcc: bccEmailList,
                                            data,
                                        };

                                        // Add to bulk email data instead of sending individually
                                        if (toAddress.length > 0)
                                            emailsData.push({
                                                template:
                                                    _.clone(emailTemplate),
                                                props: props,
                                            });
                                    }
                                });

                                // Send all emails at once through the backend (process will continue even if user closes browser)
                                try {
                                    const response =
                                        await this.EmailService.bulkSendTemplates(
                                            emailsData,
                                            10,
                                        );
                                    const operationId =
                                        response.data.operationId;

                                    // Store the operation ID in localStorage for later reference
                                    const bulkOperations = JSON.parse(
                                        localStorage.getItem(
                                            "bulkEmailOperations",
                                        ) || "[]",
                                    );
                                    bulkOperations.push({
                                        id: operationId,
                                        timestamp: new Date().toISOString(),
                                        count: emailsData.length,
                                        type: "authorReview",
                                    });

                                    // Keep only the 10 most recent operations
                                    if (bulkOperations.length > 10) {
                                        bulkOperations.shift();
                                    }

                                    localStorage.setItem(
                                        "bulkEmailOperations",
                                        JSON.stringify(bulkOperations),
                                    );

                                    this.NotificationService.send(
                                        "success",
                                        `Email sending process started for ${emailsData.length} entries. This will continue in the background.`,
                                    );

                                    // Set up a modal to monitor this process if the user wants
                                    this.$uibModal.open({
                                        component: "cpirBulkEmailStatusModal",
                                        resolve: {
                                            operationId: () => operationId,
                                            totalCount: () => emailsData.length,
                                            EmailService: () =>
                                                this.EmailService,
                                        },
                                    });
                                } catch (err) {
                                    console.error(
                                        "Problem initiating bulk email sending:",
                                        err,
                                    );
                                    this.NotificationService.send(
                                        "danger",
                                        "There was a problem initiating the email sending process.",
                                    );
                                }

                                if (needStatusChange) {
                                    let entriesToUpdate = [];
                                    entriesToUpdate = this.entries.filter(
                                        (entry) => {
                                            return emailsData.find(
                                                (item) =>
                                                    item.props.data.entry
                                                        .eid === entry.eid,
                                            );
                                        },
                                    );

                                    const entryProductionStatusData =
                                        entriesToUpdate.map((item) => ({
                                            eid: item.eid,
                                            productionStatus:
                                                item.productionStatus,
                                        }));

                                    // save the updated entries
                                    return this.batchUpdateTocEntries(
                                        entryProductionStatusData,
                                    );
                                }
                            },
                        )
                        .then(() => this.refreshToc())
                        .catch((err) => {
                            console.error(err);
                        });

                    /*     modelInstance.finally(function() {
                        // Apply the inline styles once the modal is completely rendered
                        var modalDialog = angular.element(modalInstance.modalDomEl[0]).find('.modal-dialog');

                        // Set the inline height
                        modalDialog.css({
                            'height': '500px',
                            'max-height': '90%',  // Optional max-height
                        });

                        // Optionally set styles for the modal body to ensure it's scrollable if needed
                        var modalBody = modalDialog.find('.modal-body');
                        modalBody.css({
                            'height': 'calc(100% - 60px)', // Adjust according to header/footer height
                            'overflow-y': 'auto',
                        });
                    });*/
                } else {
                    this.NotificationService.send(
                        "warning",
                        "Please select the entries for Author Communication.",
                    );
                }
            }

            updateStatus() {
                if (this.isEntriesSpecified()) {
                    let entryIds = this.getEntriesSelected();
                    // open modal to confirm the status change
                    this.$uibModal
                        .open({
                            component: "cpirTocChangeEntryStatusModal",
                            resolve: {
                                entryIds: () => entryIds,
                            },
                        })
                        .result.then(async (result) => {
                            entryIds.forEach((entryId) => {
                                // get the current entry out of the list
                                const entry = this.entryMap[entryId];
                                // update the status to author-review
                                if (!_.isNil(entry)) {
                                    entry.productionStatus = result.status;
                                }
                            });

                            const entryProductionStatusData = this.entries.map(
                                (entry) => ({
                                    eid: entry.eid,
                                    productionStatus: entry.productionStatus,
                                }),
                            );

                            // save the updated entries
                            return this.batchUpdateTocEntries(
                                entryProductionStatusData,
                            );
                        })
                        .then(() => this.refreshToc())
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    this.NotificationService.send(
                        "warning",
                        "Please select the entries for Updating status.",
                    );
                }
            }

            selectEntry(event, entry) {
                // Create a sorted array of entries
                let sortedEntries = this.$filter("entryOrderByFilter")(
                    this.entries,
                    this.articlesByEntry,
                    this.versionNumbersByEntry,
                    this.stampsByEntry,
                    this.supplementalFilesByEntry,
                    this.pitstopsByEntry,
                    this.sortType,
                    this.sortReverse,
                );

                // Find indices in the sorted array
                const currentIndex = sortedEntries.indexOf(entry);
                const lastCheckedIndex = this.lastChecked
                    ? sortedEntries.indexOf(this.lastChecked)
                    : -1;

                // Handle shift-click selection
                if (
                    this.lastChecked &&
                    event.shiftKey &&
                    lastCheckedIndex !== -1 &&
                    currentIndex !== -1
                ) {
                    // Determine if we're selecting or deselecting based on the clicked entry
                    const isSelecting = !this.selectedEntries.includes(
                        entry.eid,
                    );

                    // Calculate range
                    let start = Math.min(lastCheckedIndex, currentIndex);
                    let end = Math.max(lastCheckedIndex, currentIndex);

                    // Process all entries in the range
                    for (let i = start; i <= end; i++) {
                        const rangeEntry = sortedEntries[i];
                        if (!rangeEntry) continue;

                        const entryId = rangeEntry.eid;
                        const isSelected =
                            this.selectedEntries.includes(entryId);

                        if (isSelecting && !isSelected) {
                            // Add to selection if we're selecting and it's not already selected
                            this.selectedEntries.push(entryId);
                        } else if (!isSelecting && isSelected) {
                            // Remove from selection if we're deselecting and it's currently selected
                            const index = this.selectedEntries.indexOf(entryId);
                            if (index > -1) {
                                this.selectedEntries.splice(index, 1);
                            }
                        }
                    }
                } else {
                    // Normal toggle behavior for single click
                    if (this.selectedEntries.includes(entry.eid)) {
                        const index = this.selectedEntries.indexOf(entry.eid);
                        if (index > -1) {
                            this.selectedEntries.splice(index, 1);
                        }
                    } else {
                        this.selectedEntries.push(entry.eid);
                    }

                    // Update lastChecked only on normal selection (not during shift operations)
                    this.lastChecked = entry;
                }
            }

            goToNewVideoList(pid) {
                this.Cpir2Service.redirectToVideoList(pid).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            goToConfiguration() {
                this.Cpir2Service.redirectToProceedingConfiguration(
                    this.pid,
                ).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            goToCif() {
                this.Cpir2Service.redirectToCif(this.pid).catch((err) => {
                    console.error(err);
                    throw err;
                });
            }

            goToAk() {
                this.Cpir2Service.redirectToAuthorDashboard(this.pid).catch(
                    (err) => {
                        console.error(err);
                        throw err;
                    },
                );
            }

            openPdfs() {
                const selectedEntries = this.entries.filter((e) =>
                    this.selectedEntries.includes(e.eid),
                );

                if (selectedEntries.length === 0) {
                    this.NotificationService.send(
                        "warning",
                        "Please select at least one entry to open.",
                    );
                    return;
                }

                const entriesWithFiles = selectedEntries.filter((entry) => {
                    const article = this.articlesByEntry[entry.eid];
                    const stamp = this.stampsByEntry[entry.eid];
                    return (stamp && stamp.vid) || (article && article.vid);
                });

                if (entriesWithFiles.length === 0) {
                    this.NotificationService.send(
                        "warning",
                        "No PDFs found for the selected entries.",
                    );
                    return;
                }

                // Sort entries first
                const sortedEntries = this.$filter("entryOrderByFilter")(
                    selectedEntries,
                    this.articlesByEntry,
                    this.versionNumbersByEntry,
                    this.stampsByEntry,
                    this.supplementalFilesByEntry,
                    this.pitstopsByEntry,
                    this.sortType,
                    this.sortReverse,
                );

                // Store sorted entries and sort order for navigation back to TOC
                this.TocNavigationService.setEntries(sortedEntries, this.pid);
                this.TocNavigationService.setSortOrder(
                    this.sortType,
                    this.sortReverse,
                );

                // Create array of PDF metadata objects
                const pdfMetadata = sortedEntries
                    .map((entry) => {
                        const article = this.articlesByEntry[entry.eid];
                        const stamp = this.stampsByEntry[entry.eid];
                        const vid =
                            (stamp && stamp.vid) || (article && article.vid);

                        if (vid) {
                            return {
                                vid,
                                title: entry.title || "",
                                number: entry.sequence
                                    ? `#${entry.sequence}`
                                    : "",
                            };
                        }
                        return null;
                    })
                    .filter((metadata) => metadata !== null);

                // Navigate to multi-pdf viewer with metadata
                this.$state.go(
                    "dashboard-editor.table-of-contents-multi-pdf-viewer",
                    {
                        pid: this.pid,
                        pdfMetadata,
                        previousState: "dashboard-editor.table-of-contents",
                    },
                );
            }

            // handle column customization
            customizeColumns() {
                this.$uibModal
                    .open({
                        component: "cpirTocColumnCustomizationModal",
                        size: "lg",
                        resolve: {
                            pid: () => this.pid, // Pass the proceeding ID
                        },
                    })
                    .result.then((settings) => {
                        this.columnSettings = settings;
                        this.columnVisibility = settings.visibility;
                    })
                    .catch(() => {});
            }

            // Get column order
            getColumnOrder() {
                return (this.columnSettings && this.columnSettings.order) || [];
            }

            /**
             * Gets the ID of the currently fixed column
             * @returns {string} The column ID or empty string if none fixed
             */
            getFixedColumnId() {
                return (
                    (this.columnSettings &&
                        this.columnSettings.fixedColumnId) ||
                    ""
                );
            }

            /**
             * Get the style object for the fixed column
             * @returns {Object} CSS style object
             */
            getFixedColumnStyle() {
                return {
                    // Adjust column width based on content type
                    minWidth: this.getFixedColumnMinWidth(),
                };
            }

            getFixedColumnMinWidth() {
                const fixedColumnId = this.getFixedColumnId();

                // Define minimum widths for different column types
                const columnWidths = {
                    title: "300px", // Title needs more space
                    authors: "50px",
                    doi: "200px", // DOIs can be long
                    type: "100px",
                    pages: "80px",
                    pageNumber: "80px",
                    version: "80px",
                    file: "100px",
                    stamp: "100px",
                    supplement: "120px",
                    pitstop: "80px",
                    productionStatus: "120px",
                    promo: "80px",
                    paperId: "100px",
                    similarity: "100px",
                    copyrightType: "120px",
                };

                return columnWidths[fixedColumnId] || "100px";
            }

            // Helper method to check column visibility
            isColumnVisible(columnId) {
                // If columnVisibility doesn't exist yet, all columns are visible
                if (!this.columnVisibility) {
                    return true;
                }

                // If the column has no visibility setting yet, show it by default
                if (this.columnVisibility[columnId] === undefined) {
                    return true;
                }

                // Otherwise, respect the explicit setting
                return this.columnVisibility[columnId] === true;
            }

            // Get article file size in human-readable format
            getArticleFileSize(entry) {
                if (this.articlesByEntry && this.articlesByEntry[entry.eid]) {
                    return this.$filter("byteFmt")(
                        this.articlesByEntry[entry.eid].size,
                        0,
                    );
                }
                return "";
            }

            // Get stamp file size in human-readable format
            getStampFileSize(entry) {
                if (this.stampsByEntry && this.stampsByEntry[entry.eid]) {
                    return this.$filter("byteFmt")(
                        this.stampsByEntry[entry.eid].size,
                        0,
                    );
                }
                return "";
            }

            // Get total size of supplemental files in human-readable format
            getSupplementalFilesSize(entry) {
                if (
                    !this.supplementalFilesByEntry ||
                    !this.supplementalFilesByEntry[entry.eid]
                ) {
                    return "";
                }

                const supplementalFiles =
                    this.supplementalFilesByEntry[entry.eid];
                const totalSize = supplementalFiles.reduce(
                    (sum, file) => sum + (file.size || 0),
                    0,
                );
                return this.$filter("byteFmt")(totalSize, 0);
            }

            getColumnName(columnId) {
                const columnNames = {
                    file: "File Size",
                    stamp: "Stamp Size",
                    supplement: "Supplement Size",
                    sequence: "Order",
                    pageNumber: "Page #",
                    type: "Type",
                    title: "Title",
                    pages: "Pages",
                    version: "Version",
                    pitstop: "Pitstop",
                    productionStatus: "Status",
                    promo: "Promo",
                    paperId: "Paper ID",
                    similarity: "Similarity",
                    copyrightType: "Copyright",
                    authors: "Authors",
                    doi: "DOI",
                };
                return columnNames[columnId] || columnId;
            }

            // Add method for toggling between modes with state persistence
            toggleViewMode() {
                const newState = this.isFastMode
                    ? "dashboard-editor.table-of-contents"
                    : "dashboard-editor.table-of-contents-fast";

                const newIsFastMode = !this.isFastMode;

                // Save both session and persistent view mode preferences
                this.TOCViewModeService.saveSessionViewMode(
                    this.pid,
                    newIsFastMode,
                );

                // Also update the persistent setting if the user manually toggled
                this.TOCViewModeService.saveViewMode(this.pid, newIsFastMode);

                // Navigate to the new state
                this.$state.go(newState, { pid: this.pid });
            }

            // FAST MODE: New methods for fast mode view start here

            /**
             * FAST MODE: Initialize scroll event handlers for fast mode view
             */
            initFastModeScrollHandling() {
                if (!this.isFastMode) return;

                // Get the md-virtual-repeat-scroller element
                this.fastModeScroller = document.querySelector(
                    ".md-virtual-repeat-scroller",
                );
                this.fastModeFixedHeader =
                    document.querySelector(".fixed-header-row");
                this.fastModeHeaderFixedColumns =
                    this.fastModeFixedHeader.querySelectorAll(".fixed-column");

                if (this.fastModeScroller) {
                    console.log("FAST MODE: Initializing scroll handling");

                    // Remove any existing handlers to avoid duplicates
                    if (this._fastModeScrollHandler) {
                        this.fastModeScroller.removeEventListener(
                            "scroll",
                            this._fastModeScrollHandler,
                        );
                    }

                    // Create a bound handler and store for cleanup
                    this._fastModeScrollHandler =
                        this.handleFastModeScroll.bind(this);

                    // Add scroll event listener
                    this.fastModeScroller.addEventListener(
                        "scroll",
                        this._fastModeScrollHandler,
                    );

                    // Initial sync check
                    this.handleFastModeScroll({
                        target: this.fastModeScroller,
                    });

                    // Add cleanup on destroy
                    this.$scope.$on("$destroy", () => {
                        if (this._fastModeScrollHandler) {
                            this.fastModeScroller.removeEventListener(
                                "scroll",
                                this._fastModeScrollHandler,
                            );
                            this._fastModeScrollHandler = null;
                        }
                    });

                    console.log("FAST MODE: Scroll handling initialized");
                } else {
                    console.log(
                        "FAST MODE: Failed to initialize scroll handling - containers not found",
                    );

                    // Try again after a delay
                    this.$timeout(
                        () => this.initFastModeScrollHandling(),
                        2000,
                    );
                }
            }

            /**
             * FAST MODE: Handle scroll events in fast mode view
             * This handler sets the scroll state and calls the syncFastModeHeader method
             * @param {Event} event - The scroll event
             */
            handleFastModeScroll(event) {
                if (!this.isFastMode) return;

                const container = event.target;
                if (!container) return;

                // Get scroll metrics
                const scrollLeft = container.scrollLeft;
                const maxScroll = container.scrollWidth - container.clientWidth;

                // Update scroll state
                this.fastModeContentScrollLeft = scrollLeft;
                this.isScrolledRight = scrollLeft > 0;
                this.hasMoreToScroll = scrollLeft < maxScroll;

                this.fastModeHeaderPosition = scrollLeft || 0;

                // // Sync header position with enhanced fixed column handling
                this.syncFastModeHeader(scrollLeft);
            }

            /**
             * FAST MODE: Synchronize header position with content scroll with special handling for fixed columns
             * Directly manipulates the DOM elements to avoid Angular digest
             */
            syncFastModeHeader() {
                if (!this.isFastMode) return;

                try {
                    if (this.fastModeFixedHeader) {
                        // Apply the transform to move header with scroll
                        this.fastModeFixedHeader.style.transform = `translateX(-${this.fastModeHeaderPosition}px)`;

                        // Ensure correct transform origin
                        this.fastModeFixedHeader.style.transformOrigin =
                            "left top";

                        // Use hardware acceleration for smoother performance
                        this.fastModeFixedHeader.style.willChange = "transform";

                        // CRITICAL: Handle fixed columns specially to keep them in place
                        // This is the key improvement - fixed columns need to move in the
                        // opposite direction to stay visually in the same place
                        this.fastModeHeaderFixedColumns.forEach((column) => {
                            // Counteract the header transform for fixed columns
                            column.style.transform = `translateX(${this.fastModeHeaderPosition}px)`;
                            column.style.willChange = "transform";
                        });
                    }
                } catch (error) {
                    console.error(
                        "Error synchronizing fast mode header scroll:",
                        error,
                    );
                }
            }

            /**
             * Updates the filtered entries collection with sorted and filtered entries for fast mode
             * This applies the same filters used in the standard view to the fast mode virtual scrolling
             */
            updateSortedEntries() {
                if (!this.isFastMode || !this.entries) return;

                // Apply the same filters used in the standard mode template
                const filteredEntries = this.$filter("entryOrderByFilter")(
                    this.entries,
                    this.articlesByEntry,
                    this.versionNumbersByEntry,
                    this.stampsByEntry,
                    this.supplementalFilesByEntry,
                    this.pitstopsByEntry,
                    this.sortType,
                    this.sortReverse,
                );

                // Apply keyword filter if present
                const finalEntries =
                    this.search && this.search.keyword
                        ? this.$filter("filter")(
                              filteredEntries,
                              this.search.keyword,
                          )
                        : filteredEntries;

                // Update the filteredEntries property for virtual repeat
                this.$timeout(() => {
                    // Use a new array to trigger change detection in md-virtual-repeat
                    this.filteredEntries = [...finalEntries];
                });
            }

            /**
             * FAST MODE: Initialize keyboard handlers for navigation and search override
             * This intercepts navigation keys and Ctrl+F/Cmd+F to provide custom behavior
             */
            initFastModeKeyboardHandlers() {
                if (!this.isFastMode) return;

                console.log("FAST MODE: Initializing keyboard handlers");

                // Remove any existing handlers to avoid duplicates
                if (this._fastModeKeyboardHandler) {
                    window.removeEventListener(
                        "keydown",
                        this._fastModeKeyboardHandler,
                    );
                }

                // Create a bound handler and store for cleanup
                this._fastModeKeyboardHandler = (event) => {
                    // Get reference to the virtual scroll container
                    const virtualContainer = document.querySelector(
                        ".md-virtual-repeat-scroller",
                    );

                    // Check for Ctrl+F (Windows/Linux) or Cmd+F (Mac)
                    if ((event.ctrlKey || event.metaKey) && event.key === "f") {
                        // Prevent default browser find action
                        event.preventDefault();

                        // Focus the search input
                        const searchInput = document.querySelector(
                            ".search-container .search",
                        );
                        if (searchInput) {
                            console.log("FAST MODE: Focusing search input");
                            searchInput.focus();

                            // Optionally select all text in the input for easy replacement
                            searchInput.select();
                        }
                        return;
                    }

                    // Skip navigation handling if we're in an input field
                    if (
                        document.activeElement.tagName === "INPUT" ||
                        document.activeElement.tagName === "TEXTAREA" ||
                        document.activeElement.isContentEditable
                    ) {
                        return;
                    }

                    // Handle navigation keys only if the virtual container exists
                    if (virtualContainer) {
                        const rowHeight = 40; // Same as md-item-size in the template
                        const visibleRows = Math.floor(
                            virtualContainer.clientHeight / rowHeight,
                        );
                        const currentScrollTop = virtualContainer.scrollTop;
                        let newScrollTop = currentScrollTop;

                        switch (event.key) {
                            case "ArrowUp":
                                event.preventDefault();
                                newScrollTop = Math.max(
                                    0,
                                    currentScrollTop - rowHeight,
                                );
                                break;

                            case "ArrowDown":
                                event.preventDefault();
                                newScrollTop = currentScrollTop + rowHeight;
                                break;

                            case "PageUp":
                                event.preventDefault();
                                newScrollTop = Math.max(
                                    0,
                                    currentScrollTop - visibleRows * rowHeight,
                                );
                                break;

                            case "PageDown":
                                event.preventDefault();
                                newScrollTop =
                                    currentScrollTop + visibleRows * rowHeight;
                                break;

                            case "Home":
                                event.preventDefault();
                                newScrollTop = 0;
                                break;

                            case "End":
                                event.preventDefault();
                                // We can't just set a very large number as that would cause jank
                                // Instead, we calculate based on total items if possible
                                if (
                                    this.filteredEntries &&
                                    this.filteredEntries.length
                                ) {
                                    newScrollTop =
                                        this.filteredEntries.length *
                                            rowHeight -
                                        virtualContainer.clientHeight;
                                } else {
                                    // Fallback to a large scroll if we can't calculate exactly
                                    newScrollTop =
                                        virtualContainer.scrollHeight -
                                        virtualContainer.clientHeight;
                                }
                                break;
                        }

                        // Apply new scroll position if it changed
                        if (newScrollTop !== currentScrollTop) {
                            virtualContainer.scrollTop = newScrollTop;

                            // Optional: Find the row at this scroll position and highlight/select it
                            this.$timeout(() => {
                                const rowIndex = Math.floor(
                                    newScrollTop / rowHeight,
                                );
                                if (
                                    this.filteredEntries &&
                                    this.filteredEntries[rowIndex]
                                ) {
                                    console.log(
                                        `FAST MODE: Navigated to row ${rowIndex}`,
                                    );
                                    // Visually indicate the current row (optional)
                                    // You could add a 'focused-row' class or similar
                                }
                            });
                        }
                    }
                };

                // Add keydown event listener to window
                window.addEventListener(
                    "keydown",
                    this._fastModeKeyboardHandler,
                );

                console.log("FAST MODE: Keyboard handlers initialized");
            }
        },
    );

    /*
     Helper method that adds padding of 0s
     */
    function pad(str, max) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    }

    /*
     Helper method that checks whether n is even
     */
    function isEven(n) {
        n = Number(n);
        return n === 0 || !!(n && !(n % 2));
    }

    /*
     Helper method that checks whether n is odd
     */
    function isOdd(n) {
        return isEven(Number(n) + 1);
    }
})();
