(function() {
    "use strict";

    angular.module("cpir").factory("publicationAgreementService", [
        "apiService",
        function(api) {
            let paService = {};
            let firstItem = api.firstItem;

            paService.get = () =>
                api
                    .get("publication-agreement/default")
                    .then(result => firstItem(result))
                    .then(res => {
                        console.log(
                            "Using default Publication Agreement sections"
                        );
                        return res.sections;
                    });

            return paService;
        }
    ]);
})();
