(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparingFormattingPaperPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/formatting-paper-panel/formatting-paper-panel.component.html",
        controller: class {
            $onInit() {}

            $onChanges(changes) {
                let proceeding =
                    changes.proceeding && changes.proceeding.currentValue;

                if (proceeding) {
                    try {
                        let trim = proceeding.additionalInformation.trim;

                        let possibleTrims = [
                            { name: "8.5 x 11 1-column", value: "8.5x11-1" },
                            { name: "8.5 x 11 2-column", value: "8.5x11-2" },
                            { name: "A4 1-column", value: "A4-1" },
                            { name: "A4 2-column", value: "A4-2" },
                            { name: "6 x 9", value: "6x9" }
                        ];

                        let selected = possibleTrims.find(
                            t => t.value === trim
                        );
                        this.trimSize = selected.name;
                    } catch (e) {
                        console.log("Trim size could not be found.");
                    }
                }
            }
        }
    });
})();
