(function() {
    "use strict";

    angular.module("cpir").factory("unitPricesService", [
        "apiService",
        function(api) {
            let unitPricesService = {};
            let firstItem = api.firstItem;

            unitPricesService.get = () =>
                api
                    .get("unit-prices")
                    .then(result => firstItem(result))
                    .then(res => {
                        console.log("Using default Unit Prices");
                        return res.prices;
                    });

            return unitPricesService;
        }
    ]);
})();
