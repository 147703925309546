angular.module("cpir").factory("IndexedDBService", function () {
    if (!("indexedDB" in window)) {
        console.warn("IndexedDB is not supported");
        return {
            getEntries: function () {
                return Promise.reject("IndexedDB is not supported");
            },
            updateEntries: function () {
                return Promise.reject("IndexedDB is not supported");
            },
            isSupported: function () {
                return false;
            },
        };
    }

    const db = new Dexie("cpir");
    db.version(1).stores({
        tocEntries: "eid, [pid+status]",
        proceedings: "pid, status",
        files: "vid, [pid+type+status+active]",
    });

    return {
        getEntries: function (pid) {
            return db.tocEntries
                .where("[pid+status]")
                .equals([pid, 1])
                .toArray();
        },
        getEntry: function (eid) {
            return db.tocEntries.get(eid);
        },
        updateEntries: function (entries) {
            return db.tocEntries.bulkPut(entries);
        },
        getProceedings: function () {
            return db.proceedings.where("status").equals(1).toArray();
        },
        getProceeding: function (pid) {
            return db.proceedings.get(pid);
        },
        updateProceeding: function (proceeding) {
            return db.proceedings.put(proceeding);
        },
        updateProceedings: function (proceedings) {
            return db.proceedings.bulkPut(proceedings);
        },
        getFilesForProceeding: function (pid, type, active) {
            const index = active
                ? "[pid+type+status+active]"
                : "[pid+type+status]";
            const query = active ? [pid, type, 1, 1] : [pid, type, 1];
            return db.files.where(index).equals(query).toArray();
        },
        updateFiles: function (files) {
            return db.files.bulkPut(files);
        },
        isSupported: function () {
            return "indexedDB" in window;
        },
    };
});
