(function() {
    "use strict";

    angular.module("cpir").component("cpirCifQuoteProductAttribute", {
        bindings: {
            field: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/cif/quotes/components/product-attribute/product-attribute.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                // Set the default value if it exists
                if (
                    this.field.default !== undefined &&
                    this.field.default !== null
                ) {
                    console.log(this.field.default);
                    this.attributeValue = this.field.default;
                    this.attributeValueChanged();
                }

                // If an existing value was passed in, such
                // as in edit mode (as opposed to add mode),
                // then default to that value
                if (this.field.value) {
                    this.attributeValue = this.field.value;
                    this.attributeValueChanged();
                }

                // Boolean options are for boolean selects
                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            isTextField() {
                return this.field.type === "text";
            }

            isNumberField() {
                return this.field.type === "number";
            }

            isSelectField() {
                return this.field.type === "select";
            }

            isSelectBooleanField() {
                return this.field.type === "boolean";
            }

            isReadOnly() {
                return this.field.readonly === true;
            }

            attributeValueChanged() {
                this.onUpdate({
                    name: this.field.name,
                    order: this.field.order,
                    value: this.attributeValue
                });
            }
        }
    });
})();
