(function() {
    "use strict";

    angular.module("cpir").controller(
        "InquiryConfigurationController",
        class {
            constructor() {}

            $onInit() {}

            onCategorySelect(category) {
                switch (category) {
                    case "settings":
                        this.showConfiguration("settings.login");
                        break;
                    case "defaults":
                        this.showConfiguration("defaults.globals");
                        break;
                }
            }

            showConfiguration(status) {
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }
        }
    );
})();
