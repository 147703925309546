(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardOrganizerConferenceInformation", {
            bindings: {
                sectionTitle: "<",
                proceeding: "<",
                content: "<",
                onUpdate: "&"
            },
            templateUrl:
                "application/cpir/dashboard/organizer/quote/components/" +
                "conference-information/conference-information.component.html",
            controller: function(
                $scope,
                InternationalDataService,
                PureDateService
            ) {
                let $ctrl = this;

                this.PureDateService = PureDateService;

                $ctrl.countryOptions = InternationalDataService.getCountries();

                $ctrl.provinceOptions = InternationalDataService.getProvinces();

                $ctrl.modelChanged = () => {
                    $ctrl.onUpdate({
                        updatedConferenceInformation:
                            $ctrl.content.conferenceInformation
                    });
                };
            }
        });
})();
