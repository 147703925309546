(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardOrganizerProductionSchedule", {
            bindings: {
                sectionTitle: "<",
                content: "<"
            },
            templateUrl:
                "application/cpir/dashboard/organizer/quote/components/production-schedule/production-schedule.component.html",
            controller: [
                "moment",
                "PureDateService",
                function(moment, PureDateService) {
                    let $ctrl = this;
                    this.PureDateService = PureDateService;

                    $ctrl.$onChanges = ({ content }) => {
                        const { productionSchedule } =
                            content && content.currentValue;
                        if (productionSchedule) {
                            $ctrl.productionSchedule = angular.copy(
                                $ctrl.content.productionSchedule
                            );
                        }
                    };
                }
            ]
        });
})();
