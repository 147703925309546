(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfSettingsPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/ak-conf-settings-panel/ak-conf-settings-panel.component.html",
        controller: class {
            constructor(
                authService,
                ConfigurationService,
                NotificationService
            ) {
                this.authService = authService;
                this.ConfigurationService = ConfigurationService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.showConfiguration("ak.login");

                this.brand = this.authService.getBrand().name;

                this.getUpdatedConfiguration();
            }

            onCategorySelect(category) {
                switch (category) {
                    case "settings":
                        this.showConfiguration("settings.login");
                        break;
                    case "defaults":
                        this.showConfiguration("defaults.globals");
                        break;
                }
            }

            showConfiguration(status) {
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }

            getUpdatedConfiguration() {
                return this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        this.configuration = result.configuration.cps;
                    else if (this.brand === "pubhub")
                        this.configuration = result.configuration.pubhub;
                    return this.configuration;
                });
            }

            saveConfiguration({ configuration, hideNotification = false }) {
                return this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        result.configuration.cps = configuration;
                    else if (this.brand === "pubhub")
                        result.configuration.pubhub = configuration;

                    this.ConfigurationService.update(result)
                        .then(() => this.getUpdatedConfiguration())
                        .then(() => {
                            if (!hideNotification) {
                                this.NotificationService.send(
                                    "success",
                                    "The configuration settings were successfully saved."
                                );
                            }
                        });
                });
            }
        }
    });
})();
