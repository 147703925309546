(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("cpirAkPreparationModalLink", [
            "$uibModal",
            cpirAkPreparationModalLink
        ]);

    function cpirAkPreparationModalLink($uibModal) {
        return {
            restrict: "E",
            replace: false,
            templateUrl:
                "application/cpir/authorkit/_shared/components/preparation-modal-link/preparation-modal-link.directive.html",
            scope: {},
            link: scope => {
                scope.linkClicked = () => {
                    $uibModal.open({
                        size: "lg",
                        component: "cpirAkPreparationInformationModal"
                    });
                };
            }
        };
    }
})();
