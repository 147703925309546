(function () {
    "use strict";

    angular.module("cpir").component("downloadsInfoPanel", {
        bindings: {
            pid: "<",
            includeExtras: "&",
            exportType: "<",
            exportStarted: "<",
            exportCompleted: "<",
            keepRawFileName: "&",
            manifestColumns: "<",
            dismiss: "&",
            close: "&",
            updateSelection: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents-downloads/components/downloads-info-panel/downloads-info-panel.component.html",
        controller: class {
            constructor(
                WebSocketService,
                $scope,
                $window,
                FileService,
                NotificationService
            ) {
                this.safeApply = WebSocketService.safeApply;
                this.$scope = $scope;
                this.$window = $window;
                this.FileService = FileService;
                this.NotificationService = NotificationService;
                this.fields = [];
                this.manifestConstants = {
                    "CLASS": {key: "class", name: "Class"},
                    "TYPE": {key: "type", name: "Type"},
                    "SEQUENCE": {key: "sequence", name: "Sequence"},
                    "STATUS": {key: "productionStatus", name: "Status"},
                    "COPYRIGHT_TYPE": {key: "copyrightType", name: "Copyright Type"},
                    "COPYRIGHT_LINE": {key: "copyrightLine", name: "Copyright Line"},
                    "ARTICLE_ID": {key: "articleId", name: "Article Id (ecopyright id)"},
                    "PDF_EXPRESS": {key: "pdfExpress", name: "PDF Express"},
                    "TITLE": {key: "title", name: "Title"},
                    "SUBMITTER_ID": {key: "submitterEmail", name: "Submitter Email"},
                    "SUBMITTER_NAME": {key: "submitterName", name: "Submitter Name"},
                    "ABSTRACT": {key: "abstract", name: "Abstract"},
                    "KEYWORDS": {key: "keywords", name: "Keywords"},
                    "DOI": {key: "doi", name: "DOI"},
                    "CHAIR": {key: "chair", name: "Chair"},
                    "HAS_PDF": {key: "hasPdf", name: "Has Pdf?"},
                    "PAGE_NUMBER": {key: "pageNumber", name: "Page Number"},
                    "PAGE_PADDING": {key: "pagePadding", name: "Page Padding"},
                    "NOSHOW": {key: "noShow", name: "Is No-Show"},
                    "SUBMISSION_CHANGE_REQUEST": {key: "submissionChangeRequest", name: "Author Change Request (Read Only)"},
                    "PAPER_ID": {key: "paperId", name: "Paper Id"},
                    "ARTICLE_PDF_METADATA": {key: "articlePdfMetadata", name: "Article Pdf Metadata"},
                    "SUPPLEMENT_METADATA":  {key: "supplementMetadata", name: "Supplement Metadata"},
                    "SOURCE_FILE_METADATA": {name: "Source File Metadata", key: "sourceFileMetadata"},
                     "IS_STAMPED": {name: "Is Stamped?", key: "stamp"},
                    "IS_PITSTOP_PASSED": {name: "Is Pitstop Passed?", key: "pitstop"},
                    "IS_CCBY": {name: "Is CCBY ?", key: "ccby"},
                    "PROMO_DATES": {name: "Promo Dates", key: "promo"},
                    "FNO" : {key: "fno", name: "FNO"},
                    "EID": {key: "eid", name: "CPS Id"},
                    "GIVEN_NAME" :  {key: "givenName", name: "Author Given Name"},
                    "SURNAME":  {key: "surName", name: "Author Surname"},
                    "AUTHOR_AFF":  {key: "authorAff", name: "Author Affiliation"},
                    "AUTHOR_EMAIL":  {key: "authorEmail", name: "Author Email"},
                    "ORCID":  {key: "orcid", name: "ORCID"},
                    "SOURCE_FILE_NAME" : {key: "sourceFileName", name: "Source File Name"},
                    "SOURCE_FILE_SIZE" : {key: "sourceFileSize", name: "Source File Size"},
                    "SOURCE_FILE_TYPE" : {key: "sourceFileType", name: "Source File Type"},
                    "ARTICLE_PDF_FILE_NAME" : {key: "articlePdfFileName", name: "Article Pdf File Name"},
                    "ARTICLE_PDF_EXPORT_FILENAME" : {key: "articlePdfExportFileName", name: "Article Pdf Export File Name"},
                    "ARTICLE_PDF_FILE_SIZE" : {key: "articlePdfFileSize", name: "Article Pdf File Size"},
                    "ARTICLE_PDF_FILE_TYPE" : {key: "articlePdfFileType", name: "Article Pdf File Type"},
                    "SUPPLEMENT_FILE_NAME" : {key: "supplementFileName", name: "Supplement File Name"},
                    "SUPPLEMENT_EXPORT_FILE_NAME" : {key: "supplementExportFileName", name: "Supplement Export File Name"},
                    "SUPPLEMENT_FILE_SIZE" : {key: "supplementFileSize", name: "Supplement File Size"},
                    "SUPPLEMENT_FILE_TYPE" : {key: "supplementFileType", name: "Supplement File Type"},
                    "PROMO_START_DATE" : {key: "promoStartDate", name: "Promo Start Date"},
                    "PROMO_END_DATE" : {key: "promoeNDDate", name: "Promo End Date"},
                }
            }

            $onInit() {
                this.supplementFolderStructure = null;
                this.supplementNaming = null; //"original";
                this.includeStampFiles = false;
                this.includeOriginalFiles = true;
                this.generateManifest = true;
                this.articleFileNaming = "paperTitle";


                this.supplementFolderStructureOptions = [
                    { value: "subfolders", name: "Include extras - with entry subfolders" },
                    { value: "noSubFolders", name: "Include extras - without entry subfolders" },
                    // { value: "noSupplments", name: "No supplements", selected: true },
                ];
                this.supplementNamingOptions = [
                    { value: "original", name: "Original supplement filename" },
                    { value: "fno", name: "FNO supplement filename" },
                    { value: "paperId", name: "Paper Id supplement filename" },
                    { value: "paperTitle", name: "Paper Title supplement filename" , selected: true },
                ];
                this.articleFileNamingOptions = [
                    { value: "paperTitle", name: "Paper Title filename" , selected: true},
                    { value: "original", name: "Original filename" },
                    { value: "fno", name: "FNO filename" },
                    { value: "paperId", name: "Paper Id filename" },
                ]
                this.keepRawFileName = true;
                this.selectAll = false;
                this.fields = [
                    {
                        items: [
                            {key: this.manifestConstants.CLASS.key, name: this.manifestConstants.CLASS.name, selected: true},
                            {key: this.manifestConstants.TYPE.key, name: this.manifestConstants.TYPE.name, selected: true},
                            {key: this.manifestConstants.SEQUENCE.key, name: this.manifestConstants.SEQUENCE.name, selected: true},
                            {key: this.manifestConstants.STATUS.key, name: this.manifestConstants.STATUS.name, selected: true},
                            {key: this.manifestConstants.COPYRIGHT_TYPE.key, name: this.manifestConstants.COPYRIGHT_TYPE.name, selected: true},
                            {key: this.manifestConstants.COPYRIGHT_LINE.key, name: this.manifestConstants.COPYRIGHT_LINE.name, selected: true},
                        ]
                    },
                    {
                        items: [
                            {key: this.manifestConstants.ARTICLE_ID.key, name: this.manifestConstants.ARTICLE_ID.name, selected: true},
                            {key: this.manifestConstants.PDF_EXPRESS.key, name: this.manifestConstants.PDF_EXPRESS.name, selected: true},
                            {key: this.manifestConstants.TITLE.key, name: this.manifestConstants.TITLE.name, selected: true},
                            {key: this.manifestConstants.SUBMITTER_ID.key, name: this.manifestConstants.SUBMITTER_ID.name, selected: true},
                            {key: this.manifestConstants.SUBMITTER_NAME.key, name: this.manifestConstants.SUBMITTER_NAME.name, selected: true},
                            {key: this.manifestConstants.ABSTRACT.key, name: this.manifestConstants.ABSTRACT.name, selected: true},
                            ]
                    },
                    {
                        items: [
                            {key: this.manifestConstants.KEYWORDS.key, name: this.manifestConstants.KEYWORDS.name, selected: true},
                            {key: this.manifestConstants.DOI.key, name: this.manifestConstants.DOI.name, selected: true},
                            {key: this.manifestConstants.CHAIR.key, name: this.manifestConstants.CHAIR.name, selected: true},
                            {key: this.manifestConstants.PAGE_NUMBER.key, name: this.manifestConstants.PAGE_NUMBER.name, selected: true},
                            {key: this.manifestConstants.PAGE_PADDING.key, name: this.manifestConstants.PAGE_PADDING.name, selected: true},
                            {key: this.manifestConstants.NOSHOW.key, name: this.manifestConstants.NOSHOW.name, selected: true},
                        ]
                    },
                    {
                        items: [
                            {key: this.manifestConstants.SUBMISSION_CHANGE_REQUEST.key, name: this.manifestConstants.SUBMISSION_CHANGE_REQUEST.name, selected: true},
                            {key: this.manifestConstants.PAPER_ID.key, name: this.manifestConstants.PAPER_ID.name},
                            {key: this.manifestConstants.ARTICLE_PDF_METADATA.key, name: this.manifestConstants.ARTICLE_PDF_METADATA.name, selected: true},
                            {key: this.manifestConstants.SUPPLEMENT_METADATA.key, name: this.manifestConstants.SUPPLEMENT_METADATA.name},
                            {key: this.manifestConstants.SOURCE_FILE_METADATA.key, name: this.manifestConstants.SOURCE_FILE_METADATA.name},
                            {key: this.manifestConstants.IS_STAMPED.key, name: this.manifestConstants.IS_STAMPED.name},
                        ]
                    },
                    {
                        items: [
                            {key: this.manifestConstants.IS_PITSTOP_PASSED.key, name: this.manifestConstants.IS_PITSTOP_PASSED.name},
                            {key: this.manifestConstants.IS_CCBY.key, name: this.manifestConstants.IS_CCBY.name},
                            {key: this.manifestConstants.PROMO_DATES.key, name: this.manifestConstants.PROMO_DATES.name},
                            {key: this.manifestConstants.FNO.key, name: this.manifestConstants.FNO.name},
                            {key: this.manifestConstants.EID.key, name: this.manifestConstants.EID.name, selected: true},
                            {key: this.manifestConstants.GIVEN_NAME.key, name: this.manifestConstants.GIVEN_NAME.name, selected: true},
                        ]
                    },
                    {
                        items: [
                            {key: this.manifestConstants.SURNAME.key, name: this.manifestConstants.SURNAME.name, selected: true},
                            {key: this.manifestConstants.AUTHOR_AFF.key, name: this.manifestConstants.AUTHOR_AFF.name, selected: true},
                            {key: this.manifestConstants.AUTHOR_EMAIL.key, name: this.manifestConstants.AUTHOR_EMAIL.name, selected: true},
                            {key:  this.manifestConstants.ORCID.key, name: this.manifestConstants.ORCID.name, selected: true}
                        ]
                    }
                ];
                this.onUpdate();
            }

            $onChanges(changes) {
                if (changes.pid && changes.pid.currentValue) {
                    this.pid = changes.pid.currentValue;
                }
                if (changes.includeExtras && changes.includeExtras.currentValue) {
                    this.includeExtras = changes.includeExtras.currentValue;
                }
                if (changes.exportStarted && changes.exportStarted.currentValue) {
                    this.exportStarted = changes.exportStarted.currentValue;
                    this.safeApply(this.$scope);
                }
                if (changes.exportCompleted && changes.exportCompleted.currentValue) {
                    this.exportCompleted = changes.exportCompleted.currentValue;
                    this.safeApply(this.$scope);
                }
            }

            updateSupplementFolderStructure() {
                console.log(this.supplementFolderStructure);
                if(this.supplementFolderStructure === null) {
                    this.supplementNaming = null;
                    return true;
                } else
                    return false;
            }

            checkFileOptions() {
                if(!this.includeOriginalFiles && !this.includeStampFiles) {
                    this.articleFileNaming = null;
                    this.onUpdate();
                    return true;
                } else
                    return false;
            }

            onUpdate() {
                this.manifestFields = this.fields;
                console.log(this.supplementNaming);
                console.log(this.includeOriginalFiles);
                console.log(this.manifestColumns);
                if (this.manifestColumns) {

                    console.log("inside setting");
                    this.updateSelection({
                        includeExtras: this.includeExtras,
                        keepRawFileName: this.keepRawFileName,
                        manifestColumns: this.manifestColumns,
                        supplementFolderStructure: this.supplementFolderStructure,
                        supplementNaming: this.supplementNaming,
                        includeStampFiles: this.includeStampFiles,
                        includeOriginalFiles: this.includeOriginalFiles,
                        articleFileNaming: this.articleFileNaming,
                        generateManifest: this.generateManifest
                    });
                }
            }

            fieldClicked(field) {
                console.log("inside field clicked");
                console.log(field);
                const updatedData = _.map(this.fields, innerArray =>
                    _.map(innerArray, item => {
                            if (Object.prototype.toString.call(item) === '[object Array]') {
                                return _.map(item, (value, key) => {
                                        if (value.key === field.key) {
                                            if(!field.selected) {
                                                value.selected = true;
                                            } else {
                                                value.selected = false;
                                            }
                                         }
                                        return item;
                                    }
                                )
                            } else
                                return item;
                        }
                    )
                );
                this.updateNanifestColumns(updatedData);
            }

            updateNanifestColumns(fields) {
                this.manifestColumns =[];
                const updatedData = _.map(fields, innerArray =>
                    _.map(innerArray, item => {
                            if (Object.prototype.toString.call(item) === '[object Array]') {
                                return _.map(item, groupLevelItem => {
                                    return _.map(groupLevelItem, fieldElement => {
                                        if (fieldElement.selected) {
                                            if (fieldElement.key === this.manifestConstants.SOURCE_FILE_METADATA.key) {
                                                this.updateSourceMetadata(fieldElement);
                                            } else if (fieldElement.key === this.manifestConstants.ARTICLE_PDF_METADATA.key) {
                                                this.updateArticlePdfMetadata(fieldElement);
                                            } else if(fieldElement.key === this.manifestConstants.PROMO_DATES.key){
                                                this.updatePromoFields(fieldElement);
                                            }  else {
                                                this.manifestColumns[fieldElement.key] = fieldElement.name;
                                            }
                                        }
                                    });
                                });
                            } else
                                return item;
                        }
                    )
                );

                console.log("before ....................");
                console.log(this.manifestColumns);

                if (this.manifestColumns) {
                    console.log("inside update mani columns");
                    this.manifestColumns = _.omit(this.manifestColumns, ['authors', 'promo', "eid"]);
                    this.manifestColumns[this.manifestConstants.EID.key] = this.manifestConstants.EID.name;
                    this.updateSelection({
                        includeExtras: this.includeExtras,
                        keepRawFileName: this.keepRawFileName,
                        manifestColumns: this.manifestColumns,
                        //isAllDeselected: !this.selectAll,
                        supplementFolderStructure: this.supplementFolderStructure,
                        supplementNaming: this.supplementNaming,
                        includeStampFiles: this.includeStampFiles,
                        includeOriginalFiles: this.includeOriginalFiles,
                        articleFileNaming: this.articleFileNaming,
                        generateManifest: this.generateManifest

                    });
                }
            }


            selectAllFields(selectAll) {
                this.selectAll = selectAll;
                const updatedData = _.map(this.fields, innerArray =>
                    _.map(innerArray, item => {
                            if (Object.prototype.toString.call(item) === '[object Array]') {
                                return _.map(item, (value, key) => {
                                        if (selectAll) {
                                           value.selected = true;
                                        } else {
                                            if(value.key !== "eid")
                                                value.selected = false;
                                        }
                                        return item;
                                    }
                                )
                            } else
                                return item;
                        }
                    )
                );


                this.updateNanifestColumns(updatedData);

            }

            updatePromoFields(fieldElement) {
                    this.manifestColumns[this.manifestConstants.PROMO_START_DATE.key] = this.manifestConstants.PROMO_START_DATE.name;
                    this.manifestColumns[this.manifestConstants.PROMO_END_DATE.key] = this.manifestConstants.PROMO_END_DATE.name;
            }

            updateSourceMetadata(fieldElement) {
                    this.manifestColumns[this.manifestConstants.SOURCE_FILE_NAME.key] = this.manifestConstants.SOURCE_FILE_NAME.name;
                    this.manifestColumns[this.manifestConstants.SOURCE_FILE_SIZE.key] = this.manifestConstants.SOURCE_FILE_SIZE.name;
                    this.manifestColumns[this.manifestConstants.SOURCE_FILE_SIZE.key] = this.manifestConstants.SOURCE_FILE_SIZE.name
            }

            updateArticlePdfMetadata(fieldElement) {
                    this.manifestColumns[this.manifestConstants.ARTICLE_PDF_FILE_NAME.key] = this.manifestConstants.ARTICLE_PDF_FILE_NAME.name;
                    this.manifestColumns[this.manifestConstants.ARTICLE_PDF_EXPORT_FILENAME.key] = this.manifestConstants.ARTICLE_PDF_EXPORT_FILENAME.name;
                    this.manifestColumns[this.manifestConstants.ARTICLE_PDF_FILE_SIZE.key] = this.manifestConstants.ARTICLE_PDF_FILE_SIZE.name;
                    this.manifestColumns[this.manifestConstants.ARTICLE_PDF_FILE_TYPE.key] = this.manifestConstants.ARTICLE_PDF_FILE_TYPE.name;
            }
        }
    });
})();
