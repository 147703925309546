(function() {
    "use strict";

    /*
     * Description: This filter accepts a 'name' for a value
     * of an item attribute and the corresponding medium. It
     * then outputs the correct label for that name/value pair.
     */

    angular.module("cpir").filter("removeQuotePricesFilter", () => {
        return attributes => {
            if (!attributes) return attributes;
            return attributes.filter(a => {
                if (a.name) {
                    return a.name !== "totalPrice" && a.name !== "unitPrice";
                } else {
                    return a !== "totalPrice" && a !== "unitPrice";
                }
            });
        };
    });
})();
