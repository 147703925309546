(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfPaperDetailsPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/configuration-common/components/ak-conf-paper-details-panel/ak-conf-paper-details-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                DefaultSettingsService,
                NotificationService
            ) {
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];
            }

            saveConfiguration() {
                this.onSave({
                    props: {
                        configuration: this.configuration
                    }
                });
            }
        }
    });
})();
