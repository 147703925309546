(function() {
    "use strict";

    angular.module("cpir").provider(
        "DeepLinkService",
        class {
            $get($location, authService, EnvironmentService) {
                const isDevelopmentOrTest = EnvironmentService.isDevelopmentOrTest();
                const isProduction = EnvironmentService.isProduction();
                /**
                 * Private helper method that creates a deep link
                 * by parsing the current base URL and taking in a
                 * key-value array with the special URL parameters
                 * needed for the dispatcher
                 * @param params
                 * @returns {string}
                 */
                let createDeepLink = params => {
                    let brand = authService.getBrand();
                    let protocol = $location.protocol();
                    let host = $location.host();
                    let port = $location.port();

                    let urlParams = "";

                    params.forEach((param, index) => {
                        if (index > 0) urlParams += "&";
                        urlParams += param.name + "=" + param.value;
                    });

                    if (isDevelopmentOrTest) {
                        return port === 443 || port === 80
                            ? `${protocol}://${host}/${brand.name}#!/auth/login?${urlParams}`
                            : `${protocol}://${host}:${port}/${brand.name}#!/auth/login?${urlParams}`;
                    } else if (isProduction) {
                        return `${protocol}://${host}/#!/auth/login?${urlParams}`;
                    }
                };

                /**
                 * Gets a AK deep link
                 * @param pid
                 * @returns {string}
                 */
                let getAkDeepLink = pid => {
                    return createDeepLink([
                        { name: "ak", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                /**
                 * Gets a conference video deep link
                 * @param pid
                 * @returns {string}
                 */
                let getConferenceVideoDeepLink = pid => {
                    return createDeepLink([
                        { name: "conference_video", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                /**
                 * Gets a conference video deep link
                 * @param pid
                 * @returns {string}
                 */
                let getConferenceVideoListDeepLink = pid => {
                    return createDeepLink([
                        { name: "conference_video_list", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                /**
                 * Gets a AK resubmission link
                 * @param pid
                 * @param entryId
                 * @returns {string}
                 */
                let getAkResubmissionLink = (pid, entryId) => {
                    return createDeepLink([
                        { name: "ak", value: 1 },
                        { name: "pid", value: pid },
                        { name: "entryId", value: entryId }
                    ]);
                };

                /**
                 * Gets a quote deep link
                 * @param pid
                 * @returns {string}
                 */
                let getQuoteDeepLink = pid => {
                    return createDeepLink([
                        { name: "quote", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                /**
                 * Gets a PA link
                 * @param pid
                 * @returns {string}
                 */
                let getPADeepLink = pid => {
                    return createDeepLink([
                        { name: "pa", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                /**
                 * Gets a compliance deep link
                 * @param pid
                 * @returns {string}
                 */
                let getComplianceDeepLink = pid => {
                    return createDeepLink([
                        { name: "compliance", value: 1 },
                        { name: "pid", value: pid }
                    ]);
                };

                function getReviseQuoteDeepLink(pid, index = 0) {
                    const linkParams = [
                        { name: "configuration", value: 1 },
                        { name: "view_quotes", value: 1 },
                        { name: "pid", value: pid },
                        { name: "index", value: index }
                    ];
                    return createDeepLink(linkParams);
                }

                function getAkAuthorReviewDeepLink(entryId) {
                    const linkParams = [
                        { name: "author_review", value: 1 },
                        { name: "entryId", value: entryId }
                    ];
                    return createDeepLink(linkParams);
                }

                function getCopyrightReturnUrlDeepLink(pid, entryId) {
                    const linkParams = [
                        { name: "copyright_return", value: 1 },
                        { name: "pid", value: pid },
                        { name: "entryId", value: entryId }
                    ];
                    return createDeepLink(linkParams);
                }

                function getWebpubEditorialDeepLink(pid) {
                    const linkParams = [
                        { name: "editorial_download", value: 1 },
                        { name: "pid", value: pid }
                    ];
                    return createDeepLink(linkParams);
                }

                return {
                    getAkDeepLink,
                    getConferenceVideoDeepLink,
                    getConferenceVideoListDeepLink,
                    getAkResubmissionLink,
                    getComplianceDeepLink,
                    getQuoteDeepLink,
                    getPADeepLink,
                    getReviseQuoteDeepLink,
                    getAkAuthorReviewDeepLink,
                    getCopyrightReturnUrlDeepLink,
                    getWebpubEditorialDeepLink
                };
            }
        }
    );
})();
