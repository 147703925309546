(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorTOCVideoBrowserController",

        class {
            constructor(
                pid,
                readOnly,
                previousState,
                VideoService,
                ProceedingService,
                TocEntriesApiService,
                $scope,
                $state,
                $uibModal,
                $filter,
                WebSocketService,
                VideoSearchService,
                NotificationService,
                authService
            ) {
                this.pid = pid;
                this.readOnly = readOnly;
                this.previousState = previousState;
                this.VideoService = VideoService;
                this.ProceedingService = ProceedingService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.$scope = $scope;
                this.$state = $state;
                this.$uibModal = $uibModal;
                this.$filter = $filter;
                this.WebSocketService = WebSocketService;
                this.VideoSearchService = VideoSearchService;
                this.NotificationService = NotificationService;
                this.onSearch = _.debounce(this._onSearch, 300);
                this.authService = authService;
            }

            $onInit() {
                this.hasAccess = this.authService.isEditor(); // also check if the user is an editor

                this.sortType = "order";
                this.sortReverse = false;

                // load proceeding
                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;
                });

                this.loadVideos();

                // load entries (for entry sessions)
                this.TocEntriesApiService.getEntries(this.pid).then(entries => {
                    this.entrySessionMap = this.TocEntriesApiService.getEntrySessionMap(
                        entries
                    );
                });

                // load configuration
                this.VideoService.getS3Configuration().then(
                    ({ s3Bucket, folder }) => {
                        this.s3Bucket = s3Bucket;
                        this.s3Folder = folder;
                    }
                );
            }

            goBack() {
                this.$state.go(this.previousState, { pid: this.pid });
            }

            downloadRelease() {
                this.VideoService.downloadVideoReleaseDocuments(this.pid);
            }

            downloadLinks() {
                this.VideoService.downloadVideoLinks(this.pid);
            }

            downloadCsdlLinks() {
                this.VideoService.downloadCsdlVideoLinks(this.pid);
            }

            loadVideos(skipSort = false) {
                this.VideoService.getVideosForConference(this.pid).then(
                    videos => {
                        this.videos = videos;
                        const [articleVideos, conferenceVideos] = _.partition(
                            videos,
                            "eid"
                        );
                        const sortedArticleVideos = _.sortBy(
                            articleVideos,
                            "entry.sequence"
                        );
                        const sortedConferenceVideos = _.sortBy(
                            conferenceVideos,
                            v => v.metadata.title.toLowerCase()
                        );
                        _.forEach(
                            sortedArticleVideos.concat(sortedConferenceVideos),
                            (video, idx) => (video._order = idx + 1)
                        );

                        this.searchIndex = this.VideoSearchService.makeIndex(
                            videos,
                            this.getTitleString,
                            this.getVideoFilenameString,
                            this.getSpeakersString,
                            this.getSessionString.bind(this)
                        );
                        this.videoDisplay = this.videos;
                        this.videoMap = _.keyBy(this.videos, "videoId");
                        if (!skipSort) {
                            this.setSortType("order", false);
                        }
                    }
                );
            }

            hasVideos() {
                return !_.isEmpty(this.videos);
            }

            getDownloadLink(video) {
                return `https://${this.s3Bucket}/${
                    _.isEmpty(this.s3Folder) ? "" : this.s3Folder + "/"
                }${this.getConferenceAcronym()}/${this.getS3VideoFilename(
                    video
                )}`;
            }

            _onSearch() {
                this.videoDisplay = this.searchIndex.search(this.searchInput);
                // re-sort the results
                this.setSortType();
                this.WebSocketService.safeApply(this.$scope);
            }

            clearSearch() {
                this.searchInput = null;
                this.videoDisplay = this.videos;
                this.setSortType();
            }

            setSortType(sortType, setSortReverse) {
                // recurse to re-set current sorting if call without sortType
                if (!sortType) {
                    return this.setSortType(this.sortType, this.sortReverse);
                }
                let reverse;
                if (this.sortType !== sortType || !_.isNil(setSortReverse)) {
                    // set the new sort type
                    this.sortType = sortType;

                    // sort the videos by the type select
                    this.videoDisplay = _.sortBy(
                        this.videoDisplay,
                        this.getSortFunction(this.sortType)
                    );

                    reverse = setSortReverse || false;
                    this.sortReverse = reverse;
                } else {
                    // otherwise toggle the sort order
                    reverse = true;
                    this.sortReverse = !this.sortReverse;
                }

                // apply any reversing
                this.videoDisplay = reverse
                    ? _.reverse(this.videoDisplay)
                    : this.videoDisplay;
            }

            getSortFunction(sortType) {
                switch (sortType) {
                    case "title":
                        return this.bindAndLowercase(this.getTitleString);
                    case "speakers":
                        return this.bindAndLowercase(this.getSpeakersString);
                    case "session":
                        return this.bindAndLowercase(this.getSessionString);
                    case "paper":
                        return this.bindAndLowercase(this.getVideoPaperString);
                    case "filename":
                        return this.bindAndLowercase(
                            this.getVideoFilenameString
                        );
                    case "size":
                        return this.getVideoSizeForSorting;

                    case "uploadTime":
                        return this.bindAndLowercase(
                            this.getVideoDateStringForSorting
                        );
                    case "order":
                        return this.getVideoOrder;
                    default:
                        throw new Error("Invalid sort type: " + sortType);
                }
            }

            getConferenceAcronym() {
                if (!this.proceeding) return null;
                return (
                    this.proceeding.acronym +
                    this.proceeding.year.toString().slice(-2)
                );
            }

            getTitleString(video) {
                return (
                    (video.metadata && video.metadata.title) ||
                    video.entry.title
                );
            }

            getVideoOrder(video) {
                return video._order;
            }

            getSpeakersString(video) {
                if (!video.entry || !video.entry.authors)
                    return video.metadata && video.metadata.speakers;
                return video.entry.authors
                    .map(author => author.givenName + " " + author.surname)
                    .join(", ");
            }

            /**
             * Helper function wraps strings for the sort function with functions to convert to lowercase and remove whitespace
             * @param f
             * @return {function(*=): string}
             */
            bindAndLowercase(f) {
                const thisRef = this;
                return function(video) {
                    return _.replace(
                        _.lowerCase(f.bind(thisRef)(video) || ""), // remove punctuation, normalize spacing and convert to lowercase
                        /\s*/g, // match all whitespace
                        ""
                    );
                };
            }

            getSessionString(video) {
                return (
                    (video.metadata && video.metadata.session) || // lookup session info for the video
                    (video.entry && // use the entry session header
                        this.entrySessionMap &&
                        this.entrySessionMap[video.entry.eid])
                );
            }

            getVideoPaperString(video) {
                return video.eid ? "Yes" : "No";
            }

            getVideoFilenameString(video) {
                return video.file.original;
            }

            getVideoSizeString(video) {
                return this.$filter("byteFmt")(video.file.size, 0);
            }

            getVideoSizeForSorting(video) {
                return video.file.size;
            }

            getVideoDateString(video) {
                return this.$filter("date")(video.createDate, "short");
            }

            getVideoDateStringForSorting(video) {
                return video.createDate;
            }

            getS3VideoFilename(video) {
                return `${video.videoId}-${video.file.original}`;
            }

            getVideoEmbedCode(video) {
                return `<video
  width="680" 
  height="340"
  src="https://${this.s3Bucket}/${
                    _.isEmpty(this.s3Folder) ? "" : this.s3Folder + "/"
                }${this.getConferenceAcronym()}/${this.getS3VideoFilename(
                    video
                )}"
  controls
  style="background-color: black">
</video>`;
            }

            copyEmbedCodeToClipboard(video) {
                if (navigator.clipboard) {
                    // write to the clipboard directly using the navigator API
                    navigator.clipboard
                        .writeText(this.getVideoEmbedCode(video))
                        .then((success, err) => {
                            if (err) {
                                console.error("error: " + err);
                            } else {
                                this.NotificationService.send(
                                    "success",
                                    "Video embed code copied to clipboard."
                                );
                                this.WebSocketService.safeApply(this.$scope);
                            }
                        });
                } else {
                    // Create a temporary input to copy the string array inside it
                    const tmp = document.createElement("input");

                    // Add it to the document
                    document.body.appendChild(tmp);

                    // Set its ID
                    tmp.setAttribute("id", video.videoId);

                    // Output the text into it
                    document.getElementById(
                        video.videoId
                    ).value = this.getVideoEmbedCode(video);

                    // Select it
                    tmp.select();

                    // Copy its contents
                    document.execCommand("copy");

                    // Remove it as its not needed anymore
                    document.body.removeChild(tmp);

                    this.NotificationService.send(
                        "success",
                        "Video embed code copied to clipboard."
                    );
                }
            }

            setSelectedVideosToRemove(clear) {
                // optionally clear the selected Ids in the UI
                if (clear) {
                    this.selectedVideoIdsToRemove = null;
                }

                // find the selected articleIds for which the checkBox is marked "true"
                const filteredIdsToRemove = _.pickBy(
                    this.selectedVideoIdsToRemove,
                    value => value
                );
                // map the vids to files
                this.selectedVideosToRemove = _.map(
                    filteredIdsToRemove,
                    (value, key) => this.videoMap[key]
                );
                console.log(
                    "selected videos to remove:",
                    this.selectedVideosToRemove
                );

                return this.selectedVideoIdsToRemove;
            }

            removeVideosFromUI(selectedVideosToRemove) {
                this.videoDisplay = _.differenceBy(
                    this.videoDisplay,
                    selectedVideosToRemove,
                    "videoId"
                );
                this.videoMap = _.keyBy(this.videos, "videoId");
                this.WebSocketService.safeApply(this.$scope);
            }

            removeVideos() {
                // get the file objects being removed
                if (this.selectedVideosToRemove.length < 1) {
                    return;
                }
                const selectedVideosToRemove = this.selectedVideosToRemove;

                // clear the list of selected IDs. This will disable the delete button
                this.setSelectedVideosToRemove(true);

                // confirm the selection
                this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Warning",
                            message: () =>
                                `<p>Are you sure you want to permanently delete the selected video file(s)?</p>
                                <p>This cannot be undone.</p>`
                        }
                    })
                    .result.then(() => {
                        // remove the file from the list of files
                        this.removeVideosFromUI(selectedVideosToRemove);
                        //remove the files from the DB
                        return Promise.map(
                            selectedVideosToRemove,
                            video => this.VideoService.removeVideo(video),
                            { concurrency: 8 }
                        );
                    })
                    .then(results => {
                        console.log("remove: ", results);
                        this.NotificationService.send(
                            "success",
                            `The video(s) were removed successfully`
                        );
                    })

                    // reload the files of the selected type
                    .then(() => {
                        this.loadVideos(true);
                        this.onSearch();
                    })
                    .catch(err => {
                        if (err && err !== "backdrop click") {
                            console.log(err);
                            this.NotificationService.send(
                                "warning",
                                `There was an error in removing the video file(s).`
                            );
                        }
                    });
            }

            selectDuplicates(mode) {
                const selectingFilenames = mode === "filename";
                const selectingTitles = mode === "title";
                this.selectedVideoIdsToRemove = {};
                _(this.videos)
                    .filter(video => !selectingTitles || !video.eid) // don't include article videos in title selection
                    .groupBy(video => {
                        if (selectingTitles) {
                            return this.getTitleString(video);
                        } else if (selectingFilenames) {
                            return this.getVideoFilenameString(video);
                        } else {
                            throw new Error("Invalid group-by mode: " + mode);
                        }
                    })
                    .forEach(group => {
                        _(group)
                            .sortBy(video =>
                                this.getVideoDateStringForSorting(video)
                            )
                            .initial()
                            .forEach(video => {
                                this.selectedVideoIdsToRemove[
                                    video.videoId
                                ] = true;
                            });
                    });
                this.setSelectedVideosToRemove();
            }

            toggleSelectAll() {
                if (this.selectedVideoIdsToRemove) {
                    return this.setSelectedVideosToRemove(true);
                }

                this.selectedVideoIdsToRemove = _(this.videos)
                    .keyBy("videoId")
                    .mapValues(_ => true)
                    .value();
                return this.setSelectedVideosToRemove();
            }
        }
    );
})();
