(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("prefixvalidation", function($q, $timeout) {
            return {
                require: "ngModel",

                link: function(scope, element, attrs, ngModel) {
                    ngModel.$parsers.unshift(function(value) {
                        const field = ngModel.$name;

                        let arr = [
                            "MR",
                            "MS",
                            "MRS",
                            "MISS",
                            "DR",
                            "PROF",
                            "PROFESSOR",
                            "HON",
                            "ASST"
                        ];

                        if (value !== "") {
                            if (field === "givenName") {
                                const valueUpper = value.toUpperCase().trim();

                                /**
                                 * Check field for invalid suffix
                                 */
                                let hasInvalidPrefix = false;

                                for (let i = 0; i < arr.length; i++) {
                                    if (
                                        valueUpper.startsWith(arr[i] + ".") ||
                                        valueUpper.startsWith(arr[i] + "-") ||
                                        valueUpper.startsWith(arr[i] + " ") ||
                                        valueUpper === arr[i]
                                    ) {
                                        hasInvalidPrefix = true;
                                    }
                                }
                                if (hasInvalidPrefix) {
                                    ngModel.$setValidity("prefixcheck", false);
                                    return value;
                                } else {
                                    ngModel.$setValidity("prefixcheck", true);
                                    return value;
                                }
                            }

                            // });
                        }

                        // });
                    });
                }
            };
        });
})();
