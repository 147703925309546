(function() {
    "use strict";

    angular.module("cpir").component("cpirConfWebpubTemplatesPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/conf-webpub-templates-panel/conf-webpub-templates-panel.html",
        controller: class {
            constructor(
                authService,
                NotificationService,
                ConfigurationService
            ) {
                this.authService = authService;
                this.ConfigurationService = ConfigurationService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.brand = this.authService.getBrand().name;

                this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        this.configuration = result.configuration.cps;
                    else if (this.brand === "pubhub")
                        this.configuration = result.configuration.pubhub;

                    this.activeTemplateIndex = 0;
                    this.activeTemplate = this.configuration.settings.webpub.webpubTemplates[0];
                    console.log(
                        "Webpub templates: %O",
                        this.configuration.settings.webpub.webpubTemplates
                    );
                });
            }

            activateTemplate(templateIndex) {
                this.activeTemplateIndex = templateIndex;
                this.activeTemplate = this.configuration.setting.webpub.webpubTemplates[
                    templateIndex
                ];
            }

            saveChanges() {
                this.ConfigurationService.get().then(result => {
                    if (this.brand === "cps")
                        result.configuration.cps = this.configuration;
                    else if (this.brand === "pubhub")
                        result.configuration.pubhub = this.configuration;

                    this.ConfigurationService.update(result).then(() => {
                        this.NotificationService.send(
                            "success",
                            "The webpub template was successfully saved."
                        );
                    });
                });
            }
        }
    });
})();
