(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardEditorPaContactInformation", {
            bindings: {
                title: "<",
                contact: "<",
                onUpdate: "&"
            },
            templateUrl:
                "application/cpir/dashboard/editor/publication-agreement/components/" +
                "contact-information/contact-information.component.html",
            controller: function() {
                let $ctrl = this;

                $ctrl.modelChanged = () => {
                    $ctrl.onUpdate({
                        updatedContactInformation: $ctrl.contactInformation
                    });
                };
            }
        });
})();
