(function () {
    "use strict";

    angular.module("cpir").controller(
        "DashboardProceedingConfigurationController",
        class {
            constructor(
                $scope,
                viewQuotes,
                quoteIndex,
                findQuoteRevision,
                pid,
                NotificationService,
                ProceedingService,
                ProceedingStatusService,
                CifService,
                authService,
                $state,
                ProceedingRoleService,
                UserListService,
            ) {
                this.viewQuotes = viewQuotes;
                this.quoteIndex = quoteIndex;
                this.findQuoteRevision = findQuoteRevision;
                this.pid = pid;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.$scope = $scope;
                this.CifService = CifService;
                this.authService = authService;
                this.$state = $state;
                this.ProceedingRoleService = ProceedingRoleService;
                this.UserListService = UserListService;
            }

            $onInit() {
                if (!this.authService.isEditor()) {
                    this.$state.go("dashboard-editor.home");
                }

                this.disableQuote = true;
                this.lev1 = "0";
                this.lev2 = "0";
                if (this.viewQuotes) {
                    this.$scope.active = 1;
                    this.showConfiguration("pa", true);
                }
                this.refreshProceeding().then((proceeding) => {
                    this.UserListService.getStrings({ type: 1 }).then(
                        (strings) => {
                            this.userListUserType = strings.userType;
                        },
                    );
                    this.UserListService.getContactMatchesForProceeding(
                        proceeding,
                    )
                        .then((matches) => {
                            this.userList = matches;
                        })
                        .catch(console.error);
                });
            }

            onCategorySelect(category) {
                switch (category) {
                    case "general":
                        this.showConfiguration("general.status");
                        this.lev2 = 0;
                        break;
                    case "ak":
                        this.showConfiguration("ak.login");
                        break;
                    case "comp":
                        this.showConfiguration("comp.general");
                        break;
                    case "wk":
                        this.showConfiguration("wk.prod-schedule");
                        break;
                }
            }

            showConfiguration(status, force = false) {
                // disable switching to quote if the proceeding is a workshop
                if (status === "pa") {
                    if (this.disableQuote && !force) {
                        return;
                    }
                }
                this.activeConfigurationPanel = status;
            }

            isConfigurationPanelActive(status) {
                return this.activeConfigurationPanel === status;
            }

            hideQuoteModule() {
                return this.ProceedingStatusService.isInquiryStarted(
                    this.proceeding,
                );
            }

            showSendToOrganizerButton() {
                return this.ProceedingStatusService.isAkSetup(this.proceeding);
            }

            refreshProceeding() {
                return this.ProceedingService.get(this.pid).then(
                    (proceeding) => {
                        if (!this.ProceedingRoleService.isEditor(proceeding)) {
                            this.$state.go("dashboard-editor.home");
                        }

                        if (!proceeding.isWorkshop) {
                            this.disableQuote = false;
                        } else if (this.$scope.active === 1) {
                            this.$scope.active = 0;
                        }
                        this.proceeding = proceeding;
                        this.showSaveButton = true;
                        this.configuration = this.proceeding.configuration;
                        if (this.proceeding.isWorkshop) {
                            this.ProceedingService.get(
                                this.proceeding.parent,
                            ).then((parent) => {
                                this.parent = parent;
                            });
                        } else {
                            if (this.proceeding)
                                this.ProceedingService.getWorkshops(
                                    this.proceeding.pid,
                                )
                                    .then((workshops) => {
                                        this.workshops = workshops;
                                    })
                                    .catch(function (err) {
                                        console.log(err);
                                    });
                        }
                        return proceeding;
                    },
                );
            }

            saveConfiguration({
                configuration,
                compliance,
                editor,
                hideNotification = false,
            }) {
                let updateObject = {};
                if (configuration) {
                    updateObject.configuration = configuration;
                }
                if (compliance) {
                    updateObject["compliance.submission"] = compliance;
                }
                if (editor) {
                    updateObject.editor = editor;
                }
                const updateAsync = _.isEmpty(updateObject)
                    ? Promise.resolve()
                    : this.ProceedingService.update(this.pid, updateObject);
                return updateAsync
                    .then(() => this.refreshProceeding())
                    .then(() => {
                        if (!hideNotification) {
                            this.NotificationService.send(
                                "success",
                                "The configuration was saved successfully.",
                            );
                        }
                    });
            }

            setLabel(label) {
                return label;
            }

            onUpdate(hasSeparatePublicationsInXplore) {
                this.hasWorkshops = hasSeparatePublicationsInXplore;
            }

            saveProceeding(proceeding, isParentConfTab) {
                let proceedingPayload = {
                    acronym: proceeding.acronym,
                    dates: proceeding.dates,
                    icxId: proceeding.icxId,
                    title: proceeding.title,
                };

                if (!proceeding.isWorkshop) {
                    proceedingPayload.isSponsored = proceeding.isSponsored;
                    proceedingPayload.hasSeparatePublicationsInXplore =
                        proceeding.hasSeparatePublicationsInXplore;
                }

                proceedingPayload.location = proceeding.location;
                proceedingPayload.postalCode = proceeding.postalCode;
                proceedingPayload.webURL = proceeding.webURL;

                if (proceeding.pid) {
                    this.ProceedingService.update(
                        proceeding.pid,
                        proceedingPayload,
                    )
                        .then(() => {
                            this.NotificationService.send(
                                "success",
                                "The conference was saved successfully.",
                            );
                        })
                        .then(() => {
                            if (
                                this.proceeding.isWorkshop &&
                                !isParentConfTab
                            ) {
                                if (this.parent === undefined) {
                                    this.ProceedingService.get(
                                        this.proceeding.parent,
                                    ).then((parent) => {
                                        this.parent = parent;
                                    });
                                }

                                let w = this.parent.workshops.find(
                                    (w) => w.pid === this.proceeding.pid,
                                );

                                w.title = proceeding.title;
                                w.acronym = proceeding.acronym;
                                w.dates = proceeding.dates;
                                w.icxId = proceeding.icxId;
                                w.location = proceeding.location;
                                w.postalCode = proceeding.postalCode;
                                w.webUrl = proceeding.webUrl;

                                this.ProceedingService.update(this.parent.pid, {
                                    workshops: this.parent.workshops,
                                }).then(() => {
                                    this.NotificationService.send(
                                        "success",
                                        "The parent conference workshop array object was saved successfully.",
                                    );
                                });
                                //});
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }
            }
        },
    );
})();
