(function() {
    "use strict";

    /*
     * Description: This filter accepts a 'name' for a value
     * of an item attribute and the corresponding medium. It
     * then outputs the correct label for that name/value pair.
     */

    angular.module("cpir").filter("cpirHideHiddenAttributesFilter", () => {
        return attributes => {
            if (!attributes) return attributes;
            return attributes.filter(a => {
                return a.type !== "hidden";
            });
        };
    });
})();
