(function() {
    "use strict";

    angular.module("cpir").component("cpirAkAuthorsAuthorPanel", {
        bindings: {
            author: "<",
            isOrcidEnabled: "<",
            isLastAuthor: "<",
            onUpdate: "&",
            onRemove: "&",
            isAuthorFormValid: "&",
            isEmailRequired: "<"
        },
        templateUrl:
            "application/cpir/authorkit/authors/components/author-panel/author-panel.component.html",
        controller: class {
            constructor($rootScope
                        ) {
                this.$rootScope = $rootScope;
            }

            $onInit() {
                this.authorForm = {};

                this.$rootScope.$on("AUTHOR_ADDED", () => {
                    this.authorForm.$setPristine();
                });
                this.isEmailRequired = isEmailRequired;
            }

            $onChanges(changes) {
                if (changes.author && changes.author.currentValue) {
                    this.isAuthorFormValid = this.authorForm.$valid;
                    this.modelChanged();
                }
            }

            modelChanged() {

                this.onUpdate({
                    updatedAuthor: this.author,
                    isValid: this.authorForm.$valid
                });
            }

            removeButtonClicked() {
                this.onRemove();
            }



        }

    });
})();
