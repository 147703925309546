(function() {
    "use strict";

    angular.module("cpir").component("cpirAkConfCopyrightPanel", {
        bindings: {
            configuration: "<",
            onSave: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/configuration-common/components/ak-conf-copyright-panel/ak-conf-copyright-panel.component.html",
        controller: class {
            constructor(
                $stateParams,
                DefaultSettingsService,
                NotificationService
            ) {
                this.$stateParams = $stateParams;
                this.NotificationService = NotificationService;
                this.DefaultSettingsService = DefaultSettingsService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.booleanOptions = [
                    { name: "Yes", value: true },
                    { name: "No", value: false }
                ];

                this.copyrightTypeOptions = [
                    { name: "IEEE", value: "ieee" },
                    { name: "ACM", value: "acm" },
                    { name: "Other", value: "other" }
                ];
            }

            copyrightTypeChanged() {
                if (this.configuration.settings.ak.copyright.type === "acm") {
                    this.configuration.settings.ak.copyright.instructions = this.configuration.settings.ak.copyright.acmInstructions;
                } else if (
                    this.configuration.settings.ak.copyright.type === "ieee"
                ) {
                    this.configuration.settings.ak.copyright.instructions = this.configuration.settings.ak.copyright.ieeeInstructions;
                }

                if (this.configuration.settings.ak.copyright.type !== "ieee") {
                    this.configuration.settings.ak.submission.iThenticateEnabled = false;
                }
            }

            saveConfiguration() {
                this.onSave({
                    props: {
                        configuration: this.configuration
                    }
                });
            }
        }
    });
})();
