(function () {
    "use strict";

    angular.module("cpir").component("cpirTocQuickSelectModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@",
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/quick-select-modal/quick-select-modal.component.html",
        controller: class {
            constructor(TOCValuesService) {
                this.TOCValuesService = TOCValuesService;
            }

            $onInit() {
                this.entries = this.resolve.entries;
                this.articles = this.resolve.articles;
                this.isEntryAkActive = this.resolve.isEntryAkActive;
                this.articlesByEntry = _.keyBy(this.articles, "eid");
                //TODO check what this is and take it out
                this.copyrightLineTypeOptions = [
                    { name: "Standard", value: "standard" },
                    { name: "OA", value: "oa" },
                    { name: "Blank", value: "blank" },
                    { name: "Override", value: "override" },
                ];
                this.productionStatusOptions =
                    this.TOCValuesService.productionStatusOptions.filter(
                        (option) => option.value !== "Undefined",
                    );
                this.quickSelect = {};
                this.quickSelect.selectedStatus = "";
            }

            selectDuplicateArticles() {
                return this.selectDuplicates("SB", "duplicate articles");
            }

            selectDuplicateSessionDividers() {
                return this.selectDuplicates(
                    "SD",
                    "duplicate session dividers",
                );
            }

            selectDuplicates(entryClass, friendlyClassName) {
                let entryMap = this.entries
                    .filter((e) => e.class === entryClass)
                    .reduce((map, entry) => {
                        let file = this.articlesByEntry[entry.eid];

                        if (file) {
                            let fileTime = new Date(file.createDate);
                            let entryListing = map[entry.title] || [];
                            entryListing.push({
                                entry: entry,
                                fileTime: fileTime,
                            });
                            map[entry.title] = entryListing;
                        } else if (!this.isEntryAkActive(entry)) {
                            let entryListing = map[entry.title] || [];
                            entryListing.push({
                                entry: entry,
                                fileTime: new Date("01-01-1970").toISOString(),
                            });
                            map[entry.title] = entryListing;
                        }

                        return map;
                    }, {});

                let duplicates = Object.keys(entryMap)
                    .filter((key) => entryMap[key].length > 1)
                    .map((key) => {
                        let listings = entryMap[key];
                        listings.sort((a, b) => a.fileTime > b.fileTime);
                        listings = listings.slice(0, -1); // remove the newest listing
                        return listings.map((listing) => {
                            return {
                                eid: listing.entry.eid,
                                title: key,
                                sequence: listing.entry.sequence,
                                date: new Date(
                                    listing.fileTime,
                                ).toLocaleString(),
                            };
                        });
                    })
                    .reduce((dupes, group) => dupes.concat(group), []);

                // print duplicates
                this.printSelectedReport(duplicates, friendlyClassName);

                //select duplicates
                return duplicates.map((d) => d.eid);
            }

            selectAllSessionDividers() {
                const selected = this.entries.filter((e) => e.class === "SD");
                this.printSelectedReport(selected, "all session dividers");
                return selected.map((e) => e.eid);
            }

            selectUnfinishedAuthorKits() {
                const selected = this.entries.filter(
                    (e) =>
                        e.class === "SB" &&
                        !this.isEntryAkActive(e) &&
                        e.title &&
                        e.title
                            .toLowerCase()
                            .startsWith("[ak started by author:"),
                );

                this.printSelectedReport(selected, "unfinished author kits");
                return selected.map((e) => e.eid);
            }

            selectBlankTitles() {
                const selected = this.entries.filter((e) => {
                    let file = this.articlesByEntry[e.eid];
                    return (!e.title || e.title.trim() === "") && !file;
                });

                this.printSelectedReport(selected, "blank titles");
                return selected.map((e) => e.eid);
            }

            selectTitlesByStatus() {
                //the statys selection works on only SB filter - that is the reason the FM and BM with the selected "complete" status was left out
                const selected = this.entries.filter(
                    (e) =>
                        e.class === "SB" &&
                        e.productionStatus &&
                        typeof e.productionStatus === "string" &&
                        e.productionStatus
                            .toLowerCase()
                            .startsWith(this.quickSelect.selectedStatus),
                );

                this.printSelectedReport(selected, "titles by status");
                return selected.map((e) => e.eid);
            }

            printSelectedReport(selected, selectionName) {
                console.log(
                    selected
                        .map(function (dupe) {
                            return (
                                "" +
                                dupe.sequence +
                                "\t" +
                                dupe.title +
                                "\t" +
                                (dupe.date || "no-date")
                            );
                        })
                        .join("\n"),
                );
                console.log(
                    `number of ${selectionName} selected: ${selected.length}`,
                );
            }

            okButtonClicked() {
                let selected = {};
                let updateMapWithIds = (selected, id) => {
                    selected[id] = true;
                    return selected;
                };

                if (this.quickSelect.duplicateArticles) {
                    selected = this.selectDuplicateArticles().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                if (this.quickSelect.duplicateSessionDividers) {
                    selected = this.selectDuplicateSessionDividers().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                if (this.quickSelect.allSessionDividers) {
                    selected = this.selectAllSessionDividers().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                if (this.quickSelect.unfinishedAuthorKits) {
                    selected = this.selectUnfinishedAuthorKits().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                if (this.quickSelect.blankTitles) {
                    selected = this.selectBlankTitles().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                if (this.quickSelect.selectedStatus) {
                    console.log("inside selecting status");
                    selected = this.selectTitlesByStatus().reduce(
                        updateMapWithIds,
                        selected,
                    );
                }
                console.log("printing selected");
                console.log(selected);

                this.close({ $value: Object.keys(selected) });
            }

            selectByStatusChanged() {
                if (!this.titlesByStatus) {
                    console.log("clear status");
                    this.quickSelect.selectedStatus = "";
                }
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        },
    });
})();
