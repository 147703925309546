(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPaperValidationPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/paper-validation-panel/paper-validation-panel.component.html",
        controller: function() {}
    });
})();
