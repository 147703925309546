(function() {
    "use strict";

    angular.module("cpir").provider(
        "ProceedingXploreStatusService",
        class {
            $get($q, $http, $timeout) {
                function getXploreStatus(proceeding) {
                    let xploreStatus = null;
                    if(proceeding) {
                        let exportArray = proceeding.export;
                        if (exportArray) {
                            xploreStatus = {};
                            for (let i = 0; i < exportArray.length; i++) {
                                let item = exportArray[i];
                                if (item.syndicationDate && !item.syndicationError && !xploreStatus.syndicationError && !xploreStatus.syndicating)
                                    xploreStatus.syndicated = true;
                                else if (item.syndicationError) {
                                    xploreStatus.syndicationError = true;
                                } else if (proceeding.exportDate && !item.syndicationDate && !xploreStatus.syndicationError) {
                                    xploreStatus.syndicating = true;
                                }
                            }
                        }
                    }
                   return xploreStatus;
                }

                return {
                    getXploreStatus,
                };
            }
        }

    );
})();
