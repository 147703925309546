(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardComplianceAcceptanceController",
        class {
            constructor() {}

            $onInit() {}
        }
    );
})();
