(function() {
    "use strict";

    angular.module("cpir").component("cpirTocChangeEntryStatusModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/change-entry-status-modal/change-entry-status-modal.component.html",
        controller: class {

            constructor(TOCValuesService, NotificationService) {
                this.TOCValuesService = TOCValuesService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                this.productionStatus = "";
                _.entries(this.resolve).forEach(
                    entry => (this[entry[0]] = entry[1])
                );

                this.productionStatusOptions = this.TOCValuesService.productionStatusOptions.filter(
                    option => option.value !== "Undefined"
                );
            }

            sendButtonClicked() {
                if(this.productionStatus === "")
                    this.NotificationService.send(
                        "warning",
                        "Choose a paper status to update...",
                    );
                else
                    this.close({ $value: {
                           status : this.productionStatus
                        }
                    });
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
