(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifConferenceUpdateController",

        class CifConferenceController {
            constructor($state, NotificationService, ProceedingService) {
                this.$state = $state;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
            }

            $onInit() {
                this.isProceedingValid = false;
            }

            saveProceeding() {
                this.ProceedingService.create(this.proceeding)
                    .then(pid => {
                        this.NotificationService.send(
                            "success",
                            "The conference was saved successfully."
                        );
                        this.goToNextState(pid);
                    })
                    .catch(error => console.log(error));
            }

            proceedingChanged(proceeding, isValid) {
                this.proceeding = proceeding;
                this.isProceedingValid = isValid;
            }

            isStateValid() {
                return this.isProceedingValid === true;
            }

            goToNextState(pid) {
                this.$state.go("cif.workshops", { pid: pid });
            }
        }
    );
})();
