(function () {
    "use strict";

    /**
     * This service helps to facilitate the AK by providing
     * methods and data that would be redundant to incorp.
     * into each page itself.
     *
     * For instance, navigating between steps is handled here,
     * and also caching of the proceeding, etc.
     *
     * This service does not manage saving the AK data into
     * the appropriate store. That is typically done by the
     * TOC service.
     */
    angular.module("cpir").provider(
        "AKService",
        class {
            constructor() {}
            $get(
                $state,
                $stateParams,
                $uibModal,
                EmailService,
                ProceedingService,
                store,
                DeepLinkService,
                TocEntriesApiService,
                $q,
                WebSocketService,
                $http,
                ProceedingStatusService,
            ) {
                /**
                 * Possible states in the AK. This can be moved
                 * to the proceeding configuration so that the
                 * order can be rearranged as needed.
                 * @type {[*]}
                 */
                let akStates = [
                    "ak.preparation-information",
                    "ak.paper-details",
                    "ak.authors",
                    "ak.paper-submission",
                    "ak.entry-video-submission",
                    "ak.copyright-release",
                ];

                function getActiveAkStates() {
                    return akStates.filter((stateName) =>
                        isStateActive(stateName),
                    );
                }

                /**
                 * Get a proceeding from the browser storage if it had
                 * already been placed there from the last time this
                 * service was loaded (for caching between steps).
                 *
                 * Still, though, get the proceeding from the API and
                 * replace the cached version after it is loaded.
                 *
                 * This has the effect of minimizing perceived loading
                 * delays of the dynamic portions of the AK.
                 */
                let storedProceeding = store.get("ak.proceeding");

                if (storedProceeding) {
                    if (storedProceeding.pid === $stateParams.pid)
                        this.proceeding = storedProceeding;
                }

                // ProceedingService.get($stateParams.pid).then(proceeding => {
                //     this.proceeding = proceeding;
                //     store.set("ak.proceeding", proceeding);
                // });

                /**
                 * Helper method to give the AK components the Proceeding
                 * object without needing to make an API call.
                 */
                let getProceeding = () => this.proceeding;

                let refreshProceeding = () => {
                    return ProceedingService.get($stateParams.pid).then(
                        (proceeding) => {
                            this.proceeding = proceeding;
                            store.set("ak.proceeding", proceeding);
                            return proceeding;
                        },
                    );
                };

                let goToPrepInformation = () => {
                    $state.go("ak.preparation-information", {
                        pid: $stateParams.pid,
                        entryId: $stateParams.entryId,
                    });
                };

                function goToOaRelease(stateParams) {
                    $state.go("ak.open-access-copyright-release", stateParams);
                }

                function goToCopyrightRelease(stateParams) {
                    $state.go("ak.copyright-release", stateParams);
                }

                /**
                 * Decide on the next step (either a state or confirmation
                 * dialogue). Accept any needed params to reach a certain
                 * state from the signature.
                 * @param stateParams
                 */
                let goToNextStep = (stateParams) => {
                    refreshProceeding();
                    let useNextState = false;
                    let isLastStep = false;
                    for (let i = 0; i < akStates.length; i++) {
                        if (i === akStates.length - 1) {
                            isLastStep = true;
                        }
                        if (useNextState === true) {
                            if (isStateActive(akStates[i])) {
                                goToState(akStates[i], stateParams);
                                return;
                            }
                        }
                        if (akStates[i] === $state.current.name) {
                            useNextState = true;
                        }
                    }
                    if (isLastStep) {
                        goToState("ak.paper-acceptance", stateParams);
                    }
                };

                /**
                 * Same as going to the "next" step except in reverse
                 * and there is no need for any confirmation dialogues.
                 */
                let goToPreviousStep = (stateParams) => {
                    refreshProceeding();
                    if (isFirstStep()) {
                        goToPrepInformation();
                    } else {
                        let useNextState = false;
                        let isLastStep = false;
                        let akStatesRev = akStates.concat().reverse();
                        for (let i = 0; i < akStatesRev.length; i++) {
                            // if (i === akStatesRev.length - 1) {
                            //     isLastStep = true;
                            // }
                            if (useNextState === true) {
                                if (isStateActive(akStatesRev[i])) {
                                    goToState(akStatesRev[i], stateParams);
                                    return;
                                }
                            }
                            if (akStatesRev[i] === $state.current.name) {
                                useNextState = true;
                            }
                        }
                    }
                };

                /**
                 * Check if the current step is the first step (after the prep details)
                 * This will correspond to the second item in the states list (index 1)
                 * @returns {boolean}
                 */
                let isFirstStep = () => {
                    const activeStates = getActiveAkStates();
                    const currStatePos = activeStates.indexOf(
                        $state.current.name,
                    );
                    return currStatePos === 0;
                };

                /**
                 * Check if the current step is the last step.
                 * @returns {boolean}
                 */
                let isLastStep = () => {
                    const activeStates = getActiveAkStates();
                    const currStatePos = activeStates.indexOf(
                        $state.current.name,
                    );
                    return currStatePos === activeStates.length - 1;
                };

                /**
                 * Helper that checks if a state is active by checking
                 * against the proceeding configuration.
                 * @param stateName
                 * @returns {*}
                 */
                let isStateActive = (stateName) => {
                    if (!this.proceeding) {
                        return false;
                    }
                    switch (stateName) {
                        case "ak.copyright-release":
                            return this.proceeding.configuration.settings.ak
                                .copyright.isEnabled;
                        case "ak.paper-details":
                            return this.proceeding.configuration.settings.ak
                                .details.isEnabled;
                        case "ak.authors":
                            return this.proceeding.configuration.settings.ak
                                .authors.isEnabled;
                        case "ak.paper-submission":
                            return this.proceeding.configuration.settings.ak
                                .submission.isEnabled;
                        case "ak.entry-video-submission": {
                            const { enabled, open } =
                                this.proceeding.configuration.settings.ak
                                    .videoSubmission || {};
                            return enabled && open;
                        }
                        default:
                            return false;
                    }
                };

                /**
                 * Helper method to open the confirmation dialogue to end
                 * the AK.
                 */
                let showConfirmationDialogue = (stateParams) => {
                    $uibModal
                        .open({
                            component: "cpirAkConfirmSaveModal",
                        })
                        .result.then(() => {
                            $state.go("ak.paper-acceptance", stateParams);
                        });
                };

                /**
                 * Helper method to reach a state. Accepts the name
                 * of the state and any needed params.
                 * @param nextState
                 * @param stateParams
                 */
                let goToState = (nextState, stateParams) => {
                    stateParams = stateParams || {};

                    if (!stateParams.pid) stateParams.pid = $stateParams.pid;
                    if (!stateParams.entryId)
                        stateParams.entryId = $stateParams.entryId;

                    $state.go(nextState, stateParams);
                };

                /**
                 * Private helper method to send AK acceptance e-mail.
                 */
                let sendAKAcceptanceEmail = () => {
                    TocEntriesApiService.getEntry($stateParams.eid).then(
                        (entry) => {
                            let emailProps = {
                                to: [entry.submitterEmail],
                                data: {
                                    submitterName: entry.submitterName,
                                    entry: entry,
                                    proceeding: getProceeding(),
                                    akLink: DeepLinkService.getAkDeepLink(
                                        $stateParams.pid,
                                    ),
                                },
                            };
                            EmailService.sendImmediately(
                                "ak-submission",
                                emailProps,
                            ).catch((e) => console.log(e));
                        },
                    );
                };

                let activeConnections = {};

                /**
                 * Opens a Websocket which marks the ak connection as active
                 * @param pid
                 * @param eid
                 */
                let setActive = (pid, eid) => {
                    let deferred = $q.defer();
                    WebSocketService.openSocket((client) => {
                        client.on("ok", () => {
                            console.log("active ak connected to server");
                            activeConnections[eid] = client;
                            client.on("disconnect", () => {
                                delete activeConnections[eid];
                            });
                            deferred.resolve(client);
                        });
                        client.on("exists", () => {
                            console.log("using existing websocket connection");
                            client.disconnect();
                            deferred.resolve(activeConnections[eid]);
                        });
                        client.emit("ak.active", {
                            pid: pid,
                            eid: eid,
                        });
                    });
                    return deferred.promise.catch((err) => {
                        console.error(err);
                        throw err;
                    });
                };

                let checkActive = (entryIds) => {
                    return $http
                        .post(`/ak-active`, entryIds)
                        .then((r) => r.data)
                        .catch((err) => {
                            console.error(err);
                            throw err;
                        });
                };

                /**
                 * Returns the author names as a comma separated string
                 * @param entry The TOC entry to extract the authors from
                 * @param emptyDefault Default value to use if there are no authors
                 */
                function getAuthorNames(entry, emptyDefault = "") {
                    if (!entry || !entry.authors) {
                        return emptyDefault;
                    }
                    return entry.authors
                        .map(
                            (author) => `${author.givenName} ${author.surname}`,
                        )
                        .join(", ");
                }

                /**
                 * Generates a return URL for the last page of the author kit
                 * @param pid The proceeding id to generate a return URL for
                 * @param entryId the TOC entry id to generate a return URL for
                 */
                function getReturnUrl(pid, entryId) {
                    return DeepLinkService.getCopyrightReturnUrlDeepLink(
                        pid,
                        entryId,
                    );
                }

                const isApprovalMode = () => {
                    if (!this.proceeding) {
                        return false;
                    }
                    return (
                        ProceedingStatusService.isAkPending(this.proceeding) ||
                        ProceedingStatusService.isAkSetup(this.proceeding)
                    );
                };

                return {
                    goToNextStep,
                    isFirstStep,
                    isLastStep,
                    goToPreviousStep,
                    getProceeding,
                    refreshProceeding,
                    setActive,
                    checkActive,
                    getAuthorNames,
                    getReturnUrl,
                    goToOaRelease,
                    goToCopyrightRelease,
                    isApprovalMode,
                    sendAKAcceptanceEmail,
                };
            }
        },
    );
})();
