(function() {
    "use strict";

    angular.module("cpir").component("cpirTocReUploadModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents-edit-entry-files/components/table-of-contents-upload-file.component.html",
        controller: class {
            constructor($uibModal, $state, $stateParams, TocEntriesApiService, UploadService, ProceedingService, NotificationService, PitstopService, $window) {
                this.$uibModal = $uibModal;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.TocEntriesApiService = TocEntriesApiService;
                this.UploadService = UploadService;
                this.ProceedingService = ProceedingService;
                this.NotificationService = NotificationService;
                this.PitstopService = PitstopService;
                this.$window = $window;
            }

            $onInit() {
                this.pid = this.resolve.pid;
                this.entry = this.resolve.entry;
                this.proceeding = this.resolve.proceeding;
                this.pitstopEntry = this.resolve.pitstopEntry;
                console.log(this.pid);
                console.log(this.entry);
            }

            downloadArticle(entry) {
                if (!this.pitstopEntry) {
                    this.NotificationService.send(
                        "error",
                        "No article exists for the entry"
                    );
                    return;
                }
                this.PitstopService.getFile(this.pitstopEntry.vid);
            }


            cancelButtonClicked() {
                this.dismiss();
            }

            saveChanges() {
                this.UploadService.upload({
                    pid: this.pid,
                    entryId: this.entry.eid,
                    paper: this.paperPdf
                })
                    .then(uploadResult => {
                        console.log(uploadResult);
                        this.NotificationService.send(
                            "success",
                            "The file were uploaded successfully."
                        );
                    }).then(
                    () => {
                        this.close({$value: 1});
                    })
                    .catch(err => {
                        console.log(err);
                        this.NotificationService.send(
                            "warning",
                            "There was an error uploading the files."
                        );
                    });
            }

            selectPDFFile(file, invalidFile) {
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose a PDF file for this submission."
                    );
                }
                this.pdfDetails = {};
                this.pdfDetails.name = file.name;
                this.pdfDetails.size = file.size;
            }

            removePDFFile() {
                if (this.paperPdf) this.paperPdf = null;
            }



        }
    });
})();
