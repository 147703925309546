(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("scrollToComplianceError", ScrollToComplianceError);

    function ScrollToComplianceError($document) {
        return {
            restrict: "A",
            scope: {
                // scrollToComplianceError: '<',
            },
            link: (scope, $elm) => {
                scope.$on("[COMPLIANCE] SHOW_ERRORS", () => {
                    let complianceErrors = document.getElementsByClassName(
                        "compliance-error"
                    );

                    let firstError = complianceErrors[0];

                    console.log(firstError);

                    angular.element("body").scrollToElementAnimated(firstError);
                    // $document.duScrollTopAnimated(400);
                });
                // scope.$watch('scrollToComplianceError', (newValue, oldValue) => {
                //     if (newValue === true) {
                //         console.log('show errors');
                //     } else {
                //         console.log(newValue);
                //     }
                // });
            }
        };
    }
})();
