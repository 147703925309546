(function() {
    "use strict";

    angular.module("cpir").provider(
        "WordpressService",
        class {
            $get($window, store, $location, $http, DeepLinkService) {
                function getOpenAccessCheckoutUrl(pid, eid) {
                    const returnUrl = DeepLinkService.getCopyrightReturnUrlDeepLink(
                        pid,
                        eid
                    );
                    console.log("return url: ", returnUrl);
                    return $http
                        .post(
                            `/wordpress/open-access/create-redirect-url/proceeding/${pid}/entryId/${eid}`,
                            {
                                returnUrl
                            }
                        )
                        .then(r => r.data.url)
                        .catch(err => console.error(err));
                }

                return {
                    getOpenAccessCheckoutUrl
                };
            }
        }
    );
})();
