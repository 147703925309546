(function() {
    angular
        .module("cpir")
        .component("cpirDashboardOrganizerQuoteSectionSwitcher", {
            bindings: {
                sections: "<",
                activeSection: "<",
                onSwitch: "&"
            },
            templateUrl:
                "application/cpir/dashboard/organizer/quote/components/section-switcher/section-switcher.component.html",
            controller: function() {
                let $ctrl = this;

                $ctrl.buttonClicked = $index => {
                    $ctrl.onSwitch({ activeSectionIndex: $index });
                };
            }
        });
})();
