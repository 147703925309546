(function () {
    "use strict";

    angular.module("cpir").provider(
        "Cpir2Service",
        class {
            $get($window, store, $location, $http, TOCViewModeService) {
                const cpir2Settings = $http
                    .get("/cpir2/config")
                    .then((r) => r.data)
                    .catch((err) => console.error(err));

                async function getBaseURL() {
                    const { proto, host, port } = await cpir2Settings;
                    return `${proto}://${host}:${port}`;
                }

                async function getBaseHref() {
                    return (await cpir2Settings).baseHref;
                }

                async function redirectToAuthorDashboard(pid) {
                    return openInNewTab(await getAuthorDashboardURL(pid));
                }

                async function getAuthorDashboardURL(pid) {
                    const contextString = pid ? `/${pid}?context=${pid}` : "";
                    return `${await getBaseURL()}${await getBaseHref()}author-kit/dashboard${contextString}`;
                }

                async function redirectToAkAuthorReview(entryId) {
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}author-kit/author-review/${entryId}`,
                    );
                }

                async function redirectToProceedingConfiguration(pid) {
                    return openInNewTab(
                        `${await getBaseURL()}${await getBaseHref()}configuration/proceeding/${pid}`,
                    );
                }

                async function redirectToGlobalSettings() {
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}configuration/global-settings`,
                    );
                }

                async function redirectToReturnPath(returnPath) {
                    // remove any beginning slashes from the return path
                    const formattedReturnPath = decodeURIComponent(
                        returnPath,
                    ).replace(/^\/*/g, "");

                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}${formattedReturnPath}`,
                    );
                }

                async function redirectToLogout(context) {
                    const contextString = context
                        ? `?context=${encodeURIComponent(context)}`
                        : "";
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}auth/logout${contextString}`,
                        false,
                    );
                }

                function applyAccessToken(href) {
                    const token = store.get("id_token");
                    if (!_.isNil(token)) {
                        return `${href}#access_token=${token}`;
                    }
                    return href;
                }

                function redirect(href, includeAccessToken = false) {
                    if (includeAccessToken) {
                        $window.location.href = applyAccessToken(href);
                    } else {
                        $window.location.href = href;
                    }
                }

                function openInNewTab(href, includeAccessToken = false) {
                    if (includeAccessToken) {
                        window.open(applyAccessToken(href), "_blank");
                    } else {
                        window.open(href, "_blank");
                    }
                }

                async function redirectToCompliance(pid) {
                    return redirect(await getComplianceURL(pid));
                }

                async function getComplianceURL(pid) {
                    return `${await getBaseURL()}${await getBaseHref()}compliance/${pid}`;
                }

                async function getCifURL(pid) {
                    const url = `${await getBaseURL()}${await getBaseHref()}cif/conference`;
                    if (pid) {
                        return `${url}/${pid}`;
                    } else {
                        return url;
                    }
                }

                async function redirectToCif(pid) {
                    return openInNewTab(await getCifURL(pid));
                }

                async function redirectToInquirySummary(pid) {
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}cif/submission-summary/${pid}`,
                    );
                }

                async function getQuoteURL(pid) {
                    return `${await getBaseURL()}${await getBaseHref()}quote/${pid}`;
                }

                async function redirectToQuote(pid) {
                    return redirect(await getQuoteURL(pid));
                }

                async function redirectToCPIR2Login() {
                    const fragment = window.location.hash;

                    // parse the real query string from the fragment
                    const query = fragment.split("?")[1];

                    const urlBase = `${await getBaseURL()}${await getBaseHref()}auth/login`;

                    if (query) {
                        return redirect(`${urlBase}?${query}`);
                    }
                    return redirect(urlBase);
                }

                async function forwardToCPIR2LandingPage() {
                    const fragment = window.location.hash;

                    // parse the real query string from the fragment
                    const query = fragment.split("?")[1] || "";

                    // check if the "event" and "pid" query parameters are present
                    const queryParameters = query.split("&");
                    const includesEvent = queryParameters.some((param) =>
                        param.includes("event"),
                    );
                    const includesPid = queryParameters.some((param) =>
                        param.includes("pid"),
                    );

                    if (includesEvent && includesPid) {
                        // add a docusign=1 query parameter to the query string if it doesn't already exist
                        const docusignParam = "docusign=1";
                        if (!queryParameters.includes(docusignParam)) {
                            queryParameters.push(docusignParam);
                        }
                        // redirect to the login page with the updated query string
                        return redirect(
                            `${await getBaseURL()}${await getBaseHref()}auth/login?${queryParameters.join("&")}`,
                        );
                    } else {
                        // redirect to the login page with the original query string
                        return redirectToCPIR2Login();
                    }
                }

                async function redirectToVideoList(pid) {
                    const isFastMode =
                        await TOCViewModeService.getSessionViewMode(pid);
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}videos/${pid}?previousState=toc&fast=${isFastMode}`,
                    );
                }

                async function redirectToConfiguration(pid) {
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}configuration/proceeding/${pid}`,
                    );
                }

                async function redirectToConferenceInquiry(pid) {
                    return redirect(
                        `${await getBaseURL()}${await getBaseHref()}cif/conference/${pid}`,
                    );
                }

                return {
                    redirectToAuthorDashboard,
                    redirectToAkAuthorReview,
                    redirectToProceedingConfiguration,
                    redirectToGlobalSettings,
                    redirectToReturnPath,
                    redirectToLogout,
                    getAuthorDashboardURL,
                    getComplianceURL,
                    redirectToCompliance,
                    redirectToCif,
                    redirectToInquirySummary,
                    redirectToQuote,
                    forwardToCPIR2LandingPage,
                    redirectToVideoList,
                };
            }
        },
    );
})();
