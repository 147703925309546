(function() {
    "use strict";

    const LOAD_TIME_MINIMUM = 300;

    angular.module("cpir").provider(
        "TimerService",
        class {
            $get(WebSocketService) {
                function getTimer(time) {
                    return new Promise(resolve => {
                        setTimeout(() => resolve(), time);
                    });
                }

                /**
                 * Gets a loading timer. Re-uses an existing timer if possible
                 * @returns {null|Promise<unknown>|*}
                 */
                function getLoadingTimer(timeOverride, newTimer = false) {
                    if (this.loadingTimer && !newTimer) {
                        return this.loadingTimer;
                    } else {
                        this.loadingTimer = getTimer(
                            timeOverride || LOAD_TIME_MINIMUM
                        );
                        this.loadingTimer.then(() => {
                            this.loadingTimer = null;
                        });
                        return this.loadingTimer;
                    }
                }

                /**
                 * If UI updates are supposed to happen after a timer expires. This function will ensure
                 * Angular draws them.
                 * @param $scope
                 */
                function applyTimer($scope) {
                    WebSocketService.safeApply($scope);
                }

                return {
                    getLoadingTimer,
                    applyTimer
                };
            }
        }
    );
})();
