(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparationInformationModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/modals/preparation-information/preparation-information.modal.html",
        controller: class {
            constructor() {}

            $onInit() {
                this.closeButtonClicked = () => {
                    this.dismiss();
                };
            }
        }
    });
})();
