(function() {
    "use strict";

    angular.module("cpir").provider(
        "authConfiguration",
        class {
            $get() {
                return this.getSettings();
            }

            getSettings() {
                return {
                    domain: "ieeecs-auth.auth0.com",
                    clientID: "tQszqCxfIKt7y79Zmhwn9wUHLxCg9CTf",
                    redirectUri: location.href.toLowerCase(),
                    responseType: "token id_token",
                    audience: "https://ieeecs-auth.auth0.com/userinfo",
                    scope: "openid profile"
                };
            }
        }
    );
})();
