(function() {
    "use strict";

    angular.module("cpir").filter("tocEntriesPagesFilter", () => {
        return (entries, articles) => {
            const articlesByEntry = _.keyBy(articles, "eid");
            if (!entries) return 0;
            let entryPages = entries
                .filter(e => e.pages && articlesByEntry[e.eid])
                .map(e => {
                    let pages = parseInt(e.pages);
                    if (e.pagePadding) {
                        let pagePadding = parseInt(e.pagePadding);
                        if (pagePadding) {
                            pages = pages + pagePadding;
                        }
                    }
                    return pages;
                });
            if (entryPages.length === 1) {
                return entryPages[0];
            } else if (entryPages.length > 1) {
                return entryPages.reduce((a, b) => a + b);
            } else {
                return 0;
            }
        };
    });
})();
