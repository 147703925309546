(function() {
    "use strict";

    angular.module("cpir").component("akPreparationInformation", {
        bindings: {},
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/preparation-information.component.html",
        controller: class {
            constructor($stateParams, ProceedingService) {
                this.$stateParams = $stateParams;
                this.ProceedingService = ProceedingService;
            }

            $onInit() {
                this.pid = this.$stateParams.pid;

                this.ProceedingService.get(this.pid).then(proceeding => {
                    this.proceeding = proceeding;

                    this.activePanel =
                        proceeding.configuration.settings.ak.prep.defaultSection;

                    this.proceeding.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate = new Date(
                        this.proceeding.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate
                    );

                    this.proceeding.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate = new Date(
                        this.proceeding.configuration.settings.ak.prep.deadlines.copyrightDeadlineDate
                    );
                });
            }
        }
    });
})();
