(function() {
    "use strict";

    angular.module("cpir").controller(
        "AkPaperSubmissionController",

        class {
            constructor(
                pid,
                entryId,
                $state,
                $stateParams,
                $uibModal,
                NotificationService,
                AKService,
                ProceedingService,
                ProceedingStatusService,
                TocEntriesApiService,
                UploadService,
                FileService
            ) {
                this.pid = pid;
                this.entryId = entryId;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$uibModal = $uibModal;
                this.AKService = AKService;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.UploadService = UploadService;
                this.FileService = FileService;
            }

            $onInit() {
                this.AKService.setActive(this.pid, this.entryId);

                this.proceeding = this.AKService.getProceeding();

                this.ProceedingService.get(this.$stateParams.pid).then(
                    proceeding => {
                        this.proceeding = proceeding;
                    }
                );

                this.FileService.getActiveArticleMetadataForEntry(
                    this.entryId
                ).then(file => {
                    this.existingArticleFile = file;
                });
                this.FileService.getSourceMetadataForEntry(this.entryId).then(
                    source => {
                        this.existingSourceFile = source;
                    }
                );

                this.TocEntriesApiService.getEntry(this.entryId).then(entry => {
                    this.entry = entry;
                });
            }

            canSubmit() {
                return (
                    this.isLastStep() &&
                    !this.AKService.isApprovalMode() &&
                    !this.savingPaper &&
                    (this.paperPdf || this.existingArticleFile)
                );
            }

            canGoToNextStep() {
                return (
                    !this.savingPaper &&
                    (this.AKService.isApprovalMode() ||
                        this.paperPdf ||
                        this.existingArticleFile)
                );
            }

            savePaper() {
                this.savingPaper = true;
                this.UploadService.upload({
                    pid: this.pid,
                    entryId: this.entryId,
                    paper: this.paperPdf,
                    source: this.paperSource,
                    extras: this.supplementalFiles
                })
                    .then(() => {
                        this.TocEntriesApiService.broadcastEntries(this.pid);
                        this.savingPaper = false;
                        this.NotificationService.send(
                            "success",
                            "Thank you for submitting your article for entry."
                        );
                        this.goToNextStep();
                    })
                    .catch(err => {
                        console.log(err);
                        this.savingPaper = false;
                        this.NotificationService.send(
                            "warning",
                            "There was an error while completing the submission."
                        );
                    });
            }

            selectPDFFile(file, invalidFile) {
                if (invalidFile) {
                    this.NotificationService.send(
                        "danger",
                        "Please choose a PDF file for this submission."
                    );
                }
                this.pdfDetails = {};
                this.pdfDetails.name = file.name;
                this.pdfDetails.size = file.size;
            }

            removePDFFile() {
                if (this.paperPdf) this.paperPdf = null;
            }

            selectSourceFile(file) {
                this.sourceDetails = {};
                this.sourceDetails.name = file.name;
                this.sourceDetails.size = file.size;
            }

            removeSourceFile() {
                if (this.paperSource) this.paperSource = null;
            }

            removeSupplementalFile(index) {
                this.supplementalFiles.splice(index, 1);
            }

            isStateValid() {
                return this.paperPdf && this.paperSource;
            }

            isLastStep() {
                return this.AKService.isLastStep();
            }

            goToPreviousStep() {
                this.AKService.goToPreviousStep();
            }

            goToNextStep() {
                this.AKService.goToNextStep({
                    pid: this.pid
                });
            }
        }
    );
})();
