(function() {
    "use strict";

    angular.module("cpir").component("cpirConfDefaultsGlobalPanel", {
        bindings: {},
        templateUrl:
            "application/cpir/dashboard/editor/global-configuration/components/conf-defaults-global-panel/conf-defaults-global-panel.component.html",
        controller: class {
            constructor(GlobalDefaultsService, NotificationService) {
                this.GlobalDefaultsService = GlobalDefaultsService;
                this.NotificationService = NotificationService;
            }

            $onInit() {
                // Get the settings
                this.GlobalDefaultsService.getSettings().then(settings => {
                    this.settings = settings;
                });
            }

            saveConfiguration() {
                this.NotificationService.send(
                    "success",
                    "The settings were successfully saved."
                );
            }
        }
    });
})();
