(function() {
    "use strict";

    angular.module("cpir").component("cpirProceedingConfWorkshopsPanel", {
        bindings: {
            proceeding: "<",
            workshops: "<",
            onUpdate: "&",
            onSave: "&",
            hasWorkshops: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/proceeding-configuration/components/proceeding-conf-workshops-panel/proceeding-conf-workshops-panel.component.html",
        controller: class {
            constructor(
                $scope,
                $state,
                $stateParams,
                authService,
                CifService,
                ProceedingRoleService,
                ProceedingService,
                NotificationService,
                TimerService
            ) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.authService = authService;
                this.ProceedingRoleService = ProceedingRoleService;
                this.CifService = CifService;
                this.ProceedingService = ProceedingService;
                this.NotificationService = NotificationService;
                this.TimerService = TimerService;
            }

            $onInit() {
                this.configuration = this.CifService.getConfiguration();
                this.BLOCK_LINK_TO_NON_ACTIVE_WORKSHOP = "This workshop is not active in the dashboard yet.";
                this.booleanOptions = [
                    {name: "Yes", value: true},
                    {name: "No", value: false}
                ];
            }

            handleSaveButton() {
                if (this.proceeding && this.proceeding.hasSeparatePublicationsInXplore) {
                    if (!this.isActiveWorkshopValid) {
                        this.saveButtonPopOverMessage = "Complete missing fields before saving workshop";
                        this.isSaveButtonEnabled();
                    } else {
                        this.saveButtonPopOverMessage = "";
                        this.isSaveButtonEnabled();
                    }
                }
                if (this.proceeding && !this.proceeding.hasSeparatePublicationsInXplore) {
                    this.saveButtonPopOverMessage = "Workshops are not enabled for this proceeding.";
                    this.isSaveButtonEnabled();
                }
            }

            $onChanges(changes) {
                //This block prepares the default workshop to present on page load
                if (this.workshopPid === undefined) {
                    this.gotoWorkshopButtonSettings(true, this.BLOCK_LINK_TO_NON_ACTIVE_WORKSHOP);
                } else {
                    if (changes.workshops) {
                        this.workshopObj = this.workshops.find(
                            w => w.pid === this.workshopPid
                        );
                        if (this.workshopObj) {
                            this.workshopStatus = this.workshopObj.status === 1 ? true : false;
                            if (!this.workshopStatus) {
                                this.gotoWorkshopButtonSettings(true, this.BLOCK_LINK_TO_NON_ACTIVE_WORKSHOP)
                            }
                        }
                    }
                }
                if (changes.hasWorkshops) {
                    this.handleSaveButton();
                }
            }

            updateHasWorkshops() {
                this.handleSaveButton();
            }

            isSaveButtonEnabled() {
                this.isSavePopOverEnabled = (this.proceeding && !this.proceeding.hasSeparatePublicationsInXplore) || !this.isActiveWorkshopValid;
               }

            gotoWorkshopButtonSettings(popOverEnabled, popOverMessage) {
                this.isGotoWorkshopPopOverEnabled = popOverEnabled;
                this.goToWorkshopButtonPopOverMessage = popOverMessage;
            }

            updateWorkshops(workshop, workshops, isValid) {
                this.workshopStatus = false;
                this.proceeding.workshops = workshops;
                this.isActiveWorkshopValid = isValid;
                this.handleSaveButton();
                this.workshopPid = workshop.pid;

                //This block sets the go-to workshop button settings when the workshop is changed
                if(workshop && workshop.pid) {
                    if(this.workshops)
                        this.workshopObj = this.workshops.find(
                            w => w.pid === workshop.pid
                        );

                    if(this.workshops && this.workshopObj !== null) {
                            this.workshopStatus = this.workshopObj.status === 1;
                            if(this.workshopPid === undefined || !this.workshopStatus) {
                                if (!this.workshopPid || !this.workshopStatus) {
                                    this.gotoWorkshopButtonSettings(true, this.BLOCK_LINK_TO_NON_ACTIVE_WORKSHOP);
                                }
                            } else {
                                this.gotoWorkshopButtonSettings(false, "");
                            }
                        } else {
                            this.workshopStatus = false;
                            this.gotoWorkshopButtonSettings(false, "");
                        }
                } else {
                    if(this.workshopPid === undefined || !this.workshopStatus) {
                        if (!this.workshopPid || !this.workshopStatus) {
                            this.gotoWorkshopButtonSettings(true, this.BLOCK_LINK_TO_NON_ACTIVE_WORKSHOP);
                        }
                    }
                }
            }

            goToWorkshop() {
                if(this.workshopPid && this.workshopStatus) {
                    this.$state.go("dashboard-editor.proceeding-configuration", { pid: this.workshopPid });
                }
            }
            saveWorkshops(proceeding) {
                if(this.isActiveWorkshopValid && (this.proceeding && this.proceeding.hasSeparatePublicationsInXplore)) {
                    if (this.proceeding.pid) {
                        this.CifService.breakoutWorkshops(this.proceeding).then(() => {
                            this.NotificationService.send(
                                "success",
                                "The workshop(s) were saved successfully."
                            );

                        }).catch(function (err) {
                            console.log(err)
                        });
                    }
                }
            }

        }
    });
})();
