(function() {
    "use strict";

    /*
     * Description: This filter accepts a 'name' for a value
     * of an item attribute and the corresponding medium. It
     * then outputs the correct label for that name/value pair.
     */

    angular.module("cpir").filter("cpirQuoteAttributeTypeToLabelFilter", () => {
        return (name, medium) => {
            if (!name || !medium) return null;
            let _medium = medium.fields.find(f => f.name === name);
            return _medium && medium.label ? _medium.label : undefined;
        };
    });
})();
