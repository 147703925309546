(function() {
    "use strict";

    angular.module("cpir").component("cpirTocProductionSchedule", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/production-schedule/production-schedule.component.html",
        controller: class {
            constructor(PureDateService, ProceedingXploreStatusService, authService) {
                this.PureDateService = PureDateService;
                this.ProceedingXploreStatusService = ProceedingXploreStatusService;
                this.authService = authService;
            }

            $onInit() {
                this.isEditor = this.authService.isEditor();
            }

            isSyndicationError(proceeding) {
                let proceedingXploreStatus = this.ProceedingXploreStatusService.getXploreStatus(proceeding);
                if(proceedingXploreStatus && proceedingXploreStatus.syndicationError)
                    return true;
                else
                    return false;
               }
        }
    });
})();
