(function() {
    "use strict";

    angular.module("cpir").component("cpirTocStampingCustomizationModal", {
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/stamping-customization-modal/stamping-customization-modal.component.html",
        controller: class {
            constructor() {}

            $onInit() {
                const { proceeding } = this.resolve;
                this.proceeding = proceeding;
                this.stampSelect = {
                    headerValue: this.proceeding.title,
                    header: true,
                    doi: true,
                    pageNumber: true,
                    copyright: true,
                    logo: true,
                    background: true
                };
            }

            okButtonClicked() {
                this.close({ $value: this.stampSelect });
            }

            cancelButtonClicked() {
                this.dismiss();
            }
        }
    });
})();
