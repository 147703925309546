(function () {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardEditorPublicationAgreement", {
            bindings: {
                pid: "<",
                activateQuote: "<",
                quoteIndex: "<",
                findQuoteRevision: "<",
                proceeding: "<",
                userList: "<",
                userListUserType: "<",
            },
            templateUrl:
                "application/cpir/dashboard/editor/publication-agreement/publication-agreement.html",
            controller: class PublicationAgreement {
                constructor(
                    $q,
                    authService,
                    apiQuotes,
                    moment,
                    ProceedingService,
                    NotificationService,
                    $uibModal,
                    DeepLinkService,
                    ProceedingStatusService,
                    EmailService,
                    ContactService,
                    Hogan,
                    InternationalDataService,
                    $state,
                ) {
                    this.$q = $q;
                    this.authService = authService;
                    this.apiQuotes = apiQuotes;
                    this.apiProceedings = ProceedingService;
                    this.notifier = NotificationService;
                    this.$uibModal = $uibModal;
                    this.proceedingStatusService = ProceedingStatusService;
                    this.emailService = EmailService;
                    this.contactsService = ContactService;
                    this.DeepLinkService = DeepLinkService;
                    this.Hogan = Hogan;
                    this.moment = moment;
                    this.InternationalDataService = InternationalDataService;
                    this.$state = $state;
                    this.opts = {};
                }

                $onInit() {
                    this.sendText = "Send to Organizer";
                    this.brand = this.authService.getBrand();
                    this.loadContent()
                        .then((promises) => {
                            let quote = promises[0];
                            let proceeding = promises[1];

                            //get DocuSign information if available
                            const {
                                conferenceSignatorySignDate,
                                internalSignatorySignDate,
                            } = (proceeding && proceeding.docuSign) || {};
                            this.conferenceSignatorySignDate =
                                conferenceSignatorySignDate &&
                                new Date(
                                    conferenceSignatorySignDate,
                                ).toLocaleString();
                            this.internalSignatorySignDate =
                                internalSignatorySignDate &&
                                new Date(
                                    internalSignatorySignDate,
                                ).toLocaleString();

                            //generate quote deepLink
                            this.quoteLink =
                                this.DeepLinkService.getQuoteDeepLink(
                                    proceeding.pid,
                                );
                            console.log(this.quoteLink);

                            //check if we can resend and set button text
                            this.canResend =
                                quote.approveDate && !internalSignatorySignDate;
                            this.sendText = quote.approveDate
                                ? "Resend Quote to Organizer"
                                : this.sendText;

                            let filter = () => {
                                this.unorderedSections = this.sections.filter(
                                    (section) => !section.ordered,
                                );
                                this.sections = this.sections.filter(
                                    (section) => section.ordered,
                                );
                                this.quoteSectionIndex =
                                    this.sections.findIndex(
                                        this.isPricingInformation,
                                    );
                                this.activeSection = this.activateSection(
                                    0,
                                    "ordered",
                                );
                            };

                            this.sections = proceeding.publicationAgreement;

                            if (!this.sections) {
                                this.apiProceedings
                                    .defaultPublicationAgreement()
                                    .then((sections) => {
                                        proceeding.publicationAgreement =
                                            sections;
                                        this.sections =
                                            proceeding.publicationAgreement;
                                        filter();
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            } else {
                                filter();
                            }
                        })
                        .then(() => {
                            if (this.activateQuote) {
                                this.activateQuoteSection(this.quoteIndex);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            throw err;
                        });

                    // inline methods
                    this.sectionsReordered = (sections, name) => {
                        this.proceeding.publicationAgreement = sections.concat(
                            this.unorderedSections,
                        );
                        let newIdx =
                            this.proceeding.publicationAgreement.findIndex(
                                (s) => s.name === name,
                            );
                        this.activateSection(newIdx, "ordered");
                    };

                    this.changeQuoteEnable = () => {
                        let status = {
                            enabled: this.quote.enabled,
                        };
                        this.apiQuotes
                            .update(this.quote.qid, status)
                            .then(() => {
                                this.notifier.send(
                                    "success",
                                    `The quote has been ${
                                        this.quote.enabled
                                            ? "enabled"
                                            : "disabled"
                                    }.`,
                                );
                            });
                    };

                    this.isTextArea = (section) =>
                        section
                            ? section.type === "text" ||
                              section.type === "accept" ||
                              section.type === "signature"
                            : false;

                    this.isPricingInformation = (section) =>
                        section ? section.type === "quotes" : false;
                    this.isProductionSchedule = (section) =>
                        section
                            ? section.type === "production schedule"
                            : false;
                    this.isConferenceInformation = (section) =>
                        section ? section.type === "conference" : false;
                    this.isContactInformation = (section) =>
                        section ? section.type === "contact" : false;
                }

                activateQuoteSection(index) {
                    if (this.quoteSectionIndex) {
                        // activate quote by externally passed index
                        if (!_.isNil(index)) {
                            this.activateSection(
                                this.quoteSectionIndex,
                                "ordered",
                                { index },
                            );
                        }
                        // if no index find the index of the first revision and use that
                        else if (this.findQuoteRevision) {
                            const quoteIndexWithRevision =
                                this.quote.cart.findIndex(
                                    ({ response }) =>
                                        response &&
                                        response.action === "revise",
                                );
                            const indexToActivate =
                                quoteIndexWithRevision > 0
                                    ? quoteIndexWithRevision
                                    : 0;
                            this.activateSection(
                                this.quoteSectionIndex,
                                "ordered",
                                { index: indexToActivate },
                            );
                        } else {
                            // go to the 0th index
                            this.activateSection(
                                this.quoteSectionIndex,
                                "ordered",
                            );
                        }
                    }
                }

                loadContent() {
                    return this.$q.all([
                        this.loadQuote(),
                        this.loadProceeding(),
                    ]);
                }

                updateContent() {
                    let quote = this.quote;
                    return this.$q.all([
                        this.apiProceedings.update(this.pid, this.proceeding),
                        this.apiQuotes.update(quote.qid, quote),
                        this.contactsService.update(
                            this.signatory.cid,
                            this.signatory,
                        ),
                    ]);
                }

                loadProceeding() {
                    // load the conference signatory
                    let cids = this.proceeding.contacts.map((c) => c.cid);

                    this.contactsService
                        .list(cids)
                        .then((contacts) => {
                            this.signatory = contacts.find(
                                (contact) => contact.isSignatory,
                            );
                        })
                        .catch((err) => {
                            console.error(err);
                            throw err;
                        });

                    return this.proceeding;
                }

                loadQuote() {
                    let deferred = this.$q.defer();
                    this.apiQuotes
                        .byPid(this.pid)
                        .then((quote) => {
                            this.quote = quote;
                            this.quote.enabled =
                                this.quote.enabled !== undefined
                                    ? this.quote.enabled
                                    : true;
                            this.approveDate = this.quote.approveDate
                                ? new Date(
                                      this.quote.approveDate,
                                  ).toLocaleString()
                                : undefined;
                            deferred.resolve(quote);
                        })
                        .catch((err) => {
                            console.log("Error getting quote: ", err);
                            deferred.reject(err);
                        });
                    return deferred.promise;
                }

                /* Active Section State */
                activateSection(activeSectionIndex, type, opts) {
                    this.opts = opts;
                    if (type === "ordered")
                        this.activeSection = this.sections[activeSectionIndex];
                    else
                        this.activeSection =
                            this.unorderedSections[activeSectionIndex];
                    return this.activeSection;
                }

                sendToOrganizer() {
                    if (
                        !this.quote ||
                        (this.quote.approveDate && !this.canResend)
                    )
                        return;

                    let resend = () => {
                        this.proceedingStatusService.setQuoted(
                            this.proceeding,
                            false,
                        );
                        this.quote.approveDate = new Date();
                        this.quote.cart.forEach((subQuote) => {
                            subQuote.response = null;
                        });

                        this.updateContent().then(() => {
                            this.notifier.send(
                                "success",
                                "Quote was sent to organizer for review.",
                            );
                            this.approveDate =
                                this.quote.approveDate.toLocaleString(); // set date string
                            this.emailService.sendToOrganizers(
                                this.proceeding,
                                "quote-advisor",
                                {
                                    data: {
                                        proceeding: this.proceeding,
                                        signatoryName: `${this.signatory.givenName} ${this.signatory.surname}`,
                                        quoteLink: this.quoteLink,
                                    },
                                },
                            );
                            this.canResend =
                                this.quote.approveDate &&
                                !(
                                    this.proceeding.docuSign &&
                                    this.proceeding.docuSign
                                        .internalSignatorySignDate
                                );
                            this.sendText = "Resend Quote to Organizer";
                        });
                    };

                    if (this.canResend) {
                        this.$uibModal
                            .open({
                                animation: true,
                                component:
                                    "cpirDashboardEditorPaConfirmResendQuote",
                            })
                            .result.then((ok) => {
                                console.log("resetting quote");
                                //delete extra data
                                this.proceedingStatusService.setQuoted(
                                    this.proceeding,
                                    false,
                                );
                                this.proceeding.docuSign = null;
                                this.quote.cart.forEach((cart) => {
                                    cart.accepted = false;
                                    cart.acceptDate = undefined;
                                });

                                //reset approval date
                                this.quote.approveDate = new Date();
                                this.approveDate =
                                    this.quote.approveDate.toLocaleString();
                                resend();
                            })
                            .catch((dismiss) => {});
                    } else {
                        resend();
                    }
                }

                updateProceeding(proceeding) {
                    this.proceeding = proceeding;
                }

                saveButtonClicked() {
                    this.updateContent().then(() =>
                        this.notifier.send(
                            "success",
                            "The publication agreement was successfully saved.",
                        ),
                    );
                }
            },
        });
})();
