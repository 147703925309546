(function() {
    "use strict";

    angular.module("cpir").component("cpirTocAddSectionDividerModal", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/add-section-divider-modal/add-section-divider-modal.component.html",
        controller: class {
            constructor(TOCValuesService) {
                this.TOCValuesService = TOCValuesService;
            }

            $onInit() {
                this.dividerTypes = this.TOCValuesService.typeOptions.filter(
                    o => o.class === "SD"
                );
                this.hasPdf = false;
                this.chair = {};
                this.chair.title = "Session Chair";
                this.type = this.dividerTypes[0].value;
            }

            saveButtonClicked() {
                this.close({
                    $value: {
                        title: this.title,
                        hasPdf: this.hasPdf,
                        chair: this.chair,
                        type: this.type
                    }
                });
            }

            cancelButtonClicked() {
                this.dismiss({ $value: "cancel" });
            }
        }
    });
})();
