(function() {
    angular.module("cpir").component("cpirComplianceSponsorSwitcher", {
        bindings: {
            sponsors: "<",
            activeSponsorIndex: "<",
            onSwitch: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/sponsors-panel/components/sponsor-switcher/sponsor-switcher.component.html",
        controller: class {
            buttonClicked($index) {
                this.onSwitch({ sponsorIndex: $index });
            }
        }
    });
})();
