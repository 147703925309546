(function() {
    "use strict";

    angular.module("cpir").filter("proceedingActionEnabledFilter", [
        "ProceedingStatusService",
        statusService => {
            return function(proceeding, action) {
                if (!proceeding) return false;
                if (!statusService.getStatus(proceeding)) return false;
                switch (action) {
                    case "continueCIF":
                        return (
                            !statusService.isInquiryStarted(proceeding) &&
                            proceeding
                        );
                    default:
                        return proceeding;
                }
            };
        }
    ]);
})();
