(function() {
    "use strict";

    angular
        .module("cpir")
        .component("cpirDashboardEditorPaConferenceInformation", {
            bindings: {
                title: "<",
                proceeding: "<",
                onUpdate: "&"
            },
            templateUrl:
                "application/cpir/dashboard/editor/publication-agreement/components/" +
                "conference-information/conference-information.component.html",
            controller: class {
                constructor() {}

                modelChanged() {
                    this.onUpdate({ proceeding: this.proceeding });
                }
            }
        });
})();
