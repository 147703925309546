(function() {
    "use strict";

    angular.module("cpir").controller(
        "AuthLoginController",
        class {
            constructor($state, authService, prefilledEmail) {
                this.$state = $state;
                this.authService = authService;
                this.prefilledEmail = prefilledEmail;
            }

            $onInit() {
                if (this.authService.getProfile()) {
                    return this.$state.go("dispatcher");
                }

                if (this.prefilledEmail) {
                    this.email = this.prefilledEmail;
                }

                this.loading = false;
                this.invalidCredentials = false;
            }

            login() {
                this.loading = true;
                this.authService.login();
            }

            logout() {
                this.loading = true;
                this.authService.logout();
            }

            listenForEnter($event) {
                if (this.loginForm.$valid && $event.which === 13) {
                    this.login();
                }
            }
        }
    );
})();
