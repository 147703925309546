(function() {
    "use strict";

    angular.module("cpir").provider(
        "ProceedingStatusService",
        class {
            $get($q, ProceedingService, $http, $timeout) {
                let steps = [
                    { name: "Inquiry Started", key: "inquiry-started" },
                    { name: "Inquiry Submitted", key: "inquiry-submitted" },
                    { name: "Quoted", key: "quoted" },
                    { name: "Quote Revision Requested", key: "quote-revise" },
                    { name: "Quote Declined", key: "quote-declined" },
                    { name: "Quote Accepted", key: "quote-accepted" },
                    { name: "Compliance Review", key: "compliance" },
                    {
                        name: "Compliance Submitted",
                        key: "compliance-submitted"
                    },
                    { name: "Compliance Rejected", key: "compliance-rej" },
                    {
                        name: "CPS Signature Pending",
                        key: "internal-signatory"
                    },
                    { name: "Preparing Author Kit", key: "ak-setup" },
                    { name: "Author Kit Review", key: "ak-pending" },
                    { name: "Author Kit Active", key: "ak-active" },
                    { name: "Production", key: "production" }
                ];

                let isAtLeastQuoted = p =>
                    !isInquiryStarted(p) && !isInquirySubmitted(p);
                let isAtLeastAccepted = p =>
                    isAtLeastQuoted(p) &&
                    !isQuoted(p) &&
                    !isQuoteDeclined(p) &&
                    !isQuoteRevisionRequested(p);
                let isAtLeastComplianceReview = p =>
                    isAtLeastAccepted(p) && !isQuoteAccepted(p);
                let isAtLeastComplianceSubmitted = p =>
                    isAtLeastComplianceReview(p) && !isComplianceReview(p);
                let isAtLeastInternalSignatory = p =>
                    isAtLeastComplianceSubmitted(p) &&
                    !isComplianceRejected(p) &&
                    !isComplianceSubmitted(p);
                let isAtLeastAkSetup = p =>
                    isAtLeastInternalSignatory(p) && !isInternalSignatory(p);
                let isAtLeastAkPending = p =>
                    isAtLeastAkSetup(p) && !isAkSetup(p);
                let isAtLeastAkActive = p =>
                    isAtLeastAkPending(p) && !isAkPending(p);
                let isAtLeastProduction = p =>
                    isAtLeastAkActive(p) && !isAkActive(p);

                let setInquiryStarted = (pid, updateDb) =>
                    updateProceeding(pid, "inquiry-started", updateDb);

                function setInquirySubmitted(pid, updateDb) {
                    return updateProceeding(pid, "inquiry-submitted", updateDb);
                }

                let isInquiryStarted = proceeding =>
                    getStatus(proceeding) === "inquiry-started";

                function isInquirySubmitted(proceeding) {
                    return getStatus(proceeding) === "inquiry-submitted";
                }

                function setQuoted(pid, updateDb) {
                    return updateProceeding(pid, "quoted", updateDb);
                }

                function isQuoted(proceeding) {
                    return getStatus(proceeding) === "quoted";
                }

                function setQuoteAccepted(pid, updateDb) {
                    return updateProceeding(pid, "quote-accepted", updateDb);
                }

                function isQuoteAccepted(proceeding) {
                    return getStatus(proceeding) === "quote-accepted";
                }

                function isQuoteRevisionRequested(proceeding) {
                    return getStatus(proceeding) === "quote-revise";
                }

                function setQuoteRevisionRequested(pid, updateDb) {
                    return updateProceeding(pid, "quote-revise", updateDb);
                }

                function setQuoteDeclined(pid, updateDb) {
                    return updateProceeding(pid, "quote-declined", updateDb);
                }

                function isQuoteDeclined(proceeding) {
                    return getStatus(proceeding) === "quote-declined";
                }

                function setComplianceReview(pid, updateDb) {
                    return updateProceeding(pid, "compliance", updateDb);
                }

                function isComplianceReview(proceeding) {
                    return getStatus(proceeding) === "compliance";
                }

                function setComplianceSubmitted(pid, updateDb) {
                    return updateProceeding(
                        pid,
                        "compliance-submitted",
                        updateDb
                    );
                }

                function isComplianceSubmitted(proceeding) {
                    return getStatus(proceeding) === "compliance-submitted";
                }

                function setComplianceRejected(pid, updateDb) {
                    return updateProceeding(pid, "compliance-rej", updateDb);
                }

                function isComplianceRejected(proceeding) {
                    return getStatus(proceeding) === "compliance-rej";
                }

                function setInternalSignatory(pid, updateDb) {
                    return updateProceeding(
                        pid,
                        "internal-signatory",
                        updateDb
                    );
                }

                function isInternalSignatory(proceeding) {
                    return getStatus(proceeding) === "internal-signatory";
                }

                function setAkSetup(pid, updateDb) {
                    return updateProceeding(pid, "ak-setup", updateDb);
                }

                function isAkSetup(proceeding) {
                    return getStatus(proceeding) === "ak-setup";
                }

                function setAkPending(pid, updateDb) {
                    return updateProceeding(pid, "ak-pending", updateDb);
                }

                function isAkPending(proceeding) {
                    return getStatus(proceeding) === "ak-pending";
                }

                function setAkActive(pid, updateDb) {
                    return updateProceeding(pid, "ak-active", updateDb);
                }

                function isAkActive(proceeding) {
                    return getStatus(proceeding) === "ak-active";
                }

                function setProduction(pid, updateDb) {
                    return updateProceeding(pid, "production", updateDb);
                }

                function isProduction(proceeding) {
                    return getStatus(proceeding) === "production";
                }

                function getSteps() {
                    return steps;
                }

                function getStatus(proceeding) {
                    if (!proceeding) return proceeding;
                    return proceeding.configuration.settings.steps.status;
                }

                function updateProceeding(pid, status, updateDb) {
                    if (!pid) return $q.reject("Proceeding or PID is empty.");
                    updateDb = updateDb === undefined ? true : updateDb;
                    let result;
                    const change = {
                        status: status,
                        date: new Date()
                    };
                    if (typeof pid === "string") {
                        result = ProceedingService.get(pid).then(proceeding => {
                            proceeding.configuration.settings.steps.changes.push(
                                change
                            );
                            const changes =
                                proceeding.configuration.settings.steps.changes;
                            proceeding.configuration.settings.steps.status = status;

                            return updateDb
                                ? ProceedingService.update(pid, {
                                      "configuration.settings.steps.status": status,
                                      "configuration.settings.steps.changes": changes
                                  })
                                : proceeding;
                        });
                    } else if (pid.configuration.settings.steps) {
                        let proceeding = pid;
                        proceeding.configuration.settings.steps.status = status;
                        proceeding.configuration.settings.steps.changes.push(
                            change
                        );
                        const changes =
                            proceeding.configuration.settings.steps.changes;
                        //console.log(proceeding);
                        result = proceeding;

                        if (updateDb) {
                            result = ProceedingService.update(proceeding.pid, {
                                "configuration.settings.steps.status": status,
                                "configuration.settings.steps.changes": changes
                            }).then(updateResult =>
                                $http
                                    .post("/dashboard/triggerRefresh", {})
                                    .then(() => updateResult)
                            );
                        } else {
                            $timeout(
                                () =>
                                    $http.post("/dashboard/triggerRefresh", {}),
                                5000
                            ); // give the app 5 seconds to save
                        }
                    } else {
                        throw Error("Not a valid pid or proceeding");
                    }
                    return $q.when(result);
                }

                return {
                    getSteps,
                    getStatus,
                    setInquiryStarted,
                    isInquiryStarted,
                    setInquirySubmitted,
                    isInquirySubmitted,
                    setQuoted,
                    isQuoted,
                    setQuoteAccepted,
                    isQuoteAccepted,
                    setQuoteRevisionRequested,
                    isQuoteRevisionRequested,
                    setQuoteDeclined,
                    isQuoteDeclined,
                    setComplianceReview,
                    isComplianceReview,
                    setComplianceRejected,
                    isComplianceRejected,
                    setComplianceSubmitted,
                    isComplianceSubmitted,
                    setInternalSignatory,
                    isInternalSignatory,
                    setAkSetup,
                    isAkSetup,
                    setAkPending,
                    isAkPending,
                    setAkActive,
                    isAkActive,
                    setProduction,
                    isProduction,
                    isAtLeastQuoted,
                    isAtLeastAccepted,
                    isAtLeastComplianceReview,
                    isAtLeastInternalSignatory,
                    isAtLeastAkSetup,
                    isAtLeastAkPending,
                    isAtLeastAkActive,
                    isAtLeastProduction,
                    isAtLeastComplianceSubmitted
                };
            }
        }
    );
})();
