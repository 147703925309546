(function() {
    "use strict";

    angular.module("cpir").filter("entryPagesPlusPaddingFilter", () => {
        function entryHasFile(entry, articlesByEntry) {
            return articlesByEntry[entry.eid];
        }

        return (entry, articlesByEntry) => {
            if (!entry || !articlesByEntry) return 0;
            if (!entry.pages) return 0;
            if (!entryHasFile(entry, articlesByEntry)) return 0;
            return entry.pagePadding
                ? parseInt(entry.pages) + parseInt(entry.pagePadding)
                : entry.pages;
        };
    });
})();
