(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifWorkshopsController",

        class {
            constructor(
                $state,
                $stateParams,
                $scope,
                authService,
                CifService,
                NotificationService,
                ProceedingService,
                TimerService
            ) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$scope = $scope;
                this.authService = authService;
                this.CifService = CifService;
                this.NotificationService = NotificationService;
                this.ProceedingService = ProceedingService;
                this.TimerService = TimerService;
            }

            $onInit() {
                this.isLoading = true;
                this.loadWaitAsync = this.TimerService.getLoadingTimer();
                this.pid = this.$stateParams.pid;

                this.configuration = this.CifService.getConfiguration();

                // Call the API to receive the proceeding
                this.ProceedingService.get(this.pid)
                    .then(proceeding => {
                        this.proceeding = proceeding;

                        // Check if workshops already exist in the proceeding object
                        if (
                            this.proceeding.workshops &&
                            this.proceeding.workshops.length
                        ) {
                            // Change the dates from text to JS
                            for (let workshop of this.proceeding.workshops) {
                                workshop.startDate = new Date(
                                    workshop.startDate
                                );
                                workshop.endDate = new Date(workshop.startDate);
                            }

                            // Assume the workshops returned from the API are valid
                            this.isActiveWorkshopValid = true;
                            this.loadWaitAsync.then(() => {
                                this.isLoading = false;
                                this.TimerService.applyTimer(this.$scope);
                            });
                        } else {
                            // Assume an empty workshop is invalid
                            this.isActiveWorkshopValid = false;
                        }

                        this.loadWaitAsync.then(() => {
                            this.isLoading = false;
                            this.TimerService.applyTimer(this.$scope);
                        });
                    })
                    .catch(() => {
                        this.NotificationService.send(
                            "warning",
                            "The proceeding in question could not be found."
                        );
                        this.loadWaitAsync.then(() => {
                            this.isLoading = false;
                            this.TimerService.applyTimer(this.$scope);
                        });
                    });
            }

            updateWorkshops(workshops, isValid) {
                this.proceeding.workshops = workshops;
                this.isActiveWorkshopValid = isValid;
            }

            preSaveCheck() {
                this.isLoading = true;
                this.TimerService.getLoadingTimer();
                this.CifService.breakoutWorkshops(this.proceeding).then(() => {
                    this.NotificationService.send(
                        "success",
                        "The workshop(s) were saved successfully."
                    );
                    this.goToNextState();
                });
            }

            isStateValid() {
                if (!this.configuration) {
                    return false;
                }
                return this.isActiveWorkshopValid;
            }

            goToPreviousState() {
                this.$state.go("cif.conference-update", { pid: this.pid });
            }

            goToNextState() {
                this.$state.go("cif.contacts", { pid: this.pid });
            }
        }
    );
})();
