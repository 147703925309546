(function () {
    "use strict";

    angular.module("cpir").factory("UserListService", [
        "$q",
        "apiService",
        "ContactService",
        function ($q, api, ContactService) {
            let userListService = {};

            // List all users by type
            userListService.list = ({
                type = 1,
                showArchivedUsers = false,
            }) => {
                const deferred = $q.defer();
                let query = `type=${type}${
                    showArchivedUsers ? "&showArchivedUsers=true" : ""
                }`;
                api.get(`user-lists?${query}`)
                    .then((result) => {
                        deferred.resolve(result.data.items);
                    })
                    .catch((error) => {
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            // Get a specific user
            userListService.get = (userId) => {
                return api.get(`user-lists/${userId}`);
            };

            // Update a user
            userListService.update = (userId, userList) => {
                let deferred = $q.defer();
                delete userList._id;
                api.put(`user-lists/${userId}`, userList).then(
                    (result) => {
                        deferred.resolve(result.data);
                    },
                    (error) => {
                        deferred.reject(error);
                    },
                );
                return deferred.promise;
            };

            // Create a new user
            userListService.create = (userList) => {
                let deferred = $q.defer();
                delete userList._id;
                api.post("user-lists", userList).then(
                    (result) => {
                        deferred.resolve(result.data);
                    },
                    (error) => {
                        deferred.reject(error);
                    },
                );
                return deferred.promise;
            };

            // Patch a user
            userListService.patch = (userId, fieldsToUpdate) => {
                let deferred = $q.defer();
                api.patch(`user-lists/${userId}`, fieldsToUpdate).then(
                    (result) => {
                        deferred.resolve(result.data);
                    },
                    (error) => {
                        deferred.reject(error);
                    },
                );
                return deferred.promise;
            };

            // Remove a user
            userListService.archive = (userId) => {
                return api.delete(`user-lists/${userId}`);
            };

            // Restore a user
            userListService.restore = (userId) => {
                return api.patch(`user-lists/${userId}`, { status: 1 });
            };

            userListService.getStrings = ({ type = 1 }) => {
                const deferred = $q.defer();
                let query = `type=${type}`;
                api.get(`user-lists/strings?${query}`).then(
                    (result) => {
                        deferred.resolve(result.data.items);
                    },
                    (error) => {
                        deferred.reject(error);
                    },
                );
                return deferred.promise;
            };

            function matchUser(u, c, field) {
                let hasEmailMatch = false;
                let hasNameMatch = false;
                let matchField = null;
                let matchType = null;
                let matchStrength = null;

                if (field === "members") {
                    hasNameMatch =
                        (u.givenName && u.givenName.toLowerCase().trim()) ===
                            (c.firstName && c.firstName.toLowerCase().trim()) &&
                        (u.surname && u.surname.toLowerCase().trim()) ===
                            (c.lastName && c.lastName.toLowerCase().trim());
                } else {
                    hasEmailMatch =
                        (u.email && u.email.toLowerCase().trim()) ===
                        (c.email && c.email.toLowerCase().trim());
                    hasNameMatch =
                        (u.givenName && u.givenName.toLowerCase().trim()) ===
                            (c.givenName && c.givenName.toLowerCase().trim()) &&
                        (u.surname && u.surname.toLowerCase().trim()) ===
                            (c.surname && c.surname.toLowerCase().trim());
                }

                if (hasEmailMatch && hasNameMatch) {
                    matchField = field;
                    matchType = "email and name";
                    matchStrength = "strong";
                } else if (hasEmailMatch) {
                    matchField = field;
                    matchType = "email";
                    matchStrength = "email";
                } else if (hasNameMatch) {
                    matchField = field;
                    matchType = "name";
                    matchStrength = "name";
                }

                return {
                    hasEmailMatch,
                    hasNameMatch,
                    matchField,
                    matchType,
                    matchStrength,
                };
            }

            userListService.getContactMatchesForProceeding = (proceeding) => {
                return Promise.join(
                    ContactService.listByProceeding(proceeding),
                    userListService.list({ type: 1 }),
                ).then(([contacts, userList]) => {
                    return _.flatMap(userList, (u) => {
                        const fields = ["contacts", "members", "sponsors"];
                        let matches = [];

                        contacts.forEach((c) => {
                            const match = matchUser(u, c, "contacts");

                            if (match.hasEmailMatch || match.hasNameMatch) {
                                matches.push({
                                    ...u,
                                    matchField: "inquiry contacts",
                                    matchType: match.matchType,
                                    matchStrength: match.matchStrength,
                                });
                            }
                        });

                        // Check for matches in compliance contacts
                        for (let field of fields) {
                            if (
                                proceeding.compliance &&
                                proceeding.compliance.submission &&
                                proceeding.compliance.submission[field]
                            ) {
                                proceeding.compliance.submission[field].forEach(
                                    (c) => {
                                        const match = matchUser(u, c, field);

                                        if (
                                            match.hasEmailMatch ||
                                            match.hasNameMatch
                                        ) {
                                            matches.push({
                                                ...u,
                                                matchField: `compliance ${field}`,
                                                matchType: match.matchType,
                                                matchStrength:
                                                    match.matchStrength,
                                            });
                                        }
                                    },
                                );
                            }
                        }

                        return matches;
                    });
                });
            };

            return userListService;
        },
    ]);
})();
