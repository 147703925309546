(function() {
    "use strict";

    angular.module("cpir").provider(
        "WebSocketService",
        class {
            $get(authService) {
                function openSocket(handler) {
                        const jwt = authService.getJWT();
                    const socket = io({
                                auth: { token: jwt }
                                // force polling - uncomment below options
                                // transports: ["polling"],
                                // upgrade: false
                            });
                        handler(socket);
                }

                function safeApply(scope, callback) {
                    if (
                        scope.$$phase !== "$apply" &&
                        scope.$$phase !== "$digest" &&
                        (!scope.$root ||
                            (scope.$root.$$phase !== "$apply" &&
                                scope.$root.$$phase !== "$digest"))
                    ) {
                        scope.$apply();
                    }
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                }

                return {
                    openSocket: openSocket,
                    safeApply: safeApply
                };
            }
        }
    );
})();
