(function() {
    "use strict";

    angular.module("cpir").component("cpirStateProvinceInput", {
        bindings: {
            country: "<",
            isDisabled: "<",
            province: "<",
            onUpdate: "&"
        },
        templateUrl:
            "application/cpir/_shared/components/state-province-input/state-province-input.component.html",
        controller: class {
            constructor(InternationalDataService) {
                this.InternationalDataService = InternationalDataService;
            }

            $onInit() {
                this.provinceOptions = this.InternationalDataService.getProvinces();

                this.countriesWithProvinces = this.InternationalDataService.getCountriesWithProvincesEnabled();
            }

            isState() {
                return this.countriesWithProvinces.find(
                    c => c === this.country
                );
            }

            isRequired() {
                return false;
            }

            modelChanged() {
                this.onUpdate({
                    province: this.province
                });
            }
        }
    });
})();
