(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparingPreparingPaperPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/preparing-paper-panel/preparing-paper-panel.component.html",
        controller: function() {
            let $ctrl = this;
        }
    });
})();
