(function () {
    "use strict";

    angular.module("cpir").provider(
        "authService",
        class {
            $get(
                $q,
                $state,
                store,
                angularAuth0,
                authManager,
                EnvironmentService,
                authConfiguration,
                ProfileService,
                Cpir2Service,
                $location,
                UserService,
                jwtHelper,
            ) {
                /*
                    Private helper method to get connection.
                     */
                function getConnection() {
                    let connection;

                    if (EnvironmentService.isProduction()) {
                        connection = getBrand().connection;
                    } else if (EnvironmentService.isDevelopmentOrTest()) {
                        connection = `${getBrand().connection}-TEST`;
                    } else {
                        throw "Could not initialize authentication connection.";
                    }

                    return connection;
                }

                /*
                     Attempt to login the client as requested.
                     */
                function login() {
                    angularAuth0.authorize({
                        connection: getConnection(),
                        prompt: "login",
                    });
                }

                /**
                 * Completes the logout after CPIR2 redirects back to the legacy app
                 */
                function checkForLogout() {
                    let { cpir2_logout } = $location.search();
                    if (cpir2_logout === "1") {
                        store.remove("id_token");
                        store.remove("profile");
                        store.remove("deepLink");
                        store.remove("access_token");

                        authManager.unauthenticate();

                        // remove cpir2_logout query param
                        $location.search("cpir2_logout", null);

                        Cpir2Service.forwardToCPIR2LandingPage();
                    }
                }

                /*
                Logout as requested.
                First log-out of CPIR2. CPIR2 will redirect back to complete the logout.
                See the checkForLogout function for how this is handled.
                */
                function logout() {
                    store.remove("id_token");
                    store.remove("profile");
                    store.remove("deepLink");
                    store.remove("access_token");

                    authManager.unauthenticate();

                    Cpir2Service.redirectToLogout();
                }

                /*
                     Handle the Auth0 callback and return token when successfully
                     authenticated.
                     */
                function authenticateAndGetProfile() {
                    const hashStr = window.location.hash.slice(2);
                    const isAuth0HashString =
                        hashStr.includes("access_token") &&
                        hashStr.includes("id_token");

                    if (isAuth0HashString) {
                        // Show loading page right away
                        $state.go("dispatcher", {}, { reload: true });

                        angularAuth0.parseHash(
                            {
                                hash: hashStr,
                                __enableIdPInitiatedLogin: true,
                            },
                            async (hashError, result) => {
                                if (hashError) {
                                    alert(
                                        "error: " +
                                            hashError.error +
                                            ". " +
                                            hashError.errorDescription,
                                    );
                                    console.error(hashError);
                                } else if (result && result.idToken) {
                                    // need to make extra call to get profile if using impersonation
                                    store.set("id_token", result.idToken);
                                    store.set(
                                        "access_token",
                                        result.accessToken,
                                    );
                                    authManager.authenticate();
                                    const profile = await ProfileService.get();
                                    store.set("profile", profile);
                                    // console.log("Got profile data: ", profile);

                                    // get or create user
                                    const user = await UserService.get(profile);
                                    store.set("user", user);
                                    // console.log("Got db user: ", user);

                                    $state.go(
                                        "dispatcher",
                                        {},
                                        { reload: true },
                                    );
                                }
                            },
                        );
                    }
                }

                /**
                 * Helper function for checking id tokens are expired
                 * @return {boolean}
                 */
                function isTokenExpired() {
                    let token = store.get("id_token");
                    let tokenExpired = false;

                    if (token) {
                        tokenExpired = jwtHelper.isTokenExpired(token);
                    }
                    if (!token || tokenExpired) {
                        // remove auth variables
                        store.remove("id_token");
                        store.remove("access_token");
                        store.remove("profile");
                        UserService.logout();
                        return true;
                    }
                    return false;
                }

                /**
                 * Helper function for getting the raw JWT.
                 * Attempting to fetch a missing or expired token will log the user out.
                 * @return {string|null}
                 */
                function getJWT() {
                    let token = store.get("id_token");
                    let tokenExpired = false;

                    if (token) {
                        tokenExpired = jwtHelper.isTokenExpired(token);
                    }
                    if (!token || tokenExpired) {
                        // remove auth variables
                        store.remove("id_token");
                        store.remove("access_token");
                        store.remove("profile");
                        UserService.logout();
                        return null;
                    }
                    return token;
                }

                /*
                     Return a JSON object of the the designated brand (CPR\PubHub).
                     */
                function getBrand() {
                    let brand = {};

                    let urlPath = window.location.href;

                    if (urlPath.toLowerCase().indexOf("pubhub") >= 1) {
                        brand.name = "pubhub";
                        brand.isPubHub = true;
                        brand.connection = "PUBHUB";
                    } else if (urlPath.toLowerCase().indexOf("cps") >= 1) {
                        brand.name = "cps";
                        brand.isCPS = true;
                        brand.connection = "CPS";
                    } else {
                        // default to CPS
                        brand.name = "cps";
                        brand.isCPS = true;
                        brand.connection = "CPS";
                        // throw "Could not initialize authentication env. (PUBHUB or CPS).";
                    }

                    return brand;
                }

                /*
                     Return the JSON object of the user's profile.
                     */
                function getProfile() {
                    return store.get("profile");
                }

                function getUser() {
                    return store.get("user");
                }

                function getConfiguration() {
                    return store.get("configuration");
                }

                function saveConfiguration(configuration) {
                    store.set("configuration", configuration);
                }

                function isEditor() {
                    const user = getUser();
                    return user && user.roles && user.roles.editor;
                }

                function canAccessUserLists() {
                    const user = getUser();
                    return user && user.roles && user.roles.userLists;
                }

                async function authenticateFromCpir2(token) {
                    store.set("id_token", token);
                    // You might also want to update other related data, such as setting the token as authenticated
                    authManager.authenticate();
                    const profile = await ProfileService.get();
                    store.set("profile", profile);
                    console.log("Got profile data: ", profile);

                    // get or create user
                    const user = await UserService.get(profile);
                    store.set("user", user);
                    console.log("Got db user: ", user);

                    // reload the page to get the correct state
                    $state.go($state.current, {}, { reload: true });
                }

                return {
                    login,
                    logout,
                    getProfile,
                    getUser,
                    isEditor,
                    canAccessUserLists,
                    authenticateAndGetProfile,
                    getBrand,
                    getConfiguration,
                    saveConfiguration,
                    checkForLogout,
                    isTokenExpired,
                    getJWT,
                    authenticateFromCpir2,
                };
            }
        },
    );
})();
