(function() {
    "use strict";

    angular.module("cpir").component("tocWebPub", {
        bindings: {
            close: "&",
            dismiss: "&",
            size: "@",
            resolve: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/webpub/webpub.component.html",
        controller: class {
            constructor(
                WebpubService,
                WebSocketService,
                $scope,
                NotificationService,
                ProceedingSearchService,
                TocEntriesApiService,
                ConfigurationService,
                authService,
                InternationalDataService,
                moment,
                Hogan,
                ProceedingService,
                EnvironmentService,
                $uibModal
            ) {
                this.WebpubService = WebpubService;
                this.WebSocketService = WebSocketService;
                this.$scope = $scope;
                this.NotificationService = NotificationService;
                this.ProceedingSearchService = ProceedingSearchService;
                this.TocEntriesApiService = TocEntriesApiService;
                this.ConfigurationService = ConfigurationService;
                this.authService = authService;
                this.InternationalDataService = InternationalDataService;
                this.moment = moment;
                this.Hogan = Hogan;
                this.ProceedingService = ProceedingService;
                this.EnvironmentService = EnvironmentService;
                this.$uibModal = $uibModal;

                this.onSearch = this.doFilter = _.debounce(
                    this._onSearch,
                    300,
                    {
                        maxWait: 1000
                    }
                );
                this.enabledDisabledOptions = [
                    { label: "Enabled", value: true },
                    { label: "Disabled", value: false }
                ];

                this.yesNoOptions = [
                    { label: "Yes", value: true },
                    { label: "No", value: false }
                ];

                this.onOffOptions = [
                    { label: "On", value: true },
                    { label: "Off", value: false }
                ];

                this.webpubTypeOptions = [
                    { label: "Webpub (FTP Hosted)", value: "webpub" },
                    { label: "Editorial Only", value: "e-only" }
                ];

                this.videoPlayerOptions = [
                    {
                        label: "Embedded Video Player (recommended)",
                        value: "mediaelement"
                    },
                    { label: "Browser Default", value: "default" },
                    { label: "Download Link", value: "link" }
                ];
            }

            $onInit() {
                this.webpubForm = null;
                this.isProduction = this.EnvironmentService.isProduction();
                this.activeTab = 0;
                this.loginOptions = this.yesNoOptions;
                this.loadingLoginInfo = true;
                this.loadingConfiguration = true;
                // get the last webpub from the list of webpubs

                this.webpub = this.getLastWebpubBuild(this.resolve.proceeding);

                this.brand = this.authService.getBrand().name;

                // websocket setup
                this._setUpWebpubWebSocketListener();

                // initialize the options
                this.initializeWebpubOptions();

                // load the configuration
                this.loadTemplates();

                // load the login information
                this.loadLoginInformation();

                // initialize search
                this.initializeSearch();
            }

            loadTemplates() {
                if (!this.options) {
                    throw new Error(
                        "Options not initialized before loading configuration"
                    );
                }

                // load the start page html template if it hasn't been set yet
                if (!this.options.startPageHtml) {
                    this.ConfigurationService.get().then(result => {
                        // set the configuration based on the brand
                        let configuration;
                        if (this.brand === "cps")
                            configuration = result.configuration.cps;
                        else if (this.brand === "pubhub")
                            configuration = result.configuration.pubhub;

                        const startPageTemplate = configuration.settings.webpub.webpubTemplates.find(
                            t => t.key === "start-page"
                        );

                        this.options.startPageHtml = startPageTemplate.content;

                        this.renderStartPage();

                        this.loadingConfiguration = false;
                        this.WebSocketService.safeApply(this.$scope);
                    });
                } else {
                    this.loadingConfiguration = false;
                }
            }

            startPageHtmlChanged(content) {
                this.renderStartPage(content);
            }

            renderStartPage(newContent) {
                // format the start and end date
                const proceeding = this.resolve.proceeding;
                const startDate = this.moment.utc(proceeding.dates.start);
                const endDate = this.moment.utc(proceeding.dates.end);

                let dateString;
                if (startDate.isSame(endDate, "month")) {
                    dateString =
                        startDate.format("D") +
                        " - " +
                        endDate.format("D MMMM YYYY");
                } else {
                    dateString =
                        startDate.format("D MMMM") +
                        " - " +
                        endDate.format("D MMMM YYYY");
                }

                // compile and render the start page content
                let startPageContent =
                    newContent || this.options.startPageHtml || "";
                const templateVariable = /\[\[([_a-zA-Z0-9.#/]+)]]/g;
                startPageContent = startPageContent.replace(
                    templateVariable,
                    "{{$1}}"
                );

                const formattedSelectedConferences = this.selectedConferences.map(
                    c => ({
                        ...c,
                        selectedConferenceBmsPartNumber:
                            c.toc && c.toc.bmsPartNumber,
                        selectedConferenceIsbn:
                            c.toc && (c.toc.isbn13 || c.toc.isbn),
                        selectedConferenceIssn: c.toc && c.toc.issn
                    })
                );

                this.startPageContentRendered = this.Hogan.compile(
                    startPageContent
                ).render({
                    proceeding,
                    province:
                        proceeding.location &&
                        this.InternationalDataService.expandProvince(
                            proceeding.location.province || ""
                        ),
                    country:
                        (proceeding.location &&
                            this.InternationalDataService.expandCountry(
                                proceeding.location.country
                            )) ||
                        "",
                    dateString,
                    year: new Date().getFullYear(),
                    isbn:
                        (proceeding.toc &&
                            (proceeding.toc.isbn13 || proceeding.toc.isbn)) ||
                        "",
                    selectedConferences: formattedSelectedConferences
                });
            }

            initializeSearch() {
                // create the index
                this.index = this.ProceedingSearchService.makeIndex(
                    this.resolve.proceedings
                );

                // initialize the search results array
                this.searchResults = [];

                // load any previously merged conferences into the default selected conferences
                this.selectedConferences = this.resolve.proceedings.filter(p =>
                    this.options.selectedConferences.find(
                        ({ pid }) => pid === p.pid
                    )
                );
            }

            loadLoginInformation() {
                this.WebpubService.getLoginInfo(
                    this.resolve.proceeding,
                    this.options
                ).then(loginInfo => {
                    this.loadingLoginInfo = false;

                    // if the webpub type is e-only set the login option to no
                    if (this.options.webpubType === "e-only") {
                        this.options.enableLogin = false;
                    }

                    this.login = loginInfo;
                    // convert the opendate to a date object
                    if (this.login.opendate) {
                        this.login.opendate = new Date(this.login.opendate);
                    }
                    this.WebSocketService.safeApply(this.$scope);
                });
            }

            initializeWebpubOptions() {
                const { webpubBuildOptions } = this.resolve.proceeding;
                if (!webpubBuildOptions || !webpubBuildOptions.initialized) {
                    this.options = {
                        ...this.WebpubService.getWebpubDefaults(),
                        ...webpubBuildOptions // override the defaults with any existing options
                    };
                } else {
                    this.options = this.resolve.proceeding.webpubBuildOptions;
                }
                // if the webpub type is e-only, remove the login options
                if (this.options.webpubType === "e-only") {
                    this.loginOptions = this.loginOptions.filter(
                        o => o.value === false
                    );
                }

                // set the default webpub name
                const { acronym } = this.resolve.proceeding;
                if (!this.options.webpubName) {
                    this.options.webpubName = `${acronym.toLowerCase()}pub`;
                }
            }

            clearSearch() {
                this.searchResults = [];
                this.query = "";
            }

            addConference(proceeding) {
                this.selectedConferences.push(proceeding);
                this._onSearch();
                this.renderStartPage();
            }

            removeConference(proceeding) {
                const indexToRemove = this.selectedConferences.findIndex(
                    p => p.pid === proceeding.pid
                );
                this.selectedConferences.splice(indexToRemove, 1);
                this._onSearch();
                this.renderStartPage();
            }

            _onSearch() {
                const queryTokens = this.index.tokenizeAndStem(this.query);
                if (queryTokens.length > 0) {
                    this.searchResults = this.index
                        .search(this.query)
                        .filter(
                            // don't add results already in the search results list
                            r =>
                                !this.selectedConferences.find(
                                    ({ pid }) => r.pid === pid
                                )
                        )
                        .slice(0, 20);
                } else {
                    this.searchResults = [];
                }
                this.WebSocketService.safeApply(this.$scope);
            }

            buildButtonClicked() {
                this.buildStarted = true;

                // Update the login info if the webpub type is webpub
                const updateLoginInfoAsync =
                    this.options.webpubType === "webpub"
                        ? this.updateLoginInfo()
                        : Promise.resolve();

                return updateLoginInfoAsync
                    .then(() =>
                        this.WebpubService.build(
                            this.resolve.proceeding.pid,
                            this.selectedConferences,
                            this.options
                        )
                    )

                    .then(() => {
                        // reload the proceeding
                        return this.ProceedingService.get(
                            this.resolve.proceeding.pid
                        );
                    })
                    .then(proceeding => {
                        // turn off the loading indicator
                        this.buildStarted = false;

                        // update the last webpub build
                        this.resolve.proceeding = proceeding;
                        this.webpub = this.getLastWebpubBuild(proceeding);

                        // set the active tab to the webpub build tab
                        this.activeTab = 2;
                        this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        this.buildStarted = false;
                        this.NotificationService.send("danger", err.message);
                        console.error(err);
                        this.dismiss({ $value: 0 });
                    });
            }

            cancelButtonClicked() {
                this.dismiss({ $value: 0 });
            }

            removeBannerImage() {
                this.options.bannerImage = null;
            }

            updateLoginInfo() {
                return this.WebpubService.updateLoginInfo(
                    this.resolve.proceeding,
                    this.options,
                    this.login
                );
            }

            getEditorialAccessLink() {
                return this.WebpubService.getEditorialAccessLink(
                    this.resolve.proceeding.pid
                );
            }

            _setUpWebpubWebSocketListener() {
                // handle the cleanup upon leaving the page
                this.$scope.$on("$destroy", () => {
                    this.client && this.client.disconnect();
                    console.log("disconnecting from webpub websocket");
                });

                //start websocket
                this.TocEntriesApiService.startSocket(
                    this.resolve.proceeding.pid,
                    false
                ).then(client => {
                    console.log("Connected to webpub-status websocket");
                    this.client = client;

                    client.on("proceeding.updated", proceeding => {
                        console.log("websocket status updated");
                        this.resolve.proceeding = proceeding;
                        this.webpub = this.getLastWebpubBuild(proceeding);
                        this.WebSocketService.safeApply(this.$scope);
                    });
                });
            }

            webpubTypeChanged() {
                // if the webpub type is e-only, remove the login options
                if (this.options.webpubType === "e-only") {
                    this.loginOptions = this.loginOptions.filter(
                        o => o.value === false
                    );
                    // set the login option to no
                    this.options.enableLogin = false;
                } else {
                    this.loginOptions = this.yesNoOptions;
                    // set the login option to yes
                    this.options.enableLogin = true;
                }
            }

            isWebpubNameUnchanged() {
                const webpubNameHasChanged =
                    this.webpub &&
                    this.webpub.buildOptions &&
                    this.webpub.buildOptions.webpubName !==
                        this.options.webpubName;
                return !webpubNameHasChanged;
            }

            // provide a wrapper to display a notification
            updateLoginInfoClicked() {
                // remove the extra properties from the webpub object before updating the login info
                if (this.webpub) {
                    delete this.webpub.strings;
                    delete this.webpub.loginInfo;
                }

                this.updateLoginInfo()
                    .then(() =>
                        this.ProceedingService.get(this.resolve.proceeding.pid)
                    )
                    .then(proceeding => {
                        this.resolve.proceeding = proceeding;
                        this.webpub = this.getLastWebpubBuild(proceeding);
                        this.NotificationService.send(
                            "success",
                            "Login information updated successfully"
                        );

                        this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        this.NotificationService.send("danger", err.message);
                        console.error(err);
                    });
            }

            updateEOnlyAccessClicked() {
                // remove the extra properties from the webpub object before updating the login info
                if (this.webpub) {
                    delete this.webpub.strings;
                    delete this.webpub.loginInfo;
                }

                this.WebpubService.updateEOnlyAccess(
                    this.resolve.proceeding,
                    this.options.enableEditorialOnlyDownload
                )
                    .then(() =>
                        this.ProceedingService.get(this.resolve.proceeding.pid)
                    )
                    .then(proceeding => {
                        this.resolve.proceeding = proceeding;
                        this.webpub = this.getLastWebpubBuild(proceeding);
                        this.NotificationService.send(
                            "success",
                            "Editorial only access updated successfully"
                        );
                        this.WebSocketService.safeApply(this.$scope);
                    })
                    .catch(err => {
                        this.NotificationService.send("danger", err.message);
                        console.error(err);
                    });
            }

            uploadWebpubToFTP(webpub) {
                // open a modal to confirm the upload
                const isConfirmedAsync = this.$uibModal
                    .open({
                        component: "cpirConfirmationModal",
                        resolve: {
                            title: () => "Copy Webpub to FTP",
                            message: () =>
                                `Are you sure you want to upload the webpub to the FTP server?<br /><br /> 
This will overwrite any files modified on the FTP server since the last webpub build.<br />
Rebuild the webpub first if you need to incorporate any new changes made since the last build.`
                        }
                    })
                    .result.then(() => {
                        return true;
                    })
                    .catch(_ => {
                        return false;
                    });

                // check if the user confirmed the upload
                return isConfirmedAsync.then(isConfirmed => {
                    if (!isConfirmed) return;
                    this.uploadingToFTP = true;
                    const pid = this.resolve.proceeding.pid;
                    const key = webpub.key;
                    const webpubName = this.options.webpubName;

                    this.WebpubService.ftpUpload(pid, key, webpubName)
                        .then(() => {
                            this.NotificationService.send(
                                "success",
                                "Webpub uploaded successfully to FTP server."
                            );
                            this.uploadingToFTP = false;
                            this.WebSocketService.safeApply(this.$scope);
                        })
                        .catch(err => {
                            this.uploadingToFTP = false;
                            this.NotificationService.send(
                                "danger",
                                err.message
                            );
                            console.error(err);
                            this.WebSocketService.safeApply(this.$scope);
                        });
                });
            }

            getLastWebpubBuild(proceeding) {
                if (
                    proceeding &&
                    proceeding.toc.webpubKeys &&
                    proceeding.toc.webpubKeys.length > 0
                ) {
                    const lastWebpubBuild = proceeding.toc.webpubKeys.slice(
                        -1
                    )[0];

                    if (!lastWebpubBuild || !lastWebpubBuild.buildOptions)
                        return null;

                    // add the login info
                    if (lastWebpubBuild.buildOptions.enableLogin) {
                        this.WebpubService.getLoginInfo(
                            proceeding,
                            lastWebpubBuild.buildOptions,
                            false
                        ).then(loginInfo => {
                            lastWebpubBuild.loginInfo = loginInfo;
                            this.WebSocketService.safeApply(this.$scope);
                        });
                    }

                    // string formatting for the UI
                    lastWebpubBuild.strings = {
                        webpubType: this.webpubTypeOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions.webpubType
                        ).label,
                        webpubUrl:
                            lastWebpubBuild.buildOptions.webpubType === "webpub"
                                ? `https://conferences${
                                      this.EnvironmentService.isProduction()
                                          ? ""
                                          : "-test"
                                  }.computer.org/${
                                      lastWebpubBuild.buildOptions.webpubName
                                  }`
                                : "",
                        enableWebpubDownload: this.enabledDisabledOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions
                                    .enableWebpubDownload
                        ).label,
                        enableAffiliationIndex: this.enabledDisabledOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions
                                    .enableAffiliationIndex
                        ).label,
                        exportSupplementalFiles: this.yesNoOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions
                                    .exportSupplementalFiles
                        ).label,
                        videoPlayer: this.videoPlayerOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions.videoPlayer
                        ).label,
                        enableLogin: this.yesNoOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions.enableLogin
                        ).label,
                        enableEditorialOnlyDownload: this.enabledDisabledOptions.find(
                            o =>
                                o.value ===
                                lastWebpubBuild.buildOptions
                                    .enableEditorialOnlyDownload
                        ).label
                    };

                    return lastWebpubBuild;
                } else {
                    return null;
                }
            }
        }
    });
})();
