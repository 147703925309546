(function () {
    "use strict";

    angular.module("cpir").component("cpirTocColumnCustomizationModal", {
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/modals/column-customization-modal/column-customization-modal.html",
        bindings: {
            resolve: "<",
            close: "&",
            dismiss: "&",
        },
        controller: class {
            constructor($scope, ColumnSettingsService) {
                this.$scope = $scope;
                this.ColumnSettingsService = ColumnSettingsService;
                this.selectedColumn = null;
            }

            async $onInit() {
                // Get proceeding ID from resolve if provided
                this.pid = this.resolve && this.resolve.pid;
                if (!this.pid) {
                    console.error(
                        "Proceeding ID not provided to column customization modal",
                    );
                    return;
                }

                // Define all available columns with their display names
                const defaultColumns = [
                    { id: "type", name: "Type" },
                    { id: "title", name: "Title" },
                    { id: "pages", name: "Pages" },
                    { id: "pageNumber", name: "Page #" },
                    { id: "version", name: "Version" },
                    { id: "file", name: "File Size" },
                    { id: "stamp", name: "Stamp Size" },
                    { id: "supplement", name: "Supplement Size" },
                    { id: "pitstop", name: "Pitstop" },
                    { id: "productionStatus", name: "Status" },
                    { id: "promo", name: "Promo" },
                    { id: "paperId", name: "Paper ID" },
                    { id: "similarity", name: "Similarity" },
                    { id: "copyrightType", name: "Copyright" },
                    { id: "authors", name: "Authors" },
                    { id: "doi", name: "DOI" },
                ];

                try {
                    // Load saved column settings for this proceeding
                    const savedSettings =
                        await this.ColumnSettingsService.getSettings(this.pid);

                    // Create columns array maintaining saved order and visibility
                    this.columns = savedSettings.order.map((id) => {
                        const defaultColumn = defaultColumns.find(
                            (col) => col.id === id,
                        );
                        return {
                            ...defaultColumn,
                            visible:
                                savedSettings.visibility[id] !== undefined
                                    ? savedSettings.visibility[id]
                                    : true,
                        };
                    });

                    // Set the fixed column from saved settings
                    this.fixedColumnId = savedSettings.fixedColumnId || "";

                    // Add any new columns that weren't in the saved order
                    defaultColumns.forEach((defaultCol) => {
                        if (
                            !this.columns.find(
                                (col) => col.id === defaultCol.id,
                            )
                        ) {
                            this.columns.push({
                                ...defaultCol,
                                visible:
                                    savedSettings.visibility[defaultCol.id] !==
                                    undefined
                                        ? savedSettings.visibility[
                                              defaultCol.id
                                          ]
                                        : true,
                            });
                        }
                    });

                    // Force scope update since we're using async
                    this.$scope.$apply();
                } catch (error) {
                    console.error("Error loading column settings:", error);
                    // Fall back to default columns if error
                    this.columns = defaultColumns.map((col) => ({
                        ...col,
                        visible: true,
                    }));
                    this.fixedColumnId = "";
                }
            }

            onDrop(event, index, item) {
                if (this.columns[index] === item) {
                    return false;
                }

                const currentIndex = this.columns.findIndex(
                    (col) => col.id === item.id,
                );

                if (currentIndex > -1) {
                    this.columns.splice(currentIndex, 1);
                }

                this.columns.splice(index, 0, item);

                if (!this.$scope.$$phase) {
                    this.$scope.$apply();
                }

                return true;
            }

            onMoved(index) {
                return true;
            }

            async save() {
                // Validate: if fixed column is selected, it must be visible
                if (this.fixedColumnId) {
                    const fixedColumn = this.columns.find(
                        (col) => col.id === this.fixedColumnId,
                    );
                    if (fixedColumn && !fixedColumn.visible) {
                        fixedColumn.visible = true;
                    }
                }

                // Create settings object
                const settings = {
                    visibility: {},
                    order: this.columns.map((col) => col.id),
                    fixedColumnId: this.fixedColumnId,
                };

                this.columns.forEach((col) => {
                    settings.visibility[col.id] = col.visible;
                });

                try {
                    // Save to IndexedDB
                    await this.ColumnSettingsService.saveSettings(
                        this.pid,
                        settings,
                    );
                    this.close({ $value: settings });
                } catch (error) {
                    console.error("Error saving column settings:", error);
                    // Handle error appropriately
                }
            }

            cancel() {
                this.dismiss();
            }

            selectAll() {
                this.columns.forEach((col) => (col.visible = true));
            }

            deselectAll() {
                // Don't hide the fixed column if one is selected
                this.columns.forEach((col) => {
                    col.visible = this.fixedColumnId === col.id;
                });
            }
        },
    });
})();
