(function() {
    "use strict";

    angular.module("cpir").provider(
        "OrganizerQuoteService",
        class {
            $get(Hogan, moment) {
                let compilePaSections = (paSections, data) => {
                    // RegEx to match [[ and ]]
                    let frontDelimiter = /(\[\[)/g;
                    let backDelimiter = /(\]\])/g;

                    //get some extra dates:
                    data.submissionSiteApprovedDate = data.proceeding.productionSchedule.find(
                        dates =>
                            dates.enabled &&
                            (dates.id === "submissionSiteApproval" || // new id
                                dates.id === "submissionSiteApproved") // deprecated old id
                    );
                    data.submissionSiteApprovedDate =
                        (data.submissionSiteApprovedDate &&
                            data.submissionSiteApprovedDate.date &&
                            moment(data.submissionSiteApprovedDate.date).format(
                                "MMMM Do YYYY"
                            )) ||
                        "[DATE NOT PROVIDED]";
                    data.mediaDueDate = data.proceeding.productionSchedule.find(
                        dates => dates.enabled && dates.id === "mediaDue"
                    );
                    data.mediaDueDate =
                        (data.mediaDueDate &&
                            data.mediaDueDate.date &&
                            moment(data.mediaDueDate.date).format(
                                "MMMM Do YYYY"
                            )) ||
                        "[DATE NOT PROVIDED]";

                    // Compile each template
                    paSections
                        .filter(s => s.content && s.content.text)
                        .map(s => {
                            // Change editor delimiter to be compatible
                            // with Mustache
                            s.content.text = s.content.text
                                .replace(frontDelimiter, "{{")
                                .replace(backDelimiter, "}}");

                            // Compile the template
                            s.content.text = Hogan.compile(
                                s.content.text
                            ).render(data);
                        });

                    return paSections;
                };

                return {
                    compilePaSections
                };
            }
        }
    );
})();
