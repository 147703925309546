/**
 * Client side full text search for proceedings
 */
(function() {
    "use strict";

    // hyperparameters
    const TITLE_EDIT_DISTANCE = 2;
    const REMOVE_TITLE_STOP_WORDS = true;
    const STEM_TITLE = true;
    const TITLE_BOOST = 1;

    const FILENAME_EDIT_DISTANCE = 2;
    const REMOVE_FILENAME_STOP_WORDS = false;
    const STEM_FILENAME = false;
    const FILENAME_BOOST = 1;

    const SPEAKERS_EDIT_DISTANCE = 2;
    const REMOVE_SPEAKERS_STOP_WORDS = false;
    const STEM_SPEAKERS = false;
    const SPEAKERS_BOOST = 2;

    const SESSION_EDIT_DISTANCE = 2;
    const REMOVE_SESSION_STOP_WORDS = true;
    const STEM_SESSION = true;
    const SESSION_BOOST = 1;

    angular.module("cpir").provider(
        "VideoSearchService",
        class {
            $get(SearchUtilsService) {
                const { Matcher, SearchFactory } = SearchUtilsService;

                function makeIndex(
                    videos,
                    getTitleString,
                    getFilenameString,
                    getSpeakersString,
                    getSessionString
                ) {
                    // matchers defined here
                    const matchers = [
                        new Matcher({
                            termResolver: getTitleString,
                            boost: TITLE_BOOST,
                            maxEditDistance: TITLE_EDIT_DISTANCE,
                            removeStopwords: REMOVE_TITLE_STOP_WORDS,
                            stem: STEM_TITLE,
                            label: "title"
                        }),
                        new Matcher({
                            termResolver: getFilenameString,
                            boost: FILENAME_BOOST,
                            maxEditDistance: FILENAME_EDIT_DISTANCE,
                            removeStopwords: REMOVE_FILENAME_STOP_WORDS,
                            stem: STEM_FILENAME,
                            label: "title"
                        }),
                        new Matcher({
                            termResolver: getSpeakersString,
                            boost: SPEAKERS_BOOST,
                            maxEditDistance: SPEAKERS_EDIT_DISTANCE,
                            removeStopwords: REMOVE_SPEAKERS_STOP_WORDS,
                            stem: STEM_SPEAKERS,
                            label: "speakers"
                        }),
                        new Matcher({
                            termResolver: getSessionString,
                            boost: SESSION_BOOST,
                            maxEditDistance: SESSION_EDIT_DISTANCE,
                            removeStopwords: REMOVE_SESSION_STOP_WORDS,
                            stem: STEM_SESSION,
                            label: "session"
                        })
                    ];

                    // filters defined here
                    const filters = [];

                    const searchInstance = new SearchFactory({
                        documents: videos,
                        keyResolver: "videoId",
                        matchers,
                        filters
                    });

                    return {
                        search: term =>
                            _.map(searchInstance.search(term), "document"),

                        tokenizeAndStem: function(query) {
                            return SearchFactory.tokenizeAndStem(
                                query,
                                false,
                                false,
                                true
                            ).tokens();
                        }
                    };
                }

                return {
                    makeIndex
                };
            }
        }
    );
})();
