(function() {
    "use strict";

    angular
        .module("cpir")
        .directive("authTemplate", function($compile, DateFixerService) {
            var link = (scope, element, attr) => {
                scope.$watch("proceeding", () => {
                    if (scope.proceeding) {
                        DateFixerService.changeToString(
                            scope.proceeding,
                            "full"
                        );

                        // RegEx to match [[ and ]]
                        let front = /(\[\[)/g;
                        let back = /(\]\])/g;

                        let template = scope.proceeding.ak.login.instructions
                            .replace(front, "{{")
                            .replace(back, "}}");

                        let compiledTemplate = $compile(template)(scope);

                        element.append(compiledTemplate);
                    }
                });
            };
            return {
                restrict: "E",
                link: link,
                scope: {
                    proceeding: "<"
                }
            };
        });
})();
