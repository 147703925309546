(function() {
    "use strict";

    angular.module("cpir").provider(
        "LambdaService",
        class {
            $get($http) {
                const lambdaSettings = $http
                    .get("/lambda/config")
                    .then(r => r.data)
                    .catch(err => console.error(err));

                async function getBaseURL() {
                    const { proto, host } = await lambdaSettings;
                    return `${proto}://${host}`;
                }

                async function getPublicIp() {
                    return $http
                        .get(`${await getBaseURL()}/utils/get-ip`)
                        .then(r => r.data.ip)
                        .catch(err => {
                            console.error(err);
                            throw err;
                        });
                }

                return {
                    getPublicIp
                };
            }
        }
    );
})();
