(function() {
    "use strict";

    angular.module("cpir").component("cpirStateProvinceLabelText", {
        bindings: {
            country: "<"
        },
        templateUrl:
            "application/cpir/_shared/components/state-province-label-text/state-province-label-text.component.html",
        controller: class {
            constructor() {}

            $onInit() {}

            isState() {
                return this.country === "US";
            }

            isProvince() {
                return !this.isState();
            }
        }
    });
})();
