(function() {
    "use strict";

    angular.module("cpir").filter("entryPageNumberFilter", () => {
        return entry => {
            if (!entry) return entry;
            if (!entry.pageNumber) return entry.pageNumber;
            if (entry.isPageNumberRoman) {
                return toRoman(entry.pageNumber);
            } else {
                return entry.pageNumber;
            }
        };
    });

    function toRoman(num) {
        var result = "";
        var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        var roman = [
            "m",
            "cm",
            "d",
            "cd",
            "c",
            "xc",
            "l",
            "xl",
            "x",
            "ix",
            "v",
            "iv",
            "i"
        ];
        for (var i = 0; i <= decimal.length; i++) {
            while (num % decimal[i] < num) {
                result += roman[i];
                num -= decimal[i];
            }
        }
        return result;
    }
})();
