(function() {
    "use strict";

    angular.module("cpir").provider(
        "ItextService",
        class {
            $get($http, $window, $q, WebSocketService, $httpParamSerializer) {
                function catchError(err) {
                    console.log(err.data);
                    throw err.data;
                }

                function getData(result) {
                    return result.data;
                }

                function countPages(pid, fid, vid) {
                    return $http
                        .get(`/itext/pagecount/${pid}/${fid}/${vid}`)
                        .catch(catchError)
                        .then(getData);
                }

                function countAllPages(pid) {
                    return $http
                        .put(`/toc/${pid}/pagecount`)
                        .catch(catchError)
                        .then(getData);
                }

                function stamp(pid, entryIds, options) {
                    return $http
                        .post(`/itext/stamp/${pid}`, { entryIds, options })
                        .catch(catchError)
                        .then(getData);
                }

                function generateTOC(pid) {
                    return $http
                        .post(`/itext/toc/${pid}`)
                        .catch(catchError)
                        .then(getData);
                }

                function generateOAForm(
                    pid,
                    entryId,
                    signature,
                    formType,
                    avRelease
                ) {
                    return $http
                        .post(`/itext/oa-form/${pid}/entry/${entryId}`, {
                            signature,
                            formType,
                            avRelease
                        })
                        .catch(catchError)
                        .then(getData);
                }

                function generateVideoReleaseForm(pid, data) {
                    return $http
                        .post(`/itext/video-release/${pid}`, data)
                        .catch(catchError)
                        .then(getData);
                }

                function generateAuthorIndex(pid) {
                    return $http
                        .post(`/itext/index/${pid}`)
                        .catch(catchError)
                        .then(getData);
                }

                function generateSessionDivider(pid, eid) {
                    return $http
                        .post(`/itext/session-divider/${pid}/entry/${eid}`)
                        .then(getData);
                }

                function batchGenerateSessionDividers(
                    pid,
                    entryIds,
                    idx,
                    promises
                ) {
                    if (idx === undefined)
                        return batchGenerateSessionDividers(
                            pid,
                            entryIds,
                            0,
                            []
                        );
                    if (idx >= entryIds.length) {
                        return $q.all(promises);
                    }
                    return generateSessionDivider(pid, entryIds[idx]).then(
                        result => {
                            promises.push(result);
                            return batchGenerateSessionDividers(
                                pid,
                                entryIds,
                                ++idx,
                                promises
                            );
                        }
                    );
                }

                function buildBook(pid) {
                    let deferred = $q.defer();
                    WebSocketService.openSocket(client => {
                        client.on("error", err => {
                            console.error(err);
                            client.disconnect();
                        });
                        client.on("book-timeout", () => {
                            console.error("download timeout");
                            client.downloadTimeout = true;
                            client.disconnect();
                        });
                        client.on("book-failed", err => {
                            console.error("build book failed: ", err.message);
                            client.lastError = err.message;
                            client.disconnect();
                        });
                        client.on("download-finished", () => {
                            console.log("download complete");
                            client.disconnectHandler =
                                client.defaultDisconnectHandler;
                            client.disconnect();
                        });

                        client.defaultDisconnectHandler = () => {};

                        client.emit("itext.build-book", pid);

                        deferred.resolve(client);
                    });
                    return deferred.promise;
                }

                function downloadBook(key, year, acronym) {
                    $window.open(
                        `/itext/book?${$httpParamSerializer({
                            key: key,
                            year: year,
                            acronym: acronym
                        })}`
                    );
                }

                return {
                    countPages,
                    stamp,
                    generateTOC,
                    generateAuthorIndex,
                    generateOAForm,
                    generateVideoReleaseForm,
                    generateSessionDivider,
                    buildBook,
                    countAllPages,
                    batchGenerateSessionDividers,
                    downloadBook
                };
            }
        }
    );
})();
