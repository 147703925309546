(function () {
    "use strict";

    const BOOTSTRAP_TOTAL_COLUMNS = 12;
    const BASE_HEIGHT = 400;
    const HEIGHT_SCALING_FACTOR = 1.5;

    angular.module("cpir").controller(
        "DashboardEditorTocMultiPdfViewerController",

        class {
            constructor($window, $scope, $state, store, TOCViewModeService) {
                this.$window = $window;
                this.$scope = $scope;
                this.$state = $state;
                this.store = store;
                this.TOCViewModeService = TOCViewModeService;
            }

            $onInit() {
                console.log("MultiPdfViewer $onInit");

                this.pid = this.$state.params.pid;
                this.previousState =
                    this.$state.params.previousState ||
                    "dashboard-editor.table-of-contents"; // Default fallback
                this.previousStateParams = this.$state.params
                    .previousStateParams || { pid: this.pid };

                // Get PDF metadata array from state params
                this.pdfMetadata = this.$state.params.pdfMetadata || [];
                console.log("PDF metadata to display:", this.pdfMetadata);

                // Initialize PDFs per row with default value of 3
                this.pdfsPerRow = 3;

                this.setupPdfUrls();
                this.calculateLayout();

                // Listen for window resize to adjust layout
                this.resizeHandler = () => {
                    this.calculateLayout();
                    this.$scope.$apply();
                };
                angular.element(this.$window).on("resize", this.resizeHandler);
            }

            $onChanges(changes) {
                console.log("MultiPdfViewer $onChanges:", changes);
                if (changes.entries) {
                    console.log(
                        "Selected entries changed:",
                        this.selectedEntries,
                    );
                    this.setupPdfUrls();
                    this.calculateLayout();
                }
            }

            setupPdfUrls() {
                const protocol = window.location.protocol;
                const domain = window.location.host;
                const idToken = this.store.get("id_token");

                this.pdfUrls = this.pdfMetadata.map((metadata) => ({
                    url: `${protocol}//${domain}/files/${metadata.vid}?id_token=${idToken}`,
                    title: metadata.title || "",
                    number: metadata.number || "",
                }));

                console.log("Final PDF URLs:", this.pdfUrls);
            }

            calculateLayout() {
                const totalPdfs = this.pdfUrls.length;
                console.log("Calculating layout for", totalPdfs, "PDFs");

                console.log("PDFs per row:", this.pdfsPerRow);

                // Calculate rows based on selected PDFs per row
                this.pdfRows = [];
                for (let i = 0; i < totalPdfs; i += this.pdfsPerRow) {
                    this.pdfRows.push(
                        this.pdfUrls.slice(i, i + this.pdfsPerRow),
                    );
                }

                // Calculate Bootstrap column class
                const columnsPerPdf = Math.floor(
                    BOOTSTRAP_TOTAL_COLUMNS /
                        Math.min(totalPdfs, this.pdfsPerRow),
                );
                this.colClass = `col-sm-${columnsPerPdf}`;

                // Calculate PDF height based on viewport and pdfsPerRow
                const headerHeight = 120;
                const availableHeight =
                    this.$window.innerHeight - headerHeight - 30;
                //const numRows = this.pdfRows.length;
                console.log("Available height:", availableHeight);

                // Scale height based on pdfsPerRow
                const heightScale =
                    (HEIGHT_SCALING_FACTOR *
                        (6 - Math.min(5, this.pdfsPerRow))) /
                    5;
                console.log("Height scale:", heightScale);
                const rowHeight = Math.floor(availableHeight * heightScale);

                this.pdfHeight = `${Math.max(rowHeight, BASE_HEIGHT)}px`;
                console.log("Layout calculated:", {
                    pdfRows: this.pdfRows,
                    colClass: this.colClass,
                    rowHeight: rowHeight,
                    baseHeight: BASE_HEIGHT,
                    pdfHeight: this.pdfHeight,
                    heightScale: heightScale,
                });
            }

            $onDestroy() {
                // Clean up resize listener
                angular.element(this.$window).off("resize", this.resizeHandler);
            }

            goBack() {
                // Use TOCViewModeService to navigate back to the correct view mode
                this.TOCViewModeService.navigateToTableOfContentsWithSessionMode(
                    this.pid,
                );
            }
        },
    );
})();
