(function() {
    "use strict";

    angular.module("cpir").filter("proceedingXploreGetStatus", [ "ProceedingXploreStatusService",
            ProceedingXploreStatusService => {
        return (proceeding) => {
            const statusObject = ProceedingXploreStatusService.getXploreStatus(proceeding);
            if(statusObject) {
                if (statusObject.syndicated === true && !statusObject.syndicating && !statusObject.syndicationError)
                    return "Syndicated";
                else if (!statusObject.syndicationError && statusObject.syndicationError !== true && statusObject.syndicating === true)
                    return "Syndicating";
                else if (statusObject.syndicationError === true)
                    return "Syndication Error";
            }
        };
    }]);
})();
