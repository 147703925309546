(function() {
    "use strict";

    angular.module("cpir").provider(
        "WebpubService",
        class {
            $get(
                $window,
                $q,
                $http,
                UploadService,
                ProceedingService,
                DeepLinkService,
                store
            ) {
                function getDownloadLink(pid) {
                    // get the id_token from local storage
                    const idToken = store.get("id_token");

                    return `/webpub/${pid}?id_token=${idToken}`;
                }

                function getEditorialAccessLink(pid) {
                    return DeepLinkService.getWebpubEditorialDeepLink(pid);
                }

                async function build(pid, conferencesToMerge, buildOptions) {
                    if (!pid) {
                        throw new Error("Missing pid");
                    }

                    // first upload the banner image if it exists and has not been uploaded already
                    if (
                        buildOptions.bannerImage &&
                        !buildOptions.bannerImage.uploaded
                    ) {
                        // upload the banner image using the upload service
                        try {
                            await UploadService.uploadWebpubBanner(
                                pid,
                                buildOptions.bannerImage
                            );
                        } catch (err) {
                            throw new Error(
                                `Error uploading banner image: ${err.message}`
                            );
                        }
                    }

                    const selectedConferences = conferencesToMerge.map(
                        proceeding =>
                            _.pick(proceeding, [
                                "pid",
                                "title",
                                "acronym",
                                "year",
                                "toc"
                            ])
                    );

                    const webpubBuildOptions = {
                        ..._.omit(buildOptions, ["bannerImage"]),
                        bannerImage: buildOptions.bannerImage
                            ? {
                                  ..._.pick(buildOptions.bannerImage, [
                                      "name",
                                      "size"
                                  ]),
                                  uploaded: true
                              }
                            : null,
                        selectedConferences
                    };

                    // save the webpub build options to the proceeding
                    // this is so that the webpub can be rebuilt later without having to re-enter the options
                    await ProceedingService.update(pid, {
                        webpubBuildOptions
                    });

                    // Call the webpub build endpoint
                    return $http
                        .post(`/webpub/${pid}`, {
                            options: webpubBuildOptions
                        })
                        .then(result => result.data);
                }

                function deleteWebpub(pid, key) {
                    return $http.delete(`/webpub/${pid}/${key}`);
                }

                function getWebpubDefaults() {
                    return {
                        initialized: true,
                        webpubType: "webpub",
                        webpubName: null,
                        exportSupplementalFiles: true,
                        enableWebpubDownload: true,
                        enableAffiliationIndex: false,
                        videoPlayer: "mediaelement", // can be one of [default|link|native|videojs|mediaelement]
                        enableLogin: true,
                        enableEditorialOnlyDownload: true,
                        selectedConferences: []
                    };
                }

                async function getLoginInfo(proceeding, buildOptions) {
                    if (!proceeding || !buildOptions) {
                        throw new Error(
                            "Proceeding or buildOptions not defined"
                        );
                    }

                    try {
                        const response = await $http.get(
                            `/webpub/${proceeding.pid}/users/${buildOptions.webpubName}`
                        );
                        return response.data;
                    } catch (error) {
                        // check if it's a 404 error
                        if (error.status === 404) {
                            // return the default login info
                            return {
                                uriRoot: buildOptions.webpubName,
                                user: `${
                                    buildOptions.webpubName
                                }${proceeding.year % 100}`,
                                pass: `conf${proceeding.year % 100}//`,
                                opendate: null
                            };
                        } else {
                            // some other error occurred
                            console.error("Failed to fetch login info:", error);
                            throw error; // or handle it differently if you wish
                        }
                    }
                }

                async function updateLoginInfo(
                    proceeding,
                    buildOptions,
                    loginInfo
                ) {
                    if (!proceeding || !buildOptions) {
                        throw new Error(
                            "Proceeding or buildOptions not defined"
                        );
                    }
                    try {
                        const updateData = {
                            "webpubBuildOptions.enableLogin":
                                buildOptions.enableLogin,
                            "webpubBuildOptions.webpubType": "webpub",
                            "webpubBuildOptions.webpubName":
                                buildOptions.webpubName
                        };

                        const lastBuild =
                            proceeding.toc &&
                            _.last(proceeding.toc.webpubKeys || []);
                        if (
                            lastBuild &&
                            lastBuild.buildOptions &&
                            lastBuild.buildOptions.webpubType === "webpub"
                        ) {
                            // update the enableLoginFlag on the last build
                            // the webpub name should remain the same. It is not allowed to change between builds
                            _.set(
                                lastBuild,
                                "buildOptions.enableLogin",
                                buildOptions.enableLogin
                            );
                            updateData["toc.webpubKeys"] =
                                proceeding.toc.webpubKeys;
                        }

                        // save the option to the proceeding
                        await ProceedingService.update(
                            proceeding.pid,
                            updateData
                        );

                        let response;
                        if (buildOptions.enableLogin) {
                            response = await $http.post(
                                `/webpub/${proceeding.pid}/users/${buildOptions.webpubName}`,
                                loginInfo
                            );
                        } else {
                            response = await $http.delete(
                                `/webpub/${proceeding.pid}/users/${buildOptions.webpubName}`
                            );
                        }
                        return response.data;
                    } catch (error) {
                        // check if it's a 404 error. If so ignore it and return the login info
                        // this should only happen during a delete operation since the post operation should upsert if needed
                        if (error.status === 404) {
                            return loginInfo;
                        }
                        console.error("Failed to update login info:", error);
                        throw error;
                    }
                }

                async function ftpUpload(pid, key, webpubName) {
                    if (!pid || !key || !webpubName) {
                        throw new Error("Missing required parameters");
                    }

                    try {
                        const response = await $http.post(
                            `/webpub/${pid}/ftp`,
                            {
                                key,
                                webpubName
                            }
                        );
                        return response.data;
                    } catch (error) {
                        console.error("Failed to initiate FTP upload:", error);
                        throw error;
                    }
                }

                async function updateEOnlyAccess(
                    proceeding,
                    enableEditorialOnlyDownload
                ) {
                    if (!proceeding) {
                        throw new Error("Missing proceeding");
                    }

                    const updateData = {
                        "webpubBuildOptions.enableEditorialOnlyDownload": enableEditorialOnlyDownload,
                        "webpubBuildOptions.webpubType": "e-only"
                    };

                    const lastBuild =
                        proceeding.toc &&
                        _.last(proceeding.toc.webpubKeys || []);
                    if (
                        lastBuild &&
                        lastBuild.buildOptions.webpubType === "e-only"
                    ) {
                        // update the enableLoginFlag on the last build
                        _.set(
                            lastBuild,
                            "buildOptions.enableEditorialOnlyDownload",
                            enableEditorialOnlyDownload
                        );
                        updateData["toc.webpubKeys"] =
                            proceeding.toc.webpubKeys;
                    }

                    try {
                        await ProceedingService.update(
                            proceeding.pid,
                            updateData
                        );
                    } catch (error) {
                        console.error("Failed to update e-only access:", error);
                        throw error;
                    }
                }
                // TODO disable the update buttons when the webpub type is switched between webpub and e-only & show a tooltip
                // TODO add a loading spinner and confirmation modal to the FTP upload button

                return {
                    build,
                    getDownloadLink,
                    getEditorialAccessLink,
                    deleteWebpub,
                    updateLoginInfo,
                    getLoginInfo,
                    getWebpubDefaults,
                    ftpUpload,
                    updateEOnlyAccess
                };
            }
        }
    );
})();
