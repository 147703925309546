(function() {
    "use strict";
    angular.module("cpir").filter("proceedingXploreStatusFilter", [
        "ProceedingXploreStatusService",
        ProceedingXploreStatusService => {

        return (proceedings, xploreStatusFilter) => {
            if (!proceedings || xploreStatusFilter === "*") return proceedings;
            let _proceedings = proceedings;
            if(xploreStatusFilter === "exportDate")
                _proceedings = proceedings.filter(p => {

                   let xploreStatus = ProceedingXploreStatusService.getXploreStatus(p);
                   return (xploreStatus !== undefined && xploreStatus !== null &&
                       ((!xploreStatus.syndicated && !xploreStatus.syndicationError) || (xploreStatus.syndicated &&
                           xploreStatus.syndicating && !xploreStatus.syndicationError)));
                });
            else if(xploreStatusFilter === "syndicationDate")
                _proceedings = proceedings.filter(p => {
                   let xploreStatus = ProceedingXploreStatusService.getXploreStatus(p);
                   return (xploreStatus !== undefined && xploreStatus !== null &&
                        !xploreStatus.syndicationError
                     && xploreStatus.syndicated && !xploreStatus.syndicating);
                });
            else if(xploreStatusFilter === "syndicationError")
                _proceedings = proceedings.filter(p => {
                    let xploreStatus = ProceedingXploreStatusService.getXploreStatus(p);
                    return (xploreStatus !== undefined && xploreStatus !== null && xploreStatus.syndicationError);
                });
            return _proceedings;
        };
    }]);
})();
