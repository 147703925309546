(function() {
    "use strict";

    angular.module("cpir").factory("VideoService", [
        "$q",
        "$http",
        "apiService",
        "$window",
        function($q, $http, api, $window) {
            const videoService = {};
            const firstItem = api.firstItem;
            const items = api.items;

            /*
            Load the video settings for later use
             */
            const videoSettingsAsync = $http
                .get("/videos/config")
                .then(r => r.data)
                .catch(err => console.error(err));

            /**
             * Get an individual TOC entry
             * @param videoId
             * @param [params]
             * @param {boolean} [params.includeFiles]  whether or not to join files. default true
             * @param {boolean} [params.includeTranscripts] whether or not to join transcript files. default true
             * @param {boolean} [params.includeEntries] whether or not to join entries. default true
             */
            videoService.getVideo = (
                videoId,
                params = {
                    includeEntries: true,
                    includeTranscripts: true,
                    includeFiles: true
                }
            ) => {
                const queryArgs = [];
                if (params.includeFiles) {
                    queryArgs.push("file=1");
                }
                if (params.includeTranscripts) {
                    queryArgs.push("transcript=1");
                }
                if (params.includeEntries) {
                    queryArgs.push("entry=1");
                }
                const queryString = _.isEmpty(queryArgs)
                    ? ""
                    : `?${queryArgs.join("&")}`;
                return api
                    .get(`videos/${videoId}${queryString}`)
                    .then(firstItem);
            };

            /**
             * Get all videos for a conference
             * @param pid
             * @param [params]
             * @param {boolean} [params.includeFiles]  whether or not to join files. default true
             * @param {boolean} [params.includeTranscripts] whether or not to join transcript files. default true
             * @param {boolean} [params.includeEntries] whether or not to join entries. default true
             */
            videoService.getVideosForConference = (
                pid,
                params = {
                    includeEntries: true,
                    includeTranscripts: true,
                    includeFiles: true
                }
            ) => {
                const queryArgs = [];
                if (params.includeFiles) {
                    queryArgs.push("file=1");
                }
                if (params.includeTranscripts) {
                    queryArgs.push("transcript=1");
                }
                if (params.includeEntries) {
                    queryArgs.push("entry=1");
                }
                const queryString = _.isEmpty(queryArgs)
                    ? ""
                    : `?${queryArgs.join("&")}`;
                return api
                    .get(`videos/proceeding/${pid}${queryString}`)
                    .then(items);
            };

            /**
             * Get all videos for the current user
             * @param [params]
             * @param {boolean} [params.includeFiles]  whether or not to join files. default true
             * @param {boolean} [params.includeTranscripts] whether or not to join transcript files. default true
             * @param {boolean} [params.includeEntries] whether or not to join entries. default true
             */
            videoService.getVideosForUser = (
                params = {
                    includeEntries: true,
                    includeTranscripts: true,
                    includeFiles: true
                }
            ) => {
                const queryArgs = [];
                if (params.includeFiles) {
                    queryArgs.push("file=1");
                }
                if (params.includeTranscripts) {
                    queryArgs.push("transcript=1");
                }
                if (params.includeEntries) {
                    queryArgs.push("entry=1");
                }
                const queryString = _.isEmpty(queryArgs)
                    ? ""
                    : `?${queryArgs.join("&")}`;

                // the backend will append the userId
                return api.get(`videos/user${queryString}`).then(items);
            };

            /**
             * Get all videos for a TOC entry
             * @param eid
             * @param [params]
             * @param {boolean} [params.includeFiles]  whether or not to join files. default true
             * @param {boolean} [params.includeTranscripts] whether or not to join transcript files. default true
             * @param {boolean} [params.includeEntries] whether or not to join entries. default true
             */
            videoService.getVideosForEntry = (
                eid,
                params = {
                    includeEntries: true,
                    includeTranscripts: true,
                    includeFiles: true
                }
            ) => {
                const queryArgs = [];
                if (params.includeFiles) {
                    queryArgs.push("file=1");
                }
                if (params.includeTranscripts) {
                    queryArgs.push("transcript=1");
                }
                if (params.includeEntries) {
                    queryArgs.push("entry=1");
                }
                const queryString = _.isEmpty(queryArgs)
                    ? ""
                    : `?${queryArgs.join("&")}`;
                return api
                    .get(`videos/toc-entry/${eid}${queryString}`)
                    .then(items);
            };

            videoService.getS3Configuration = () =>
                videoSettingsAsync.then(settings => {
                    // sanitize folder
                    settings.s3.folder = settings.s3.folder || "";
                    return settings.s3;
                });

            videoService.removeVideo = video => {
                return api
                    .delete(`videos/${video.videoId}`)
                    .then(result => result.data);
            };

            videoService.downloadVideoReleaseDocuments = pid => {
                return $window.open(`videos/${pid}/packages/release`);
            };

            videoService.downloadVideoLinks = pid => {
                return $window.open(`videos/${pid}/packages/links`);
            };

            videoService.downloadCsdlVideoLinks = pid => {
                return $window.open(`videos/${pid}/packages/csdl-links`);
            };

            return videoService;
        }
    ]);
})();
