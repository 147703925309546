(function() {
    "use strict";

    angular.module("cpir").filter("tocRecordNameFilter", [
        "TOCValuesService",
        function(TOCValuesService) {
            return function(record) {
                let typeOptions = TOCValuesService.typeOptions;

                if (
                    record.type === "AP" ||
                    record.class === "FM" ||
                    record.class === "BM"
                ) {
                    return record.title;
                }

                let option = typeOptions.find(o => o.value === record.type);

                if (option) {
                    if (record.class === "SD") {
                        return `${option.name}: ${record.title}`;
                    }
                    return option.name;
                }

                return "UNDEFINED TYPE";
            };
        }
    ]);
})();
