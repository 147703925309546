(function() {
    "use strict";

    angular.module("cpir").filter("complianceApprovalStatusFilter", () => {
        return status => {
            if (status === null || status === undefined) return status;
            return status ? "Approved" : "Rejected";
        };
    });
})();
