(function () {
    "use strict";

    angular.module("cpir").factory("TocEntriesApiService", [
        "apiService",
        "TOCValuesService",
        "WebSocketService",
        "$q",
        "$http",
        "IndexedDBService",
        function (
            api,
            tocValues,
            WebSocketService,
            $q,
            $http,
            IndexedDBService,
        ) {
            const tocEntriesApiService = {};
            const { from, concat, EMPTY, throwError } = rxjs;
            const { catchError, tap, finalize, map } = rxjs.operators;
            let firstItem = api.firstItem;
            let items = api.items;

            const getVolumeSequencePairs = (entries) => {
                const getVolumeSequences = (entries) =>
                    entries
                        .filter((e) => e.type === "VD")
                        .map((e) => e.sequence);
                let previousSequence = 0;
                const volumeSequences = getVolumeSequences(entries);
                const sequencePairs = [];

                // create pairs of volume start and end sequences
                volumeSequences.forEach((sequence) => {
                    sequencePairs.push({
                        start: previousSequence,
                        end: sequence,
                    });
                    previousSequence = sequence;
                });
                // add the sequence pair for the final volume
                const endingSequence =
                    entries.sort((a, b) => a.sequence - b.sequence).slice(-1)[0]
                        .sequence + 100;
                sequencePairs.push({
                    start: previousSequence,
                    end: endingSequence,
                });
                return sequencePairs;
            };

            const getVolumes = (entries) => {
                const sequencePairs = getVolumeSequencePairs(entries);
                return sequencePairs.map(({ start, end }) => {
                    return entries
                        .filter((e) => e.sequence >= start)
                        .filter((e) => e.sequence < end)
                        .sort((a, b) => a.sequence - b.sequence);
                });
            };
            tocEntriesApiService.getVolumes = getVolumes;

            const getExistingEntryTypeForVolume = (volume, type) =>
                volume.find((e) => e.type === type);

            const createTocEntryForVolume = (pid, volume) => {
                const existingEntry = getExistingEntryTypeForVolume(
                    volume,
                    "FM_TABLE_OF_CONTENTS",
                );
                if (existingEntry) {
                    return $q.when(existingEntry);
                }

                const entry = tocValues.getFrontMatterTemplate(
                    "FM_TABLE_OF_CONTENTS",
                );
                entry.pid = pid;
                entry.title = tocValues.typeOptions.find(
                    (o) => o.value === "FM_TABLE_OF_CONTENTS",
                ).name;

                const beginning =
                    volume.find((e) => e.type === "FM_COPYRIGHT_PAGE") ||
                    volume.find((e) => e.type === "FM_TITLE_PAGE_III") ||
                    volume.find((e) => e.type === "FM_TITLE_PAGE_I");
                if (!beginning) entry.sequence = volume[0].sequence + 1e-12;
                // place directly following the volume divider
                else entry.sequence = parseFloat(beginning.sequence) + 1e-12;

                console.log(
                    `volume start: ${volume[0].sequence}, volume end: ${
                        volume.slice(-1)[0].sequence
                    } insert sequence: ${entry.sequence}`,
                );

                return tocEntriesApiService.insertEntries(entry);
            };

            const createAuthorIndexForVolume = (pid, volume) => {
                const existingEntry = getExistingEntryTypeForVolume(
                    volume,
                    "BM_AUTHOR_INDEX",
                );
                if (existingEntry) {
                    return $q.when(existingEntry);
                }

                const entry =
                    tocValues.getBackMatterTemplate("BM_AUTHOR_INDEX");
                entry.pid = pid;
                entry.title = tocValues.typeOptions.find(
                    (o) => o.value === "BM_AUTHOR_INDEX",
                ).name;

                entry.sequence = volume.slice(-1)[0].sequence + 1e-12; // place at the end of the volume
                return tocEntriesApiService.insertEntries(entry);
            };

            /**
             * Get an individual TOC entry
             * @param entryId
             */
            tocEntriesApiService.getEntry = (entryId) =>
                api.get(`tocEntries/${entryId}`).then(firstItem);

            /**
             * Check if doi already exists in cpir db
             * @param doi
             */
            tocEntriesApiService.getDoi = (doi) => {
                doi = doi.replace(/\//g, "%2F");
                return api.get(`tocEntries/doi/${doi}`).then(items);
            };

            /**
             * Get all entries for a conference
             * @param pid
             * @param all
             * @param statusZero
             */
            tocEntriesApiService.getEntries = (
                pid,
                { all, statusZero } = {},
            ) => {
                let queryParams;
                if (all || statusZero) {
                    queryParams = [];
                    if (all) queryParams.push("all=true");
                    if (statusZero) queryParams.push("status=0");
                }
                const path = `tocEntries/proceedingToc/${pid}${queryParams ? "?" + queryParams.join("&") : ""}`;
                return api.get(path).then(items);
            };

            /**
             * Get all entries for a conference with cache
             * If IndexedDB is supported, it will return the cache first and then update the cache with the API result
             * @param pid
             * @returns {{fromApi: *, fromCache: *}}
             */
            tocEntriesApiService.getEntriesWithCache$ = function (pid) {
                const hasIndexedDBSupport = IndexedDBService.isSupported();
                if (!hasIndexedDBSupport) {
                    console.warn(
                        "IndexedDB is not supported. Falling back to API only.",
                    );
                }

                const indexedDBObservable = hasIndexedDBSupport
                    ? from(IndexedDBService.getEntries(pid)).pipe(
                          catchError((err) => {
                              console.error("Error accessing IndexedDB", err);
                              return EMPTY; // If there's an error or IndexedDB is empty, emit nothing from this stream
                          }),
                      )
                    : EMPTY; // If no IndexedDB support, emit nothing

                // get status 0 entries from API
                tocEntriesApiService
                    .getEntries(pid, { statusZero: true })
                    .then((entries) => {
                        if (hasIndexedDBSupport) {
                            IndexedDBService.updateEntries(entries);
                        }
                    });

                const apiObservable = from(
                    tocEntriesApiService.getEntries(pid),
                ).pipe(
                    tap((entries) => {
                        if (hasIndexedDBSupport) {
                            IndexedDBService.updateEntries(entries);
                        }
                    }),
                    map((entries) => entries.filter((e) => e.status === 1)),
                    catchError((err) => {
                        console.error("Error fetching entries from API", err);
                        return throwError(
                            () => new Error("Failed to fetch entries from API"),
                        );
                    }),
                );

                return concat(indexedDBObservable, apiObservable).pipe(
                    finalize(() => console.log("Completed fetching entries")),
                );
            };

            /**
             * Get TOC entries across all TOC documents for the submitter's Email
             * @param pid
             * @param email
             */
            tocEntriesApiService.getEntriesBySubmitterEmail = (pid, email) => {
                return api
                    .get(
                        `tocEntries/proceeding/${pid}/submitter?email=${encodeURIComponent(
                            email,
                        )}`,
                    )
                    .then(items);
            };

            /**
             * Update TOC Entry
             * @param entries
             */
            tocEntriesApiService.updateEntries = (entries) => {
                // delete toc._id;
                return api
                    .put(`tocEntries`, entries)
                    .then((result) => result.data);
            };

            /**
             * Update TOC Entry with Projection.
             * Uses projection to only return the updated fields to keep payload smaller.
             * @param entries
             */
            tocEntriesApiService.updateEntriesWithProjection = (entries) => {
                // delete toc._id;
                return api
                    .put(`tocEntries/withProjection`, entries)
                    .then((result) => result.data);
            };

            /**
             * Delete TOC Entry
             * @param entryIds
             */
            tocEntriesApiService.deleteEntries = (entryIds) => {
                return api.post("tocEntries/delete", entryIds);
            };

            /**
             * Insert TOC Entry(s)
             * @param entries (single or array)
             */
            tocEntriesApiService.insertEntries = (entries) =>
                api.post("tocEntries", entries).then((result) => result.data);

            /**
             * Inserts the actual table of contents entries (as in article)
             * @param pid
             * @param entries
             */
            tocEntriesApiService.insertTableOfContentsEntries = (
                pid,
                entries,
            ) => {
                //if no toc get toc and recurse
                if (!entries)
                    return tocEntriesApiService
                        .getEntries(pid)
                        .then((entries) =>
                            tocEntriesApiService.insertTableOfContentsEntries(
                                pid,
                                entries,
                            ),
                        )
                        .catch((err) => console.error(err));

                return $q.all(
                    getVolumes(entries).map((v) =>
                        createTocEntryForVolume(pid, v),
                    ),
                );
            };

            /**
             * Inserts an author index
             * @param pid
             * @param entries
             */
            tocEntriesApiService.insertAuthorIndices = (pid, entries) => {
                //if no toc get toc then recurse
                if (!entries)
                    return tocEntriesApiService
                        .getEntries(pid)
                        .then((entries) =>
                            tocEntriesApiService.insertAuthorIndices(
                                pid,
                                entries,
                            ),
                        )
                        .catch((err) => console.error(err));

                return $q.all(
                    getVolumes(entries).map((v) =>
                        createAuthorIndexForVolume(pid, v),
                    ),
                );
            };

            /**
             * Inserts a session divider
             * @param pid
             * @param title
             * @param options
             */
            tocEntriesApiService.insertSessionDivider = (
                pid,
                title,
                options,
            ) => {
                if (!pid) throw new Error("Missing pid");
                if (!title) throw new Error("Missing title");
                const entry = angular.copy(tocValues.sectionDividerTemplate);
                options = options || {};
                entry.pid = pid;
                if (options.sequence !== undefined)
                    entry.sequence = parseFloat(options.sequence) + 1e-12;
                entry.hasPdf =
                    options.hasPdf === undefined ? false : options.hasPdf;
                entry.title = title;
                entry.chair =
                    options.chair && options.chair.name
                        ? options.chair
                        : undefined;
                entry.type = options.type ? options.type : entry.type;
                entry.pagePadding = 1;
                console.log("pre entry: ", entry);

                return tocEntriesApiService
                    .insertEntries(entry)
                    .then((result) => {
                        console.log("new entry: ", result);
                        return result.eid;
                    });
            };

            tocEntriesApiService.insertVolumeDivider = (pid, options) => {
                if (!pid) throw new Error("Missing pid");
                const entry = angular.copy(tocValues.volumeDividerTemplate);
                entry.pid = pid;
                options = options || {};
                if (options.sequence !== undefined)
                    entry.sequence = parseFloat(options.sequence) + 1e-12;
                return tocEntriesApiService
                    .insertEntries(entry)
                    .then((result) => {
                        console.log("new entry: ", result);
                        return result.eid;
                    });
            };

            /**
             * Created a mapping of entry ids to session titles.
             * Uses only SD_SESSION types
             * @param entries
             * @return {{}}
             */
            tocEntriesApiService.getEntrySessionMap = (entries) => {
                let seenSession = null;
                const entriesWithSessions = _.map(
                    _.sortBy(entries, "sequence"),
                    (entry) => {
                        if (entry.type === "SD_SESSION") {
                            seenSession = entry.title;
                        }
                        // reset session for volume divider
                        if (entry.type === "VD") {
                            seenSession = null;
                        }
                        return { eid: entry.eid, session: seenSession };
                    },
                );
                return _.mapValues(
                    _.keyBy(entriesWithSessions, "eid"),
                    "session",
                );
            };

            /**
             * Starts the TOC websocket.  Initializes status listener and iThenticate listener
             * @param pid
             * @param getIthenticateStatus
             * @return {*}
             */
            tocEntriesApiService.startSocket = (
                pid,
                getIthenticateStatus = true,
                getPitstopStatus = true,
            ) => {
                const tocStatusDeferred = $q.defer();
                const iThenticateDeferred = $q.defer();
                const pitstopDeferred = $q.defer();

                WebSocketService.openSocket((client) => {
                    client.on("toc.ok", () => {
                        console.log("toc status websocket ready");
                        tocStatusDeferred.resolve(client);
                    });
                    if (getIthenticateStatus) {
                        client.on("ithenticate.ok", () => {
                            console.log("ithenticate websocket ready");
                            iThenticateDeferred.resolve(client);
                        });
                    } else {
                        iThenticateDeferred.resolve(client);
                    }
                    if (getPitstopStatus) {
                        client.on("pitstop.ok", () => {
                            pitstopDeferred.resolve(client);
                        });
                    } else {
                        pitstopDeferred.resolve(client);
                    }

                    client.emit("toc.status", pid);
                    if (getIthenticateStatus) {
                        client.emit("toc.ithenticate", pid);
                    }
                    if (getPitstopStatus) {
                        client.emit("pitstop.status", pid);
                    }
                });
                return $q
                    .all([
                        iThenticateDeferred.promise,
                        tocStatusDeferred.promise,
                        pitstopDeferred.promise,
                    ])
                    .then(([client, _]) => client)
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });
            };

            tocEntriesApiService.broadcastEntries = (pid) => {
                return $http
                    .post(`/toc/${pid}/broadcast`, {})
                    .then((r) => r.data)
                    .catch((err) => console.error(err));
            };

            tocEntriesApiService.getSessionDividerNames = (pid, type) => {
                const queryString = type ? `?type=${type}` : "";
                return $http
                    .get(
                        `/proceedings/${pid}/session-divider-names${queryString}`,
                    )
                    .then((r) => r.data)
                    .catch((err) => console.error(err));
            };

            return tocEntriesApiService;
        },
    ]);
})();
