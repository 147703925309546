/**
 * Created by jhellwig on 1/17/2017.
 */ (function() {
    "use strict";

    angular.module("cpir").provider(
        "apiService",
        class {
            $get($http) {
                const PATH = "/api/";
                const PUBLIC_PATH = "/public/api/";
                const baseConfig = { "content-type": "application/json" };

                function _mergeConfig(config) {
                    return angular.merge({}, baseConfig, config);
                }

                // proxy get post and put with the API URL
                function get(path, config) {
                    return $http.get(PATH + path, config).catch(_logErr);
                }

                function getPublic(path, config) {
                    return $http.get(PUBLIC_PATH + path, config).catch(_logErr);
                }

                function post(path, data, config) {
                    return $http
                        .post(PATH + path, data, _mergeConfig(config))
                        .catch(_logErr);
                }
                function put(path, data, config) {
                    return $http
                        .put(PATH + path, data, _mergeConfig(config))
                        .catch(_logErr);
                }

                function patch(path, data, config) {
                    return $http
                        .patch(PATH + path, data, _mergeConfig(config))
                        .catch(_logErr);
                }

                function httpDelete(path, config) {
                    return $http.delete(PATH + path, config).catch(_logErr);
                }

                function _logErr(err) {
                    console.log(err);
                    throw err;
                }

                function items(result) {
                    if (result.data.items && result.data.items.length > 0)
                        result.data.items.forEach(item => delete item._id);
                    return result.data.items;
                }

                function firstItem(result) {
                    if (result.data.items[0]) delete result.data.items[0]._id;
                    else {
                        console.log();
                        throw new Error(
                            "No Results for URL: " + result.config.url
                        );
                    }
                    return result.data.items[0];
                }

                return {
                    get,
                    getPublic,
                    post,
                    put,
                    patch,
                    delete: httpDelete,
                    items,
                    firstItem
                };
            }
        }
    );
})();
