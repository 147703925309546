(function() {
    "use strict";

    angular.module("cpir").controller(
        "CifContactsController",

        class {
            constructor(
                pid,
                $scope,
                $state,
                $uibModal,
                $stateParams,
                authService,
                CifService,
                NotificationService,
                ContactService,
                ProceedingService,
                ProceedingStatusService,
                TimerService
            ) {
                this.pid = pid;
                this.$scope = $scope;
                this.$state = $state;
                this.$uibModal = $uibModal;
                this.$stateParams = $stateParams;
                this.authService = authService;
                this.CifService = CifService;
                this.NotificationService = NotificationService;
                this.ContactService = ContactService;
                this.ProceedingService = ProceedingService;
                this.ProceedingStatusService = ProceedingStatusService;
                this.TimerService = TimerService;
            }

            $onInit() {
                this.isLoading = true;
                this.loadWaitAsync = this.TimerService.getLoadingTimer();
                this.configuration = this.CifService.getConfiguration();

                this.activeContactIndex = 0;

                this.profile = this.authService.getProfile();

                this.chairValidation = {
                    conferenceIsNonSponsored: false,
                    chairsMatch: false,
                    missingGeneralChair: true,
                    missingFinanceChair: true
                };

                // Call the API to receive the proceeding
                this.ProceedingService.get(this.pid)
                    .then(proceeding => {
                        this.proceeding = proceeding;

                        this.chairValidation.conferenceIsNonSponsored = !this
                            .proceeding.isSponsored;

                        if (this.proceeding.contacts) {
                            let cids = [];
                            for (let contact of this.proceeding.contacts) {
                                cids.push(contact.cid);
                            }
                            this.ContactService.list(cids).then(contacts => {
                                this.contacts = contacts;
                                this.activeContact = this.contacts[0];
                                this.originalContact = angular.copy(
                                    this.activeContact
                                );

                                this.loadWaitAsync.then(() => {
                                    this.isLoading = false;
                                    this.TimerService.applyTimer(this.$scope);
                                });
                            });
                            this.isActiveContactValid = true;
                        } else {
                            let conferenceOrganizer = {
                                isConferenceOrganizer: true,
                                email: this.profile.email,
                                address: {
                                    country: "US"
                                }
                            };
                            this.contacts = [conferenceOrganizer];
                            this.contactForm = {};
                            this.isActiveContactValid = false;
                            this.activeContact = this.contacts[0];
                            this.originalContact = angular.copy(
                                this.activeContact
                            );
                            this.loadWaitAsync.then(() => {
                                this.isLoading = false;
                                this.TimerService.applyTimer(this.$scope);
                            });
                        }
                    })
                    .catch(e => console.log(e));
            }

            addContact() {
                this.contacts.push({
                    isConferenceOrganizer: false,
                    givenName: "",
                    surname: "",
                    address: {
                        country: "US"
                    }
                });
                this.activateContact(this.contacts.length - 1);
                this.isActiveContactValid = false;
                this.$scope.$emit("CONTACT_ADDED");
            }

            removeActiveContact() {
                this.contacts.splice(this.activeContactIndex, 1);
                this.activateContact(this.activeContactIndex - 1);
            }

            activateContact(contactIndex) {
                this.activeContactIndex = contactIndex;
                this.activeContact = this.contacts[contactIndex];
                this.originalContact = angular.copy(this.activeContact); // Store the original contact
                this.isActiveContactValid = true;
            }

            activeContactChanged(contact, isValid) {
                this.activeContact = contact;
                this.isActiveContactValid = isValid;

                // Check if the email, givenName, or surname fields have been updated
                if (this.originalContact) {
                    this.activeContact.isContactUpdated =
                        (this.originalContact.email || "")
                            .trim()
                            .toLowerCase() !==
                            (this.activeContact.email || "")
                                .trim()
                                .toLowerCase() ||
                        (this.originalContact.givenName || "")
                            .trim()
                            .toLowerCase() !==
                            (this.activeContact.givenName || "")
                                .trim()
                                .toLowerCase() ||
                        (this.originalContact.surname || "")
                            .trim()
                            .toLowerCase() !==
                            (this.activeContact.surname || "")
                                .trim()
                                .toLowerCase();
                }
            }

            getChairValidation() {
                if (!this.contacts) return this.chairValidation;

                const generalChairs =
                    this.contacts.filter(
                        c => c.roles && c.roles.find(r => r === "GC")
                    ) || [];
                const financeChairs =
                    this.contacts.filter(
                        c => c.roles && c.roles.find(r => r === "FC")
                    ) || [];
                const gcNames = generalChairs.map(c =>
                    (
                        (c.givenName || "").trim() + (c.surname || "").trim()
                    ).toLowerCase()
                );
                const fcNames = financeChairs.map(c =>
                    (
                        (c.givenName || "").trim() + (c.surname || "").trim()
                    ).toLowerCase()
                );
                const gcEmails = generalChairs.map(c =>
                    (c.email || "").trim().toLowerCase()
                );
                const fcEmails = financeChairs.map(c =>
                    (c.email || "").trim().toLowerCase()
                );

                const hasGeneralAndFinanceChairs =
                    generalChairs.length > 0 && financeChairs.length > 0;

                const namesMatch =
                    hasGeneralAndFinanceChairs &&
                    _.union(gcNames, fcNames).length < 2;
                const emailsMatch =
                    hasGeneralAndFinanceChairs &&
                    _.union(gcEmails, fcEmails).length < 2;
                this.chairValidation.chairsMatch =
                    hasGeneralAndFinanceChairs && (namesMatch || emailsMatch);

                this.chairValidation.missingGeneralChair =
                    generalChairs.length < 1;
                this.chairValidation.missingFinanceChair =
                    financeChairs.length < 1;

                return this.chairValidation;
            }

            /**
             * If an editor is updating the contacts, warn him or her
             * that changing e-mail addresses may impede access to the
             * publication agreement unless it is resent.
             */
            preSaveCheck() {
                if (
                    !this.ProceedingStatusService.isInquiryStarted(
                        this.proceeding
                    )
                ) {
                    this.$uibModal
                        .open({
                            component: "cpirConfirmationModal",
                            resolve: {
                                title: () => "Caution",
                                message: () =>
                                    "Please note that if any e-mail addresses are changed, the publication agreement must be resent if access needs to be maintained.",
                                cancelButtonDisabled: () => true
                            }
                        })
                        .result.then(() => {
                            this.saveContacts();
                        });
                } else {
                    this.saveContacts();
                }
            }

            saveContacts() {
                this.isLoading = true;
                this.TimerService.getLoadingTimer();
                this.ContactService.createOrUpdate(this.contacts, this.pid)
                    .then(contacts => {
                        this.proceeding.contacts = contacts.map(c => {
                            return {
                                cid: c.cid,
                                roles: c.roles
                            };
                        });
                        this.ProceedingService.update(this.pid, this.proceeding)
                            .then(() => {
                                this.NotificationService.send(
                                    "success",
                                    "The contact(s) were saved successfully."
                                );
                                this.goToNextState();
                            })
                            .catch(error => console.log(error));
                    })
                    .then(() => {
                        return this.ProceedingService.batchUpdateWorkshopContacts(
                            this.pid
                        );
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.NotificationService.send(
                            "warning",
                            "The contacts could not found."
                        );
                    });
            }

            isProvinceEnabled() {
                for (let country of $this.countriesWithProvincesEnabled) {
                    if (this.activeContact.address.country === country)
                        return true;
                }
                return false;
            }

            isStateValid() {
                const chairValidation = this.getChairValidation();
                const isChairInformationValid =
                    !chairValidation.conferenceIsNonSponsored ||
                    (!chairValidation.chairsMatch &&
                        !chairValidation.missingFinanceChair &&
                        !chairValidation.missingGeneralChair);
                const isSignatorySelected = this.isSignatoryInContacts();

                this.nextButtonErrorText = "<ul>";
                if (!this.isActiveContactValid) {
                    this.nextButtonErrorText +=
                        "<li>Please complete or remove the current contact.</li>";
                }
                if (!isSignatorySelected) {
                    this.nextButtonErrorText +=
                        "<li>Please select a Signatory.</li>";
                }
                if (!isChairInformationValid) {
                    if (chairValidation.missingGeneralChair) {
                        this.nextButtonErrorText +=
                            "<li>Please select at least one General Chair role.</li>";
                    }
                    if (chairValidation.missingFinanceChair) {
                        this.nextButtonErrorText +=
                            "<li>Please select at least one Finance Chair role.</li>";
                    }
                    if (chairValidation.chairsMatch) {
                        this.nextButtonErrorText +=
                            "<li>The General Chair and Finance Chair cannot be the same person.</li>";
                    }
                }
                this.nextButtonErrorText += "</ul>";

                return !!(
                    this.isActiveContactValid &&
                    isSignatorySelected &&
                    isChairInformationValid
                );
            }

            isEmailDisabled() {
                if (!this.proceeding) return true;
                if (
                    this.ProceedingStatusService.isInquiryStarted(
                        this.proceeding
                    )
                ) {
                    return this.activeContact
                        ? this.activeContact.isConferenceOrganizer
                        : true;
                } else {
                    return false;
                }
            }

            isSignatoryInContacts() {
                let hasSignatory = false;
                if (!this.contacts) return false;
                for (let contact of this.contacts) {
                    if (contact.isSignatory === true) {
                        hasSignatory = true;
                    }
                }
                return hasSignatory;
            }

            goToPreviousState() {
                if (this.proceeding.hasSeparatePublicationsInXplore) {
                    this.$state.go("cif.workshops", { pid: this.pid });
                } else {
                    this.$state.go("cif.conference-update", { pid: this.pid });
                }
            }

            goToNextState() {
                this.$state.go("cif.quotes", { pid: this.pid });
            }
        }
    );
})();
