(function() {
    "use strict";

    angular.module("cpir").component("cpirAkPreparingProductionEditorPanel", {
        bindings: {
            proceeding: "<"
        },
        templateUrl:
            "application/cpir/authorkit/_shared/components/preparation-information/components/production-editor-panel/production-editor-panel.component.html",
        controller: class {
            constructor() {}

            $onInit() {}
        }
    });
})();
