(function() {
    "use strict";

    angular.module("cpir").factory("ContactService", [
        "$q",
        "apiService",
        function($q, api) {
            let contactService = {};

            /**
             * List all contacts by cids.
             */
            contactService.list = cids => {
                if (cids === undefined) return api.get("contacts");

                let deferred = $q.defer();
                let promises = [];
                for (let cid of cids) {
                    promises.push(api.get(`contacts/${cid}`));
                }
                $q.all(promises)
                    .then(results => {
                        let contacts = [];
                        for (let result of results) {
                            contacts.push(result.data.items[0]);
                        }
                        deferred.resolve(contacts);
                    })
                    .catch(error => {
                        console.log(error);
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            /**
             * List all contacts by proceeding.
             */
            contactService.listByProceeding = proceeding => {
                if (!proceeding || !proceeding.contacts)
                    return $q.reject("Proceeding or contacts do not exists.");

                let deferred = $q.defer();

                let promises = proceeding.contacts.map(c =>
                    api.get(`contacts/${c.cid}`)
                );

                $q.all(promises)
                    .then(results => {
                        let contacts = results.map(r => r.data.items[0]);
                        deferred.resolve(contacts);
                    })
                    .catch(error => {
                        console.log(error);
                        deferred.reject(error);
                    });

                return deferred.promise;
            };

            /**
             * Get conference organizer by proceeding.
             */
            contactService.getConferenceOrganizer = proceeding => {
                if (!proceeding || !proceeding.contacts)
                    return $q.reject("Proceeding or contacts do not exists.");

                return contactService
                    .listByProceeding(proceeding)
                    .then(contacts =>
                        contacts.find(c => c.isConferenceOrganizer)
                    );
            };

            /**
             * Insert a new contact.
             * @param contact
             * @param pid
             */
            contactService.create = (contact, pid) => {
                let deferred = $q.defer();
                api.post(`contacts?pid=${pid}`, contact)
                    .then(result => {
                        console.log("create contact result: ", result.data);
                        deferred.resolve(result.data);
                    })
                    .catch(error => {
                        console.log(error);
                        deferred.reject(error);
                    });

                return deferred.promise;
            };

            /**
             * Update a contact.
             * @param cid
             * @param contact
             * @param isContactUpdated
             * @returns {*}
             **/
            contactService.update = (cid, contact, isContactUpdated) => {
                let deferred = $q.defer();
                delete contact._id;
                const query = isContactUpdated ? "?isContactUpdated=true" : "";
                api.put(`contacts/${cid}${query}`, contact).then(
                    result => {
                        console.log("update contact result: ", result.data);
                        deferred.resolve(result.data);
                    },
                    error => {
                        deferred.reject(error);
                    }
                );
                return deferred.promise;
            };

            contactService.createOrUpdate = (contacts, pid) => {
                let deferred = $q.defer();
                let promises = [];
                for (let contact of contacts) {
                    const isContactUpdated = contact.isContactUpdated;
                    delete contact.isContactUpdated;
                    if (contact.cid) {
                        promises.push(
                            contactService.update(
                                contact.cid,
                                contact,
                                isContactUpdated
                            )
                        );
                    } else {
                        promises.push(contactService.create(contact, pid));
                    }
                }
                $q.all(promises)
                    .then(results => {
                        console.log("results: ", results);
                        deferred.resolve(results);
                    })
                    .catch(error => {
                        deferred.reject(error);
                    });
                return deferred.promise;
            };

            return contactService;
        }
    ]);
})();
