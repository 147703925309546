(function() {
    "use strict";

    angular.module("cpir").provider(
        "ProceedingRoleService",
        class {
            $get() {
                function getRole(proceeding) {
                    return (
                        proceeding.decorations && proceeding.decorations.role
                    );
                }

                function isOrganizer(proceeding) {
                    return getRole(proceeding) === "organizer";
                }

                function isEditor(proceeding) {
                    return getRole(proceeding) === "editor";
                }

                function isVendor(proceeding) {
                    return getRole(proceeding) === "vendor";
                }

                function isAuthor(proceeding) {
                    return getRole(proceeding) === "author";
                }

                function isSignatory(proceeding) {
                    return getRole(proceeding) === "signatory";
                }

                function isEditorOrOrganizer(proceeding) {
                    return isEditor(proceeding) || isOrganizer(proceeding);
                }

                return {
                    isEditorOrOrganizer,
                    isSignatory,
                    isOrganizer,
                    isEditor,
                    isAuthor,
                    isVendor,
                    getRole
                };
            }
        }
    );
})();
