(function() {
    "use strict";

    angular.module("cpir").component("cpirComplianceAddChairButton", {
        bindings: {
            addChair: "&"
        },
        templateUrl:
            "application/cpir/dashboard/editor/compliance-submission/components/compliance-form/components/add-chair-button/add-member-button.component.html",
        controller: function() {
            let $ctrl = this;

            $ctrl.buttonClicked = () => {
                $ctrl.addChair();
            };
        }
    });
})();
