(function() {
    "use strict";

    angular.module("cpir").controller(
        "DashboardEditorPublicationAgreementController",
        class DashboardEditorPublicationAgreementWrapper {
            constructor(pid) {
                this.pid = pid;
            }
        }
    );
})();
