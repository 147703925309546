(function() {
    "use strict";

    angular.module("cpir").component("cpirTocSummaryPanel", {
        bindings: {
            entries: "<",
            articles: "<"
        },
        templateUrl:
            "application/cpir/dashboard/editor/table-of-contents/components/summary-panel/summary-panel.component.html",
        controller: class {
            constructor($uibModal) {
                this.$uibModal = $uibModal;
            }

            $onInit() {}

            showQuoteMedia() {
                this.$uibModal.open({
                    component: "cpirTocQuoteMediaModal",
                    size: "lg"
                });
            }

            showNoShowEntryList() {
                let entries = this.entries.filter(e => e.noShow === true);
                this.$uibModal.open({
                    component: "noShowPanel",
                    size: "lg",
                    resolve: {
                        "entries": () => entries,
                        "isSummaryPanel": () => true
                    }
                });
            }
        }
    });
})();
